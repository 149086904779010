<div class="row">
    <div class="col">
        <div class="form-row">
            <div class="col-md-2">
                <label for="accBalance">Account</label>
                <select class="form-control" [(ngModel)]="search.accountId" (change)="selectChangeHandler31($event)">
                    <option value="0" selected disabled>Choose</option>
                    <option *ngFor="let p of accounts" [value]="p.accountId">{{p.accountName}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="operation">Operation</label>
                <select class="form-control" [(ngModel)]="search.operation" (change)="selectChangeHandler31($event)">
                    <option value="0" selected disabled>Choose</option>
                    <option value="PaymentRequest">PaymentRequest</option>
                    <option value="GRNAdvice">GRNAdvice</option>
                    <option value="PaymentAdvice">PaymentAdvice</option>
                </select>
            </div>
            <div class="col-md-2">
                <label></label><br />
                <div>
                    <button class="btn btn-sm btn-info" (click)="getAccountCompanySetting()">Search</button>
                </div>
            </div>
        </div>
        <br>
        <div class="form-row" *ngIf="accountCompanySetts.length > 0">
            <div class="col-md-2">
                <label></label>
                <div>
                    <button class="btn btn-sm btn-info" (click)="UpdateAccountCompanySetting()">Save</button>
                </div>
            </div>
        </div>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">CompanyName</th>
                    <th scope="col">Company</th>
                    <th scope="col">ToEmail</th>
                    <th scope="col">ToEmail</th>
                    <th scope="col">CCEmail</th>
                    <th scope="col">CCEmail</th>
                </tr>
            </thead>
            <tbody *ngFor="let u of accountCompanySetts">
                <tr>
                    <td>{{u.vehicleName}}</td>
                    <td>{{u.companyName}}</td>
                    <td> <input type="text" [(ngModel)]="u.toEmail" class="form-control" />
                    </td>
                    <td class="fixw">{{gettext(u.toEmail)}}</td>
                    <td><input type="text" [(ngModel)]="u.ccEmail" class="form-control" /></td>
                    <td class="fixw">{{gettext(u.ccEmail)}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>