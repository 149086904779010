import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { empModel, Role, User } from '../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-emp',
  templateUrl: './emp.component.html',
  styleUrls: ['./emp.component.css']
})
export class EmpComponent implements OnInit {

  customers: empModel[] = [];
  loading = false;
  isAddCustomerClicked: boolean = false;
  error = '';
  submitted = false;
  customerForm: FormGroup;
  get f() { return this.customerForm.controls; }
  searchCustomerName: string = "";
  addEditCustModel: empModel = new empModel();
  currentUser: User;

  constructor(private formBuilder: FormBuilder, private api: RayarApiService,
    private apia: AuthenticationService) {
    this.currentUser = this.apia.currentUserValue;

    this.getCollPesons();
  }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      employeeName: ['', Validators.required],
      phone: [''],
      salary: [''],
      incentive: [''],
    });
  }


  onEditCustomer(u: empModel) {
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      employeeName: u.employeeName,
      phone: u.phone,
      salary: u.salary,
      incentive: u.incentive,
    });
    this.addEditCustModel = u
  }

  onAddCustSubmit() {
    this.submitted = true;
    if (this.customerForm.invalid) return;
    this.loading = true;
    this.addEditCustModel.employeeName = this.f.employeeName.value;
    this.addEditCustModel.phone = this.f.phone.value;
    if (this.currentUser.userName == "Admin"
      || this.currentUser.userName == "mahendran"
      || this.currentUser.userName == "santhanam"){
        this.addEditCustModel.salary = this.f.salary.value;
        this.addEditCustModel.incentive = this.f.incentive.value;
      }

    this.api.AddEditEmp(this.addEditCustModel)
      .subscribe(() => {
        this.submitted = false;
        this.loading = false;
        this.onCustSearchReset();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  SetNoTrack(u: empModel) {
    var c = new empModel();
    c.employeeId = u.employeeId;
    c.track = !u.track;
    this.api.SetNoTrack(c).subscribe(() => {
      this.submitted = false;
      this.loading = false;
      this.getCollPesons();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  onCloseAddCust() {
    this.isAddCustomerClicked = false;
  }

  oAddCustomer() {
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      employeeName: '',
      phone: '',
      salary: 0,
      incentive: 0,
    });
    this.addEditCustModel = new empModel();
    this.addEditCustModel.employeeId = 0;
    this.addEditCustModel.employeeName = '';
    this.addEditCustModel.phone = '';
    this.addEditCustModel.salary = 0;
    this.addEditCustModel.incentive = 0;
  }

  searchTrack: string = "All";

  getcustomers() {
    var s = this.searchTrack;
    var sf = this.searchCustomerName;
    return this.customers.filter(function (hero) {
      return (s == "All" || (s == "Yes" && hero.track) || (s == "No" && !hero.track))
        && (sf == "" || hero.employeeName.toLowerCase().includes(sf));
    });
  }

  onCustSearchReset() {
    this.isAddCustomerClicked = false;
    this.searchCustomerName = "";
    this.getCollPesons();
  }

  getCollPesons() {
    this.api.getEmps()
      .subscribe(result => { this.customers = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }
}
