import { Component, OnDestroy, OnInit } from '@angular/core';
import { EmpAttendanceModel, VehicleModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.css']
})
export class CheckinComponent implements OnInit, OnDestroy {
  time = new Date();
  intervalID: any;
  searchlModel = new EmpAttendanceModel();
  searchActive: string = "";
  selectedDate1 = Date.now();
  selectedDate = Date.now();
  error = '';
  bills: EmpAttendanceModel[] = [];
  vehicles: VehicleModel[] = [];
  loading = false;

  constructor(private api: RayarApiService) {
  }

  ngOnInit(): void {
    this.intervalID = setInterval(() => { this.time = new Date(); }, 1000);
    this.searchlModel.page = 0;
    this.getBills(this.searchlModel.page);
  }


  ngOnDestroy() {
    clearInterval(this.intervalID);
  }

  getBills(p: number) {
    this.searchlModel.fromDate = new Date(this.selectedDate);
    this.searchlModel.toDate = new Date(this.selectedDate1);

    this.error = "";
    this.searchlModel.page = p;
    this.searchlModel.employeeId = Number(this.searchlModel.employeeId);
    this.searchlModel.uniqId = null;
    this.api.getEmpAttendances(this.searchlModel)
      .subscribe(result => {
        this.bills = result;
        this.bills.forEach(x => {
          x.isEdit = false;
        });
      }, error => {
        this.error = error; this.loading = false; console.error(error);
      });
  }

  editgrnData: EmpAttendanceModel;

  editGrn(u: EmpAttendanceModel) {
    this.bills.forEach(x => {
      x.isEdit = u.uniqId == x.uniqId;
      if (u.uniqId == x.uniqId) {
        this.editgrnData = new EmpAttendanceModel();
        this.editgrnData.empAttendanceId = u.empAttendanceId;
        this.editgrnData.dateValue = u.dateValue;
        this.editgrnData.entryDate = u.dateValue;
        this.editgrnData.employeeId = u.employeeId;
        this.editgrnData.reason = u.reason;
        this.editgrnData.checkIn = u.checkIn;
        this.editgrnData.uniqId = u.uniqId;
      }
    });
  }


  saveGrn(u: EmpAttendanceModel) {
    if (!this.editgrnData.checkIn) return;
    this.editgrnData.checkIn = this.editgrnData.checkIn.toString();
    this.api.SaveEmpAttendRemarks(this.editgrnData)
      .subscribe(() => {
        this.loading = false;
        this.getBills(this.searchlModel.page);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  cancelGrn(u: EmpAttendanceModel) {
    this.bills.forEach(x => {
      x.isEdit = false;
    });
  }


}
