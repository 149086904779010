import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { accountModel, accountLedgerModel, grnModel, User, Role, DocumentModel, grnEmailModel, companyModel, collectionDetailModel } from '../models';
import { formatDate } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { AuthenticationService } from '../_services/authentication.service';
import { jsPDF } from 'jspdf';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-billgrn',
  templateUrl: './billgrn.component.html',
  styleUrls: ['./billgrn.component.css']
})
export class BillgrnComponent implements OnInit {
  persons: accountModel[] = [];
  loading = false;
  error = '';
  accledgers: grnModel[] = [];
  searchBillModel: grnModel = new grnModel();;
  currentUser: User;
  companys: companyModel[];
  isAllBills: true;

  constructor(private imageCompress: NgxImageCompressService, private authenticationService: AuthenticationService, private api: RayarApiService) {
    this.getAccounts();
    this.getcompany();
    this.currentUser = this.authenticationService.currentUserValue;
    this.searchBillModel.billStatus = "All";
    this.searchBillModel.accountId = 0;
    this.searchBillModel.balance = 0;
    this.searchBillModel.grnStatus = "All";
    this.searchBillModel.poStatus = "All";
    this.searchBillModel.billDays = 3650;
    this.isAllBills = true;
    this.searchBillModel.balance = -100000000;
  }

  ngOnInit(): void {
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Enter") {
      this.onCollBillSearch(0);
    }
  }

  postShortage(u: grnModel) {
    if (u.shortage > 0 && u.shortageRemarks)
      this.api.oncollectionMTIR(u).subscribe(result => {
        this.onCollBillSearch(this.pagenumber);
      },
        error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
  }

  getcompany() {
    this.api.getCompanys().subscribe(result => {
      this.companys = result;
      this.searchBillModel.companyName = "All";
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  onPaymentRequest(o: string) {
    if (!this.searchBillModel.accountId) return;
    var sels = this.accledgers.filter(function (hero) {
      return hero.selected;
    });
    if (sels.length <= 0) return;
    var mtemailModel = new grnEmailModel();
    mtemailModel.accountId = parseInt(this.searchBillModel.accountId.toString());
    mtemailModel.operation = o;
    mtemailModel.grnModels = sels;
    this.api.MtGrnEmail(mtemailModel).subscribe(result => {
      this.error = o + ' EMail Success';
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  @ViewChild('inputFile') inputFile: ElementRef;
  imageSrc: string;
  fileType: string;
  fileSource: any = null;

  selAcled: grnModel = new grnModel();

  selectledger(u: grnModel) {
    this.selAcled = u;
  }

  upload(event) {
    this.error = "";
    this.fileType = null;
    this.fileSource = null;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      this.accledgers.forEach(x => {
        if (this.selAcled.billId == x.billId)
          x.showDetail = true;
        else
          x.showDetail = false;
      });
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          this.imageSrc = reader.result as string;
          this.imageCompress.compressFile(this.imageSrc, 50, 100).then((compressedImage) => {
            this.fileType = "Image";
            this.getDocuments();
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            this.fileSource = doc.output('blob');
          });
        }
        else {
          this.imageSrc = null;
          if (file.type.startsWith("application/pdf")) {
            this.api.uploadToBlob(file, "UploadBill/" + this.selAcled.billId + "/" + this.selAcled.billNumber + "/GRN").subscribe(
              data => {
                if (data) {
                  switch (data.type) {
                    case HttpEventType.UploadProgress:
                      break;
                    case HttpEventType.Response:
                      this.inputFile.nativeElement.value = '';
                      this.fileType = null;
                      this.fileSource = null;
                      this.imageSrc = null;
                      this.getDocuments();
                      break;
                  }
                }
              },
              error => {
                this.inputFile.nativeElement.value = '';
              }
            );
          } else {
            this.error = "Invalid File format images/PDF only"
          }
        }
      };
    }
  }

  onUploadCancel() {
    this.fileType = null;
    this.fileSource = null;
    this.imageSrc = null;
    this.inputFile.nativeElement.value = '';
  }

  onUpload() {
    this.api.uploadToBlob(this.fileSource, "UploadBill/" + this.selAcled.billId + "/" + this.selAcled.billNumber + "/GRN").subscribe(
      data => {
        if (data) {
          switch (data.type) {
            case HttpEventType.UploadProgress:
              break;
            case HttpEventType.Response:
              this.inputFile.nativeElement.value = '';
              this.fileType = null;
              this.fileSource = null;
              this.imageSrc = null;
              this.getDocuments();
              break;
          }
        }
      },
      error => {
        this.inputFile.nativeElement.value = '';
      }
    );
  }

  selectChangeHandler3(event: any) {
    this.searchBillModel.accountId = parseInt(event.target.value);
  }

  onclickIncbal() {
    if (!this.isAllBills) {
      this.searchBillModel.balance = -100000000;
    } else {
      this.searchBillModel.balance = 0;
    }
  }

  onCollBillSearch(p: number) {
    this.pagenumber = p;
    this.selAcled = new grnModel();
    this.searchBillModel.page = p;
    if (this.searchBillModel.poNumber)
      this.searchBillModel.poNumber = this.searchBillModel.poNumber.trim();
    if (this.searchBillModel.accountId && this.searchBillModel.accountId > 0)
      this.searchBillModel.accountId = parseInt(this.searchBillModel.accountId.toString());
    if (this.isAllBills) {
      this.searchBillModel.balance = -100000000;
    } else {

    }
    this.api.getGrn(this.searchBillModel).subscribe(result => {
      this.accledgers = result;
      this.error = '';
      this.accledgers.forEach(x => {
        x.isEdit = false;
      });
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  pagenumber = 0;
  onpage(next: boolean) {
    if (next) {
      this.onCollBillSearch(this.pagenumber + 1);
    }
    else {
      if (this.pagenumber >= 1)
        this.onCollBillSearch(this.pagenumber - 1);
    }
  }


  editGrn(u: grnModel) {
    this.accledgers.forEach(x => {
      x.isEdit = u.uniqId == x.uniqId;
      if (u.uniqId == x.uniqId) {
        this.editgrnData = new grnModel();
        this.editgrnData.grnNumber = u.grnNumber;
        this.editgrnData.remarks = u.remarks;
        this.editgrnData.billId = u.billId;
        this.editgrnData.grnStatus = u.grnStatus;
      }
    });
  }

  editgrnData: grnModel;

  saveGrn() {
    var d = new accountLedgerModel();
    d.id = this.editgrnData.billId;
    d.grnNumber = this.editgrnData.grnNumber;
    d.remarks = this.editgrnData.remarks;
    d.grnStatus = this.editgrnData.grnStatus;

    d.crDr = "Bill";
    this.api.SaveGrnDetail(d).subscribe(() => {
      this.loading = false;
      this.onCollBillSearch(this.pagenumber);
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });

  }

  markPaid(u: grnModel) {
    this.error = '';
    this.api.markPaid(u.billId).subscribe(() => { this.onCollBillSearch(this.pagenumber); }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  cancelGrn() {
    this.accledgers.forEach(x => {
      x.isEdit = false;
    });
  }

  getAccounts() {
    this.api.getAccounts().subscribe(result => {
      this.persons = result;
      this.persons.sort((a, b) => a.accountName.localeCompare(b.accountName));
      this.searchBillModel.accountId = this.persons[0].accountId;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  suppliedDocs: DocumentModel[] = [];
  collectionDetails: collectionDetailModel[] = [];

  getDocuments() {
    this.api.getDcouments(this.selAcled.billId, "ALL")
      .subscribe(result => this.suppliedDocs = result, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  getmtcollectiondetails() {
    this.api.getmtcollectiondetails(this.selAcled.billId)
      .subscribe(result => this.collectionDetails = result, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onDeleteDocument(i: number) {
    this.api.deleteDocument(i)
      .subscribe(result => this.getDocuments(), error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onDownloadDocument(i: number, f: string) {
    this.api.downloadBlobFilePost("Download/" + i, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = f;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }

  downloadBills(u: grnModel) {
    this.api.downloadBlobFilePost("Download1/" + u.billId + "/ALL", null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            var fileName = u.billId + ".jpg";
            if (data.body.type == "application/zip") {
              fileName = u.billId + ".zip"
            } else if (data.body.type == "application/pdf") {
              fileName = u.billId + ".pdf"
            }
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );

  }

  showDetail(u: grnModel) {
    this.accledgers.forEach(x => {
      if (u.billId == x.billId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.selAcled = u;
          this.getDocuments();
          this.getmtcollectiondetails();
        }
      }
      else
        x.showDetail = false;
    });
  }


  download() {
    if (this.searchBillModel.accountId && this.searchBillModel.accountId > 0)
      this.searchBillModel.accountId = parseInt(this.searchBillModel.accountId.toString());
    var q = 'MtGrnReport';
    this.api.downloadFilePost(q, this.searchBillModel).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }

}
