<div class="row">
    <div class="col">
        <div class="form-row">
            <div class="col-md-3">
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Collected By</th>
                            <th scope="col">Cash Amount</th>
                            <th scope="col">Cheque Amount</th>
                            <th scope="col">Cheque Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let u of collsSum">
                            <td>{{u.collectedBy}}</td>
                            <td>{{u.billValue}}</td>
                            <td>{{u.collectionId}}</td>
                            <td>{{u.billId}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-9">
                <div class="row">
                    <div class="col">
                        <label>Bill Number</label>
                        <input class="form-control" type="text" [(ngModel)]="searchBillModel.billNumber" />
                    </div>
                    <div class="col">
                        <label>Customer/Account</label>
                        <input class="form-control" type="text" [(ngModel)]="searchBillModel.customerName" />
                    </div>
                    <div class="col">
                        <label>Bill AssignedTo</label>
                        <input class="form-control" type="text" [(ngModel)]="searchBillModel.personName" />
                    </div>
                    <div class="col">
                        <label>From</label>
                        <input type="date" [ngModel]="searchBillModel.fromDate | date:'yyyy-MM-dd'"
                            (change)="selectChangeHandler($event)" class="form-control" />
                    </div>
                    <div class="col">
                        <label>To</label>
                        <input type="date" [ngModel]="searchBillModel.toDate | date:'yyyy-MM-dd'"
                            (change)="selectChangeHandler1($event)" class="form-control" />
                    </div>
                    <div class="col">
                        <label for="mode">Mode</label>
                        <select class="form-control" [(ngModel)]="searchBillModel.billStatus"
                            (change)="selectChangeHandler3($event)">
                            <option selected value="">All</option>
                            <option value="Cash">Cash</option>
                            <option value="CashDiscount">CashDiscount</option>
                            <option value="Cheque">Cheque</option>
                            <option value="Damage">Damage</option>
                            <option value="MobileTransfer">MobileTransfer</option>
                            <option value="NEFT">NEFT</option>
                            <option value="Shortage">Shortage</option>
                        </select>
                    </div>
                    <div class="col">
                        <label for="collectedBy">Collected By</label>
                        <select class="form-control" [(ngModel)]="searchBillModel.personId">
                            <option value="0" selected>All</option>
                            <option *ngFor="let p of persons1" [value]="p.personId">{{p.name}}</option>
                        </select>
                    </div>
                    <div class="col">
                        <label></label>
                        <div>
                            <button class="btn btn-sm btn-info" (click)="onSearchPerson()">Search</button>&nbsp;
                            <button class="btn btn-sm btn-info" (click)="onReset()">Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col"><input type="checkbox" [(ngModel)]="selectedconfig"
                                (change)="selectChangeHandlercheck($event)" /> &nbsp;<button *DataCustomAclUsers="'Admin:mahendran:santhanam'"
                                class="btn btn-sm btn-success" (click)="autoReview()"><i class="fa fa-eye"></i></button>
                        </th>
                        <th *ngIf="selectedconfig" scope="col">Code</th>
                        <th scope="col">Mode</th>
                        <th scope="col">Bill Num</th>
                        <th scope="col">Bill Value</th>
                        <th scope="col">Collected</th>
                        <th scope="col">Created</th>
                        <th scope="col">CheckDetails</th>
                        <th scope="col">remarks</th>
                        <th scope="col">IRNumber</th>
                        <th scope="col">EntryDate</th>
                        <th scope="col">CollectedBy</th>
                        <th scope="col">Company</th>
                        <th scope="col">Customer Name</th>
                        <th scope="col">Bill Date</th>
                        <th scope="col">Assign Person</th>
                    </tr>
                </thead>
                <tbody *ngFor="let u of getData()">
                    <tr>
                        <th scope="row">

                            <button class="btn btn-sm btn-info" (click)="markReceived(u)">Received</button>&nbsp;
                            <button class="btn btn-sm btn-success" (click)="showDetail(u)">
                                <i class="fa fa-share-square-o"></i></button> &nbsp;
                            <button class="btn btn-sm btn-success" (click)="downloadBills(u)">
                                <i class="fa fa-download"></i></button>
                        </th>
                        <td *ngIf="selectedconfig">{{u.autoReviewCode}}</td>
                        <td>{{u.mode}}</td>
                        <td>{{u.billNumber}}</td>
                        <td>{{u.billValue}}</td>
                        <td>{{u.collectionValue}}</td>
                        <td>{{u.createdBy}}</td>
                        <td class="elip">{{u.chequeDate| date: 'dd/MM/yyyy'}} {{u.chequeNumber}}</td>
                        <td class="elip">{{u.remarks}}</td>
                        <td>{{u.damageIrNumber}}</td>
                        <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.collectedBy}}</td>
                        <td>{{u.companyName}}</td>
                        <td class="elip">{{u.customerName}}</td>
                        <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.personName}}</td>
                    </tr>
                    <tr *ngIf="u.showDetail">
                        <td colspan="14">
                            <table class="table table-striped table-sm table-responsive table-condensed">
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">Collection Date</th>
                                        <th scope="col">Collection Value</th>
                                        <th scope="col">Mode</th>
                                        <th scope="col">CollectedBy</th>
                                        <th scope="col">Remarks</th>
                                        <th scope="col">ChequeDate</th>
                                        <th scope="col">ChequeNumber</th>
                                        <th scope="col">Cheque Credited Date</th>
                                        <th scope="col">Bounced</th>
                                        <th scope="col">Bounced FollowedUp</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let u of billscolls">
                                        <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                                        <td>{{u.collectionValue}}</td>
                                        <td>{{u.mode}}</td>
                                        <td>{{u.collectedBy}}</td>
                                        <td>{{u.remarks}}</td>
                                        <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
                                        <td>{{u.chequeNumber}}</td>
                                        <td>{{u.creditedDate| date: 'dd/MM/yyyy'}}</td>
                                        <td>{{u.bounced}}</td>
                                        <td>{{u.bouncedFollowedUp}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>