
import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { BillProductInvModel, companyModel, ProductModel } from '../models';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-produts',
  templateUrl: './produts.component.html',
  styleUrls: ['./produts.component.css']
})
export class ProdutsComponent implements OnInit {

  loading = false;
  error = '';
  submitted = false;
  companys: companyModel[];
  search: ProductModel = new ProductModel();
  customers: ProductModel[] = [];
  customers1: BillProductInvModel[] = [];
  ae: ProductModel = new ProductModel();

  getcompany() {
    this.api.getCompanys().subscribe(result => { this.companys = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  constructor(private api: RayarApiService) {
    this.search.page = 0;
    this.search.pageSize = 100;
    this.companys = [];
    this.getcompany();
  }

  ngOnInit(): void {
  }

  onAddCustSubmit(u: ProductModel) {
    this.submitted = true;
    this.loading = true;
    if (u.category && u.productName && u.crate && u.srate && u.mrp && u.hsno && u.noPcs && u.barCode && u.defaultMargin) {
      u.crate = parseFloat(u.crate.toString());
      u.srate = parseFloat(u.srate.toString());
      u.mrp = parseFloat(u.mrp.toString());
      u.noPcs = parseInt(u.noPcs.toString());
      if (isNaN(u.crate) || isNaN(u.srate) || isNaN(u.mrp) || isNaN(u.noPcs)) return;
      this.api.AddEditProduct(u).subscribe(() => {
        this.submitted = false;
        this.loading = false;
        this.onNameCustomerSearch(this.search.page);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    }
  }

  onEdit(u: ProductModel) {
    this.customers.forEach(x => {
      x.showDetail = false;
    });
    this.customers.forEach(x => {
      if (u.productId == x.productId) {
        x.isEdit = !x.isEdit;
        if (x.isEdit) {

        }
      }
      else
        x.isEdit = false;
    });
    this.ae.isEdit = false;
  }

  cancelGrn() {
    this.customers.forEach(x => {
      x.isEdit = false;
    });
    this.ae.isEdit = false;
  }

  oAddCustomer() {
    this.cancelGrn()
    this.ae = new ProductModel();
    this.ae.productId = 0;
    this.ae.isEdit = true;
    this.customers.forEach(x => {
      x.showDetail = false;
    });
  }

  onpage(next: boolean) {
    if (next) {
      this.onNameCustomerSearch(this.search.page + 1);
    }
    else {
      if (this.search.page >= 1)
        this.onNameCustomerSearch(this.search.page - 1);
    }
  }

  showDetails(u: ProductModel) {
    this.customers.forEach(x => {
      x.isEdit = false;
    });
    this.ae.isEdit = false;
    this.customers.forEach(x => {
      if (u.productId == x.productId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.getBillProdtsInv(u.productId);
        }
      }
      else
        x.showDetail = false;
    });
  }

  onNameCustomerSearch(p: number) {
    this.ae.isEdit = false;
    this.search.page = p;
    this.api.getProducts(this.search).subscribe(result => { this.customers = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  getBillProdtsInv(p: number) {
    var search = new ProductModel();
    search.productId = p;
    this.api.getBillProdtsInv(search).subscribe(result => { this.customers1 = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  
  downloadReport() {
    this.api.downloadFilePost("ProductsDownload", this.search).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = "ProductsDownload.csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }


}
