<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-md-1">
                <label>Product Name</label>
                <input class="form-control" type="text" [(ngModel)]="search.productName" />
            </div>
            <div class="col-md-1">
                <label>Category</label>
                <select class="form-control" [(ngModel)]="search.category">
                    <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label>Prd Qty Nearing MinQty(Avl-Min)</label>
                <input class="form-control" type="number" [(ngModel)]="search.availableQuantity" />
            </div>
            <div class="col-md-1">
                <label></label><br>
                <div>
                    <button class="btn btn-sm btn-info" (click)="onNameCustomerSearch(0)">Search</button>
                    <button class="btn btn-sm btn-info" (click)="downloadReport()">Download</button>

                </div>
            </div>
        </div><br>
        <nav aria-label="...">
            <ul class="pagination justify-content-end">
                <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a></li>
                <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                <li class="page-item">
                    <span class="page-link">Page: {{search.page+1}}</span>
                </li>
            </ul>
        </nav>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">
                        <button class="btn btn-sm btn-info" (click)="oAddCustomer()"><i class="fa fa-plus"></i></button>
                    </th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Company</th>
                    <th scope="col">Product </th>
                    <th scope="col">BarCode </th>
                    <th scope="col">DefaultMargin</th>
                    <th scope="col">MRP</th>
                    <th scope="col">CGST</th>
                    <th scope="col">SGST</th>
                    <th scope="col">HSNo</th>
                    <th scope="col">Pcs</th>
                    <th scope="col">Min Order Qty</th>
                    <th scope="col">Inventory Qty</th>
                    <th scope="col">Purchase Qty</th>
                    <th scope="col">Bill Qty</th>
                    <th scope="col">Available Qty</th>
                    <th scope="col">Inv Verified Date</th>
                    <th scope="col">Inv Verified By</th>
                </tr>
            </thead>
            <tbody *ngIf="ae.isEdit">
                <tr>
                    <td></td>
                    <td>
                        <button class="btn btn-sm btn-info" (click)="onAddCustSubmit(ae)">Save</button>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-info" *ngIf="ae.isEdit" (click)="cancelGrn()">Cancel</button>
                    </td>
                    <td>
                        <select class="form-control form-control-sm" [(ngModel)]="ae.category">
                            <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
                        </select>
                    </td>
                    <td>
                        <input type="text" [(ngModel)]="ae.productName" class="form-control form-control-sm" />
                    </td>
                    <td>
                        <input type="text" [(ngModel)]="ae.barCode" class="form-control form-control-sm" />
                    </td>
                    <td>
                        <input type="text" [(ngModel)]="ae.defaultMargin" appTwoDigitDecimaNumber
                            class="form-control form-control-sm" />
                    </td>
                    <td>
                        <input type="text" [(ngModel)]="ae.mrp" appTwoDigitDecimaNumber
                            class="form-control form-control-sm">
                    </td>
                    <td>
                        <input type="text" [(ngModel)]="ae.crate" appTwoDigitDecimaNumber
                            class="form-control form-control-sm" />
                    </td>
                    <td>
                        <input type="text" [(ngModel)]="ae.srate" appTwoDigitDecimaNumber
                            class="form-control form-control-sm" />
                    </td>
                    <td>
                        <input type="text" [(ngModel)]="ae.hsno" class="form-control form-control-sm" />
                    </td>
                    <td>
                        <input type="number" [(ngModel)]="ae.noPcs" min="1" class="form-control form-control-sm" />
                    </td>
                    <td>
                        <input type="number" [(ngModel)]="ae.minOrderQuantity" min="1"
                            class="form-control form-control-sm" />
                    </td>
                </tr>
            </tbody>
            <tbody *ngFor="let u of customers">
                <tr>
                    <td>
                        <i class="fa fa-angle-down" (click)="showDetails(u)"></i>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-info" *ngIf="!u.isEdit" (click)="onEdit(u)">Edit</button>
                        <button class="btn btn-sm btn-info" *ngIf="u.isEdit" (click)="onAddCustSubmit(u)">Save</button>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-info" *ngIf="u.isEdit" (click)="cancelGrn()">Cancel</button>
                    </td>
                    <td>
                        {{u.category }}
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.productName }}</div>
                        <input type="text" *ngIf="u.isEdit" [(ngModel)]="u.productName"
                            class="form-control form-control-sm" />
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.barCode }}</div>
                        <input type="textbox" *ngIf="u.isEdit" [(ngModel)]="u.barCode"
                            class="form-control form-control-sm">
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.defaultMargin }}</div>
                        <input type="textbox" *ngIf="u.isEdit" [(ngModel)]="u.defaultMargin" appTwoDigitDecimaNumber
                            class="form-control form-control-sm">
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.mrp }}</div>
                        <input type="textbox" *ngIf="u.isEdit" [(ngModel)]="u.mrp" appTwoDigitDecimaNumber
                            class="form-control form-control-sm">
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.crate }}</div>
                        <input type="text" *ngIf="u.isEdit" [(ngModel)]="u.crate" appTwoDigitDecimaNumber
                            class="form-control form-control-sm" />
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.srate }}</div>
                        <input type="text" *ngIf="u.isEdit" [(ngModel)]="u.srate" appTwoDigitDecimaNumber
                            class="form-control form-control-sm" />
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.hsno }}</div>
                        <input type="text" *ngIf="u.isEdit" [(ngModel)]="u.hsno" class="form-control form-control-sm" />
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.noPcs }}</div>
                        <input type="number" *ngIf="u.isEdit" [(ngModel)]="u.noPcs" min="1"
                            class="form-control form-control-sm" />
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.minOrderQuantity }}</div>
                        <input type="number" *ngIf="u.isEdit" [(ngModel)]="u.minOrderQuantity" min="1"
                            class="form-control form-control-sm" />
                    </td>
                    <td>{{u.inventoryQuantity}}</td>
                    <td>{{u.purchaseQuantity}}</td>
                    <td>{{u.billQuantity}}</td>
                    <td>{{u.availableQuantity}}</td>
                    <td>{{u.inventoryVerifiedDate | date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.inventoryVerifiedBy}}</td>
                </tr>
                <tr *ngIf="u.showDetail">
                    <td colspan="20">
                        <div>
                            <table class="table table-striped table-sm table-responsive table-condensed">
                                <thead>
                                    <tr>
                                        <th scope="col">Running Total</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Bill Invoice#</th>
                                        <th scope="col">Bill Invoice Date</th>
                                        <th scope="col">Bill Status</th>
                                        <th scope="col">Bill Value</th>
                                        <th scope="col">C/S Rate</th>
                                        <th scope="col">Margin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{u.inventoryQuantity}}
                                        </td>
                                        <td colspan="7">
                                            Inventory Qty
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngFor="let u of customers1">
                                    <tr>
                                        <td>
                                            {{u.runningTotal}}
                                        </td>
                                        <td>
                                            {{u.quantity}}
                                        </td>
                                        <td>
                                            {{u.invoiceNumber}}{{u.billNumber}}
                                        </td>
                                        <td>
                                            {{u.invoiceDate| date: 'dd/MM/yyyy'}}{{u.billDate| date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{u.billStatus}}
                                        </td>
                                        <td>
                                            {{u.billValue}}
                                        </td>
                                        <td>
                                            {{u.crate}}/{{u.srate}}
                                        </td>
                                        <td>
                                            {{u.margin}}
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{u.availableQuantity}}
                                        </td>
                                        <td colspan="7">
                                            Available Qty
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>