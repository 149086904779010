import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { VehicleModel, DocumentTrackModel, DocumentModel } from '../models';
import { HttpEventType } from '@angular/common/http';
import { NgxImageCompressService } from 'ngx-image-compress';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css']
})
export class VehicleComponent implements OnInit {

  customers1: DocumentTrackModel[] = [];
  customers: VehicleModel[] = [];
  loading = false;
  error = '';
  submitted = false;
  search: VehicleModel = new VehicleModel();
  addEditCustModel: VehicleModel = new VehicleModel();

  constructor(private imageCompress: NgxImageCompressService, private api: RayarApiService) {
    this.search.page = 0;
    this.search.category = 'Vehicle';
    this.onNameCustomerSearch(0);
  }

  ngOnInit(): void {
  }

  editGrn(u: VehicleModel) {
    this.addEditCustModel.isEdit = false;
    this.customers.forEach(x => {
      x.showDetail = false;
      x.isEdit = u.vehicleId == x.vehicleId;
    });
  }

  cancelGrn() {
    this.addEditCustModel.isEdit = false;
    this.customers.forEach(x => {
      x.isEdit = false;
      x.showDetail = false;
    });
  }

  saveGrn(u: VehicleModel) {
    u.remarks = u.vehicleName;
    if (u.category && u.vehicleName)
      this.api.AddEditVehicle(u).subscribe(() => {
        this.loading = false;
        this.addEditCustModel.isEdit = false;
        this.onNameCustomerSearch(this.search.page);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  oAddCustomer() {
    this.cancelGrn();
    this.addEditCustModel = new VehicleModel();
    this.addEditCustModel.isEdit = true;
    this.addEditCustModel.vehicleId = 0;
  }

  onNameCustomerSearch(p: number) {
    this.search.page = p;
    this.api.getSearchVehicle(this.search)
      .subscribe(result => { this.customers = result.vehicles; this.cancelGrn(); }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  getTracks(u: number) {
    var d = new DocumentTrackModel();
    d.vehicleId = u;
    d.page = 0;
    this.api.getDocumentTracks(d)
      .subscribe(result => { this.customers1 = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  showDetail(u: VehicleModel) {
    this.addEditCustModel1.isEdit = false;
    this.customers.forEach(x => {
      if (u.vehicleId == x.vehicleId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.getTracks(u.vehicleId);
        }
      }
      else
        x.showDetail = false;
    });
  }

  //

  addEditCustModel1: DocumentTrackModel = new DocumentTrackModel();

  editGrn1(u: DocumentTrackModel) {
    this.addEditCustModel1.isEdit = false;
    this.customers1.forEach(x => {
      x.showDetail = false;
      x.isEdit = u.documentTrackId == x.documentTrackId;
    });
  }

  cancelGrn1() {
    this.addEditCustModel1.isEdit = false;
    this.customers1.forEach(x => {
      x.isEdit = false;
      x.showDetail = false;
    });
  }

  saveGrn1(u: DocumentTrackModel) {
    if (u.vehicleId && u.vehicleId > 0 && u.documentName && u.validTillDate)
      this.api.AddEditDocTrack(u).subscribe(() => {
        this.loading = false;
        this.addEditCustModel1.isEdit = false;
        this.getTracks(u.vehicleId);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  oAddCustomer1(u: VehicleModel) {
    this.cancelGrn1();
    this.addEditCustModel1 = new DocumentTrackModel();
    this.addEditCustModel1.isEdit = true;
    this.addEditCustModel1.vehicleId = u.vehicleId;
  }

  sel: DocumentTrackModel = new DocumentTrackModel();
  showDetail1(u: DocumentTrackModel) {
    this.customers1.forEach(x => {
      if (u.documentTrackId == x.documentTrackId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.sel = x;
          this.getDocuments();
        }
      }
      else
        x.showDetail = false;
    });
  }

  getDocs(u: VehicleModel) {
    if (u.category == "Vehicle") {
      var ret = [];
      var sel = []
      this.customers1.forEach(x => {
        sel.push(x.documentName);
      });
      ["RCBOOK", "POLUTINCERT", "INSURANCE", "FC", "Service", "VGENDOC1", "VGENDOC2", "VGENDOC3"].forEach(x => {
        if (sel.includes(x)) {
        } else {
          ret.push(x);
        }
      });
      return ret;
    } else {
      var ret = [];
      var sel = []
      this.customers1.forEach(x => {
        sel.push(x.documentName);
      });
      ["PAN", "RC", "FSSI", "CANCLCHQ", "MSME","MS:GodownRentTDS","RT:SubmitSalesGST","MS:TDS Filing", "CGENDOC1", "CGENDOC2", "CGENDOC3"].forEach(x => {
        if (sel.includes(x)) {
        } else {
          ret.push(x);
        }
      });
      return ret;
    }
  }

  setupload(u: DocumentTrackModel) {
    this.sel = u;
  }

  suppliedDocs: DocumentModel[] = [];

  getDocuments() {
    this.api.getDcouments(this.sel.documentTrackId, this.sel.documentName)
      .subscribe(result => this.suppliedDocs = result, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onDeleteDocument(i: number) {
    this.api.deleteDocument(i)
      .subscribe(result => this.getDocuments(), error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onDownloadDocument(i: number, f: string) {
    this.api.downloadBlobFilePost("Download/" + i, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = f;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }

  @ViewChild('inputFile') inputFile: ElementRef;
  imageSrc: string;

  upload(event) {
    this.error = "";
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          this.imageSrc = reader.result as string;
          this.imageCompress.compressFile(this.imageSrc, 50, 100).then((compressedImage) => {
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            this.uploaddoc(doc.output('blob'));
          });
        }
        else {
          this.imageSrc = null;
          if (file.type.startsWith("application/pdf")) {
            this.uploaddoc(file);
          } else {
            this.error = "Invalid File format images/PDF only"
          }
        }
      };
    }
  }

  uploaddoc(file: Blob) {
    this.api.uploadToBlob(file, "UploadBill/" + this.sel.documentTrackId + "/" + this.sel.documentTrackId + "/" + this.sel.documentName).subscribe(
      data => {
        if (data) {
          switch (data.type) {
            case HttpEventType.UploadProgress:
              break;
            case HttpEventType.Response:
              this.inputFile.nativeElement.value = '';
              this.imageSrc = null;
              this.getDocuments();
              break;
          }
        }
      },
      error => {
        this.inputFile.nativeElement.value = '';
      }
    );
  }
}
