<div class="overlay" [hidden]="!loading">
    <div class="centered">
        <div class="spinner-grow text-primary"></div>
        <div class="spinner-grow text-success"></div>
        <div class="spinner-grow text-info"></div>
        <div class="spinner-grow text-warning"></div>
        <div class="spinner-grow text-danger"></div>
    </div>
</div>
<nav class="navbar navbar-expand-md navbar-dark bg-dark" *ngIf="currentUser">
    <a class="navbar-brand pb-2" routerLink="/">Home</a>
    <button (click)="toggleNavbarCollapsing()" class="navbar-toggler" type="button" data-toggle="collapse"
        data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown" [class.collapse]="navbarCollapsed">
        <ul class="navbar-nav">
            <li class="nav-item dropdown" *DataCustomAclOnly="'PAdmin:MN'">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"> Madhan </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li><a class="dropdown-item" routerLink="/pmc">Chits</a></li>
                    <li><a class="dropdown-item" routerLink="/datr">Accounts</a></li>
                </ul>
            </li>
            <li class="nav-item dropdown" *DataCustomAclOnly="'PAdmin:MN:TI'">
                <a class="nav-link dropdown-toggle" routerLink="/activ" id="navbarDropdownMenuLink"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Activity </a>
            </li>
            <li class="nav-item dropdown" *DataCustomAclOnly="'PAdmin:TI'">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"> Manage </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li><a class="dropdown-item" routerLink="/hrchits">Chits</a></li>
                    <li><a class="dropdown-item" routerLink="/datr1">Accounts</a></li>
                    <li><a class="dropdown-item" routerLink="/rana">RanaResidency</a></li>
                </ul>
            </li>
            <li class="nav-item dropdown" *DataCustomAcl="'Employee:Sales:Driver:Company:ReadOnly'">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"> Bills </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li *DataCustomAcl="'Employee'">
                        <a class="dropdown-item" routerLink="/acolls">Add Collection</a>
                    </li>
                    <li *DataCustomAcl="'Employee:Sales:Company:ReadOnly'">
                        <a class="dropdown-item" routerLink="/bpl">Plan</a>
                    </li>
                    <li *DataCustomAcl="'Employee:Sales:ReadOnly'">
                        <a class="dropdown-item" routerLink="/bpl1">Pending BillPlan</a>
                    </li>
                    <li *DataCustomAcl="'Driver:Sales:Company'">
                        <a class="dropdown-item" routerLink="/drve">Route</a>
                    </li>
                    <li *DataCustomAcl="'Customer'">
                        <a class="dropdown-item" routerLink="/bills1">Order</a>
                    </li>
                    <li *DataCustomAcl="'Employee'">
                        <a class="dropdown-item" routerLink="/colls">Modify/ViewCollection</a>
                    </li>
                    <li *DataCustomAcl="'Employee:ReadOnly'">
                        <a class="dropdown-item" routerLink="/vwBill">ViewBills</a>
                    </li>
                    <li *DataCustomAcl="'Employee'">
                        <a class="dropdown-item" routerLink="/vwCols">View Collections</a>
                    </li>
                    <li *DataCustomAcl="'Employee'">
                        <a class="dropdown-item" routerLink="/bakprnt">Xerox Status</a>
                    </li>
                    <li *DataCustomAcl="'Employee:Sales:Company'">
                        <a class="dropdown-item" routerLink="/aldg1">MT GRN</a>
                    </li>
                    <li *DataCustomAcl="'Employee'">
                        <a class="dropdown-item" routerLink="/mtpayre">MT Collections</a>
                    </li>
                    <li *DataCustomAcl="'Employee'">
                        <a class="dropdown-item" routerLink="/fv">FullView</a>
                    </li>
                    <li *DataCustomAcl="'Sales:Company'">
                        <a class="dropdown-item" routerLink="/smr">SalesMan RoutePlan</a>
                    </li>
                    <li *DataCustomAcl="'Sales:Company'">
                        <a class="dropdown-item" routerLink="/smrc">SalesMan RouteConfig</a>
                    </li>
                    <li *DataCustomAcl="'Driver:Sales:ReadOnly'">
                        <a class="dropdown-item" routerLink="/ear">Attendance</a>
                    </li>
                    <li *DataCustomAcl="'Driver:Employee'">
                        <a class="dropdown-item" routerLink="/vch">Vehicles</a>
                    </li>
                    <li *DataCustomAcl="'Employee:Sales:Company'">
                        <a class="dropdown-item" routerLink="/i100">Inventory</a>
                    </li>
                    <li *DataCustomAcl="'Employee:Sales:Company'">
                        <a class="dropdown-item" routerLink="/i200">Sales</a>
                    </li>
                    <li *DataCustomAcl="'Employee:Sales:Company'">
                        <a class="dropdown-item" routerLink="/dash">Dashboard</a>
                    </li>
                    <li *DataCustomAcl="'Admin'">
                        <a class="dropdown-item" routerLink="/notesView">Notes</a>
                    </li>
                    <li *DataCustomAcl="'Sales'">
                        <a class="dropdown-item" routerLink="/scols">Collections</a>
                    </li>
                    <li *DataCustomAcl="'Employee:Sales:Company'">
                        <a class="dropdown-item" routerLink="/invledger">Ledger</a>
                    </li>
                </ul>
            </li>
            <!--<li class="nav-item dropdown" *DataCustomAcl="'Driver:Employee'">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"> Attendance </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li><a class="dropdown-item" *DataCustomAcl="'Driver:Employee'" routerLink="/aci">CheckIn</a></li>
                    <li><a class="dropdown-item" *DataCustomAcl="'Driver:Employee'" routerLink="/aco">CheckOut</a></li>
                    <li><a class="dropdown-item" *DataCustomAcl="'Employee'" routerLink="/acrv">View</a></li>
                </ul>
            </li>-->
            <li class="nav-item dropdown" *DataCustomAcl="'Employee'">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"> Reports </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li><a class="dropdown-item" routerLink="/acrv">Delivery</a></li>
                    <li><a class="dropdown-item" routerLink="/aldg">Ledger Balance</a></li>
                    <li><a class="dropdown-item" routerLink="/cldg">Customer Balance</a></li>
                    <li><a class="dropdown-item" routerLink="/collRpt">Collections Report</a></li>
                    <li><a class="dropdown-item" routerLink="/custRpt">Bills Report</a></li>
                </ul>
            </li>
            <li class="nav-item dropdown" *DataCustomAcl="'Employee'">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">Employee</a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li><a class="dropdown-item" routerLink="/bills">Bill</a></li>
                    <li><a class="dropdown-item" routerLink="/agnColl">Assign Collection</a></li>
                    <li><a class="dropdown-item" routerLink="/collRev">Collection Review</a></li>
                    <li><a class="dropdown-item" routerLink="/exp">Expense Track</a></li>
                    <li><a class="dropdown-item" routerLink="/dmgtk">Damage Track</a></li>
                    <li><a class="dropdown-item" routerLink="/purInv">Purchase Invoice</a></li>
                    <li><a class="dropdown-item" routerLink="/custs">Customers</a></li>
                    <li><a class="dropdown-item" routerLink="/custs2">Customer Margin View</a></li>
                    <li><a class="dropdown-item" routerLink="/prnepi">PRN Review</a></li>
                    <li><a class="dropdown-item" routerLink="/prnepi1">PRN</a></li>
                </ul>
            </li>
            <li class="nav-item dropdown" *DataCustomAcl="'Admin'">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">Admin</a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li><a class="dropdown-item" routerLink="/collRec">Collection Review</a></li>
                    <li><a class="dropdown-item" routerLink="/closRev">Closing Review</a></li>
                    <li><a class="dropdown-item" routerLink="/bnkSt">Bank Statement</a></li>
                    <li><a class="dropdown-item" routerLink="/bnkSt1">Bank Stmt Review</a></li>
                    <li><a class="dropdown-item" routerLink="/chkRev">Cheque Review</a></li>
                    <li><a class="dropdown-item" routerLink="/bncChk">Bounced Cheque</a></li>
                    <li><a class="dropdown-item" routerLink="/invent">Inventory</a></li>
                    <li><a class="dropdown-item" routerLink="/comLed">Profit Report</a></li>
                    <li><a class="dropdown-item" routerLink="/user">App Users</a></li>
                    <li><a class="dropdown-item" routerLink="/mtes">MTEmailSetting</a></li>
                    <li><a class="dropdown-item" routerLink="/mtbks">MTPaymentAdvice</a></li>
                </ul>
            </li>
            <li class="nav-item dropdown" *DataCustomAcl="'Employee'">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"> Folio </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li><a class="dropdown-item" routerLink="/c1mps">Companys</a></li>
                    <li><a class="dropdown-item" routerLink="/acts">Accounts</a></li>
                    <!-- <li><a class="dropdown-item" routerLink="/custs1">Customer Margin</a></li>-->
                    <li><a class="dropdown-item" routerLink="/prds">Products</a></li>
                    <li><a class="dropdown-item" routerLink="/emps">Sales Person</a></li>
                    <li><a class="dropdown-item" routerLink="/acr">Routes</a></li>
                    <li><a class="dropdown-item" routerLink="/staff">Staff</a></li>                   
                </ul>
            </li>
            <li class="nav-item dropdown" *DataCustomAcl="'Company'">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"> Company </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li><a class="dropdown-item" routerLink="/stc">Claim Followup</a></li>
                </ul>
            </li>
            <li class="nav-item">
                <a class="nav-item nav-link" routerLink="/pr">{{currentUser.firstName}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-item nav-link" (click)="logout()">Logout</a>
            </li>
        </ul>
    </div>
</nav>
<br>
<div class="container-fluid">
    <router-outlet></router-outlet>
</div>