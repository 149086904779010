import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { chitDetailModel, chitModel } from 'src/app/models1';
import { PmService } from 'src/app/_services/pm.service';

@Component({
  selector: 'app-chits',
  templateUrl: './chits.component.html',
  styleUrls: ['./chits.component.css']
})
export class ChitsComponent implements OnInit {

  customers: chitModel[] = [];
  chitDetails: chitDetailModel[] = [];

  loading = false;
  isAddCustomerClicked: boolean = false;
  isAddAuctionClicked: boolean = false;

  error = '';
  submitted = false;
  customerForm: FormGroup;
  customerForm1: FormGroup;

  get f() { return this.customerForm.controls; }
  get f1() { return this.customerForm1.controls; }

  searchCustomerName: string = "";
  addEditCustModel: chitModel = new chitModel();
  addEditCustModel1: chitDetailModel = new chitDetailModel();

  isChecked: boolean = false;
  constructor(private formBuilder: FormBuilder, private api: PmService) {
    this.onNameCustomerSearch(0);
  }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      accountName: ['', Validators.required],
      amount: ['', Validators.required],
      fee: ['', Validators.required],
      months: ['', Validators.required],
      startDate: ['', Validators.required],
      remarks: [''],
    });
    this.customerForm1 = this.formBuilder.group({
      auctionDate: ['', Validators.required],
      auction: ['', Validators.required],
      remarks: [''],
    });
  }

  customerst: chitModel = new chitModel();

  sort: string = "accountName";

  sorting(u: string) {
    this.sort = u;
  }

  getData() {
    var t1 = 0;
    var t3 = 0;
    var t5 = 0;
    var t2 = 0;

    var t = 0;

    var d6 = this.isChecked;
    var ret = this.customers.filter(function (hero) {
      if ((d6 == true || hero.chitStatus == 1)) {
        t1 = t1 + hero.amount;
        t3 = t3 + hero.fee;
        t5 = t5 + hero.profit;
        t2 = t2 + hero.chitMonth * (hero.amount / hero.months) - hero.profit;


        t = t + ((hero.profit - hero.fee) / hero.months);
        return true;
      }
      return false;
    }).sort((a, b) => a.chitId - b.chitId);
    this.customerst.amount = t1;
    this.customerst.fee = t3;
    this.customerst.profit = t5;
    this.customerst.months = t2;
    var u = this.sort;
    if (u === "amount") {
      ret.sort((a, b) => b.amount - a.amount);
    }
    if (u === "accountName") {
      ret.sort((a, b) => a.accountName.localeCompare(b.accountName));
    }
    if (u === "auctionDate") {
      ret.sort((a, b) => a.auctionDate.getTime() - b.auctionDate.getTime());
    }
    return ret;
  }

  onEditCustomer(u: chitModel) {
    this.error = '';
    this.customers.forEach(x => {
      x.showDetail = false;
    });
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      accountName: u.accountName,
      amount: u.amount,
      fee: u.fee,
      months: u.months,
      startDate: formatDate(u.startDate, 'yyyy-MM-dd', 'en'),
      remarks: u.remarks,
    });
    this.addEditCustModel = u
  }

  ShowDetail(u: chitModel) {
    this.customers.forEach(x => {
      x.showDetail = u.chitId == x.chitId;
    });
    this.isAddCustomerClicked = true;
    this.addEditCustModel = u
    this.getChitDetail(u.chitId);
  }

  getChitDetail(chitId: number) {
    this.api.getchitDetails(chitId).subscribe(result => {
      result.sort((b, a) => new Date(b.auctionDate).getTime() - new Date(a.auctionDate).getTime()).forEach((x, index) => {
        x.slNo = index + 1;
      })
      this.chitDetails = result;
    }, error => {
      console.error(error);
      this.error = error;
      this.loading = false;
    });
  }

  oAddAuction() {
    this.isAddAuctionClicked = true;
    this.customerForm1.setValue({
      auction: '',
      auctionDate: Date.now,
      remarks: '',
    });
    this.addEditCustModel1 = new chitDetailModel();
    this.addEditCustModel1.chitDetailId = 0;
    this.addEditCustModel1.chitId = this.addEditCustModel.chitId;
  }

  onEditChitDetail(u: chitDetailModel) {
    this.error = '';
    this.isAddAuctionClicked = true;
    this.customerForm1.setValue({
      auction: u.auction,
      auctionDate: formatDate(u.auctionDate, 'yyyy-MM-dd', 'en'),
      remarks: u.remarks,
    });
    this.addEditCustModel1 = u
  }

  onMarkClose(u: chitModel) {
    if (confirm("Are you sure to Mark Complete?")) {
      this.error = '';
      this.submitted = true;
      this.loading = true;
      this.api.MarkComplete(u).subscribe(result => {
        this.submitted = false;
        this.loading = false;
        this.isAddCustomerClicked = false;
        this.onNameCustomerSearch(0);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    }
  }

  onMarkcDelete(u: chitModel) {
    if (confirm("Are you sure to Delete?")) {
      this.error = '';
      this.submitted = true;
      this.loading = true;
      this.api.MarkcDelete(u).subscribe(result => {
        this.submitted = false;
        this.loading = false;
        this.isAddCustomerClicked = false;
        this.onNameCustomerSearch(0);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    }
  }

  onMarkPaid(u: chitDetailModel) {
    if (confirm("Are you sure to Mark Paid?")) {
      this.error = '';
      this.submitted = true;
      this.loading = true;
      this.api.MarkPaid(u).subscribe(result => {
        this.submitted = false;
        this.loading = false;
        this.isAddCustomerClicked = false;
        this.ShowDetail(this.addEditCustModel)
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    }
  }

  onAddCustSubmit1() {
    this.error = '';
    this.submitted = true;
    if (this.customerForm1.invalid) return;
    this.loading = true;
    this.addEditCustModel1.auction = this.f1.auction.value;
    this.addEditCustModel1.auctionDate = this.f1.auctionDate.value;
    this.addEditCustModel1.remarks = this.f1.remarks.value;
    this.api.AddEditChitDetail(this.addEditCustModel1)
      .subscribe(result => {
        this.submitted = false;
        this.loading = false;
        this.isAddAuctionClicked = false;
        this.ShowDetail(this.addEditCustModel)
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onAddCustSubmit() {
    this.error = '';
    this.submitted = true;
    if (this.customerForm.invalid) return;
    this.loading = true;
    this.addEditCustModel.accountName = this.f.accountName.value;
    this.addEditCustModel.amount = this.f.amount.value;
    this.addEditCustModel.fee = this.f.fee.value;
    this.addEditCustModel.months = this.f.months.value;
    this.addEditCustModel.startDate = this.f.startDate.value;
    this.addEditCustModel.remarks = this.f.remarks.value;
    this.api.AddEditChit(this.addEditCustModel)
      .subscribe(result => {
        this.submitted = false;
        this.loading = false;
        this.isAddCustomerClicked = false;
        this.onNameCustomerSearch(0);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onCloseAddCust() {
    this.isAddCustomerClicked = false;
  }

  onCloseAddCust1() {
    this.isAddAuctionClicked = false;
  }

  oAddCustomer() {
    this.isAddCustomerClicked = true;
    this.customers.forEach(x => {
      x.showDetail = false;
    });
    this.customerForm.setValue({
      accountName: '',
      amount: '',
      fee: '',
      startDate: Date.now,
      remarks: '',
      months: 20
    });
    this.addEditCustModel = new chitModel();
    this.addEditCustModel.chitId = 0;
  }

  onNameCustomerSearch(chitStatus: number) {
    this.isAddCustomerClicked = false;
    this.api.getchits(chitStatus).subscribe(result => {
      this.customers = result;
      this.customers.forEach((currentValue, index) => {
        currentValue.showDetail = false;
      });
    }, error => {
      console.error(error);
      this.error = error;
      this.loading = false;
    });
  }

  onCustSearchReset() {
    this.isAddCustomerClicked = false;
    this.searchCustomerName = "";
    this.customers = [];
  }

  onMarkcdDelete(u: chitDetailModel) {
    if (confirm("Are you sure to Delete?")) {
      this.error = '';
      this.submitted = true;
      this.loading = true;
      this.api.MarkCdDelete(u).subscribe(result => {
        this.submitted = false;
        this.loading = false;
        this.isAddCustomerClicked = false;
        this.ShowDetail(this.addEditCustModel)
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    }
  }
}
