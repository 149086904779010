import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { CustomerModel, accountModel, CustomerMarginModel, companyModel, BillModel, userModel, Role } from '../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  customers: CustomerModel[] = [];
  loading = false;
  isAddCustomerClicked: boolean = false;
  error = '';
  submitted = false;
  customerForm: FormGroup;
  get f() { return this.customerForm.controls; }
  searchCustomerName: string = "";
  addEditCustModel: CustomerModel = new CustomerModel();
  persons: accountModel[] = [];
  companys: companyModel[] = [];
  reviewed: string = 'both';
  constructor(private formBuilder: FormBuilder, private api: RayarApiService, private api1: AuthenticationService) {
    this.getAccounts();
    this.getCompanys();
  }

  goToLink(u: BillModel) {
    if (u.mapLink)
      window.open(u.mapLink, "_blank");
  }
  
  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      accountId: ['', Validators.required],
      customerName: ['', Validators.required],
      address: ['', Validators.required],
      mapLink: [''],
      information1: [''],
      information2: [''],
      information3: [''],
      gst: [''],
      contactName: [''],
      contactPhone: [''],
      latitude: [''],
      baskinBill: [''],
      zone: ['', Validators.required],
      longitude: ['']
    });
  }



  onCreateSalesLogin(cp: CustomerModel) {
    if (cp.userName == "zzzzz" && cp.contactPhone) {
      this.error = '';
      this.submitted = true;
      this.loading = true;
      var u = new userModel();
      u.userName = cp.contactPhone.toString();
      u.firstName = cp.customerName;
      u.lastName = cp.customerName;
      u.role = Role.Customer;
      u.profileId = cp.customerId.toString();
      u.appName = 'Rayar';
      this.api1.AddEditUser(u)
        .subscribe(result => {
          this.submitted = false;
          this.loading = false;
          this.isAddCustomerClicked = false;
          this.onNameCustomerSearch();
        }, error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
    }
  }

  downloadBills(u: number) {
    this.api.downloadBlobFilePost("Download1/" + u + "/Customer", null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            var fileName = u + ".jpg";
            if (data.body.type == "application/zip") {
              fileName = u + ".zip"
            } else if (data.body.type == "application/pdf") {
              fileName = u + ".pdf"
            }
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );

  }


  getAccounts() {
    this.api.getAccounts().subscribe(result => {
      this.persons = result;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  getCompanys() {
    this.api.getCompanys().subscribe(result => { this.companys = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  MarkReviewedCust(u: CustomerModel) {
    this.api.MarkReviewedCust(u.customerId).subscribe(result => {
      this.onNameCustomerSearch();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  onDelteCust(u: CustomerModel) {
    this.api.deleteCustomer(u.customerId).subscribe(result => {
      this.onNameCustomerSearch();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  onEditCustomer(u: CustomerModel) {
    var n = new CustomerMarginModel();
    n.page = 0;
    n.customerId = u.customerId;
    this.api.getCustomerMargin(n).subscribe(result => {
      u.margins = result;
      this.isAddCustomerClicked = true;
      var a = "0";
      if (u.baskinBill) a = "1";;
      if (!u.accountId) u.accountId = 0;
      this.customerForm.setValue({
        accountId: u.accountId,
        customerName: u.customerName,
        address: u.address,
        mapLink: u.mapLink,
        information1: u.information1,
        information2: u.information2,
        information3: u.information3,
        gst: u.gst,
        contactName: u.contactName,
        contactPhone: u.contactPhone,
        latitude: u.latitude,
        baskinBill: a,
        zone: u.zone,
        longitude: u.longitude
      });
      this.addEditCustModel = u
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  onAddCustSubmit() {
    this.submitted = true;
    if (this.customerForm.invalid) return;
    this.loading = true;
    var aid = parseInt(this.f.accountId.value);
    if (aid == 0)
      this.addEditCustModel.accountId = null;
    else
      this.addEditCustModel.accountId = aid;
    this.addEditCustModel.customerName = this.f.customerName.value;
    this.addEditCustModel.address = this.f.address.value;
    this.addEditCustModel.mapLink = this.f.mapLink.value;
    this.addEditCustModel.information1 = this.f.information1.value;
    this.addEditCustModel.information2 = this.f.information2.value;
    this.addEditCustModel.information3 = this.f.information3.value;
    this.addEditCustModel.gst = this.f.gst.value;
    this.addEditCustModel.contactName = this.f.contactName.value;
    this.addEditCustModel.zone = this.f.zone.value;
    this.addEditCustModel.contactPhone = this.f.contactPhone.value;
    this.addEditCustModel.latitude = parseFloat(this.f.latitude.value);
    this.addEditCustModel.longitude = parseFloat(this.f.longitude.value);
    this.addEditCustModel.baskinBill = this.f.baskinBill.value == "1";

    this.api.AddEditCustomer(this.addEditCustModel).subscribe(result => {
      this.submitted = false;
      this.loading = false;
      this.isAddCustomerClicked = false;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }


  download() {
    var q = 'CustomersDownload';
    this.api.downloadFilePost(q, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }

  onCloseAddCust() {
    this.isAddCustomerClicked = false;
  }

  oAddCustomer() {
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      accountId: '0',
      customerName: '',
      address: '',
      mapLink: '',
      information1: '',
      information2: '',
      information3: '',
      gst: '',
      contactName: '',
      contactPhone: '',
      latitude: '',
      baskinBill: '0',
      zone: '',
      longitude: ''
    });
    this.addEditCustModel = new CustomerModel();
    this.addEditCustModel.hasReviewed = false;
    this.addEditCustModel.customerId = 0;
    this.addEditCustModel.accountId = 0;
    this.addEditCustModel.margins = [];
    this.addEditCustModel.bills = [];
    this.companys.forEach(x => {
      var d = new CustomerMarginModel();
      d.customerId = 0;
      d.companyName = x.companyName;
      this.addEditCustModel.margins.push(d);
    });
  }

  showDetail(u: CustomerModel) {
    this.getCustomers();
    this.selectedtext1 = "";
    this.selectedCust = 0;
    this.custAll1 = [];
    this.billIds = [];
    if (u.margins.length == 0) {
      var n = new CustomerMarginModel();
      n.page = 0;
      n.customerId = u.customerId;
      this.api.getCustomerMargin(n).subscribe(result => {
        u.margins = result;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
    }
    this.api.getBills(u.customerId).subscribe(result => {
      u.bills = result;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
    this.customers.forEach(x => {
      if (u.customerId == x.customerId)
        x.showDetail = !x.showDetail;
      else
        x.showDetail = false;
    });
  }

  custAll: CustomerModel[] = [];
  custAll1: CustomerModel[] = [];
  billIds: number[] = [];
  selectedtext1: string = '';
  selectedCust: number = 0;

  Updatebill() {
    this.api.moveBill(this.selectedCust, this.billIds).subscribe(result => {
      this.onNameCustomerSearch();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  selectChangeHandlerCustSelect(event: any) {
    var cid = parseInt(event.target.value);
    this.selectedCust = cid;
  }

  selectChangeHandlerBillSelect(event: any) {
    var cid = parseInt(event.target.value);
    if (event.target.checked) {
      this.billIds.push(cid);
    }
    else {
      for (var i = 0; i < this.billIds.length; i++) {
        if (this.billIds[i] === cid) {
          this.billIds.splice(i, 1);
        }
      }
    }
  }

  onKey1(event: any) {
    this.selectedtext1 = event.target.value;
    var d2 = this.selectedtext1;
    this.custAll1 = this.custAll.filter(function (hero) {
      return (d2 != '' && hero.customerName.toLowerCase().includes(d2.toLowerCase()))
    });
  }

  getCustomers() {
    if (this.custAll.length == 0)
      this.api.getCustomers().subscribe(result => {
        this.custAll = result;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  onNameCustomerSearch() {
    this.isAddCustomerClicked = false;
    var s = ''
    if (this.searchCustomerName) {
      s = this.searchCustomerName;
    } else {
      s = "EmptySearch";
    }
    this.api.getSearchCustomer(s, this.reviewed)
      .subscribe(result => {
        this.customers = result;
        this.customers.forEach(x => {
          if (!x.userName) x.userName = "zzzzz";
          x.showDetail = false;
          x.margins = [];
          x.bills = [];
        });
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  onCustSearchReset() {
    this.isAddCustomerClicked = false;
    this.searchCustomerName = "";
    this.reviewed = "both";
    this.customers = [];
    this.error = "";
  }

}
