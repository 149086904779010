import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { accountModel, billPlanModel, CollectonPersonModel, companyModel, Role, User } from '../models';
import { AuthenticationService } from '../_services/authentication.service';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-fullview',
  templateUrl: './fullview.component.html',
  styleUrls: ['./fullview.component.css']
})
export class FullviewComponent implements OnInit {
  error = '';
  loading = false;
  currentUser: User;
  companys: companyModel[] = [];
  CollPersons: CollectonPersonModel[] = [];
  selectedcompany: string = '-1';
  selectedAccount: string = '-1';
  billAgeF: number = 21;
  billAgeT: number = 90;

  selectedcustomerName: string = '';

  selectedPerson: string = "-1";
  pendBills: billPlanModel[] = [];
  accounts: accountModel[] = [];

  constructor(private router: Router,
    private authenticationService: AuthenticationService, private api: RayarApiService) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.geCompany();
    this.getAccounts();
    this.getCollPersons1();
  }

  ngOnInit(): void {
  }

  onKey1(event: any) {
    this.selectedcustomerName = event.target.value;
  }

  getData() {

    var d2 = this.selectedcompany;
    var d3 = this.selectedPerson;
    var d4 = this.selectedcustomerName;
    var d6 = this.selectedAccount;
    var d7 = this.billAgeF;
    var d8 = this.billAgeT;

    return this.pendBills.filter(function (hero) {
      return ((d7 <= hero.billDays && hero.billDays <= d8) &&
        (d2 == '-1' || hero.companyName == d2) &&
        (d6 == '-1' || hero.accountName == d6) &&
        (d4 == '' || (hero.billNumber != null && hero.billNumber.toLowerCase().includes(d4.toLowerCase())) || (hero.customerName != null && hero.customerName.toLowerCase().includes(d4.toLowerCase()))) &&
        (d3 == '-1' || hero.salesPersonName == d3))
    }).sort((a, b) => b.billDays - a.billDays);
  }

  selectChangeHandlerCompany(event: any) {
    this.selectedcompany = event.target.value;
  }

  selectChangeHandlerAccount(event: any) {
    this.selectedAccount = event.target.value;
  }

  selectChangeHandlerPerson(event: any) {
    this.selectedPerson = event.target.value;
  }

  getAccounts() {
    this.api.getAccounts()
      .subscribe(result => { this.accounts = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  geCompany() {
    this.api.getCompanys().subscribe(result => {
      this.companys = result;
    }, error => {
      this.loading = false; console.error(error);
    });
  }

  getCollPersons1() {
    this.api.getCollPersons1().subscribe(r => {
      this.CollPersons = r;
      var sales = [];
      var isSales = this.currentUser.role === Role.Sales;
      this.api.getBillReportNew().subscribe(result => {
        if (isSales) {
          r.forEach((currentValue) => {
            sales.push(currentValue.name);
          });
        }
        this.pendBills = result.filter(function (hero) {
          return !isSales || (sales.includes(hero.salesPersonName));
        });
      }, error => {
        this.loading = false; console.error(error);
      });
    }, error => {
      this.loading = false; console.error(error)
    });
  }

}
