
import { Component, OnInit } from '@angular/core';
import { userModel, User, Role, Role1, Role2 } from '../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-appuser',
  templateUrl: './appuser.component.html',
  styleUrls: ['./appuser.component.css']
})
export class AppuserComponent implements OnInit {

  customers: userModel[] = [];
  loading = false;
  isAddCustomerClicked: boolean = false;
  error = '';
  submitted = false;
  customerForm: FormGroup;
  get f() { return this.customerForm.controls; }
  searchCustomerName: string = "";
  searchFirstName: string = "";
  searchAppName: string = "Rayar";
  searchRole: string = "All";
  addEditCustModel: userModel = new userModel();
  currentUser: User;

  constructor(private formBuilder: FormBuilder, private router: Router,
    private api: AuthenticationService) {
    this.currentUser = this.api.currentUserValue;
  }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      userName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      role: ['', Validators.required],
      appName: ['', Validators.required],
      profileId: [''],
    });
  }

  get isSuperAdmin() {
    return this.currentUser && this.currentUser.id === 909;
  }

  get isSuperAdmin1() {
    return this.currentUser && (this.currentUser.id === 909 || this.currentUser.id === 928);
  }

  keys(): Array<string> {
    if (this.currentUser && this.currentUser.id === 909) {
      return Object.keys(Role1).concat(Object.keys(Role2));
    } else {
      return Object.keys(Role1);
    }
  }

  getcustomers() {
    var s = this.searchRole;
    var sf = this.searchFirstName;
    return this.customers.filter(function (hero) {
      return (s == "All" || hero.role == s) && (sf == "" || hero.firstName.toLowerCase().includes(sf));
    });
  }

  onEditCustomer(u: userModel) {
    this.error = '';
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      userName: u.userName,
      firstName: u.firstName,
      lastName: u.lastName,
      role: u.role,
      profileId: u.profileId,
      appName: u.appName,
    });
    this.addEditCustModel = u
  }

  onDeleteUser(u: userModel) {
    this.error = '';
    this.submitted = true;
    this.loading = true;
    if (confirm("Are you sure to Delete User " + u.userName + " ?"))
      this.api.onMarkUserInactive(u).subscribe(result => {
        this.submitted = false;
        this.loading = false;
        this.isAddCustomerClicked = false;
        this.onNameCustomerSearch();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onResetPassword(u: userModel) {
    this.error = '';
    this.submitted = true;
    this.loading = true;
    if (confirm("Are you sure to Reset Password for " + u.userName + " ?"))
      this.api.onResetPassword(u).subscribe(result => {
        this.submitted = false;
        this.loading = false;
        this.isAddCustomerClicked = false;
        this.onNameCustomerSearch();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onLoginAs(u: userModel) {
    this.error = '';
    this.submitted = true;
    this.loading = true;
    this.api.loginas(u.userName, "tr").pipe(first()).subscribe(
      data => {
        this.router.navigate(['/']);
      },
      error => {
        this.error = error;
        this.loading = false;
      });
  }

  onAddCustSubmit() {
    this.error = '';
    this.submitted = true;
    if (this.customerForm.invalid) return;
    this.loading = true;
    this.addEditCustModel.userName = this.f.userName.value;
    this.addEditCustModel.firstName = this.f.firstName.value;
    this.addEditCustModel.lastName = this.f.lastName.value;
    this.addEditCustModel.role = this.f.role.value;
    this.addEditCustModel.profileId = this.f.profileId.value;
    this.addEditCustModel.appName = this.f.appName.value;
    this.api.AddEditUser(this.addEditCustModel)
      .subscribe(result => {
        this.submitted = false;
        this.loading = false;
        this.isAddCustomerClicked = false;
        this.onNameCustomerSearch();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onCloseAddCust() {
    this.isAddCustomerClicked = false;
  }

  oAddCustomer() {
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      userName: '',
      firstName: '',
      lastName: '',
      role: '',
      appName: '',
      profileId: ''
    });
    this.addEditCustModel = new userModel();
    this.addEditCustModel.id = 0;
  }

  onNameCustomerSearch() {
    this.isAddCustomerClicked = false;
    this.api.getSearchUsers(this.searchCustomerName, this.searchAppName)
      .subscribe(result => { this.customers = result.sort((a, b) => a.role.localeCompare(b.role) || a.userName.localeCompare(b.userName)); }, error => {
        console.error(error);
        this.error = error;
        this.loading = false;
      });
  }

  onCustSearchReset() {
    this.isAddCustomerClicked = false;
    this.searchCustomerName = "";
    this.searchFirstName = "";
    this.customers = [];
  }

}
