<div class="row">
    <div class="col">
        <div class="form-row">
            <div class="col-md-2">
                <label>Bill Number</label>
                <input type="text" [(ngModel)]="searchBillModel.billNumber" class="form-control" />
            </div>
            <div class="col-md-2">
                <label>Customer</label>
                <input type="text" [(ngModel)]="searchBillModel.customerName" class="form-control" />
            </div>
            <div class="col-md-2">
                <label>Account</label>
                <input type="text" [(ngModel)]="searchBillModel.accountName" class="form-control" />
            </div>
            <div class="col-md-2">
                From
                <input type="date" [ngModel]="searchBillModel.fromDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler($event)" class="form-control" />
            </div>
            <div class="col-md-2">
                To<input type="date" [ngModel]="searchBillModel.toDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler1($event)" class="form-control" />
            </div>
            <div class="col-md-2">
                <input type="radio" name="revRadio" value="2" [(ngModel)]="sReviewed"
                    (change)="selectChangeHandler11($event)" /> All
                <input type="radio" name="revRadio" value="1" [(ngModel)]="sReviewed"
                    (change)="selectChangeHandler11($event)" /> Taken
                <input type="radio" name="revRadio" value="0" [(ngModel)]="sReviewed"
                    (change)="selectChangeHandler11($event)" /> Not Taken
            </div>
            <div class="col-md-2">
                <label></label>
                <div>
                    <button class="btn btn-outline-primary" (click)="onCollBillSearch()">Search</button>
                    <button class="btn btn-outline-primary" (click)="onCollBillSearchReset()">Reset</button>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-md-6">
                <nav aria-label="...">
                    <ul class="pagination justify-content-end">
                        <li class="page-item"><a class="page-link" (click)="onpage1(false)">Previous</a></li>
                        <li class="page-item"><a class="page-link" (click)="onpage1(true)">Next</a></li>
                        <li class="page-item">
                            <span class="page-link">Page: {{page+1}}</span>
                        </li>
                    </ul>
                </nav>
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Batch</th>
                            <th scope="col">Document Name</th>
                            <th scope="col">Upload Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let u of suppliedDocs">
                            <td>
                                <button class="btn btn-sm btn-info"
                                    (click)="onDownloadDocument(u.documentId, u.documentName)">
                                    Download
                                </button>
                            </td>
                            <td>{{u.billId}}</td>
                            <td>{{u.documentName}}</td>
                            <td>{{u.uploadDate| date: 'dd/MM/yy hh:mm a'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <nav aria-label="...">
            <ul class="pagination justify-content-end">
                <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a></li>
                <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                <li class="page-item">
                    <span class="page-link">Page: {{searchBillModel.page+1}}</span>
                </li>
            </ul>
        </nav>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Bill Number</th>
                    <th scope="col">Bill Status</th>
                    <th scope="col">Bill Date</th>
                    <th scope="col">Bill Value</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Xeroxed Date</th>
                    <th scope="col">Xeroxed</th>
                    <th scope="col">Remarks</th>
                    <th scope="col">#</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of bills">
                    <td>{{u.billNumber}}</td>
                    <td>{{u.billStatus}}</td>
                    <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.billValue}}</td>
                    <td>{{u.customerName}}</td>
                    <td>{{u.printedDate| date: 'dd/MM/yyyy'}}</td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.printed }}</div>
                        <div *ngIf="u.isEdit">
                            <select class="form-control" [(ngModel)]="editgrnData.printedString">
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.remarks }}</div>
                        <input type="text" *ngIf="u.isEdit" [(ngModel)]="editgrnData.remarks" class="form-control" />
                    </td>
                    <td>
                        <button class="btn btn-outline-primary" *ngIf="!u.isEdit" (click)="editGrn(u)">Edit</button>
                        <button class="btn btn-outline-primary" *ngIf="u.isEdit" (click)="saveGrn(u)">Save</button>
                        <button class="btn btn-outline-primary" *ngIf="u.isEdit" (click)="cancelGrn(u)">Cancel</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>