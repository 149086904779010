import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BillModel, CustomerModel, DocumentModel, companyModel, BillProductInvModel, CustomerMarginModel, PendingBills, PendingFollowUpModel, TimeTrackResourceModel, CollectionModel, ProductModel, BillProductModel, billPlanModel, User, Role, AttendanceModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { conditionalValidator } from '../_helpers/conditionalValidator';
import { jsPDF } from 'jspdf';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.css']
})
export class BillComponent implements OnInit {

  loading = false;
  isAddNewBillClicked: boolean = false;
  searchCustModel = new CustomerModel();
  bills: BillModel[] = [];
  billsAll: BillModel[] = [];

  billModel = new BillModel();
  isSearchClicked: boolean = false;
  customers: CustomerModel[];
  companys: companyModel[];
  error = '';
  showAlert1 = false;
  billAddEditForm: FormGroup;
  get f() { return this.billAddEditForm.controls; }

  @ViewChild('inputFile') inputFile: ElementRef;
  imageSrc: string;
  fileType: string;
  fileSource: any = null;
  submitted = false;
  isEditBill = false;
  isEditBill1 = false;
  isServiceCompany: boolean = false;
  isInStock: boolean = true;
  searchBillModel: BillModel = new BillModel();
  selectedCompany: string;
  billNums: string[];
  selectedcompany: string = '-1';
  selectedstock: string = '-1';
  selectedtext1: string = '';
  selecteddriver: string = '0';
  pendingBills: PendingBills[] = [];
  pendingFollowups: PendingFollowUpModel[] = [];
  pendingFollowupsAll: PendingFollowUpModel[] = [];
  selectedSupplied: string = '2';
  suppliedDocs: DocumentModel[] = [];
  suppliedDocs1: DocumentModel[] = [];
  customers1: CustomerMarginModel[] = [];
  selectedMargin: string = '';
  drivers: TimeTrackResourceModel[] = [];
  colls: CollectionModel[] = [];
  searchBillModel1: BillModel = new BillModel();
  search: ProductModel = new ProductModel();
  searchBp: BillProductModel = new BillProductModel();
  billProducts: BillProductModel[] = [];
  billProducts1: BillProductModel[] = [];

  products: ProductModel[] = [];
  srcPrds: string = '';
  srcPrds1: string = '';

  selTab: string = '3';
  currentUser: User;
  selectedCustomer: string = '0';

  constructor(private authenticationService: AuthenticationService, private imageCompress: NgxImageCompressService, private formBuilder: FormBuilder, private api: RayarApiService, public sanitizer: DomSanitizer) {
    this.companys = [];
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser.role == Role.Customer) {
      this.selectedCustomer = this.currentUser.profileId.toLowerCase();
      this.api.getSearchCustomerId(parseInt(this.selectedCustomer)).subscribe(result => {
        this.onSelectCust(result[0]);
        this.getcompany();
        this.searchBillModel.page = 0;
        this.getBills(0);
        this.getPendingFollowup();
        this.onCollBillSearch();
      }, error => { this.error = error; this.loading = false; console.error(error) });
    } else {

    }
  }
  accledgersAll: billPlanModel[] = [];

  downloadBills(u: number) {
    this.api.downloadBlobFilePost("Download1/" + u + "/Order", null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            var fileName = u + ".jpg";
            if (data.body.type == "application/zip") {
              fileName = u + ".zip"
            } else if (data.body.type == "application/pdf") {
              fileName = u + ".pdf"
            }
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );

  }

  onCollBillSearch() {
    var cid = parseInt(this.selectedCustomer);
    this.api.getBillPlanRoute(cid).subscribe(result => {
      this.accledgersAll = result.billPlanModels.filter(function (hero) {
        return hero.driverName != '-1' && hero.customerId == cid;
      });
      if (this.accledgersAll.length > 0) {
        this.getRoute(this.accledgersAll[0].driverName);
      }
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }
  searchlModel = new AttendanceModel();

  routeAttends: AttendanceModel[] = [];

  getRoute(p: string) {
    this.searchlModel.isReviewed = null;
    this.searchlModel.fromDate = new Date();
    this.searchlModel.toDate = new Date();
    this.searchlModel.page = 0;
    this.searchlModel.uniqId = null;
    this.searchlModel.timeTrackResourceId = Number(this.searchlModel.timeTrackResourceId);
    this.api.getAttendances(this.searchlModel).subscribe(result => {
      this.routeAttends = result.filter(function (h) {
        return h.resourceName == p;
      });
    }, error => {
      this.error = error; this.loading = false; console.error(error);
    });
  }

  selectChangeHandler3(event: any, d: BillModel) {
    var d1 = new billPlanModel();
    d1.billId = d.billId;
    d1.driverName = d.driverName;
    this.api.UpdateBillDriver(d1).subscribe(result => {
    }, error => { this.error = error; this.loading = false; console.error(error) });
  }

  onTabSelect(s: string) {
    this.selTab = s;
  }

  onDownload(s: number) {
    var q = 'GenBill';
    this.api.downloadFilePost(q + '/' + s, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + s + ".pdf";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }

  color(q: ProductModel) {
    var d = false;
    this.billProducts.forEach(function (hero) {
      if (hero.productId == q.productId) {
        d = true;
      }
    });
    return d;
  }
  color1(q: ProductModel) {
    var d = false;
    this.billProducts1.forEach(function (hero) {
      if (hero.productId == q.productId) {
        d = true;
      }
    });
    return d;
  }

  onAddNew(p: ProductModel) {
    var isexists = false;
    this.billProducts1.forEach(function (hero) {
      if (hero.productId == p.productId) {
        hero.quantity = hero.quantity + 1;
        isexists = true;
      }
    });
    if (!isexists) {
      var bp = new BillProductModel();
      bp.quantity = 1;
      bp.billId = 0;
      bp.billProductId = 0;
      bp.price = p.mrp;
      bp.crate = p.crate;
      bp.srate = p.srate;
      bp.defaultMargin = p.defaultMargin;
      bp.margin = "";
      bp.productId = p.productId;
      bp.productName = p.productName
      this.billProducts1.push(bp);
    }
  }

  onAdd(q: BillModel, p: ProductModel) {
    var isexists = false;
    var m = parseFloat(q.margin.toString());
    if (isNaN(m)) m = 0;
    this.billProducts.forEach(function (hero) {
      if (hero.productId == p.productId) {
        hero.quantity = hero.quantity + 1;
        isexists = true;
      }
    });
    if (!isexists) {
      var bp = new BillProductModel();
      bp.quantity = 1;
      bp.billId = q.billId;
      bp.billProductId = 0;
      bp.price = p.mrp;
      bp.crate = p.crate;
      bp.srate = p.srate;
      bp.defaultMargin = p.defaultMargin;
      bp.margin = m.toString();
      bp.productId = p.productId;
      bp.productName = p.productName
      this.billProducts.push(bp);
    }
  }

  onAdd1(q1: BillModel, q: BillProductModel) {
    this.billProducts.forEach(function (hero) {
      if (hero.productId == q.productId) {
        hero.quantity = hero.quantity + 1;
      }
    });
  }

  onSub(q1: BillModel, q: BillProductModel) {
    this.billProducts.forEach(function (hero, index, object) {
      if (hero.productId == q.productId) {
        hero.quantity = hero.quantity - 1;
        if (hero.quantity <= 0) {
          object.splice(index, 1);
        }
      }
    });
  }


  onSubNew1(q: BillProductModel) {
    this.billProducts1.forEach(function (hero, index, object) {
      if (hero.productId == q.productId) {
        hero.quantity = hero.quantity - 1;
        if (hero.quantity <= 0) {
          object.splice(index, 1);
        }
      }
    });
  }

  onSub1(q: BillProductModel) {
    this.billProducts.forEach(function (hero, index, object) {
      if (hero.productId == q.productId) {
        object.splice(index, 1);
      }
    });
  }

  onSubNew(q: BillProductModel) {
    this.billProducts1.forEach(function (hero, index, object) {
      if (hero.productId == q.productId) {
        object.splice(index, 1);
      }
    });
  }

  getTotal1() {
    let total = 0;
    this.billProducts1.forEach((item) => {
      total += item.disPrice;
    });
    return total.toFixed(2);
  }

  getTotal() {
    let total = 0;
    this.billProducts.forEach((item) => {
      total += item.disPrice;
    });
    return total.toFixed(2);
  }

  getBillProductsFilter(q1: BillModel) {
    var bm = parseFloat(q1.margin.toString());
    if (isNaN(bm)) bm = 0;
    var p = this.billProducts.filter(function (hero) {
      hero.margin = bm.toString();
      var m = parseFloat(hero.margin);
      if (isNaN(m)) m = 0;
      if (m == 0) {
        var dfm = parseFloat(hero.defaultMargin);
        if (isNaN(dfm)) dfm = 0;
        m = dfm;
      }
      hero.disPrice = parseFloat((hero.quantity * (hero.price * (100 - m) / 100)).toFixed(2));
      return true;
    });
    return p;
  }

  getBillProductsFilter1() {
    var p = this.billProducts1.filter(function (hero) {
      hero.disPrice = parseFloat((hero.quantity * (hero.price * (100 - 0) / 100)).toFixed(2));
      return true;
    });
    return p;
  }

  getProductsFilter() {
    var st = this.srcPrds;
    return this.products.filter(function (hero) {
      return st == "" || hero.productName.toLowerCase().includes(st.toLowerCase());
    });
  }

  getProductsFilter1() {
    var st = this.srcPrds1;
    return this.products.filter(function (hero) {
      return st == "" || hero.productName.toLowerCase().includes(st.toLowerCase());
    });
  }

  onCompanyChange(event: any) {
    this.getProducts(event.target.value);
    this.setMargin(event.target.value);
    this.billProducts1 = [];
    var c = this.bills.filter(function (s) {
      return s.companyName == event.target.value;
    });
    if (c.length > 0) {
      this.alterText = "Pending order for the Brand " + event.target.value + " exists.";
    } else {
      this.alterText = "";
    }
  }

  alterText: string = '';

  getProducts(s: string) {
    this.search.category = s;
    this.search.page = 0;
    this.search.pageSize = 1000;
    this.api.getProducts1(this.search).subscribe(result => { this.products = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  getBillProducts(s: number) {
    this.searchBp.billId = s;
    this.searchBp.type = "B";
    this.api.getBillProducts(this.searchBp).subscribe(result => { this.billProducts = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  getPendingCheques(c: number) {
    this.searchBillModel1.page = 0;
    this.searchBillModel1.personId = 0;
    this.searchBillModel1.customerId = c;
    this.api.oncollsView('chequeReviewCid', this.searchBillModel1).subscribe(result => { this.colls = result; }, error => {
      this.error = error; this.loading = false; console.error(error)
    });
  }

  getDrivers() {
    this.api.getResources().subscribe(result => { this.drivers = result.resources; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  getPendingBills(cName: string, customerId: number) {
    this.pendingFollowups = this.pendingFollowupsAll.filter(function (hero) {
      return hero.customerId == customerId;
    });
    this.api.getCustomerSearch1(cName, '', 0)
      .subscribe(result => {
        this.pendingBills = result;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
    this.getPendingCheques(customerId);
  }

  selectChangeHandlerSupplied(event: any) {
    this.filter();
  }

  onBlurEvent(b: BillModel) {
    var d = new BillModel();
    d.billId = b.billId; d.billRemarks = b.billRemarks;
    this.api.UpdateBillRemakrs(d).subscribe(result => {
    }, error => { this.error = error; this.loading = false; console.error(error) });
  }

  getPendingFollowup() {
    this.api.getPendingFollowup().subscribe(result => { this.pendingFollowupsAll = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  filter() {
    var d3 = this.selectedcompany;
    var d2 = this.selectedtext1;
    var d1 = this.selectedstock;
    var d4 = this.selecteddriver;
    var d5 = this.selectedSupplied;;

    this.bills = this.billsAll.filter(function (hero) {
      return (d4 == '0' || (d4 == hero.driverName)) &&
        (d5 == '2' || (d5 == '1' && hero.supplied) || (d5 == '0' && !hero.supplied)) &&
        (d1 == '-1' || (d1 == '1' && hero.inStock) || (d1 == '0' && !hero.inStock)) &&
        (d2 == '' || (hero.billNumber != null && hero.billNumber.toLowerCase().includes(d2.toLowerCase()))
          || (hero.customerName != null && hero.customerName.toLowerCase().includes(d2.toLowerCase()))) &&
        (d3 == '-1' || (d3 == hero.companyName))
    });
  }

  onKey1(event: any) {
    this.selectedtext1 = event.target.value;
    this.filter();
  }

  selectChangeHandlerStock(event: any) {
    this.selectedstock = event.target.value;
    this.filter();
  }

  selectChangeHandlerDriver(event: any) {
    this.selecteddriver = event.target.value;
    this.filter();
  }

  selectChangeHandlerCompany(event: any) {
    this.selectedcompany = event.target.value;
    this.filter();
  }

  ngOnInit() {
    this.billAddEditForm = this.formBuilder.group({
      billNumber: ['', conditionalValidator(() => this.billAddEditForm.get('companyName').value != 'Service' &&
        this.billAddEditForm.get('inStock').value == '1', Validators.required)],
      billValue: ['', Validators.required],
      billDate: ['', Validators.required],
      companyName: ['', Validators.required],
      margin: [''],
      inStock: [''],
      billRemarks: [''],
      billNumber1: ['', conditionalValidator(() => this.billAddEditForm.get('companyName').value == 'Service' &&
        this.billAddEditForm.get('inStock').value == '1',
        Validators.required)],
    });
    this.billAddEditForm.get('inStock').valueChanges
      .subscribe(value => {
        this.isInStock = (value == '1');
        this.billAddEditForm.get('billNumber').updateValueAndValidity();
        this.billAddEditForm.get('billNumber1').updateValueAndValidity();
      });
    this.billAddEditForm.get('companyName').valueChanges
      .subscribe(value => {
        this.isServiceCompany = (value == 'Service');
        this.billAddEditForm.get('billNumber').updateValueAndValidity();
        this.billAddEditForm.get('billNumber1').updateValueAndValidity();
      });
  }

  getcompany() {
    this.api.getCompanys().subscribe(result => {
      this.companys = result.filter(function (f) {
        return (f.companyName == 'Baskin'
          || f.companyName == "creambell"
          || f.companyName == "Gadre"
          || f.companyName == "LIQI"
          || f.companyName == "londondiary"
          || f.companyName == "Sunriseideal");
      });
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  upload(event) {
    this.error = "";
    this.fileType = null;
    this.fileSource = null;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          this.imageSrc = reader.result as string;
          this.imageCompress.compressFile(this.imageSrc, 50, 100).then((compressedImage) => {
            this.fileType = "Image";
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            //this.url = doc.output('bloburl');
            //this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
            this.fileSource = doc.output('blob');
          });
        }
        else {
          this.imageSrc = null;
          if (file.type.startsWith("application/pdf")) {
            this.api.uploadToBlob(file, "UploadBill/" + this.billModel.billId + "/" + this.billModel.billNumber + "/Bill").subscribe(
              data => {
                if (data) {
                  switch (data.type) {
                    case HttpEventType.UploadProgress:
                      break;
                    case HttpEventType.Response:
                      this.inputFile.nativeElement.value = '';
                      this.fileType = null;
                      this.fileSource = null;
                      this.imageSrc = null;
                      this.getDocuments();
                      break;
                  }
                }
              },
              error => {
                this.inputFile.nativeElement.value = '';
              }
            );
          } else {
            this.error = "Invalid File format images/PDF only"
          }
        }
      };
    }
  }

  url: any;
  urlSafe: SafeResourceUrl;

  onUpload() {
    this.api.uploadToBlob(this.fileSource, "UploadBill/" + this.billModel.billId + "/" + this.billModel.billNumber + "/Bill").subscribe(
      data => {
        if (data) {
          switch (data.type) {
            case HttpEventType.UploadProgress:
              break;
            case HttpEventType.Response:
              this.inputFile.nativeElement.value = '';
              this.fileType = null;
              this.fileSource = null;
              this.imageSrc = null;
              this.getDocuments();
              break;
          }
        }
      },
      error => {
        this.inputFile.nativeElement.value = '';
      }
    );
  }

  onpage(next: boolean) {
    if (next) {
      this.getBills(this.searchBillModel.page + 1);
    }
    else {
      if (this.searchBillModel.page >= 1)
        this.getBills(this.searchBillModel.page - 1);
    }
  }

  getBills(p: number) {
    this.searchBillModel.page = p;
    this.searchBillModel.pageSize = 1000;
    this.searchBillModel.customerId = parseInt(this.selectedCustomer);
    this.api.onBillSearch('BillsNew', this.searchBillModel)
      .subscribe(result => {
        this.billsAll = result;
        this.filter();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  getBillSequence(companyName: string) {
    this.selectedCompany = companyName;
    this.setMargin(companyName);
    this.billNums = [];
    this.api.getBillSequence(companyName).subscribe(result => { this.billNums = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  selectChangeHandler(event: any) {
    this.getBillSequence(event.target.value);
    this.showAlert1 = this.pendingBills.filter(function (hero) {
      return hero.companyName == event.target.value;
    }).length >= 2;
  }

  onAddNewBill() {
    this.pendingBills = [];
    this.isEditBill = false;
    this.isEditBill1 = false;
    this.customers1 = [];
    this.selectedMargin = '';
    this.selectedCompany = '';
    this.error = '';
    this.isAddNewBillClicked = true;
    this.isSearchClicked = false;
    this.billModel = new BillModel();
    this.billModel.billId = 0;
    this.searchCustModel = new CustomerModel();
    this.billAddEditForm.setValue({
      billDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
      billNumber: '',
      billNumber1: '',
      billValue: '',
      companyName: '',
      margin: '',
      billRemarks: '',
      inStock: '1'
    });
    this.billsAll.forEach(x => {
      x.showDetail = false;
      x.showDetail1 = false;
    });
  }

  editBillG(u: BillModel) {
    this.urlSafe = null;
    this.billsAll.forEach(x => {
      x.showDetail = false;
      if (u.billId == x.billId) {
        x.showDetail1 = !x.showDetail1;
        if (x.showDetail1) {
          if (u.inStock) {
            this.editBill(u);
          } else {
            this.editBill1(u);
          }
        }
      }
      else
        x.showDetail1 = false;
    });
  }

  editBill(u: BillModel) {
    this.error = '';
    this.isEditBill = true;
    this.isEditBill1 = false;
    this.isAddNewBillClicked = true;
    this.isSearchClicked = false;
    this.billModel = u;
    this.selectedCompany = u.companyName;
    this.getProducts(u.companyName);
    this.getBillProducts(u.billId);
  }

  editBill1(u: BillModel) {
    this.error = '';
    this.isEditBill1 = true;
    this.isEditBill = false;
    this.isAddNewBillClicked = true;
    this.isSearchClicked = false;
    if (u.inStock) u.inStock1 = "1"; else u.inStock1 = "0";
    this.billModel = u;
    this.selectedCompany = u.companyName;
    this.getProducts(u.companyName);
    this.getBillProducts(u.billId);
  }

  onSearchCust() {
    if (this.searchCustModel.customerName)
      this.api.getSearchCustomer(this.searchCustModel.customerName, 'both').subscribe(users => {
        this.loading = false;
        this.customers = users;
        this.isSearchClicked = true;
      },
        error => {
          this.error = error;
          this.loading = false;
        });

  }

  onSelectCust(u: CustomerModel) {
    this.billModel.customerName = u.customerName;
    this.billModel.customerId = u.customerId;
    this.billModel.accountName = u.accountName;
    this.getMargin(u.customerId);
    this.getPendingBills(u.customerName, u.customerId);
  }

  setMargin(c: string) {
    var cm = this.customers1.filter(function (hero) {
      return hero.companyName == c;
    });
    if (cm.length > 0) {
      this.selectedMargin = cm[0].margin;
    } else {
      this.selectedMargin = '';
    }
  }

  getMargin(u: number) {
    var n = new CustomerMarginModel();
    n.page = 0;
    n.customerId = u;
    this.api.getCustomerMargin(n).subscribe(result => {
      this.customers1 = result;
      this.setMargin(this.selectedCompany);
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  onBillCancel() {
    this.isAddNewBillClicked = false;
  }
  selectedFile: any;
  selCompany: string = '';
  selRemakrs: string = '';
  onBillSubmit() {
    this.alterText = "";
    if (this.pendingBills.length > 2) {
      this.alterText = "Please clear any pending bills before placing the order.";
      return;
    }
    this.submitted = true;
    if (!this.selCompany) {
      this.alterText = "Please select Brand.";
      return;
    }
    var bu = this.billProducts1.filter(function (hero) {
      return hero.quantity > 0;
    });
    if (bu.length <= 0) {
      this.alterText = "Please select Products.";
      return;
    }
    var com = this.selCompany;
    var c = this.bills.filter(function (s) {
      return s.companyName == com;
    });
    if (c.length > 0) {
      this.alterText = "Pending order for the Brand " + this.selCompany + " exists.";
      return;
    }
    this.error = '';
    this.loading = true;
    this.billModel.inStock = false;
    this.billModel.billDate = new Date();
    this.billModel.billValue = 1;
    this.billModel.companyName = this.selCompany;
    this.billModel.billRemarks = this.selRemakrs;
    this.billModel.billNumber = "NoStock";
    this.billModel.custOrder = 1;
    this.billModel.billProducts = bu;
    var bm = parseFloat(this.selectedMargin);
    if (isNaN(bm)) bm = 0;
    this.billModel.margin = bm;

    this.api.uploadAddBilFile(this.selectedFile, this.billModel)
      .subscribe(result => {
        if (confirm("Your order received.We will deliver it at the earliest.Thank you")) {
        }
        this.error = '';
        this.submitted = false;
        this.loading = false;
        this.isAddNewBillClicked = false;
        this.selCompany = "";
        this.billProducts1 = [];
        this.products = []
        this.getBills(this.searchBillModel.page);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onBillSubmit1() {
    this.submitted = true;
    this.error = '';
    this.loading = true;

    this.billModel.billProducts = this.billProducts.filter(function (hero) {
      return hero.quantity > 0;
    });
    this.api.MUpdateBill(this.billModel).subscribe(result => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.isAddNewBillClicked = false;
      this.getBills(this.searchBillModel.page);
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  onBillSubmit11() {
    this.submitted = true;
    this.error = '';
    this.loading = true;
    this.billModel.inStock = this.billModel.inStock1 == '1' ? true : false;
    if (this.billModel.inStock) {
      if (this.billModel.companyName == "Service") {
        if (!this.billModel.billNumber1) return;
        this.billModel.billNumber = this.billModel.billNumber1.toString();
      } else {
        if (!this.billModel.billNumber || this.billModel.billNumber == "NoStock") return;
        this.billModel.billNumber = this.billModel.billNumber;
      }
    } else {
      this.billModel.billNumber = "NoStock";
    }
    this.billModel.billProducts = this.billProducts.filter(function (hero) {
      return hero.quantity > 0;
    });
    this.api.MUpdateBill1(this.billModel).subscribe(result => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.isAddNewBillClicked = false;
      this.getBills(this.searchBillModel.page);
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  markSupplied(billId: number) {
    this.api.markSupplied(billId)
      .subscribe(result => {
        this.isAddNewBillClicked = false;
        this.getBills(this.searchBillModel.page);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onClickSupplied(u: BillModel) {
    this.fileType = null;
    this.error = '';
    this.isEditBill = false;
    this.isEditBill1 = false;
    this.isAddNewBillClicked = false;
    this.isSearchClicked = false;
    this.billModel = u;
    this.billsAll.forEach(x => {
      x.showDetail1 = false;
      if (u.billId == x.billId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.getDocuments();
        }
      }
      else
        x.showDetail = false;
    });
  }

  onClickDelivered(u: BillModel) {
    this.api.markDelivered(u.billId)
      .subscribe(result => {
        this.isAddNewBillClicked = false;
        this.getBills(this.searchBillModel.page);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  getDocuments() {
    this.api.getDcouments(this.billModel.billId, "Bill")
      .subscribe(result => this.suppliedDocs = result, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onDeleteDocument(i: number) {
    this.api.deleteDocument(i)
      .subscribe(result => this.getDocuments(), error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onDownloadDocument1(i: number, f: string) {
    this.api.downloadBlobFilePost("Download/" + i, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            window.open(URL.createObjectURL(new Blob([data.body], { type: data.body.type })), 'mywindow1');
            break;
        }
      },
      () => {
      }
    );
  }

  onDownloadDocument(i: number, f: string) {
    this.api.downloadBlobFilePost("Download/" + i, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = f;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }

  markCancel(u: BillModel) {
    if (confirm("Are you sure to Cancel Bill"))
      this.api.markCancel(u.billId)
        .subscribe(result => { this.getBills(this.searchBillModel.page); }, error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
  }

  onCheckboxChange1(e) {
    this.api.SetCashCarry(e.target.value, e.target.checked).subscribe(() => {
      this.loading = false;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  showDetails(u: ProductModel) {
    this.products.forEach(x => {
      if (u.productId == x.productId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.getBillProdtsInv(u.productId);
        }
      }
      else
        x.showDetail = false;
    });
  }
  BillProdtsInv: BillProductInvModel[] = [];

  getBillProdtsInv(p: number) {
    var search = new ProductModel();
    search.productId = p;
    this.api.getBillProdtsInv(search).subscribe(result => { this.BillProdtsInv = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

}
