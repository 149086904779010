
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BillModel, CollectionModel, User, Role, DocumentModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';
import { HttpEventType } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { AuthenticationService } from '../_services/authentication.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-extmgnxerox',
  templateUrl: './extmgnxerox.component.html',
  styleUrls: ['./extmgnxerox.component.css']
})
export class ExtmgnxeroxComponent implements OnInit {
  loading = false;
  bills: BillModel[] = [];
  error = '';
  searchBillModel: BillModel = new BillModel();
  sReviewed: string = "0";
  selectedBill: BillModel = new BillModel();
  currentUser: User;

  constructor(private authenticationService: AuthenticationService, private api: RayarApiService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.searchBillModel.page = 0;
    this.sReviewed = "0";
    this.getDocuments(0);
  }

  ngOnInit() {
  }

  suppliedDocs: DocumentModel[] = [];
  page: number = 0;
  getDocuments(p: number) {
    this.page = p;
    this.api.getDocuments1(this.page, "BAskin")
      .subscribe(result => this.suppliedDocs = result, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }
  onpage1(next: boolean) {
    if (next) {
      this.getDocuments(this.page + 1);
    }
    else {
      if (this.page >= 1)
        this.getDocuments(this.page - 1);
    }
  }
  onDownloadDocument(i: number, f: string) {
    this.api.downloadBlobFilePost("Download/" + i, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = f;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }

  getBills(p: number) {
    this.searchBillModel.page = p;
    this.searchBillModel.reviewe = this.sReviewed;
    this.api.onBillSearch('baskinprint', this.searchBillModel).subscribe(result => { this.bills = result; }, error => {
      this.error = error;
      this.loading = false;
      console.error(error);
    });
  }

  editGrn(u: BillModel) {
    this.bills.forEach(x => {
      x.isEdit = u.baskinPrintId == x.baskinPrintId;
      if (u.baskinPrintId == x.baskinPrintId) {
        this.editgrnData = new BillModel();
        this.editgrnData.billId = u.billId;
        this.editgrnData.printed = u.printed;
        this.editgrnData.printedString = u.printed ? "true" : "false";
        this.editgrnData.remarks = u.remarks;
        this.editgrnData.baskinPrintId = u.baskinPrintId;
      }
    });
  }

  editgrnData: BillModel;

  saveGrn(u: BillModel) {
    this.editgrnData.printed = this.editgrnData.printedString == "true";
    this.api.SavePrint(this.editgrnData)
      .subscribe(() => {
        this.loading = false;
        this.getBills(this.searchBillModel.page);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  cancelGrn(u: BillModel) {
    this.bills.forEach(x => {
      x.isEdit = false;
    });
  }


  onCollBillSearch() {
    this.getBills(0);
  }

  onCollBillSearchReset() {
    this.searchBillModel = new BillModel();
    this.bills = [];
  }

  selectChangeHandler11(event: any) {
    this.sReviewed = event.target.value;
  }

  selectChangeHandler(event: any) {
    this.searchBillModel.fromDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.searchBillModel.toDate = event.target.value;
  }
  onpage(next: boolean) {
    if (next) {
      this.getBills(this.searchBillModel.page + 1);
    }
    else {
      if (this.searchBillModel.page >= 1)
        this.getBills(this.searchBillModel.page - 1);
    }
  }
}

