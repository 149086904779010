<div class="row">
    <div class="col">
        <div class="row">
            <div class="col-md-1">
                <label for="company">Company</label>
                <select class="form-control" [(ngModel)]="selectedcompany">
                    <option value="All">All</option>
                    <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
                </select>
            </div>
            <div class="col-md-1">
                <label for="accBalance">Account</label>
                <select class="form-control" [(ngModel)]="selectedAccount">
                    <option value="-1">All</option>
                    <option value="0">General</option>
                    <option *ngFor="let p of persons" [value]="p.accountId">{{p.accountName}}</option>
                </select>
            </div>
            <div class="col-md-1">
                <div>
                    <label>Customer</label>
                    <input type="text" class="form-control" [(ngModel)]="selectedtext" />
                </div>
            </div>
            <div class="col-md-1" *DataCustomAclOnly="'Admin:Company'">
                <div>
                    <label>Route</label>
                    <input type="text" class="form-control" [(ngModel)]="selectedtext1" />
                </div>
            </div>
            <div class="col-md-1">
                <label></label><br>
                <div>
                    <button class="btn btn-sm btn-info" (click)="getBSaleManTrackModel(0)">Search</button> &nbsp;
                </div>
            </div>
        </div><br>
        <div class="form-row">
            <nav aria-label="...">
                <ul class="pagination justify-content-end">
                    <li class="page-item"><a class="page-link" (click)="newCust()">New
                            Customer</a></li>
                    <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a></li>
                    <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                    <li class="page-item">
                        <span class="page-link">Page: {{page+1}}</span>
                    </li>
                </ul>
            </nav>
            <input name="file1" id="file1" (change)="uploadMT($event)" type="file" #inputFile1 hidden>
            <input name="file" id="file" (change)="upload($event)" type="file" #inputFile hidden>
            <div *ngIf="shownewCust">
                <form [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
                    <div class="form-row">
                        <div class="col-md-2">
                            <label for="customerName">Customer Name</label>
                            <input type="text" formControlName="customerName" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.customerName.errors }" />
                            <div *ngIf="submitted && f.customerName.errors" class="invalid-feedback">
                                <div *ngIf="f.customerName.errors.required">Customer Name is required</div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label for="contactName">Contact Name</label>
                            <input type="text" formControlName="contactName" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.contactName.errors }" />
                            <div *ngIf="submitted && f.contactName.errors" class="invalid-feedback">
                                <div *ngIf="f.contactName.errors.required">ContactName is required</div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label for="contactPhone">Contact Phone</label>
                            <input type="text" formControlName="contactPhone" class="form-control" maxlength="10"
                                [ngClass]="{ 'is-invalid': submitted && f.contactPhone.errors }" />
                            <div *ngIf="submitted && f.contactPhone.errors" class="invalid-feedback">
                                <div *ngIf="f.contactPhone.errors.required">Contact Phone is required</div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label for="PO">Image</label>
                            <label class="btn btn-sm btn-info" (click)="inputFilePO.click()">Select
                                Image/PDF</label> <label>{{fileName}}</label>
                        </div>
                        <div class="col-md-2">
                            <label for="address">Address</label>
                            <input type="text" formControlName="address" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                <div *ngIf="f.address.errors.required">Address is required</div>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <label for="information3">Store Times</label>
                            <input type="text" formControlName="information3" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.information3.errors }" />
                            <div *ngIf="submitted && f.information3.errors" class="invalid-feedback">
                                <div *ngIf="f.information3.errors.required">Information3 is required</div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label for="gst">GST</label>
                            <input type="text" formControlName="gst" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.gst.errors }" />
                            <div *ngIf="submitted && f.gst.errors" class="invalid-feedback">
                                <div *ngIf="f.gst.errors.required">GST is required</div>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <label for="latitude">Latitude</label>
                            <input type="text" formControlName="latitude" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.latitude.errors }" />
                            <div *ngIf="submitted && f.latitude.errors" class="invalid-feedback">
                                <div *ngIf="f.latitude.errors.required">Latitude is required</div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label for="longitude">Longitude</label>
                            <input type="text" formControlName="longitude" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.longitude.errors }" />
                            <div *ngIf="submitted && f.longitude.errors" class="invalid-feedback">
                                <div *ngIf="f.longitude.errors.required">Longitude is required</div>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <label for="mapLink">Map Link</label>
                            <input type="text" formControlName="mapLink" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.mapLink.errors }" />
                            <div *ngIf="submitted && f.mapLink.errors" class="invalid-feedback">
                                <div *ngIf="f.mapLink.errors.required">MapLink is required</div>
                            </div>
                            <a (click)="getcurr()" class="btn btn-outline-primary">
                                Get Location
                            </a>
                        </div>
                    </div>
                    <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Save
                    </button>
                    <a (click)="newCust()" class="btn btn-outline-primary">
                        Close
                    </a>
                </form>
            </div>
            <br> <br> <input name="filePO" id="filePO" (change)="uploadPO($event)" type="file" #inputFilePO hidden>

            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Customer</th>
                        <th scope="col">VisitDay</th>
                        <th scope="col">Person</th>
                        <th scope="col">Route</th>
                        <th scope="col">Company</th>
                        <th scope="col">Account</th>
                        <th scope="col" *DataCustomAclOnly="'Admin:Sales'"></th>
                        <th scope="col" *DataCustomAclOnly="'Admin:Sales'"></th>
                    </tr>
                </thead>
                <tbody *ngFor="let u of custReport">
                    <tr [class.bg-success]="isOrdered(u)">
                        <td>{{u.customerName}}</td>
                        <td>
                            <div *ngIf="!u.isEdit">
                                <span *ngIf="u.visitDay==1">Sun</span>
                                <span *ngIf="u.visitDay==2">Mon</span>
                                <span *ngIf="u.visitDay==3">Tue</span>
                                <span *ngIf="u.visitDay==4">Wed</span>
                                <span *ngIf="u.visitDay==5">Thu</span>
                                <span *ngIf="u.visitDay==6">Fri</span>
                                <span *ngIf="u.visitDay==7">Sat</span>
                            </div>
                            <select *ngIf="u.isEdit" class="form-control" [(ngModel)]="editgrnData.visitDay">
                                <option disabled selected value="">choose</option>
                                <option [value]="1">Sun</option>
                                <option [value]="2">Mon</option>
                                <option [value]="3">Tue</option>
                                <option [value]="4">Wed</option>
                                <option [value]="5">Thu</option>
                                <option [value]="6">Fri</option>
                                <option [value]="7">Sat</option>
                            </select>
                        </td>
                        <td>
                            <div *ngIf="!u.isEdit">{{u.personName}}</div>
                            <select *ngIf="u.isEdit" class="form-control" [(ngModel)]="editgrnData.personId">
                                <option disabled selected value="">choose</option>
                                <option *ngFor="let p of persons1" [value]="p.personId">{{p.name}}</option>
                            </select>
                        </td>
                        <td>{{u.route}}</td>
                        <td>{{u.companyName}}</td>
                        <td>{{u.accountName}}</td>
                        <td *DataCustomAclOnly="'Admin:Sales'">
                            <button class="btn btn-sm btn-info" *ngIf="!u.isEdit" (click)="editGrn(u)">Edit</button>
                            <button class="btn btn-sm btn-info" *ngIf="u.isEdit" (click)="saveGrn(u)">Save</button>
                        </td>
                        <td *DataCustomAclOnly="'Admin:Sales'">
                            <button class="btn btn-sm btn-info" *ngIf="u.isEdit" (click)="cancelGrn(u)">Cancel</button>
                        </td>
                    </tr>
                    <tr *ngIf="u.showDetail">
                        <td colspan="19">
                            <div class="btn-group" role="group">
                                <button type="button" class="btn btn-info"
                                    (click)="onTabSelect('1',u.customerId)">Bills</button>
                                <button type="button" class="btn btn-success" (click)="onTabSelect('2',u.customerId)">
                                    Cheques
                                </button>
                            </div><br>
                            <div class="form-row">
                                <div class="col" *ngIf="selTab=='1'">
                                    <div class="form-row">
                                        <div class="col">
                                            <table
                                                class="table table-striped table-sm table-responsive table-condensed">
                                                <thead class="thead-dark">
                                                    <th scope="col"><i class="fa fa-inr"></i></th>
                                                    <th scope="col">Margin</th>
                                                    <th scope="col" *DataCustomAclOnly="'Admin:Sales'"></th>
                                                    <th scope="col" *DataCustomAclOnly="'Admin:Sales'"></th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input type="checkbox" [(ngModel)]="u.cashCarry">
                                                        </td>
                                                        <td [class.bg-danger]="isdanger(u.configMargin)">
                                                            <input type="text" [(ngModel)]="u.configMargin"
                                                                class="form-control" appTwoDigitDecimaNumber
                                                                style="max-width: 70px;">
                                                        </td>
                                                        <td class="at1" *DataCustomAclOnly="'Admin:Sales'">
                                                            <textarea *ngIf="!u.isEdit && u.baskinSaleManTrackId>0"
                                                                rows="2" [(ngModel)]="u.orderText"
                                                                class="form-control"></textarea>
                                                        </td>
                                                        <td *DataCustomAclOnly="'Admin:Sales'">
                                                            <i *ngIf="!u.isEdit && u.baskinSaleManTrackId>0"
                                                                (click)="postOrder(u)" class="fa fa-check"></i>
                                                            <br>
                                                            <i *ngIf="!u.isEdit && u.baskinSaleManTrackId>0"
                                                                (click)="selectledger(u);inputFile.click()"
                                                                class="fa fa-camera"></i>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-md-2">
                                            <table
                                                class="table table-striped table-sm table-responsive table-condensed">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">Upload Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let u of suppliedDocs">
                                                        <td>
                                                            <button class="btn btn-sm btn-info"
                                                                (click)="onDownloadDocument1(u.documentId, u.documentName)">View</button>
                                                        </td>
                                                        <td>{{u.uploadDate| date: 'dd/MM/yy hh:mm a'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col" *ngIf="pendingBills.length>0">
                                            <table
                                                class="table table-striped table-sm table-responsive table-condensed">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                        <th scope="col" *DataCustomAclOnly="'Admin:Sales'"></th>
                                                        <th scope="col" *DataCustomAclOnly="'Admin:Sales'"></th>
                                                        <th scope="col">Bill Age</th>
                                                        <th scope="col">Company</th>
                                                        <th scope="col">Bill#</th>
                                                        <th scope="col">Bill Date</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Collected</th>
                                                        <th scope="col">Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let u of pendingBills">
                                                    <tr [class.table-danger]="u.companyName==this.selectedCompany1">
                                                        <td>
                                                            <i class="fa fa-angle-down" (click)="showDetail1(u)"></i>
                                                        </td>
                                                        <td><button class="btn btn-sm btn-info"
                                                                (click)="downloadBills(u)"><i
                                                                    class="fa fa-download"></i></button>
                                                        </td>
                                                        <td *DataCustomAclOnly="'Admin:Sales'">
                                                            <textarea rows="2" [(ngModel)]="u.orderText"
                                                                class="form-control form-control-sm"></textarea>
                                                        </td>
                                                        <td *DataCustomAclOnly="'Admin:Sales'">
                                                            <i (click)="postOrder1(u)" class="fa fa-check"></i>
                                                            <br>
                                                            <i (click)="inputFile2.click()" class="fa fa-camera"></i>
                                                            <input name="file2" id="file2" (change)="upload2($event,u)"
                                                                type="file" #inputFile2 hidden>
                                                        </td>
                                                        <td>{{calculateDiff(u.billDate) }}</td>
                                                        <td>{{u.companyName}}</td>
                                                        <td>{{u.billNumber}}</td>
                                                        <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
                                                        <td>{{u.billValue }}</td>
                                                        <td>{{u.colVal }}</td>
                                                        <td>{{u.balance }}</td>
                                                    </tr>
                                                    <tr *ngIf="u.showDetail">
                                                        <td colspan="9">
                                                            <table
                                                                class="table table-striped table-sm table-responsive table-condensed">
                                                                <thead class="thead-dark">
                                                                    <tr>
                                                                        <th scope="col"></th>
                                                                        <th scope="col">Type</th>
                                                                        <th scope="col">Upload Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let u of suppliedDocs2">
                                                                        <td>
                                                                            <button class="btn btn-sm btn-info"
                                                                                (click)="onDownloadDocument(u.documentId, u.documentName)">Download</button>
                                                                        </td>
                                                                        <td>{{u.documentType}}</td>
                                                                        <td>{{u.uploadDate| date: 'dd/MM/yy hh:mm a'}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <table
                                                                class="table table-striped table-sm table-responsive table-condensed">
                                                                <thead class="thead-dark">
                                                                    <tr>
                                                                        <th scope="col">Collect Date</th>
                                                                        <th scope="col">Collected</th>
                                                                        <th scope="col">Mode</th>
                                                                        <th scope="col">CollectedBy</th>
                                                                        <th scope="col">Remarks</th>
                                                                        <th scope="col">ChequeDate</th>
                                                                        <th scope="col">Cheque#</th>
                                                                        <th scope="col">Credited Date</th>
                                                                        <th scope="col">Bounced</th>
                                                                        <th scope="col">FollowedUp</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let u of billscolls">
                                                                        <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                                                                        <td>{{u.collectionValue}}</td>
                                                                        <td>{{u.mode}}</td>
                                                                        <td>{{u.collectedBy}}</td>
                                                                        <td>{{u.remarks}}</td>
                                                                        <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
                                                                        <td>{{u.chequeNumber}}</td>
                                                                        <td>{{u.creditedDate| date: 'dd/MM/yyyy'}}</td>
                                                                        <td>{{u.bounced}}</td>
                                                                        <td>{{u.bouncedFollowedUp}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col" *ngIf="accledgers.length>0">
                                            <table
                                                class="table table-striped table-sm table-responsive table-condensed">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th *DataCustomAclOnly="'Admin:Employee:Sales'" scope="col">
                                                        </th>
                                                        <th scope="col">GRN Number</th>
                                                        <th scope="col">Remarks</th>
                                                        <th scope="col">Account</th>
                                                        <th scope="col">Customer</th>
                                                        <th scope="col">Company</th>
                                                        <th scope="col">Bill#</th>
                                                        <th scope="col">Bill Date</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Collected</th>
                                                        <th scope="col">Balance</th>
                                                        <th scope="col">Supplied</th>
                                                        <th scope="col">Status</th>
                                                    </tr>
                                                <tbody *ngFor="let u of accledgers">
                                                    <tr>
                                                        <td>
                                                            <i class="fa fa-angle-down" (click)="showDetailMT(u)"></i>
                                                        </td>
                                                        <td *DataCustomAclOnly="'Admin:Employee:Sales'">
                                                            <button class="btn btn-sm btn-info"
                                                                (click)="selectledgerMT(u);inputFile1.click()">Upload</button>&nbsp;
                                                        </td>
                                                        <td>
                                                            <input type="text" [(ngModel)]="u.grnNumber"
                                                                (blur)="saveGrn1(u)" class="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="text" [(ngModel)]="u.remarks"
                                                                (blur)="saveGrn1(u)" class="form-control" />
                                                        </td>
                                                        <td>{{u.accountName}}</td>
                                                        <td>{{u.customerName}}</td>
                                                        <td>{{u.companyName}}</td>
                                                        <td>{{u.billNumber }}</td>
                                                        <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
                                                        <td>{{u.billValue }}</td>
                                                        <td>{{u.colVal }}</td>
                                                        <td>{{u.billValue-u.colVal| number }}</td>
                                                        <td>{{u.suppliedDate | date: 'dd/MM/yyyy'}}</td>
                                                        <td>{{u.billStatus }}</td>
                                                    </tr>
                                                    <tr *ngIf="u.showDetail">
                                                        <td colspan="14">
                                                            <div class="row" *ngIf="selAcled1.billId >0">
                                                                <div class="col-md-2" *ngIf="fileTypeMT">
                                                                    <button class="btn btn-sm btn-info"
                                                                        (click)="onUploadMT()">Upload Selected
                                                                        {{fileTypeMT}}</button>&nbsp;
                                                                    <button class="btn btn-sm btn-info"
                                                                        (click)="onUploadCancelMT()">Cancel</button><br>
                                                                    <img [src]="imageSrcMT" *ngIf="imageSrcMT"
                                                                        style="width: 100%;height: auto;">
                                                                </div>
                                                                <div class="col">
                                                                    <table
                                                                        class="table table-striped table-sm table-responsive table-condensed">
                                                                        <thead class="thead-dark">
                                                                            <tr>
                                                                                <th scope="col"></th>
                                                                                <th scope="col">Type</th>
                                                                                <th scope="col">Upload Date</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let u of suppliedDocsMT">
                                                                                <td>
                                                                                    <button class="btn btn-sm btn-info"
                                                                                        (click)="onDownloadDocument(u.documentId, u.documentName)">Download</button>
                                                                                </td>
                                                                                <td>{{u.documentType}}</td>
                                                                                <td>{{u.uploadDate| date: 'dd/MM/yy
                                                                                    hh:mm a'}}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100"></div>
                                <div class="col" *ngIf="selTab=='2'">
                                    <div class="form-row">
                                        <div class="col-md-3">
                                            Bounced Checks
                                            <table
                                                class="table table-striped table-sm table-responsive table-condensed">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th scope="col">Bill#</th>
                                                        <th scope="col">Check Details</th>
                                                    </tr>
                                                <tbody>
                                                    <tr *ngFor="let u of pendingFollowups">
                                                        <td>{{u.billNumber}}</td>
                                                        <td>{{u.bouncedCheckDetail }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col">
                                            Pending Cheque Clerance
                                            <table
                                                class="table table-striped table-sm table-responsive table-condensed">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th scope="col">Cheque#</th>
                                                        <th scope="col">Cheque Date</th>
                                                        <th scope="col">Collected</th>
                                                        <th scope="col">Remarks</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Mode</th>
                                                        <th scope="col">CollectedBy</th>
                                                        <th scope="col">Bill Date</th>
                                                        <th scope="col">Bill#</th>
                                                        <th scope="col">Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let u of colls">
                                                        <td>{{u.chequeNumber}}</td>
                                                        <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
                                                        <td>{{u.collectionValue}}</td>
                                                        <td>{{u.remarks}}</td>
                                                        <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                                                        <td>{{u.mode}}</td>
                                                        <td>{{u.collectedBy}}</td>
                                                        <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                                                        <td>{{u.billNumber}}</td>
                                                        <td>{{u.billValue}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>