import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { CollectionPendingReview, PersonCollectionReport, CollectionPersonVolumneByMonth, CollectonPersonModel } from '../models';
import { HttpEventType } from '@angular/common/http';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-collection-report',
  templateUrl: './collection-report.component.html',
  styleUrls: ['./collection-report.component.css']
})
export class CollectionReportComponent implements OnInit {

  collectionPendingReview: CollectionPendingReview[] = [];
  personCollectionReport: PersonCollectionReport[] = [];
  collectionPersonVolumneByMonth: CollectionPersonVolumneByMonth[] = [];
  persons1: CollectonPersonModel[] = [];
  selectedPerson = 0;
  selectedPerson1 = 0;

  loading = false;
  error = '';

  constructor(private api: RayarApiService) {
    this.getCollPersons();

  }

  ngOnInit(): void {
  }

  getPendingReviewReport() {
    this.api.getCollectionPendingReview()
      .subscribe(result => { this.collectionPendingReview = result; },
        error => { console.error(error); });
  }

  getCollPersons() {
    this.api.getCollPersons().subscribe(result => {
      this.persons1 = result;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  selectChangeHandler(event: any) {
    this.selectedPerson = parseInt(event.target.value);
  }

  selectChangeHandler1(event: any) {
    this.selectedPerson1 = parseInt(event.target.value);
  }

  OnpersonCollectionReport() {
    this.api.getPersonCollectionReport(this.selectedPerson)
      .subscribe(result => { this.personCollectionReport = result; }, error => { console.error(error); });
  }

  OnCollectionPersonReport() {
    this.api.getCollectionPersonVolumneByMonth(this.selectedPerson1)
      .subscribe(result => { this.collectionPersonVolumneByMonth = result; }, error => { console.error(error); });
  }

  public download(q: string) {
    this.api.downloadFile(q).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }
}
