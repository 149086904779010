<div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
<div class="row">
    <div class="col">
        <div class="form-row">
            <div class="col-md-2">
                <label for="accBalance">Company</label>
                <select class="form-control" [(ngModel)]="searchBillModel.companyName">
                    <option value="0" selected disabled>Choose</option>
                    <option *ngFor="let p of persons" [value]="p.companyName">{{p.companyName}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label>From</label>
                <input type="date" [ngModel]="searchBillModel.fromDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler($event)" class="form-control" />
            </div>
            <div class="col-md-2">
                <label>To</label>
                <input type="date" [ngModel]="searchBillModel.toDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler1($event)" class="form-control" />
            </div>
            <div class="col-md-2">
                <label> </label>
                <div>
                    <button class="btn btn-outline-primary" (click)="onCollBillSearch()">Search</button>
                    <button class="btn btn-outline-primary" (click)="onCollBillSearchReset()">Reset</button>
                </div>
            </div>
            <div class="col-md-2">
                <label> </label>
                <div>
                    <button class="btn btn-outline-primary" (click)="downloadAccLedReport()">Download</button>
                </div>
            </div>
        </div>
        <input name="file" id="file" (change)="uploadLedger($event)" type="file" #inputFile hidden>
        <div class="row">
            <div class="col">
                <form [formGroup]="billAddEditForm" (ngSubmit)="onBillSubmit()">
                    <div class="form-row">
                        <div class="col-md-2">
                            <label for="invoiceType">Category</label>
                            <select formControlName="invoiceType" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.invoiceType.errors }">
                                <option value="" selected disabled>Choose</option>
                                <option value="Payment">Payment</option>
                                <option value="PurchaseReturn">PurchaseReturn</option>
                                <option value="Claims">Claims</option>
                                <option value="+Adjustment">+Adjustment</option>
                                <option value="-Adjustment">-Adjustment</option>
                            </select>
                            <div *ngIf="submitted && f.invoiceType.errors" class="invalid-feedback">
                                <div *ngIf="f.invoiceType.errors.required">Category is required</div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label for="invoiceValue">Amount</label>
                            <input type="number" formControlName="invoiceValue" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.invoiceValue.errors }" />
                            <div *ngIf="submitted && f.invoiceValue.errors" class="invalid-feedback">
                                <div *ngIf="f.invoiceValue.errors.required">Amount is required</div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label for="remarks">Remarks</label>
                            <input type="text" formControlName="remarks" class="form-control" maxlength="200"
                                [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }" />
                            <div *ngIf="submitted && f.remarks.errors" class="invalid-feedback">
                                <div *ngIf="f.remarks.errors.required">Remarks is required</div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label for="remarks"></label>
                            <br>
                            <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-4">
                <table class="table table-striped table-sm  table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Count</th>
                            <th scope="col">Balance </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>&lt;21</td>
                            <td>{{total11.billcount5}}</td>
                            <td>{{total11.billAmount5|currency:'INR': 'symbol' : '1.0-0'}}</td>
                        </tr>
                        <tr>
                            <td>21+</td>
                            <td>{{total11.billcount1}}</td>
                            <td>{{total11.billAmount1|currency:'INR': 'symbol' : '1.0-0'}}</td>
                        </tr>
                        <tr>
                            <td>42+</td>
                            <td>{{total11.billcount2}}</td>
                            <td>{{total11.billAmount2|currency:'INR': 'symbol' : '1.0-0'}}</td>
                        </tr>
                        <tr>
                            <td>63+</td>
                            <td>{{total11.billcount3}}</td>
                            <td>{{total11.billAmount3|currency:'INR': 'symbol' : '1.0-0'}}</td>
                        </tr>
                        <tr class="bg-warning">
                            <td>Total</td>
                            <td>{{total11.billcount}}</td>
                            <td>{{total11.billAmount|currency:'INR': 'symbol' : '1.0-0'}}</td>
                        </tr>
                        <tr class="bg-info">
                            <td>Total 21+</td>
                            <td>{{total11.billcount4}}</td>
                            <td>{{total11.billAmount4|currency:'INR': 'symbol' : '1.0-0'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-8">
                <table class="table table-striped table-sm  table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">CompanyName </th>
                            <th scope="col">Years</th>
                            <th scope="col">Months</th>
                            <th scope="col">Dates</th>
                            <th scope="col">CANCEL</th>
                            <th scope="col">PAID</th>
                            <th scope="col">COLLASGND</th>
                            <th scope="col">NEW</th>
                            <th scope="col">SUPPLIED</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let u of getData()">
                            <td>{{u.companyName}}</td>
                            <td>{{u.years}}</td>
                            <td>{{u.months}}</td>
                            <td>{{u.dates}}</td>
                            <td>{{u.cancel |currency:'INR': 'symbol' : '1.0-0'}}</td>
                            <td>{{u.paid |currency:'INR': 'symbol' : '1.0-0'}}</td>
                            <td>{{u.collasgnd |currency:'INR': 'symbol' : '1.0-0'}}</td>
                            <td>{{u.new |currency:'INR': 'symbol' : '1.0-0'}}</td>
                            <td>{{u.supplied |currency:'INR': 'symbol' : '1.0-0'}}</td>
                            <td>{{u.total|currency:'INR': 'symbol' : '1.0-0'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div><Br>
        <table class="table table-striped table-sm table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th scope="col">SlNo</th>
                    <th scope="col">Transaction Date</th>
                    <th scope="col">Description</th>
                    <th scope="col">CrDr</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Running Balance</th>
                </tr>
            </thead>
            <tbody *ngFor="let u of accledgers">
                <tr>
                    <td>
                        <button *ngIf="u.crDr!='Invoice'" class="btn btn-sm btn-info"
                            (click)="showDetail(u)">{{u.showDetail?'Hide':'Show'}}</button>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-info"
                            (click)="selectlinvoideTrak(u);inputFile.click()">Upload</button>&nbsp;
                    </td>
                    <td>
                        <button *ngIf="u.doc ==1" class="btn btn-sm btn-info" (click)="downloadBills(u)"><i
                                class="fa fa-download"></i></button>
                    </td>
                    <td>{{u.rowNum}}</td>
                    <td>{{u.transactionDate | date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.descriptions }}</td>
                    <td>{{u.crDr }}</td>
                    <td>{{u.amount }}</td>
                    <td>{{u.runningBalance }}</td>
                </tr>
                <tr *ngIf="u.showDetail">
                    <td colspan="100">
                        <div class="row">
                            <div class="col-md-2">
                                <label for="invoiceValue">Amount</label>
                                <input type="number" [(ngModel)]="amount" class="form-control" min="0" />
                            </div>
                            <div class="col-md-2">
                                <label for="remarks">Remarks</label>
                                <input type="text" [(ngModel)]="remarks" class="form-control" maxlength="2000" />
                            </div>
                            <div class="col-md-2">
                                <label for="remarks"></label>
                                <br>
                                <button type="submit" (click)="onUpdate(u)" class="btn btn-outline-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                        <Br>
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table table-striped table-sm table-responsive table-condensed">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">User</th>
                                            <th scope="col">Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let u of suppliedDocs">
                                            <td>{{u.enteredDate| date: 'dd/MM/yy hh:mm a'}}</td>
                                            <td>{{u.userName}}</td>
                                            <td>{{u.notes}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>