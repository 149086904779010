<div class="card">
    <h4 class="card-header">Check Out</h4>
    <div class="card-body">
        <h4>Time : {{ time | date: 'medium' }}</h4>
        <table class="table table-striped">
            <thead class="thead-light">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Absent</th>
                    <th scope="col">In Time</th>
                    <th scope="col">Vehicle</th>
                    <th scope="col">In OdoMeter</th>
                    <th scope="col">In Remarks</th>
                    <th scope="col">Out Time</th>
                    <th scope="col">Fuel In Amount</th>
                    <th scope="col">Out OdoMeter</th>
                    <th scope="col">Out Remarks</th>
                    <th scope="col">KM</th>
                    <th scope="col">#</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of bills">
                    <td>{{u.resourceName}}</td>
                    <td>{{u.isReviewed}} </td>
                    <td>{{u.checkInTime | date: 'shortTime'}} </td>
                    <td>{{u.vehicleName}}</td>
                    <td>{{u.checkIn}}</td>
                    <td>{{u.reason}}</td>
                    <td>{{u.checkOutTime | date: 'shortTime'}} </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.fuel }}</div>
                        <input type="number" *ngIf="u.isEdit" [(ngModel)]="editgrnData.fuel" class="form-control" />
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.checkOut }}</div>
                        <input type="number" *ngIf="u.isEdit" [(ngModel)]="editgrnData.checkOut" class="form-control" />
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.reasonOut }}</div>
                        <input type="text" *ngIf="u.isEdit" [(ngModel)]="editgrnData.reasonOut" class="form-control" />
                    </td>
                    <td>{{u.checkOut-u.checkIn }}</td>
                    <td>
                        <button class="btn btn-outline-primary" *ngIf="u.checkInTime && !u.isEdit"
                            (click)="editGrn(u)">Out</button>
                        <button class="btn btn-outline-primary" *ngIf="!u.checkInTime && !u.isEdit && !u.isReviewed"
                            (click)="markAbsent(u)">Absent</button>
                        <button class="btn btn-outline-primary" *ngIf="u.isEdit" (click)="saveGrn(u)">Save</button>
                        <button class="btn btn-outline-primary" *ngIf="u.isEdit" (click)="cancelGrn(u)">Cancel</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>