import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { paymentModel, User } from '../models';
import { AuthenticationService } from '../_services/authentication.service';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  loading = false;
  error = '';
  searchreviewed: string = '2';
  search: paymentModel = new paymentModel();
  payments: paymentModel[] = [];
  pagenumber1 = 1;
  currentUser: User;

  constructor(private authenticationService: AuthenticationService, private api: RayarApiService) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.search.page = 0;
    this.search.pageSize = 20;
    this.search.createdBy = this.currentUser.userName;
    this.onSearchPayment(0);
  }

  ngOnInit(): void {
  }

  onGoto() {
    if (this.pagenumber1 > 0) {
      this.onSearchPayment(this.pagenumber1 - 1);
    }
  }

  onpage(next: boolean) {
    if (next) {
      this.onSearchPayment(this.search.page + 1);
    }
    else {
      if (this.search.page >= 1)
        this.onSearchPayment(this.search.page - 1);
    }
  }

  onSearchPayment(p: number) {
    this.search.page = p;
    if (this.searchreviewed == "2") this.search.reviewed = null;
    if (this.searchreviewed == "1") this.search.reviewed = true;
    if (this.searchreviewed == "0") this.search.reviewed = false;
    this.api.getPayments(this.search).subscribe(result => {
      this.payments = result;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  onDocView(i: paymentModel) {
    this.api.downloadBlobFilePost("Download/" + i.documentId, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const fileURL = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
            window.open(fileURL, 'mywindow1');
            break;
        }
      },
      () => {
      }
    );
  }

}
