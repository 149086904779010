<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-lg-2 col-md-2">
                <label for="accBalance">Account</label>
                <select class="form-control" [(ngModel)]="searchBillModel.accountId"
                    (change)="selectChangeHandler3($event)">
                    <option value="0" selected>All</option>
                    <option *ngFor="let p of persons" [value]="p.accountId">{{p.accountName}}</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-2">
                <label for="companyName">Company</label>
                <select class="form-control" [(ngModel)]="searchBillModel.companyName">
                    <option value="All" selected>All</option>
                    <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-2">
                <label>PO#</label>
                <input type="text" [(ngModel)]="searchBillModel.poNumber" class="form-control" />
            </div>
            <div class="col-lg-2 col-md-2">
                <label>Bill/GRN/Remarks</label>
                <input type="text" [(ngModel)]="searchBillModel.remarks" class="form-control" />
            </div>
            <div class="col-lg-2 col-md-2">
                <label>Bill Status</label>
                <select class="form-control" [(ngModel)]="searchBillModel.billStatus">
                    <option value="All" selected>All</option>
                    <option value="NotPaid">NotPaid</option>
                    <option value="Paid">Paid</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-2">
                All &nbsp;<input type="checkbox" [(ngModel)]="isAllBills" (click)="onclickIncbal()">&nbsp; Or &nbsp;
                <label>Balance Greater Than</label>
                <input type="number" [(ngModel)]="searchBillModel.balance" class="form-control"
                    [disabled]="isAllBills" />
            </div>
            <div class="col-lg-2 col-md-2">
                <label>GRN Status</label>
                <select class="form-control" [(ngModel)]="searchBillModel.grnStatus">
                    <option value="All">ALL</option>
                    <option value="NoGRN">NoGRN</option>
                    <option value="HalfGRN">HalfGRN</option>
                    <option value="ALLGRN">ALLGRN</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-2">
                <label>PO Status</label>
                <select class="form-control" [(ngModel)]="searchBillModel.poStatus">
                    <option value="All">ALL</option>
                    <option value="NOPO">No</option>
                    <option value="YESPO">Yes</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-2">
                <label>BillDays Less Than</label>
                <input type="number" [(ngModel)]="searchBillModel.billDays" class="form-control" />
            </div>
            <div class="w-100"></div>
            <div class="col-lg-1 col-md-2">
                <label> </label>
                <div>
                    <button class="btn btn-sm btn-info" (click)="onCollBillSearch(0)">Search</button> &nbsp;
                </div>
            </div>
            <div class="col-lg-1 col-md-1">
                <label> </label>
                <div>
                    <button class="btn btn-sm btn-info" (click)="download()">Download</button> &nbsp;
                </div>
            </div>
            <div class="col-lg-1 col-md-1" *DataCustomAclOnly="'Admin'">
                <label> </label>
                <div>
                    <button class="btn btn-sm btn-info"
                        (click)="onPaymentRequest('PaymentRequest')">PaymentRequest</button> &nbsp;
                </div>
            </div>
            <div class="col-md-1" *DataCustomAclOnly="'Admin'">
                <label> </label>
                <div>
                    <button class="btn btn-sm btn-info" (click)="onPaymentRequest('GRNAdvice')">GrnAdvice</button>
                    &nbsp;
                </div>
            </div>
        </div>
        <br>
        <input name="file" id="file" (change)="upload($event)" type="file" #inputFile hidden>
        <nav aria-label="...">
            <ul class="pagination">
                <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a></li>
                <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                <li class="page-item">
                    <span class="page-link">Page: {{pagenumber+1}}</span>
                </li>
            </ul>
        </nav>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col"></th>
                    <!-- <th *DataCustomAclOnly="'Admin'" scope="col">-->
                    <th *DataCustomAclOnly="'Admin:Employee:Sales'" scope="col"></th>
                    <th *DataCustomAclOnly="'Admin:Employee:Sales'" scope="col"></th>
                    <th *DataCustomAclOnly="'Admin:Employee:Sales'" scope="col"></th>
                    <th *DataCustomAclOnly="'Admin'" scope="col"></th>
                    <th scope="col">GRN Number</th>
                    <th scope="col">Remarks</th>
                    <th scope="col">GRN Status</th>
                    <th *DataCustomAclOnly="'Admin:Employee'" scope="col">IRAmount</th>
                    <th *DataCustomAclOnly="'Admin:Employee'" scope="col">IRNumber</th>
                    <th *DataCustomAclOnly="'Admin:Employee'" scope="col"></th>
                    <th scope="col">Account</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Company</th>
                    <th scope="col">Bill#</th>
                    <th scope="col">PO#</th>
                    <th scope="col">Bill Days</th>
                    <th scope="col">BillSuppAge</th>
                    <th scope="col">Bill Date</th>
                    <th scope="col">Bill Value</th>
                    <th scope="col">Collected</th>
                    <th scope="col">Balance</th>
                    <th scope="col">Supplied Date</th>
                    <th scope="col">Status</th>
                </tr>
            <tbody *ngFor="let u of accledgers">
                <tr [class.bg-success]="u.selected">
                    <td>
                        <i class="fa fa-angle-down" (click)="showDetail(u)"></i>
                    </td>
                    <!-- <td *DataCustomAclOnly="'Admin'">
                        <button class="btn btn-sm btn-info" *ngIf="u.billStatus!='PAID'"
                            (click)="markPaid(u)">Paid</button>
                    </td>-->
                    <td *DataCustomAclOnly="'Admin:Employee:Sales'">
                        <button class="btn btn-sm btn-info" (click)="selectledger(u);inputFile.click()">Upload</button>
                    </td>
                    <td *DataCustomAclOnly="'Admin:Employee:Sales'">
                        <button class="btn btn-sm btn-info" *ngIf="!u.isEdit" (click)="editGrn(u)">Edit</button>
                        <button class="btn btn-sm btn-info" *ngIf="u.isEdit" (click)="saveGrn()">Save</button>
                    </td>
                    <td *DataCustomAclOnly="'Admin:Employee:Sales'">
                        <button class="btn btn-sm btn-info" *ngIf="u.isEdit" (click)="cancelGrn()">Cancel</button>
                    </td>
                    <td *DataCustomAclOnly="'Admin'">
                        <input type="checkbox" [(ngModel)]="u.selected">
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.grnNumber }}</div>
                        <input type="text" *ngIf="u.isEdit" [(ngModel)]="editgrnData.grnNumber" class="form-control" />
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.remarks }}</div>
                        <input type="text" *ngIf="u.isEdit" [(ngModel)]="editgrnData.remarks" class="form-control" />
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.grnStatus }}</div>
                        <select *ngIf="u.isEdit" class="form-control" [(ngModel)]="editgrnData.grnStatus">
                            <option value="NoGRN">NoGRN</option>
                            <option value="HalfGRN">HalfGRN</option>
                            <option value="ALLGRN">ALLGRN</option>
                        </select>
                    </td>
                    <td class="elip" *DataCustomAclOnly="'Admin:Employee'">
                        <input type="number" [(ngModel)]="u.shortage" class="form-control" />
                    </td>
                    <td class="elip" *DataCustomAclOnly="'Admin:Employee'">
                        <input type="text" [(ngModel)]="u.shortageRemarks" class="form-control" />
                    </td>
                    <td *DataCustomAclOnly="'Admin:Employee'">
                        <button class="btn btn-sm btn-info" (click)="postShortage(u)">PostIR</button>
                    </td>
                    <td>{{u.accountName}}</td>
                    <td>{{u.customerName}}</td>
                    <td>{{u.companyName}}</td>
                    <td>{{u.billNumber }}</td>
                    <td>{{u.poNumber }}</td>
                    <td>{{u.billDays }}</td>
                    <td>{{u.suppliedDays }}</td>
                    <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.billValue }}</td>
                    <td>{{u.colVal }}</td>
                    <td>{{u.billValue-u.colVal| number }}</td>
                    <td>{{u.suppliedDate | date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.billStatus }}</td>
                </tr>
                <tr *ngIf="u.showDetail">
                    <td colspan="14">
                        <div class="row" *ngIf="selAcled.billId >0">
                            <div class="col">
                                <table class="table table-striped table-sm table-responsive table-condensed">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">CreatedBy</th>
                                            <th scope="col">CreatedDate</th>
                                            <th scope="col">CollectionValue</th>
                                            <th scope="col">Mode</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">CrDr</th>
                                            <th scope="col">TransactionAmount</th>
                                            <th scope="col">TransPostedDate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let u of collectionDetails">
                                            <td>{{u.createdBy}}</td>
                                            <td>{{u.createdDate| date: 'dd/MM/yy hh:mm a'}}</td>
                                            <td>{{u.collectionValue}}</td>
                                            <td>{{u.mode}}</td>
                                            <td>{{u.description}}</td>
                                            <td>{{u.crDr}}</td>
                                            <td>{{u.transactionAmount}}</td>
                                            <td>{{u.transPostedDate| date: 'dd/MM/yy hh:mm a'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row" *ngIf="selAcled.billId >0">
                            <div class="col-md-2" *ngIf="fileType">
                                <button class="btn btn-sm btn-info" (click)="onUpload()">Upload Selected
                                    {{fileType}}</button>&nbsp;
                                <button class="btn btn-sm btn-info" (click)="onUploadCancel()">Cancel</button><br>
                                <img [src]="imageSrc" *ngIf="imageSrc" style="width: 100%;height: auto;">
                            </div>
                            <div class="col">
                                <table class="table table-striped table-sm table-responsive table-condensed">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col"><i (click)="downloadBills(u)" class="fa fa-home"></i></th>
                                            <th scope="col"></th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Upload Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let u of suppliedDocs">
                                            <td>
                                                <button class="btn btn-sm btn-info"
                                                    (click)="onDownloadDocument(u.documentId, u.documentName)">Download</button>
                                            </td>
                                            <td>
                                                <button *DataCustomAcl="'Admin'" class="btn btn-sm btn-info"
                                                    (click)="onDeleteDocument(u.documentId)">Delete</button>
                                            </td>
                                            <td>{{u.documentType}}</td>
                                            <td>{{u.uploadDate| date: 'dd/MM/yy hh:mm a'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>