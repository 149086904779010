import { Component, OnInit } from '@angular/core';
import { CollectionModel, CollectonPersonModel, BillModel, noteTrackModel, User } from '../models';
import { AuthenticationService } from '../_services/authentication.service';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-bounced-cheques',
  templateUrl: './bounced-cheques.component.html',
  styleUrls: ['./bounced-cheques.component.css']
})
export class BouncedChequesComponent implements OnInit {
  submitted = false;
  loading = false;
  error = '';
  persons1: CollectonPersonModel[] = [];
  colls: CollectionModel[] = [];
  colls1: CollectionModel[] = [];
  searchBillModel: BillModel = new BillModel();
  currentUser: User;

  constructor(private authenticationService: AuthenticationService, private api: RayarApiService) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.onSearchPerson();
    this.getCollPersons();
    this.searchBillModel.personId = 0;
  }

  ngOnInit(): void {
  }

  onSearchPerson() {
    this.error = "";
    this.searchBillModel.page = 0;
    if (this.searchBillModel.personId)
      this.searchBillModel.personId = parseInt(this.searchBillModel.personId.toString());
    this.api.oncollsView('chequeReviewBounce', this.searchBillModel)
      .subscribe(result => {
        this.colls = result;
        this.colls.sort((a, b) => b.collectionId - a.collectionId);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  getCollPersons() {
    this.api.getCollPersons().subscribe(result => {
      this.persons1 = result;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }


  showDetail(u: CollectionModel) {
    this.colls.forEach(x => {
      if (u.collectionId == x.collectionId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.error = '';
          this.api.getCollsSearch(u.billId.toString(), 'GetColls').subscribe(result => { this.colls1 = result; },
            error => console.error(error));
          this.getDocuments(u.collectionId);
          var n = new noteTrackModel();
          n.id = u.collectionId;
          n.noteType = "BncChFlp";
          n.internal = false;
          n.customerId = x.customerId;
          n.billId = x.billId;
          this.addNotes = n;
        }
      }
      else
        x.showDetail = false;
    });
  }

  addNotes: noteTrackModel = new noteTrackModel();
  suppliedDocs: noteTrackModel[] = [];

  saveNoteTrack() {
    if (this.addNotes.notes)
      this.api.saveNoteTrack(this.addNotes).subscribe(result => {
        this.getDocuments(this.addNotes.id);
        this.addNotes.notes = "";
        this.addNotes.internal = false;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  getDocuments(cid: number) {
    var d = new noteTrackModel();
    d.id = cid;
    d.noteType = "BncChFlp";
    d.page = 0;
    d.pageSize = 200;
    this.api.getnoteTraks(d).subscribe(result => this.suppliedDocs = result, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  onCollSubmit(i1: number) {
    this.submitted = true;
    this.loading = true;
    if (confirm("Are you sure to Mark Followed-Up"))
      this.api.markFollowuped(i1, this.currentUser.userName + " MarkedFollowedUp")
        .subscribe(result => {
          this.submitted = false;
          this.loading = false;
          this.onSearchPerson();
        }, error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
  }

}
