import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Role, User, VehicleModel, DocumentTrackModel, AttendanceModel } from '../models';
import { AuthenticationService } from '../_services/authentication.service';
import { locationService } from '../_services/locationService';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    error = '';
    loading = false;
    currentUser: User;
    currentLat: any;
    currentLong: any;
    currloclink: string;
    vehicles: VehicleModel[] = [];
    alerts: string[] = [];
    vehicles1: VehicleModel[] = [];

    constructor(private api: RayarApiService, private router: Router,
        private authenticationService: AuthenticationService, private locationService: locationService) {
        this.currentUser = this.authenticationService.currentUserValue;
        if (this.currentUser.role === Role.Driver) {
            this.router.navigate(['/drve']);
        } else if (this.currentUser.role === Role.Admin) {
            if (this.currentUser.userName === "Admin")
                this.router.navigate(['/bpl']);
        } else if (this.currentUser.role === Role.Customer) {
            this.router.navigate(['/bills1']);
        } else {
            this.locationService.getPosition().then(pos => {
                this.currentLat = pos.lat;
                this.currentLong = pos.lng;
                this.currloclink = "https://www.google.com/maps?q=" + pos.lat + "," + pos.lng;
            });
        }
        if (this.currentUser.role === Role.Driver || this.currentUser.role === Role.Employee || this.currentUser.role === Role.Admin) {
            this.getBills(0);
        }
    }

    ngOnInit() {
    }

    dateDifference(date2: Date) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        var date1 = new Date();
        const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    getVehicles() {
        var search: VehicleModel = new VehicleModel();
        search.page = 0;
        search.category = "Vehicle";
        this.api.getSearchVehicle(search).subscribe(result => {
            this.vehicles = result.vehicles;
            this.vehicles1 = result.vehicles.filter(function (hero) {
                return hero.vehicleName != 'Godown';
            });
            result.vehicleDocs.forEach(x => {
                var exp = this.dateDifference(new Date(x.validTillDate));
                if (x.documentName == "Service") {
                    var lst1 = [];
                    this.billsAll.forEach(x1 => {
                        if (x1.vehicleName && x1.vehicleName.toLowerCase() == x.vehicleName.toLowerCase() && x1.checkOut) {
                            var odo = parseFloat(x1.checkOut.toString());
                            if (isNaN(odo)) odo = 0;
                            lst1.push(odo);
                        }
                    });
                    var currentOdoMeter = 0;
                    if (lst1.length > 0) currentOdoMeter = Math.max.apply(Math, lst1);
                    var exp = this.dateDifference(new Date(x.validTillDate));
                    if (x.documentName == "Service") {
                        var lastServicedOdo = 0;
                        if (x.notes) {
                            lastServicedOdo = parseFloat(x.notes.toString());
                            if (isNaN(lastServicedOdo)) lastServicedOdo = 0;
                        }
                        if ((currentOdoMeter - lastServicedOdo > x.serviceMileLimit))
                            this.alerts.push(x.vehicleName + " ;Service: " + exp + " days ago Serviced: " + new Date(x.validTillDate).toDateString() + " ;NextServiceMile:  " + x.serviceMileLimit + " ;Last Serviced Odo:  " + x.notes + " ;Current Odo: " + currentOdoMeter + " ; Miles run:" + (currentOdoMeter - lastServicedOdo));
                    }
                } else {
                    if (exp <= 10)
                        this.alerts.push(x.vehicleName + ": " + x.documentName + " Expires in " + exp + " days; Valid Till: " + new Date(x.validTillDate).toDateString());
                }
            });
        }, error => {
            this.error = error;
            this.loading = false; console.error(error);
        });
    }

    getCompanyDocs() {
        var search: VehicleModel = new VehicleModel();
        search.page = 0;
        search.category = "Company";
        this.api.getSearchVehicle(search).subscribe(result => {
            result.vehicleDocs.forEach(x => {
                var exp = this.dateDifference(new Date(x.validTillDate));
                if (exp <= 10)
                    this.alerts.push(x.vehicleName + ": " + x.documentName + " Expires in " + exp + " days; Valid Till: " + new Date(x.validTillDate).toDateString());
            });
        }, error => {
            this.error = error;
            this.loading = false; console.error(error);
        });
    }

    billsAll: AttendanceModel[] = [];
    searchlModel = new AttendanceModel();
    getBills(p: number) {
        this.searchlModel.isReviewed = null;
        var d = new Date();
        d.setDate(d.getDate() - 10);
        this.searchlModel.fromDate = new Date(d);
        this.searchlModel.toDate = new Date(Date.now());
        this.searchlModel.page = p;
        this.searchlModel.uniqId = null;
        this.searchlModel.timeTrackResourceId = Number(this.searchlModel.timeTrackResourceId);
        this.api.getAttendances(this.searchlModel).subscribe(result => {
            this.billsAll = result;
            this.getVehicles();
            this.getCompanyDocs();
        }, error => {
            this.error = error; this.loading = false; console.error(error);
        });
    }

    getvehicleData(vehicleName: string) {
        var lst = [];
        this.billsAll.forEach(x => {
            if (x.vehicleName && x.vehicleName.toLowerCase() == vehicleName.toLowerCase()) {
                lst.push(x);
            }
        });
        for (let i = 0; i < lst.length - 1; i++) {
            var codo = 0;
            if (lst[i].checkOut)
                codo = parseFloat(lst[i].checkOut.toString());
            if (isNaN(codo)) codo = 0;

            var podo = 0;
            if (lst[i + 1].checkOut)
                podo = parseFloat(lst[i + 1].checkOut.toString());
            if (isNaN(podo)) podo = 0;
            var f = 0;
            if (lst[i].fuel)
                f = parseFloat(lst[i].fuel.toString());
            if (isNaN(f)) f = 0;

            lst[i].miles = codo - podo;
            if (f == 0)
                lst[i].mileage = 0;
            else
                lst[i].mileage = ((codo - podo) / f).toFixed(2);
        }
        return lst;
    }


    address = "Rayar Agencies NO.1/10,8th Main Muthyala Nagar,Opp Nandish Park Apartment,Bengaluru-560054";

    maplink = "https://goo.gl/maps/BSX8zRFssJP6voWt6";

    goToLink() {
        window.open(this.maplink, "_blank");
    }
}
