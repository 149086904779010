<div class="row">
  <div class="col">
    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
    <div *ngIf="!isAddNewBillClicked">
      <div class="form-row">
        <div class="col-md-2">
          <label for="source">Source</label>
          <select class="form-control" [(ngModel)]="source">
            <option value="" selected>All</option>
            <option value="Source1" selected>Source1-Rayar</option>
            <option value="Source2">Source2-WhiteHorse</option>
            <option value="Source3">Source3-Nestling</option>
            <option value="Source4">Source4-Sunrise</option>
            <option value="Source5">Source5</option>
          </select>
        </div>
        <div class="col-md-2">
          <Label>From</Label>
          <input type="date" [ngModel]="selectedFromDate | date:'yyyy-MM-dd'" (change)="selectChangeHandlerFrom($event)"
            class="form-control" />
        </div>
        <div class="col-md-2">
          <label>To</label>
          <input type="date" [ngModel]="selectedToDate | date:'yyyy-MM-dd'" (change)="selectChangeHandlerTo($event)"
            class="form-control" />
        </div>
        <div class="col-md-2">
          <label>Description</label>
          <input type="text" [(ngModel)]="description" class="form-control" />
        </div>
        <div class="col-md-2">
          <label for="crDr">CrDr</label>
          <select class="form-control" [(ngModel)]="crDr">
            <option value="" selected>Both</option>
            <option value="CR">CR</option>
            <option value="DR">DR</option>
          </select>
        </div>

      </div>
      <br>
      <div class="form-row">

        <div class="col-md-2">
          <input type="radio" name="revRadio" value="0" [(ngModel)]="sReviewed"
            (change)="selectChangeHandler1($event)" /> Not Reviewed
          <input type="radio" name="revRadio" value="1" [(ngModel)]="sReviewed"
            (change)="selectChangeHandler1($event)" /> Reviewed
          <input type="radio" name="revRadio" value="2" [(ngModel)]="sReviewed"
            (change)="selectChangeHandler1($event)" /> Both
        </div>
        <div class="col-md-2">
          <input type="radio" name="revRadio1" value="0" [(ngModel)]="sRemar" (change)="selectChangeHandler2($event)" />
          No Remarks
          <input type="radio" name="revRadio1" value="1" [(ngModel)]="sRemar" (change)="selectChangeHandler2($event)" />
          WithRemarks
          <input type="radio" name="revRadio1" value="2" [(ngModel)]="sRemar" (change)="selectChangeHandler2($event)" />
          Both
        </div>
        <div class="col-md-2">
          <button class="btn btn-sm btn-info" (click)="onSearch()">Search</button>&nbsp;
          <button class="btn btn-sm btn-info" (click)="downloadBkStmtReport()">Download</button>&nbsp;
          <button class="btn btn-sm btn-info" *DataCustomAcl="'Admin'" (click)="getPendRevBankStmts()">Get Pending
            Review</button>
        </div>
      </div>
      <br>
      <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
      <table class="table table-striped table-sm table-responsive table-condensed">
        <thead class="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Remarks</th>
            <th scope="col">Reviewed</th>
            <th scope="col">UpdatedBy</th>
            <th scope="col">Source</th>
            <th scope="col">Has Collections</th>
            <th scope="col">Value Date</th>
            <th scope="col">CrDr</th>
            <th scope="col">Transaction Amount</th>
            <th scope="col">Description</th>
            <th scope="col">Id</th>
            <th scope="col">Transaction Id</th>
            <th scope="col">ChequeNo</th>
            <th scope="col">TransPosted Date</th>
            <th scope="col">Available Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of bills">
            <th scope="row">
              <button class="btn btn-outline-primary" (click)="editBill(u)">Update</button>
              <button class="btn btn-outline-primary" (click)="markBankStmtRev(u)"
                *ngIf="isAdmin && !u.reviewed && u.remarks">Reviewed</button>
            </th>
            <td class="elip">{{u.remarks}}</td>
            <td>{{u.reviewed}}</td>
            <td>{{u.lastUpdatedBy}}</td>
            <td>{{u.source}}</td>
            <td>{{u.hasCollections}}</td>
            <td>{{u.valueDate | date: 'dd/MM/yyyy'}}</td>
            <td>{{u.crDr}}</td>
            <td>{{u.transactionAmount}}</td>
            <td>{{u.description}}</td>
            <td>{{u.id}}</td>
            <td>{{u.transactionId}}</td>
            <td>{{u.chequeNo}}</td>
            <td>{{u.transPostedDate | date: 'dd/MM/yyyy hh:mm:ss a'}}</td>
            <td>{{u.availableBalance}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="isAddNewBillClicked">
      <form [formGroup]="billAddEditForm" (ngSubmit)="onBillSubmit()">
        <div class="form-row">
          <div class="col-md-3">Transaction Amount: {{billModel.transactionAmount}}</div>
          <div class="col-md-3">Value Date: {{billModel.valueDate| date: 'dd/MM/yyyy'}}</div>
          <div class="col-md-3">Description: {{billModel.description}}</div>
          <div class="col-md-3">CrDr : {{billModel.crDr}}</div>
          <div class="col-md-3">
            <label for="remarks">Remarks</label>
            <input type="text" formControlName="remarks" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }" />
            <div *ngIf="submitted && f.remarks.errors" class="invalid-feedback">
              <div *ngIf="f.remarks.errors.required">Remarks is required</div>
            </div>
          </div>
          <div class="col-md-3">
            <br>
            <button *ngIf="!billModel.reviewed|| isAdmin" type="submit" [disabled]="loading"
              class="btn btn-outline-primary">
              Save
            </button>
            <a *ngIf="!billModel.reviewed && isAdmin" class="btn btn-outline-primary"
              (click)="markBankStmtRevWithRemarks()">Mark Review</a>
            <button class="btn btn-outline-primary" (click)="onBillCancel()">close</button>
          </div>
        </div>
      </form><br>
      <table class="table table-striped table-sm table-condensed">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Bill Number</th>
            <th scope="col">Collection Date</th>
            <th scope="col">Collection Value</th>
            <th scope="col">Mode</th>
            <th scope="col">Collected By</th>
            <th scope="col">Remarks</th>
            <th scope="col">Cheque Date</th>
            <th scope="col">Cheque Number</th>
            <th scope="col">Bounced Credited Date</th>
            <th scope="col">Bounced</th>
            <th scope="col">Bounced FollowedUp</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of colls">
            <td>{{u.billNumber}}</td>
            <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
            <td>{{u.collectionValue}}</td>
            <td>{{u.mode}}</td>
            <td>{{u.collectedBy}}</td>
            <td>{{u.remarks}}</td>
            <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
            <td>{{u.chequeNumber}}</td>
            <td>{{u.creditedDate| date: 'dd/MM/yyyy'}}</td>
            <td>{{u.bounced}}</td>
            <td>{{u.bouncedFollowedUp}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
