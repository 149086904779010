<div class="row">
  <div class="col">
    <div *ngIf="!isAddCustomerClicked">
      <div class="form-row">
        <div class="col-md-2">
          <label>Name</label>
          <input class="form-control" type="text" [(ngModel)]="searchCustomerName" />
        </div>
        <div class="col-md-2">
          <label></label>
          <div>
            <button class="btn btn-sm btn-info" (click)="onNameCustomerSearch()">Search</button>&nbsp;
            <button class="btn btn-sm btn-info" (click)="onCustSearchReset()">Reset</button>
          </div>
        </div>
        <div class="col-md-2">
          <label></label>
          <div>
            <button class="btn btn-sm btn-info" (click)="oAddCustomer()">Add</button>
          </div>
        </div>
      </div>
      <br>
      <table class="table table-striped table-sm table-responsive table-condensed">
        <thead class="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Group</th>
            <th scope="col" *DataCustomAcl="'Admin'">User Name</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of customers">
            <th scope="row">
              <button class="btn btn-sm btn-info" (click)="onEditCustomer(u)">Edit</button>
            </th>
            <td>{{u.resourceName}}</td>
            <td>{{u.contactPhone}}</td>
            <td *DataCustomAcl="'Admin'">
              <button *ngIf="u.userName=='zzzzz'" class="btn btn-sm btn-info" (click)="onCreateSalesLogin(u)">Create
                Login</button>
              <div *ngIf="u.userName !='zzzzz'"> {{u.userName}}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="isAddCustomerClicked">
      <form [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
        <div class="form-row">
          <div class="col-md-2">
            <label for="resourceName">Name</label>
            <input type="text" formControlName="resourceName" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.resourceName.errors }" />
            <div *ngIf="submitted && f.resourceName.errors" class="invalid-feedback">
              <div *ngIf="f.resourceName.errors.required">Name is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="contactPhone">Group</label>
            <input type="text" formControlName="contactPhone" class="form-control" maxlength="10"
              [ngClass]="{ 'is-invalid': submitted && f.contactPhone.errors }" />
            <div *ngIf="submitted && f.contactPhone.errors" class="invalid-feedback">
              <div *ngIf="f.contactPhone.errors.required">Group is required</div>
            </div>
          </div>
        </div>
        <button type="submit" [disabled]="loading" class="btn btn-sm btn-info">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Save
        </button>&nbsp;
        <button class="btn btn-sm btn-info" (click)="onCloseAddCust()">close</button>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
      </form>
    </div>
  </div>
</div>