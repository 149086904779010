import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { invoiceTrackModel, accountLedgerModel, Role, User, companyModel, company1Model, PendingBills, noteTrackModel, LedgerBillSumModel } from '../models';
import { formatDate } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { AuthenticationService } from '../_services/authentication.service';
import { jsPDF } from 'jspdf';
import { NgxImageCompressService } from 'ngx-image-compress';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-invledger',
  templateUrl: './invledger.component.html',
  styleUrls: ['./invledger.component.css']
})
export class InvledgerComponent implements OnInit {
  persons: companyModel[] = [];
  loading = false;
  error = '';
  accledgers: accountLedgerModel[] = [];
  ledgerBill: LedgerBillSumModel[] = [];
  searchBillModel: accountLedgerModel = new accountLedgerModel();;
  submitted = false;
  billAddEditForm: FormGroup;
  get f() { return this.billAddEditForm.controls; }

  constructor(private imageCompress: NgxImageCompressService, private formBuilder: FormBuilder, private authenticationService: AuthenticationService, private api: RayarApiService) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.getCompanys();
    this.searchBillModel.companyName = "";
    this.searchBillModel.toDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
    this.searchBillModel.fromDate = new Date(new Date().setMonth(new Date().getMonth() - 3));
  }

  ngOnInit(): void {
    this.billAddEditForm = this.formBuilder.group({
      invoiceType: ['', Validators.required],
      invoiceValue: ['', Validators.required],
      remarks: ['', Validators.required],
    });
  }

  selAccledger: accountLedgerModel;
  selectlinvoideTrak(u: accountLedgerModel) {
    this.selAccledger = u;
  }

  uploadLedger(event) {
    this.error = "";
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          var imageSrc = reader.result as string;
          this.imageCompress.compressFile(imageSrc, 50, 100).then((compressedImage) => {
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            this.onUploadLedger(doc.output('blob'));
          });
        }
        else {
          if (file.type.startsWith("application/pdf")) {
            this.onUploadLedger(file);
          } else {
            this.error = "Invalid File format images/PDF only"
          }
        }
      };
    }
  }

  getData() {
    var c = this.searchBillModel.companyName;
    return this.ledgerBill.filter(function (hero) {
      return hero.companyName == c;
    });
  }

  onUploadLedger(file: Blob) {
    var url = ``;
    if (this.selAccledger.crDr == "Invoice") {
      url = `UploadLedger/${this.selAccledger.id}/INVICE`;
    } else {
      url = `UploadLedger/${this.selAccledger.id}/LEGER`;
    }
    this.api.uploadToBlob(file, url).subscribe(
      data => {
        if (data) {
          switch (data.type) {
            case HttpEventType.UploadProgress:
              break;
            case HttpEventType.Response:
              this.inputFile.nativeElement.value = '';
              this.onCollBillSearch();
              break;
          }
        }
      },
      error => {
        this.inputFile.nativeElement.value = '';
      }
    );
  }

  downloadBills(u: accountLedgerModel) {
    var url = ``;
    if (u.crDr == "Invoice") {
      url = "Download1/" + u.id + "/INVICE";
    } else {
      url = "Download1/" + u.id + "/LEGER";
    }
    this.api.downloadBlobFilePost(url, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            var fileName = u.id + ".jpg";
            if (data.body.type == "application/zip") {
              fileName = u.id + ".zip"
            } else if (data.body.type == "application/pdf") {
              fileName = u.id + ".pdf"
            }
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }


  billModel = new invoiceTrackModel();

  onBillSubmit() {
    this.submitted = true;
    if (this.billAddEditForm.invalid) return;
    this.loading = true;
    this.billModel.invoiceType = this.f.invoiceType.value;
    this.billModel.invoiceValue = this.f.invoiceValue.value;
    this.billModel.companyName = this.searchBillModel.companyName;
    this.billModel.remarks = this.f.remarks.value;

    this.api.AddInvoiceTrack(this.billModel).subscribe(result => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.billAddEditForm.setValue({
        invoiceType: '',
        invoiceValue: '',
        remarks: '',
      });
      this.onCollBillSearch();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  onUpdate(u: accountLedgerModel) {
    this.submitted = true;
    this.loading = true;
    var up = new invoiceTrackModel();
    up.invoiceTrackId = u.id;
    up.invoiceValue = this.amount;
    up.remarks = this.remarks;
    this.api.UpdateInvoiceTrack(up).subscribe(result => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.onCollBillSearch();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  @ViewChild('inputFile') inputFile: ElementRef;
  imageSrc: string;
  fileType: string;
  fileSource: any = null;

  selAcled: accountLedgerModel = new accountLedgerModel();
  selectledger(u: accountLedgerModel) {
    this.selAcled = u;
  }

  selectChangeHandler(event: any) {
    this.searchBillModel.fromDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.searchBillModel.toDate = event.target.value;
  }


  onCollBillSearchReset() {
    this.searchBillModel = new accountLedgerModel();
    this.searchBillModel.toDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.searchBillModel.fromDate = new Date(formatDate(new Date(new Date().setMonth(new Date().getMonth() - 2)), 'yyyy-MM-dd', 'en'));
    this.searchBillModel.companyName = "0";
    this.accledgers = [];
    this.error = '';
  }

  onCollBillSearch() {
    this.searchBillModel.page = 0;
    if (this.searchBillModel.companyName == "0") {
      this.error = 'Please Select Company';
      return;
    }
    this.search();
    this.api.getInvoiceLedger(this.searchBillModel).subscribe(result => {
      this.accledgers = result;
      this.error = '';
      this.accledgers.forEach(x => {
        x.isEdit = false;
      });
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }
  currentUser: User;

  markPaid(u: accountLedgerModel) {
    this.error = '';
    this.api.markPaid(u.id).subscribe(() => { this.onCollBillSearch(); }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  editGrn(u: accountLedgerModel) {
    this.accledgers.forEach(x => {
      x.isEdit = u.uniqId == x.uniqId;
      if (u.uniqId == x.uniqId) {
        this.editgrnData = new accountLedgerModel();
        this.editgrnData.grnNumber = u.grnNumber;
        this.editgrnData.remarks = u.remarks;
        this.editgrnData.crDr = u.crDr;
        this.editgrnData.id = u.id;
      }
    });
  }

  editgrnData: accountLedgerModel;

  saveGrn(u: accountLedgerModel) {
    this.api.SaveGrnDetail(this.editgrnData)
      .subscribe(() => {
        this.loading = false;
        this.onCollBillSearch();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });

  }

  cancelGrn(u: accountLedgerModel) {
    this.accledgers.forEach(x => {
      x.isEdit = false;
    });
  }

  getCompanys() {
    this.api.getCompanys().subscribe(result => {
      this.persons = result;
      this.persons.sort((a, b) => a.companyName.localeCompare(b.companyName));
      this.searchBillModel.companyName = this.persons[0].companyName;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  downloadAccLedReport() {
    this.error = '';
    this.searchBillModel.page = 0;
    if (this.searchBillModel.companyName == "0") {
      this.error = 'Please Select Company';
      return;
    }
    this.download("InvoiceLedgerReport", this.searchBillModel)
  }

  public download(q: string, b: any) {
    this.api.downloadFilePost(q, b).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }

  search() {
    this.api.getPendingBillAge().subscribe(result => {
      this.pendBills = result;
      this.summarize1();
    }, error => {
      this.loading = false; console.error(error);
    });
    if (this.ledgerBill.length == 0) {
      this.api.getInvoiceLedgerBill().subscribe(result => {
        this.ledgerBill = result;
      }, error => {
        this.loading = false; console.error(error);
      });
    }
  }

  companys21: company1Model[] = [];

  pendBills: PendingBills[] = [];
  total11: company1Model = new company1Model();
  summarize1() {
    this.companys21 = [];
    var cs = this.searchBillModel.companyName;
    this.pendBills.filter(function (c) {
      return c.companyName == cs;
    }).map(item => item.customerId)
      .filter((value, index, self) => self.indexOf(value) === index)
      .forEach(x => {
        var c = new company1Model();
        c.companyId = x;
        c.billAmount1 = 0;
        c.billAmount2 = 0;
        c.billAmount3 = 0;
        c.billAmount4 = 0;
        c.billAmount5 = 0;
        c.billAmount = 0;
        c.billcount = 0;
        c.billcount1 = 0;
        c.billcount2 = 0;
        c.billcount3 = 0;
        c.billcount4 = 0;
        c.billcount5 = 0;
        this.companys21.push(c);
      });
    var c = new company1Model();
    c.companyId = 0;
    c.companyName = "AllCustomers";
    c.billAmount1 = 0;
    c.billAmount2 = 0;
    c.billAmount3 = 0;
    c.billAmount4 = 0;
    c.billAmount5 = 0;
    c.billAmount = 0;
    c.billcount = 0;
    c.billcount1 = 0;
    c.billcount2 = 0;
    c.billcount3 = 0;
    c.billcount4 = 0;
    c.billcount5 = 0;
    if (this.currentUser.role !== Role.Sales) {
      this.companys21.push(c);
    }
    this.companys21.forEach(x => {
      this.pendBills.filter(function (hero) {
        return hero.customerId == x.companyId
          && hero.companyName == cs;
      }).forEach(hero => {
        x.companyName = hero.customerName;
        x.contactName = hero.contactName;
        x.contactPhone = hero.contactPhone;
        x.custStatus = hero.custStatus;
        x.gst = hero.gst;
        x.billcount = x.billcount + 1;
        x.billAmount = x.billAmount + hero.balance;
        x.customerFolDate = x.customerFolDate;
        if (hero.billDays >= 21 && hero.billDays < 42) {
          x.billAmount1 = x.billAmount1 + hero.balance;
          x.billcount1 = x.billcount1 + 1;
        } else if (hero.billDays >= 42 && hero.billDays < 63) {
          x.billAmount2 = x.billAmount2 + hero.balance;
          x.billcount2 = x.billcount2 + 1;
        } else if (hero.billDays >= 63) {
          x.billAmount3 = x.billAmount3 + hero.balance;
          x.billcount3 = x.billcount3 + 1;
        } else if (hero.billDays < 21) {
          x.billAmount5 = x.billAmount5 + hero.balance;
          x.billcount5 = x.billcount5 + 1;
        }
      });
    });
    let t1 = 0;
    let t2 = 0;
    let t3 = 0;
    let t4 = 0;
    let t5 = 0;
    let t6 = 0;
    let t7 = 0;
    let t8 = 0;
    let t9 = 0;
    let t10 = 0;
    for (let data of this.companys21) {
      t1 += data.billAmount1;
      t2 += data.billcount1;

      t3 += data.billAmount2;
      t4 += data.billcount2;

      t5 += data.billAmount3;
      t6 += data.billcount3;

      t7 += data.billAmount;
      t8 += data.billcount;

      t9 += data.billAmount5;
      t10 += data.billcount5;
    }

    var c = new company1Model();
    c.companyId = 0;
    c.companyName = "Total";
    c.billAmount1 = t1;
    c.billAmount2 = t3;
    c.billAmount3 = t5;
    c.billAmount4 = t1 + t3 + t5;
    c.billAmount = t7;
    c.billAmount5 = t9;

    c.billcount1 = t2;
    c.billcount2 = t4;
    c.billcount3 = t6;
    c.billcount4 = t2 + t4 + t6;
    c.billcount = t8;
    c.billcount5 = t10;

    this.total11 = c;
    this.companys21 = this.companys21.filter(function (hero) { return hero.billAmount > 0 }).sort((a, b) => a.companyName.localeCompare(b.companyName));
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Enter") {
      this.onCollBillSearch();
    }
  }
  amount: number;
  remarks: string;

  showDetail(u: accountLedgerModel) {
    this.accledgers.forEach(x => {
      if (u.uniqId == x.uniqId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          if (u.amount < 0) {
            this.amount = u.amount * -1;
          } else {
            this.amount = u.amount;
          }
          this.remarks = u.remarks;
          this.getNotes(x.id);
        } else
          x.showDetail = false;
      }
    });
  }

  suppliedDocs: noteTrackModel[] = [];

  getNotes(i: number) {
    var d = new noteTrackModel();
    d.id = i;
    d.noteType = "InvLedger";
    d.page = 0;
    d.pageSize = 100;
    this.api.getnoteTraks(d).subscribe(result => this.suppliedDocs = result, error => {
      this.loading = false; console.error(error)
    });
  }
}
