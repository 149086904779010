<div class="card">
    <h4 class="card-header">Profit Report</h4>
    <div class="card-body">
        <div class="form-row">
            <div class="col-md-2">
                <label></label>
                <div>
                    <button class="btn btn-outline-primary" (click)="getLedger()">Profit Report</button>
                    <button class="btn btn-outline-primary" (click)="bkpPendingBills()">Backup Pending Bills</button>
                </div>
            </div>
        </div>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Year</th>
                    <th scope="col">Month</th>
                    <th scope="col">Bills</th>
                    <th scope="col">Purchases</th>
                    <th scope="col">Expenses</th>
                    <th scope="col">Inventory</th>
                    <th scope="col">Profit</th>
                    <th scope="col">Collection</th>
                    <th scope="col">Pending Collection</th>
                </tr>
            <tbody>
                <tr *ngFor="let u of profitLoss">
                    <td>{{u.years}}</td>
                    <td>{{u.months }}</td>
                    <td>{{u.bill |currency:"INR":"symbol"}}</td>
                    <td>{{u.purchase |currency:"INR":"symbol"}}</td>
                    <td>{{u.expense |currency:"INR":"symbol"}}</td>
                    <td>{{u.inventory |currency:"INR":"symbol"}}</td>
                    <td>{{u.bill-u.expense-u.purchase+u.inventory |currency:"INR":"symbol" }}</td>
                    <td>{{u.collections |currency:"INR":"symbol"}}</td>
                    <td>{{u.bill -u.collections |currency:"INR":"symbol"}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>