<div class="row">
    <div class="col-6">
        <div class="form-row" *DataCustomAcl="'Admin:Sales:Company'">
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th *DataCustomAclOnly="'Admin'" scope="col">#</th>
                        <th scope="col">Route</th>
                        <th scope="col" colspan="3">Bill Amount</th>
                        <th scope="col" colspan="3">Bill Count</th>
                        <th scope="col" colspan="3">Customer Count</th>
                    </tr>
                    <tr>
                        <th scope="col"></th>
                        <th *DataCustomAclOnly="'Admin'" scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">Actual</th>
                        <th scope="col">Planned</th>
                        <th scope="col">%</th>
                        <th scope="col">Actual</th>
                        <th scope="col">Planned</th>
                        <th scope="col">%</th>
                        <th scope="col">Actual</th>
                        <th scope="col">Planned</th>
                        <th scope="col">%</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <button class="btn btn-sm btn-success" (click)="showDetail(billSum1)">
                                <i class="fa fa-pencil"></i>
                            </button>
                        </td>
                        <td *DataCustomAcl="'Admin'">
                        </td>
                        <td>Over All</td>
                        <td>{{billSum1.billValue1}}</td>
                        <td>{{billSum1.billValue}}</td>
                        <td>{{((billSum1.billValue1/billSum1.billValue)*100).toFixed(2)}}%</td>
                        <td>{{billSum1.billCount1}}</td>
                        <td>{{billSum1.billCount}}</td>
                        <td>{{((billSum1.billCount1/billSum1.billCount)*100).toFixed(2)}}%</td>
                        <td>{{billSum1.customerCount1}}</td>
                        <td>{{billSum1.customerCount}}</td>
                        <td>{{((billSum1.customerCount1/billSum1.customerCount)*100).toFixed(2)}}%</td>
                    </tr>
                    <tr *ngFor="let u of billSum">
                        <td>
                            <button class="btn btn-sm btn-success" (click)="showDetail(u)">
                                <i class="fa fa-pencil"></i></button>
                        </td>
                        <td *DataCustomAcl="'Admin'">
                            <button class="btn btn-sm btn-success" (click)="showMap(u)"
                                [ngClass]="{ 'btn-success': isMapView && u.driverName==lastmapdriver,'btn-warning': !(isMapView && u.driverName==lastmapdriver) }">
                                <i class="fa fa-map-marker"></i>
                            </button>
                        </td>
                        <td>{{u.driverName=='-1'?"None":u.driverName}}</td>
                        <td>{{u.billValue1}}</td>
                        <td>{{u.billValue}}</td>
                        <td>{{((u.billValue1/u.billValue)*100).toFixed(2)}}%</td>
                        <td>{{u.billCount1}}</td>
                        <td>{{u.billCount}}</td>
                        <td>{{((u.billCount1/u.billCount)*100).toFixed(2)}}%</td>
                        <td>{{u.customerCount1}}</td>
                        <td>{{u.customerCount}}</td>
                        <td>{{((u.customerCount1/u.customerCount)*100).toFixed(2)}}%</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-6">
        <agm-map *ngIf="isMapView" [latitude]="lat" [longitude]="lng">
            <agm-direction [waypoints]="waypoints" *ngFor="let dir of directions" [origin]="dir.origin"
                [renderOptions]="renderOptions" [markerOptions]="markerOptions" [destination]="dir.destination">
            </agm-direction>
            <agm-marker *ngFor="let marker of markers" [latitude]="marker.lat" [longitude]="marker.lng"
                [label]="marker.label" [title]="marker.label1" placement="top"></agm-marker>
        </agm-map>
    </div>
</div>
<input name="file" id="file" (change)="upload($event)" type="file" #inputFile hidden>

<Br>
<div class="row">
    <div class="w-100"></div>
    <div class="col" *ngIf="selecteddriver!='0'">
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Route</th>
                    <th scope="col">Start Time</th>
                    <th scope="col">Complete Time</th>
                    <th scope="col">Driver Name</th>
                    <th scope="col">Driver Phone</th>
                    <th scope="col">Helper Name</th>
                    <th scope="col">Helper Phone</th>
                    <th scope="col">Vehicle</th>
                    <th scope="col">FuelIn</th>
                    <th scope="col">Fuel Amount</th>
                    <th scope="col"></th>
                    <th scope="col">Odometer</th>
                    <th scope="col">Miles</th>
                    <th scope="col" *DataCustomAcl="'Admin:Employee:Driver'"></th>
                    <th scope="col" *DataCustomAcl="'Admin:Employee:Driver'"></th>
                    <th scope="col" *DataCustomAcl="'Admin'"></th>
                    <th scope="col" *DataCustomAcl="'Admin'"></th>
                    <th scope="col" *DataCustomAcl="'Admin'"></th>
                </tr>
            </thead>
            <tbody *ngFor="let u of getData1()">
                <tr>
                    <td>{{u.resourceName}}</td>
                    <td>{{u.checkInTime | date: 'shortTime'}} </td>
                    <td>{{u.checkOutTime | date: 'shortTime'}} </td>
                    <td class="elip">
                        <div *ngIf="!u.isEdit">{{u.driverName }}</div>
                        <select *ngIf="u.isEdit" class="form-control form-control-sm" [(ngModel)]="u.driverName">
                            <option *ngFor="let p of empsAll" [value]="p.employeeName">{{p.employeeName}}</option>
                        </select>
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">
                            <a *ngIf="u.driverPhone" [href]="'tel:+91' + u.driverPhone">{{u.driverPhone}}</a>
                        </div>
                    </td>
                    <td class="elip">
                        <div *ngIf="!u.isEdit">{{u.helperName }}</div>
                        <select *ngIf="u.isEdit" class="form-control form-control-sm" [(ngModel)]="u.helperName">
                            <option *ngFor="let p of empsAll" [value]="p.employeeName">{{p.employeeName}}</option>
                        </select>
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">
                            <a *ngIf="u.helperPhone" [href]="'tel:+91' + u.helperPhone">{{u.helperPhone}}</a>
                        </div>
                    </td>
                    <td class="elip">
                        <div *ngIf="!u.isEdit">{{u.vehicleName }}</div>
                        <select *ngIf="u.isEdit" class="form-control form-control-sm" [(ngModel)]="u.vehicleName">
                            <option *ngFor="let p of vehicles" [value]="p.vehicleName">{{p.vehicleName}}</option>
                        </select>
                    </td>
                    <td class="elip">
                        <div *ngIf="!u.isEdit">{{u.fuel }}</div>
                        <input *ngIf="u.isEdit" type="number" class="form-control form-control-sm" [(ngModel)]="u.fuel">
                    </td>
                    <td class="elip">
                        <div *ngIf="!u.isEdit">{{u.reason}}</div>
                        <input *ngIf="u.isEdit" type="number" class="form-control form-control-sm"
                            [(ngModel)]="u.reason">
                    </td>
                    <td>
                        <i *ngIf="u.attendanceId && u.attendanceId>0" (click)="selectledger(u);inputFile.click()"
                            class="fa fa-camera">{{u.doc}}</i>
                        <i *ngIf="u.doc && u.doc>0" (click)="downloadPetrol(u)" class="fa fa-download"></i>
                    </td>
                    <td class="elip">
                        <div *ngIf="!u.isEdit">{{u.checkOut }}</div>
                        <input *ngIf="u.isEdit" type="number" class="form-control form-control-sm"
                            [(ngModel)]="u.checkOut">
                    </td>
                    <td class="elip">
                        {{getmiles() }}
                    </td>
                    <td *DataCustomAcl="'Admin:Employee:Driver'">
                        <button class="btn btn-sm btn-info" *ngIf="!u.checkOutTime &&!u.isEdit"
                            (click)="editGrn(u)">Start</button>
                        <button class="btn btn-sm btn-info" *ngIf="u.isEdit" (click)="saveGrn(u)">Save</button>
                    </td>
                    <td *DataCustomAcl="'Admin:Employee:Driver'">
                        <button class="btn btn-sm btn-warning" *ngIf="!u.checkOutTime && u.checkInTime && !u.isEdit"
                            (click)="saveOut(u)">Complete</button>
                        <button class="btn btn-sm btn-info" *ngIf="u.isEdit" (click)="cancelGrn(u)">Cancel</button>
                    </td>
                    <td *DataCustomAcl="'Admin'">
                        <button class="btn btn-sm btn-info" *ngIf="!u.reasonOut"
                            (click)="editGrn(u)">AdminStart</button>
                    </td>
                    <td *DataCustomAcl="'Admin'">
                        <button class="btn btn-sm btn-warning" *ngIf="!u.reasonOut"
                            (click)="saveOutadmin(u)">AdminComplete</button>
                    </td>
                    <td *DataCustomAcl="'Admin'">
                        <button class="btn btn-sm btn-warning" *ngIf="showDone(u)"
                            (click)="onCheckboxChange(u)">Done</button>
                    </td>
                </tr>
            </tbody>

            <tbody *ngFor="let u of getRouteSumm()">
                <tr>
                    <td colspan="19" class="bg-success" style="text-align: right;color: white;">
                        {{u}}
                    </td>
                </tr>
            </tbody>
            <tbody *ngFor="let u of getData12()">
                <tr>
                    <td colspan="19" class="bg-danger" style="text-align: right;color: white;">
                        {{u}}
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <td colspan="19">
                        <span *ngFor="let u of getDataDocDownload()">
                            <button class="btn btn-sm btn-info"
                                (click)="onDownloadDocument(u.documentId, u.documentName)">
                                {{u.documentName}}</button>&nbsp;
                        </span>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <td>Driver:</td>
                    <td colspan="18">
                        <span *ngFor="let u of getdriverattend()">
                            <button [ngClass]="{ 
                                'btn-success': u.checkIn ==1,
                                'btn-warning': u.checkIn==2,
                                'btn-info': u.checkIn==3,
                                'btn-danger': !u.checkIn||u.checkIn==4 }" class="btn btn-sm"> {{u.dateValue |
                                date:'dd'}} {{displayText(u)}}</button>&nbsp;

                        </span>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <td>Helper:</td>
                    <td colspan="19">
                        <span *ngFor="let u of gethelperAtted()">
                            <button [ngClass]="{ 
                                'btn-success': u.checkIn ==1,
                                'btn-warning': u.checkIn==2,
                                'btn-info': u.checkIn==3,
                                'btn-danger': !u.checkIn||u.checkIn==4 }" class="btn btn-sm"> {{u.dateValue |
                                date:'dd'}} {{displayText(u)}}</button>&nbsp;
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="col" *ngIf="selecteddriver!='0'">
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Fuel-In</th>
                        <th scope="col">FuelAmount</th>
                        <th scope="col">Odometer</th>
                        <th scope="col">Miles</th>
                        <th scope="col">Mileage</th>
                        <th scope="col">Route</th>
                        <th scope="col">Driver</th>
                        <th scope="col">Helper</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let u of getvehicleData()">
                        <td>{{u.dateValue | date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.fuel}} </td>
                        <td>{{u.reason}} </td>
                        <td>{{u.checkOut}} </td>
                        <td>{{u.miles}} </td>
                        <td>{{u.mileage}} </td>
                        <td>{{u.resourceName}}</td>
                        <td>{{u.driverName}}</td>
                        <td>{{u.helperName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="w-100"></div>
    <br>
    <div class="col">
        <div class="form-row" *ngIf="isSelectResStarte() && selecteddriver!='0'">
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">Sq</th>
                        <th scope="col">Status</th>
                        <th scope="col">Delivered <br> Time</th>
                        <th scope="col">Bill No</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Infor3</th>
                        <th scope="col">Company</th>
                        <th scope="col">SalesMan<br>Phone</th>
                        <th scope="col">Customer Phone</th>
                        <th scope="col">PO Number</th>
                        <th scope="col">PO Exp Date</th>
                        <th scope="col">PO Apt Time</th>
                        <th scope="col" *DataCustomAcl="'Admin'">Received</th>
                        <th scope="col" *DataCustomAcl="'Admin'" style="width: 150px;">Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let u of getData()">
                        <td>
                            <i class="btn btn-sm btn-success fa"
                                [ngClass]="{ 'fa-cart-plus': u.billType=='NB','fa-usd': u.billType=='PB'}"></i>
                        </td>
                        <td>
                            <button class="btn btn-sm btn-success mp" *ngIf="u.cashCarry">
                                <i class="fa fa-inr"></i></button>
                        </td>
                        <td>
                            <button class="btn btn-sm btn-success" (click)="goToLink(u)">
                                <i class="fa fa-map-marker"></i></button>
                        </td>
                        <td>
                            <div *ngIf="u.billType=='NB'">
                                <button *ngIf="showDelivered(u)" (dblclick)="markDelivered(u, false)" class="btn btn-sm"
                                    title="Mark Delivered"
                                    [ngClass]="{ 'btn-success': u.deliveryStatus=='Delivered','btn-warning': u.deliveryStatus!='Delivered'}">
                                    <i class="fa fa-home"></i></button>

                            </div>
                        </td>
                        <td>
                            <div *ngIf="u.billType=='NB'">
                                <button *ngIf="showDelivered(u)" (dblclick)="markUnDelivered(u)" class="btn btn-sm"
                                    title="Mark NotDeliver"
                                    [ngClass]="{ 'btn-success': u.deliveryStatus=='NotDeliver', 'btn-warning': u.deliveryStatus!='NotDeliver'}">
                                    <i class="fa fa-window-close"></i></button>
                            </div>
                        </td>

                        <td>
                            <div *ngIf="u.billType=='NB'">
                                <button *ngIf="showDelivered(u)" (dblclick)="markDelivered(u,true)" class="btn btn-sm"
                                    title="Mark Short Delivered"
                                    [ngClass]="{ 'btn-success': u.deliveryStatus=='SDelivered','btn-warning': u.deliveryStatus!='SDelivered'}">
                                    <i class="fa fa-battery-half"></i></button>

                            </div>
                        </td>
                        <td>
                            <button *ngIf="u.saleOrder" class="btn btn-sm btn-info" (click)="downloadBills(u.billId)">
                                <i class="fa fa-download"></i>
                            </button>
                        </td>
                        <td>{{u.serial}}</td>
                        <td>
                            <div *ngIf="u.billType=='NB'">
                                {{u.deliveryStatus}}
                            </div>
                        </td>
                        <td>{{u.deliveryDate | date: 'shortTime' }}</td>
                        <td>{{u.billNumber}}</td>
                        <td [class.bg-warning]="u.billValue > 50000">{{u.billValue |currency:'INR': 'symbol' : '1.0-0'}}
                        </td>
                        <td>{{u.customerName}}</td>
                        <td>{{u.information3}}</td>
                        <td>{{u.companyName}}</td>
                        <td>{{u.salesPersonName }}<br><a *ngIf="u.salesContactPhone"
                                [href]="'tel:+91' + u.salesContactPhone">{{u.salesContactPhone}}</a></td>
                        <td>
                            <a *ngIf="u.contactPhone" [href]="'tel:+91' + u.contactPhone">{{u.contactPhone}}</a>
                        </td>
                        <td>{{u.poNumber}}</td>
                        <td>{{u.poExpDate | date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.appointDate | date: 'dd/MM/yyyy hh:mm'}}</td>
                        <td *DataCustomAcl="'Admin'">
                            <button type="button" *ngIf="showRecieved(u)" class="btn btn-sm btn-warning btn-sm"
                                (click)="onClickDelivered(u)">
                                Supplied
                            </button>
                        </td>
                        <td *DataCustomAcl="'Admin'">
                            <input *ngIf="u.billType=='NB'" type="text" [(ngModel)]="u.billRemarks"
                                (blur)="onBlurEvent(u)" class="form-control" />
                            <input *ngIf="u.billType=='PB'" type="text" [(ngModel)]="u.billRemarks"
                                (blur)="saveNoteTrack1(u)" class="form-control" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>