<div class="card">
  <h4 class="card-header">Expenses</h4>
  <div class="card-body">
    <div *ngIf="!isAddNewBillClicked">
      <div class="form-row">
        <div class="col-md-2">
          <label for="From">From</label>
          <input type="date" [ngModel]="selectedDate | date:'yyyy-MM-dd'" (change)="selectChangeHandler($event)"
            class="form-control" />
        </div>
        <div class="col-md-2">
          <label for="To">To</label>
          <input type="date" [ngModel]="selectedDate1 | date:'yyyy-MM-dd'" (change)="selectChangeHandler1($event)"
            class="form-control" />
        </div>
        <div class="col-md-2">
          <label for="category">Category</label>
          <select class="form-control" [(ngModel)]="selectedCategory">
            <option value="" selected>All</option>
            <option value="Petrol">Petrol</option>
            <option value="Rent">Rent</option>
            <option value="Electricity">Electricity</option>
            <option value="Vehicle">Vehicle</option>
            <option value="Food">Food</option>
            <option value="Salary">Salary</option>
            <option value="SalaryAdvance">SalaryAdvance</option>
            <option value="Stationary">Stationary</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div class="col-md-2">
          <label for="Reviewed">Reviewed</label>
          <br>
          <input type="radio" name="revRadio" value="0" [(ngModel)]="sReviewed"
            (change)="selectChangeHandler11($event)" /> No
          <input type="radio" name="revRadio" value="1" [(ngModel)]="sReviewed"
            (change)="selectChangeHandler11($event)" /> Yes
          <input type="radio" name="revRadio" value="2" [(ngModel)]="sReviewed"
            (change)="selectChangeHandler11($event)" /> Both
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-1">
          <button class="btn btn-outline-primary" (click)="onSearch()">Search</button>
        </div>
        <div class="col-md-1">
          <button class="btn btn-outline-primary" (click)="onReset()">Reset</button>
        </div>
        <div class="col-md-2">
          <button class="btn btn-outline-primary" (click)="onAddNewBill()">Add Expense</button>
        </div>
        <div class="col-md-1">
          <button class="btn btn-outline-primary" (click)="downloadExpenseReport()">Download</button>
        </div>
      </div>
      <br>
      <div class="form-row">
        <div class="col-md-2">Reviewed- {{colAmount}}</div>
        <div class="col-md-2">Not Reviewed- {{colAmount1}}</div>
        <div class="col-md-2">Total - {{colAmount+colAmount1}}</div>
      </div>
      <div class="form-row">
        <table class="table table-striped table-sm table-responsive table-condensed">
          <thead class="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Created</th>
              <th scope="col">Expense Date</th>
              <th scope="col">Amount </th>
              <th scope="col">Category</th>
              <th scope="col">Paid To</th>
              <th scope="col">Reason</th>
              <th scope="col" *DataCustomAcl="'Admin'">Reviewed</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let u of bills">
              <th scope="row">
                <button class="btn btn-outline-primary" (click)="markReviewedExp(u)"
                  *ngIf="!u.isReviewed&& isAdmin">Reviewed</button>
                <button class="btn btn-outline-primary" (click)="markExpInAct(u)" *ngIf="!u.isReviewed">Delete</button>
                <button class="btn btn-outline-primary" (click)="editBill(u)"
                  *ngIf="!u.isReviewed || isAdmin">Edit</button>
              </th>
              <td>{{u.createdDate | date: 'dd/MM/yyyy'}}</td>
              <td>{{u.entryDate | date: 'dd/MM/yyyy'}}</td>
              <td>{{u.amount}}</td>
              <td>{{u.category}}</td>
              <td>{{u.paidTo}}</td>
              <td>{{u.reason}}</td>
              <td *DataCustomAcl="'Admin'">{{u.isReviewed}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="isAddNewBillClicked">
      <form [formGroup]="billAddEditForm" (ngSubmit)="onBillSubmit()">
        <div class="form-row">
          <div class="col-md-2">
            <label for="category">Category</label>
            <select formControlName="category" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
              <option value="" selected disabled>Choose</option>
              <option value="Petrol">Petrol</option>
              <option value="Rent">Rent</option>
              <option value="Electricity">Electricity</option>
              <option value="Vehicle">Vehicle</option>
              <option value="Food">Food</option>
              <option value="Salary">Salary</option>
              <option value="SalaryAdvance">SalaryAdvance</option>
              <option value="Stationary">Stationary</option>
              <option value="Other">Other</option>
            </select>
            <div *ngIf="submitted && f.category.errors" class="invalid-feedback">
              <div *ngIf="f.category.errors.required">Category is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="amount">Amount</label>
            <input type="number" formControlName="amount" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.amount.errors }" />
            <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
              <div *ngIf="f.amount.errors.required">Amount is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="entryDate">Expense Date</label>
            <input type="date" formControlName="entryDate" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.entryDate.errors }" />
            <div *ngIf="submitted && f.entryDate.errors" class="invalid-feedback">
              <div *ngIf="f.entryDate.errors.required">Expense Date is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="paidTo">Paid To</label>
            <select formControlName="paidTo" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.paidTo.errors }">
              <option value="Other" selected>Other</option>
              <option *ngFor="let p of customers" [value]="p.employeeName">{{p.employeeName}}</option>
            </select>
            <div *ngIf="submitted && f.paidTo.errors" class="invalid-feedback">
              <div *ngIf="f.paidTo.errors.required">Paid To is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="reason">Reason</label>
            <input type="text" formControlName="reason" class="form-control" maxlength="200"
              [ngClass]="{ 'is-invalid': submitted && f.reason.errors }" />
            <div *ngIf="submitted && f.reason.errors" class="invalid-feedback">
              <div *ngIf="f.reason.errors.required">Reason is required</div>
            </div>
          </div>
        </div>
        <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Save
        </button>
        <button class="btn btn-outline-primary" (click)="onBillCancel()">close</button>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}
        </div>
      </form>
    </div>
  </div>
</div>