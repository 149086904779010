import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { comLedger } from '../models';

@Component({
  selector: 'app-comledger',
  templateUrl: './comledger.component.html',
  styleUrls: ['./comledger.component.css']
})
export class ComledgerComponent implements OnInit {
  ledgers: comLedger[] = [];
  profitLoss: comLedger[] = [];

  constructor(private api: RayarApiService) {
  }

  ngOnInit(): void {
  }

  getLedger() {
    this.api.getcom("ProfitLoss").subscribe(result => {
      this.profitLoss = result;
    },
      error => { });
  }

  bkpPendingBills(){
    this.api.getBakup("PendingBills").subscribe(result => {
      this.profitLoss = result;
    },
      error => { });
  }
}
