import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { accountModel, collectionFollowupModel, companyModel, noteTrackModel, Role, User } from '../models';
import { HttpEventType } from '@angular/common/http';
import { AuthenticationService } from '../_services/authentication.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-salemantrack',
  templateUrl: './salemantrack.component.html',
  styleUrls: ['./salemantrack.component.css']
})
export class SalemantrackComponent implements OnInit {

  searchClaim: collectionFollowupModel = new collectionFollowupModel();
  custReportAll: collectionFollowupModel[] = [];
  loading = false;
  error = '';
  currentUser: User;
  persons: accountModel[] = [];
  companys: companyModel[] = [];

  ngOnInit(): void {
  }

  constructor(private authenticationService: AuthenticationService, private api: RayarApiService) {
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser.role == Role.Company) {
      this.searchClaim.companyName = this.currentUser.profileId;
      var d = new companyModel();
      d.companyName = this.currentUser.profileId;
      this.companys = [d]
    } else {
      this.getcompany();
      this.searchClaim.companyName = "";
    }
    this.searchClaim.toDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.searchClaim.fromDate = new Date(formatDate(new Date(new Date().setMonth(new Date().getMonth() - 2)), 'yyyy-MM-dd', 'en'));

    this.searchClaim.pageSize = 250;
    this.searchClaim.page = 0;
    this.searchClaim.received = false;
    this.searchClaim.accountId = -1;
    this.getAccounts();
  }

  selectChangeHandler1(event: any) {
    this.searchClaim.toDate = event.target.value;
  }

  selectChangeHandler(event: any) {
    this.searchClaim.fromDate = event.target.value;
  }

  getcompany() {
    this.api.getCompanys().subscribe(result => { this.companys = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  getAccounts() {
    this.api.getAccounts().subscribe(result => {
      this.persons = result;
      this.persons.sort((a, b) => a.accountName.localeCompare(b.accountName));
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onpage(next: boolean) {
    if (next) {
      this.getClaimFollowup(this.searchClaim.page + 1);
    }
    else {
      if (this.searchClaim.page >= 1)
        this.getClaimFollowup(this.searchClaim.page - 1);
    }
  }

  downloadExpenseReport() {
    this.searchClaim.page = 0;
    if (this.searchClaim.accountId)
      this.searchClaim.accountId = parseInt(this.searchClaim.accountId.toString());
    else
      this.searchClaim.accountId = -1;
    if (this.rec == "both") this.searchClaim.received = null;
    if (this.rec == "true") this.searchClaim.received = true;
    if (this.rec == "false") this.searchClaim.received = false;
    this.download("ClaimFollowupDownload", this.searchClaim)
  }

  public download(q: string, b: any) {
    this.api.downloadFilePost(q, b).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }


  rec: string = "both";

  getClaimFollowup(p: number) {
    this.searchClaim.page = p;
    if (this.searchClaim.accountId)
      this.searchClaim.accountId = parseInt(this.searchClaim.accountId.toString());
    else
      this.searchClaim.accountId = -1;
    if (this.rec == "both") this.searchClaim.received = null;
    if (this.rec == "true") this.searchClaim.received = true;
    if (this.rec == "false") this.searchClaim.received = false;

    this.api.getClaimFollowup(this.searchClaim).subscribe(result => {
      result.forEach(x => { x.isEdit = false; });
      this.custReportAll = result;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }


  editGrn(u: collectionFollowupModel) {
    this.custReportAll.forEach(x => {
      x.isEdit = u.collectionDetailId == x.collectionDetailId;
      if (u.collectionDetailId == x.collectionDetailId) {
        this.editgrnData = new collectionFollowupModel();
        this.editgrnData.collectionDetailId = u.collectionDetailId;
        this.editgrnData.remarks = u.remarks;
        this.editgrnData.claimValue = u.claimValue;
        this.editgrnData.received = u.received;
      }
    });
  }

  editgrnData: collectionFollowupModel;

  saveGrn(u: collectionFollowupModel) {
    this.editgrnData.received = this.editgrnData.received.toString() == "true"
    this.api.SavecollectionFollowupModel(this.editgrnData)
      .subscribe(() => {
        this.loading = false;
        this.getClaimFollowup(this.searchClaim.page);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  cancelGrn(u: collectionFollowupModel) {
    this.custReportAll.forEach(x => {
      x.isEdit = false;
    });
  }

  downloadBills(u: collectionFollowupModel) {
    this.api.downloadBlobFilePost("Download1/" + u.billId + "/Claim", null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            var fileName = u.billId + ".jpg";
            if (data.body.type == "application/zip") {
              fileName = u.billId + ".zip"
            } else if (data.body.type == "application/pdf") {
              fileName = u.billId + ".pdf"
            }
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }

  selAcled: collectionFollowupModel = new collectionFollowupModel();

  showDetail(u: collectionFollowupModel) {
    this.custReportAll.forEach(x => {
      if (u.collectionDetailId == x.collectionDetailId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.selAcled = u;
          this.getDocuments();
          var n = new noteTrackModel();
          n.id = this.selAcled.collectionDetailId;
          n.noteType = "ClaimFlP";
          n.internal = false;
          n.customerId = x.customerId;
          n.billId = x.billId;
          this.addNotes = n;
        }
      }
      else
        x.showDetail = false;
    });
  }

  saveNoteTrack() {
    if (this.addNotes.notes)
      this.api.saveNoteTrack(this.addNotes).subscribe(result => {
        this.getDocuments();
        this.addNotes.notes = "";
        this.addNotes.internal = false;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  suppliedDocs: noteTrackModel[] = [];
  addNotes: noteTrackModel = new noteTrackModel();
  getDocuments() {
    var d = new noteTrackModel();
    d.id = this.selAcled.collectionDetailId;
    d.noteType = "ClaimFlP";
    d.page = 0;
    d.pageSize = 200;
    this.api.getnoteTraks(d).subscribe(result => this.suppliedDocs = result, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  onDeleteDocument(i: number) {
    this.api.deletenoteTrack(i)
      .subscribe(result => this.getDocuments(), error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

}
