<div class="row">
    <div class="col">
        <div class="form-row">
            <div class="col-lg-1 col-md-2">
                <label for="companyName">Company</label>
                <select class="form-control" [(ngModel)]="searchBillModel.companyName">
                    <option value="" selected>All</option>
                    <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
                </select>
            </div>
            <div class="col-lg-1 col-md-2">
                <label>Bill#</label>
                <input type="text" [(ngModel)]="searchBillModel.billNumber" class="form-control" />
            </div>
            <div class="col-lg-1 col-md-2">
                <label>Customer</label>
                <input type="text" [(ngModel)]="searchBillModel.customerName" class="form-control" />
            </div>
            <div class="col-lg-1 col-md-2">
                <label>Account</label>
                <input type="text" [(ngModel)]="searchBillModel.accountName" class="form-control" />
            </div>
            <div class="col-lg-1 col-md-2">
                <label>PO#</label>
                <input type="text" [(ngModel)]="searchBillModel.poNumber" class="form-control" />
            </div>
            <div class="col-lg-1 col-md-2">
                <label>Person</label>
                <input type="text" [(ngModel)]="searchBillModel.personName" class="form-control" />
            </div>
            <div class="col-lg-1 col-md-2">
                <label> Bill Value</label>
                <input type="number" [(ngModel)]="searchBillModel.billValue" class="form-control" />
            </div>
            <div class="col-lg-1 col-md-2">
                <label>From</label>
                <input type="date" [ngModel]="searchBillModel.fromDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler($event)" class="form-control" />
            </div>
            <div class="col-lg-1 col-md-2">
                <label>To</label>
                <input type="date" [ngModel]="searchBillModel.toDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler1($event)" class="form-control" />
            </div>
            <div class="col-lg-1 col-md-2">
                <label>Include Service Bill</label> <br>
                <input type="radio" value="I" name="ISE" [(ngModel)]="searchBillModel.includeService" />Yes
                <input type="radio" value="E" name="ISE" [(ngModel)]="searchBillModel.includeService" />No
            </div>
            <div class="col-lg-1 col-md-2">
                <label>Include Cancel Bill</label><br>
                <input type="radio" value="I" name="IcE" [(ngModel)]="searchBillModel.includeCancel" />Yes
                <input type="radio" value="E" name="ICE" [(ngModel)]="searchBillModel.includeCancel" />No
            </div>
            <div class="w-100"></div>
            <div class="col-lg-1 col-md-2">
                <label></label><Br>
                <div>
                    <button class="btn btn-sm btn-info" (click)="onCollBillSearch()">Search</button>&nbsp;
                </div>
            </div>
            <div class="col-lg-1 col-md-2">
                <label></label><Br>
                <div>
                    <button class="btn btn-sm btn-info" (click)="onCollBillSearchReset()">Reset</button>
                </div>
            </div>
            <div class="col-lg-1 col-md-2">
                <label></label><Br>
                <div>
                    <button class="btn btn-sm btn-info" (click)="downloadExpenseReport()">Download</button>
                </div>
            </div>
        </div>
        <br>
        <input name="file" id="file" (change)="upload($event)" type="file" #inputFile hidden>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Account</th>
                    <th scope="col">Company</th>
                    <th scope="col">Bill#</th>
                    <th scope="col">PO#</th>
                    <th scope="col">BillAge</th>
                    <th scope="col">BillSuppAge</th>
                    <th scope="col">Bill Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Bill</th>
                    <th scope="col">Collection</th>
                    <th scope="col">Balance</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Person</th>
                    <th scope="col">Supplied</th>
                </tr>
            </thead>
            <tbody *ngFor="let u of bills">
                <tr>
                    <td>
                        <button class="btn btn-sm btn-info" (click)="showDetail(u)">
                            Collections
                        </button>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-info" (click)="downloadBills(u)">Download</button>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-info" (click)="selectledger(u);inputFile.click()">
                            Image/PDF
                        </button>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-info" *DataCustomAcl="'Admin'" (click)="markNew(u)">
                            MarkNew
                        </button>
                    </td>
                    <td>{{u.accountName}}</td>
                    <td>{{u.companyName}}</td>
                    <td>{{u.billNumber}}</td>
                    <td>{{u.poNumber}}</td>
                    <td>{{calculateDiff(u.billDate) }}</td>
                    <td>{{calculateDiff(u.suppliedDate) }}</td>
                    <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.billStatus}}</td>
                    <td>{{u.billValue}}</td>
                    <td>{{u.colVal}}</td>
                    <td>{{u.balance}}</td>
                    <td><i class="fa fa-map-marker" *ngIf="u.mapLink" (click)="goToLink(u)">-</i>
                        {{u.customerName}}
                    </td>
                    <td>{{u.personName}}</td>
                    <td>{{u.suppliedDate| date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr *ngIf="fileType && u.showDetail">
                    <td colspan="15">
                        <div class="form-row">
                            <div class="col-md-2">
                                <button class="btn btn-sm btn-info" (click)="onUpload()">Upload Selected
                                    {{fileType}}</button>&nbsp;
                                <button class="btn btn-sm btn-info" (click)="onUploadCancel()">Cancel</button>
                            </div>
                            <div class="col-md-2">
                                <img [src]="imageSrc" *ngIf="imageSrc" style="width: 100%;height: auto;">
                            </div>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="!fileType && u.showDetail && u.accountName=='General'">
                    <td colspan="15">
                        <table class="table table-striped table-sm table-responsive table-condensed">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">CreatedBy</th>
                                    <th scope="col">CreatedDate</th>
                                    <th scope="col">Collection Date</th>
                                    <th scope="col">Collection Value</th>
                                    <th scope="col">Mode</th>
                                    <th scope="col">CollectedBy</th>
                                    <th scope="col">Remarks</th>
                                    <th scope="col">ChequeDate</th>
                                    <th scope="col">ChequeNumber</th>
                                    <th scope="col">Cheque Credited Date</th>
                                    <th scope="col">Bounced</th>
                                    <th scope="col">Bounced FollowedUp</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let u of colls">
                                    <td>{{u.createdBy}}</td>
                                    <td>{{u.createdDate| date: 'dd/MM/yyyy'}}</td>
                                    <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                                    <td>{{u.collectionValue}}</td>
                                    <td>{{u.mode}}</td>
                                    <td>{{u.collectedBy}}</td>
                                    <td>{{u.remarks}}</td>
                                    <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
                                    <td>{{u.chequeNumber}}</td>
                                    <td>{{u.creditedDate| date: 'dd/MM/yyyy'}}</td>
                                    <td>{{u.bounced}}</td>
                                    <td>{{u.bouncedFollowedUp}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr *ngIf="!fileType && u.showDetail && u.accountName!='General'">
                    <td colspan="15">
                        <table class="table table-striped table-sm table-responsive table-condensed">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">CreatedBy</th>
                                    <th scope="col">CreatedDate</th>
                                    <th scope="col">Bill#</th>
                                    <th scope="col">BillValue</th>
                                    <th scope="col">BillDate</th>
                                    <th scope="col">BillStatus</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Mode</th>
                                    <th scope="col">CollectionValue</th>
                                    <th scope="col">BankDescri</th>
                                    <th scope="col">BankAmount</th>
                                    <th scope="col">CrDr</th>
                                    <th scope="col">TransPostedDate</th>
                                    <th scope="col">Source</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let u1 of collsmt">
                                    <td [class.bg-success]="u.billNumber==u1.billNumber">{{u1.billNumber}}</td>
                                    <td>{{u1.createdBy}}</td>
                                    <td>{{u1.createdDate| date: 'dd/MM/yyyy'}}</td>
                                    <td>{{u1.billValue}}</td>
                                    <td>{{u1.billDate| date: 'dd/MM/yyyy'}}</td>
                                    <td>{{u1.billStatus}}</td>
                                    <td>{{u1.companyName}}</td>
                                    <td>{{u1.customerName}}</td>
                                    <td>{{u1.mode}}</td>
                                    <td>{{u1.collectionValue}}</td>
                                    <td>{{u1.description}}</td>
                                    <td>{{u1.transactionAmount}}</td>
                                    <td>{{u1.crDr}}</td>
                                    <td>{{u1.transPostedDate| date: 'dd/MM/yyyy'}}</td>
                                    <td>{{getSource(u1.source)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>