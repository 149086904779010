import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class locationService {
  constructor() { }

  getPosition(): Promise<any> {

    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resp => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
          err => {
            console.log(err);
            resolve({ lng:0, lat: 0 });
          });
      }else{
        resolve({ lng:0, lat: 0 });
      }
    });
  }

  watchID: any;
  
  watchPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.watchID) {
        navigator.geolocation.clearWatch(this.watchID);
      }
      this.watchID = navigator.geolocation.watchPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          console.log(err);
          reject(err);
        });
    });
  }

}
