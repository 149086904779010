import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { hrChitModel } from 'src/app/models1';
import { PmService } from 'src/app/_services/pm.service';
import { RayarApiService } from 'src/app/_services/rayar-api.service';

@Component({
  selector: 'app-hrchit',
  templateUrl: './hrchit.component.html',
  styleUrls: ['./hrchit.component.css']
})
export class HrchitComponent implements OnInit {
  loading = false;
  error = '';
  submitted = false;

  billAddEditForm1: FormGroup;
  get f1() { return this.billAddEditForm1.controls; }

  constructor(private api: PmService, private formBuilder: FormBuilder, private rapi: RayarApiService) { }

  ngOnInit(): void {
    this.billAddEditForm1 = this.formBuilder.group({
      name: ['', Validators.required],
      mode: ['', Validators.required],
      amount: ['', Validators.required],
      amountType: ['', Validators.required],
    });
  }

  onSubmitOut() {
    this.submitted = true;
    if (this.billAddEditForm1.invalid) return;
    this.loading = true;
    var billModel = new hrChitModel();
      this.api.AddEditChit1(billModel).subscribe(result => {
      this.error = '';
      this.submitted = false;
      this.loading = false;      
      this.billAddEditForm1.setValue({
        name: '',
        mode: '',
        amount: '',
        amountType: '',
      });
      this.onSearch();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }
  customers: hrChitModel[] = [];

  onSearch() {
    this.api.getchits1().subscribe(result => {
      this.customers = result;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }
}
