import { Component, OnInit } from '@angular/core';
import { CollectionModel, BillModel, BankStmtModel, User, Role, CollectonPersonModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';
import { formatDate } from '@angular/common';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-cheque-review',
  templateUrl: './cheque-review.component.html',
  styleUrls: ['./cheque-review.component.css']
})
export class ChequeReviewComponent implements OnInit {
  submitted = false;
  loading = false;
  error = '';
  selectedBill: CollectionModel[] = [];
  currentUser: User;
  persons1: CollectonPersonModel[] = [];

  constructor(private authenticationService: AuthenticationService,
    private api: RayarApiService) {
    this.onSearchPerson();
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getCollPersons();
    this.searchBillModel.personId = 0;
  }

  ngOnInit(): void {
  }

  getCollPersons() {
    this.api.getCollPersons().subscribe(result => {
      this.persons1 = result;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  colls: CollectionModel[] = [];
  searchCustomerName: string = '';
  searchBillModel: BillModel = new BillModel();

  onSearchPerson() {
    this.searchBillModel.page = 0;
    if (this.searchBillModel.personId)
      this.searchBillModel.personId = parseInt(this.searchBillModel.personId.toString());
    if (this.searchBillModel.billValue)
      this.searchBillModel.billValue = parseInt(this.searchBillModel.billValue.toString());
    if (this.searchBillModel.companyName)
      this.searchBillModel.companyName = this.searchBillModel.companyName.toString();

    this.api.oncollsView('chequeReview', this.searchBillModel)
      .subscribe(result => {
        this.colls = result;
        this.colls.forEach((currentValue, index) => {
          currentValue.showDetail = false;
        });
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }


  onReset() {
    this.searchBillModel = new BillModel();
    this.searchBillModel.personId = 0;
    this.onSearchPerson();
  }

  markMissing(u: CollectionModel) {
    if (confirm("Are you sure to Mark Returned?")) {
      this.api.markMissing(u.collectionId).subscribe(result => {
        this.onSearchPerson();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
    }
  }

  markCredited(u: CollectionModel) {
    this.colls.forEach(x => {
      if (u.collectionId == x.collectionId) {
        x.showDetail = !x.showDetail;
        this.error = '';
        this.bankstamentIds = [];
        this.selectedBill = this.colls.filter(function (hero) {
          return hero.chequeNumber == u.chequeNumber;
        });
        this.bouncedRadio = "No";
        this.selectedFromDate = u.chequeDate;
        this.description = u.chequeNumber.toString();
        this.getBankStmts();
      }
      else {
        x.showDetail = false;
      }
    });
  }

  onSelectBkStmtSaveRev() {
    if (this.selectedBill.length == 1) {
      if (this.bankstamentIds.length = 1) {
        this.error = '';
        this.markBkStmtRev = true;
        this.onCollSubmit();
      }
    } else {
      if (this.bankstamentIds.length > 0) {
        this.error = '';
        this.markBkStmtRev = true;
        this.onCollSubmit();
      }
    }
  }

  onSelectBkStmtSave() {
    if (this.selectedBill.length == 1) {
      if (this.bankstamentIds.length = 1) {
        this.error = '';
        this.markBkStmtRev = false;
        this.onCollSubmit();
      }
    } else {
      if (this.bankstamentIds.length > 0) {
        this.error = '';
        this.markBkStmtRev = false;
        this.onCollSubmit();
      }
    }
  }

  markBkStmtRev: boolean = false;
  bankstamentIds: number[] = [];

  onbankstmtsSelect(event: any) {
    var cid = parseInt(event.target.value);
    if (event.target.checked) {
      this.bankstamentIds.push(cid);
    }
    else {
      for (var i = 0; i < this.bankstamentIds.length; i++) {
        if (this.bankstamentIds[i] === cid) {
          this.bankstamentIds.splice(i, 1);
        }
      }
    }
  }

  onCollSubmit() {
    this.submitted = true;
    this.loading = true;

    var markbkStRev = false;
    if (this.markBkStmtRev) {
      markbkStRev = true;
    }
    var cids: number[] = [];
    this.selectedBill.forEach((x) => {
      cids.push(x.collectionId);
    });
    this.api.markCredited(cids, this.bankstamentIds, markbkStRev, this.bouncedRadio == 'Yes').subscribe(result => {
      this.submitted = false;
      this.loading = false;
      this.onSearchPerson();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  selectedFromDate = new Date();
  selectedToDate = new Date();
  sReviewed: string = "2";
  sRemar: string = "2";
  bouncedRadio: string = "No";

  bills: BankStmtModel[] = [];
  description: string = '';

  onSearch() {
    this.getBills();
  }

  selectChangeHandlerFrom(event: any) {
    this.selectedFromDate = event.target.value;
  }

  selectChangeHandlerTo(event: any) {
    this.selectedToDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.sReviewed = event.target.value;
  }

  selectChangeHandler2(event: any) {
    this.sRemar = event.target.value;
  }

  getBankStmts() {
    this.api.getBankStmts("bankStmtsDesc",
      formatDate(this.selectedFromDate, 'yyyy-MM-dd', 'en'),
      formatDate(this.selectedToDate, 'yyyy-MM-dd', 'en'),
      this.sReviewed,
      this.sRemar,
      this.description, "", "", 0)
      .subscribe(result => { this.bills = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  getBills() {
    this.api.getBankStmts("bankStmts", formatDate(this.selectedFromDate, 'yyyy-MM-dd', 'en'),
      formatDate(this.selectedToDate, 'yyyy-MM-dd', 'en'), this.sReviewed, this.sRemar,
      this.description, "", "", 0)
      .subscribe(result => { this.bills = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

}
