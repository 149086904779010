<div class="row">
    <div class="col">
        <div class="form-row">
            <div class="col-md-2">
                <label>Bill Number</label>
                <input class="form-control" type="text" [(ngModel)]="searchBillModel.billNumber" />
            </div>
            <div class="col-md-2">
                <label>Customer/Account</label>
                <input class="form-control" type="text" [(ngModel)]="searchBillModel.customerName" />
            </div>
            <div class="col-md-2">
                <label>Bill AssignedTo</label>
                <input class="form-control" type="text" [(ngModel)]="searchBillModel.personName" />
            </div>
            <div class="col-md-2">
                <label>From</label>
                <input type="date" [ngModel]="searchBillModel.fromDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler($event)" class="form-control" />
            </div>
            <div class="col-md-2">
                <label>To</label>
                <input type="date" [ngModel]="searchBillModel.toDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler1($event)" class="form-control" />
            </div>
            <div class="col-md-2">
                <label for="mode">Mode</label>
                <select class="form-control" [(ngModel)]="searchBillModel.billStatus"
                    (change)="selectChangeHandler3($event)">
                    <option selected value="">All</option>
                    <option value="Cash">Cash</option>
                    <option value="CashDiscount">CashDiscount</option>
                    <option value="Cheque">Cheque</option>
                    <option value="Damage">Damage</option>
                    <option value="MobileTransfer">MobileTransfer</option>
                    <option value="NEFT">NEFT</option>
                    <option value="Shortage">Shortage</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="collectedBy">Collected By</label>
                <select class="form-control" [(ngModel)]="searchBillModel.personId">
                    <option value="0" selected>All</option>
                    <option *ngFor="let p of persons1" [value]="p.personId">{{p.name}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label></label><br>
                <button class="btn btn-outline-primary" (click)="onSearchPerson()">Search</button>
                <button class="btn btn-outline-primary" (click)="onReset()">Reset</button>
            </div>
        </div>
        <br>
        <div class="form-row">
            <div class="col-md-4">
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Collected By</th>
                            <th scope="col">Cash Amount</th>
                            <th scope="col">Cheque Amount</th>
                            <th scope="col">Cheque Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let u of collsSum">
                            <td>{{u.collectedBy}}</td>
                            <td>{{u.billValue}}</td>
                            <td>{{u.collectionId}}</td>
                            <td>{{u.billId}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-4">
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Collected By</th>
                            <th scope="col">Cash Amount</th>
                            <th scope="col">Cheque Amount</th>
                            <th scope="col">Cheque Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let u of collsSum1">
                            <td>{{u.collectedBy}}</td>
                            <td>{{u.billValue}}</td>
                            <td>{{u.collectionId}}</td>
                            <td>{{u.billId}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <table class="table table-striped table-sm table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Company</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Bill Date</th>
                    <th scope="col">Bill Num</th>
                    <th scope="col">Bill Value</th>
                    <th scope="col">Assign Person</th>
                    <th scope="col">Mode</th>
                    <th scope="col">Collected</th>
                    <th scope="col">Date</th>
                    <th scope="col">remarks</th>
                    <th scope="col">CollectedBy</th>
                    <th scope="col">CheckDetails</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of colls">
                    <th scope="row">
                        <button class="btn btn-outline-primary" *ngIf="isRecButton(u)"
                            (click)="markReviewed(u)">Received</button>
                    </th>
                    <td>{{u.companyName}}</td>
                    <td>{{u.customerName}}</td>
                    <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.billNumber}}</td>
                    <td>{{u.billValue}}</td>
                    <td>{{u.personName}}</td>
                    <td>{{u.mode}}</td>
                    <td>{{u.collectionValue}}</td>
                    <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.remarks}}</td>
                    <td>{{u.collectedBy}}</td>
                    <td>{{u.chequeDate| date: 'dd/MM/yyyy'}} {{u.chequeNumber}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>