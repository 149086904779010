<div class="card">
    <h4 class="card-header"> Bills Report</h4>
    <div class="card-body">
        <div class="form-row">
            <div class="col-md-2">
                <label for="person">Pending Bills Aged Report</label>
                <select class="form-control" (change)="selectChangeHandler($event)">
                    <option value="ALL" selected>ALL</option>
                    <option value="NotAssigned">NotAssigned</option>
                    <option *ngFor="let p of persons1" [value]="p.personId">{{p.name}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label></label>
                <div>
                    <button class="btn btn-outline-primary" (click)="downloadPendBillAgeReport()">Pending Bills
                        Summary</button>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-md-2">
                <label for="person">Pending Bills By Person</label>
                <div>
                    <button class="btn btn-outline-primary" (click)="getBillsByPerson()">Pending Bills By
                        Person</button>
                </div>
            </div>
        </div>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Person Name</th>
                    <th scope="col">Bill Count</th>
                    <th scope="col">Balance</th>
                    <th scope="col">Bill Value</th>
                    <th scope="col">Collected</th>
                </tr>
            <tbody>
                <tr *ngFor="let u of pendingBillsByPerson">
                    <td>{{u.personName}}</td>
                    <td>{{u.billCount}}</td>
                    <td>{{u.balance}}</td>
                    <td>{{u.billvalue}}</td>
                    <td>{{u.collected}}</td>
                </tr>
            </tbody>
        </table>
        <div class="form-row">
            <div class="col-md-2">
                <label>Customer Last Billed Report</label>
                <div>
                    <select class="form-control" (change)="selectChangeHandler1($event)">
                        <option value="ALL" selected>ALL</option>
                        <option *ngFor="let p of routes" [value]="p.information3">{{p.information3}}-({{p.customerId}})</option>
                    </select>
                </div>
            </div>
            <div>
                <label> </label>
                <div>
                    <button class="btn btn-outline-primary" (click)="getCustomerReport()">Get Report</button>
                </div>
            </div>
        </div>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Account</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Last Billed</th>
                    <th scope="col">Last Payment</th>
                    <th scope="col">Address</th>
                    <th scope="col">Information 1</th>
                    <th scope="col">Information 2</th>
                    <th scope="col">Contact Name</th>
                    <th scope="col">Contact Phone</th>
                    <th scope="col">GST</th>
                </tr>
            <tbody>
                <tr *ngFor="let u of custReport">
                    <td>{{u.accountName}}</td>
                    <td>{{u.customerName}}</td>
                    <td>{{u.lastBilled| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.lastPayment| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.address}}</td>
                    <td>{{u.information1}}</td>
                    <td>{{u.information2}}</td>
                    <td>{{u.contactName}}</td>
                    <td>{{u.contactPhone}}</td>
                    <td>{{u.gst}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>