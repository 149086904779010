<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-md-2">
                <label for="source">Source</label>
                <select class="form-control" [(ngModel)]="source">
                    <option value="Source1" selected>Source1-Rayar</option>
                    <option value="Source2">Source2-WhiteHorse</option>
                    <option value="Source3">Source3-Nestling</option>
                    <option value="Source4">Source4-Sunrise</option>
                    <option value="Source5">Source5</option>
                </select>
            </div>
            <div class="col-md-2">
                <button class="btn btn-sm btn-info" (click)="onSearch()">Search</button>&nbsp;
            </div>
            <div class="col-md-1" *DataCustomAclOnly="'Admin'">
                <label> </label>
                <div>
                    <button class="btn btn-sm btn-info" (click)="onPaymentRequest()">Payment Advice</button>
                    &nbsp;
                </div>
            </div>
        </div>
        <br>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Account</th>
                    <th scope="col">Value Date</th>
                    <th scope="col">CrDr</th>
                    <th scope="col">Transaction Amount</th>
                    <th scope="col">Description</th>
                    <th scope="col">Id</th>
                    <th scope="col">Transaction Id</th>
                    <th scope="col">ChequeNo</th>
                    <th scope="col">TransPosted Date</th>
                </tr>
            </thead>
            <tbody *ngFor="let u of bks">
                <tr [class.bg-success]="u.selected">
                    <td>
                        <input type="checkbox" [(ngModel)]="u.selected">
                    </td>
                    <td>{{u.accountName}}</td>
                    <td>{{u.valueDate | date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.crDr}}</td>
                    <td>{{u.transactionAmount}}</td>
                    <td>{{u.description}}</td>
                    <td>{{u.id}}</td>
                    <td>{{u.transactionId}}</td>
                    <td>{{u.chequeNo}}</td>
                    <td>{{u.transPostedDate | date: 'dd/MM/yyyy'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>