import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { rayarSettingModel } from '../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-docreview',
  templateUrl: './docreview.component.html',
  styleUrls: ['./docreview.component.css']
})
export class DocreviewComponent implements OnInit {

  customers: rayarSettingModel[] = [];
  loading = false;
  isAddCustomerClicked: boolean = false;
  error = '';
  submitted = false;
  customerForm: FormGroup;
  get f() { return this.customerForm.controls; }
  searchCustomerName: string = "";
  addEditCustModel: rayarSettingModel = new rayarSettingModel();

  constructor(private formBuilder: FormBuilder, private api: RayarApiService) {
    this.getCollPesons();
  }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      settingName: ['', Validators.required],
      settingValue: ['', Validators.required]
    });
  }
  day: string = new Date().getDate().toString();
  m: string = new Date().getMonth().toString();
  downloadBills() {
    this.api.downloadBlobFilePost("Download12/2022/" + this.m + "/" + this.day, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            var fileName = "123.jpg";
            if (data.body.type == "application/zip") {
              fileName = "123.zip"
            } else if (data.body.type == "application/pdf") {
              fileName = "123.pdf"
            }
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );

  }

  onEditCustomer(u: rayarSettingModel) {
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      settingName: u.settingName,
      settingValue: u.settingValue
    });
    this.addEditCustModel = u
  }

  onAddCustSubmit() {
    this.submitted = true;
    if (this.customerForm.invalid) return;
    this.loading = true;
    this.addEditCustModel.settingValue = this.f.settingValue.value;
    this.addEditCustModel.settingName = this.f.settingName.value;

    this.api.AddEditRayarSetting(this.addEditCustModel)
      .subscribe(() => {
        this.submitted = false;
        this.loading = false;
        this.onCustSearchReset();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onCloseAddCust() {
    this.isAddCustomerClicked = false;
  }

  oAddCustomer() {
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      settingValue: '',
      settingName: ''
    });
    this.addEditCustModel = new rayarSettingModel();
    this.addEditCustModel.rayarSettingId = 0;
    this.addEditCustModel.settingValue = '';
    this.addEditCustModel.settingName = '';
  }

  onNameCustomerSearch() {
    this.isAddCustomerClicked = false;
    if (this.searchCustomerName) {
      this.api.getSearchRayarSetting(this.searchCustomerName)
        .subscribe(result => { this.customers = result; }, error => {
          this.error = error;
          this.loading = false; console.error(error);
        });
    }
  }

  onCustSearchReset() {
    this.isAddCustomerClicked = false;
    this.searchCustomerName = "";
    this.getCollPesons();
  }

  getCollPesons() {
    this.api.getRayarSettings()
      .subscribe(result => { this.customers = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  doRefresh() {
    this.api.getRayarSettingsRefresh()
      .subscribe(result => { this.customers = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }
}
