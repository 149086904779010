<div class="card">
    <h4 class="card-header"> Collection Report</h4>
    <div class="card-body">
        <h4>Collection Pending Review Last 6 Months</h4>
        <button class="btn btn-outline-primary" (click)="getPendingReviewReport()">Pending Review Report</button>
        <button class="btn btn-outline-primary" (click)="download('CollectionPendingReviewReport')">Download</button>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">CollectedBy</th>
                    <th scope="col">Mode</th>
                    <th scope="col">NotReceived</th>
                    <th scope="col">NotReceivedCount</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of collectionPendingReview">
                    <td>{{u.collectedBy}}</td>
                    <td>{{u.mode}}</td>
                    <td>{{u.notReceived}}</td>
                    <td>{{u.notReceivedCount}}</td>
                </tr>
            </tbody>
        </table>

        <div class="form-row">
            <div class="col-md-2">
                <label for="person">Person Collection Report</label>
                <select class="form-control" (change)="selectChangeHandler($event)">
                    <option value="0" selected>ALL</option>
                    <option *ngFor="let p of persons1" [value]="p.personId">{{p.name}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label></label>
                <div>
                    <button class="btn btn-outline-primary" (click)="OnpersonCollectionReport()">Person Collection Report</button>
                </div>
            </div>
        </div>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">CollectedBy</th>
                    <th scope="col">Date</th>
                    <th scope="col">TotalValue</th>
                    <th scope="col">TotalCount</th>
                    <th scope="col">Cash</th>
                    <th scope="col">CashDiscount</th>
                    <th scope="col">NEFT</th>
                    <th scope="col">Cheque</th>
                    <th scope="col">MobileTransfer</th>
                    <th scope="col">Damage</th>
                    <th scope="col">CashCount</th>
                    <th scope="col">CashDiscountCount</th>
                    <th scope="col">NEFTCount</th>
                    <th scope="col">ChequeCount</th>
                    <th scope="col">MobileTransferCount</th>
                    <th scope="col">DamageCount</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of personCollectionReport">
                    <td>{{u.collectedBy}}</td>
                    <td>{{u.entryDate | date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.total}}</td>
                    <td>{{u.totalCount}}</td>
                    <td>{{u.cash}}</td>
                    <td>{{u.cashDiscount}}</td>
                    <td>{{u.neft}}</td>
                    <td>{{u.cheque}}</td>
                    <td>{{u.mobileTransfer}}</td>
                    <td>{{u.damage}}</td>
                    <td>{{u.cashCount}}</td>
                    <td>{{u.cashDiscountCount}}</td>
                    <td>{{u.neftCount}}</td>
                    <td>{{u.chequeCount}}</td>
                    <td>{{u.mobileTransferCount}}</td>
                    <td>{{u.damageCount}}</td>
                </tr>
            </tbody>
        </table>

        <div class="form-row">
            <div class="col-md-2">
                <label for="person">Collection Person Volumne By Month</label>
                <select class="form-control" (change)="selectChangeHandler1($event)">
                    <option value="0" selected>ALL</option>
                    <option *ngFor="let p of persons1" [value]="p.personId">{{p.name}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label></label>
                <div>
                    <button class="btn btn-outline-primary" (click)="OnCollectionPersonReport()">Collection ByMonth Report</button>
                </div>
            </div>
        </div>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">CollectedBy</th>
                    <th scope="col">Year</th>
                    <th scope="col">Month</th>
                    <th scope="col">Cash</th>
                    <th scope="col">CashDiscount</th>
                    <th scope="col">NEFT</th>
                    <th scope="col">Cheque</th>
                    <th scope="col">Damage</th>
                    <th scope="col">MobileTransfer</th>
                    <th scope="col">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of collectionPersonVolumneByMonth">
                    <td>{{u.collectedBy}}</td>
                    <td>{{u.year}}</td>
                    <td>{{u.month}}</td>
                    <td>{{u.cash}}</td>
                    <td>{{u.cashDiscount}}</td>
                    <td>{{u.neft}}</td>
                    <td>{{u.cheque}}</td>
                    <td>{{u.damage}}</td>
                    <td>{{u.mobileTransfer}}</td>
                    <td>{{u.total}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>