import { Component, OnInit } from '@angular/core';
import { User, ReturnTrackModel, Role, ProductModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { AuthenticationService } from '../_services/authentication.service';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-damage-track',
  templateUrl: './damage-track.component.html',
  styleUrls: ['./damage-track.component.css']
})
export class DamageTrackComponent implements OnInit {

  loading = false;
  bills: ReturnTrackModel[] = [];
  billModel = new ReturnTrackModel();
  search = new ReturnTrackModel();
  error = '';
  billAddEditForm: FormGroup;
  get f() { return this.billAddEditForm.controls; }
  submitted = false;
  isAddEdit: boolean = false;

  constructor(private formBuilder: FormBuilder, private api: RayarApiService) {
    this.search.page = 0;
    this.search.pageSize = 200;
  }

  ngOnInit() {
    this.billAddEditForm = this.formBuilder.group({
      mrp: ['', Validators.required],
      quantity: ['', Validators.required],
      remarks: ['', Validators.required],
      batchNo: ['', Validators.required],
    });
  }

  getBills(p: number) {
    this.search.page = p;
    this.api.getDamages(this.search).subscribe(result => { this.bills = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }


}
