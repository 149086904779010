<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-md-2">
                <label>Bill/Customer</label>
                <input type="text" [(ngModel)]="search.customerName" class="form-control" />
            </div>
            <div class="col-md-2">
                <label>Product Name</label>
                <input type="text" [(ngModel)]="search.productName" class="form-control" />
            </div>
            <div class="col-md-1">
                <label> </label> <Br>
                <div>
                    <button class="btn btn-sm btn-info" (click)="getBills(0)">Search</button>
                </div>
            </div>
        </div>
        <br>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Bill#</th>
                    <th scope="col">Bill Value</th>
                    <th scope="col">Bill Date</th>
                    <th scope="col">Bill Status</th>
                    <th scope="col">Product</th>
                    <th scope="col">Billed Qty</th>
                    <th scope="col">Returned Qty</th>
                    <th scope="col">Returnable Qty</th>
                    <th scope="col">Return</th>
                    <th scope="col">C Rate</th>
                    <th scope="col">S Rate</th>
                    <th scope="col">MRP</th>
                    <th scope="col">Margin</th>
                    <th scope="col">Account</th>
                    <th scope="col">Customer</th>
                </tr>
            </thead>
            <tbody *ngFor="let u of bills">
                <tr [class.table-danger]="u.toBeReturn>0">
                    <td>
                    </td>
                    <td>{{u.billNumber}}</td>
                    <td>{{u.billValue}}</td>
                    <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.billStatus}}</td>
                    <td>{{u.productName}}</td>
                    <td>{{u.quantity}}</td>
                    <td>{{u.returnedQuantity}}</td>
                    <td>{{u.quantity-u.returnedQuantity}}</td>
                    <td>
                        <input type="number" min="1" max={{u.quantity-u.returnedQuantity}} [(ngModel)]="u.toBeReturn"
                            class="form-control form-control-sm" />
                    </td>
                    <td>{{u.crate}}</td>
                    <td>{{u.srate}}</td>
                    <td>{{u.price}}</td>
                    <td>{{u.margin}}</td>
                    <td>{{u.accountName}}</td>
                    <td>{{u.customerName}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>