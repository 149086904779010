<div class="row">
  <div class="col">
    <div *ngIf="!isAddCustomerClicked">
      <div class="form-row">
        <div class="col-md-2">
          <label>Account</label>
          <input class="form-control" type="text" [(ngModel)]="searchCustomerName" />
        </div>
        <div class="col-md-2">
          <label></label>
          <div>
            <button class="btn btn-outline-primary" (click)="onNameCustomerSearch()">Search</button>
            <button class="btn btn-outline-primary" (click)="onCustSearchReset()">Reset</button>
          </div>
        </div>
        <div class="col-md-2">
          <label></label>
          <div>
            <button class="btn btn-outline-primary" (click)="oAddCustomer()">Add Account</button>
          </div>
        </div>
      </div>
      <br>
      <table class="table table-striped table-sm table-responsive table-condensed">
        <thead class="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Account</th>
            <th scope="col">Vendor Code</th>
            <th scope="col">Search Term</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of customers">
            <th scope="row">
              <button class="btn btn-outline-primary" (click)="onEditCustomer(u)">Edit</button>
            </th>
            <td>{{u.accountName}}</td>
            <td>{{u.vendorCode}}</td>
            <td>{{u.email}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="isAddCustomerClicked">
      <form [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
        <div class="form-row">
          <div class="col-md-2">
            <label for="accountName">Account Name</label>
            <input type="text" formControlName="accountName" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.accountName.errors }" />
            <div *ngIf="submitted && f.accountName.errors" class="invalid-feedback">
              <div *ngIf="f.accountName.errors.required">Account Name is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="vendorCode">VendorCode</label>
            <input type="text" formControlName="vendorCode" class="form-control" maxlength="2000"
              [ngClass]="{ 'is-invalid': submitted && f.vendorCode.errors }" />
            <div *ngIf="submitted && f.vendorCode.errors" class="invalid-feedback">
              <div *ngIf="f.vendorCode.errors.required">VendorCode is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="email">EMail</label>
            <input type="text" formControlName="email" class="form-control" maxlength="2000"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">EMail is required</div>
            </div>
          </div>
        </div>
        <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Save
        </button>
        <button class="btn btn-outline-primary" (click)="onCloseAddCust()">close</button>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
      </form>
    </div>
  </div>
</div>