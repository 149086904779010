import { Component, OnInit } from '@angular/core';
import { BillModel, CollectionModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-paid-bills',
  templateUrl: './paid-bills.component.html',
  styleUrls: ['./paid-bills.component.css']
})
export class PaidBillsComponent implements OnInit {
  isAddCollectionClicked: boolean = false;
  loading = false;
  bills: BillModel[] = [];
  searchBillModel: BillModel = new BillModel();
  colls: CollectionModel[] = [];
  error = '';

  constructor(private api: RayarApiService) {
  }

  ngOnInit() {
  }

  onCloseAddColl() {
    this.isAddCollectionClicked = false;
  }
  selectedBill: BillModel = new BillModel();

  viewColls(u: BillModel) {
    this.selectedBill = u;
    this.isAddCollectionClicked = true;
    this.api.getCollsSearch(u.billId.toString(), 'GetColls').subscribe(result => { this.colls = result; },
      error => console.error(error));
  }


  onSearchPerson() {
    this.searchBillModel.page = 0;
    if (this.searchBillModel.personId)
      this.searchBillModel.personId = parseInt(this.searchBillModel.personId.toString());
    this.api.onBillSearch('BillsPaid', this.searchBillModel)
      .subscribe(result => { this.bills = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  selectChangeHandler(event: any) {
    this.searchBillModel.fromDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.searchBillModel.toDate = event.target.value;
  }


  onReset() {
    this.isAddCollectionClicked = false;
    this.searchBillModel = new BillModel();
    this.searchBillModel.personId = 0;
    this.bills = [];
  }
}
