<div class="row">
    <div class="col">
        <div class="row">
            <div class="col-md-2 col-lg-2">
                <label for="company">Company</label>
                <select class="form-control" [(ngModel)]="selectedcompany">
                    <option *DataCustomAclOnly="'Admin'" value="All">All</option>
                    <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
                </select>
            </div>
            <div class="col-md-2 col-lg-2">
                <label for="accBalance">Account</label>
                <select class="form-control" [(ngModel)]="selectedAccount">
                    <option value="-1">All</option>
                    <option value="0">General</option>
                    <option *ngFor="let p of persons" [value]="p.accountId">{{p.accountName}}</option>
                </select>
            </div>
            <div class="col-md-2 col-lg-2" *DataCustomAclOnly="'Admin:Company:Sales'">
                <label for="person">Sales Person</label>
                <select class="form-control" [(ngModel)]="selectedperson">
                    <option disabled selected value="">choose</option>
                    <option *DataCustomAclOnly="'Admin:Company'" value="0">All</option>
                    <option *ngFor="let p of persons1" [value]="p.personId">{{p.name}}</option>
                </select>
            </div>
            <div class="col-md-2 col-lg-2">
                <div>
                    <label>Day</label>
                    <select class="form-control" [(ngModel)]="day">
                        <option disabled selected value="">choose</option>
                        <option value=0>All</option>
                        <option value=1>Sun</option>
                        <option value=2>Mon</option>
                        <option value=3>Tue</option>
                        <option value=4>Wed</option>
                        <option value=5>Thu</option>
                        <option value=6>Fri</option>
                        <option value=7>Sat</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2 col-lg-2">
                <div>
                    <label>Customer</label>
                    <input type="text" class="form-control" [(ngModel)]="selectedtext" />
                </div>
            </div>
            <div class="col-md-2 col-lg-2" *DataCustomAclOnly="'Admin:Company'">
                <div>
                    <label>Route</label>
                    <input type="text" class="form-control" [(ngModel)]="selectedtext1" />
                </div>
            </div>
            <div class="col-md-4 col-lg-2"><label></label><br>
                <input type="radio" name="Ordered" [(ngModel)]="selectedorder" value="0"
                    (change)="selectChangeHandlercheck1($event)" />Order
                <input type="radio" name="Ordered" [(ngModel)]="selectedorder" value="1"
                    (change)="selectChangeHandlercheck1($event)" />No Order
                <input type="radio" name="Ordered" [(ngModel)]="selectedorder" value="2"
                    (change)="selectChangeHandlercheck1($event)" />All
            </div>
            <div class="col-md-2 col-lg-2">
                <label></label><br>
                <div>
                    <button class="btn btn-sm btn-info" (click)="getBSaleManTrackModel(0)">Search</button> &nbsp;
                </div>
            </div>
            <div class="col-md-2 col-lg-2">
                <label></label><br>
                <div>
                    <button class="btn btn-sm btn-info" (click)="download()">Download PendingBills</button> &nbsp;
                </div>
            </div>
        </div><br>
        <div class="form-row">
            <nav aria-label="...">
                <ul class="pagination justify-content-end">
                    <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a></li>
                    <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                    <li class="page-item">
                        <span class="page-link">Page: {{page+1}}</span>
                    </li>
                </ul>
            </nav>
            <input name="file1" id="file1" (change)="uploadMT($event)" type="file" #inputFile1 hidden>
            <input name="file" id="file" (change)="upload($event)" type="file" #inputFile hidden>

            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col" *DataCustomAclOnly="'Admin:Sales'"></th>
                        <th scope="col">Customer</th>
                        <th scope="col">Company</th>
                        <th scope="col">VisitDay</th>
                        <th scope="col" *DataCustomAclOnly="'Admin:Company'">Person</th>
                        <th scope="col" *DataCustomAclOnly="'Sales'">Person</th>
                        <th scope="col">Route</th>
                        <th scope="col">Last Order</th>
                        <th scope="col">Account</th>
                        <th scope="col" *DataCustomAclOnly="'Admin:Sales:Company'"></th>
                        <th scope="col" *DataCustomAclOnly="'Admin:Sales:Company'"></th>
                    </tr>
                </thead>
                <tbody *ngFor="let u of custReport">
                    <tr [class.bg-success]="isOrdered(u)">
                        <td *DataCustomAclOnly="'Admin:Sales'">
                            <i *ngIf="!u.isEdit && u.baskinSaleManTrackId>0" class="fa fa-angle-down"
                                (click)="showDetail(u)"></i>
                        </td>
                        <td>{{u.customerName}}</td>
                        <td>{{u.companyName}}</td>
                        <td>
                            <div *ngIf="!u.isEdit">
                                <span *ngIf="u.visitDay==1">Sun</span>
                                <span *ngIf="u.visitDay==2">Mon</span>
                                <span *ngIf="u.visitDay==3">Tue</span>
                                <span *ngIf="u.visitDay==4">Wed</span>
                                <span *ngIf="u.visitDay==5">Thu</span>
                                <span *ngIf="u.visitDay==6">Fri</span>
                                <span *ngIf="u.visitDay==7">Sat</span>
                            </div>
                            <select *ngIf="u.isEdit" class="form-control" [(ngModel)]="editgrnData.visitDay">
                                <option disabled selected value="">choose</option>
                                <option [value]="1">Sun</option>
                                <option [value]="2">Mon</option>
                                <option [value]="3">Tue</option>
                                <option [value]="4">Wed</option>
                                <option [value]="5">Thu</option>
                                <option [value]="6">Fri</option>
                                <option [value]="7">Sat</option>
                            </select>
                        </td>
                        <td *DataCustomAclOnly="'Admin:Company'">
                            <div *ngIf="!u.isEdit">{{u.personName}}</div>
                            <select *ngIf="u.isEdit" class="form-control" [(ngModel)]="editgrnData.personId">
                                <option disabled selected value="">choose</option>
                                <option *ngFor="let p of persons1" [value]="p.personId">{{p.name}}</option>
                            </select>
                        </td>
                        <td *DataCustomAclOnly="'Sales'">
                            {{u.personName}}
                        </td>
                        <td>{{u.route}}</td>
                        <td>{{u.orderedDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.accountName}}</td>
                        <td *DataCustomAclOnly="'Admin:Sales:Company'">
                            <button class="btn btn-sm btn-info" *ngIf="!u.isEdit" (click)="editGrn(u)">Edit</button>
                            <button class="btn btn-sm btn-info" *ngIf="u.isEdit" (click)="saveGrn(u)">Save</button>
                        </td>
                        <td *DataCustomAclOnly="'Admin:Sales:Company'">
                            <button class="btn btn-sm btn-info" *ngIf="u.isEdit" (click)="cancelGrn(u)">Cancel</button>
                        </td>
                    </tr>
                    <tr *ngIf="u.showDetail">
                        <td colspan="19">
                            <div class="form-row">
                                <div class="col-md-3">
                                    Bounced Checks
                                    <table class="table table-striped table-sm table-responsive table-condensed">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col">Bill#</th>
                                                <th scope="col">Check Details</th>
                                            </tr>
                                        <tbody>
                                            <tr *ngFor="let u of pendingFollowups">
                                                <td>{{u.billNumber}}</td>
                                                <td>{{u.bouncedCheckDetail }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col">
                                    Pending Cheque Clerance
                                    <table class="table table-striped table-sm table-responsive table-condensed">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col">Cheque#</th>
                                                <th scope="col">Cheque Date</th>
                                                <th scope="col">Collected</th>
                                                <th scope="col">Remarks</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Mode</th>
                                                <th scope="col">CollectedBy</th>
                                                <th scope="col">Bill Date</th>
                                                <th scope="col">Bill#</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let u of colls">
                                                <td>{{u.chequeNumber}}</td>
                                                <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
                                                <td>{{u.collectionValue}}</td>
                                                <td>{{u.remarks}}</td>
                                                <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                                                <td>{{u.mode}}</td>
                                                <td>{{u.collectedBy}}</td>
                                                <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                                                <td>{{u.billNumber}}</td>
                                                <td>{{u.billValue}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col" *ngIf="pendingBills.length>0">
                                    <table class="table table-striped table-sm table-responsive table-condensed">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col" *DataCustomAclOnly="'Admin:Sales'">Payment</th>
                                                <th scope="col" *DataCustomAclOnly="'Admin:Sales'"></th>
                                                <th scope="col" *DataCustomAclOnly="'Admin:Sales'"></th>
                                                <th scope="col">Age</th>
                                                <th scope="col">Company</th>
                                                <th scope="col">Balance</th>
                                                <th scope="col">Collected</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Bill#</th>
                                                <th scope="col">Bill Date</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let u of pendingBills">
                                            <tr [class.table-danger]="u.companyName==this.selectedCompany1">
                                                <td>
                                                    <i class="fa fa-angle-down" (click)="showDetail1(u)"></i>
                                                </td>
                                                <td><button class="btn btn-sm btn-info" (click)="downloadBills(u)"><i
                                                            class="fa fa-download"></i></button>
                                                </td>
                                                <td *DataCustomAclOnly="'Admin:Sales'">
                                                    <textarea rows="2" [(ngModel)]="u.orderText"
                                                        class="form-control form-control-sm"></textarea>
                                                </td>
                                                <td *DataCustomAclOnly="'Admin:Sales'">
                                                    <button class="btn btn-sm btn-info" (click)="postOrder1(u)"><i
                                                            class="fa fa-check"></i></button>
                                                </td>
                                                <td *DataCustomAclOnly="'Admin:Sales'">
                                                    <button class="btn btn-sm btn-info" (click)="inputFile2.click()"><i
                                                            class="fa fa-camera"></i></button>
                                                    <input name="file2" id="file2" (change)="upload2($event,u)"
                                                        type="file" #inputFile2 hidden>

                                                </td>
                                                <td>{{calculateDiff(u.billDate) }}</td>
                                                <td>{{u.companyName}}</td>
                                                <td>{{u.balance }}</td>
                                                <td>{{u.colVal }}</td>
                                                <td>{{u.billValue }}</td>
                                                <td>{{u.billNumber}}</td>
                                                <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
                                            </tr>
                                            <tr *ngIf="u.showDetail">
                                                <td colspan="9">
                                                    <table
                                                        class="table table-striped table-sm table-responsive table-condensed">
                                                        <thead class="thead-dark">
                                                            <tr>
                                                                <th scope="col"></th>
                                                                <th scope="col">Type</th>
                                                                <th scope="col">Upload Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let u of suppliedDocs2">
                                                                <td>
                                                                    <button class="btn btn-sm btn-info"
                                                                        (click)="onDownloadDocument(u.documentId, u.documentName)">Download</button>
                                                                </td>
                                                                <td>{{u.documentType}}</td>
                                                                <td>{{u.uploadDate| date: 'dd/MM/yy hh:mm a'}}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table
                                                        class="table table-striped table-sm table-responsive table-condensed">
                                                        <thead class="thead-dark">
                                                            <tr>
                                                                <th scope="col">Collect Date</th>
                                                                <th scope="col">Collected</th>
                                                                <th scope="col">Mode</th>
                                                                <th scope="col">CollectedBy</th>
                                                                <th scope="col">Remarks</th>
                                                                <th scope="col">ChequeDate</th>
                                                                <th scope="col">Cheque#</th>
                                                                <th scope="col">Credited Date</th>
                                                                <th scope="col">Bounced</th>
                                                                <th scope="col">FollowedUp</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let u of billscolls">
                                                                <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                                                                <td>{{u.collectionValue}}</td>
                                                                <td>{{u.mode}}</td>
                                                                <td>{{u.collectedBy}}</td>
                                                                <td>{{u.remarks}}</td>
                                                                <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
                                                                <td>{{u.chequeNumber}}</td>
                                                                <td>{{u.creditedDate| date: 'dd/MM/yyyy'}}</td>
                                                                <td>{{u.bounced}}</td>
                                                                <td>{{u.bouncedFollowedUp}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col" *ngIf="false">
                                    <!--*ngIf="accledgers.length>0"-->
                                    <table class="table table-striped table-sm table-responsive table-condensed">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col"></th>
                                                <th *DataCustomAclOnly="'Admin:Employee:Sales'" scope="col">
                                                </th>
                                                <th scope="col">GRN Number</th>
                                                <th scope="col">Remarks</th>
                                                <th scope="col">Account</th>
                                                <th scope="col">Customer</th>
                                                <th scope="col">Company</th>
                                                <th scope="col">Bill#</th>
                                                <th scope="col">Bill Date</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Collected</th>
                                                <th scope="col">Balance</th>
                                                <th scope="col">Supplied</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        <tbody *ngFor="let u of accledgers">
                                            <tr>
                                                <td>
                                                    <i class="fa fa-angle-down" (click)="showDetailMT(u)"></i>
                                                </td>
                                                <td *DataCustomAclOnly="'Admin:Employee:Sales'">
                                                    <button class="btn btn-sm btn-info"
                                                        (click)="selectledgerMT(u);inputFile1.click()">Upload</button>&nbsp;
                                                </td>
                                                <td>
                                                    <input type="text" [(ngModel)]="u.grnNumber" (blur)="saveGrn1(u)"
                                                        class="form-control" />
                                                </td>
                                                <td>
                                                    <input type="text" [(ngModel)]="u.remarks" (blur)="saveGrn1(u)"
                                                        class="form-control" />
                                                </td>
                                                <td>{{u.accountName}}</td>
                                                <td>{{u.customerName}}</td>
                                                <td>{{u.companyName}}</td>
                                                <td>{{u.billNumber }}</td>
                                                <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
                                                <td>{{u.billValue }}</td>
                                                <td>{{u.colVal }}</td>
                                                <td>{{u.billValue-u.colVal| number }}</td>
                                                <td>{{u.suppliedDate | date: 'dd/MM/yyyy'}}</td>
                                                <td>{{u.billStatus }}</td>
                                            </tr>
                                            <tr *ngIf="u.showDetail">
                                                <td colspan="14">
                                                    <div class="row" *ngIf="selAcled1.billId >0">
                                                        <div class="col-md-2" *ngIf="fileTypeMT">
                                                            <button class="btn btn-sm btn-info"
                                                                (click)="onUploadMT()">Upload Selected
                                                                {{fileTypeMT}}</button>&nbsp;
                                                            <button class="btn btn-sm btn-info"
                                                                (click)="onUploadCancelMT()">Cancel</button><br>
                                                            <img [src]="imageSrcMT" *ngIf="imageSrcMT"
                                                                style="width: 100%;height: auto;">
                                                        </div>
                                                        <div class="col">
                                                            <table
                                                                class="table table-striped table-sm table-responsive table-condensed">
                                                                <thead class="thead-dark">
                                                                    <tr>
                                                                        <th scope="col"></th>
                                                                        <th scope="col">Type</th>
                                                                        <th scope="col">Upload Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let u of suppliedDocsMT">
                                                                        <td>
                                                                            <button class="btn btn-sm btn-info"
                                                                                (click)="onDownloadDocument(u.documentId, u.documentName)">Download</button>
                                                                        </td>
                                                                        <td>{{u.documentType}}</td>
                                                                        <td>{{u.uploadDate| date: 'dd/MM/yy
                                                                            hh:mm a'}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col">
                                    <table class="table table-striped table-sm table-responsive table-condensed">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Upload Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let u of suppliedDocs">
                                                <td>
                                                    <button class="btn btn-sm btn-info"
                                                        (click)="onDownloadDocument1(u.documentId, u.documentName)">View</button>
                                                </td>
                                                <td>{{u.uploadDate| date: 'dd/MM/yy hh:mm a'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col">
                                    <table class="table table-striped table-sm table-responsive table-condensed">
                                        <thead class="thead-dark">
                                            <th scope="col"><i class="fa fa-inr"></i></th>
                                            <th scope="col">Margin</th>
                                            <th scope="col" *DataCustomAclOnly="'Admin:Sales'">Order</th>
                                            <th scope="col" *DataCustomAclOnly="'Admin:Sales'"></th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <input type="checkbox" [(ngModel)]="u.cashCarry">
                                                </td>
                                                <td [class.bg-danger]="isdanger(u.configMargin)">
                                                    <input type="text" [(ngModel)]="u.configMargin" class="form-control"
                                                        appTwoDigitDecimaNumber style="max-width: 70px;">
                                                </td>
                                                <td class="at1" *DataCustomAclOnly="'Admin:Sales'">
                                                    <textarea *ngIf="!u.isEdit && u.baskinSaleManTrackId>0" rows="2"
                                                        [(ngModel)]="u.orderText" class="form-control"></textarea>
                                                </td>
                                                <td *DataCustomAclOnly="'Admin:Sales'">
                                                    <button (click)="postOrder(u)"
                                                        *ngIf="!u.isEdit && u.baskinSaleManTrackId>0"
                                                        class="form-control form-control-sm">
                                                        <i class="fa fa-check"></i>
                                                    </button>
                                                    <button (click)="selectledger(u);inputFile.click()"
                                                        *ngIf="!u.isEdit && u.baskinSaleManTrackId>0"
                                                        class="form-control form-control-sm">
                                                        <i class="fa fa-camera"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" style="color: red;">
                                    {{alterText}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <table class="table table-striped table-sm table-responsive table-condensed">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col">Price</th>
                                                <th scope="col" colspan="4">Product</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{getTotal1()}}</td>
                                                <td colspan="4">Total</td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td colspan="5"><textarea type="text" rows="3" [(ngModel)]="selRemakrs"
                                                        class="form-control"></textarea>

                                                    <button (click)="onBillSubmit(u.companyName)"
                                                        class="btn btn-sm btn-info">Bill</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngFor="let u1 of getBillProductsFilter1()">
                                            <tr>
                                                <td>{{u1.disPrice}}</td>
                                                <td colspan="4">{{u1.productName}}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <button class="form-control form-control-sm" (click)="onSubNew(u1)">
                                                        <i class="fa fa-times"></i></button>
                                                </td>
                                                <td>
                                                    <button class="form-control form-control-sm"
                                                        (click)="onSubNew1(u1)">
                                                        <i class="fa fa-minus"></i></button>
                                                </td>
                                                <td>
                                                    <button class="form-control form-control-sm" (click)="onAddNew(u1)">
                                                        <i class="fa fa-plus"></i></button>
                                                </td>
                                                <td class="fixw">
                                                    <input type="number" [(ngModel)]="u1.quantity" min="1"
                                                        class="form-control form-control-sm">
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <br>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <table class="table table-striped table-sm table-responsive table-condensed">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col"><input type="text" [(ngModel)]="srcPrds1"></th>
                                                <th scope="col">MRP</th>
                                                <th scope="col">Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let u2 of getProductsFilter1()">
                                            <tr [class.bg-success]="color1(u2)">
                                                <td>
                                                    <button class="form-control form-control-sm" (click)="onAddNew(u2)">
                                                        <i class="fa fa-plus"></i>
                                                    </button>
                                                </td>
                                                <td>{{u2.productName}}</td>
                                                <td>{{u2.mrp}}</td>
                                                <td>{{u2.availableQuantity}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>