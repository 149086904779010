import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { User, Role, BankStmtModel, CollectionModel, accountModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { AuthenticationService } from '../_services/authentication.service';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-bank-stmt',
  templateUrl: './bank-stmt.component.html',
  styleUrls: ['./bank-stmt.component.css']
})
export class BankStmtComponent implements OnInit {
  loading = false;
  isAddNewBillClicked: boolean = false;
  bills: BankStmtModel[] = [];
  billModel = new BankStmtModel();
  error = '';
  billAddEditForm: FormGroup;
  get f() { return this.billAddEditForm.controls; }
  submitted = false;
  currentUser: User;
  selectedToDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
  selectedFromDate = new Date(formatDate(new Date(new Date().setMonth(new Date().getMonth() - 2)), 'yyyy-MM-dd', 'en'));
  sReviewed: string = "2";
  sRemar: string = "2";
  persons: accountModel[] = [];
  accountId: number = 0;


  constructor(private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder, private api: RayarApiService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    // this.getAccounts();
  }

  ngOnInit() {
    this.billAddEditForm = this.formBuilder.group({
      remarks: ['', Validators.required],
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Enter") {
      this.onSearch();
    }
  }
  
  selectChangeHandler3(event: any) {
    this.accountId = parseInt(event.target.value);
  }

  getAccounts() {
    this.api.getAccounts().subscribe(result => {
      this.persons = result;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  postCollection() {
    this.error = '';
    if (this.accountId <= 0) return;
    this.submitted = true;
    this.loading = true;
    this.api.postCollection(this.accountId, this.billModel.id).subscribe(() => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.isAddNewBillClicked = false;
      this.getBills();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  get isAdmin() {
    return this.currentUser && this.currentUser.role === Role.Admin;
  }

  getBills() {
    this.error = '';
    this.api.getBankStmts("bankStmts", formatDate(this.selectedFromDate, 'yyyy-MM-dd', 'en'),
      formatDate(this.selectedToDate, 'yyyy-MM-dd', 'en'), this.sReviewed, this.sRemar, this.description, this.crDr, this.source, 0)
      .subscribe(result => { this.bills = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  downloadBkStmtReport() {
    var transPostedDate = formatDate(this.selectedFromDate, 'yyyy-MM-dd', 'en');
    var valueDate = formatDate(this.selectedToDate, 'yyyy-MM-dd', 'en');
    var reviewe = this.sReviewed; var remar = this.sRemar; var description = this.description;
    var page = 0; var crDr = this.crDr; var source = this.source;
    this.download("BankStmtReport", { transPostedDate, valueDate, reviewe, remar, description, crDr, source, page })
  }

  public download(q: string, b: any) {
    this.api.downloadFilePost(q, b).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }


  getPendRevBankStmts() {
    this.error = '';
    this.api.getPendRevBankStmts().subscribe(result => { this.bills = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  selectChangeHandlerFrom(event: any) {
    this.selectedFromDate = event.target.value;
  }

  selectChangeHandlerTo(event: any) {
    this.selectedToDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.sReviewed = event.target.value;
  }

  selectChangeHandler2(event: any) {
    this.sRemar = event.target.value;
  }

  editBill(u: BankStmtModel) {
    this.error = '';
    this.isAddNewBillClicked = true;
    this.billModel = u;
    this.billAddEditForm.setValue({
      remarks: u.remarks,
    });
    this.api.getCollsSearch(u.id.toString(), 'GetCollsByBkId')
      .subscribe(result => { this.colls = result; }, error => console.error(error));
  }

  onBillCancel() {
    this.error = '';
    this.isAddNewBillClicked = false;
  }

  onBillSubmit() {
    this.error = '';
    this.submitted = true;
    if (this.billAddEditForm.invalid) return;
    this.loading = true;
    this.billModel.remarks = this.f.remarks.value;

    this.api.AddBankStmt(this.billModel).subscribe(() => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.isAddNewBillClicked = false;
      this.getBills();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  markBankStmtRev(u: BankStmtModel) {
    this.error = '';
    if (!u.remarks) {
      this.error = "Remarks is Required";
      return;
    }
    if (confirm("Are you sure to Mark Reviewed?")) {
      this.api.markBankStmtRev(u).subscribe(() => {
        this.getBills();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    }
  }

  markBankStmtRevWithRemarks() {
    this.error = '';
    this.submitted = true;
    if (this.billAddEditForm.invalid) return;
    this.loading = true;
    this.billModel.remarks = this.f.remarks.value;
    this.api.markBankStmtRev(this.billModel).subscribe(() => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.isAddNewBillClicked = false;
      this.getBills();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  onSearch() {
    this.getBills();
  }

  colls: CollectionModel[] = [];
  description: string = '';
  crDr: string = "";
  source: string = "";

}
