<div class="card">
  <h4 class="card-header">App Users</h4>
  <div class="card-body">
    <div *ngIf="!isAddCustomerClicked">
      <div class="form-row">
        <div class="col-md-2">
          <label>User Name</label>
          <input class="form-control" type="text" [(ngModel)]="searchCustomerName" />
        </div>
        <div class="col-md-2">
          <label>First Name</label>
          <input class="form-control" type="text" [(ngModel)]="searchFirstName" />
        </div>
        <div class="col-md-2">
          <label for="appName">App Name</label>
          <select class="form-control" [(ngModel)]="searchAppName">
            <option value="Rayar">Rayar</option>
            <option *ngIf="isSuperAdmin" value="PerMgt">PerMgt</option>
          </select>
        </div>
        <div class="col-md-2">
          <label for="role">Role</label>
          <select class="form-control"  [(ngModel)]="searchRole">
            <option value="All" selected>All</option>
            <option *ngFor="let enum of keys()" [value]="enum">{{enum}}</option>
          </select>
           
        </div>
        <div class="col-md-2">
          <label></label>
          <div>
            <button class="btn btn-outline-primary" (click)="onNameCustomerSearch()">Search</button>
            <button class="btn btn-outline-primary" (click)="onCustSearchReset()">Reset</button>
          </div>
        </div>
        <div class="col-md-2">
          <label></label>
          <div>
            <button class="btn btn-outline-primary" (click)="oAddCustomer()">Add AppUser</button>
          </div>
        </div>
      </div>
      <br>
      <table class="table table-striped table-sm table-responsive table-condensed">
        <thead class="thead-dark">
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th *ngIf="isSuperAdmin1" scope="col"></th>
            <th scope="col">User Name</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Role</th>
            <th scope="col">ProfileId</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of getcustomers()">
            <td>
              <button class="btn btn-outline-primary" (click)="onDeleteUser(u)">Delete</button>
            </td>
            <td>
              <button class="btn btn-outline-primary" (click)="onResetPassword(u)">ResetPassword</button>
            </td>
            <td *ngIf="isSuperAdmin1">
              <button class="btn btn-outline-primary" (click)="onLoginAs(u)">Login</button>
            </td>
            <td>{{u.userName}}</td>
            <td>{{u.firstName}}</td>
            <td>{{u.lastName}}</td>
            <td>{{u.role}}</td>
            <td>{{u.profileId}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="isAddCustomerClicked">
      <form [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
        <div class="form-row">
          <div class="col-md-2">
            <label for="userName">User Name</label>
            <input type="text" formControlName="userName" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" />
            <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
              <div *ngIf="f.userName.errors.required">User Name is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="firstName">First Name</label>
            <input type="text" formControlName="firstName" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
              <div *ngIf="f.firstName.errors.required">First Name is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="lastName">Last Name</label>
            <input type="text" formControlName="lastName" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
              <div *ngIf="f.lastName.errors.required">Last Name is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="role">Role</label>
            <select formControlName="role" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.role.errors }">
              <option value="" selected>Choose</option>
              <option *ngFor="let enum of keys()" [value]="enum">{{enum}}</option>
            </select>
            <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
              <div *ngIf="f.role.errors.required">Role is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="profileId">ProfileId</label>
            <input type="text" formControlName="profileId" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.profileId.errors }" />
            <div *ngIf="submitted && f.profileId.errors" class="invalid-feedback">
              <div *ngIf="f.profileId.errors.required">Profile Id is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="appName">App Name</label>
            <select formControlName="appName" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.appName.errors }">
              <option value="" selected disabled>Choose</option>
              <option value="Rayar">Rayar</option>
              <option *ngIf="isSuperAdmin" value="PerMgt">PerMgt</option>
            </select>
            <div *ngIf="submitted && f.appName.errors" class="invalid-feedback">
              <div *ngIf="f.appName.errors.required">App Name is required</div>
            </div>
          </div>
        </div>
        <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Save
        </button>
        <button class="btn btn-outline-primary" (click)="onCloseAddCust()">close</button>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
      </form>
    </div>
  </div>
</div>
