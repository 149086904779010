<div class="card">
    <h4 class="card-header">Cancelled Bills</h4>
    <div class="card-body">
        <div>
            <div class="form-row">
                <div class="col-md-2">
                    <label>Bill Number</label>
                    <input type="text" [(ngModel)]="searchBillModel.billNumber" class="form-control" />
                </div>
                <div class="col-md-2">
                    <label>Customer</label>
                    <input type="text" [(ngModel)]="searchBillModel.customerName" class="form-control" />
                </div>
                <div class="col-md-2">
                    <label>Account</label>
                    <input type="text" [(ngModel)]="searchBillModel.accountName" class="form-control" />
                </div>
                <div class="col-md-2">
                    From
                    <input type="date" [ngModel]="searchBillModel.fromDate | date:'yyyy-MM-dd'"
                        (change)="selectChangeHandler($event)" class="form-control" />
                </div>
                <div class="col-md-2">
                    To<input type="date" [ngModel]="searchBillModel.toDate | date:'yyyy-MM-dd'"
                        (change)="selectChangeHandler1($event)" class="form-control" />
                </div>
                <div class="col-md-2">
                    Bill Value
                    <input type="number" [(ngModel)]="searchBillModel.billValue" class="form-control" />
                </div>
                <div class="col-md-2">
                    <div>
                        <button class="btn btn-outline-primary" (click)="onSearchPerson()">Search</button>
                        <button class="btn btn-outline-primary" (click)="onReset()">Reset</button>
                    </div>
                </div>
            </div>
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Account</th>
                        <th scope="col">Bill Id</th>
                        <th scope="col">Bill Number</th>
                        <th scope="col">Bill Date</th>
                        <th scope="col">Bill Value</th>
                        <th scope="col">Customer Name</th>
                        <th scope="col">Company Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let u of bills">
                        <td>{{u.accountName}}</td>
                        <td>{{u.billId}}</td>
                        <td>{{u.billNumber}}</td>
                        <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.billValue}}</td>
                        <td>{{u.customerName}}</td>
                        <td>{{u.companyName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>