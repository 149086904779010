import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { BillModel, CollectionModel, User, DocumentModel, companyModel, CollectionMtModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';
import { HttpEventType } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { AuthenticationService } from '../_services/authentication.service';
import { jsPDF } from 'jspdf';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-view-bills',
  templateUrl: './view-bills.component.html',
  styleUrls: ['./view-bills.component.css']
})
export class ViewBillsComponent implements OnInit {
  loading = false;
  bills: BillModel[] = [];
  colls: CollectionModel[] = [];
  collsmt: CollectionMtModel[] = [];
  error = '';
  searchBillModel: BillModel = new BillModel();
  currentUser: User;
  imageSrc: string;
  fileType: string;
  fileSource: any = null;
  selAcled: BillModel = new BillModel();
  suppliedDocs: DocumentModel[] = [];
  @ViewChild('inputFile') inputFile: ElementRef;
  companys: companyModel[];

  constructor(private imageCompress: NgxImageCompressService, private authenticationService: AuthenticationService, private api: RayarApiService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.searchBillModel.includeService = "E";
    this.searchBillModel.includeCancel = "E";
    this.searchBillModel.companyName = "";
    this.getcompany();
  }

  selectledger(u: BillModel) {
    this.selAcled = u;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Enter") {
      this.onCollBillSearch();
    }
  }

  getcompany() {
    this.api.getCompanys().subscribe(result => {
      this.companys = result;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }


  upload(event) {
    this.error = "";
    this.fileType = null;
    this.fileSource = null;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          this.bills.forEach(x => {
            if (this.selAcled.billId == x.billId) {
              x.showDetail = true;
            }
            else
              x.showDetail = false;
          });
          this.imageSrc = reader.result as string;
          this.imageCompress.compressFile(this.imageSrc, 50, 100).then((compressedImage) => {
            this.fileType = "Image";
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            this.fileSource = doc.output('blob');
          });
        }
        else {
          this.imageSrc = null;
          if (file.type.startsWith("application/pdf")) {
            this.api.uploadToBlob(file, "UploadBill/" + this.selAcled.billId + "/" + this.selAcled.billNumber + "/General").subscribe(
              data => {
                if (data) {
                  switch (data.type) {
                    case HttpEventType.UploadProgress:
                      break;
                    case HttpEventType.Response:
                      this.inputFile.nativeElement.value = '';
                      this.fileType = null;
                      this.fileSource = null;
                      this.imageSrc = null;
                      break;
                  }
                }
              },
              error => {
                this.inputFile.nativeElement.value = '';
              }
            );
          } else {
            this.error = "Invalid File format images/PDF only"
          }
        }
      };
    }
  }

  onUploadCancel() {
    this.bills.forEach(x => {
      x.showDetail = false;
    });
    this.fileType = null;
    this.fileSource = null;
    this.imageSrc = null;
    this.inputFile.nativeElement.value = '';
  }

  onUpload() {
    this.api.uploadToBlob(this.fileSource, "UploadBill/" + this.selAcled.billId + "/" + this.selAcled.billNumber + "/General").subscribe(
      data => {
        if (data) {
          switch (data.type) {
            case HttpEventType.UploadProgress:
              break;
            case HttpEventType.Response:
              this.inputFile.nativeElement.value = '';
              this.fileType = null;
              this.fileSource = null;
              this.imageSrc = null;
              break;
          }
        }
      },
      error => {
        this.inputFile.nativeElement.value = '';
      }
    );
  }

  ngOnInit() {
  }

  downloadExpenseReport() {
    this.searchBillModel.page = 0;
    this.download("BillsDownload/ViewBillsAll", this.searchBillModel)
  }

  downloadBills(u: BillModel) {
    this.api.downloadBlobFilePost("Download1/" + u.billId + "/ALL", null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            var fileName = u.billId + ".jpg";
            if (data.body.type == "application/zip") {
              fileName = u.billId + ".zip"
            } else if (data.body.type == "application/pdf") {
              fileName = u.billId + ".pdf"
            }
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );

  }

  getSource(c: string) {
    if (c == "Source1") return 'Rayar';
    if (c == "Source2") return 'WhiteHorse';
    if (c == "Source3") return 'Nestling';
    if (c == "Source4") return 'Sunrise';
    if (c == "Source5") return 'Source5';
  }

  showDetail(u: BillModel) {
    this.bills.forEach(x => {
      if (u.billId == x.billId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          if (x.accountName == "General") {
            this.api.getCollsSearch(u.billId.toString(), 'GetColls').subscribe(result => { this.collsmt = []; this.colls = result; },
              error => console.error(error));
          } else {
            this.api.getCollsmt(u.billId.toString(), 'GetCollsmt').subscribe(result => { this.colls = []; this.collsmt = result; },
              error => console.error(error));
          }
        }
      }
      else
        x.showDetail = false;
    });
  }

  download(q: string, b: any) {
    this.api.downloadFilePost(q, b).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }

  goToLink(u: BillModel) {
    if (u.mapLink)
      window.open(u.mapLink, "_blank");
  }

  getBills() {
    this.searchBillModel.page = 0;
    if (this.searchBillModel.poNumber)
      this.searchBillModel.poNumber = this.searchBillModel.poNumber.trim();
    if (this.searchBillModel.customerName)
      this.searchBillModel.customerName = this.searchBillModel.customerName.trim();
    if (this.searchBillModel.billNumber)
      this.searchBillModel.billNumber = this.searchBillModel.billNumber.trim();
    if (this.searchBillModel.accountName)
      this.searchBillModel.accountName = this.searchBillModel.accountName.trim();
    if (this.searchBillModel.personName)
      this.searchBillModel.personName = this.searchBillModel.personName.trim();
    this.api.onBillSearch('ViewBillsAll', this.searchBillModel).subscribe(result => { this.bills = result; }, error => {
      this.error = error;
      this.loading = false;
      console.error(error);
    });
  }

  calculateDiff(date: string) {
    if(date==null) return "-1";
    return Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000 / 60 / 60 / 24);
  }

  markNew(u: BillModel) {
    if (confirm("Are you sure to Mark New?")) {
      this.api.markNew(u.billId)
        .subscribe(result => { this.getBills(); }, error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
    }
  }

  onCollBillSearch() {
    this.getBills();
  }

  onCollBillSearchReset() {
    this.searchBillModel = new BillModel();
    this.searchBillModel.includeService = "E";
    this.searchBillModel.includeCancel = "E";
    this.bills = [];
  }

  selectChangeHandler(event: any) {
    this.searchBillModel.fromDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.searchBillModel.toDate = event.target.value;
  }

}
