import { Component, OnInit } from '@angular/core';
import { accountModel, BankStmtModel, CollectionMtModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-mtstmtview',
  templateUrl: './mtstmtview.component.html',
  styleUrls: ['./mtstmtview.component.css']
})
export class MtstmtviewComponent implements OnInit {
  persons: accountModel[] = [];
  loading = false;
  error = '';
  bills: BankStmtModel[] = [];
  search: BankStmtModel = new BankStmtModel();
  collsmt: CollectionMtModel[] = [];

  constructor(private api: RayarApiService) {
    this.search.page = 0;
    this.search.source = "";
    this.getAccounts();
  }

  ngOnInit(): void {
  }

  showDetail(u: BankStmtModel) {
    this.bills.forEach(x => {
      if (u.id == x.id) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.api.getCollsmt(u.id.toString(), 'GetCollsmt1').subscribe(result => { this.collsmt = result; },
            error => console.error(error));
        }
      }
      else
        x.showDetail = false;
    });
  }

  selectChangeHandler3(event: any) {
    this.search.accountId = parseInt(event.target.value);
  }

  onpage(next: boolean) {
    if (next) {
      this.onSearch(this.search.page + 1);
    }
    else {
      if (this.search.page >= 1)
        this.onSearch(this.search.page - 1);
    }
  }

  onSearch(p: number) {
    this.error = '';
    this.search.page = p;
    this.api.getMtBankStmts(this.search)
      .subscribe(result => { this.bills = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }
  getSource(c: string) {
    if (c == "Source1") return 'Rayar';
    if (c == "Source2") return 'WhiteHorse';
    if (c == "Source3") return 'Nestling';
    if (c == "Source4") return 'Sunrise';
    if (c == "Source5") return 'Source5';
  }
  getAccounts() {
    this.api.getAccounts().subscribe(result => {
      this.persons = result;
      this.persons.sort((a, b) => a.accountName.localeCompare(b.accountName));
      this.search.accountId = result[0].accountId;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }
}
