<div class="row">
    <div class="col">
        <div class="form-row">
            <div class="col">
                <div class="col-md-2">
                    <label for="category">Account</label>
                    <select class="form-control" (change)="selectChangeHandler31($event)" [(ngModel)]="selCategory">
                      <option value="Mom">Mom</option>
                      <option value="P2">Mom2</option>
                      <option value="Thimma">Thimma</option>
                      <option value="P1">Dad</option>
                      <option value="P3">P3</option>
                    </select>
                </div>
                <form [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
                    <div class="form-row">
                        <div class="col-md-2">
                            <label for="entryDate">Date</label>
                            <input type="date" formControlName="entryDate" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.entryDate.errors }" />
                            <div *ngIf="submitted && f.entryDate.errors" class="invalid-feedback">
                                <div *ngIf="f.entryDate.errors.required">Date is required</div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label for="remarks">Remarks</label>
                            <input type="text" formControlName="remarks" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }" />
                            <div *ngIf="submitted && f.remarks.errors" class="invalid-feedback">
                                <div *ngIf="f.remarks.errors.required">Remarks is required</div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label for="amount">Amount</label>
                            <input type="number" formControlName="amount" class="form-control" min="1"
                                [ngClass]="{ 'is-invalid': submitted && f.amount.errors }" />
                            <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                                <div *ngIf="f.amount.errors.required">Amount is required</div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label for="inout">In/Out</label>
                            <select formControlName="inout" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.inout.errors }">
                                <option value="IN">IN</option>
                                <option value="OUT">OUT</option>
                            </select>
                            <div *ngIf="submitted && f.inout.errors" class="invalid-feedback">
                                <div *ngIf="f.inout.errors.required">In/Out is required</div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label></label><br>
                            <button type="submit" [disabled]="loading" class="btn btn-sm btn-info">
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <br>
        <div class="form-row">
            <div class="col-md-2">
                <label>Show Deleted</label>
                <input type="checkbox" [(ngModel)]="isChecked">
            </div>
        </div>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Remarks</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Balance</th>
                    <th scope="col">Date</th>
                    <th scope="col">In/Out</th>
                    <th scope="col">Active</th>
                    <th scope="col">CreatedDate</th>
                    <th scope="col">CreatedBy</th>
                    <th scope="col">LastUpdated</th>
                    <th scope="col">LastUpdatedBy</th>
                </tr>
            </thead>
            <tbody *ngFor="let u of getData()">
                <tr [class.table-danger]="!u.isActive">
                    <td>
                        <i class="fa fa-angle-down" (click)="showDetail(u)"></i>
                    </td>
                    <td>
                        <button *ngIf="u.isActive" class="btn btn-sm btn-info" (click)="OnUpdate(u)">Update</button>
                    </td>
                    <td>
                        <button *ngIf="u.isActive" class="btn btn-sm btn-info"
                            (click)="MarkAcInActve(u)">Delete</button>
                    </td>

                    <td class="elip">
                        <textarea type="text" rows="3" title="{{u.remarks}}" [(ngModel)]="u.remarks" row
                            class="form-control form-control-sm"></textarea>
                    </td>
                    <td>
                        <input type="number" min="1" [(ngModel)]="u.amount" class="form-control form-control-sm" />
                    </td>
                    <td>{{u.runningBalance}}</td>
                    <td>{{u.entryDate | date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.inout}}</td>
                    <td>{{u.isActive}}</td>
                    <td>{{u.createdDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.createdBy}}</td>
                    <td>{{u.lastUpdatedDate| date: 'dd/MM/yy hh:mm a'}}</td>
                    <td>{{u.lastUpdatedBy}}</td>
                </tr>
                <tr *ngIf="u.showDetail">
                    <td colspan="10">
                        <table class="table table-striped table-sm table-responsive table-condensed">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Remarks</th>
                                    <th scope="col">LastUpdated</th>
                                    <th scope="col">LastUpdatedBy</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let u1 of customerHists">
                                    <td>{{u1.remarks}}</td>
                                    <td>{{u1.lastUpdatedDate| date: 'dd/MM/yy hh:mm a'}}</td>
                                    <td>{{u1.lastUpdatedBy}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
