<div class="card">
    <h4 class="card-header">Activitys</h4>
    <div class="card-body">
        <div *ngIf="!isAddCustomerClicked">
            <div class="form-row">
                <div class="col-md-2">
                    <label>Activity Name</label>
                    <input class="form-control" type="text" [(ngModel)]="searchCustomerName" />
                </div>
                <div class="col-md-2">
                    <label></label>
                    <div>
                        <button class="btn btn-outline-primary" (click)="onNameCustomerSearch()">Search</button>
                        <button class="btn btn-outline-primary" (click)="onCustSearchReset()">Reset</button>
                    </div>
                </div>
                <div class="col-md-2">
                    <label></label>
                    <div>
                        <button class="btn btn-outline-primary" (click)="oAddCustomer()">Add Activity</button>
                    </div>
                </div>
            </div>
            <br>
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Activity Name</th>
                        <th scope="col">Remarks</th>
                        <th scope="col">Active</th>
                        <th scope="col">User</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let u of customers">
                        <th scope="row">
                            <button class="btn btn-sm btn-success" (click)="onEditCustomer(u)">Edit</button>&nbsp;
                            <button class="btn btn-sm btn-danger" (click)="MarkADelete(u)">Delete</button>&nbsp;
                            <button class="btn btn-sm btn-info" *ngIf="u.inUse"
                                (click)="MarkInUse(u)">InActive</button>&nbsp;
                        </th>
                        <td>{{u.activityName}}</td>
                        <td>{{u.remarks}}</td>
                        <td>{{u.inUse}}</td>
                        <td>{{u.appUserName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="isAddCustomerClicked">
            <form [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
                <div class="form-row">
                    <div class="col-md-2">
                        <label for="activityName">Activity Name</label>
                        <input type="text" formControlName="activityName" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.activityName.errors }" />
                        <div *ngIf="submitted && f.activityName.errors" class="invalid-feedback">
                            <div *ngIf="f.activityName.errors.required">Activity Name is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="remarks">Remarks</label>
                        <input type="text" formControlName="remarks" class="form-control" maxlength="2000"
                            [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }" />
                        <div *ngIf="submitted && f.remarks.errors" class="invalid-feedback">
                            <div *ngIf="f.remarks.errors.required">Remarks is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="appUserID">App Name</label>
                        <select formControlName="appUserID" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.appUserID.errors }">
                            <option value="" selected disabled>Choose</option>
                            <option *ngFor="let u of users" [value]="u.id">{{u.userName}}</option>
                        </select>
                        <div *ngIf="submitted && f.appUserID.errors" class="invalid-feedback">
                            <div *ngIf="f.appUserID.errors.required">User is required</div>
                        </div>
                    </div>
                </div>
                <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Save
                </button>
                <button class="btn btn-outline-primary" (click)="onCloseAddCust()">close</button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>
    </div>
</div>