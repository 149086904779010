<div class="card">
    <h4 class="card-header">Customers Margin</h4>
    <div class="card-body">
        <div *ngIf="!isAddCustomerClicked">
            <div class="form-row">
                <div class="col-md-2">
                    <label>Customer Name</label>
                    <input class="form-control" type="text" [(ngModel)]="searchlModel.customerName" />
                </div>
                <div class="col-md-2">
                    <label></label>
                    <div>
                        <button class="btn btn-outline-primary" (click)="onNameCustomerSearch(0)">Search</button>
                        <button class="btn btn-outline-primary" (click)="onCustSearchReset()">Reset</button>
                    </div>
                </div>
            </div>
            <nav aria-label="...">
                <ul class="pagination justify-content-end">
                    <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a></li>
                    <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                    <li class="page-item">
                        <span class="page-link">Page: {{searchlModel.page+1}}</span>
                    </li>
                </ul>
            </nav>
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Company</th>
                        <th scope="col">Margin</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let u of customers">
                        <th scope="row">
                            <button class="btn btn-outline-primary" (click)="onEditCustomer(u)">Edit</button>
                        </th>
                        <td class="elip">{{u.customerName}}</td>
                        <td class="elip">{{u.companyName}}</td>
                        <td class="elip">{{u.margin}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="isAddCustomerClicked">
            <div class="form-row">
                <div class="col-md-2">
                    <div>
                        <button class="btn btn-outline-primary" (click)="UpdateMargin()">Update</button>
                        <button class="btn btn-outline-primary" (click)="onCloseAddCust()">Close</button>

                    </div>
                </div>
            </div>
            <table class="table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Company</th>
                        <th scope="col">Margin</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let u of customers1">
                        <td class="elip">{{u.customerName}}</td>
                        <td class="elip">{{u.companyName}}</td>
                        <td>
                            <input type="text" [(ngModel)]="u.margin" class="form-control" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>