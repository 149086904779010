import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BillModel, CustomerModel, DocumentModel, companyModel, CustomerMarginModel, PendingBills, PendingFollowUpModel, TimeTrackResourceModel, CollectionModel, billPlanModel, ResourceVModel, User, Role, ProductModel, BillProductModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { conditionalValidator } from '../_helpers/conditionalValidator';
import { jsPDF } from 'jspdf';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-custorder',
  templateUrl: './custorder.component.html',
  styleUrls: ['./custorder.component.css']
})
export class CustorderComponent implements OnInit {

  loading = false;
  isAddNewBillClicked: boolean = false;
  searchCustModel = new CustomerModel();
  bills: BillModel[] = [];
  billsAll: BillModel[] = [];

  billModel = new BillModel();
  isSearchClicked: boolean = false;
  customers: CustomerModel[];
  companys: companyModel[];
  error = '';
  showAlert1 = false;
  billAddEditForm: FormGroup;
  billAddEditForm1: FormGroup;
  billAddEditForm11: FormGroup;
  get f() { return this.billAddEditForm.controls; }
  get f1() { return this.billAddEditForm1.controls; }
  get f11() { return this.billAddEditForm11.controls; }
  @ViewChild('inputFilePO') inputFilePO: ElementRef;

  @ViewChild('inputFile') inputFile: ElementRef;
  imageSrc: string;
  fileType: string;
  fileSource: any = null;
  submitted = false;
  isEditBill = false;
  isEditBill1 = false;
  isServiceCompany: boolean = false;
  isInStock: boolean = true;
  searchBillModel: BillModel = new BillModel();
  selectedCompany: string;
  billNums: string[];
  selectedcompany: string = '-1';
  selectedstock: string = '-1';
  selectedtext1: string = '';
  selecteddriver: string = '0';
  selectedCustomer: string = '0';
  selSource: string = '';
  selectedTag: string = '0';
  pendingBills: PendingBills[] = [];
  pendingFollowups: PendingFollowUpModel[] = [];
  pendingFollowupsAll: PendingFollowUpModel[] = [];
  selectedSupplied: string = '2';
  suppliedDocs: DocumentModel[] = [];
  suppliedDocs1: DocumentModel[] = [];
  customers1: CustomerMarginModel[] = [];
  selectedMargin: string = '';
  drivers: TimeTrackResourceModel[] = [];
  tags: string[] = [];
  colls: CollectionModel[] = [];
  searchBillModel1: BillModel = new BillModel();
  currentUser: User;
  srcPrds: string = '';

  constructor(private imageCompress: NgxImageCompressService, private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, private api: RayarApiService, public sanitizer: DomSanitizer) {
    this.companys = [];
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser.role == Role.Customer) {
      this.selectedCustomer = this.currentUser.profileId.toLowerCase();
      this.api.getSearchCustomerId(parseInt(this.selectedCustomer)).subscribe(result => {
        this.onSelectCust(result[0]);
        this.getcompany();
        this.searchBillModel.page = 0;
        this.getBills(0);
        this.getPendingFollowup();
      }, error => { this.error = error; this.loading = false; console.error(error) });
    } else {

    }
  }
  products: ProductModel[] = [];
  search: ProductModel = new ProductModel();

  getProductsFilter() {
    var st = this.srcPrds;
    return this.products.filter(function (hero) {
      return st == "" || hero.productName.toLowerCase().includes(st.toLowerCase());
    });
  }

  getProducts(s: string) {
    this.search.category = s;
    this.search.page = 0;
    this.search.pageSize = 1000;
    this.api.getProducts(this.search)
      .subscribe(result => { this.products = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  selectChangeHandler3(event: any, d: BillModel) {
    var d1 = new billPlanModel();
    d1.billId = d.billId;
    d1.driverName = d.driverName;
    this.api.UpdateBillDriver(d1).subscribe(result => {
    }, error => { this.error = error; this.loading = false; console.error(error) });
  }

  getPendingCheques(c: number) {
    this.searchBillModel1.page = 0;
    this.searchBillModel1.personId = 0;
    this.searchBillModel1.customerId = c;
    this.api.oncollsView('chequeReviewCid', this.searchBillModel1).subscribe(result => { this.colls = result; }, error => {
      this.error = error; this.loading = false; console.error(error)
    });
  }

  getDrivers() {
    this.api.getResources().subscribe(result => { this.drivers = result.resources; this.tags = result.tags; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  selectChangeHandlertag(b: BillModel) {
    var d = new BillModel();
    d.billId = b.billId; d.tag = b.tag;
    this.api.UpdateBillTag(d).subscribe(result => {
    }, error => { this.error = error; this.loading = false; console.error(error) });
  }

  getPendingBills(cName: string, customerId: number) {
    this.pendingFollowups = this.pendingFollowupsAll.filter(function (hero) {
      return hero.customerId == customerId;
    });
    this.api.getCustomerSearch1(cName, '', 0)
      .subscribe(result => {
        this.pendingBills = result;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
    this.getPendingCheques(customerId);
  }

  selectChangeHandlerSupplied(event: any) {
    this.filter();
  }

  onBlurEvent(b: BillModel) {
    var d = new BillModel();
    d.billId = b.billId; d.billRemarks = b.billRemarks;
    this.api.UpdateBillRemakrs(d).subscribe(result => {
    }, error => { this.error = error; this.loading = false; console.error(error) });
  }

  getPendingFollowup() {
    this.api.getPendingFollowup().subscribe(result => { this.pendingFollowupsAll = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  filter() {
    var d3 = this.selectedcompany;
    var d2 = this.selectedtext1;
    var d1 = this.selectedstock;
    var d4 = this.selecteddriver;
    var d5 = this.selectedSupplied;
    var d6 = this.selectedTag;

    this.bills = this.billsAll.filter(function (hero) {
      return (d4 == '0' || (d4 == hero.driverName)) &&
        (d6 == '0' || (d6 == "-1" && (!hero.tag || hero.tag == "")) || (d6 == hero.tag)) &&
        (d5 == '2' || (d5 == '1' && hero.supplied) || (d5 == '0' && !hero.supplied)) &&
        (d1 == '-1' || (d1 == '1' && hero.inStock) || (d1 == '0' && !hero.inStock)) &&
        (d2 == '' || (hero.billNumber != null && hero.billNumber.toLowerCase().includes(d2.toLowerCase()))
          || (hero.customerName != null && hero.customerName.toLowerCase().includes(d2.toLowerCase()))) &&
        (d3 == '-1' || (d3 == hero.companyName))
    });
  }

  onKey1(event: any) {
    this.selectedtext1 = event.target.value;
    this.filter();
  }

  selectChangeHandlerStock(event: any) {
    this.selectedstock = event.target.value;
    this.filter();
  }

  selectChangeHandlerDriver(event: any) {
    this.selecteddriver = event.target.value;
    this.filter();
  }

  selectChangeHandlerTag(event: any) {
    this.selectedTag = event.target.value;
    this.filter();
  }

  selectChangeHandlerCompany(event: any) {
    this.selectedcompany = event.target.value;
    this.filter();
  }

  ngOnInit() {
    this.billAddEditForm = this.formBuilder.group({
      billNumber: ['', conditionalValidator(() => this.billAddEditForm.get('companyName').value != 'Service' &&
        this.billAddEditForm.get('inStock').value == '1', Validators.required)],
      billValue: ['', Validators.required],
      billDate: ['', Validators.required],
      companyName: ['', Validators.required],
      margin: [''],
      inStock: [''],
      billRemarks: [''],
      poExpDate: [''],
      poNumber: [''],
      appointDate: [''],
      billNumber1: ['', conditionalValidator(() => this.billAddEditForm.get('companyName').value == 'Service' &&
        this.billAddEditForm.get('inStock').value == '1',
        Validators.required)],
    });
    this.billAddEditForm.get('inStock').valueChanges
      .subscribe(value => {
        this.isInStock = (value == '1');
        this.billAddEditForm.get('billNumber').updateValueAndValidity();
        this.billAddEditForm.get('billNumber1').updateValueAndValidity();
      });
    this.billAddEditForm.get('companyName').valueChanges
      .subscribe(value => {
        this.isServiceCompany = (value == 'Service');
        this.billAddEditForm.get('billNumber').updateValueAndValidity();
        this.billAddEditForm.get('billNumber1').updateValueAndValidity();
      });

    this.billAddEditForm1 = this.formBuilder.group({
      billValue: ['', Validators.required],
      billDate: ['', Validators.required],
      margin: [''],
      billRemarks: [''],
    });

    this.billAddEditForm11 = this.formBuilder.group({
      inStock: [''],
      billRemarks: [''],
      billValue: ['', Validators.required],
      billDate: ['', Validators.required],
      margin: [''],
      billNumber: ['', conditionalValidator(() => this.billModel.companyName != 'Service' && this.billAddEditForm11.get('inStock').value == '1', Validators.required)],
      billNumber1: ['', conditionalValidator(() => this.billModel.companyName == 'Service' && this.billAddEditForm11.get('inStock').value == '1', Validators.required)],
    });
    this.billAddEditForm11.get('inStock').valueChanges
      .subscribe(value => {
        this.isInStock = (value == '1');
        this.billAddEditForm11.get('billNumber').updateValueAndValidity();
        this.billAddEditForm11.get('billNumber1').updateValueAndValidity();
      });
  }

  getcompany() {
    this.api.getCompanys().subscribe(result => {
      this.companys = result;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  upload(event) {
    this.error = "";
    this.fileType = null;
    this.fileSource = null;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          this.imageSrc = reader.result as string;
          this.imageCompress.compressFile(this.imageSrc, 50, 100).then((compressedImage) => {
            this.fileType = "Image";
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            //this.url = doc.output('bloburl');
            //this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
            this.fileSource = doc.output('blob');
          });
        }
        else {
          this.imageSrc = null;
          if (file.type.startsWith("application/pdf")) {
            this.api.uploadToBlob(file, "UploadBill/" + this.billModel.billId + "/" + this.billModel.billNumber + "/Bill").subscribe(
              data => {
                if (data) {
                  switch (data.type) {
                    case HttpEventType.UploadProgress:
                      break;
                    case HttpEventType.Response:
                      this.inputFile.nativeElement.value = '';
                      this.fileType = null;
                      this.fileSource = null;
                      this.imageSrc = null;
                      this.getDocuments();
                      break;
                  }
                }
              },
              error => {
                this.inputFile.nativeElement.value = '';
              }
            );
          } else {
            this.error = "Invalid File format images/PDF only"
          }
        }
      };
    }
  }

  selectedFile: any;
  fileName: string;
  uploadPO(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileName = file.name;
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          var imageSrc = reader.result as string;
          this.imageCompress.compressFile(imageSrc, 50, 100).then((compressedImage) => {
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            this.selectedFile = doc.output('blob');
          });
        }
        else {
          if (file.type.startsWith("application/pdf")) {
            this.selectedFile = file;
          } else {
            this.error = "Invalid File format images/PDF only"
          }
        }
      };
    }
  }

  url: any;
  urlSafe: SafeResourceUrl;

  onUpload() {
    this.api.uploadToBlob(this.fileSource, "UploadBill/" + this.billModel.billId + "/" + this.billModel.billNumber + "/Bill").subscribe(
      data => {
        if (data) {
          switch (data.type) {
            case HttpEventType.UploadProgress:
              break;
            case HttpEventType.Response:
              this.inputFile.nativeElement.value = '';
              this.fileType = null;
              this.fileSource = null;
              this.imageSrc = null;
              this.getDocuments();
              break;
          }
        }
      },
      error => {
        this.inputFile.nativeElement.value = '';
      }
    );
  }

  onpage(next: boolean) {
    if (next) {
      this.getBills(this.searchBillModel.page + 1);
    }
    else {
      if (this.searchBillModel.page >= 1)
        this.getBills(this.searchBillModel.page - 1);
    }
  }

  getBills(p: number) {
    this.searchBillModel.page = p;
    this.searchBillModel.pageSize = 2000;
    this.searchBillModel.customerId = parseInt(this.selectedCustomer);
    this.api.onBillSearch('BillsNew', this.searchBillModel)
      .subscribe(result => {
        this.billsAll = result;
        this.filter();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  getBillSequence(companyName: string) {
    this.selectedCompany = companyName;
    this.setMargin(companyName);
    this.billNums = [];
    this.api.getBillSequence(companyName).subscribe(result => { this.billNums = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  selectChangeHandler(event: any) {
    this.getBillSequence(event.target.value);
    this.showAlert1 = this.pendingBills.filter(function (hero) {
      return hero.companyName == event.target.value;
    }).length >= 2;
  }
  billProducts: BillProductModel[] = [];

  onAddNewBill() {
    if (!this.selSource) return;
    this.pendingBills = [];
    this.isEditBill = false;
    this.isEditBill1 = false;
    this.customers1 = [];
    this.selectedMargin = '';
    this.selectedCompany = '';
    this.error = '';
    this.isAddNewBillClicked = true;
    this.isSearchClicked = false;
    this.billModel = new BillModel();
    this.billModel.billId = 0;
    this.searchCustModel = new CustomerModel();
    this.billsAll.forEach(x => {
      x.showDetail = false;
      x.showDetail1 = false;
    });
    this.getProducts(this.selSource);

  }

  color(q: ProductModel) {
    var d = false;
    this.billProducts.forEach(function (hero) {
      if (hero.productId == q.productId) {
        d = true;
      }
    });
    return d;
  }

  onAdd(q: BillModel, p: ProductModel) {
    var isexists = false;
    var m = parseFloat(q.margin.toString());
    if (isNaN(m)) m = 0;
    this.billProducts.forEach(function (hero) {
      if (hero.productId == p.productId) {
        hero.quantity = hero.quantity + 1;
        isexists = true;
      }
    });
    if (!isexists) {
      var bp = new BillProductModel();
      bp.quantity = 1;
      bp.billId = q.billId;
      bp.billProductId = 0;
      bp.price = p.mrp;
      bp.crate = p.crate;
      bp.srate = p.srate;
      bp.defaultMargin = p.defaultMargin;
      bp.margin = m.toString();
      bp.productId = p.productId;
      bp.productName = p.productName
      this.billProducts.push(bp);
    }
  }

  onAdd1(q1: BillModel, q: BillProductModel) {
    this.billProducts.forEach(function (hero) {
      if (hero.productId == q.productId) {
        hero.quantity = hero.quantity + 1;
      }
    });
  }

  onSub(q1: BillModel, q: BillProductModel) {
    this.billProducts.forEach(function (hero, index, object) {
      if (hero.productId == q.productId) {
        hero.quantity = hero.quantity - 1;
        if (hero.quantity <= 0) {
          object.splice(index, 1);
        }
      }
    });
  }

  onSub1(q: BillProductModel) {
    this.billProducts.forEach(function (hero, index, object) {
      if (hero.productId == q.productId) {
        object.splice(index, 1);
      }
    });
  }

  getTotal() {
    let total = 0;
    this.billProducts.forEach((item) => {
      total += item.disPrice;
    });
    return total.toFixed(2);
  }

  getBillProductsFilter() {
    var p = this.billProducts.filter(function (hero) {
      var m = parseFloat(hero.margin);
      if (isNaN(m)) m = 0;
      if (m == 0) {
        var dfm = parseFloat(hero.defaultMargin);
        if (isNaN(dfm)) dfm = 0;
        m = dfm;
      }
      hero.disPrice = parseFloat((hero.quantity * (hero.price * (100 - m) / 100)).toFixed(2));
      return true;
    });
    return p;
  }
  searchBp: BillProductModel = new BillProductModel();

  getBillProducts(s: number) {
    this.searchBp.billId = s;
    this.searchBp.type = "B";
    this.api.getBillProducts(this.searchBp).subscribe(result => { this.billProducts = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  editBillG(u: BillModel) {
    this.urlSafe = null;
    this.billsAll.forEach(x => {
      x.showDetail = false;
      if (u.billId == x.billId) {
        x.showDetail1 = !x.showDetail1;
        if (x.showDetail1) {
          this.api.getDcouments(x.billId, "ALL")
            .subscribe(result => this.suppliedDocs1 = result, error => {
              this.error = error;
              this.loading = false; console.error(error)
            });
          if (u.inStock) {
            this.editBill(u);
          } else {
            this.editBill1(u);
          }
        }
      }
      else
        x.showDetail1 = false;
    });
  }

  editBill(u: BillModel) {
    this.getMargin(u.customerId);
    this.error = '';
    this.isEditBill = true;
    this.isEditBill1 = false;
    this.isAddNewBillClicked = true;
    this.isSearchClicked = false;
    this.billAddEditForm1.setValue({
      billDate: formatDate(u.billDate, 'yyyy-MM-dd', 'en'),
      billValue: u.billValue,
      margin: u.margin,
      billRemarks: u.billRemarks
    });
    this.billModel = u;
    this.selectedCompany = u.companyName;
    this.getPendingBills(u.customerName, u.customerId);
  }

  editBill1(u: BillModel) {
    this.isServiceCompany = (u.companyName == 'Service');
    this.getMargin(u.customerId);
    this.getBillSequence(u.companyName);
    this.error = '';
    this.isEditBill1 = true;
    this.isEditBill = false;
    this.isAddNewBillClicked = true;
    this.isSearchClicked = false;
    var s = u.inStock ? "1" : "0";
    this.billAddEditForm11.setValue({
      billNumber: '',
      billNumber1: '',
      billDate: formatDate(u.billDate, 'yyyy-MM-dd', 'en'),
      billValue: u.billValue,
      margin: u.margin,
      billRemarks: u.billRemarks,
      inStock: s
    });
    this.billModel = u;
    this.selectedCompany = u.companyName;
    this.getPendingBills(u.customerName, u.customerId);
  }

  onSearchCust() {
    if (this.searchCustModel.customerName)
      this.api.getSearchCustomer(this.searchCustModel.customerName, 'both').subscribe(users => {
        this.loading = false;
        this.customers = users;
        this.isSearchClicked = true;
      },
        error => {
          this.error = error;
          this.loading = false;
        });

  }

  onSelectCust(u: CustomerModel) {
    this.billModel.customerName = u.customerName;
    this.billModel.customerId = u.customerId;
    this.billModel.accountName = u.accountName;
    this.getMargin(u.customerId);
    this.getPendingBills(u.customerName, u.customerId);
  }

  setMargin(c: string) {
    var cm = this.customers1.filter(function (hero) {
      return hero.companyName == c;
    });
    if (cm.length > 0) {
      this.selectedMargin = cm[0].margin;
    } else {
      this.selectedMargin = '';
    }
  }

  getMargin(u: number) {
    var n = new CustomerMarginModel();
    n.page = 0;
    n.customerId = u;
    this.api.getCustomerMargin(n).subscribe(result => {
      this.customers1 = result;
      this.setMargin(this.selectedCompany);
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  onBillCancel() {
    this.isAddNewBillClicked = false;
  }

  onBillSubmit() {
    this.submitted = true;
    if (this.billAddEditForm.invalid) return;
    if (!this.billModel.customerId || this.billModel.customerId <= 0) {
      this.error = "Select Customer"; return;
    }
    var cusid = this.billModel.customerId;
    var companyName = this.f.companyName.value;

    var bils = this.billsAll.filter(function (hero) {
      return hero.customerId == cusid && hero.companyName == companyName;
    });

    if (bils.length >= 1) {
      if (confirm("Are you sure Second Bill?")) {
        this.save();
      } else {

      }
    } else {
      this.save();
    }
  }

  save() {
    this.error = '';
    this.loading = true;
    this.billModel.margin = this.f.margin.value;
    this.billModel.inStock = this.f.inStock.value == '1' ? true : false;
    this.billModel.billDate = this.f.billDate.value;
    this.billModel.billValue = this.f.billValue.value;
    this.billModel.companyName = this.f.companyName.value;
    this.billModel.billRemarks = this.f.billRemarks.value;
    this.billModel.poNumber = this.f.poNumber.value;
    this.billModel.poExpDate = this.f.poExpDate.value;
    this.billModel.appointDate = this.f.appointDate.value;

    if (this.billModel.inStock) {
      if (this.billModel.companyName == "Service") {
        this.billModel.billNumber = this.f.billNumber1.value.toString();
      } else {
        this.billModel.billNumber = this.f.billNumber.value;
      }
    } else {
      this.billModel.billNumber = "NoStock";
    }
    this.api.uploadAddBilFile(this.selectedFile, this.billModel)
      .subscribe(result => {
        this.inputFilePO.nativeElement.value = '';
        this.selectedFile = null;
        this.fileName = "";
        this.error = '';
        this.submitted = false;
        this.loading = false;
        this.isAddNewBillClicked = false;
        this.getBills(this.searchBillModel.page);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onBillSubmit1() {
    this.submitted = true;
    if (this.billAddEditForm1.invalid) return;
    this.error = '';
    this.loading = true;
    this.billModel.margin = this.f1.margin.value;
    this.billModel.billDate = this.f1.billDate.value;
    this.billModel.billValue = this.f1.billValue.value;
    this.billModel.billRemarks = this.f1.billRemarks.value;
    this.api.UpdateBill(this.billModel).subscribe(result => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.isAddNewBillClicked = false;
      this.getBills(this.searchBillModel.page);
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  onBillSubmit11() {
    this.submitted = true;
    if (this.billAddEditForm11.invalid) return;
    this.error = '';
    this.loading = true;
    this.billModel.margin = this.f11.margin.value;
    this.billModel.inStock = this.f11.inStock.value == '1' ? true : false;
    this.billModel.billDate = this.f11.billDate.value;
    this.billModel.billValue = this.f11.billValue.value;
    this.billModel.billRemarks = this.f11.billRemarks.value;
    if (this.billModel.inStock) {
      if (this.billModel.companyName == "Service") {
        this.billModel.billNumber = this.f11.billNumber1.value.toString();
      } else {
        this.billModel.billNumber = this.f11.billNumber.value;
      }
    } else {
      this.billModel.billNumber = "NoStock";
    }

    this.api.UpdateBill1(this.billModel).subscribe(result => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.isAddNewBillClicked = false;
      this.getBills(this.searchBillModel.page);
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  markSupplied(billId: number) {
    this.api.markSupplied(billId)
      .subscribe(result => {
        this.isAddNewBillClicked = false;
        this.getBills(this.searchBillModel.page);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onClickSupplied(u: BillModel) {
    this.fileType = null;
    this.error = '';
    this.isEditBill = false;
    this.isEditBill1 = false;
    this.isAddNewBillClicked = false;
    this.isSearchClicked = false;
    this.billModel = u;
    this.billsAll.forEach(x => {
      x.showDetail1 = false;
      if (u.billId == x.billId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.getDocuments();
        }
      }
      else
        x.showDetail = false;
    });
  }

  onClickDelivered(u: BillModel) {
    this.api.markDelivered(u.billId)
      .subscribe(result => {
        this.isAddNewBillClicked = false;
        this.getBills(this.searchBillModel.page);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  getDocuments() {
    this.api.getDcouments(this.billModel.billId, "Bill")
      .subscribe(result => this.suppliedDocs = result, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onDeleteDocument(i: number) {
    this.api.deleteDocument(i)
      .subscribe(result => this.getDocuments(), error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onDownloadDocument1(i: number, f: string) {
    this.api.downloadBlobFilePost("Download/" + i, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            window.open(URL.createObjectURL(new Blob([data.body], { type: data.body.type })), 'mywindow1');
            break;
        }
      },
      () => {
      }
    );
  }

  onDownloadDocument(i: number, f: string) {
    this.api.downloadBlobFilePost("Download/" + i, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = f;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }

  markCancel(u: BillModel) {
    if (confirm("Are you sure to Cancel Bill"))
      this.api.markCancel(u.billId)
        .subscribe(result => { this.getBills(this.searchBillModel.page); }, error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
  }

  onCheckboxChange1(e) {
    this.api.SetCashCarry(e.target.value, e.target.checked).subscribe(() => {
      this.loading = false;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }
}

