import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { accountDetailHistoryModel, accountDetailModel } from 'src/app/models1';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { PmService } from 'src/app/_services/pm.service';

@Component({
  selector: 'app-account1',
  templateUrl: './account1.component.html',
  styleUrls: ['./account1.component.css']
})
export class Account1Component implements OnInit {

  customers: accountDetailModel[] = [];
  customerHists: accountDetailHistoryModel[] = [];
  loading = false;
  error = '';
  submitted = false;
  customerForm: FormGroup;
  get f() { return this.customerForm.controls; }
  addEditCustModel: accountDetailModel = new accountDetailModel();
  selCategory: string = "Mom";

  constructor(private formBuilder: FormBuilder, private api: PmService, private api1: AuthenticationService) {
    this.onNameCustomerSearch();
  }

  selectChangeHandler31(event: any) {
    this.onNameCustomerSearch();
  }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      remarks: ['', Validators.required],
      entryDate: [formatDate(new Date(), 'yyyy-MM-dd', 'en'), Validators.required],
      amount: ['', Validators.required],
      inout: ['IN', Validators.required],
    });
  }

  OnUpdate(u: accountDetailModel) {
    this.api.SaveAccountDetail(u)
      .subscribe(() => {
        this.submitted = false;
        this.loading = false;
        this.customerForm.patchValue({
          entryDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          remarks: '',
          amount: '',
          inout: 'IN'
        });
        this.onNameCustomerSearch();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  isChecked: boolean = false;

  getData() {
    var d6 = this.isChecked;
    var ret = this.customers.filter(function (hero) {
      if ((d6 == true || hero.isActive == true)) {
        return true;
      }
      return false;
    });
    return ret;
  }


  onAddCustSubmit() {
    this.submitted = true;
    if (this.customerForm.invalid) return;
    this.loading = true;
    this.addEditCustModel.category = this.selCategory;
    this.addEditCustModel.entryDate = this.f.entryDate.value;
    this.addEditCustModel.remarks = this.f.remarks.value;
    this.addEditCustModel.amount = this.f.amount.value;
    this.addEditCustModel.inout = this.f.inout.value;

    this.api.SaveAccountDetail(this.addEditCustModel)
      .subscribe(() => {
        this.submitted = false;
        this.loading = false;
        this.customerForm.patchValue({
          entryDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          remarks: '',
          amount: '',
          inout: 'IN'
        });
        this.onNameCustomerSearch();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onNameCustomerSearch() {
    var u = new accountDetailModel();
    u.page = 0;
    u.category = this.selCategory;
    this.api.GetAccountDetails(u)
      .subscribe(result => {
        this.customers = result;
        this.customers.forEach(x => { x.showDetail = false; });
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }


  showDetail(u: accountDetailModel) {
    this.customers.forEach(x => {
      if (u.accountDetailId == x.accountDetailId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.api.GetAccountDetailHistory(u.accountDetailId).subscribe(result => {
            this.customerHists = result;
          }, error => {
            this.error = error;
            this.loading = false; console.error(error);
          });
        }
      }
      else
        x.showDetail = false;
    });
  }

  MarkAcInActve(u: accountDetailModel) {
    if (confirm("Are you sure to Delete"))
      this.api.MarkAcInActve(u.accountDetailId).subscribe(result => {
        this.onNameCustomerSearch();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }
}
