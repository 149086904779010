import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { companyModel, InventLookupModel } from '../models';
import { HttpEventType } from '@angular/common/http';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-invent',
  templateUrl: './invent.component.html',
  styleUrls: ['./invent.component.css']
})
export class InventComponent implements OnInit {
  customers: InventLookupModel[] = [];
  customers1: InventLookupModel[] = [];
  loading = false;
  error = '';
  companys: companyModel[] = [];
  searchIL: InventLookupModel = new InventLookupModel();
  @ViewChild('inputFile') inputFile: ElementRef;

  constructor(private api: RayarApiService) {
    this.searchIL.page = 0;
    this.searchIL.companyName = "All";
    this.searchIL.createdDate = new Date(new Date().getTime() + (330 + new Date().getTimezoneOffset()) * 60000);
    this.getcompany();
  }

  getcompany() {
    this.api.getCompanys1().subscribe(result => {
      this.companys = result.filter(function (hero) {
        if (hero.rCompanyName)
          return true;
        else
          return false;
      });
      this.companys.sort((a, b) => a.rCompanyName.localeCompare(b.rCompanyName));
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  ngOnInit(): void {
  }

  onpage(next: boolean) {
    if (next) {
      this.onNameCustomerSearch(this.searchIL.page + 1);
    }
    else {
      if (this.searchIL.page >= 1)
        this.onNameCustomerSearch(this.searchIL.page - 1);
    }
  }
  selectChangeHandlerTo(event: any) {
    this.searchIL.createdDate = event.target.value;
  }
  total: number = 0;
  onNameCustomerSearch(p: number) {
    this.searchIL.page = p;
    this.api.getInventLookups(this.searchIL).subscribe(result => {
      this.customers = result.inventorys;
      this.customers1 = result.inventorySum;
      this.total = result.inventoryTotal;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  download() {
    var q = 'InventrylookupsReport';
    this.api.downloadFilePost(q, this.searchIL).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }

  upload(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.api.uploadFile(file, 'UploadInv').subscribe(
        data => {
          if (data) {
            switch (data.type) {
              case HttpEventType.UploadProgress:
                break;
              case HttpEventType.Response:
                this.inputFile.nativeElement.value = '';
                this.error = "Uploaded Sucessfully";
                this.onNameCustomerSearch(this.searchIL.page);
                break;
            }
          }
        },
        error => {
          this.inputFile.nativeElement.value = '';
        }
      );
    }
  }
}
