<div class="row">
  <div class="col">
    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
    <div class="row">
      <div class="col-md-2">
        <div class="input-group mb-3">
          <select class="form-control" [(ngModel)]="selFormat">
            <option value="Format1" selected>Format1-Xls</option>
            <option value="Format2">Format2-XlsX</option>
          </select>
        </div>
      </div>
      <div class="col-md-2">
        <div class="input-group mb-3">
          <select class="form-control" [(ngModel)]="selSource" (change)="selectChangeHandler31($event)">
            <option value="Source1" selected>Source1-Rayar</option>
            <option value="Source2">Source2-WhiteHorse</option>
            <option value="Source3">Source3-Nestling</option>
            <option value="Source4">Source4-Sunrise</option>
            <option value="Source5">Source5</option>
          </select>
        </div>
      </div>
      <div class="col">
        <Label>&nbsp;</Label>
        <input name="file" id="file" (change)="upload($event)" type="file" #inputFile hidden>
        <button class="btn btn-info" (click)="inputFile.click()">Upload BankStmt</button> &nbsp;
        <button class="btn btn-info" (click)="autoReview()">Auto Review</button>
      </div>
      <div class="col">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-end">
            <li class="page-item"><input type="text" class="page-link" [(ngModel)]="selSearch"></li>
            <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a></li>
            <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
            <li class="page-item"><span class="page-link">Page: {{pagenumber+1}}</span></li>
            <li class="page-item">
              <div class="input-group mb-3">
                <input type="number" class="page-link" [(ngModel)]="pagenumber1" style="max-width: 65px;" min="1">
                <div class="input-group-append">
                  <button class="btn btn-sm btn-info" (click)="onGoto()">Goto</button>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div *ngIf="billModel.id > 0">
      <form [formGroup]="billAddEditForm" (ngSubmit)="onBillSubmit()">
        <div class="form-row">
          <div class="col-md-3"><b>Source: </b>{{billModel.source}}<br>
            <b>Description: </b>{{billModel.description}}<br><b>Transaction Amount:</b>
            {{billModel.transactionAmount}} <b>{{billModel.crDr}}</b><br><b>Value
              Date:</b> {{billModel.valueDate| date: 'dd/MM/yyyy'}}
          </div>
          <div class="col-md-2">
            <label for="remarks">Remarks</label>
            <input type="text" formControlName="remarks" class="form-control form-control-sm"
              [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }" />
            <div *ngIf="submitted && f.remarks.errors" class="invalid-feedback">
              <div *ngIf="f.remarks.errors.required">Remarks is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label>&nbsp;</label><br>
            <button *ngIf="!billModel.reviewed|| isAdmin" type="submit" class="btn btn-sm btn-info">Update
              Remarks</button>
          </div>
          <div class="col-md-2">
            <label>&nbsp;</label> <br>
            <a *ngIf="!billModel.reviewed && isAdmin" class="btn btn-sm btn-info"
              (click)="markBankStmtRevWithRemarks()">Mark Review</a>
          </div>
        </div>
      </form>
      <hr color="red">
      <table class="table table-striped table-sm table-condensed">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Bill Number</th>
            <th scope="col">Collection Date</th>
            <th scope="col">Collection Value</th>
            <th scope="col">Mode</th>
            <th scope="col">Remarks</th>
            <th scope="col">Cheque Date</th>
            <th scope="col">Cheque Number</th>
            <th scope="col">Bounced Credited Date</th>
            <th scope="col">Bounced/FollowedUp</th>
            <th scope="col">Collected By</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of colls">
            <td>{{u.billNumber}}</td>
            <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
            <td>{{u.collectionValue}}</td>
            <td>{{u.mode}}</td>
            <td>{{u.remarks}}</td>
            <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
            <td>{{u.chequeNumber}}</td>
            <td>{{u.creditedDate| date: 'dd/MM/yyyy'}}</td>
            <td>{{u.bounced}}<br>{{u.bouncedFollowedUp}}</td>
            <td>{{u.collectedBy}}</td>
          </tr>
        </tbody>
      </table>
      <hr color="red">
      <div class="form-row">
        <div class="col-md-2">
          <label>Bank Stmt Search</label>
          <input type="text" [(ngModel)]="description" class="form-control form-control-sm" />
        </div>
        <div class="col-md-2">
          <br> <button class="btn btn-sm btn-info" (click)="getbankStmts()">Search</button>
        </div>
      </div>
      <table class="table table-striped table-sm table-condensed">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Remarks</th>
            <th scope="col">Reviewed</th>
            <th scope="col">Source</th>
            <th scope="col">Has Collections</th>
            <th scope="col">Value Date</th>
            <th scope="col">CrDr</th>
            <th scope="col">Transaction Amount</th>
            <th scope="col">Description</th>
            <th scope="col">Id</th>
            <th scope="col">Transaction Id</th>
            <th scope="col">ChequeNo</th>
            <th scope="col">TransPosted Date</th>
            <th scope="col">Available Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of bankstmts">
            <td class="elip">{{u.remarks}}</td>
            <td>{{u.reviewed}}</td>
            <td>{{u.source}}</td>
            <td>{{u.hasCollections}}</td>
            <td>{{u.valueDate | date: 'dd/MM/yyyy'}}</td>
            <td>{{u.crDr}}</td>
            <td>{{u.transactionAmount}}</td>
            <td>{{u.description}}</td>
            <td>{{u.id}}</td>
            <td>{{u.transactionId}}</td>
            <td>{{u.chequeNo}}</td>
            <td>{{u.transPostedDate | date: 'dd/MM/yyyy'}}</td>
            <td>{{u.availableBalance}}</td>
          </tr>
        </tbody>
      </table>
      <hr color="red">
      <div class="form-row">
        <div class="col-md-2">
          <label>Bill Number</label>
          <input type="text" [(ngModel)]="searchBillModel1.billNumber" class="form-control form-control-sm" />
        </div>
        <div class="col-md-2">
          <label>Customer</label>
          <input type="text" [(ngModel)]="searchBillModel1.customerName" class="form-control form-control-sm" />
        </div>
        <div class="col-md-2">
          <label></label>
          <div>
            <button class="btn btn-sm btn-info" (click)="getViewBills()">Search</button>
            <input type="checkbox" [(ngModel)]="showCancel" (change)="selectChangeHandlercancel($event)" />Show Cancel
          </div>
        </div>
      </div>
      <br>
      <table class="table table-striped table-sm table-condensed" *ngIf="vwbillcolls.length>0">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Collection Date</th>
            <th scope="col">Collection Value</th>
            <th scope="col">Mode</th>
            <th scope="col">Remarks</th>
            <th scope="col">Cheque</th>
            <th scope="col">Bounced /FollowedUp</th>
            <th scope="col">CollectedBy</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of vwbillcolls">
            <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
            <td>{{u.collectionValue}}</td>
            <td>{{u.mode}}</td>
            <td>{{u.remarks}}</td>
            <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}<br>{{u.chequeNumber}}<br>{{u.creditedDate| date: 'dd/MM/yyyy'}}
            </td>
            <td>{{u.bounced}}<br>{{u.bouncedFollowedUp}}</td>
            <td>{{u.collectedBy}}</td>
          </tr>
        </tbody>
      </table>
      <table class="table table-striped table-sm table-condensed">
        <thead class="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Bill Number</th>
            <th scope="col">Bill Status</th>
            <th scope="col">Bill Date</th>
            <th scope="col">Bill Value</th>
            <th scope="col">Collection Value</th>
            <th scope="col">Balance</th>
            <th scope="col">Supplied/Paid</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of viewbills">
            <td> <button class="btn btn-sm btn-info" (click)="viewColls(u)">Collections</button></td>
            <td class="elip">{{u.accountName}}<br>{{u.customerName}}</td>
            <td>{{u.companyName}}<br>{{u.billNumber}}</td>
            <td>{{u.billStatus}}</td>
            <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
            <td>{{u.billValue}}</td>
            <td>{{u.colVal}}</td>
            <td>{{u.balance}}</td>
            <td>{{u.suppliedDate| date: 'dd/MM/yyyy'}}<br>{{u.paidDate| date: 'dd/MM/yyyy'}}</td>
          </tr>
        </tbody>
      </table>
      <hr color="red">
      <div class="form-row" *ngIf="!billModel.reviewed && isAdmin">
        <div class="col-md-3"><b>Description: </b>{{billModel.description}}<br><b>Transaction Amount:</b>
          {{billModel.transactionAmount}} <b>{{billModel.crDr}}</b><br><b>Value
            Date:</b> {{billModel.valueDate| date: 'dd/MM/yyyy'}}
        </div>
        <div class="col-md-2">
          <label>Bill/Customer Search</label>
          <input type="text" [(ngModel)]="srcMtBills" class="form-control  form-control-sm" />
        </div>
        <div class="col-md-2">
          <label>Account</label>
          <select class="form-control form-control-sm" [(ngModel)]="accountId" (change)="selectChangeHandler3($event)">
            <option value="0" selected disabled>choose</option>
            <option *ngFor="let p of persons" [value]="p.accountId">{{p.accountName}}</option>
          </select>
        </div>
        <div class="col-md-2"><label></label><br>
          <button class="btn btn-sm btn-info" (click)="onBillCollectionLink1()">Post Collection</button>&nbsp;
        </div><br>
        <table class="table table-striped table-sm table-condensed">
          <thead class="thead-dark">
            <tr>
              <th></th>
              <th scope="col">Collection</th>
              <th scope="col">Discount</th>
              <th scope="col">Shortage</th>
              <th scope="col">Claim Disc</th>
              <th></th>
              <th scope="col">Remarks</th>
              <th scope="col">Remaining</th>
              <th scope="col">Bill#</th>
              <th scope="col">BillVal</th>
              <th scope="col">Collected Value</th>
              <th scope="col">Balance</th>
              <th scope="col">Bill Date</th>
              <th scope="col">Company Name</th>
              <th scope="col">Customer Name</th>
            </tr>
            <tr>
              <th></th>
              <th scope="col">{{gettotal1()}}</th>
              <th scope="col">{{getdiscount1()}}</th>
              <th scope="col">{{getdiscount12()}}</th>
              <th scope="col">{{getdiscount121()}}</th>
              <th scope="col">{{getdiscount123()}}</th>
              <th></th>
              <th scope="col"></th>
              <th scope="col">{{mtPndingBills.length}}</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          <tbody>
            <tr *ngFor="let u of mtPndingBills">
              <td>
                <button class="btn btn-sm btn-info" (click)="removemtbill(u)">Remove</button>
              </td>
              <td class="fixw">
                <input type="number" min="0" [(ngModel)]="u.billValue1" class="form-control form-control-sm" />
              </td>
              <td class="fixw">
                <input type="number" min="0" [(ngModel)]="u.discount" class="form-control form-control-sm" />
              </td>
              <td class="fixw">
                <input type="number" min="0" [(ngModel)]="u.shortage" class="form-control form-control-sm" />
              </td>
              <td class="fixw">
                <input type="number" min="0" [(ngModel)]="u.displayDiscount" class="form-control form-control-sm" />
              </td>
              <td class="fixw" [class.bg-danger]="u.claimDocCount==0 && u.displayDiscount>0">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-append">
                    <span class="input-group-text" id="inputGroup-sizing-sm">{{u.claimDocCount}}</span>
                    <button (click)="inputFile2.click()" class="btn btn-sm btn-info">
                      <i class="fa fa-upload"></i>
                    </button>
                    <input name="file" id="file" (change)="upload1($event,u)" type="file" #inputFile2 hidden>
                  </div>
                </div>
              </td>
              <td>
                <input type="text" [(ngModel)]="u.remarks" (blur)="onBlurEvent(u)"
                  class="form-control form-control-sm" />
              </td>
              <td [class.bg-danger]="coloralertS(u)">{{u.billValue1+u.discount+u.shortage-u.balance | number}}</td>
              <td [class.bg-danger]="u.billValue1>0">{{u.billNumber}}</td>
              <td>{{u.billValue }}</td>
              <td>{{u.colVal }}</td>
              <td>{{u.balance }}</td>
              <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
              <td>{{u.companyName}}</td>
              <td [class.bg-danger]="color(u)">{{u.customerName}}</td>
            </tr>
          </tbody>
        </table><br>
        <table class="table table-striped table-sm table-condensed">
          <thead class="thead-dark">
            <tr>
              <th></th>
              <th scope="col">Remarks</th>
              <th scope="col">Bill#</th>
              <th scope="col">BillVal</th>
              <th scope="col">Collected Value</th>
              <th scope="col">Balance</th>
              <th scope="col">Bill Date</th>
              <th scope="col">Company Name</th>
              <th scope="col">Customer Name</th>
            </tr>
          <tbody>
            <tr *ngFor="let u of getData()">
              <td>
                <button class="btn btn-sm btn-info" (click)="selectmtbill(u)">Add</button>&nbsp;
              </td>
              <td class="elip">
                {{u.remarks}}
              </td>
              <td>{{u.billNumber}}</td>
              <td>{{u.billValue }}</td>
              <td>{{u.colVal }}</td>
              <td>{{u.balance }}</td>
              <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
              <td>{{u.companyName}}</td>
              <td>{{u.customerName}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr color="red">
      <div class="form-row" *ngIf="!billModel.reviewed && isAdmin">
        <div class="col-md-3"><b>Description: </b>{{billModel.description}}<br><b>Transaction Amount:</b>
          {{billModel.transactionAmount}} <b>{{billModel.crDr}}</b><br><b>Value
            Date:</b> {{billModel.valueDate| date: 'dd/MM/yyyy'}}</div>
        <div class="col-md-2">
          <label>Cheque Number</label>
          <input class="form-control form-control-sm" type="number" [(ngModel)]="searchBillModel.companyName" />
        </div>
        <div class="col-md-2">
          <br> <button class="btn btn-sm btn-info" (click)="onSearchPerson()">Search</button>
        </div>
        <div class="col-md-2"> <br>
          <input type="radio" name="bRadio1" value="Yes" [(ngModel)]="bouncedRadio" /> Bounced
          <input type="radio" name="bRadio2" value="No" [(ngModel)]="bouncedRadio" /> Credited
          <button class="btn btn-sm btn-info" (click)="onCollectionLink()">Link</button>
        </div>
      </div>
      <table *ngIf="!billModel.reviewed && isAdmin" class="table table-striped table-sm table-condensed">
        <thead class="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Mode</th>
            <th scope="col">Cheque Number</th>
            <th scope="col">Cheque Date</th>
            <th scope="col">Collected {{colAmount}}</th>
            <th scope="col">Bill Value</th>
            <th scope="col">Customer Name</th>
            <th scope="col">Bill Num</th>
            <th scope="col">Bill Date</th>
            <th scope="col">remarks</th>
            <th scope="col">Date</th>
            <th scope="col">CollectedBy</th>
            <th scope="col">Assign Person</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of colls1">
            <th scope="row">
              <input type="checkbox" [value]="u.collectionId" (change)="selectChangeHandler1($event)" />
            </th>
            <td>{{u.mode}}</td>
            <td>{{u.chequeNumber}}</td>
            <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
            <td>{{u.collectionValue}}</td>
            <td>{{u.billValue}}</td>
            <td>{{u.customerName}}</td>
            <td>{{u.billNumber}}</td>
            <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
            <td>{{u.remarks}}</td>
            <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
            <td>{{u.collectedBy}}</td>
            <td>{{u.personName}}</td>
          </tr>
        </tbody>
      </table>
      <hr color="red">
      <div class="form-row">
        <div class="col-md-3"><b>Description: </b>{{billModel.description}}<br><b>Transaction Amount:</b>
          {{billModel.transactionAmount}} <b>{{billModel.crDr}}</b><br><b>Value
            Date:</b> {{billModel.valueDate| date: 'dd/MM/yyyy'}}
        </div>
        <div class="col-md-2">
          <label>Customer</label>
          <input type="text" [(ngModel)]="srcCustomerName" class="form-control form-control-sm" />
        </div>
        <div class="col-md-2">
          <label>Bill Number</label>
          <input type="text" [(ngModel)]="srcBillNumber" class="form-control form-control-sm" />
        </div>
        <div class="col-md-2"><br>
          <a class="btn btn-sm btn-info" (click)="getPendingBills()">Search</a>
        </div>
        <div class="col-md-2"><br>
          <button class="btn btn-sm btn-info" (click)="onBillCollectionLink()">Post</button>
        </div>
      </div>
      <table class="table table-striped table-sm table-condensed">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Collection</th>
            <th scope="col">Discount</th>
            <th scope="col">Claim Disc</th>
            <th scope="col">Docs</th>
            <th scope="col">Remaining</th>
            <th scope="col">Bill Value</th>
            <th scope="col">Collected Value</th>
            <th scope="col">Balance</th>
            <th scope="col">Bill Number</th>
            <th scope="col">Customer Name</th>
            <th scope="col">Company Name</th>
            <th scope="col">Bill Date</th>
            <th scope="col">Bill Assigned To</th>
          </tr>
          <tr>
            <th scope="col">{{gettotal()}}</th>
            <th scope="col">{{getdiscount()}}</th>
            <th scope="col">{{getcdiscount()}}</th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        <tbody>
          <tr *ngFor="let u of pendingBills">
            <td class="fixw">
              <input type="number" min="0" [(ngModel)]="u.billValue1" class="form-control form-control-sm" />
            </td>
            <td class="fixw">
              <input type="number" min="0" [(ngModel)]="u.discount" class="form-control form-control-sm" />
            </td>
            <td class="fixw">
              <input type="number" min="0" [(ngModel)]="u.displayDiscount" class="form-control form-control-sm">
            </td>
            <td class="fixw" [class.bg-danger]="u.claimDocCount==0 && u.displayDiscount>0">
              <div class="input-group input-group-sm mb-3">
                <div class="input-group-append">
                  <span class="input-group-text" id="inputGroup-sizing-sm">{{u.claimDocCount}}</span>
                  <button (click)="inputFile21.click()" class="btn btn-sm btn-info"><i
                      class="fa fa-upload"></i></button>
                </div>
              </div>
              <input name="file" id="file" (change)="upload12($event,u)" type="file" #inputFile21 hidden>
            </td>
            <td [class.bg-danger]="coloralertS(u)">{{u.billValue1-u.balance}}</td>
            <td>{{u.billValue }}</td>
            <td>{{u.colVal }}</td>
            <td>{{u.balance }}</td>
            <td [class.bg-danger]="u.billValue1>0">{{u.billNumber}}</td>
            <td [class.bg-danger]="color(u)">{{u.customerName}}</td>
            <td>{{u.companyName}}</td>
            <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
            <td>{{u.personName }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
