import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { Role, TimeTrackResourceModel, userModel } from '../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-attendsrc',
  templateUrl: './attendsrc.component.html',
  styleUrls: ['./attendsrc.component.css']
})
export class AttendsrcComponent implements OnInit {

  customers: TimeTrackResourceModel[] = [];
  loading = false;
  isAddCustomerClicked: boolean = false;
  error = '';
  submitted = false;
  customerForm: FormGroup;
  get f() { return this.customerForm.controls; }
  searchCustomerName: string = "";
  addEditCustModel: TimeTrackResourceModel = new TimeTrackResourceModel();

  constructor(private formBuilder: FormBuilder, private api: RayarApiService, private api1: AuthenticationService) {
    this.onNameCustomerSearch();
  }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      contactPhone: ['', Validators.required],
      resourceName: ['', Validators.required]
    });
  }


  onEditCustomer(u: TimeTrackResourceModel) {
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      resourceName: u.resourceName,
      contactPhone: u.contactPhone
    });
    this.addEditCustModel = u
  }

  onAddCustSubmit() {
    this.submitted = true;
    if (this.customerForm.invalid) return;
    this.loading = true;
    this.addEditCustModel.resourceName = this.f.resourceName.value;
    this.addEditCustModel.contactPhone = this.f.contactPhone.value;

    this.api.AddEditResource(this.addEditCustModel)
      .subscribe(() => {
        this.submitted = false;
        this.loading = false;
        this.onCustSearchReset();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onCloseAddCust() {
    this.isAddCustomerClicked = false;
  }

  oAddCustomer() {
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      accountName: '', contactPhone: ''
    });
    this.addEditCustModel = new TimeTrackResourceModel();
    this.addEditCustModel.timeTrackResourceId = 0;
  }

  onNameCustomerSearch() {
    this.isAddCustomerClicked = false;
    this.api.getSearchResource(this.searchCustomerName)
      .subscribe(result => {
        result.forEach(x => { if (!x.contactPhone) x.contactPhone = ""; if (!x.userName) x.userName = "zzzzz"; });
        this.customers = result.sort((a, b) => a.contactPhone.localeCompare(b.contactPhone) || a.resourceName.localeCompare(b.resourceName));
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  onCreateSalesLogin(cp: TimeTrackResourceModel) {
    if (cp.userName == "zzzzz") {
      this.error = '';
      this.submitted = true;
      this.loading = true;
      var u = new userModel();
      u.userName = cp.resourceName;
      u.firstName = cp.resourceName;
      u.lastName = cp.resourceName;
      u.role = Role.Driver;
      u.profileId = cp.resourceName;
      u.appName = 'Rayar';
      this.api1.AddEditUser(u)
        .subscribe(result => {
          this.submitted = false;
          this.loading = false;
          this.isAddCustomerClicked = false;
          this.onNameCustomerSearch();
        }, error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
    }
  }

  onCustSearchReset() {
    this.isAddCustomerClicked = false;
    this.searchCustomerName = "";
    this.onNameCustomerSearch();
  }
}
