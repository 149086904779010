import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { companyModel, CollectonPersonModel, CollectionModel, User, Role, PendingBills, DocumentModel, paymentModel, ReturnTrackModel } from '../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';
import { conditionalValidator } from '../_helpers/conditionalValidator';
import { jsPDF } from 'jspdf';
import { HttpEventType } from '@angular/common/http';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-add-collection',
  templateUrl: './add-collection.component.html',
  styleUrls: ['./add-collection.component.css']
})
export class AddCollectionComponent implements OnInit {

  bills: PendingBills[] = [];
  loading = false;
  error = '';
  persons: CollectonPersonModel[] = [];
  collectionlModel = new CollectionModel();
  submitted = false;
  collectionForm: FormGroup;
  get f() { return this.collectionForm.controls; }
  srcCustomerName: string = "";
  srcCompany: string = "";
  srcBillNumber: string = "";
  srcBillValue?: number;
  isModeCheque: boolean = false;
  currentUser: User;

  constructor(private imageCompress: NgxImageCompressService, private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder, private api: RayarApiService) {
    this.getcompany();
    this.getCollPersons();
    this.currentUser = this.authenticationService.currentUserValue;
    this.collectionlModel = new CollectionModel();
    this.search.page = 0;
    this.searchrt.page = 0;
    this.search.pageSize = 10;
    this.onSearchPayment(0);
  }

  searchrt = new ReturnTrackModel();
  billsrts: ReturnTrackModel[] = [];
  onpagert(next: boolean) {
    if (next) {
      this.getBills(this.searchrt.page + 1);
    }
    else {
      if (this.searchrt.page >= 1)
        this.getBills(this.searchrt.page - 1);
    }
  }
  companys: companyModel[];

  getcompany() {
    this.api.getCompanys().subscribe(result => {
      this.companys = result;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  getBills(p: number) {
    var d1: string[] = [];
    var d: number[] = [];
    this.bills.forEach(x => {
      if (x.damage > 0) {
        d.push(x.customerId);
        d1.push(x.customerName);
      }
    });
    if (d.length == 0 || d.map(item => item).filter((value, index, self) => self.indexOf(value) === index).length > 1) return;

    this.searchrt.page = p;
    this.searchrt.pageSize = 100;
    this.searchrt.customerName = d1[0];
    this.api.getDamages(this.searchrt).subscribe(result => { this.billsrts = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  searchreviewed: string = '0';
  search: paymentModel = new paymentModel();
  payments: paymentModel[] = [];

  onpage(next: boolean) {
    if (next) {
      this.onSearchPayment(this.search.page + 1);
    }
    else {
      if (this.search.page >= 1)
        this.onSearchPayment(this.search.page - 1);
    }
  }

  pagenumber1 = 1;

  onGoto() {
    if (this.pagenumber1 > 0) {
      this.onSearchPayment(this.pagenumber1 - 1);
    }
  }

  onSearchPayment(p: number) {
    this.search.page = p;
    if (this.searchreviewed == "2") this.search.reviewed = null;
    if (this.searchreviewed == "1") this.search.reviewed = true;
    if (this.searchreviewed == "0") this.search.reviewed = false;
    this.api.getPayments(this.search).subscribe(result => {
      this.payments = result;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  onBlurEvent(b: paymentModel) {
    var d = new paymentModel();
    d.documentId = b.documentId; d.remarks = b.remarks;
    this.api.UpdateDocRemakrs(d).subscribe(result => {
    }, error => { this.error = error; this.loading = false; console.error(error) });
  }

  onMarkDocRev(u: paymentModel) {
    if (confirm("Are you sure to Mark Reviewed")) {
      if (this.searchreviewed == "2") this.search.reviewed = null;
      if (this.searchreviewed == "1") this.search.reviewed = true;
      if (this.searchreviewed == "0") this.search.reviewed = false;
      this.api.markRevPayments(u.documentId, this.search).subscribe(result => {
        this.payments = result;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
    }
  }

  onDocView(i: paymentModel) {
    this.api.downloadBlobFilePost("Download/" + i.documentId, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const fileURL = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
            window.open(fileURL, 'mywindow1');
            break;
        }
      },
      () => {
      }
    );
  }

  onPost(u: paymentModel) {
    this.srcCustomerName = u.customerName;
    this.srcBillNumber = u.billNumber;
    var n = u.createdBy;
    var person = this.persons.filter(function (hero) {
      return hero.name == n;
    });
    if (person.length == 1)
      this.collectionForm.patchValue({
        collectedBy: person[0].personId,
      });
    this.onCollBillSearch1();
  }

  get isAdmin() {
    return this.currentUser && this.currentUser.role === Role.Admin;
  }

  get isUrAmSr() {
    return this.currentUser && (this.currentUser.role === Role.Employee ||
      this.currentUser.role === Role.Admin || this.currentUser.role === Role.Sales);
  }

  get getProfileId() {
    return parseInt(this.currentUser.profileId);
  }

  get isSalePerson() {
    return this.currentUser && this.currentUser.role === Role.Sales;
  }

  ngOnInit(): void {
    this.collectionForm = this.formBuilder.group({
      mode: ['', Validators.required],
      remarks: [''],
      collectedBy: ['', Validators.required],
      chequeDate: ['', conditionalValidator(() => this.collectionForm.get('mode').value == 'Cheque',
        Validators.required)],
      chequeNumber: ['', conditionalValidator(() => this.collectionForm.get('mode').value == 'Cheque',
        Validators.required)],
    });
    this.collectionForm.get('mode').valueChanges.subscribe(value => {
      this.isModeCheque = (value == 'Cheque');
      this.collectionForm.get('chequeDate').updateValueAndValidity();
      this.collectionForm.get('chequeNumber').updateValueAndValidity();
    });
  }

  selectedBills: PendingBills[] = [];
  ismutiCus: boolean = false;
  onCollSubmit1() {
    let map = new Map<string, number>();
    var da: string[] = [];
    var d: string[] = [];
    var isTotalError = false;
    this.ismutiCus = false;
    this.selectedBills = [];
    this.bills.forEach(x => {
      if (x.damage > 0) {
        if (x.damageIrNumber) {
          if (this.billNums.includes(x.damageIrNumber)) {
            x.isnotvalidir = false;
            if (map.has(x.damageIrNumber)) {
              var v = map.get(x.damageIrNumber);
              map.delete(x.damageIrNumber)
              map.set(x.damageIrNumber, v + x.damage);
            } else {
              map.set(x.damageIrNumber, x.damage);
            }
          } else {
            x.isnotvalidir = true;
          }
        } else {
          x.isnotvalidir = true;
        }
      } else {
        x.isnotvalidir = false;
        x.damageIrNumber = ""
      }
      if (x.billValue1 > 0 || x.damage > 0 || x.discount > 0 || x.shortage > 0 || x.displayDiscount > 0) {
        d.push(x.customerId + x.companyName);
        this.selectedBills.push(x);
        if (!isTotalError)
          isTotalError = x.billValue1 + x.discount + x.displayDiscount + x.damage + x.shortage - x.balance > 0;
      }
    });
    if (d.length == 0) {
      return da;
    }
    if (d.map(item => item).filter((value, index, self) => self.indexOf(value) === index).length > 1) {
      da.push("Please Select Same Customer and company Bills");
      this.ismutiCus = true;
      return da;
    }
    da.push('Customer : ' + this.selectedBills[0].customerName + "  Company : " + this.selectedBills[0].companyName);

    if (isTotalError) {
      da.push("Invalid Total:Collection>BillValue");
      return da;
    }
    var b1 = 0;
    var b2 = 0;
    var b3 = 0;
    var b4 = 0;
    var b5 = 0;
    var b6 = 0;
    this.bills.forEach(x => {
      b1 = b1 + x.billValue1;
      b2 = b2 + x.damage;
      b3 = b3 + x.discount;
      b4 = b4 + x.shortage;
      b6 = b6 + x.displayDiscount;
      if (x.billValue1 > 0 || x.damage > 0 || x.discount > 0 || x.displayDiscount > 0 || x.shortage > 0) {
        var ir = "";
        if (x.damage > 0) {
          if (x.damageIrNumber && !x.isnotvalidir) {
            ir = "IR-" + x.damageIrNumber;
          } else {
            ir = "IR-Missing";
          }
        }
        var dd = "";
        if (x.displayDiscount > 0) {
          if (x.claimDocCount > 0) {
            dd = "" + x.claimDocCount;
          } else {
            dd = "Missing";
          }
        }
        da.push("Bill : " + x.billNumber + ";  Mode : " + x.billValue1 + ";  Discount : " + x.discount + "; Claim Discount : " + x.displayDiscount + "; Docs : " + dd + ";  Shortage : " + x.shortage + ";  Damage : " + x.damage + "; " + ir);
      }
    });
    b5 = b1 + b2 + b3 + b4 + b6;
    da.push("Total : " + b5 + ";  Mode : " + b1 + ";  Discount : " + b3 + ";  Claim Discount : " + b6 + ";  Shortage : " + b4 + ";  Damage : " + b2);
    for (const [key, value] of map) {
      da.push('IR-' + key + ' = ' + value);
    }
    return da;
  }

  onCollSubmit() {
    this.submitted = true;
    if (this.collectionForm.invalid) return;
    var d: number[] = [];
    var isTotalError = false;
    var isdamageNumMiss = false;
    var isclaimDocMising = false;
    this.bills.forEach(x => {
      if (x.damage > 0) {
        if (x.damageIrNumber && !x.isnotvalidir) {
          x.damageIrNumber = x.damageIrNumber.toString()
        } else {
          isdamageNumMiss = true;
        }
      } else {
        x.damageIrNumber = ""
      }
      if (x.displayDiscount > 0) {
        if (x.claimDocCount > 0) {
        } else {
          isclaimDocMising = true;
        }
      }
      if (x.billValue1 > 0 || x.damage > 0 || x.discount > 0 || x.displayDiscount > 0 || x.shortage > 0) {
        d.push(x.customerId);
        if (!isTotalError)
          isTotalError = x.billValue1 + x.discount + x.displayDiscount + x.damage + x.shortage - x.balance > 0;
      }
    });
    if (isclaimDocMising || isdamageNumMiss || isTotalError || d.length == 0 || d.map(item => item).filter((value, index, self) => self.indexOf(value) === index).length > 1) return;
    this.collectionlModel.entryDate = new Date();
    this.collectionlModel.collectionValue = 0;
    this.collectionlModel.mode = this.f.mode.value;
    var pid = parseInt(this.f.collectedBy.value);
    var personName = this.persons.filter(function (hero) {
      return hero.personId == pid;
    })[0].name;
    this.collectionlModel.collectedBy = personName;
    this.collectionlModel.personId = pid;
    this.collectionlModel.remarks = this.f.remarks.value;
    if (this.collectionlModel.mode == 'Cheque') {
      this.collectionlModel.chequeNumber = this.f.chequeNumber.value;
      this.collectionlModel.chequeDate = this.f.chequeDate.value;
      this.collectionlModel.isChequeCredited = false;
    }
    this.loading = true;
    this.api.onAddCollections(this.collectionlModel, this.bills).subscribe(result => {
      this.submitted = false;
      this.loading = false;
      this.collectionlModel = new CollectionModel();
      this.onCollBillSearch1();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  getCollPersons() {
    this.api.getCollPersons().subscribe(result => {
      this.persons = result;
      var pid = this.getProfileId;
      if (!this.isUrAmSr) {
        var personName = result.filter(function (hero) {
          return hero.personId == pid;
        });
        this.persons = personName;
      }
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onCollBillSearch1() {
    var b = 0;
    if (!this.srcBillValue) b = 0; else
      b = parseInt(this.srcBillValue.toString())
    this.api.getCustomerSearch(this.srcCustomerName, this.srcBillNumber, b, this.srcCompany).subscribe(result => {
      this.bills = result;
      this.bills.forEach(x => {
        x.billValue1 = 0;
        x.discount = 0;
        x.displayDiscount = 0;
        x.claimDocCount = 0;
        x.shortage = 0;
        x.damage = 0;
        x.damageIrNumber = ""
      });
      this.prevFetch = "";
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  prevFetch: string = "";
  billNums: string[] = [];

  changeFn(u: PendingBills) {
    if (this.prevFetch == "") {
      console.log('Intial Fetching...');
      this.getDa();
    } else {
      if (this.ismutiCus) {
        console.log('MultiCust No Fetching...');
      } else {
        if (this.selectedBills.length > 0)
          if (this.prevFetch != this.selectedBills[0].companyName) {
            console.log('Fetching');
            this.getDa();
          } else {
            console.log('Previous Fetch same...' + this.prevFetch);
          }
      }
    }
  }
  getDa() {
    this.billNums = [];
    console.log('Fetching...' + this.selectedBills[0].companyName);
    this.api.getDamageSequence(this.selectedBills[0].companyName).subscribe(result => {
      this.billNums = result;
      this.prevFetch = this.selectedBills[0].companyName;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  @ViewChild('inputFile2') inputFile2: ElementRef;

  upload1(event, u: PendingBills) {
    this.error = "";
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          var imageSrc = reader.result as string;
          this.imageCompress.compressFile(imageSrc, 50, 100).then((compressedImage) => {
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            this.api.uploadToBlob(doc.output('blob'), "UploadBill/" + u.billId + "/" + u.billNumber + "/Claim").subscribe(
              data => {
                if (data) {
                  switch (data.type) {
                    case HttpEventType.UploadProgress:
                      break;
                    case HttpEventType.Response:
                      this.inputFile2.nativeElement.value = '';
                      this.api.getDcouments(u.billId, "Claim")
                        .subscribe(result => {
                          u.claimDocCount = result.length;
                        }, error => {
                          this.error = error;
                          this.loading = false; console.error(error)
                        });
                      break;
                  }
                }
              },
              error => {
                this.inputFile2.nativeElement.value = '';
              }
            );
          });
        }
        else {
          if (file.type.startsWith("application/pdf")) {
            this.api.uploadToBlob(file, "UploadBill/" + u.billId + "/" + u.billNumber + "/Claim").subscribe(
              data => {
                if (data) {
                  switch (data.type) {
                    case HttpEventType.UploadProgress:
                      break;
                    case HttpEventType.Response:
                      this.inputFile2.nativeElement.value = '';
                      this.api.getDcouments(u.billId, "Claim")
                        .subscribe(result => {
                          u.claimDocCount = result.length;
                        }, error => {
                          this.error = error;
                          this.loading = false; console.error(error)
                        });
                      break;
                  }
                }
              },
              error => {
                this.inputFile2.nativeElement.value = '';
              }
            );
          } else {
            this.error = "Invalid File format images/PDF only"
            return;
          }
        }
      };
    }
  }

  getTheColor(status: string) {
    if (status.includes("Missing")) {
      return "yellow";
    } else {
      return "white";
    }
  }

}
