<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-md-2">
                <label for="accBalance">Account</label>
                <select class="form-control" [(ngModel)]="search.accountId" (change)="selectChangeHandler3($event)">
                    <option *ngFor="let p of persons" [value]="p.accountId">{{p.accountName}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="source">Source</label>
                <select class="form-control" [(ngModel)]="search.source">
                    <option value="" selected>All</option>
                    <option value="Source1" selected>Source1-Rayar</option>
                    <option value="Source2">Source2-WhiteHorse</option>
                    <option value="Source3">Source3-Nestling</option>
                    <option value="Source4">Source4-Sunrise</option>
                    <option value="Source5">Source5</option>
                </select>
            </div>
            <div class="col-md-2">
                <label>Description</label>
                <input type="text" [(ngModel)]="search.description" class="form-control" />
            </div>
            <div class="col-md-2">
                <label>BillNumber</label>
                <input type="text" [(ngModel)]="search.remarks" class="form-control" />
            </div>
            <div class="col-md-2">
                <label></label><br>
                <button class="btn btn-sm btn-info" (click)="onSearch(0)">Search</button>
            </div>
        </div>
    </div>
</div>
<br>
<div class="row">
    <div class="col">
        <nav aria-label="Page navigation">
            <ul class="pagination">
                <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a></li>
                <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                <li class="page-item"><span class="page-link">Page: {{search.page+1}}</span></li>
            </ul>
        </nav>
    </div>
</div>
<div class="row">
    <div class="col">
        <table class="table table-striped table-sm table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col"></th>
                    <th scope="col">LastUpdatedBy</th>
                    <th scope="col">Remarks</th>
                    <th scope="col">TransactionAmount</th>
                    <th scope="col">Description</th>
                    <th scope="col">CrDr</th>
                    <th scope="col">TransPostedDate</th>
                    <th scope="col">ValueDate</th>
                    <th scope="col">TransactionId</th>
                    <th scope="col">Source</th>
                    <th scope="col">AvailableBalance</th>
                </tr>
            </thead>
            <tbody *ngFor="let u of bills">
                <tr>
                    <th scope="row">
                        <button class="btn btn-sm btn-info" (click)="showDetail(u)">
                            Collections
                        </button>
                    </th>
                    <td>{{u.lastUpdatedBy}}</td>
                    <td class="elip">{{u.remarks}}</td>
                    <td>{{u.transactionAmount}}</td>
                    <td class="elip">{{u.description}}</td>
                    <td>{{u.crDr}}</td>
                    <td>{{u.transPostedDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.valueDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.transactionId}}</td>
                    <td>{{getSource(u.source)}}</td>
                    <td>{{u.availableBalance}}</td>
                </tr>
                <tr *ngIf="u.showDetail">
                    <td colspan="15">
                        <table class="table table-striped table-sm table-responsive table-condensed">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Bill#</th>
                                    <th scope="col">BillValue</th>
                                    <th scope="col">BillDate</th>
                                    <th scope="col">BillStatus</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Mode</th>
                                    <th scope="col">CollectionValue</th>
                                    <th scope="col">CreatedBy</th>
                                    <th scope="col">CreatedDate</th>
                                    <th scope="col">BankDescri</th>
                                    <th scope="col">BankAmount</th>
                                    <th scope="col">CrDr</th>
                                    <th scope="col">TransPostedDate</th>
                                    <th scope="col">Remarks</th>
                                    <th scope="col">Source</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let u1 of collsmt">
                                    <td>{{u1.billNumber}}</td>
                                    <td>{{u1.billValue}}</td>
                                    <td>{{u1.billDate| date: 'dd/MM/yyyy'}}</td>
                                    <td>{{u1.billStatus}}</td>
                                    <td>{{u1.companyName}}</td>
                                    <td>{{u1.customerName}}</td>
                                    <td>{{u1.mode}}</td>
                                    <td>{{u1.collectionValue}}</td>
                                    <td>{{u1.createdBy}}</td>
                                    <td>{{u1.createdDate| date: 'dd/MM/yyyy'}}</td>
                                    <td>{{u1.description}}</td>
                                    <td>{{u1.transactionAmount}}</td>
                                    <td>{{u1.crDr}}</td>
                                    <td>{{u1.transPostedDate| date: 'dd/MM/yyyy'}}</td>
                                    <td>{{u1.remarks}}</td>
                                    <td>{{getSource(u1.source)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>