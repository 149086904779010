import { Component, OnInit } from '@angular/core';
import { BillModel, CollectionModel, CollectonPersonModel, User, Role, companyModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';
import { HttpEventType } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-viewcollection',
  templateUrl: './viewcollection.component.html',
  styleUrls: ['./viewcollection.component.css']
})
export class ViewcollectionComponent implements OnInit {
  loading = false;
  colls: CollectionModel[] = [];
  error = '';
  searchBillModel: BillModel = new BillModel();
  selectedPersonId: number;
  companys: companyModel[];

  constructor(private authenticationService: AuthenticationService, private api: RayarApiService) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.selectedPersonId = 0;
    this.searchBillModel.companyName = "";
    this.searchBillModel.billStatus = "";
    this.searchBillModel.personId = 0;
    this.getCollPersons();
    this.getcompany();
  }

  ngOnInit() {
  }

  getcompany() {
    this.api.getCompanys().subscribe(result => {
      this.companys = result;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  downloadExpenseReport() {
    this.searchBillModel.page = 0;
    this.download("CollsDownload/ViewCollsAll", this.searchBillModel)
  }

  public download(q: string, b: any) {
    this.api.downloadFilePost(q, b).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }

  onCollBillSearch() {
    this.searchBillModel.page = 0;
    this.api.oncollsView('ViewCollsAll', this.searchBillModel).subscribe(result => { this.colls = result; }, error => {
      this.error = error;
      this.loading = false;
      console.error(error);
    });
  }

  selectedBill: BillModel = new BillModel();

  onCollBillSearchReset() {
    this.searchBillModel = new BillModel();
    this.colls = [];
  }


  selectChangeHandler(event: any) {
    this.searchBillModel.fromDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.searchBillModel.toDate = event.target.value;
  }

  selectChangeHandler2(event: any) {
    var personId = parseInt(event.target.value)
    this.searchBillModel.personId = personId;
  }

  selectChangeHandler3(event: any) {
    this.searchBillModel.billStatus = event.target.value;
  }

  persons1: CollectonPersonModel[] = [];
  currentUser: User;

  get getProfileId() {
    return parseInt(this.currentUser.profileId);
  }
  get isUrAmSr() {
    return this.currentUser && (this.currentUser.role === Role.Employee ||
      this.currentUser.role === Role.Admin || this.currentUser.role === Role.Sales);
  }

  getCollPersons() {
    this.api.getCollPersons().subscribe(result => {
      this.persons1 = result;
      var pid = this.getProfileId;
      if (!this.isUrAmSr) {
        var personName = result.filter(function (hero) {
          return hero.personId == pid;
        });
        this.persons1 = personName;
      }
    },
      error => {
        this.error = error; this.loading = false; console.error(error)
      });
  }

}
