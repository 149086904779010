import { Component, OnInit } from '@angular/core';
import { BankStmtModel, grnEmailModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-bankstmtemail',
  templateUrl: './bankstmtemail.component.html',
  styleUrls: ['./bankstmtemail.component.css']
})
export class BankstmtemailComponent implements OnInit {
  loading = false;
  error = '';
  bks: BankStmtModel[] = [];
  source: string = "Source1";

  constructor(private api: RayarApiService) { }

  ngOnInit(): void {
  }

  onSearch() {
    this.api.getemailBks(this.source).subscribe(result => {
      this.bks = result;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onPaymentRequest() {
    var sels = this.bks.filter(function (hero) {
      return hero.selected;
    });
    if (sels.length <= 0) return;
    this.api.MtPaymentEmail(sels).subscribe(result => {
      this.error = 'PaymentAdvice EMail Success';
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

}
