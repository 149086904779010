<div class="row">
  <div class="col">
    <div class="form-row">
      <div class="col-md-2">
        <label>Name</label>
        <input class="form-control" type="text" [(ngModel)]="searchCustomerName" />
      </div>
      <div class="col-md-2">
        <label></label>
        <div>
          <button class="btn btn-sm btn-info" (click)="onNameCustomerSearch()">Search</button>&nbsp;
          <button class="btn btn-sm btn-info" (click)="onCustSearchReset()">Reset</button>
        </div>
      </div>
      <div class="col-md-2">
        <label></label>
        <div>
          <button class="btn btn-sm btn-info" (click)="oAddCustomer()">Add</button>
        </div>
      </div>
    </div>
    <br>
    <table class="table table-striped table-sm table-responsive table-condensed">
      <thead class="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Employee</th>
          <th scope="col">Manager</th>
          <th scope="col">Name</th>
          <th scope="col">Contact Phone</th>
          <th scope="col">Email1</th>
          <th scope="col">Email2</th>
          <th scope="col" *DataCustomAcl="'Admin'">UserName</th>
          <th scope="col">Companys</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isAddCustomerClicked">
          <td colspan="9">
            <form [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
              <div class="form-row">
                <div class="col-md-2">
                  <label for="name">Name</label>
                  <input type="text" formControlName="name" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                  <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">Name is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="contactPhone">Contact Phone</label>
                  <input type="text" formControlName="contactPhone" class="form-control" maxlength="10"
                    [ngClass]="{ 'is-invalid': submitted && f.contactPhone.errors }" />
                  <div *ngIf="submitted && f.contactPhone.errors" class="invalid-feedback">
                    <div *ngIf="f.contactPhone.errors.required">Contact Phone is required</div>
                  </div>
                </div>
                <div class="w-100"></div>
                <div class="col-md-2">
                  <label for="emailId1">EMailId1</label>
                  <input type="text" formControlName="emailId1" class="form-control" maxlength="200"
                    [ngClass]="{ 'is-invalid': submitted && f.emailId1.errors }" />
                  <div *ngIf="submitted && f.emailId1.errors" class="invalid-feedback">
                    <div *ngIf="f.emailId1.errors.required">EMailId1 is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="emailId2">EMailId2</label>
                  <input type="text" formControlName="emailId2" class="form-control" maxlength="200"
                    [ngClass]="{ 'is-invalid': submitted && f.emailId2.errors }" />
                  <div *ngIf="submitted && f.emailId2.errors" class="invalid-feedback">
                    <div *ngIf="f.emailId2.errors.required">EMailId2 is required</div>
                  </div>
                </div>
                <div class="w-100"></div>
                <div class="col-md-2">
                  <label for="managerId">Manager</label>
                  <select formControlName="managerId" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.managerId.errors }">
                    <option value="0">Sales</option>
                    <option *ngFor="let p of customers" [value]="p.personId">{{p.name}}</option>
                  </select>
                  <div *ngIf="submitted && f.managerId.errors" class="invalid-feedback">
                    <div *ngIf="f.managerId.errors.required">Manager is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="appUserId">Employee</label>
                  <select formControlName="appUserId" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.appUserId.errors }">
                    <option value="0">NoEmp</option>
                    <option *ngFor="let p of appusers" [value]="p.id">{{p.userName}}</option>
                  </select>
                  <div *ngIf="submitted && f.appUserId.errors" class="invalid-feedback">
                    <div *ngIf="f.appUserId.errors.required">Employee is required</div>
                  </div>
                </div>
              </div>
              <button type="submit" [disabled]="loading" class="btn btn-sm btn-info">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Save
              </button>
              <button class="btn btn-sm btn-info" (click)="onCloseAddCust()">close</button>
            </form>
          </td>
        </tr>
      </tbody>
      <tbody *ngFor="let u of customers">
        <tr>
          <td>
            <button class="btn btn-sm btn-info" (click)="onEditCustomer(u)">Edit</button>
          </td>
          <td>{{getEmployeeName(u.appUserId)}}</td>
          <td>{{getManagerName(u.managerId)}}</td>
          <td>{{u.name}}</td>
          <td>{{u.contactPhone}}</td>
          <td>{{u.emailId1}}</td>
          <td>{{u.emailId2}}</td>
          <td *DataCustomAcl="'Admin'">
            <button *ngIf="u.userName=='zzzzz'" class="btn btn-sm btn-info" (click)="onCreateSalesLogin(u)">Create
              Login</button>
            <div *ngIf="u.userName !='zzzzz'"> {{u.userName}}</div>
          </td>
          <td>{{u.companys}}</td>
        </tr>
        <tr *ngIf="u.showDetail">
          <td colspan="8">
            <form [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
              <div class="form-row">
                <div class="col-md-2">
                  <label for="name">Name</label>
                  <input type="text" formControlName="name" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                  <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">Name is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="contactPhone">Contact Phone</label>
                  <input type="text" formControlName="contactPhone" class="form-control" maxlength="10"
                    [ngClass]="{ 'is-invalid': submitted && f.contactPhone.errors }" />
                  <div *ngIf="submitted && f.contactPhone.errors" class="invalid-feedback">
                    <div *ngIf="f.contactPhone.errors.required">Contact Phone is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="emailId1">EMailId1</label>
                  <input type="text" formControlName="emailId1" class="form-control" maxlength="200"
                    [ngClass]="{ 'is-invalid': submitted && f.emailId1.errors }" />
                  <div *ngIf="submitted && f.emailId1.errors" class="invalid-feedback">
                    <div *ngIf="f.emailId1.errors.required">EMailId1 is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="emailId2">EMailId2</label>
                  <input type="text" formControlName="emailId2" class="form-control" maxlength="200"
                    [ngClass]="{ 'is-invalid': submitted && f.emailId2.errors }" />
                  <div *ngIf="submitted && f.emailId2.errors" class="invalid-feedback">
                    <div *ngIf="f.emailId2.errors.required">EMailId2 is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="managerId">Manager</label>
                  <select formControlName="managerId" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.managerId.errors }">
                    <option value="0">Sales</option>
                    <option *ngFor="let p of customers" [value]="p.personId">{{p.name}}</option>
                  </select>
                  <div *ngIf="submitted && f.managerId.errors" class="invalid-feedback">
                    <div *ngIf="f.managerId.errors.required">Manager is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="appUserId">Employee</label>
                  <select formControlName="appUserId" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.appUserId.errors }">
                    <option value="0">NoEmp</option>
                    <option *ngFor="let p of appusers" [value]="p.id">{{p.userName}}</option>
                  </select>
                  <div *ngIf="submitted && f.appUserId.errors" class="invalid-feedback">
                    <div *ngIf="f.appUserId.errors.required">Employee is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label></label><br>
                  <button type="submit" [disabled]="loading" class="btn btn-sm btn-info">
                    Save
                  </button>
                </div>
              </div>
            </form>
            <br />
            <table>
              <thead>
                <th></th>
                <th>Company</th>
                <th>AliasInv</th>
                <th>AliasSales</th>
              </thead>
              <tbody>
                <tr *ngFor="let u1 of companys">
                  <td><input type="checkbox" [value]="u1.companyName" type="checkbox" [(ngModel)]="u1.showDetail"
                      (change)="onCheckboxChange1($event)"> </td>
                  <td>{{u1.companyName}}</td>
                  <td>{{u1.rCompanyName}}</td>
                  <td>{{u1.sCompanyName}}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>