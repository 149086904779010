<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-lg-1 col-md-2">
                <div>
                    <label>Bill</label>
                    <input type="text" class="form-control" (keyup)="onKey1($event)" [(ngModel)]="selectedtext1" />
                </div>
            </div>
            <div class="col-lg-1 col-md-2">
                <label for="companyName">Company</label>
                <select class="form-control" (change)="selectChangeHandlerCompany($event)">
                    <option value="-1" selected>All</option>
                    <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
                </select>
            </div>

            <div class="col-lg-3 col-md-4">
                <label>Supplied</label><Br>
                <input type="radio" name="revRadio1" value="2" [(ngModel)]="selectedSupplied"
                    (change)="selectChangeHandlerSupplied($event)" />
                All
                <input type="radio" name="revRadio1" value="0" [(ngModel)]="selectedSupplied"
                    (change)="selectChangeHandlerSupplied($event)" />
                Not Supplied
                <input type="radio" name="revRadio1" value="1" [(ngModel)]="selectedSupplied"
                    (change)="selectChangeHandlerSupplied($event)" />
                Supplied
            </div>
        </div>
        <br>
        <div class="form-row">
            <div class="col-md-12">
                <div *ngIf="showAlert1" class="alert alert-danger mt-3 mb-0"> 2 or More Pending Bills!!
                </div>
                Pending Bills
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Company</th>
                            <th scope="col">Bill#</th>
                            <th scope="col">Bill Date</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Collected</th>
                            <th scope="col">Balance</th>
                            <th scope="col">Assigned</th>
                        </tr>
                    <tbody>
                        <tr *ngFor="let u of pendingBills" [class.table-danger]="u.companyName==this.selectedCompany">
                            <td>{{u.companyName}}</td>
                            <td>{{u.billNumber}}</td>
                            <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
                            <td>{{u.billValue }}</td>
                            <td>{{u.colVal }}</td>
                            <td>{{u.balance }}</td>
                            <td>{{u.personName }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-12">
                Pending Cheque Clerance
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Cheque#</th>
                            <th scope="col">Cheque Date</th>
                            <th scope="col">Collected</th>
                            <th scope="col">Remarks</th>
                            <th scope="col">Date</th>
                            <th scope="col">Mode</th>
                            <th scope="col">CollectedBy</th>
                            <th scope="col">Bill Date</th>
                            <th scope="col">Bill#</th>
                            <th scope="col">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let u of colls">
                            <td>{{u.chequeNumber}}</td>
                            <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
                            <td>{{u.collectionValue}}</td>
                            <td>{{u.remarks}}</td>
                            <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                            <td>{{u.mode}}</td>
                            <td>{{u.collectedBy}}</td>
                            <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                            <td>{{u.billNumber}}</td>
                            <td>{{u.billValue}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-12">
                Bounced Checks
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Bill Number</th>
                            <th scope="col">Check Details</th>
                        </tr>
                    <tbody>
                        <tr *ngFor="let u of pendingFollowups">
                            <td>{{u.billNumber}}</td>
                            <td>{{u.bouncedCheckDetail }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <nav aria-label="...">
            <ul class="pagination">
                <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a></li>
                <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                <li class="page-item">
                    <span class="page-link">Page: {{searchBillModel.page+1}}</span>
                </li>
            </ul>
        </nav>
        <input name="file" id="file" (change)="upload($event)" type="file" #inputFile hidden>
        <input name="filePO" id="filePO" (change)="uploadPO($event)" type="file" #inputFilePO hidden>
        <div class="row">
            <div class="col-md-2">
                <div class="input-group mb-3">
                    <select class="form-control" [(ngModel)]="selSource">
                        <option value="" selected disabled>Choose</option>
                        <option *ngFor="let p of companys" [value]="p.companyName">
                            {{p.companyName}}</option>
                    </select>
                </div>
            </div>
            <div class="col">
                <Label>&nbsp;</Label>
                <button class="btn btn-info" (click)="onAddNewBill()">Add New Bill</button> &nbsp;
            </div>
        </div>
        <div class="form-row">
            <div *ngIf="isAddNewBillClicked && !isEditBill && !isEditBill1" class="col-md-12">
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col"><input type="text" [(ngModel)]="srcPrds"></th>
                            <th scope="col">MRP</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let u2 of getProductsFilter()">
                        <tr [class.bg-success]="color(u2)">
                            <td>{{u2.productName}}</td>
                            <td>{{u2.mrp}}</td>
                            <td><i class="fa fa-plus" ></i></td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Product</th>
                            <th scope="col">Price</th>
                            <th scope="col"></th>
                            <th scope="col">Quantity</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>Total</td>
                            <td>{{ getTotal() }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    <tbody *ngFor="let u1 of getBillProductsFilter()">
                        <tr>
                            <td>
                                <i class="fa fa-times" (click)="onSub1(u1)"></i>
                            </td>
                            <td>{{u1.productName}}</td>
                            <td>{{u1.disPrice}}</td>
                            <td>
                                <i class="fa fa-minus"></i>
                            </td>
                            <td class="fixw"><input type="number" [(ngModel)]="u1.quantity" min="1"
                                    class="form-control form-control-sm">
                            </td>
                            <td><i class="fa fa-plus" ></i></td>
                        </tr>
                    </tbody>
                </table>
                <form [formGroup]="billAddEditForm" (ngSubmit)="onBillSubmit()">
                    <div class="form-row">
                        <div class="col-md-3">
                            <label for="billRemarks">Remarks</label>
                            <input type="text" formControlName="billRemarks" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.billRemarks.errors }" />
                        </div>
                        <div class="col-md-3">
                            <label>&nbsp;</label><br>
                            <button type="submit" [disabled]="loading" class="btn btn-sm btn-info">
                                Save
                            </button>
                        </div>
                        <div class="col-md-3">
                            <button class="btn btn-sm btn-info" (click)="onBillCancel()">Close</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-12">
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Bill</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Date</th>
                            <th scope="col">Company</th>
                            <th scope="col">Stock</th>
                            <th scope="col" style="width: 150px;">Remarks</th>
                            <th scope="col">Driver</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>

                    <tbody *ngFor="let u of bills">
                        <tr [class.table-danger]="u.inStock=='0'">
                            <td>{{u.billNumber}}</td>
                            <td>{{u.billValue }}</td>
                            <td [class.bg-danger]="u.saleOrder">{{u.billDate | date: 'dd/MM/yyyy'}}</td>
                            <td>{{u.companyName}}</td>
                            <td>{{u.inStock=='1'?'InStock':'OutStock'}}</td>
                            <td>
                                <input type="text" [(ngModel)]="u.billRemarks" (blur)="onBlurEvent(u)"
                                    class="form-control" />
                            </td>
                            <td>
                                <button type="button" class="btn btn-sm btn-info btn-sm"
                                    (click)="editBillG(u)">Edit</button>&nbsp;
                            </td>
                        </tr>
                        <tr *ngIf="u.showDetail">
                            <td colspan="25">
                                <div class="form-row">
                                    <div class="col-md-3">
                                        <button class="btn btn-sm btn-info" [disabled]="suppliedDocs.length<=0"
                                            (click)="markSupplied(billModel.billId)">
                                            Mark Supplied
                                        </button>&nbsp;
                                        <button class="btn btn-sm btn-info" (click)="inputFile.click()">Select
                                            Image/PDF</button>&nbsp;
                                        <button class="btn btn-sm btn-success" *ngIf="fileType" (click)="onUpload()">
                                            <span>Upload Selected {{fileType }}</span>
                                        </button>
                                        <br>
                                        <img [src]="imageSrc" *ngIf="imageSrc" style="width: 100%;height: auto;">
                                    </div>
                                    <!--<div class="col-md-3"><iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe"></iframe></div>-->
                                    <div class="col-md-6">
                                        <table class="table table-striped table-sm table-responsive table-condensed">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Document Name</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Upload Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let u of suppliedDocs">
                                                    <th scope="row">
                                                        <button class="btn btn-sm btn-info"
                                                            (click)="onDownloadDocument(u.documentId, u.documentName)">
                                                            Download
                                                        </button>&nbsp;
                                                        <button class="btn btn-sm btn-danger"
                                                            (click)="onDeleteDocument(u.documentId)">Delete</button>
                                                    </th>
                                                    <td>{{u.documentName}}</td>
                                                    <td>{{u.documentType}}</td>
                                                    <td>{{u.uploadDate| date: 'dd/MM/yyyy'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="u.showDetail1">
                            <td colspan="25">
                                <div *ngIf="isEditBill1">
                                    <form [formGroup]="billAddEditForm11" (ngSubmit)="onBillSubmit11()">
                                        <div class="form-row">
                                            <div class="col-md-2">
                                                <label for="inStock">InStock</label>
                                                <select formControlName="inStock" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f11.inStock.errors }">
                                                    <option value="" selected disabled>Choose</option>
                                                    <option value="1">In Stock</option>
                                                    <option value="0">No Stock</option>
                                                </select>
                                                <div *ngIf="submitted && f11.inStock.errors" class="invalid-feedback">
                                                    <div *ngIf="f11.inStock.errors.required">InStock is required</div>
                                                </div>
                                            </div>
                                            <div class="col-md-2" [hidden]="!(isInStock && !isServiceCompany)">
                                                <label for="billNumber">Bill Number</label>
                                                <select formControlName="billNumber" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f11.billNumber.errors }">
                                                    <option value="" selected disabled>Choose</option>
                                                    <option *ngFor="let p of billNums" [value]="p">{{p}}</option>
                                                </select>
                                                <div *ngIf="submitted && f11.billNumber.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f11.billNumber.errors.required">Bill Number is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2" [hidden]="!(isInStock && isServiceCompany)">
                                                <label for="billNumber1">Bill Number</label>
                                                <input type="number" formControlName="billNumber1" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f11.billNumber1.errors }" />
                                                <div *ngIf="submitted && f11.billNumber1.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f11.billNumber1.errors.required">Bill Number is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="billValue">Bill Value</label>
                                                <input type="number" formControlName="billValue" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f11.billValue.errors }" />
                                                <div *ngIf="submitted && f11.billValue.errors" class="invalid-feedback">
                                                    <div *ngIf="f11.billValue.errors.required">Bill Value is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="margin">Margin({{selectedMargin}})</label>
                                                <input type="text" formControlName="margin" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f11.margin.errors }" />
                                                <div *ngIf="submitted && f11.margin.errors" class="invalid-feedback">
                                                    <div *ngIf="f11.margin.errors.required">Margin is required</div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="billDate">Bill Date</label>
                                                <input type="date" formControlName="billDate" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f11.billDate.errors }" />
                                                <div *ngIf="submitted && f11.billDate.errors" class="invalid-feedback">
                                                    <div *ngIf="f11.billDate.errors.required">Bill Date is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="billRemarks">Remarks</label>
                                                <input type="text" formControlName="billRemarks" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f.billRemarks.errors }" />
                                            </div>
                                            <div class="col-md-2">
                                                <label>&nbsp;</label><br>
                                                <button type="submit" [disabled]="loading" class="btn btn-sm btn-info">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div *ngIf="isEditBill">
                                    <form [formGroup]="billAddEditForm1" (ngSubmit)="onBillSubmit1()">
                                        <div class="form-row">
                                            <div class="col-md-2">
                                                <label for="billValue">Bill Value</label>
                                                <input type="number" formControlName="billValue" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f1.billValue.errors }" />
                                                <div *ngIf="submitted && f1.billValue.errors" class="invalid-feedback">
                                                    <div *ngIf="f1.billValue.errors.required">Bill Value is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="margin">Margin({{selectedMargin}})</label>
                                                <input type="text" formControlName="margin" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f.margin.errors }" />
                                                <div *ngIf="submitted && f.margin.errors" class="invalid-feedback">
                                                    <div *ngIf="f.margin.errors.required">Margin is required</div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="billDate">Bill Date</label>
                                                <input type="date" formControlName="billDate" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f1.billDate.errors }" />
                                                <div *ngIf="submitted && f1.billDate.errors" class="invalid-feedback">
                                                    <div *ngIf="f1.billDate.errors.required">Bill Date is required</div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="billRemarks">Remarks</label>
                                                <input type="text" formControlName="billRemarks" class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && f.billRemarks.errors }" />
                                            </div>
                                            <div class="col-md-2">
                                                <label>&nbsp;</label>&nbsp;<br>
                                                <button type="submit" [disabled]="loading" class="btn btn-sm btn-info">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="form-row">
                                    <div class="col-md-3" *ngIf="urlSafe">
                                        <embed [src]="urlSafe" width="100%" height="100%" />
                                    </div>
                                    <div class="col-md-6">
                                        <table class="table table-striped table-sm table-responsive table-condensed">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Upload Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let u of suppliedDocs1">
                                                    <td>
                                                        <button class="btn btn-sm btn-info"
                                                            (click)="onDownloadDocument1(u.documentId, u.documentName)">View</button>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-sm btn-info"
                                                            (click)="onDownloadDocument(u.documentId, u.documentName)">
                                                            Download
                                                        </button>
                                                    </td>
                                                    <td>{{u.documentType}}</td>
                                                    <td>{{u.uploadDate| date: 'dd/MM/yy hh:mm a'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</div>