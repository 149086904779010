<div class="card">
    <h4 class="card-header">Paid Bills</h4>
    <div class="card-body">
        <div *ngIf="!isAddCollectionClicked">
            <div class="form-row">
                <div class="col-md-2">
                    <label>Bill Number</label>
                    <input type="text" [(ngModel)]="searchBillModel.billNumber" class="form-control" />
                </div>
                <div class="col-md-2">
                    <label>Customer</label>
                    <input type="text" [(ngModel)]="searchBillModel.customerName" class="form-control" />
                </div>
                <div class="col-md-2">
                    <label>Account</label>
                    <input type="text" [(ngModel)]="searchBillModel.accountName" class="form-control" />
                </div>
                <div class="col-md-2">
                    <label>Person</label>
                    <input type="text" [(ngModel)]="searchBillModel.personName" class="form-control" />
                </div>
                <div class="col-md-2">
                    From
                    <input type="date" [ngModel]="searchBillModel.fromDate | date:'yyyy-MM-dd'"
                        (change)="selectChangeHandler($event)" class="form-control" />
                </div>
                <div class="col-md-2">
                    To<input type="date" [ngModel]="searchBillModel.toDate | date:'yyyy-MM-dd'"
                        (change)="selectChangeHandler1($event)" class="form-control" />
                </div>
                <div class="col-md-2">
                    Bill Value
                    <input type="number" [(ngModel)]="searchBillModel.billValue" class="form-control" />
                </div>
                <div class="col-md-2">
                    <div>
                        <button class="btn btn-outline-primary" (click)="onSearchPerson()">Search</button>
                        <button class="btn btn-outline-primary" (click)="onReset()">Reset</button>
                    </div>
                </div>
            </div>
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Account</th>
                        <th scope="col">Bill Number</th>
                        <th scope="col">Bill Date</th>
                        <th scope="col">Bill Value</th>
                        <th scope="col">Collection Value</th>
                        <th scope="col">Balance</th>
                        <th scope="col">Customer Name</th>
                        <th scope="col">Company</th>
                        <th scope="col">Person</th>
                        <th scope="col">Supplied</th>
                        <th scope="col">Paid</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let u of bills">
                        <th scope="row">
                            <button class="btn btn-outline-primary" (click)="viewColls(u)">Collections</button>
                        </th>
                        <td>{{u.accountName}}</td>
                        <td>{{u.billNumber}}</td>
                        <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.billValue}}</td>
                        <td>{{u.colVal}}</td>
                        <td>{{u.balance}}</td>
                        <td>{{u.customerName}}</td>
                        <td>{{u.companyName}}</td>
                        <td>{{u.personName}}</td>
                        <td>{{u.suppliedDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.paidDate| date: 'dd/MM/yyyy'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="isAddCollectionClicked">
            <button class="btn btn-outline-primary" (click)="onCloseAddColl()">close</button>
            <div class="form-row">
                <div class="col-md-2">Bill Number: {{selectedBill.billNumber}}</div>
                <div class="col-md-2">Bill Date: {{selectedBill.billDate | date: 'dd/MM/yyyy'}}</div>
                <div class="col-md-2">Customer Name: {{selectedBill.customerName}}</div>

                <div class="col-md-2">Collected : {{selectedBill.colVal}}</div>
                <div class="col-md-2">Balance: {{selectedBill.balance}}</div>
                <div class="col-md-2">Billed: {{selectedBill.billValue}}</div>
                <div class="col-md-2">Company: {{selectedBill.companyName}}</div>
            </div>
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Collection Date</th>
                        <th scope="col">Collection Value</th>
                        <th scope="col">Mode</th>
                        <th scope="col">CollectedBy</th>
                        <th scope="col">Remarks</th>
                        <th scope="col">ChequeDate</th>
                        <th scope="col">ChequeNumber</th>
                        <th scope="col">Cheque Credited Date</th>
                        <th scope="col">Bounced</th>
                        <th scope="col">Bounced FollowedUp</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let u of colls">
                        <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.collectionValue}}</td>
                        <td>{{u.mode}}</td>
                        <td>{{u.collectedBy}}</td>
                        <td>{{u.remarks}}</td>
                        <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.chequeNumber}}</td>
                        <td>{{u.creditedDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.bounced}}</td>
                        <td>{{u.bouncedFollowedUp}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>