export class epiprnModel {
  page: number;
  showDetail: boolean;
  epiprnId: number;
  epiType: string;
  customerId?: number;
  billId?: number;
  billNumber: string;
  accountId?: number;
  status: string;
  saleReturnNo: string;
  date: string;
  date1: Date;
  date2: Date;
  paymentId: string;
  deliveryChallanNo: string;
  user: string;
  outlet: string;
  outletId: string;
  billNo: string;
  skunit: string;
  quantity: string;
  unitPrice: string;
  amount?: number;
  batchNo: string;
  mfgDate: string;
  reason: string;
  saleReturnState: string;
  select: string;
  action: string;
}

export class prnReult {
  result: epiprnModel[];
  result1: CustomerModel[];
}

export class comLedger {
  years: number;
  months: number;
  bill: number;
  purchase: number;
  expense: number;
  collections: number;
  inventory: number;

  amount: number;
  runningBalance: number;
  descr: string;
}

export class DocumentTrackModel {
  vehicleId: number;
  documentTrackId: number;
  documentName: string;
  validTillDate: Date
  isEdit: boolean;
  showDetail: boolean;
  page: number;
  documentId: number;
  docName: string;
  category: string;
  vehicleName: string;
  notes: number;
  serviceMileLimit: number;
}

export class VehicleModel {
  vehicleId: number;
  vehicleName: string;
  remarks: string;
  category: string;
  isEdit: boolean;
  page: number;
  showDetail: boolean;
}

export class isales {
  inventorys: SalesLookupModel[];
  inventoryTotal: number;
  inventorySum: SalesLookupModel[];
}

export class iv {
  inventorySum: InventLookupModel[];
  inventorys: InventLookupModel[];
  inventoryTotal: number;
}

export class InventLookupModel {
  page: number;
  inventLookupId: number;
  companyName: string;
  productName: string;
  prodValue1: number;
  prodValue2: number;
  quantity: number;
  createdDate: Date;
}

export class SalesLookupModel {
  page: number;
  salesLookupId: number;
  companyName: string;
  billNumber: string;
  billDate: Date;
  customerName: string;
  productName: string;
  prodValue1: number;
  prodValue2: number;
  prodValue3: number;
  prodValue4: number;
  prodValue5: number;
  prodValue6: number;
  billValue: number;
  quantity: number;
  fromDate?: Date;
  toDate?: Date;
}

export class LoadSheetModel {
  loadSheetDatas: LoadSheetData[]
}

export class LoadSheetData {
  billNumber: string;
  companyName: string;
}

export class accountComOperModel {
  accounts: accountModel[];
  operations: string[];
}

export class accountCompanyModel {
  accountCompanyId: number;
  accountId: number;
  accountName: string;
  companyId: number;
  companyName: string;
  operation: string;
  toEmail: string;
  ccEmail: string;
  vehicleName: string;
}

export class accountModel {
  accountId: number;
  accountName: string;
  vendorCode: string;
  billValue: number;
  balance: number;
  colVal: number;
  email: string;
}

export class empModel {
  employeeId: number;
  employeeName: string;
  phone: string;
  track?: boolean;
  salary?: number;
  incentive?: number;
}

export class custmarviewModel {
  accountId?: number;
  accountName: string;
  customerId?: number;
  customerName: string;
  companyId?: number;
  companyName: string;
  margin: string;
}

export class rayarSettingModel {
  rayarSettingId: number;
  settingName: string;
  settingValue: string;
}

export class billsumModel {
  groupName: string;
  driverName1: string;
  driverName: string;
  driverPhone: string;
  billValue: number;
  billCount: number;
  customerCount: number;
  billValue1: number;
  billCount1: number;
  customerCount1: number;
  resource1: string;
  resource2: string;
  vehicleName: string;
  remarks: string;
  serial: number;
  shortDelBillCount: number;
}

export class SaveBillPlanModel {
  billPlanModels: billPlanModel[];
  billsumModels: billsumModel[];
  dbVersion: string;
}

export class billPlanModel {
  rhelperName: string;
  rdriverName: string;
  shortDelivery: boolean;
  customerIdcount:number;
  billCount: number;
  pLatitude: number;
  pLongitude: number;
  billType: string;
  customerFolDate?: Date;
  salesAdd: boolean;
  pendingPost: number;
  hasReviewed: boolean;
  supplied: boolean;
  selected: boolean;
  tag: string;
  saleOrder: boolean;
  custOrder: number;
  billDays: number;
  cashCarry: boolean;
  grnNumber: string;
  grnRemarks: string;
  lastDrivers: string;
  contactPhone: string;

  salesContactPhone: string;
  salesPersonName: string;
  latitude: number;
  longitude: number;
  showDetail: boolean;
  pending: number;
  balance: number;
  colVal: number;
  configMargin: string;
  margin: string;
  companyName: string;
  billPlanId: number;
  serial: number;
  driverName: string;
  driverName1: string;
  billId: number;
  billNumber: string;
  billDate: Date;
  prevBillDate?: Date;
  billValue: number;
  billStatus: string;
  remarks: string;
  accountName: string;
  customerId: number;
  customerName: string;
  address: string;
  mapLink: string;
  information1: string;
  information2: string;
  information3: string;
  gst: string;
  inStock: boolean;
  billRemarks: string;
  zone: string;
  deliveryDate: Date;
  deliveryStatus: string;
  groupName: string;

  poNumber: string;
  poExpDate?: Date;
  appointDate?: Date;
}

export class companyModel {
  showDetail: boolean;
  companyId: number;
  companyName: string;
  rCompanyName: string;
  sCompanyName: string;
  serialStart: number;
  damageSerialStart: number;
  email: string;
  serialGroup: string;
  userName: string;
  vehicleId?: number;
  vehicleName: string;
  createdDate: Date;
}

export class grnFollowUpModel {
  accountId: number;
  accountName: string;
  noGRNBillCount: number;
  allgrnBillCount: number;
  halfGRNBillCount: number;
}

export class company1Model {
  custStatus: string;
  customerFolDate?: Date;
  contactName: string;
  contactPhone: string;
  gst: string;
  companyId: number;
  companyName: string;
  billcount: number;
  billcount1: number;
  billcount2: number;
  billcount3: number;
  billcount4: number;
  billcount5: number;
  billAmount: number;
  billAmount1: number;
  billAmount2: number;
  billAmount3: number;
  billAmount4: number;
  billAmount5: number;
  showDetail: boolean;
}
export class ResourceVModel {
  resources: TimeTrackResourceModel[];
  tags: string[];
  employees: empModel[];
  vehicles: VehicleModel[];
}

export class TimeTrackResourceModel {
  timeTrackResourceId: number;
  resourceName: string;
  contactPhone: string;
  userName: string;
  resource1: string;
  resource2: string;
  vehicleName: string;
  remarks: string;
  serial?: number;
}

export class vehicalVm {
  vehicles: VehicleModel[];
  vehicleDocs: DocumentTrackModel[];
}

export class AttendanceModel {
  doc : number;
  mileage: number;
  miles: number;
  fromDate?: Date;
  toDate?: Date;
  page: number;
  resourceName: string;
  dateValue: Date;
  attendanceId?: number;
  timeTrackResourceId?: number;
  entryDate?: Date;
  checkInTime?: Date;
  checkOutTime?: Date;
  reason: string;
  checkIn: string;
  fuel?: number;
  reasonOut: string;
  checkOut: string;
  isReviewed?: boolean;
  reviewedDate?: Date;
  vehicleName: string;
  isEdit: boolean;
  uniqId: string;
  driverName: string;
  driverPhone: string;
  billValue: number;
  billCount: number;
  customerCount: number;
  billValue1: number;
  billCount1: number;
  customerCount1: number;
  helperName: string;
  helperPhone: string;
}


export class EmpAttendanceModel {
  incentive?: number
  salary?: number;
  track?: boolean;
  fromDate?: Date;
  toDate?: Date;
  page: number;
  employeeName: string;
  dateValue: Date;
  empAttendanceId?: number;
  employeeId?: number;
  entryDate?: Date;
  checkInTime?: Date;
  reason: string;
  checkIn: string;
  isEdit: boolean;
  uniqId: string;
}

export class InventoryModel {
  inventoryId: number;
  inventoryDate: Date;
  inventoryValue: number;
  page: number;
  fromDate: Date;
  toDate: Date;
}

export class invoiceTrackModel {
  invoiceTrackId: number;
  invoiceValue: number;
  invoiceType: string;
  remarks: string;
  createdBy: string;
  createdDate: Date;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  companyName: string;
}

export class LedgerBillSumModel {
  companyName: string;
  years: number;
  months: number;
  dates: number;
  cancel: number;
  paid: number;
  collasgnd: number;
  new: number;
  supplied: number;
  total: number;
}

export class accountLedgerModel {
  accountId: number;
  companyName: string;
  showDetail: boolean;
  doc: number;
  crDr: string;
  descriptions: string;
  transactionDate: Date;
  amount: number;
  rowNum: number;
  runningBalance: number;
  page: number;
  fromDate: Date;
  toDate: Date;
  billStatus: string;

  id: number;
  remarks: string;
  grnNumber: string;
  isEdit: boolean;
  uniqId: string;
  grnStatus: string;

}
export class grnEmailModel {
  grnModels: grnModel[];
  accountId: number;
  operation: string;
}

export class grnModel {
  poStatus: string;
  billDays: number;
  suppliedDays: number;
  balance: number;
  shortage: number;
  shortageRemarks: string;
  page: number;
  billId: number;
  accountId: number;
  accountName: string;
  customerName: string;
  companyName: string;
  billNumber: string;
  billDate: Date;
  billValue: number;
  colVal: number;
  billStatus: string;
  suppliedDate: Date;
  docCount: number;
  remarks: string;
  grnNumber: string;
  billAccountDetailId: number;
  isEdit: boolean;
  uniqId: string;
  grnStatus: string;
  showDetail: boolean;
  selected: boolean;
  poNumber: string;
}


export class BankStmtModel {
  showDetail: boolean;
  page: number;
  lastUpdatedBy: string;
  hasCollections: number;
  id: number;
  remarks: string;
  reviewe: number;
  reviewed: boolean;
  serialNo: string;
  transactionId: string;
  valueDate: Date;
  transPostedDate: Date;
  chequeNo: string;
  description: string;
  crDr: string;
  source: string;
  transactionAmount: number;
  availableBalance: number;
  selected: boolean
  accountId: number
  accountName: string
}

export class OrderTrackModel {
  customerName: string;
  personName: string;
  orderId: number;
  personId: number;
  companyName: string;
  customerId: number;
  orderDate: Date;
  remarks: number;
  orderStatus: string;
  isBilled: boolean;
  billedDate: Date;
}

export class ExpenseModel {
  expenseId: number;
  years: number;
  entryDate: Date;
  amount: number;
  paidTo: string;
  reason: string;
  category: string;
  isReviewed: boolean;
  reviewedDate: Date;
  createdDate: Date;
}


export class ProductModel {
  purchaseQuantity: number;
  availableQuantity?: number;
  billQuantity: number;
  minOrderQuantity: number;
  inventoryQuantity: number;
  inventoryVerifiedDate?: Date;
  inventoryVerifiedBy: string;
  defaultMargin: string;
  barCode: string;

  noPcs: number;
  price: number;
  hsno: string;
  crate: number;
  srate: number;
  mrp: number;
  productId: number;
  productName: string;
  category: string;
  page: number;
  pageSize: number;
  showDetail: boolean;
  isEdit: boolean;

}

export class DamageTrackModel {
  page: number;
  damageTrackId: number;
  entryDate: Date;
  mrp: number;
  quantity: number;
  batchNo: string;
  productName: string;
  productId: number;
  category: string;
  remarks: string;
  isReviewed: boolean;
  reviewedDate: Date;
}

export class PendingFollowUpModel {
  customerId: number;
  customerName: string;
  bouncedCheckDetail: string;
  billNumber: string;
}

export class damageReport {
  companyName: string;
  mode: string;
  entryDate: Date;
  collectionValue: number;
}

export class PendingBills {
  custStatus: string;
  billNote: string;
  customerFolDate?: Date;
  contactPhone: string;
  contactName: string;
  gst: string;
  orderText: string;
  verifiedRemarks: string;
  showDetail: boolean;
  customerId: number;
  companyName: string;
  billDays: number;
  address: string;
  billId: number;
  balance: number;
  billValue: number;
  notReceived: number;
  collected: number;
  billNumber: string;
  customerName: string;
  personName: string;
  billDate: Date;
  billValue1: number;
  discount: number;
  shortage: number;
  displayDiscount: number;
  damage: number;
  remarks: string;
  damageIrNumber: string;
  claimDocCount: number;
  isnotvalidir: boolean;
}

export class CollectionPendingReview {
  collectedBy: string;
  mode: string;
  total: number;
  received: number;
  notReceived: number;
  reviewedCount: number;
  notReceivedCount: number;
  totalCount: number;
}

export class CollectionPersonVolumneByMonth {
  year: number;
  month: number;
  collectedBy: string;
  total: number;
  cash: number;
  cashDiscount: number;
  neft: number;
  cheque: number;
  damage: number;
  mobileTransfer: number;
}

export class PersonCollectionReport {
  collectedBy: string;
  entryDate: Date;
  cashCount: number;
  chequeCount: number;
  damageCount: number;
  mobileTransferCount: number;
  totalCount: number;
  total: number;
  cash: number;
  cheque: number;
  damage: number;
  mobileTransfer: number;
  cashDicount: number;
  cashDiscountCount: number;
  neft: number;
  neftCount: number;
}

export class PendingBillsByCust {
  customerName: string;
  age20BillCount: number;
  age20Balance: number;
  age90BillCount: number;
  age90Balance: number;
  ageAbove90BillCount: number;
  ageAbove90Balance: number;
  totalBills: number;
  totalBillValue: number;
  collected: number;
  balance: number;
}

export class PendingBillsByPerson {
  personName: string;
  balance: number;
  billvalue: number;
  collected: number;
  notReceived: number;
  billCount: number;

}

export class User {
  id: number;
  userName: string;
  password: string;
  firstName: string;
  lastName: string;
  role: Role;
  token?: string;
  refreshToken?: string;
  profileId: string;
}

export enum Role1 {
  Employee = 'Employee',
  Admin = 'Admin',
  Sales = 'Sales',
  Company = 'Company',
  Customer = 'Customer',
  ReadOnly = 'ReadOnly',
  Driver = 'Driver'
}

export enum Role2 {
  PAdmin = "PAdmin",
  MN = "MN",
  TI = "TI",
}

export enum Role {
  Employee = 'Employee',
  Admin = 'Admin',
  Sales = 'Sales',
  Driver = 'Driver',
  Company = 'Company',
  Customer = 'Customer',
  ReadOnly = 'ReadOnly',
  PAdmin = "PAdmin",
  MN = "MN",
  TI = "TI",
}

export class userModel {
  id: number;
  userName: string;
  firstName: string;
  lastName: string;
  password: string;
  role: string;
  profileId: string;
  appName: string;
}

export class collectionDetailModel {
  collectionDetailId: number;
  collectionValue: number;
  createdBy: string;
  createdDate: Date;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  mode: string;
  description: string;
  crDr: string;
  transactionAmount: number;
  transPostedDate: Date;

}

export class DocumentModel {
  documentId: number;
  documentType: string;
  documentName: string;
  documentPath: string;
  uploadDate: Date;
  billId: number;
  invoiceId: number;
  collectionId: number;
}

export class CustomerMarginModel {
  page: number;
  customerId: number;
  customerName: string;
  companyName: string;
  margin: string;
}

export class CustomerModel {
  holdBill: boolean;
  salesAdd: boolean;
  hasReviewed: boolean;
  userName: string;
  showDetail: boolean;
  accountId: number;
  accountName: string;
  customerId: number;
  customerName: string;
  address: string;
  mapLink: string;
  information1: string;
  information2: string;
  information3: string;
  gst: string;
  contactName: string;
  contactPhone: string;
  zone: string;
  latitude: number;
  longitude: number;
  lastBilled: Date;
  lastPayment: Date;
  baskinBill: boolean;
  margins: CustomerMarginModel[];
  bills: BillModel[];
}

export class RoutePlanBillModel {
  contactName: string;
  contactPhone: string;
  address: string;
  mapLink: string;
  information3: string;
  information1: string;
  information2: string;
  companyName: string;
  billNumber: string;
  customerName: string;
  billDate: Date;
  billValue: number;
  latitude: number;
  longitude: number
}

export class InvoiceModel {
  page: number;
  fromDate: Date;
  toDate: Date;
  invoiceId: number;
  invoiceDate: Date;
  invoiceNumber: string;
  invoiceValue: number;
  preGstValue: number;
  gstValue: number;
  gstPercentage: number;
  mode: string;
  companyName: string;
  margin: number;
  billProducts: BillProductModel[];
}

export class AsgnTrackModel {
  assignedDate: Date;
  name: string;
  billCount: number;
}

export class SaleManTrack {
  customerId: number[];
  trackDate: Date;
  personId: number;
  information3: string;
}

export class SaleManTrackModel {
  customerId: number;
  trackDate: Date;
  personId: number;
  information3: string;
  salesManTrackId: number;
  customerName: string;
  address: string;
  accountName: string;
  personName: string;
}

export class BSaleManTrackModel {
  confirmMargin: boolean;
  configMargin: string;
  cashCarry: boolean;
  bounced: number;
  personName: string;
  customerName: string;
  customerId: number;
  baskinSaleManTrackId: number;
  personId: number;
  visitDay: number;
  margin: string;
  category: number;
  address: string;
  route: string;
  remarks: string;
  accountName: string;
  visitedDate?: Date;
  orderedDate?: Date;
  billedDate?: Date;
  isEdit: boolean;
  uniqId: string;
  companyName: string;
  zone: string;
  pending: number;
  pendingMT: number;
  billAge: number;
  showDetail: boolean;
  companyId: number;
  accountId?: number;
  orderText: string;
}


export class BankCollectionModel {
  collectionId: number[];
  bounced: boolean;
  id: number;
}

export class BankCollection1Model {
  id: number;
  accountId: number;
  billDetail: BillDetail[];
}

export class BillDetail {
  billId: number;
  collectionValue: number;
  discountValue: number;
  shortageValue: number;
  displayDiscountValue: number;
}

export class photoModel {
  photoId: string;
  uploadDate: Date;

  fileName: string;
  fileType: string;
  fileSource: any;
  imageSrc: string;
  text: string;
  user: string;
  parentPhotoId: string;

}

export class BillProductModel {
  billProductId: number;
  productId: number;
  billId: number;
  invoiceId: number;
  quantity: number;
  price: number;
  disPrice: number;
  productName: string;
  crate: number;
  srate: number;
  type: string;
  defaultMargin: string;
  margin: string;
}

export class ReturnTrackModel {
  page: number;
  pageSize: number;
  billProductId: number;
  productId: number;
  billId: number;
  invoiceId: number;
  quantity: number;
  price: number;
  disPrice: number;
  productName: string;
  crate: number;
  srate: number;
  type: string;

  customerName: string;
  accountName: string;
  billNumber: string;
  billValue: number;
  billDate: Date;
  billStatus: string;
  margin: string;
  defaultMargin: string;
  isReturned: boolean;
  returnedQuantity: number;
  toBeReturn: number;
}

export class BillProductInvModel {
  billProductId: number;
  productId: number;
  billId: number;
  invoiceId: number;
  quantity: number;
  price: number;
  disPrice: number;
  productName: string;
  crate: number;
  srate: number;
  type: string;

  billNumber: string;
  margin: string;
  invoiceNumber: string;
  billStatus: string;
  runningTotal: number;
  billValue: number;
  billDate?: Date;
  invoiceDate?: Date;
}



export class BillModel {
  mapLink: string;
  createdBy: string;
  tag: string;
  lastDrivers: string;
  billProducts: BillProductModel[];
  showDetail: boolean;
  showDetail1: boolean;
  cashCarry: boolean;
  billRemarks: string;
  driverName: string;
  trade: string;
  lastCheckedInOut: string;
  verifiedRemarks: string;
  lastVerifiedDate: Date;
  previousAssigned: string;
  accountName: string;
  accountId: number;
  page: number;
  pageSize?: number;
  fromDate: Date;
  toDate: Date;
  companyName: string;
  balance: number;
  billId: number;
  billNumber: string;
  customerName: string;
  customerId: number;
  billDate: Date;
  billValue: number;
  supplied: boolean;
  paid: boolean;
  suppliedDate: Date;
  paidUpDate: Date;
  billStatus: string;
  personId: number;
  personName: string;
  colVal: number;
  inStock: boolean;
  baskinPrintId: number;
  printed: boolean;
  printedString: string;

  printedDate: Date;
  remarks: string;
  reviewe: string;
  isEdit: boolean;
  margin: number;
  configMargin: string;
  includeService: string;
  includeCancel: string;
  inStock1: string;
  billNumber1: string;

  poNumber: string;
  poExpDate?: Date;
  appointDate?: Date;
  custOrder: number;
}

export class CollectonPersonModel {
  personId: number;
  name: string;
  contactPhone: string;
  emailId1: string;
  emailId2: string;
  managerId: number;
  userName: string;
  companys: string;
  showDetail: boolean;
  appUserId : number;
}

export class paymentModel {
  pageSize: number;
  page: number;
  customerName: string;
  billNumber: string;
  companyName: string;
  billValue: number;
  billDate: Date;
  documentName: string;
  uploadDate: Date;
  reviewed?: boolean;
  billId: number;
  customerId: number;
  documentId: number;
  createdBy: string;
  remarks: string;
}

export class CollectionModel {
  createdBy: string;
  createdDate: Date;
  autoReviewCode: number;
  accountId: number;
  customerId: number;
  markbkStRev: boolean;
  bankStatementId: number;
  bouncedFollowedUp: boolean;
  bounced: number;
  bouncedCheckDetail: string;
  chequeDate: Date;
  chequeNumber: number;
  isChequeCredited: boolean;
  creditedDate: Date;
  companyName: string;
  billId: number;
  collectionId: number;
  entryDate: Date;
  collectionValue: number;
  reviewed: boolean;
  reviewedDate: Date;
  mode: string;
  collectedBy: string;
  remarks: string;
  billNumber: string;
  billDate: Date;
  billValue: number;
  customerName: string;
  personName: string;
  personId: number;
  showDetail: boolean;
  damageIrNumber: string;

}

export class CollectionMtModel {
  billId: number;
  billNumber: string;
  billValue?: number;
  billDate?: Date;
  customerName: string;
  customerId: number;
  billStatus: string;
  companyName: string;
  mode: string;
  collectionValue: number;
  createdBy: string;
  createdDate: Date;
  description: string;
  crDr: string;
  id: number;
  transPostedDate: Date;
  remarks: string;
  source: string;
  transactionAmount: number;
}

export class collectionFollowupModel {
  pageSize?: number;
  page: number;
  collectionDetailId: number;
  billId: number;
  accountId?: number;
  customerId: number;
  collectionValue: number;
  mode: string;
  bankStatementId?: number;
  remarks: string;
  claimValue?: number;
  received?: boolean;
  accountName: string;
  customerName: string;
  companyName: string;
  isEdit: boolean;
  billNumber: string;
  billValue?: number;
  transactionId: string;
  valueDate?: Date;
  billDate?: Date;
  transPostedDate?: Date;
  description: string;
  crDr: string;
  transactionAmount?: number;
  fromDate: Date;
  toDate: Date;
  showDetail: boolean;
}

export class noteTrackModel {
  pageSize?: number;
  page: number;
  noteTrackId: number;
  noteType: string;
  internal: boolean;
  userName: string;
  enteredDate: Date;
  id: number;
  billId: number;
  billNumber: string;
  companyName: string;
  billValue: number;
  customerId: number;
  customerName: string;
  customerFolDate?: Date;
  notes: string;
  removeFollowUp: boolean;
  personName: string;
}
