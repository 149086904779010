import { formatDate } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AttendanceModel, TimeTrackResourceModel, VehicleModel, empModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {

  searchlModel = new AttendanceModel();
  selectedDate1 = Date.now();
  selectedDate = Date.now();
  error = '';
  bills: AttendanceModel[] = [];
  loading = false;
  emps: TimeTrackResourceModel[] = [];
  vehicles: VehicleModel[] = [];
  empsAll: empModel[] = [];

  getVehicles() {
    var search: VehicleModel = new VehicleModel();
    search.page = 0;
    search.category = "Vehicle";
    this.api.getSearchVehicle(search).subscribe(result => {
      this.vehicles = result.vehicles;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  getResources() {
    this.api.getResources()
      .subscribe(result => {
        this.empsAll = result.employees.sort((a, b) => a.employeeName.localeCompare(b.employeeName));
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }
  selectChangeHandler(event: any) {
    this.selectedDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.selectedDate1 = event.target.value;
  }

  onpage(next: boolean) {
    if (next) {
      this.getBills(this.searchlModel.page + 1);
    }
    else {
      if (this.searchlModel.page >= 1)
        this.getBills(this.searchlModel.page - 1);
    }
  }

  constructor(private api: RayarApiService) {
    this.getVehicles();
    this.getResources();
  }

  ngOnInit(): void {
    this.searchlModel.page = 0;
    this.searchlModel.timeTrackResourceId = -1;
    this.searchlModel.driverName = "";
    this.searchlModel.helperName = "";
    this.searchlModel.vehicleName = "";
    this.getCollPesons();
  }


  getBills(p: number) {
    this.error = "";
    this.searchlModel.fromDate = new Date(this.selectedDate);
    this.searchlModel.toDate = new Date(this.selectedDate1);
    this.searchlModel.page = p;
    this.searchlModel.timeTrackResourceId = Number(this.searchlModel.timeTrackResourceId);
    this.searchlModel.uniqId = "d";
    this.api.getAttendances(this.searchlModel).subscribe(result => {
      this.bills = result;
    }, error => {
      this.error = error; this.loading = false; console.error(error);
    });
  }

  getCollPesons() {
    this.api.getResources()
      .subscribe(result => { this.emps = result.resources; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  onSearch() {
    this.getBills(0);
  }

  onReset() {
    this.selectedDate1 = Date.now();
    this.selectedDate = Date.now();
    this.searchlModel.timeTrackResourceId = -1;
    this.error = "";
    this.bills = [];
  }

  downloadReport() {
    this.searchlModel.page = 0;
    this.searchlModel.fromDate = new Date(this.selectedDate);
    this.searchlModel.toDate = new Date(this.selectedDate1);
    this.searchlModel.timeTrackResourceId = Number(this.searchlModel.timeTrackResourceId);
    this.searchlModel.uniqId = "d";
    this.api.downloadFilePost("AttendDownload", this.searchlModel).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = "Attendance" + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }

  public download(q: string, b: any) {
    this.api.downloadFilePost(q, b).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }

}
