<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-md-2">
                <table class="table table-striped table-sm table-responsive table-condensed" *ngIf="!fileType">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col" (click)="sortA()">Account&nbsp;<i class="fa fa-arrows-v"></i></th>
                            <th scope="col" (click)="sortB()">Balance&nbsp;<i class="fa fa-arrows-v"></i></th>
                        </tr>
                    <tbody>
                        <tr *ngFor="let u of accBalance">
                            <td>{{u.accountName}}</td>
                            <td>{{u.balance }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-10">
                <div class="form-row" *ngIf="!fileType">
                    <div class="col-md-2">
                        <label for="accBalance">Account</label>
                        <select class="form-control" [(ngModel)]="searchBillModel.accountId"
                            (change)="selectChangeHandler3($event)">
                            <option value="0" selected disabled>Choose</option>
                            <option *ngFor="let p of persons" [value]="p.accountId">{{p.accountName}}</option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <label>From</label>
                        <input type="date" [ngModel]="searchBillModel.fromDate | date:'yyyy-MM-dd'"
                            (change)="selectChangeHandler($event)" class="form-control" />
                    </div>
                    <div class="col-md-2">
                        <label>To</label>
                        <input type="date" [ngModel]="searchBillModel.toDate | date:'yyyy-MM-dd'"
                            (change)="selectChangeHandler1($event)" class="form-control" />
                    </div>
                    <div class="col-md-2">
                        <label> </label>
                        <div>
                            <button class="btn btn-outline-primary" (click)="onCollBillSearch()">Search</button>
                            <button class="btn btn-outline-primary" (click)="onCollBillSearchReset()">Reset</button>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label> </label>
                        <div>
                            <button class="btn btn-outline-primary" (click)="downloadAccLedReport()">Download</button>
                        </div>
                    </div>
                </div>
                <input name="file" id="file" (change)="upload($event)" type="file" #inputFile hidden>
                <div class="form-row" *ngIf="fileType">
                    <div class="col-md-2">
                        <label>Transaction Date</label>
                        <input class="form-control" type="text" disabled
                            value="{{selAcled.transactionDate | date: 'dd/MM/yyyy'}}" />
                    </div>
                    <div class="col-md-2">
                        <label>Description</label>
                        <input class="form-control" type="text" disabled value="{{selAcled.descriptions}}" />
                    </div>
                    <div class="col-md-2">
                        <label>CrDr</label>
                        <input class="form-control" type="text" disabled value="{{selAcled.crDr}}" />
                    </div>
                    <div class="col-md-2">
                        <label>Amount</label>
                        <input class="form-control" type="text" disabled value="{{selAcled.amount}}" />
                    </div>
                    <div class="col-md-2">
                        <label>GRN Number</label>
                        <input class="form-control" type="text" disabled value="{{selAcled.grnNumber}}" />
                    </div>
                    <div class="col-md-2">
                        <label>Remarks</label>
                        <input class="form-control" type="text" disabled value="{{selAcled.remarks}}" />
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-outline-primary" (click)="onUpload()">
                            <span>Upload Selected {{fileType }}</span>
                        </button>
                        <button class="btn btn-outline-primary" (click)="onUploadCancel()">
                            <span>Cancel</span>
                        </button>
                    </div>
                    <div class="col-md-2">
                        <img [src]="imageSrc" *ngIf="imageSrc" style="width: 100%;height: auto;">
                    </div>
                </div>
                <table class="table table-striped table-sm table-condensed" *ngIf="!fileType">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">SlNo</th>
                            <th scope="col">Transaction Date</th>
                            <th scope="col">Description</th>
                            <th scope="col">CrDr</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Running Balance</th>
                            <th scope="col">GRN Number</th>
                            <th scope="col">Remarks</th>
                        </tr>
                    <tbody>
                        <tr *ngFor="let u of accledgers">
                            <td>{{u.rowNum}}</td>
                            <td>{{u.transactionDate | date: 'dd/MM/yyyy'}}</td>
                            <td>{{u.descriptions }}</td>
                            <td>{{u.crDr }}</td>
                            <td>{{u.amount }}</td>
                            <td>{{u.runningBalance }}</td>
                            <td>{{u.grnNumber}}</td>
                            <td>{{u.remarks}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>