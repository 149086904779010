import { Component, OnInit } from '@angular/core';
import { userModel, User } from '../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  loading = false;
  error = '';
  submitted = false;
  customerForm: FormGroup;
  get f() { return this.customerForm.controls; }
  addEditCustModel: userModel = new userModel();
  currentUser: User;

  constructor(private formBuilder: FormBuilder, 
    private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      firstName: [this.currentUser.firstName, Validators.required],
      lastName: [this.currentUser.lastName, Validators.required],
      password: [''],
    });
    this.addEditCustModel.id = this.currentUser.id;
  }


  onAddCustSubmit() {
    this.error = '';
    this.submitted = true;
    if (this.customerForm.invalid) return;
    this.loading = true;
    this.addEditCustModel.firstName = this.f.firstName.value;
    this.addEditCustModel.lastName = this.f.lastName.value;
    this.addEditCustModel.password = this.f.password.value;
    this.authenticationService.profileEditUser(this.addEditCustModel)
      .subscribe(() => {
        this.error = "Profile Updated";
        this.submitted = false;
        this.loading = false;
      }, error => {
      this.error = error;
        this.loading = false; console.error(error)
      });
  }


}
