import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { BillModel, CollectionModel } from '../models';

@Component({
  selector: 'app-closing',
  templateUrl: './closing.component.html',
  styleUrls: ['./closing.component.css']
})
export class ClosingComponent implements OnInit {

  loading = false;
  error = '';
  submitted = false;
  colls: CollectionModel[] = [];
  selectedBill: BillModel = new BillModel();
  pagenumber = 0;
  constructor(private api: RayarApiService) {
    this.getClosingBills(this.pagenumber);
    this.error = '';
  }

  ngOnInit(): void {
  }

  onpage(next: boolean) {
    if (next) {
      this.getClosingBills(this.pagenumber + 1);
    }
    else {
      if (this.pagenumber >= 1)
        this.getClosingBills(this.pagenumber - 1);
    }
  }

  markPaid1() {
    this.error = '';
    this.api.markPaid(this.selectedBill.billId).subscribe(() => { this.getClosingBills(this.pagenumber); }, error => { this.error = error; this.loading = false; console.error(error) });
  }

  viewColls(u: BillModel) {
    this.error = '';
    this.selectedBill = u;
    this.api.getCollsSearch(u.billId.toString(), 'GetColls').subscribe(result => { this.colls = result; }, error => console.error(error));
  }

  getClosingBills(p: number) {
    this.pagenumber = p;
    this.api.onCollBillSearch(p.toString(), 'ClosingBills1').subscribe(result => {
      if (result.length > 0) {
        this.selectedBill = result[0];
        this.viewColls(this.selectedBill)
      }
      else {
        this.selectedBill = new BillModel();
        this.selectedBill.billId == 0;
      }
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }
}
