import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Role1, User } from '../models';
import { AuthenticationService } from '../_services/authentication.service';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-order-intake',
  templateUrl: './order-intake.component.html',
  styleUrls: ['./order-intake.component.css']
})
export class OrderIntakeComponent implements OnInit {
  loading = false;
  error = '';

  constructor(private route: ActivatedRoute,
    private router: Router, private api: RayarApiService, private authenticationService: AuthenticationService) {
    if (Object.keys(Role1).includes(this.authenticationService.currentUserValue.role)) {
      this.router.navigate(['/home']);
    } else {

    }
  }

  ngOnInit(): void {
  }

}
