<div class="card">
    <h4 class="card-header">Profile</h4>
    <div class="card-body">
        <form [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
            <div class="form-row">
                <div class="col-md-2">
                    <label for="userName">User Name</label>
                    <div  class="form-control">{{currentUser.userName}}</div>
                </div>
                <div class="col-md-2">
                    <label for="firstName">First Name</label>
                    <input type="text" formControlName="firstName" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                        <div *ngIf="f.firstName.errors.required">First Name is required</div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label for="lastName">Last Name</label>
                    <input type="text" formControlName="lastName" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                        <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label for="password">Password</label>
                    <input type="text" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Last Name is required</div>
                    </div>
                </div>
            </div>
            <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Save
            </button>
            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        </form>
    </div>
</div>