import { Component, OnInit } from '@angular/core';
import { User, BillModel, CustomerModel } from '../models';
import { first } from 'rxjs/operators';
import { RayarApiService } from '../_services/rayar-api.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-cancelbill',
  templateUrl: './cancelbill.component.html',
  styleUrls: ['./cancelbill.component.css']
})
export class CancelbillComponent implements OnInit {

  loading = false;
  bills: BillModel[] = [];
  error = '';

  constructor(private formBuilder: FormBuilder, private api: RayarApiService) {
  }

  ngOnInit() {

  }

  onSearchPerson() {
    this.searchBillModel.page = 0;
    if (this.searchBillModel.personId)
      this.searchBillModel.personId = parseInt(this.searchBillModel.personId.toString());
    this.api.onBillSearch('BillsCancelled', this.searchBillModel)
      .subscribe(result => { this.bills = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }


  selectChangeHandler(event: any) {
    this.searchBillModel.fromDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.searchBillModel.toDate = event.target.value;
  }


  searchBillModel: BillModel = new BillModel();

  onReset() {
    this.searchBillModel = new BillModel();
    this.searchBillModel.personId = 0;
    this.onSearchPerson();
  }
}
