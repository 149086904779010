import { AuthenticationService } from '../_services/authentication.service';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Role } from '../models';
import { ElementRef, HostListener } from '@angular/core';


@Directive({ selector: '[DataCustomAcl]' })
export class AclDirective {

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthenticationService) { }

    @Input()
    set DataCustomAcl(demandAcl: string) {
        if (environment.applyAcl && demandAcl && this.authService) {
            if (this.authService.currentUserValue.role == Role.Admin) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                if (demandAcl.split(":").findIndex(x => x === this.authService.currentUserValue.role.toString()) >= 0) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainer.clear();
                }
            }
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}

@Directive({
    selector: '[appTwoDigitDecimaNumber]'
})
export class TwoDigitDecimaNumberDirective {
    private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
    constructor(private el: ElementRef) {
    }
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        let current: string = this.el.nativeElement.value;
        const position = this.el.nativeElement.selectionStart;
        const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }
}