<div class="row">
    <div class="col">
        <div class="form-row">
            <div class="col-md-2">
                <label>Name</label>
                <input class="form-control form-control-sm" type="text" [(ngModel)]="search.vehicleName" />
            </div>
            <div class="col-md-2">
                <label>Category</label>
                <select class="form-control form-control-sm" [(ngModel)]="search.category">
                    <option value="Vehicle">Vehicle</option>
                    <option *DataCustomAcl="'Admin:Employee'" value="Company">Company</option>
                </select>
            </div>
            <div class="col-md-2">
                <label></label><br>
                <button class="btn btn-sm btn-info" (click)="onNameCustomerSearch(0)">Search</button>
            </div>
        </div>
        <br><input name="file" id="file" (change)="upload($event)" type="file" #inputFile hidden>
        <div class="form-row">
            <div class="col">
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">
                                <div *DataCustomAcl="'Admin:Employee'">
                                    <button class="btn btn-sm btn-info" *ngIf="!addEditCustModel.isEdit"
                                        (click)="oAddCustomer()"><i class="fa fa-plus"></i></button>
                                </div>
                            </th>
                            <th></th>
                            <th scope="col">Name</th>
                            <th scope="col">Category</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="addEditCustModel.isEdit">
                        <tr>
                            <td>
                                <button class="btn btn-sm btn-info" *ngIf="addEditCustModel.isEdit"
                                    (click)="saveGrn(addEditCustModel)">Save</button>&nbsp;
                                <button class="btn btn-sm btn-info" *ngIf="addEditCustModel.isEdit"
                                    (click)="cancelGrn()">Cancel</button>
                            </td>
                            <td></td>
                            <td>
                                <input type="text" *ngIf="addEditCustModel.isEdit"
                                    [(ngModel)]="addEditCustModel.vehicleName" class="form-control form-control-sm" />
                            </td>
                            <td>
                                <select *ngIf="addEditCustModel.isEdit" class="form-control form-control-sm"
                                    [(ngModel)]="addEditCustModel.category">
                                    <option value="Vehicle">Vehicle</option>
                                    <option *DataCustomAcl="'Admin'" value="Company">Company</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngFor="let u of customers">
                        <tr>
                            <td>
                                <button class="btn btn-sm btn-info"
                                    (click)="showDetail(u)">{{u.showDetail?'Hide':'Show'}}</button>&nbsp;
                            </td>
                            <td>
                                <div *DataCustomAclOnly="'Admin'">
                                    <button class="btn btn-sm btn-info" *ngIf="!u.isEdit"
                                        (click)="editGrn(u)">Edit</button>
                                    <button class="btn btn-sm btn-info" *ngIf="u.isEdit"
                                        (click)="saveGrn(u)">Save</button>&nbsp;
                                    <button class="btn btn-sm btn-info" *ngIf="u.isEdit"
                                        (click)="cancelGrn()">Cancel</button>
                                </div>
                                <div *DataCustomAclOnly="'Employee'">
                                    <div *ngIf="u.category=='Vehicle'">
                                        <button class="btn btn-sm btn-info" *ngIf="!u.isEdit"
                                            (click)="editGrn(u)">Edit</button>
                                        <button class="btn btn-sm btn-info" *ngIf="u.isEdit"
                                            (click)="saveGrn(u)">Save</button>&nbsp;
                                        <button class="btn btn-sm btn-info" *ngIf="u.isEdit"
                                            (click)="cancelGrn()">Cancel</button>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div *ngIf="!u.isEdit">{{u.vehicleName }}</div>
                                <input type="text" *ngIf="u.isEdit" [(ngModel)]="u.vehicleName"
                                    class="form-control form-control-sm" />
                            </td>
                            <td>
                                {{u.category }}
                            </td>
                        </tr>
                        <tr *ngIf="u.showDetail">
                            <td colspan="8">
                                <div class="form-row">
                                    <div class="col">
                                        <table class="table table-striped table-sm table-responsive table-condensed">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">
                                                        <div *DataCustomAclOnly="'Admin'">
                                                            <button class="btn btn-sm btn-info"
                                                                *ngIf="!addEditCustModel1.isEdit"
                                                                (click)="oAddCustomer1(u)"><i
                                                                    class="fa fa-plus"></i></button>
                                                        </div>
                                                        <div *DataCustomAclOnly="'Employee'">
                                                            <div *ngIf="u.category=='Vehicle'">
                                                                <button class="btn btn-sm btn-info"
                                                                    *ngIf="!addEditCustModel1.isEdit"
                                                                    (click)="oAddCustomer1(u)"><i
                                                                        class="fa fa-plus"></i></button>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th></th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">ValidTillDate</th>
                                                    <th scope="col">ServicedOdoMeter</th>
                                                    <th scope="col">NextServiceMile</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="addEditCustModel1.isEdit">
                                                <tr>
                                                    <td>
                                                        <button class="btn btn-sm btn-info"
                                                            *ngIf="addEditCustModel1.isEdit"
                                                            (click)="saveGrn1(addEditCustModel1)">Save</button>&nbsp;
                                                        <button class="btn btn-sm btn-info"
                                                            *ngIf="addEditCustModel1.isEdit"
                                                            (click)="cancelGrn1()">Cancel</button>
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                        <select *ngIf="addEditCustModel1.isEdit"
                                                            class="form-control form-control-sm"
                                                            [(ngModel)]="addEditCustModel1.documentName">
                                                            <option *ngFor="let p of getDocs(u)" [value]="p">
                                                                {{p}}</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="date" *ngIf="addEditCustModel1.isEdit"
                                                            [(ngModel)]="addEditCustModel1.validTillDate"
                                                            class="form-control form-control-sm" />
                                                    </td>
                                                    <td>
                                                        <input type="number" *ngIf="addEditCustModel1.isEdit"
                                                            [(ngModel)]="addEditCustModel1.notes"
                                                            class="form-control form-control-sm" />
                                                    </td>
                                                    <td>
                                                        <input type="number" *ngIf="addEditCustModel1.isEdit"
                                                            [(ngModel)]="addEditCustModel1.serviceMileLimit"
                                                            class="form-control form-control-sm" />
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngFor="let u of customers1">
                                                <tr>
                                                    <td>
                                                        <div *DataCustomAclOnly="'Admin'">
                                                            <button class="btn btn-sm btn-info"
                                                                (click)="showDetail1(u)">{{u.showDetail?'Hide':'Show'}}</button>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div *DataCustomAclOnly="'Admin'">
                                                            <button class="btn btn-sm btn-info" *ngIf="!u.isEdit"
                                                                (click)="editGrn1(u)">Edit</button>
                                                            <button class="btn btn-sm btn-info" *ngIf="u.isEdit"
                                                                (click)="saveGrn1(u)">Save</button>&nbsp;
                                                            <button class="btn btn-sm btn-info" *ngIf="u.isEdit"
                                                                (click)="cancelGrn1()">Cancel</button>
                                                        </div>
                                                        <div *DataCustomAclOnly="'Employee'">
                                                            <div *ngIf="u.category=='Vehicle'">
                                                                <button class="btn btn-sm btn-info" *ngIf="!u.isEdit"
                                                                    (click)="editGrn1(u)">Edit</button>
                                                                <button class="btn btn-sm btn-info" *ngIf="u.isEdit"
                                                                    (click)="saveGrn1(u)">Save</button>&nbsp;
                                                                <button class="btn btn-sm btn-info" *ngIf="u.isEdit"
                                                                    (click)="cancelGrn1()">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {{u.documentName }}
                                                    </td>
                                                    <td>
                                                        <div *ngIf="!u.isEdit">{{u.validTillDate | date: 'dd/MM/yyyy'}}
                                                        </div>
                                                        <input type="date" *ngIf="u.isEdit"
                                                            [(ngModel)]="u.validTillDate"
                                                            class="form-control form-control-sm" />
                                                    </td>
                                                    <td>
                                                        <div *ngIf="!u.isEdit">{{u.notes}}
                                                        </div>
                                                        <input type="number" *ngIf="u.isEdit" [(ngModel)]="u.notes"
                                                            class="form-control form-control-sm" />
                                                    </td>
                                                    <td>
                                                        <div *ngIf="!u.isEdit">{{u.serviceMileLimit}}
                                                        </div>
                                                        <input type="number" *ngIf="u.isEdit"
                                                            [(ngModel)]="u.serviceMileLimit"
                                                            class="form-control form-control-sm" />
                                                    </td>
                                                    <td> <button class="btn btn-sm btn-info" *ngIf="u.documentId>0"
                                                            (click)="onDownloadDocument(u.documentId, u.docName)">Download</button>
                                                    </td>
                                                    <td>
                                                        <div *DataCustomAclOnly="'Admin'">
                                                            <button class="btn btn-sm btn-info"
                                                                (click)="setupload(u);inputFile.click()">Upload</button>
                                                        </div>
                                                        <div *DataCustomAclOnly="'Employee'">
                                                            <div *ngIf="u.category=='Vehicle'">
                                                                <button class="btn btn-sm btn-info"
                                                                    (click)="setupload(u);inputFile.click()">Upload</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="u.showDetail">
                                                    <td colspan="10">
                                                        <div class="form-row">
                                                            <div class="col">
                                                                <table
                                                                    class="table table-striped table-sm table-responsive table-condensed">
                                                                    <thead class="thead-dark">
                                                                        <tr>
                                                                            <th scope="col"></th>
                                                                            <th scope="col">Document Name</th>
                                                                            <th scope="col">Type</th>
                                                                            <th scope="col">Upload Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let u of suppliedDocs">
                                                                            <th scope="row">
                                                                                <button class="btn btn-sm btn-info"
                                                                                    (click)="onDownloadDocument(u.documentId, u.documentName)">Download</button>&nbsp;
                                                                                <button *DataCustomAcl="'Admin'"
                                                                                    class="btn btn-sm btn-info"
                                                                                    (click)="onDeleteDocument(u.documentId)">Delete</button>
                                                                            </th>
                                                                            <td>{{u.documentName}}</td>
                                                                            <td>{{u.documentType}}</td>
                                                                            <td>{{u.uploadDate| date: 'dd/MM/yy hh:mm
                                                                                a'}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>