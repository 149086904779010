<div class="row">
    <div class="col">
        <div class="form-row">
            <div class="col-md-2">
                <label>SaleReturnNo</label>
                <input class="form-control" type="text" [(ngModel)]="search.saleReturnNo" />
            </div>
            <div class="col-md-2">
                <label>Outlet</label>
                <input class="form-control" type="text" [(ngModel)]="search.outlet" />
            </div>
            <div class="col-md-2">
                <label>Status</label>
                <select class="form-control" [(ngModel)]="search.status">
                    <option value="">All</option>
                    <option value="New">New</option>
                    <option value="Posted">Posted</option>
                    <option value="NotUsed">NotUsed</option>
                    <option value="Complete">Complete</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="From"> From</label>
                <input type="date" [ngModel]="search.date1 | date:'yyyy-MM-dd'" (change)="selectChangeHandler($event)"
                    class="form-control" />
            </div>
            <div class="col-md-2">
                <label for="From"> To</label>
                <input type="date" [ngModel]="search.date2 | date:'yyyy-MM-dd'" (change)="selectChangeHandler1($event)"
                    class="form-control" />
            </div>
            <div class="col-md-2">
                <label for="category">Category</label>
                <select class="form-control" [(ngModel)]="search.epiType">
                    <option value="GT">GT</option>
                    <option value="MT">MT</option>
                </select>
            </div>
            <div class="col-md-2">
                <button class="btn btn-outline-primary" (click)="getepiprn(0)">Search</button>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <br>
        <nav aria-label="...">
            <ul class="pagination">
                <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a></li>
                <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                <li class="page-item">
                    <span class="page-link">Page:{{search.page+1}}</span>
                </li>
            </ul>
        </nav>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Status</th>
                    <th scope="col">SaleReturnNo</th>
                    <th scope="col">Date</th>
                    <th scope="col">Outlet</th>
                    <th scope="col">Sk Unit</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">UnitPrice</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Reason</th>
                    <th scope="col">SaleReturnState</th>
                    <th scope="col">Action</th>
                </tr>
            <tbody>
                <tr *ngFor="let u of customers">
                    <td>{{u.epiType}}</td>
                    <td>{{u.status}}</td>
                    <td>{{u.saleReturnNo}}</td>
                    <td>{{u.date}}</td>
                    <td>{{u.outlet}}</td>
                    <td>{{u.skunit}}</td>
                    <td>{{u.quantity}}</td>
                    <td>{{u.unitPrice}}</td>
                    <td>{{u.amount}}</td>
                    <td>{{u.reason}}</td>
                    <td>{{u.saleReturnState}}</td>
                    <td>{{u.action}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>