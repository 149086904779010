import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { BillModel, CollectonPersonModel, AsgnTrackModel, companyModel } from '../models';
import { formatDate } from '@angular/common';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-assign-collection',
  templateUrl: './assign-collection.component.html',
  styleUrls: ['./assign-collection.component.css']
})
export class AssignCollectionComponent implements OnInit {
  loading = false;
  showhideTable: boolean = true;
  bills: BillModel[] = [];
  asgnTrackReport: AsgnTrackModel[] = [];
  searchBillModel: BillModel = new BillModel();
  error = '';
  persons: CollectonPersonModel[] = [];
  submitted = false;
  companys: companyModel[];

  constructor(private api: RayarApiService) {
    this.searchBillModel.toDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.searchBillModel.fromDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.searchBillModel.personName = "";
    this.searchBillModel.companyName = "";
    this.searchBillModel.lastCheckedInOut = "0";
    this.searchBillModel.trade = "0";
    this.getCollPersons();
    this.onSearchPerson();
    this.getcompany();
  }

  ngOnInit() {
  }

  getcompany() {
    this.api.getCompanys().subscribe(result => {
      this.companys = result;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  getAsgnTrackReport() {
    this.api.getAsgnTrackReport(this.searchBillModel)
      .subscribe(result => { this.asgnTrackReport = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }


  getCollPersons() {
    this.api.getCollPersons().subscribe(result => { this.persons = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  assignCollection(u: BillModel, personId: string) {
    this.submitted = true;
    this.loading = true;
    if (u.personId && parseInt(personId) > 0) {
      this.api.onAssignCollection(u.billId, parseInt(personId), u.customerId, u.companyName).subscribe(result => {
        this.onSearchPerson();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    }
  }


  markChecked(u: BillModel) {
    this.api.onMarkChecked(u.billId, u.printedString)
      .subscribe(result => { this.onSearchPerson(); },
        error => { this.error = error; this.loading = false; console.error(error) });
  }


  onSearchPerson() {
    this.searchBillModel.page = 0;
    this.searchBillModel.pageSize = 30;
    this.api.onBillSearch('BillsAssignCollection', this.searchBillModel)
      .subscribe(result => {
        this.bills = result;
        this.bills.forEach((currentValue, index) => {
          if (!currentValue.personId)
            currentValue.personId = 0;
        });
        this.getAsgnTrackReport();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  downloadReport() {
    this.api.downloadFilePost("AssignBillDownload", this.searchBillModel).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = "Bills" + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }

  onReset() {
    this.searchBillModel = new BillModel();
    this.searchBillModel.personId = 0;
    this.searchBillModel.personName = "";
    this.searchBillModel.lastCheckedInOut = "0";
    this.searchBillModel.trade = "0";
    this.searchBillModel.toDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.searchBillModel.fromDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.onSearchPerson();
  }

  showHide() {
    this.showhideTable = !this.showhideTable;
  }

  selectChangeHandler(event: any) {
    this.searchBillModel.fromDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.searchBillModel.toDate = event.target.value;
  }

  selectChangeHandler11(event: any) {
    this.searchBillModel.lastVerifiedDate = event.target.value;
  }
}
