import { Component, OnDestroy, OnInit } from '@angular/core';
import { AttendanceModel, VehicleModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit, OnDestroy {
  time = new Date();
  intervalID: any;
  searchlModel = new AttendanceModel();
  searchActive: string = "";
  selectedDate1 = Date.now();
  selectedDate = Date.now();
  error = '';
  bills: AttendanceModel[] = [];
  vehicles: VehicleModel[] = [];
  loading = false;

  constructor(private api: RayarApiService) {
    this.getVehicles();
  }

  ngOnInit(): void {
    this.intervalID = setInterval(() => { this.time = new Date(); }, 1000);
    this.searchlModel.page = 0;
    this.getBills(this.searchlModel.page);
  }

  getVehicles() {
    this.api.getVehicles().subscribe(result => { this.vehicles = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  ngOnDestroy() {
    clearInterval(this.intervalID);
  }

  getBills(p: number) {
    if (this.searchActive == "")
      this.searchlModel.isReviewed = null;
    else if (this.searchActive == "true")
      this.searchlModel.isReviewed = true;
    else if (this.searchActive == "false")
      this.searchlModel.isReviewed = false;
    this.searchlModel.fromDate = new Date(this.selectedDate);
    this.searchlModel.toDate = new Date(this.selectedDate1);

    this.error = "";
    this.searchlModel.page = p;
    this.searchlModel.timeTrackResourceId = Number(this.searchlModel.timeTrackResourceId);
    this.searchlModel.uniqId = null;
    this.api.getAttendances(this.searchlModel)
      .subscribe(result => {
        this.bills = result;
        this.bills.forEach(x => {
          x.isEdit = false;
        });
      }, error => {
        this.error = error; this.loading = false; console.error(error);
      });
  }

  editgrnData: AttendanceModel;

  editGrn(u: AttendanceModel) {
    this.bills.forEach(x => {
      x.isEdit = u.uniqId == x.uniqId;
      if (u.uniqId == x.uniqId) {
        this.editgrnData = new AttendanceModel();
        this.editgrnData.attendanceId = u.attendanceId;
        this.editgrnData.dateValue = u.dateValue;
        this.editgrnData.entryDate = u.dateValue;
        this.editgrnData.timeTrackResourceId = u.timeTrackResourceId;
        this.editgrnData.reasonOut = u.reasonOut;
        this.editgrnData.fuel = u.fuel;
        this.editgrnData.checkOut = u.checkOut;
        this.editgrnData.uniqId = u.uniqId;
      }
    });
  }

  saveGrn(u: AttendanceModel) {
    if (!this.editgrnData.checkOut) return;
    this.editgrnData.checkOut = this.editgrnData.checkOut.toString();
    this.api.SaveAttendRemarksOut(this.editgrnData)
      .subscribe(() => {
        this.loading = false;
        this.getBills(this.searchlModel.page);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  cancelGrn(u: AttendanceModel) {
    this.bills.forEach(x => {
      x.isEdit = false;
    });
  }

  markAbsent(u: AttendanceModel) {
    u.entryDate = u.dateValue;
    this.api.MarkAbsent(u).subscribe(() => {
      this.loading = false;
      this.getBills(this.searchlModel.page);
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

}
