import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User, userModel } from '../models';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private _isUserAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  isUserAuthenticated: Observable<boolean> = this._isUserAuthenticatedSubject.asObservable();
  appversion: string;

  constructor(private router: Router, private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.appversion = localStorage.getItem('appversion');
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getSearchUsers(s: string, a: string) {
    if (s)
      return this.http.get<userModel[]>(`${environment.apiUrl}/users/SearchUser/` + a + '/' + s);
    else
      return this.http.get<userModel[]>(`${environment.apiUrl}/users/SearchUser/` + a);
  }

  AddEditUser(b: userModel) {
    return this.http.post<any>(`${environment.apiUrl}/users/createUser`, b);
  }

  onResetPassword(b: userModel) {
    return this.http.post<any>(`${environment.apiUrl}/users/ResetPassword`, b);
  }

  onMarkUserInactive(b: userModel) {
    return this.http.post<any>(`${environment.apiUrl}/users/MarkUserInactive`, b);
  }

  profileEditUser(b: userModel) {
    return this.http.post<any>(`${environment.apiUrl}/users/profileEdit`, b);
  }

  login(username: string, password: string, latitude: number, longitude: number) {
    return this.http.post<any>(`${environment.apiUrl}/users/authenticate`, { username, password, longitude, latitude })
      .pipe(map(user => {
        if (user && user.token) {
          localStorage.setItem('currentUser', JSON.stringify(user));
          if (this.appversion == user.version) {
            localStorage.setItem('appuser', user.userName);
            if (user.username == "Admin")
              localStorage.setItem('appCrednt', password);
            this.currentUserSubject.next(user);
            this.startRefreshTokenTimer();
          } else {
            localStorage.setItem('appversion', user.version);
            window.location.reload();
          }
        }
        return user;
      }));
  }

  loginas(username: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/users/LoginAs`, { username, password })
      .pipe(map(user => {
        if (user && user.token) {
          localStorage.setItem('currentUser', JSON.stringify(user));
          if (this.appversion == user.version) {
            window.location.reload();
          } else {
            localStorage.setItem('appversion', user.version);
            window.location.reload();
          }
        }
        return user;
      }));
  }

  logout() {
    return this.http.get<any>(`${environment.apiUrl}/users/LogOut`).pipe(map(user => {
      localStorage.removeItem('currentUser');
      this.stopRefreshTokenTimer();
      this.currentUserSubject.next(null);
    }));
  }

  apilogout() {
    localStorage.removeItem('currentUser');
    this.stopRefreshTokenTimer();
    this.currentUserSubject.next(null);
  }

  refreshToken() {
    return this.http.get<any>(`${environment.apiUrl}/users/refresh-token`)
      .pipe(tap(user => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        if (this.appversion == user.version) {
          this.currentUserSubject.next(user);
          this.startRefreshTokenTimer();
        } else {
          localStorage.setItem('appversion', user.version);
          window.location.reload();
        }
      }));
  }

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.currentUserValue.token.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    console.log(new Date().toUTCString() + '-' + expires.toUTCString())
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
