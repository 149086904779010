<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-md-2">
                <label>Employee Track</label>
                <select class="form-control" [(ngModel)]="searchTrack">
                    <option value="Yes" selected>Yes</option>
                    <option value="No" selected>No</option>
                </select>
            </div>
            <div class="col-md-2">
                <label>Employee</label>
                <select class="form-control" [(ngModel)]="searchlModel.employeeId">
                    <option value="-1" selected>All</option>
                    <option *ngFor="let p of emps" [value]="p.employeeId">{{p.employeeName}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="From"> Month</label>
                <input type="month" [(ngModel)]="selectedDate" class="form-control" />
            </div>
            <div class="col-md-2">
                <label></label><br>
                <button class="btn btn-sm btn-info" (click)="onSearch()">Search</button>&nbsp;
                <button class="btn btn-sm btn-info" (click)="onReset()">Reset</button> &nbsp;
            </div>
            <div class="col-md-2">
                <label></label><br>
                <button class="btn btn-sm btn-info" (click)="downloadReport()">Download</button>
            </div>
        </div>
        <br>
        <table class="table table-striped table-sm table-responsive table-condensed" *ngIf="employees.length>0">
            <thead class="thead-light">
                <tr>
                    <th scope="col">
                        <button class="btn btn-sm btn-success">F</button>
                    </th>
                    <th scope="col">
                        <button class="btn btn-sm btn-warning">H</button>
                    </th>
                    <th scope="col">
                        <button class="btn btn-sm btn-info">PL</button>
                    </th>
                    <th scope="col">
                        <button class="btn btn-sm btn-danger">A</button>
                    </th>
                    <th scope="col">
                        <button class="btn btn-sm btn-secondary">WD</button>
                    </th>
                    <th scope="col" *DataCustomAclUsers="'Admin:mahendran:santhanam'">BasicSal</th>
                    <th scope="col" *DataCustomAclUsers="'Admin:mahendran:santhanam'">Incentive</th>
                    <th scope="col" *DataCustomAclUsers="'Admin:mahendran:santhanam'">Salary</th>
                    <th scope="col">Name</th>
                    <th scope="col" *ngFor="let u1 of gethelperAtted(employees[0])">
                        <span>
                            {{u1.dateValue | date:'MMM'}}<br>
                            {{u1.dateValue | date:'EEE'}}<br>
                            {{u1.dateValue | date:'dd'}}
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody *ngFor="let u of employees">
                <tr>
                    <td>
                        <button class="btn btn-sm btn-success">{{gethelperAttedTotal(u)[0]}}</button>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-warning">{{gethelperAttedTotal(u)[1]}}</button>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-info">{{gethelperAttedTotal(u)[2]}}</button>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-danger">{{gethelperAttedTotal(u)[3]}}</button>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-secondary">{{gethelperAttedTotal(u)[4]}}</button>
                    </td>
                    <td *DataCustomAclUsers="'Admin:mahendran:santhanam'">
                        {{gethelperAttedTotal(u)[5]}}
                    </td>
                    <td *DataCustomAclUsers="'Admin:mahendran:santhanam'">
                        {{gethelperAttedTotal(u)[6]}}
                    </td>
                    <td *DataCustomAclUsers="'Admin:mahendran:santhanam'">
                        {{gethelperAttedTotal(u)[7]}}
                    </td>
                    <td>{{u}}</td>
                    <td *ngFor="let u1 of gethelperAtted(u)">
                        <button (click)="OnAttendance(u,u1)" [ngClass]="{ 
                            'btn-success': u1.checkIn ==1,
                            'btn-warning': u1.checkIn==2,
                            'btn-info': u1.checkIn==3,
                            'btn-danger': !u1.checkIn||u1.checkIn==4
                    }" class="btn btn-sm">{{displayText(u1)}}</button>
                    </td>
                </tr>
                <tr *ngIf="selEmp==u">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td *DataCustomAclUsers="'Admin:mahendran:santhanam'"></td>
                    <td *DataCustomAclUsers="'Admin:mahendran:santhanam'"></td>
                    <td *DataCustomAclUsers="'Admin:mahendran:santhanam'"></td>
                    <td></td>
                    <td colspan="210">
                        <span>
                            {{editgrnData.entryDate | date:'dd-MM-yyyy EEE'}} :
                        </span>
                        <span *DataCustomAclOnly="'Admin'">
                            <button (click)="saveAttendance(1)" class="btn btn-sm btn-success">F</button>&nbsp;
                            <button (click)="saveAttendance(2)" class="btn btn-sm btn-warning">H</button>&nbsp;
                            <button (click)="saveAttendance(3)" class="btn btn-sm btn-info">PL</button>&nbsp;
                            <button (click)="saveAttendance(4)" class="btn btn-sm btn-danger">A</button>
                        </span>
                        <span>
                            Last Updated: {{editgrnData.reason}}
                        </span>
                    </td>
                </tr>
                <tr *DataCustomAclUsers="'Admin:mahendran:santhanam'">
                    <td colspan="210" *ngIf="selEmp==u">
                        <form [formGroup]="billAddEditForm" (ngSubmit)="onBillSubmit()">
                            <div class="form-row">
                                <div class="col-md-2">
                                    <label for="category">Category</label>
                                    <select formControlName="category" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
                                        <option value="" selected disabled>Choose</option>
                                        <option value="Salary">Salary</option>
                                        <option value="SalaryAdvance">SalaryAdvance</option>
                                    </select>
                                    <div *ngIf="submitted && f.category.errors" class="invalid-feedback">
                                        <div *ngIf="f.category.errors.required">Category is required</div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <label for="amount">Amount</label>
                                    <input type="number" formControlName="amount" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.amount.errors }" />
                                    <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                                        <div *ngIf="f.amount.errors.required">Amount is required</div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <label for="entryDate">Expense Date</label>
                                    <input type="date" formControlName="entryDate" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.entryDate.errors }" />
                                    <div *ngIf="submitted && f.entryDate.errors" class="invalid-feedback">
                                        <div *ngIf="f.entryDate.errors.required">Expense Date is required</div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <label for="reason">Reason</label>
                                    <input type="text" formControlName="reason" class="form-control" maxlength="200"
                                        [ngClass]="{ 'is-invalid': submitted && f.reason.errors }" />
                                    <div *ngIf="submitted && f.reason.errors" class="invalid-feedback">
                                        <div *ngIf="f.reason.errors.required">Reason is required</div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <label>&nbsp;</label><br>
                                    <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Add Expense
                                    </button>
                                </div>
                            </div>
                        </form>
                        <br>
                        <div class="row">
                            <div class="col-4">
                                <table class="table table-striped table-sm table-responsive table-condensed">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">Paid To</th>
                                            <th scope="col">Year</th>
                                            <th scope="col">Month</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let u of getexpensesSum(u)">
                                            <td>{{u.paidTo}}</td>
                                            <td>{{u.years}}</td>
                                            <td>{{u.expenseId}}</td>
                                            <td>{{u.category}}</td>
                                            <td>{{u.amount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-6">
                                <table class="table table-striped table-sm table-responsive table-condensed">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">Created</th>
                                            <th scope="col">Expense Date</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Paid To</th>
                                            <th scope="col">Reason</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let u of getexpenses(u)">
                                            <td>{{u.createdDate | date: 'dd/MM/yyyy'}}</td>
                                            <td>{{u.entryDate | date: 'dd/MM/yyyy'}}</td>
                                            <td>{{u.amount}}</td>
                                            <td>{{u.category}}</td>
                                            <td>{{u.paidTo}}</td>
                                            <td>{{u.reason}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>