import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { epiprnModel } from '../models';
import { AuthenticationService } from '../_services/authentication.service';
import { RayarApiService } from '../_services/rayar-api.service';
@Component({
  selector: 'app-epiprnview',
  templateUrl: './epiprnview.component.html',
  styleUrls: ['./epiprnview.component.css']
})
export class EpiprnviewComponent implements OnInit {

  loading = false;
  error = '';
  search: epiprnModel = new epiprnModel();

  customers: epiprnModel[] = [];

  constructor(private authenticationService: AuthenticationService,
    private api: RayarApiService) {
    this.search.epiType = "GT";
    this.search.status = "";
    this.search.page = 0;

  }

  ngOnInit(): void {
  }

  selectChangeHandler(event: any) {
    this.search.date1 = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.search.date2 = event.target.value;
  }

  onpage(next: boolean) {
    if (next) {
      this.getepiprn(this.search.page + 1);
    }
    else {
      if (this.search.page >= 1)
        this.getepiprn(this.search.page - 1);
    }
  }

  getepiprn(p: number) {
    this.search.page = p;
    this.api.getepiprnView(this.search).subscribe(result => {
      this.customers = result;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }
}
