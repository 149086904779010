<div class="row">
    <div class="col">
        <div class="form-row">
            <div class="col-md-2">
                <label for="companyName">Company</label>
                <select class="form-control" [(ngModel)]="search.companyName" (change)="selectChangeHandler3($event)">
                    <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="accBalance">Margin</label>
                <select class="form-control" [(ngModel)]="search.margin">
                    <option value="" selected>All</option>
                    <option value="NotSet">NotSet</option>
                    <option *ngFor="let p of margins" [value]="p">{{p}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label></label>
                <div>
                    <button class="btn btn-outline-primary" (click)="onSearch()">Search</button>
                </div>
            </div>
        </div>
        <br>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Account</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Company</th>
                    <th scope="col">Margin</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of custmars">
                    <td>{{u.accountName}}</td>
                    <td>{{u.customerName}}</td>
                    <td>{{u.companyName}}</td>
                    <td>{{u.margin}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>