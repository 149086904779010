import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CollectionModel, companyModel, epiprnModel, PendingBills } from '../models';
import { AuthenticationService } from '../_services/authentication.service';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-epiprn',
  templateUrl: './epiprn.component.html',
  styleUrls: ['./epiprn.component.css']
})
export class EpiprnComponent implements OnInit {
  loading = false;
  error = '';
  search: epiprnModel = new epiprnModel();
  pagenumber = 0;
  customers: epiprnModel[] = [];
  @ViewChild('inputFile') inputFile: ElementRef;

  constructor(private authenticationService: AuthenticationService,
    private api: RayarApiService) {
    this.search.epiType = "GT";
    this.getepiprn(0);
  }

  ngOnInit(): void {
  }

  onpage(next: boolean) {
    if (next) {
      this.getepiprn(this.pagenumber + 1);
    }
    else {
      if (this.pagenumber >= 1)
        this.getepiprn(this.pagenumber - 1);
    }
  }

  pagenumber1 = 1;

  onGoto() {
    if (this.pagenumber1 > 0) {
      this.getepiprn(this.pagenumber1 - 1);
    }
  }

  upload(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.api.uploadFile(file, 'UploadEpiPrn/' + this.search.epiType).subscribe(
        data => {
          if (data) {
            switch (data.type) {
              case HttpEventType.UploadProgress:
                break;
              case HttpEventType.Response:
                this.inputFile.nativeElement.value = '';
                this.error = "Uploaded Sucessfully";
                this.getepiprn(0);
                break;
            }
          }
        },
        error => {
          this.inputFile.nativeElement.value = '';
        }
      );
    }
  }

  getTotal() {
    var r = 0;
    this.customers.forEach(x => {
      r = r + x.amount;
    });
    return Math.round(r * 100) / 100;
  }

  getepiprn(p: number) {
    this.error = "";
    this.pagenumber = p;
    this.error = '';
    this.search.page = p;
    this.api.getepiprn(this.search).subscribe(result => {
      this.customers = result.result;
      this.bills = [];
      if (result.result.length > 0) {
        if (result.result1 && result.result1.length > 0)
          this.srcCustomerName = result.result1[0].customerName;
        else
          this.srcCustomerName = result.result[0].outlet;
      }
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  srcBillValue?: number;
  srcCustomerName: string = "";
  srcBillNumber: string = "";
  srcCompany: string = "GTEpigamia";
  bills: PendingBills[] = [];
  companys: companyModel[];

  onCollBillSearch1() {
    this.error = "";
    var b = 0;
    if (!this.srcBillValue) b = 0; else
      b = parseInt(this.srcBillValue.toString())
    this.api.getCustomerSearch(this.srcCustomerName, this.srcBillNumber, b, this.srcCompany).subscribe(result => {
      this.bills = result;
      this.bills.forEach(x => {
        x.billValue1 = 0;
        x.discount = 0;
        x.displayDiscount = 0;
        x.claimDocCount = 0;
        x.shortage = 0;
        x.damage = 0;
        x.damageIrNumber = ""
      });
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  ismutiCus: boolean = false;
  selectedBills: PendingBills[] = [];
  billNums: string[] = [];

  submitted = false;
  collectionlModel = new CollectionModel();
  getSummarry() {
    let map = new Map<string, number>();
    var da: string[] = [];
    var d: string[] = [];
    var isTotalError = false;
    this.ismutiCus = false;
    this.selectedBills = [];
    this.bills.forEach(x => {
      if (x.damage > 0) {
        if (x.damageIrNumber) {
          if (this.billNums.includes(x.damageIrNumber)) {
            x.isnotvalidir = false;
            if (map.has(x.damageIrNumber)) {
              var v = map.get(x.damageIrNumber);
              map.delete(x.damageIrNumber)
              map.set(x.damageIrNumber, v + x.damage);
            } else {
              map.set(x.damageIrNumber, x.damage);
            }
          } else {
            x.isnotvalidir = true;
          }
        } else {
          x.isnotvalidir = true;
        }
      } else {
        x.isnotvalidir = false;
        x.damageIrNumber = ""
      }
      if (x.billValue1 > 0 || x.damage > 0 || x.discount > 0 || x.shortage > 0 || x.displayDiscount > 0) {
        d.push(x.customerId + x.companyName);
        this.selectedBills.push(x);
        if (!isTotalError)
          isTotalError = x.billValue1 + x.discount + x.displayDiscount + x.damage + x.shortage - x.balance > 0;
      }
    });
    if (d.length == 0) {
      return da;
    }
    if (d.map(item => item).filter((value, index, self) => self.indexOf(value) === index).length > 1) {
      da.push("Please Select Same Customer and company Bills");
      this.ismutiCus = true;
      return da;
    }
    da.push("PRN - SaleReturn : " + this.customers[0].saleReturnNo);
    da.push("PRN Amount Total : " + this.getTotal());
    da.push('Customer : ' + this.selectedBills[0].customerName);
    da.push("Company : " + this.selectedBills[0].companyName);

    if (isTotalError) {
      da.push("Invalid Total:Collection>BillValue");
      return da;
    }
    var b1 = 0;
    var b2 = 0;
    var b3 = 0;
    var b4 = 0;
    var b5 = 0;
    var b6 = 0;
    this.bills.forEach(x => {
      b1 = b1 + x.billValue1;
      b2 = b2 + x.damage;
      b3 = b3 + x.discount;
      b4 = b4 + x.shortage;
      b6 = b6 + x.displayDiscount;
      if (x.billValue1 > 0 || x.damage > 0 || x.discount > 0 || x.displayDiscount > 0 || x.shortage > 0) {
        var ir = "";
        if (x.damage > 0) {
          if (x.damageIrNumber && !x.isnotvalidir) {
            ir = "IR-" + x.damageIrNumber;
          } else {
            ir = "IR-Missing";
          }
        }
        var dd = "";
        if (x.displayDiscount > 0) {
          if (x.claimDocCount > 0) {
            dd = "" + x.claimDocCount;
          } else {
            dd = "Missing";
          }
        }
        da.push("Bill : " + x.billNumber + "; Claim Discount : " + x.displayDiscount + "; " + ir);
      }
    });
    b5 = b1 + b2 + b3 + b4 + b6;
    da.push("Total : " + b5 + ";  Claim Discount : " + b6);
    for (const [key, value] of map) {
      da.push('IR-' + key + ' = ' + value);
    }
    return da;
  }

  getTheColor(status: string) {
    if (status.includes("Missing")) {
      return "yellow";
    } else {
      return "white";
    }
  }

  onNotUsed() {
    if (confirm("Are you sure to mark as Not Used")) {
      this.api.onMarkNotUsedPRN(this.customers).subscribe(result => {
        this.submitted = false;
        this.loading = false;
        this.collectionlModel = new CollectionModel();
        this.bills = [];
        this.getepiprn(this.pagenumber);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    }
  }

  onClickAddCol() {
    this.submitted = true;
    var d: number[] = [];
    var isTotalError = false;
    var isdamageNumMiss = false;
    var isclaimDocMising = false;
    this.bills.forEach(x => {
      if (x.damage > 0) {
        if (x.damageIrNumber && !x.isnotvalidir) {
          x.damageIrNumber = x.damageIrNumber.toString()
        } else {
          isdamageNumMiss = true;
        }
      } else {
        x.damageIrNumber = ""
      }
      if (x.displayDiscount > 0) {
        if (x.claimDocCount > 0) {
        } else {
          isclaimDocMising = true;
        }
      }
      if (x.billValue1 > 0 || x.damage > 0 || x.discount > 0 || x.displayDiscount > 0 || x.shortage > 0) {
        d.push(x.customerId);
        if (!isTotalError)
          isTotalError = x.billValue1 + x.discount + x.displayDiscount + x.damage + x.shortage - x.balance > 0;
      }
    });
    if (isdamageNumMiss || isTotalError || d.length == 0 || d.map(item => item).filter((value, index, self) => self.indexOf(value) === index).length > 1) return;
    this.collectionlModel.entryDate = new Date();
    this.collectionlModel.collectionValue = 0;
    this.collectionlModel.mode = "Cash";
    this.collectionlModel.collectedBy = "Neft";
    this.collectionlModel.personId = 103;
    var p = this.customers[0].saleReturnNo;
    this.collectionlModel.remarks = "PRN" + p;
    this.loading = true;
    this.api.onAddCollectionPRN(this.collectionlModel, this.bills, this.customers).subscribe(result => {
      this.submitted = false;
      this.loading = false;
      this.collectionlModel = new CollectionModel();
      this.bills = [];
      this.getepiprn(this.pagenumber);
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

}
