<div class="row">
    <div class="col">
        <div class="form-row">
            <div class="col-md-1">
                <label for="accBalance">Account</label>
                <select class="form-control" [(ngModel)]="searchClaim.accountId">
                    <option value="-1">All</option>
                    <option value="0">General</option>
                    <option *ngFor="let p of persons" [value]="p.accountId">{{p.accountName}}</option>
                </select>
            </div>
            <div class="col-md-1">
                <label for="Company">Company</label>
                <select class="form-control" [(ngModel)]="searchClaim.companyName">
                    <option value="">All</option>
                    <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="customerName">Customer/Remarks/Bill/Description</label>
                <input type="text" class="form-control" [(ngModel)]="searchClaim.customerName">
            </div>
            <div class="col-lg-2 col-md-1">
                <label>Claim Posted From</label>
                <input type="date" [ngModel]="searchClaim.fromDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler($event)" class="form-control" />
            </div>
            <div class="col-lg-2 col-md-1">
                <label>Claim Posted To</label>
                <input type="date" [ngModel]="searchClaim.toDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler1($event)" class="form-control" />
            </div>

            <div class="col-lg-2 col-md-2">
                <label>Claim Status</label>&nbsp;<br>
                <input type="radio" [(ngModel)]="rec" value="true" /> Complete
                <input type="radio" [(ngModel)]="rec" value="false" />InProgress
                <input type="radio" [(ngModel)]="rec" value="both" /> Both
            </div>
        </div> <br>
        <div class="form-row">
            <div class="col-lg-1 col-md-2">
                <label></label>&nbsp;
                <button class="btn btn-sm btn-info" (click)="getClaimFollowup(0)">Search</button> &nbsp;
            </div>
            <div class="col-lg-1 col-md-2">
                <label> </label>&nbsp;
                <button class="btn btn-sm btn-info" (click)="downloadExpenseReport()">Download</button>
            </div>
        </div>
        <br>
        <div class="form-row">
            <nav aria-label="...">
                <ul class="pagination justify-content-end">
                    <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a></li>
                    <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                    <li class="page-item">
                        <span class="page-link">Page: {{searchClaim.page+1}}</span>
                    </li>
                </ul>
            </nav>
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">Claim<br>Status</th>
                        <th scope="col">Claim<br>Approved</th>
                        <th scope="col">Claim<br>Discount</th>
                        <th scope="col">Claim<br>Posted Date</th>
                        <th scope="col">Bill#</th>
                        <th scope="col">BillValue</th>
                        <th scope="col">BillDate</th>
                        <th scope="col">Company</th>
                        <th scope="col">Account</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Transaction Id</th>
                        <th scope="col">Transaction Date</th>
                        <th scope="col">Description</th>
                    </tr>
                <tbody *ngFor="let u of custReportAll">
                    <tr>
                        <td scope="row">
                            <button class="btn btn-sm btn-info"
                                (click)="showDetail(u)">{{u.showDetail?'Hide':'Show'}}</button>
                        </td>
                        <td scope="row">
                            <button class="btn btn-sm btn-info" (click)="downloadBills(u)">
                                <i class="fa fa-download"></i></button>
                        </td>
                        <td scope="row">
                            <button class="btn btn-sm btn-info" *ngIf="!u.isEdit"
                                (click)="editGrn(u)">Edit</button>&nbsp;
                            <button class="btn btn-sm btn-info" *ngIf="u.isEdit"
                                (click)="saveGrn(u)">Save</button>&nbsp;
                            <button class="btn btn-sm btn-info" *ngIf="u.isEdit" (click)="cancelGrn(u)">Cancel</button>
                        </td>
                        <td>
                            <div *ngIf="!u.isEdit">{{u.received?"Complete":"InProgress"}}</div>
                            <select *ngIf="u.isEdit" class="form-control" [(ngModel)]="editgrnData.received">
                                <option disabled selected value="">choose</option>
                                <option [value]="false">InProgress</option>
                                <option [value]="true">Complete</option>
                            </select>
                        </td>
                        <td>
                            <div *ngIf="!u.isEdit">{{u.claimValue }}</div>
                            <input type="number" min="0" *ngIf="u.isEdit" [(ngModel)]="editgrnData.claimValue"
                                class="form-control" />
                        </td>
                        <td>{{u.collectionValue}}</td>
                        <td>{{u.createdDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.billNumber}}</td>
                        <td>{{u.billValue}}</td>
                        <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.companyName}}</td>
                        <td>{{u.accountName}}</td>
                        <td class="elip">{{u.customerName}}</td>
                        <td>{{u.transactionId}}</td>
                        <td>{{u.valueDate| date: 'dd/MM/yyyy'}}</td>
                        <td class="elip">{{u.description}}</td>
                    </tr>
                    <tr *ngIf="u.showDetail">
                        <td colspan="17">
                            <div class="row">
                                <div class="col">
                                    <table class="table table-striped table-sm table-responsive table-condensed">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Notes</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">User</th>
                                                <th scope="col" *DataCustomAcl="'Admin'">Internal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><button class="btn btn-sm btn-info" (click)="saveNoteTrack()">Add
                                                        Note</button></td>
                                                <td colspan="3">
                                                    <input type="text" class="form-control form-control-sm"
                                                        [(ngModel)]="addNotes.notes">
                                                </td>
                                                <td *DataCustomAcl="'Admin'">
                                                    <input type="checkbox" [(ngModel)]="addNotes.internal">
                                                </td>
                                            </tr>
                                            <tr *ngFor="let u of suppliedDocs">
                                                <th scope="row">
                                                    <button *DataCustomAcl="'Admin'" class="btn btn-sm btn-info"
                                                        (click)="onDeleteDocument(u.noteTrackId)">Delete</button>
                                                </th>
                                                <td>{{u.notes}}</td>
                                                <td>{{u.enteredDate| date: 'dd/MM/yy hh:mm a'}}</td>
                                                <td>{{u.userName}}</td>
                                                <td *DataCustomAcl="'Admin'">{{u.internal}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>