<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-md-2">
                <label>Bill Number</label>
                <input class="form-control" type="text" [(ngModel)]="searchBillModel.billNumber" />
            </div>
            <div class="col-md-2">
                <label>Customer/Account</label>
                <input class="form-control" type="text" [(ngModel)]="searchBillModel.customerName" />
            </div>
            <div class="col-md-2">
                <label>Bill AssignedTo</label>
                <input class="form-control" type="text" [(ngModel)]="searchBillModel.personName" />
            </div>
            <div class="col-md-2">
                <label for="collectedBy">Collected By</label>
                <select class="form-control" [(ngModel)]="searchBillModel.personId">
                    <option value="0" selected>All</option>
                    <option *ngFor="let p of persons1" [value]="p.personId">{{p.name}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label></label>
                <div>
                    <button class="btn btn-outline-primary" (click)="onSearchPerson()">Search</button>
                </div>
            </div>
        </div>
        <br>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Bounced Check Detail</th>
                    <th scope="col">Cheque#</th>
                    <th scope="col">Cheque Date</th>
                    <th scope="col">Collected</th>
                    <th scope="col">remarks</th>
                    <th scope="col">Collected Date</th>
                    <th scope="col">CollectedBy</th>
                    <th scope="col">Assign Person</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Bill#</th>
                    <th scope="col">Bill Value</th>
                    <th scope="col">Bill Date</th>
                </tr>
            </thead>
            <tbody *ngFor="let u of colls">
                <tr>
                    <td>
                        <i class="fa fa-angle-down" (click)="showDetail(u)"></i>
                    </td>
                    <td>{{u.bouncedCheckDetail}}</td>
                    <td>{{u.chequeNumber}}</td>
                    <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.collectionValue}}</td>
                    <td>{{u.remarks}}</td>
                    <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.collectedBy}}</td>
                    <td>{{u.personName}}</td>
                    <td>{{u.customerName}}</td>
                    <td>{{u.billNumber}}</td>
                    <td>{{u.billValue}}</td>
                    <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                </tr>
                <tr *ngIf="u.showDetail">
                    <td colspan="100">
                        <div class="row">
                            <div class="col">
                                All Collections For Bill Number : {{u.billNumber}}
                                <table class="table table-striped table-sm table-responsive table-condensed">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">CreatedBy</th>
                                            <th scope="col">CreatedDate</th>
                                            <th scope="col">Collection Date</th>
                                            <th scope="col">Collection Value</th>
                                            <th scope="col">Mode</th>
                                            <th scope="col">CollectedBy</th>
                                            <th scope="col">Remarks</th>
                                            <th scope="col">ChequeDate</th>
                                            <th scope="col">ChequeNumber</th>
                                            <th scope="col">Cheque Credited Date</th>
                                            <th scope="col">Bounced</th>
                                            <th scope="col">Bounced FollowedUp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let u of colls1">
                                            <td>{{u.createdBy}}</td>
                                            <td>{{u.createdDate| date: 'dd/MM/yyyy'}}</td>
                                            <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                                            <td>{{u.collectionValue}}</td>
                                            <td>{{u.mode}}</td>
                                            <td>{{u.collectedBy}}</td>
                                            <td>{{u.remarks}}</td>
                                            <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
                                            <td>{{u.chequeNumber}}</td>
                                            <td>{{u.creditedDate| date: 'dd/MM/yyyy'}}</td>
                                            <td>{{u.bounced}}</td>
                                            <td>{{u.bouncedFollowedUp}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button class="btn btn-sm btn-info" (click)="onCollSubmit(u.collectionId)">Mark FollowedUp</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <table class="table table-striped table-sm table-responsive table-condensed">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">User</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><button class="btn btn-sm btn-info" (click)="saveNoteTrack()">Add
                                                    Note</button></td>
                                            <td colspan="3">
                                                <input type="text" class="form-control form-control-sm"
                                                    [(ngModel)]="addNotes.notes">
                                            </td>
                                        </tr>
                                        <tr *ngFor="let u of suppliedDocs">
                                            <td>{{u.userName}}</td>
                                            <td>{{u.enteredDate| date: 'dd/MM/yy hh:mm a'}}</td>
                                            <td>{{u.notes}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>