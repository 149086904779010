import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { companyModel, CustomerMarginModel } from '../models';

@Component({
  selector: 'app-customermargin',
  templateUrl: './customermargin.component.html',
  styleUrls: ['./customermargin.component.css']
})
export class CustomermarginComponent implements OnInit {
  searchlModel = new CustomerMarginModel();
  customers: CustomerMarginModel[] = [];
  customers1: CustomerMarginModel[] = [];
  companys: companyModel[] = [];
  loading = false;
  isAddCustomerClicked: boolean = false;
  error = '';
  submitted = false;

  constructor(private api: RayarApiService) {
    this.getcompany();
    this.searchlModel.page = 0;
  }

  ngOnInit(): void {

  }

  onEditCustomer(u: CustomerMarginModel) {
    this.isAddCustomerClicked = true;
    var n = new CustomerMarginModel();
    n.page = 0;
    n.customerId = u.customerId;
    this.api.getCustomerMargin(n).subscribe(result => {
      this.customers1 = result;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  UpdateMargin() {
    this.api.saveCustomerMargin(this.customers1).subscribe(result => { this.isAddCustomerClicked = false; this.onNameCustomerSearch(0); }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  getcompany() {
    this.api.getCompanys().subscribe(result => { this.companys = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  onCloseAddCust() {
    this.isAddCustomerClicked = false;
  }

  onNameCustomerSearch(p: number) {
    this.isAddCustomerClicked = false;
    this.searchlModel.page = p;
    this.api.getCustomerMargin(this.searchlModel).subscribe(result => { this.customers = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  onpage(next: boolean) {
    if (next) {
      this.onNameCustomerSearch(this.searchlModel.page + 1);
    }
    else {
      if (this.searchlModel.page >= 1)
        this.onNameCustomerSearch(this.searchlModel.page - 1);
    }
  }

  onCustSearchReset() {
    this.isAddCustomerClicked = false;
    this.searchlModel = new CustomerMarginModel();
    this.searchlModel.page = 0;
    this.customers = [];
  }

}
