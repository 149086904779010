<div class="card">
    <h4 class="card-header">Closing Review</h4>
    <div class="card-body">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <nav aria-label="...">
            <ul class="pagination justify-content-end">
                <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a></li>
                <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                <li class="page-item">
                    <span class="page-link">Page: {{pagenumber+1}}</span>
                </li>
            </ul>
        </nav>
        <div *ngIf="selectedBill.billId > 0">
            <button class="btn btn-outline-primary" *ngIf="selectedBill.balance==0" (click)="markPaid1()">Mark
                Paid</button>
            <div class="form-row">
                <div class="col-md-2">Bill Number: {{selectedBill.billNumber}}</div>
                <div class="col-md-2">Bill Date: {{selectedBill.billDate | date: 'dd/MM/yyyy'}}</div>
                <div class="col-md-2">Customer Name: {{selectedBill.customerName}}</div>
                <div class="col-md-2">Collected : {{selectedBill.colVal}}</div>
                <div class="col-md-2">Balance: {{selectedBill.balance}}</div>
                <div class="col-md-2">Billed: {{selectedBill.billValue}}</div>
                <div class="col-md-2">Company: {{selectedBill.companyName}}</div>
            </div>
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Received</th>
                        <th scope="col">Collection Date</th>
                        <th scope="col">Collection Value</th>
                        <th scope="col">Mode</th>
                        <th scope="col">Collected By</th>
                        <th scope="col">Remarks</th>
                        <th scope="col">Cheque Date</th>
                        <th scope="col">Cheque Number</th>
                        <th scope="col">Bounced Credited Date</th>
                        <th scope="col">Bounced</th>
                        <th scope="col">Bounced FollowedUp</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let u of colls">
                        <td>{{u.received}}</td>
                        <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.collectionValue}}</td>
                        <td>{{u.mode}}</td>
                        <td>{{u.collectedBy}}</td>
                        <td>{{u.remarks}}</td>
                        <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.chequeNumber}}</td>
                        <td>{{u.creditedDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.bounced}}</td>
                        <td>{{u.bouncedFollowedUp}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>