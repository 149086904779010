import { Component, OnInit } from '@angular/core';
import { accountModel, accountCompanyModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-accountcompany',
  templateUrl: './accountcompany.component.html',
  styleUrls: ['./accountcompany.component.css']
})
export class AccountcompanyComponent implements OnInit {
  accounts: accountModel[] = [];
  search: accountCompanyModel = new accountCompanyModel();
  accountCompanySetts: accountCompanyModel[] = [];

  loading = false;
  error = '';

  constructor(private api: RayarApiService) {
    this.getAccounts();
  }

  ngOnInit(): void {
  }

  getAccounts() {
    this.api.getAccounts().subscribe(result => {
      this.accounts = result;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  getAccountCompanySetting() {
    if (this.search.accountId && this.search.operation)
      this.api.getAccountCompanySetting(this.search.accountId, this.search.operation).subscribe(result => {
        this.accountCompanySetts = result;
        this.accountCompanySetts.forEach(x => { if (!x.vehicleName) x.vehicleName = "" });
        this.accountCompanySetts.sort((a, b) => a.vehicleName.localeCompare(b.vehicleName));
      },
        error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
  }

  UpdateAccountCompanySetting() {
    if (this.search.accountId && this.search.operation && this.accountCompanySetts.length > 0)
      this.api.updateAccountCompanySetting(this.search.accountId, this.search.operation, this.accountCompanySetts).subscribe(result => {
        this.accountCompanySetts = result;
      },
        error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
  }

  selectChangeHandler31(event: any) {
    this.accountCompanySetts = [];
  }

  gettext(f: string) {
    if (!f) return '';
    var escapedFind = ';'.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return f.replace(new RegExp(escapedFind, 'g'), ";\r\n");
  }

}
