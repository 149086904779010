import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { accountDetailHistoryModel, accountDetailModel, activityModel, chitDetailModel, chitModel, hrChitModel, TenantCollectionModel, TenantModel } from '../models1';

const pmourl = `${environment.apiUrl}/PersonalMgmt/`;

@Injectable({
  providedIn: 'root'
})
export class PmService {


  constructor(private http: HttpClient) { }

  getchits1() {
    return this.http.get<hrChitModel[]>(pmourl + 'Chits1');
  }

  AddEditChit1(b: hrChitModel) {
    return this.http.post<any>(pmourl + `AddEditChit1`, b);
  }

  getchits(chitStatus: number) {
    return this.http.get<chitModel[]>(pmourl + 'Chits');
  }

  getchitDetails(chitId: number) {
    return this.http.get<chitDetailModel[]>(pmourl + 'ChitDetails/' + chitId);
  }

  AddEditChit(b: chitModel) {
    return this.http.post<any>(pmourl + `AddEditChit`, b);
  }

  AddEditChitDetail(b: chitDetailModel) {
    return this.http.post<any>(pmourl + `AddEditChitDetail`, b);
  }

  MarkPaid(b: chitDetailModel) {
    return this.http.post<any>(pmourl + `MarkPaid`, b);
  }

  MarkcDelete(b: chitModel) {
    return this.http.post<any>(pmourl + `MarkCDelete`, b);
  }

  MarkCdDelete(b: chitDetailModel) {
    return this.http.post<any>(pmourl + `MarkCDDelete`, b);
  }

  MarkComplete(b: chitModel) {
    return this.http.post<any>(pmourl + `MarkComplete`, b);
  }

  getActivitys(s: string) {
    if (s)
      return this.http.get<activityModel[]>(pmourl + `Activitys/` + s);
    return this.http.get<activityModel[]>(pmourl + `Activitys`);
  }

  AddEditActivity(b: activityModel) {
    return this.http.post<any>(pmourl + `AddEditActivity`, b);
  }

  MarkInUse(b: activityModel) {
    return this.http.post<any>(pmourl + `MarkInUse`, b);
  }

  MarkADelete(b: activityModel) {
    return this.http.post<any>(pmourl + `MarkADelete`, b);
  }

  GetAccountDetails(b: accountDetailModel) {
    return this.http.post<accountDetailModel[]>(pmourl + `GetAccountDetail`, b);
  }

  GetAccountDetailHistory(b: number) {
    return this.http.get<accountDetailHistoryModel[]>(pmourl + `GetAccountDetailHistory/` + b);
  }

  MarkAcInActve(b: number) {
    return this.http.get<any>(pmourl + `MarkAcInActve/` + b);
  }

  SaveAccountDetail(b: accountDetailModel) {
    return this.http.post<any>(pmourl + `SaveAccountDetail`, b);
  }

  GetTenantCollection(b: number, y: number) {
    return this.http.get<TenantCollectionModel[]>(pmourl + 'GetTenantCollection/' + b + '/' + y);
  }

  GetTenants() {
    return this.http.get<TenantModel[]>(pmourl + 'GetTenants');
  }

  GetRentCollectHistory(id: number, p: number) {
    return this.http.get<TenantCollectionModel[]>(pmourl + 'RentCollectHistory/' + id + '/' + p);
  }

  AddTenantCollection(b: TenantCollectionModel) {
    return this.http.post<any>(pmourl + `AddTenantCollection`, b);
  }

  UpdateNotes(b: TenantCollectionModel) {
    return this.http.post<any>(pmourl + `UpdateNotes`, b);
  }

  MarkInActiveTentantCollection(u: number) {
    return this.http.get<any>(pmourl + 'MarkInActiveTentantCollection/' + u);
  }

  UpdateTentantState(u: number, s: number) {
    return this.http.get<any>(pmourl + 'UpdateTentantState/' + u + '/' + s);
  }

  AddEditTenant(b: TenantModel) {
    return this.http.post<any>(pmourl + `AddEditTenant`, b);
  }
}
