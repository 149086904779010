import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { CollectonPersonModel, accountModel } from '../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent implements OnInit {

  customers: accountModel[] = [];
  loading = false;
  isAddCustomerClicked: boolean = false;
  error = '';
  submitted = false;
  customerForm: FormGroup;
  get f() { return this.customerForm.controls; }
  searchCustomerName: string = "";
  addEditCustModel: accountModel = new accountModel();

  constructor(private formBuilder: FormBuilder, private api: RayarApiService) {
    this.getCollPesons();
  }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      accountName: ['', Validators.required],
      vendorCode: [''],
      email: ['']
    });
  }


  onEditCustomer(u: accountModel) {
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      accountName: u.accountName,
      vendorCode: u.vendorCode,
      email: u.email
    });
    this.addEditCustModel = u
  }

  onAddCustSubmit() {
    this.submitted = true;
    if (this.customerForm.invalid) return;
    this.loading = true;
    this.addEditCustModel.accountName = this.f.accountName.value;
    this.addEditCustModel.email = this.f.email.value;
    this.addEditCustModel.vendorCode = this.f.vendorCode.value;

    this.api.AddEditAccount(this.addEditCustModel)
      .subscribe(() => {
        this.submitted = false;
        this.loading = false;
        this.onCustSearchReset();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onCloseAddCust() {
    this.isAddCustomerClicked = false;
  }

  oAddCustomer() {
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      accountName: '',
      vendorCode: '',
      email: ''
    });
    this.addEditCustModel = new accountModel();
    this.addEditCustModel.accountId = 0;
    this.addEditCustModel.email = '';
    this.addEditCustModel.vendorCode = '';
  }

  onNameCustomerSearch() {
    this.isAddCustomerClicked = false;
    if (this.searchCustomerName) {
      this.api.getSearchAccount(this.searchCustomerName)
        .subscribe(result => { this.customers = result; }, error => {
          this.error = error;
          this.loading = false; console.error(error);
        });
    }
  }

  onCustSearchReset() {
    this.isAddCustomerClicked = false;
    this.searchCustomerName = "";
    this.getCollPesons();
  }

  getCollPesons() {
    this.api.getAccounts()
      .subscribe(result => { this.customers = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }
}
