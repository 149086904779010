import { Injectable } from '@angular/core';
import { MyMonitoringService } from './MyMonitoringService';
import { ErrorHandler } from '@angular/core';


@Injectable()
export class ErrorHandlerService extends ErrorHandler {

  constructor(private myMonitoringService: MyMonitoringService) {
    super();
  }

  handleError(error: Error) {
    this.myMonitoringService.logException(error); // Manually log exception
  }
}
