<div class="card">
    <div class="card-body">
        <div class="form-row">
            <div class="col-md-2">
                <label for="companyName">Company</label>
                <select class="form-control" (change)="selectChangeHandlerCompany($event)">
                    <option value="-1" selected>All</option>
                    <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="accountName">Account</label>
                <select class="form-control" (change)="selectChangeHandlerAccount($event)">
                    <option value="-1" selected>All</option>
                    <option value="General">General</option>
                    <option *ngFor="let p of accounts" [value]="p.accountName">{{p.accountName}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="collPerson">Person</label>
                <select class="form-control" (change)="selectChangeHandlerPerson($event)">
                    <option value="-1" selected>All</option>
                    <option value="NotAssigned">NotAssigned</option>
                    <option *ngFor="let p of CollPersons" [value]="p.name">{{p.name}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="collPerson">BillAge</label>
                From <input class="form-control" type="number" [(ngModel)]="billAgeF">
                To <input class="form-control" type="number" [(ngModel)]="billAgeT">
            </div>
            <div class="col-md-2">
                <label>Customer/Bill</label>
                <input type="text" class="form-control" (keyup)="onKey1($event)" [(ngModel)]="selectedcustomerName" />
            </div>
        </div>
        <br>
        <div class="form-row">
            <div class="col">
                <div class="form-row">
                    <div class="col-md-12">
                        <div class="form-row header1">
                            <div class="col">#</div>
                            <div class="col">Bill#</div>
                            <div class="col">BillStatus</div>
                            <div class="col">BillDate</div>
                            <div class="col">Amount</div>
                            <div class="col">Collected</div>
                            <div class="col">Balance</div>
                            <div class="col">Company</div>
                            <div class="col">Account</div>
                            <div class="col">Customer</div>
                            <div class="col">Person</div>
                            <div class="col">GRN</div>
                            <div class="col">GRNRemarks</div>
                        </div>
                        <div *ngFor="let u of getData()">
                            <div class="form-row custrow">
                                <div class="col">{{u.billDays}}</div>
                                <div class="col">{{u.billNumber}}</div>
                                <div class="col">{{u.billStatus}}</div>
                                <div class="col">{{u.billDate | date:'dd/MM/yy'}}</div>
                                <div class="col">{{u.billValue}}</div>
                                <div class="col">{{u.colVal}}</div>
                                <div class="col">{{u.balance}}</div>
                                <div class="col">{{u.companyName}}</div>
                                <div class="col">{{u.accountName}}</div>
                                <div class="col elip">{{u.customerName}}</div>
                                <div class="col elip">{{u.salesPersonName}}</div>
                                <div class="col elip">{{u.grnNumber}}</div>
                                <div class="col elip">{{u.grnRemarks}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>