<div class="row">
  <div class="col">
    <div class="row">
      <div class="col-md-2">
        <label for="companyName">Company</label>
        <select class="form-control" [(ngModel)]="selectedcompany">
          <option value="-1" selected>All</option>
          <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
        </select>
      </div>
      <div class="col-md-2">
        <label for="companyName">SalePerson</label>
        <select class="form-control" [(ngModel)]="selectedSales">
          <option value="-1" selected>All</option>
          <option value="NotAssigned">NotAssigned</option>
          <option *ngFor="let p of CollPersons" [value]="p.name">{{getEmployeeName(p.appUserId)}}-{{p.name}}</option>
        </select> 
      </div>
      <div class="col-md-2">
        <label for="appUserId">Employee</label>
        <select class="form-control" [(ngModel)]="selectedEmployee">
          <option value="-1" selected>All</option>
          <option *ngFor="let p of appusers" [value]="p.id">{{p.userName}}</option>
        </select>

      </div>
      <div class="col-md-2">
        <label for="customer">Customer</label>
        <input type="text" [(ngModel)]="srcCustomer" class="form-control">
      </div>
      <div class="col-md-2">
        <label for="status">Status</label><br>
        <input type="radio" name="status" [(ngModel)]="srcCustomerStatus" value="0">ALL
        <input type="radio" name="status" [(ngModel)]="srcCustomerStatus" value="C">Closed
      </div>
      <div class="col-md-2">
        <label for="FollowUp">FollowUp</label><br>
        <input type="radio" name="FollowUp" [(ngModel)]="srcCustomerFUp" value="0">ALL
        <input type="radio" name="FollowUp" [(ngModel)]="srcCustomerFUp" value="1">Yes
        <input type="radio" name="FollowUp" [(ngModel)]="srcCustomerFUp" value="2">No
      </div>
      <div class="col-md-2">
        <input type="checkbox" [(ngModel)]="FollowUp" />&nbsp;
        <label for="From">FollowUpDate</label>
        <input type="date" [(ngModel)]="selectedDate" class="form-control" />
      </div>
      <div class="w-100"></div>
      <div class="col-md-2"> <label>&nbsp;</label><br>
        <button class="btn btn-outline-primary" (click)="search()">Search</button><br>
      </div>
      <div class="col-md-2"> <label>&nbsp;</label><br>
        <button class="btn btn-outline-primary" (click)="download()">Download</button><br>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-12">
        <table class="table table-striped table-sm  table-responsive table-condensed">
          <thead class="thead-dark">
            <tr>
              <th scope="col"></th>
              <th scope="col"></th>
              <th (click)="sort21('5')" scope="col">FollowUp</th>
              <th scope="col">Customer</th>
              <th scope="col">Status</th>
              <th scope="col">Name</th>
              <th scope="col">Phone</th>
              <th scope="col">GST</th>
              <th scope="col">21+<br>Count</th>
              <th (click)="sort21('1')" scope="col">21+<br> Bal&nbsp;<i class="fa fa-long-arrow-down"></i></th>
              <th scope="col">42+ <br>Count</th>
              <th (click)="sort21('2')" scope="col">42+ <br>Bal&nbsp;<i class="fa fa-long-arrow-down"></i></th>
              <th scope="col">63+ <br>Count</th>
              <th (click)="sort21('3')" scope="col">63+<br> Bal&nbsp;<i class="fa fa-long-arrow-down"></i></th>
              <th (click)="sort21('4')" scope="col">Total<br> 21+ Bal&nbsp;<i class="fa fa-long-arrow-down"></i>
              </th>
              <th (click)="sort21('0')" scope="col">Total <br>Bal&nbsp;<i class="fa fa-long-arrow-down"></i>
              </th>
              <th scope="col">Total<br> Count</th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-warning">
              <td></td>
              <td></td>
              <td></td>
              <td>{{total11.companyName}}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{total11.billcount1}}</td>
              <td>{{total11.billAmount1|currency:'INR': 'symbol' : '1.0-0'}}</td>
              <td>{{total11.billcount2}}</td>
              <td>{{total11.billAmount2|currency:'INR': 'symbol' : '1.0-0'}}</td>
              <td>{{total11.billcount3}}</td>
              <td>{{total11.billAmount3|currency:'INR': 'symbol' : '1.0-0'}}</td>
              <td>{{total11.billAmount4|currency:'INR': 'symbol' : '1.0-0'}}</td>
              <td>{{total11.billAmount|currency:'INR': 'symbol' : '1.0-0'}}</td>
              <td>{{total11.billcount}}</td>
            </tr>
          </tbody>
          <tbody *ngFor="let u of companys21">
            <tr>
              <td>
                <button class="btn btn-sm btn-info" (click)="showDetail(u)">{{u.showDetail?'Hide':'Show'}}</button>
              </td>
              <td>
                <div *DataCustomAclOnly="'Admin'">
                  <button class="btn btn-sm btn-info" *ngIf="!(u.custStatus && u.custStatus=='C')"
                    (click)="markStatus(u,'C')">MarkClose</button>
                  <button class="btn btn-sm btn-info" *ngIf="(u.custStatus && u.custStatus=='C')"
                    (click)="markStatus(u,'A')">MarkActive</button>
                </div>
              </td>
              <td>{{getFDate(u.companyId) |date:'dd/MM/yy'}}</td>
              <td>{{u.companyName}}</td>
              <td>{{u.custStatus}}</td>
              <td class="elip">
                <span *DataCustomAclOnly="'Employee:Company'">{{u.contactName}}</span>
                <input *DataCustomAclOnly="'Sales:Admin'" (blur)="onBlurEvent(u)" type="text"
                  [(ngModel)]="u.contactName" class="form-control form-control-sm" />
              </td>
              <td class="elip">
                <span *DataCustomAclOnly="'Employee:Company'">{{u.contactPhone}}</span>
                <input *DataCustomAclOnly="'Sales:Admin'" (blur)="onBlurEvent(u)" type="text"
                  [(ngModel)]="u.contactPhone" class="form-control form-control-sm" />
              </td>
              <td class="elip">
                <span *DataCustomAclOnly="'Employee:Company'">{{u.gst}}</span>
                <input *DataCustomAclOnly="'Sales:Admin'" (blur)="onBlurEvent(u)" type="text" [(ngModel)]="u.gst"
                  class="form-control form-control-sm" />
              </td>
              <td>{{u.billcount1}}</td>
              <td>{{u.billAmount1|currency:'INR': 'symbol' : '1.0-0'}}</td>
              <td>{{u.billcount2}}</td>
              <td>{{u.billAmount2|currency:'INR': 'symbol' : '1.0-0'}}</td>
              <td>{{u.billcount3}}</td>
              <td>{{u.billAmount3|currency:'INR': 'symbol' : '1.0-0'}}</td>
              <td>{{u.billAmount1+u.billAmount2+u.billAmount3|currency:'INR': 'symbol' : '1.0-0'}}</td>
              <td>{{u.billAmount|currency:'INR': 'symbol' : '1.0-0'}}</td>
              <td>{{u.billcount}}</td>
            </tr>
            <tr *ngIf="u.showDetail">
              <td colspan="100">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label for="companyName">Company</label>
                        <select class="form-control" [(ngModel)]="selectedcompany1">
                          <option value="-1" selected>All</option>
                          <option *ngFor="let p of custcompanys" [value]="p">{{p}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
            <tr>
              <td colspan="2">
                <textarea rows="2" class="form-control form-control-sm" [(ngModel)]="addNotes.notes"></textarea>
              </td>
              <td>
                <button class="btn btn-sm btn-info" (click)="saveNoteTrack1(u.companyId)">Add
                  Note</button>
              </td>
              <td><input type="date" class="form-control form-control-sm" [(ngModel)]="addNotes.customerFolDate"></td>
              <td>
                <button class="btn btn-sm btn-info" (click)="saveNoteTrack2(u.companyId)">Add
                  FollowUp</button>
              </td>
              <td>
                <button class="btn btn-sm btn-info" *ngIf="getFDate(u.companyId)!=null"
                  (click)="saveNoteTrack3(u.companyId)">Remove
                  FollowUp</button>
              </td>
            </tr>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <table class="table table-striped table-sm  table-responsive table-condensed">
          <thead class="thead-dark">
            <tr>
              <th scope="col"></th>
              <th scope="col">Note</th>
              <th scope="col">Age</th>
              <th scope="col">BillDate</th>
              <th scope="col">Company</th>
              <th scope="col">AssignTo</th>
              <th scope="col">Bill#</th>
              <th scope="col">BillValue</th>
              <th scope="col">ColValue</th>
              <th scope="col">Balance</th>
              <th scope="col">Remarks</th>
            </tr>
          <tbody>
            <tr *ngFor="let u2 of getpendBills()">
              <td>
                <button class="btn btn-sm btn-info" (click)="downloadBills(u2)">Download</button>
              </td>
              <td class="elip">
                <input (blur)="saveNoteTrackBill(u2)" type="text" [(ngModel)]="u2.billNote"
                  class="form-control form-control-sm" />
              </td>
              <td>{{u2.billDays}}</td>
              <td>{{u2.billDate | date:'dd/MM/yy'}}</td>
              <td>{{u2.companyName}}</td>
              <td>{{u2.personName}}</td>
              <td>{{u2.billNumber}}</td>
              <td>{{u2.billValue|currency:'INR': 'symbol' : '1.0-0'}}</td>
              <td>{{u2.colVal|currency:'INR': 'symbol' : '1.0-0'}}</td>
              <td>{{u2.balance|currency:'INR': 'symbol' : '1.0-0'}}</td>
              <td>{{u2.billRemarks}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-6">
        <table class="table table-striped table-sm table-responsive table-condensed">
          <thead class="thead-dark">
            <tr>
              <th scope="col">User</th>
              <th scope="col">Notes</th>
              <th scope="col">Date</th>
              <th scope="col">Bill#</th>
              <th scope="col">BillValue</th>
              <th scope="col">NoteType</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let u of suppliedDocs">
              <td>{{u.userName}}</td>
              <td>{{u.notes}}</td>
              <td>{{u.enteredDate| date: 'dd/MM/yy hh:mm a'}}</td>
              <td>{{u.billNumber}}</td>
              <td>{{u.billValue}}</td>
              <td>{{u.noteType}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </td>
    </tr>
    </tbody>
    </table>
  </div>
</div>
</div>
</div>