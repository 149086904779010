import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Role, User, userModel } from 'src/app/models';
import { activityModel } from 'src/app/models1';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { PmService } from 'src/app/_services/pm.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {

  customers: activityModel[] = [];
  loading = false;
  isAddCustomerClicked: boolean = false;
  error = '';
  submitted = false;
  customerForm: FormGroup;
  get f() { return this.customerForm.controls; }
  searchCustomerName: string = "";
  addEditCustModel: activityModel = new activityModel();
  users: userModel[] = [];
  currentUser: User;

  constructor(private formBuilder: FormBuilder, private api: PmService, private api1: AuthenticationService) {
    this.currentUser = this.api1.currentUserValue;

    this.getCollPesons();
    if (this.currentUser.role == Role.PAdmin) {
      this.api1.getSearchUsers(this.searchCustomerName, "PerMgt")
        .subscribe(result => { this.users = result.sort((a, b) => a.role.localeCompare(b.role) || a.userName.localeCompare(b.userName)); }, error => {
          console.error(error);
          this.error = error;
          this.loading = false;
        });
    } else {
      var r = new userModel();
      r.id = this.currentUser.id;
      r.userName = this.currentUser.userName;
      this.users.push(r);
    }
  }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      activityName: ['', Validators.required],
      appUserID: ['', Validators.required],
      remarks: ['']
    });
  }

  onEditCustomer(u: activityModel) {
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      activityName: u.activityName,
      remarks: u.remarks,
      appUserID: u.appUserID
    });
    this.addEditCustModel = u
  }

  onAddCustSubmit() {
    this.submitted = true;
    if (this.customerForm.invalid) return;
    this.loading = true;
    this.addEditCustModel.remarks = this.f.remarks.value;
    this.addEditCustModel.activityName = this.f.activityName.value;
    this.addEditCustModel.appUserID = parseInt(this.f.appUserID.value);

    this.api.AddEditActivity(this.addEditCustModel)
      .subscribe(() => {
        this.submitted = false;
        this.loading = false;
        this.onCustSearchReset();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onCloseAddCust() {
    this.isAddCustomerClicked = false;
  }

  oAddCustomer() {
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      activityName: '',
      remarks: '',
      appUserID: this.currentUser.id
    });
    this.addEditCustModel = new activityModel();
    this.addEditCustModel.activityId = 0;
    this.addEditCustModel.remarks = '';
    this.addEditCustModel.activityName = '';
    this.addEditCustModel.appUserID = this.currentUser.id;
  }

  onNameCustomerSearch() {
    this.isAddCustomerClicked = false;
    this.getCollPesons();
  }

  onCustSearchReset() {
    this.isAddCustomerClicked = false;
    this.searchCustomerName = "";
    this.getCollPesons();
  }

  getCollPesons() {
    this.api.getActivitys(this.searchCustomerName)
      .subscribe(result => { this.customers = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  MarkInUse(u: activityModel) {
    if (confirm("Are you sure to Mark Inactive?")) {
      this.error = '';
      this.submitted = true;
      this.loading = true;
      this.api.MarkInUse(u).subscribe(result => {
        this.submitted = false;
        this.loading = false;
        this.isAddCustomerClicked = false;
        this.onNameCustomerSearch();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    }
  }


  MarkADelete(u: activityModel) {
    if (confirm("Are you sure to Delete?")) {
      this.error = '';
      this.submitted = true;
      this.loading = true;
      this.api.MarkADelete(u).subscribe(result => {
        this.submitted = false;
        this.loading = false;
        this.isAddCustomerClicked = false;
        this.onNameCustomerSearch();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    }
  }

}
