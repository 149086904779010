<div class="row">
    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
    <input name="file" id="file" (change)="uploadLedger($event)" type="file" #inputFile hidden>
    <div class="col">
        <div *ngIf="!isAddNewBillClicked">
            <div class="form-row">
                <div class="col-md-1">
                    <label>Invoice Number</label>
                    <input type="text" [(ngModel)]="searchBillModel.invoiceNumber" class="form-control" />
                </div>
                <div class="col-md-1">
                    <label>Company Name</label>
                    <input type="text" [(ngModel)]="searchBillModel.companyName" class="form-control" />
                </div>
                <div class="col-md-1">
                    <label>From</label>
                    <input type="date" [ngModel]="searchBillModel.fromDate | date:'yyyy-MM-dd'"
                        (change)="selectChangeHandler($event)" class="form-control" />
                </div>
                <div class="col-md-1">
                    <label>To</label>
                    <input type="date" [ngModel]="searchBillModel.toDate | date:'yyyy-MM-dd'"
                        (change)="selectChangeHandler1($event)" class="form-control" />
                </div>
                <div class="col-md-1">
                    <label> </label> <Br>
                    <div>
                        <button class="btn btn-sm btn-info" (click)="onCollBillSearch()">Search</button>
                    </div>
                </div>
                <div class="col-md-1">
                    <label> </label> <Br>
                    <div>
                        <button class="btn btn-sm btn-info" (click)="onCollBillSearchReset()">Reset</button>
                    </div>
                </div>
                <div class="col-md-1">
                    <label> </label><Br>
                    <div>
                        <button class="btn btn-sm btn-info" (click)="downloadInvoiceReport()">Download</button>
                    </div>
                </div>
                <div class="col-md-1">
                    <label for="companyName">Company</label>
                    <div>
                        <select class="form-control" [(ngModel)]="selectedComp">
                            <option value="" selected disabled>Choose</option>
                            <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
                        </select>
                        <button class="btn btn-sm btn-info" (click)="onAddNewBill()">Add New Invoice</button>
                    </div>
                </div>
            </div><br>
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">Invoice Id</th>
                        <th scope="col">Company Name</th>
                        <th scope="col">Invoice Number</th>
                        <th scope="col">Invoice Date</th>
                        <th scope="col">PreGst Value</th>
                        <th scope="col">Invoice Value</th>
                        <th scope="col">Gst Value</th>
                        <th scope="col">Gst Percentage</th>
                        <th scope="col">Payment Mode</th>
                        <th scope="col">Margin</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let u of colls">
                        <td>
                            <button type="button" class="btn btn-sm btn-info btn-sm" (click)="editBill(u)">Edit</button>
                        </td>
                        <td>
                            <button class="btn btn-sm btn-info"
                                (click)="selectlinvoideTrak(u);inputFile.click()">Upload</button>&nbsp;
                        </td>
                        <td><button class="btn btn-sm btn-info" (click)="downloadBills(u)"><i
                                    class="fa fa-download"></i></button>
                        </td>
                        <td>{{u.invoiceId}}</td>
                        <td>{{u.companyName}}</td>
                        <td>{{u.invoiceNumber}}</td>
                        <td>{{u.invoiceDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.preGstValue}}</td>
                        <td>{{u.invoiceValue}}</td>
                        <td>{{u.gstValue}}</td>
                        <td>{{u.gstPercentage}}</td>
                        <td>{{u.mode}}</td>
                        <td>{{u.margin}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="isAddNewBillClicked">
            <form [formGroup]="billAddEditForm" (ngSubmit)="onBillSubmit()">
                <div class="form-row">
                    <div class="col-md-2">
                        <label for="companyName">Company Name</label><br>
                        {{billModel.companyName}}
                    </div>
                    <div class="col-md-2">
                        <label for="invoiceNumber">Invoice Number</label>
                        <input type="text" formControlName="invoiceNumber" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.invoiceNumber.errors }" />
                        <div *ngIf="submitted && f.invoiceNumber.errors" class="invalid-feedback">
                            <div *ngIf="f.invoiceNumber.errors.required">Invoice Number is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="invoiceDate">Invoice Date</label>
                        <input type="date" formControlName="invoiceDate" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.invoiceDate.errors }" />
                        <div *ngIf="submitted && f.invoiceDate.errors" class="invalid-feedback">
                            <div *ngIf="f.invoiceDate.errors.required">Invoice Date is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="invoiceValue">Invoice Value</label>
                        <input type="number" formControlName="invoiceValue" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.invoiceValue.errors }" />
                        <div *ngIf="submitted && f.invoiceValue.errors" class="invalid-feedback">
                            <div *ngIf="f.invoiceValue.errors.required">Invoice Value is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="preGstValue">Pre Gst Value</label>
                        <input type="number" formControlName="preGstValue" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.preGstValue.errors }" />
                        <div *ngIf="submitted && f.preGstValue.errors" class="invalid-feedback">
                            <div *ngIf="f.preGstValue.errors.required">Pre Gst Value is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="gstPercentage">GST Percentage</label>
                        <input type="number" formControlName="gstPercentage" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.gstPercentage.errors }" />
                        <div *ngIf="submitted && f.gstPercentage.errors" class="invalid-feedback">
                            <div *ngIf="f.gstPercentage.errors.required">GST Percentage is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="gstValue">GST Value</label>
                        <input type="number" formControlName="gstValue" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.gstValue.errors }" />
                        <div *ngIf="submitted && f.gstValue.errors" class="invalid-feedback">
                            <div *ngIf="f.gstValue.errors.required">GST Value is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="mode">Payment Mode</label>
                        <select formControlName="mode" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.mode.errors }">
                            <option value="" selected disabled>Choose</option>
                            <option value="Cheque">Cheque</option>
                            <option value="NEFT">NEFT</option>
                        </select>
                        <div *ngIf="submitted && f.mode.errors" class="invalid-feedback">
                            <div *ngIf="f.mode.errors.required">Payment Mode is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="margin">Margin</label>
                        <input type="number" formControlName="margin" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.margin.errors }" />
                        <div *ngIf="submitted && f.margin.errors" class="invalid-feedback">
                            <div *ngIf="f.margin.errors.required">Margin is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label></label><br>
                        <button type="submit" [disabled]="loading" class="btn btn-sm btn-info">
                            Save
                        </button>
                        <button class="btn btn-sm btn-info" (click)="onBillCancel()">close</button>
                    </div>
                </div>
            </form>
            <br>
            <div class="form-row">
                <div class="col-md-6">
                    <table class="table table-striped table-sm table-responsive table-condensed">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col"><input type="text" [(ngModel)]="srcPrds"></th>
                                <th scope="col">C/S<br>GST</th>
                                <th scope="col">MRP</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let u2 of getProductsFilter()">
                            <tr [class.bg-success]="color(u2)">
                                <td>{{u2.productName}}</td>
                                <td>{{u2.crate}}/{{u2.srate}}</td>
                                <td>{{u2.mrp}}</td>
                                <td><i class="fa fa-plus" (click)="onAdd(u2)"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-6">
                    <table class="table table-striped table-sm table-responsive table-condensed">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Product</th>
                                <th scope="col">Price</th>
                                <th scope="col"></th>
                                <th scope="col">Quantity</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>Total</td>
                                <td>{{getTotal()}}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                        <tbody *ngFor="let u1 of getBillProductsFilter()">
                            <tr>
                                <td>
                                    <i class="fa fa-times" (click)="onSub1(u1)"></i>
                                </td>
                                <td>{{u1.productName}}</td>
                                <td>{{u1.disPrice}}</td>
                                <td>
                                    <i class="fa fa-minus" (click)="onSub(u1)"></i>
                                </td>
                                <td class="fixw"><input type="number" [(ngModel)]="u1.quantity" min="1"
                                        class="form-control form-control-sm">
                                </td>
                                <td><i class="fa fa-plus" (click)="onAdd1(u1)"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>