import { Component, OnInit } from '@angular/core';
import { CollectionModel, BillModel, CollectonPersonModel, User, Role } from '../models';
import { AuthenticationService } from '../_services/authentication.service';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-collection-review',
  templateUrl: './collection-review.component.html',
  styleUrls: ['./collection-review.component.css']
})
export class CollectionReviewComponent implements OnInit {

  loading = false;
  error = '';
  colls: CollectionModel[] = [];
  searchBillModel: BillModel = new BillModel();
  persons1: CollectonPersonModel[] = [];
  collsSum: CollectionModel[] = [];
  collsSum1: CollectionModel[] = [];
  currentUser: User;

  constructor(private api: RayarApiService, private authenticationService: AuthenticationService) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.onSearchPerson();
    this.getCollPersons();
    this.searchBillModel.personId = 0;
    this.searchBillModel.billStatus = "";
  }

  ngOnInit(): void {
  }

  isRecButton(u: CollectionModel) {
    if (!this.currentUser) return false;
    if (this.currentUser.role === Role.Admin) {
      return !u.reviewed;
    } else {
      if (u.mode == "Cash") {
        return false;
      } else {
        return !u.reviewed;
      }
    }
  }

  onSearchPerson() {
    this.searchBillModel.page = 0;
    if (this.searchBillModel.personId)
      this.searchBillModel.personId = parseInt(this.searchBillModel.personId.toString());
    this.api.oncollsView('collsForReview', this.searchBillModel)
      .subscribe(result => {
        this.colls = result;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    this.onSearchPersonSum();
    this.onSearchPersonSum1();
  }

  onSearchPersonSum() {
    this.api.oncollsView('collsForEmpReviewSumm', this.searchBillModel)
      .subscribe(result => { this.collsSum = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onSearchPersonSum1() {
    this.api.oncollsView('collsForAdminReviewSumm', this.searchBillModel)
      .subscribe(result => { this.collsSum1 = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onReset() {
    this.searchBillModel = new BillModel();
    this.searchBillModel.personId = 0;
    this.onSearchPerson();
  }

  getCollPersons() {
    this.api.getCollPersons().subscribe(result => {
      this.persons1 = result;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }


  markReviewed(u: CollectionModel) {
    this.api.markReviewed(u.collectionId)
      .subscribe(result => {
        this.onSearchPerson();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }
  selectChangeHandler(event: any) {
    this.searchBillModel.fromDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.searchBillModel.toDate = event.target.value;
  }

  selectChangeHandler3(event: any) {
    this.searchBillModel.billStatus = event.target.value;
  }
}
