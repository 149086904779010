<div class="row">
    <div class="col">
        <form [formGroup]="billAddEditForm1" (ngSubmit)="onSubmitOut()">
            <div class="form-row">
                <div class="col-md-2">
                    <label for="amountType">Amount Type</label>
                    <select formControlName="amountType" class="form-control"
                    
                        [ngClass]="{ 'is-invalid': submitted && f1.amountType.errors }">
                        <option value="" selected disabled>Choose</option>
                        <option value="LaxmanSalary">LaxmanSalary</option>
                        <option value="PowerBill">PowerBill</option>
                        <option value="Association">Association</option>
                        <option value="Paint">Paint</option>
                        <option value="Cleaning">Cleaning</option>
                        <option value="Lift">Lift</option>
                        <option value="AgreementCharge">AgreementCharge</option>
                        <option value="Other1">Other1</option>
                        <option value="Other2">Other2</option>
                        <option value="Other3">Other3</option>
                        <option value="CollectedBy">CollectedBy</option>
                        <option value="PrevMonthFwd">PrevMonthFwd</option>
                    </select>
                    <div *ngIf="submitted && f1.amountType.errors" class="invalid-feedback">
                        <div *ngIf="f1.amountType.errors.required">Amount Type is required
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label for="name">Description</label>
                    <input type="text" formControlName="name" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f1.name.errors }" />
                    <div *ngIf="submitted && f1.name.errors" class="invalid-feedback">
                        <div *ngIf="f1.name.errors.required">Description is required</div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label for="mode">Payment Mode</label>
                    <select formControlName="mode" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f1.mode.errors }">
                        <option value="" disabled>Choose</option>
                        <option value="Cash" selected>Cash</option>
                        <option value="TriAcc">TriAcc</option>
                        <option value="MaheAcc">MaheAcc</option>
                        <option value="GowAcc">GowAcc</option>
                        <option value="Acc1">Acc1</option>
                        <option value="Acc2">Acc2</option>
                        <option value="Acc3">Acc3</option>
                    </select>
                    <div *ngIf="submitted && f1.mode.errors" class="invalid-feedback">
                        <div *ngIf="f1.mode.errors.required">Payment Mode is required
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label for="amount">Amount</label>
                    <input type="number" formControlName="amount" class="form-control" min="1"
                        [ngClass]="{ 'is-invalid': submitted && f1.amount.errors }" />
                    <div *ngIf="submitted && f1.amount.errors" class="invalid-feedback">
                        <div *ngIf="f1.amount.errors.required">Amount is required</div>
                    </div>
                </div>
                <div class="col-md-2">
                    <label>&nbsp;</label><br>
                    <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Save
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>