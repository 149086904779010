<div class="row">
  <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
  <div class="col">
    <div class="btn-group" role="group">
      <button type="button" [class.btn-success]="selTab=='3'" [class.btn-info]="selTab!='3'" class="btn"
        (click)="onTabSelect('3')">Home</button>
      <button type="button" [class.btn-success]="selTab=='1'" [class.btn-info]="selTab!='1'" class="btn"
        (click)="onTabSelect('1')">Pending
        Bills</button>
      <button type="button" [class.btn-success]="selTab=='2'" [class.btn-info]="selTab!='2'" class="btn"
        (click)="onTabSelect('2')">Order In-Progress</button>
      <button type="button" [class.btn-success]="selTab=='4'" [class.btn-info]="selTab!='4'" class="btn"
        (click)="onTabSelect('4')">New Order</button>
    </div>
    <br>
    <div *ngIf="selTab=='2'">
      <br>
      <div class="row">
        <input name="file" id="file" (change)="upload($event)" type="file" #inputFile hidden>
        <div class="col">
          <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Order</th>
                <th scope="col">Bill</th>
                <th scope="col">Amount</th>
                <th scope="col">Date</th>
                <th scope="col">Brand</th>
                <th scope="col">Customer</th>
                <th scope="col" style="width: 150px;">Remarks</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody *ngFor="let u of bills">
              <tr [class.table-danger]="u.inStock=='0'">
                <td>{{u.saleOrder||u.custOrder==2 ?'SalesManOrder':u.custOrder==1?'CustomerOrder':'PhoneOrder'}}</td>
                <td>{{u.billNumber=='NoStock'?'InProgress':u.billNumber}}</td>
                <td>{{u.billValue }}</td>
                <td [class.bg-danger]="u.saleOrder">{{u.billDate | date: 'dd/MM/yyyy'}}</td>
                <td>{{u.companyName}}</td>
                <td>{{u.customerName }}</td>
                <td>
                  <div *ngIf="u.billNumber !='NoStock'">
                  </div>
                  <div *ngIf="u.billNumber=='NoStock'">
                    <input type="text" [(ngModel)]="u.billRemarks" (blur)="onBlurEvent(u)" class="form-control" />
                  </div>
                </td>
                <td>
                  <button type="button" class="btn btn-sm btn-danger btn-sm"
                    (click)="markCancel(u)">Cancel</button>&nbsp;
                </td>
                <td>
                  <button *ngIf="u.inStock=='1'" type="button" class="btn btn-info"
                    (click)="onDownload(u.billId)">Download Bill</button>
                </td>
                <td>
                  <button *ngIf="u.custOrder==1||u.custOrder==2" type="button" class="btn btn-sm btn-info btn-sm"
                    (click)="editBillG(u)">View</button>&nbsp;
                </td>
              </tr>
              <tr *ngIf="u.showDetail1">
                <td colspan="20">
                  <div class="form-row">
                    <div class="col-md-5">
                      <table class="table table-striped table-sm table-responsive table-condensed">
                        <thead class="thead-dark">
                          <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Price</th>
                            <th scope="col">Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total</td>
                            <td>{{ getTotal() }}</td>
                            <td></td>
                          </tr>
                        </tbody>
                        <tbody *ngFor="let u1 of getBillProductsFilter(u)">
                          <tr>
                            <td>{{u1.productName}}</td>
                            <td>{{u1.disPrice}}</td>
                            <td>{{u1.quantity}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="selTab=='4'">
      <br>
      <div class="row">
        <div class="col" style="color: red;">
          Please clear any pending bills if any, before placing the order.<br>
          If any pending bills, orders will not be honored and cancelled.
        </div>
      </div>
      <br>
      <br>
      <div class="row">
        <div class="col-md-2">
          <label for="companyName">Brand</label>
          <select class="form-control" [(ngModel)]="selCompany" (change)="onCompanyChange($event)">
            <option value="" selected disabled>Choose</option>
            <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
          </select>
        </div>
        <div class="col-md-2">
          <label for="margin">Margin</label>
          <input type="text" [(ngModel)]="selectedMargin" readonly class="form-control" />
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col" style="color: red;">
          {{alterText}}
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-4">
          <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
              <tr>
                <th scope="col"><input type="text" [(ngModel)]="srcPrds1"></th>
                <th scope="col">MRP</th>
                <th scope="col">Quantity</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody *ngFor="let u2 of getProductsFilter1()">
              <tr [class.bg-success]="color1(u2)">
                <td>{{u2.productName}}</td>
                <td>{{u2.mrp}}</td>
                <td>{{u2.availableQuantity}}</td>
                <td><i class="fa fa-plus" (click)="onAddNew(u2)"></i></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3">
              <label for="billRemarks">Remarks</label>
              <textarea type="text" rows="3" [(ngModel)]="selRemakrs" class="form-control"></textarea>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-12">
              <button (click)="onBillSubmit()" class="btn btn-sm btn-info">Bill</button>&nbsp;
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col" style="color: red;">
              {{alterText}}
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-12">
              <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Product</th>
                    <th scope="col">Price</th>
                    <th scope="col"></th>
                    <th scope="col">Quantity</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td>Total</td>
                    <td>{{ getTotal1() }}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
                <tbody *ngFor="let u1 of getBillProductsFilter1()">
                  <tr>
                    <td>
                      <i class="fa fa-times" (click)="onSubNew(u1)"></i>
                    </td>
                    <td>{{u1.productName}}</td>
                    <td>{{u1.disPrice}}</td>
                    <td>
                      <i class="fa fa-minus" (click)="onSubNew1(u1)"></i>
                    </td>
                    <td class="fixw"><input type="number" [(ngModel)]="u1.quantity" min="1"
                        class="form-control form-control-sm">
                    </td>
                    <td><i class="fa fa-plus" (click)="onAddNew(u1)"></i></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="form-row" *ngIf="selTab=='1'">
      <div class="col-md-12">
        <div *ngIf="showAlert1" class="alert alert-danger mt-3 mb-0"> 2 or More Pending Bills!! </div>
        Pending Bills
        <table class="table table-striped table-sm table-responsive table-condensed">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Company</th>
              <th scope="col">Bill#</th>
              <th scope="col">Bill Date</th>
              <th scope="col">Amount</th>
              <th scope="col">Collected</th>
              <th scope="col">Balance</th>
              <th scope="col">Assigned</th>
            </tr>
          <tbody>
            <tr *ngFor="let u of pendingBills" [class.table-danger]="u.companyName==this.selectedCompany">
              <td>{{u.companyName}}</td>
              <td>{{u.billNumber}}</td>
              <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
              <td>{{u.billValue }}</td>
              <td>{{u.colVal }}</td>
              <td>{{u.balance }}</td>
              <td>{{u.personName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12">
        Pending Cheque Clerance
        <table class="table table-striped table-sm table-responsive table-condensed">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Cheque#</th>
              <th scope="col">Cheque Date</th>
              <th scope="col">Collected</th>
              <th scope="col">Remarks</th>
              <th scope="col">Date</th>
              <th scope="col">Mode</th>
              <th scope="col">CollectedBy</th>
              <th scope="col">Bill Date</th>
              <th scope="col">Bill#</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let u of colls">
              <td>{{u.chequeNumber}}</td>
              <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
              <td>{{u.collectionValue}}</td>
              <td>{{u.remarks}}</td>
              <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
              <td>{{u.mode}}</td>
              <td>{{u.collectedBy}}</td>
              <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
              <td>{{u.billNumber}}</td>
              <td>{{u.billValue}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-12">
        Bounced Checks
        <table class="table table-striped table-sm table-responsive table-condensed">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Bill Number</th>
              <th scope="col">Check Details</th>
            </tr>
          <tbody>
            <tr *ngFor="let u of pendingFollowups">
              <td>{{u.billNumber}}</td>
              <td>{{u.bouncedCheckDetail }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="form-row" *ngIf="selTab=='3'">
      <div class="col-md-12">
        <div class="row">
          <div class="col">
            <b>Note:</b> Please use online payment option always,<b> Try to avoid cash transactions.</b><br>
            <b>Note:</b><b> Always Quote Bill Numbers in Payment Remarks</b>.<br><br>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <table class="table table-striped table-sm table-responsive table-condensed">
              <thead class="thead-dark">
                <tr>
                  <th></th>
                  <th>SunRise Distributor</th>
                  <th>Rayar Agencies</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>GST</td>
                  <td>29DOTPS8776F1ZP</td>
                  <td>29AUUPR7909K1ZG</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>9952663691</td>
                  <td>9952595512</td>
                </tr>
                <tr>
                  <td>Account Name</td>
                  <td>SunRise Distributor</td>
                  <td>RAYAR AGENCIES</td>
                </tr>
                <tr>
                  <td>Account Number</td>
                  <td>232905000832</td>
                  <td>233605000449</td>
                </tr>
                <tr>
                  <td>Bank Branch</td>
                  <td>ICICI Mathikere Branch</td>
                  <td>ICICI Bank - Sanjay Nagar</td>
                </tr>
                <tr>
                  <td>IFSC Code</td>
                  <td>ICIC002329</td>
                  <td>ICIC0002336</td>
                </tr>
                <tr>
                  <td>Scan</td>
                  <td><img src="/assets/Sunrise.png" alt="Sunrise Distributor"></td>
                  <td><img src="/assets/Rayar.png" alt="RayarAgencies"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <img src="/assets/GoodPayment.jpeg" alt="GoodPayment">
          </div>
        </div>
        <div class="row" *ngIf="accledgersAll.length>0">
          <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Route</th>
                <th scope="col">Start Time</th>
                <th scope="col">Complete Time</th>
                <th scope="col">Driver Name</th>
                <th scope="col">Driver Phone</th>
                <th scope="col">Helper Name</th>
                <th scope="col">Helper Phone</th>
                <th scope="col">Vehicle</th>
              </tr>
            </thead>
            <tbody *ngFor="let u of routeAttends">
              <tr>
                <td>{{u.resourceName}}</td>
                <td>{{u.checkInTime | date: 'shortTime'}} </td>
                <td>{{u.checkOutTime | date: 'shortTime'}} </td>
                <td>{{u.driverName}}</td>
                <td><a *ngIf="u.driverPhone" [href]="'tel:+91' + u.driverPhone">{{u.driverPhone}}</a></td>
                <td>{{u.helperName}}</td>
                <td><a *ngIf="u.helperPhone" [href]="'tel:+91' + u.helperPhone">{{u.helperPhone}}</a></td>
                <td>{{u.vehicleName}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" *ngIf="accledgersAll.length>0">
          <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Serial</th>
                <th scope="col">Delivered <br> Time</th>
                <th scope="col">Bill No</th>
                <th scope="col">Bill Amount</th>
                <th scope="col">Customer</th>
                <th scope="col">Infor3</th>
                <th scope="col">Company</th>
                <th scope="col">SalesMan<br>Phone</th>
                <th scope="col">Customer Phone</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let u of accledgersAll">
                <td>
                  <button class="btn btn-sm btn-success mp" *ngIf="u.cashCarry"><i class="fa fa-inr"></i></button>
                </td>
                <td>
                  <button *ngIf="u.saleOrder" class="btn btn-sm btn-info" (click)="downloadBills(u.billId)">
                    <i class="fa fa-download"></i>
                  </button>
                </td>
                <td>{{u.serial}}</td>
                <td>{{u.deliveryDate | date: 'shortTime' }}</td>
                <td [class.bg-warning]="u.billValue > 50000">{{u.billNumber}}</td>
                <td>{{u.billValue |currency:'INR': 'symbol' : '1.0-0'}}</td>
                <td>{{u.customerName}}</td>
                <td>{{u.information3}}</td>
                <td>{{u.companyName}}</td>
                <td>{{u.salesPersonName }}<br><a *ngIf="u.salesContactPhone"
                    [href]="'tel:+91' + u.salesContactPhone">{{u.salesContactPhone}}</a></td>
                <td>
                  <a *ngIf="u.contactPhone" [href]="'tel:+91' + u.contactPhone">{{u.contactPhone}}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br>
    </div>
  </div>
</div>