<div class="card">
  <h4 class="card-header">Collection</h4>
  <div class="card-body">
    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
    <div class="form-row">
      <div class="col-md-2">
        <label for="collectedBy">Collected By</label>
        <select class="form-control" (change)="selectChangeHandler($event)">
          <option value="0" selected *ngIf="isUrAmSr">All</option>
          <option *ngFor="let p of persons1" [value]="p.personId">{{p.name}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3" *ngFor="let u of collsByUser">
        <div class="card border-dark mb-3">
          <div class="card-header">
            BillNumber <span class="badge badge-primary badge-pill">{{u.billNumber}}</span>
          </div>
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Collection
              <span class="badge badge-primary badge-pill">{{u.mode}}:{{u.collectionValue}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Collection Date
              <span class="badge badge-primary badge-pill">{{u.entryDate| date: 'dd/MM/yyyy'}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              CollectedBy
              <span class="badge badge-primary badge-pill">{{u.collectedBy}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Cheque<span
                class="badge badge-primary badge-pill">{{u.chequeDate | date: 'dd/MM/yyyy'}}{{u.chequeNumber}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Company
              <span class="badge badge-primary badge-pill">{{u.companyName}}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Customer
              <span class="badge badge-primary badge-pill">{{u.customerName}}</span>
            </li>
          </ul>
          <div class="card-footer bg-transparent" *DataCustomAcl="'Admin'">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button type="button" class="btn btn-outline-primary btn-sm" *ngIf="u.reviewed==0"
                (click)="deleteCol(u)">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
