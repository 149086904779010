<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-md-2">
                <label>Customer/Bill</label>
                <input class="form-control" type="text" [(ngModel)]="search.customerName" />
            </div>
            <div class="col-md-2">
                <label>Reviewed</label><Br>
                <input type="radio" name="revRadio1" value="0" [(ngModel)]="searchreviewed" />
                Not Reviewed
                <input type="radio" name="revRadio1" value="1" [(ngModel)]="searchreviewed" />
                Reviewed
                <input type="radio" name="revRadio1" value="2" [(ngModel)]="searchreviewed" />
                Both
            </div>
            <div class="col-md-2">
                <label></label>
                <div>
                    <button class="btn btn-sm btn-info" (click)="onSearchPayment(0)">Search</button>
                </div>
            </div>
        </div>
        <br>
        <div class="form-row">
            <div class="col">
                <nav>
                    <ul class="pagination">
                        <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a>
                        </li>
                        <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                        <li class="page-item"><span class="page-link">Page: {{search.page+1}}</span>
                        </li>
                        <li class="page-item">
                            <div class="input-group">
                                <input type="number" class="page-link" [(ngModel)]="pagenumber1" min="1"
                                    style="max-width: 65px;">
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-info" (click)="onGoto()">GoTo</button>
                                </div>
                            </div>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="w-100"></div>
            <div class="col">
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Customer</th>
                            <th scope="col">Bill#</th>
                            <th scope="col">BillValue</th>
                            <th scope="col">Bill Date</th>
                            <th scope="col">Company</th>
                            <th scope="col">Upload Date</th>
                            <th scope="col">Reviewed</th>
                            <th scope="col">CreatedBy</th>
                            <th scope="col">Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let u of payments">
                            <td>
                                <button class="btn btn-sm btn-info" (click)="onDocView(u)">View</button>
                            </td>
                            <td>{{u.customerName}}</td>
                            <td>{{u.billNumber}}</td>
                            <td>{{u.billValue}}</td>
                            <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                            <td>{{u.companyName}}</td>
                            <td>{{u.uploadDate| date: 'dd/MM/yy hh:mm a'}}</td>
                            <td>{{u.reviewed}}</td>
                            <td>{{u.createdBy}}</td>
                            <td>{{u.remarks}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>