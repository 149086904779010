<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-md-2">
                <label>Customer/Bill</label>
                <input class="form-control" type="text" [(ngModel)]="search.customerName" />
            </div>
            <div class="col-md-2">
                <label>CreatedBy</label>
                <input class="form-control" type="text" [(ngModel)]="search.createdBy" />
            </div>
            <div class="col-md-2">
                <label>Reviewed</label><Br>
                <input type="radio" name="revRadio1" value="0" [(ngModel)]="searchreviewed" />
                Not Reviewed
                <input type="radio" name="revRadio1" value="1" [(ngModel)]="searchreviewed" />
                Reviewed
                <input type="radio" name="revRadio1" value="2" [(ngModel)]="searchreviewed" />
                Both
            </div>
            <div class="col-md-2">
                <label></label>
                <div>
                    <button class="btn btn-sm btn-info" (click)="onSearchPayment(0)">Search</button>
                </div>
            </div>
        </div>
        <br>
        <div class="form-row">
            <div class="col">
                <nav>
                    <ul class="pagination">
                        <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a>
                        </li>
                        <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                        <li class="page-item"><span class="page-link">Page: {{search.page+1}}</span>
                        </li>
                        <li class="page-item">
                            <div class="input-group">
                                <input type="number" class="page-link" [(ngModel)]="pagenumber1" min="1"
                                    style="max-width: 65px;">
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-info" (click)="onGoto()">GoTo</button>
                                </div>
                            </div>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="w-100"></div>
            <div class="col">
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col">Customer</th>
                            <th scope="col">Bill#</th>
                            <th scope="col">BillValue</th>
                            <th scope="col">Bill Date</th>
                            <th scope="col">Company</th>
                            <th scope="col">Upload Date</th>
                            <th scope="col">Reviewed</th>
                            <th scope="col">CreatedBy</th>
                            <th scope="col">Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let u of payments">
                            <td>
                                <button class="btn btn-sm btn-info" (click)="onDocView(u)">View</button>
                            </td>
                            <td>
                                <button *ngIf="!u.reviewed" class="btn btn-sm btn-info"
                                    (click)="onPost(u)">Search</button>
                            </td>
                            <td>
                                <button *ngIf="!u.reviewed" class="btn btn-sm btn-info"
                                    (click)="onMarkDocRev(u)">Reviewed</button>
                            </td>
                            <td>{{u.customerName}}</td>
                            <td>{{u.billNumber}}</td>
                            <td>{{u.billValue}}</td>
                            <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                            <td>{{u.companyName}}</td>
                            <td>{{u.uploadDate| date: 'dd/MM/yy hh:mm a'}}</td>
                            <td>{{u.reviewed}}</td>
                            <td>{{u.createdBy}}</td>
                            <td class="elip">
                                <input (blur)="onBlurEvent(u)" type="text" [(ngModel)]="u.remarks"
                                    class="form-control form-control-sm" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="form-row">
            <div class="col-xs-12">
                <div class="row">
                    <div class="col">
                        <label>Customer</label>
                        <input type="text" [(ngModel)]="srcCustomerName" class="form-control" />
                    </div>
                    <div class="col">
                        <label>Bill Number</label>
                        <input type="text" [(ngModel)]="srcBillNumber" class="form-control" />
                    </div>
                    <div class="col">
                        <label>Bill/Balance Value</label>
                        <input type="number" [(ngModel)]="srcBillValue" class="form-control" />
                    </div>
                    <div class="col-">
                        <label for="companyName">Company</label>
                        <select class="form-control" [(ngModel)]="srcCompany">
                            <option value="" selected>All</option>
                            <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
                        </select>
                    </div>
                    <div class="col">
                        <label> </label>
                        <div>
                            <a class="btn btn-sm btn-info" (click)="onCollBillSearch1()">Search</a>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="col-xs-12">
                <form [formGroup]="collectionForm" (ngSubmit)="onCollSubmit()">
                    <div class="row">
                        <div class="col">
                            <label for="collectedBy">Collected By</label>
                            <select formControlName="collectedBy" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.collectedBy.errors }">
                                <option value="">Select</option>
                                <option *ngFor="let p of persons" [value]="p.personId">{{p.name}}</option>
                            </select>
                            <div *ngIf="submitted && f.collectedBy.errors" class="invalid-feedback">
                                <div *ngIf="f.collectedBy.errors.required">Collected By is required</div>
                            </div>
                        </div>
                        <div class="col">
                            <label for="mode">Mode</label>
                            <select formControlName="mode" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.mode.errors }">
                                <option value="">Select</option>
                                <option value="Cash">Cash</option>
                                <option value="Cheque">Cheque</option>
                                <option *DataCustomAcl="'Admin'" value="MobileTransfer">MobileTransfer</option>
                            </select>
                            <div *ngIf="submitted && f.mode.errors" class="invalid-feedback">
                                <div *ngIf="f.mode.errors.required">Mode is required</div>
                            </div>
                        </div>
                        <div class="col" [hidden]="!isModeCheque">
                            <label for="chequeDate">Cheque Date</label>
                            <input type="date" formControlName="chequeDate" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.chequeDate.errors }" />
                            <div *ngIf="submitted && f.chequeDate.errors" class="invalid-feedback">
                                <div *ngIf="f.chequeDate.errors.required">chequeDate is required</div>
                            </div>
                        </div>
                        <div class="col" [hidden]="!isModeCheque">
                            <label for="chequeNumber">Cheque Number</label>
                            <input type="number" min="0" formControlName="chequeNumber" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.chequeNumber.errors }" />
                            <div *ngIf="submitted && f.chequeNumber.errors" class="invalid-feedback">
                                <div *ngIf="f.chequeNumber.errors.required">Cheque Number is required</div>
                            </div>
                        </div>
                        <div class="col">
                            <label for="remarks">Remarks</label>
                            <input type="text" multiple formControlName="remarks" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }" />
                            <div *ngIf="submitted && f.remarks.errors" class="invalid-feedback">
                                <div *ngIf="f.remarks.errors.required">Remarks is required</div>
                            </div>
                        </div>
                        <div class="col">
                            <label> </label>
                            <div>
                                <button type="submit" [disabled]="loading" class="btn btn-sm btn-info">
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <table>
                    <tbody>
                        <tr *ngFor="let u of onCollSubmit1()">
                            <td [ngStyle]="{'background':getTheColor(u)}">{{u}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div><br>
        <div class="row">
            <div class="col">
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Mode</th>
                            <th scope="col">Discount</th>
                            <th scope="col">Claim Disc</th>
                            <th scope="col">Docs</th>
                            <th scope="col">Shortage</th>
                            <th scope="col">Damage</th>
                            <th scope="col">IR Number</th>
                            <th scope="col">Total</th>
                            <th scope="col">Remaining</th>
                            <th scope="col">Balance</th>
                            <th scope="col">Bill Value</th>
                            <th scope="col">Collected Value</th>
                            <th scope="col">Bill#</th>
                            <th scope="col">Customer</th>
                            <th scope="col">Company</th>
                            <th scope="col">Bill Date</th>
                            <th scope="col">Bill Assigned</th>
                        </tr>
                    <tbody>
                        <tr *ngFor="let u of bills">
                            <td class="fixw">
                                <input type="number" min="0" [(ngModel)]="u.billValue1"
                                    class="form-control form-control-sm" />
                            </td>
                            <td class="fixw">
                                <input type="number" min="0" [(ngModel)]="u.discount"
                                    class="form-control form-control-sm" />
                            </td>
                            <td class="fixw">
                                <input type="number" min="0" [(ngModel)]="u.displayDiscount"
                                    class="form-control form-control-sm">
                            </td>
                            <td class="fixw" [class.bg-danger]="u.claimDocCount==0 && u.displayDiscount>0">
                                <div class="input-group input-group-sm mb-3">
                                    <div class="input-group-append">
                                        <span class="input-group-text"
                                            id="inputGroup-sizing-sm">{{u.claimDocCount}}</span>
                                        <button (click)="inputFile2.click()" class="btn btn-sm btn-info"><i
                                                class="fa fa-upload"></i></button>
                                    </div>
                                </div>
                                <input name="file" id="file" (change)="upload1($event,u)" type="file" #inputFile2
                                    hidden>
                            </td>
                            <td class="fixw">
                                <input type="number" min="0" [(ngModel)]="u.shortage"
                                    class="form-control form-control-sm" />
                            </td>
                            <td class="fixw">
                                <input type="number" min="0" [(ngModel)]="u.damage" (change)="changeFn(u)"
                                    class="form-control form-control-sm" />
                            </td>
                            <td class="fixw" [class.bg-danger]="u.isnotvalidir">
                                <input type="number" min="0" [(ngModel)]="u.damageIrNumber"
                                    class="form-control form-control-sm" />
                            </td>
                            <td>{{u.billValue1+u.discount+u.displayDiscount+u.damage+u.shortage}}</td>
                            <td [class.alert]="u.billValue1+u.discount+u.damage+u.shortage-u.balance>0">
                                {{u.billValue1+u.discount+u.displayDiscount+u.damage+u.shortage-u.balance}}</td>
                            <td>{{u.balance }}</td>
                            <td>{{u.billValue }}</td>
                            <td>{{u.colVal }}</td>
                            <td>{{u.billNumber}}</td>
                            <td class="elip">{{u.customerName}}</td>
                            <td>{{u.companyName}}</td>
                            <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
                            <td>{{u.personName }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="form-row">
            <div class="col">
                <nav>
                    <ul class="pagination">
                        <li class="page-item"><a class="page-link" (click)="onpagert(false)">Previous</a>
                        </li>
                        <li class="page-item"><a class="page-link" (click)="onpagert(true)">Next</a></li>
                        <li class="page-item"><span class="page-link">Page: {{searchrt.page+1}}</span>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col">
                <button class="btn btn-sm btn-info" (click)="getBills(0)">Search</button>
            </div>
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Bill#</th>
                        <th scope="col">Bill Value</th>
                        <th scope="col">Bill Date</th>
                        <th scope="col">Bill Status</th>
                        <th scope="col"> <input type="text" [(ngModel)]="searchrt.productName" class="form-control" />
                        </th>
                        <th scope="col">Billed Qty</th>
                        <th scope="col">Returned Qty</th>
                        <th scope="col">Returnable Qty</th>
                        <th scope="col">Return</th>
                        <th scope="col">C Rate</th>
                        <th scope="col">S Rate</th>
                        <th scope="col">MRP</th>
                        <th scope="col">Bill.Margin</th>
                        <th scope="col">Prd.Margin</th>
                        <th scope="col">Account</th>
                    </tr>
                </thead>
                <tbody *ngFor="let u of billsrts">
                    <tr [class.table-danger]="u.toBeReturn>0">
                        <td>
                        </td>
                        <td>{{u.billNumber}}</td>
                        <td>{{u.billValue}}</td>
                        <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.billStatus}}</td>
                        <td>{{u.productName}}</td>
                        <td>{{u.quantity}}</td>
                        <td>{{u.returnedQuantity}}</td>
                        <td>{{u.quantity-u.returnedQuantity}}</td>
                        <td>
                            <input type="number" min="1" max={{u.quantity-u.returnedQuantity}}
                                [(ngModel)]="u.toBeReturn" class="form-control form-control-sm" />
                        </td>
                        <td>{{u.crate}}</td>
                        <td>{{u.srate}}</td>
                        <td>{{u.price}}</td>
                        <td>{{u.margin}}</td>
                        <td>{{u.defaultMargin}}</td>
                        <td>{{u.accountName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>