import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { InventoryModel } from '../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {

  customers: InventoryModel[] = [];
  loading = false;
  isAddCustomerClicked: boolean = false;
  error = '';
  submitted = false;
  customerForm: FormGroup;
  get f() { return this.customerForm.controls; }
  searchBillModel: InventoryModel= new InventoryModel();
  addEditCustModel: InventoryModel = new InventoryModel();

  constructor(private formBuilder: FormBuilder, private api: RayarApiService) {
    this.searchBillModel.toDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.searchBillModel.fromDate = new Date(formatDate(new Date(new Date().setMonth(new Date().getMonth() - 2)), 'yyyy-MM-dd', 'en'));
  }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      inventoryDate: ['', Validators.required],
      inventoryValue: ['', Validators.required]
    });
  }

  selectChangeHandler(event: any) {
    this.searchBillModel.fromDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.searchBillModel.toDate = event.target.value;
  }

  onEditCustomer(u: InventoryModel) {
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      inventoryDate: formatDate(u.inventoryDate, 'yyyy-MM-dd', 'en'),
      inventoryValue: u.inventoryValue
    });
    this.addEditCustModel = u
  }

  onAddCustSubmit() {
    this.submitted = true;
    if (this.customerForm.invalid) return;
    this.loading = true;
    this.addEditCustModel.inventoryDate = this.f.inventoryDate.value;
    this.addEditCustModel.inventoryValue = this.f.inventoryValue.value;

    this.api.AddEditInventory(this.addEditCustModel)
      .subscribe(() => {
        this.submitted = false;
        this.loading = false;
        this.onNameCustomerSearch();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onCloseAddCust() {
    this.isAddCustomerClicked = false;
  }

  oAddCustomer() {
    this.isAddCustomerClicked = true;
    this.customerForm.setValue({
      inventryDate: '',
      inventoryValue: ''
    });
    this.addEditCustModel = new InventoryModel();
    this.addEditCustModel.inventoryId = 0;
  }

  onNameCustomerSearch() {
    this.isAddCustomerClicked = false;
    this.api.getSearchInventory(this.searchBillModel)
      .subscribe(result => { this.customers = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  onCustSearchReset() {
    this.isAddCustomerClicked = false;
    this.searchBillModel = new InventoryModel();
    this.searchBillModel.toDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.searchBillModel.fromDate = new Date(formatDate(new Date(new Date().setMonth(new Date().getMonth() - 2)), 'yyyy-MM-dd', 'en'));
  }

}
