<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-md-2">
                <label>Route</label>
                <select class="form-control" [(ngModel)]="searchlModel.timeTrackResourceId">
                    <option value="-1" selected>All</option>
                    <option *ngFor="let p of emps" [value]="p.timeTrackResourceId">{{p.resourceName}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label>Driver</label>
                <select class="form-control" [(ngModel)]="searchlModel.driverName">
                    <option value="" selected>All</option>
                    <option *ngFor="let p of empsAll" [value]="p.employeeName">{{p.employeeName}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label>Helper</label>
                <select class="form-control" [(ngModel)]="searchlModel.helperName">
                    <option value="" selected>All</option>
                    <option *ngFor="let p of empsAll" [value]="p.employeeName">{{p.employeeName}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label>Vehicle</label>
                <select class="form-control" [(ngModel)]="searchlModel.vehicleName">
                    <option value="" selected>All</option>
                    <option *ngFor="let p of vehicles" [value]="p.vehicleName">{{p.vehicleName}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label for="From"> From</label>
                <input type="date" [ngModel]="selectedDate | date:'yyyy-MM-dd'" (change)="selectChangeHandler($event)"
                    class="form-control" />
            </div>
            <div class="col-md-2">
                <label for="To">To</label>
                <input type="date" [ngModel]="selectedDate1 | date:'yyyy-MM-dd'" (change)="selectChangeHandler1($event)"
                    class="form-control" />
            </div>
            <div class="col-md-2">
                <label></label><br>
                <button class="btn btn-sm btn-info" (click)="onSearch()">Search</button>&nbsp;
                <button class="btn btn-sm btn-info" (click)="onReset()">Reset</button> &nbsp;
                <button class="btn btn-sm btn-info" (click)="downloadReport()">Download</button>
            </div>
        </div>
        <br>
        <nav aria-label="...">
            <ul class="pagination">
                <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a></li>
                <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                <li class="page-item">
                    <span class="page-link">Page: {{searchlModel.page+1}}</span>
                </li>
            </ul>
        </nav>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-light">
                <tr>
                    <th scope="col">Route</th>
                    <th scope="col">Date</th>
                    <th scope="col">Start Time</th>
                    <th scope="col">Complete Time</th>
                    <th scope="col">Vehicle</th>
                    <th scope="col">Driver</th>
                    <th scope="col">Helper</th>
                    <th scope="col">Bill Value</th>
                    <th scope="col">Bill Count</th>
                    <th scope="col">Customer Count</th>
                    <th scope="col">FuelIn</th>
                    <th scope="col">FuelAmount</th>
                    <th scope="col">OdoMeter</th>
                    <th scope="col">ReasonOut</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of bills">
                    <td>{{u.resourceName}}</td>
                    <td>{{u.dateValue | date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.checkInTime | date: 'shortTime'}} </td>
                    <td>{{u.checkOutTime | date: 'shortTime'}} </td>
                    <td>{{u.vehicleName}}</td>
                    <td>{{u.driverName}}</td>
                    <td>{{u.helperName}}</td>
                    <td>{{u.billValue1}}<br>{{u.billValue}}<br>{{((u.billValue1/u.billValue)*100).toFixed(2)}}%</td>
                    <td>{{u.billCount1}}<br>{{u.billCount}}<br>{{((u.billCount1/u.billCount)*100).toFixed(2)}}%</td>
                    <td>{{u.customerCount1}}<br>{{u.customerCount}}<br>{{((u.customerCount1/u.customerCount)*100).toFixed(2)}}%</td>
                    <td>{{u.fuel}}</td>
                    <td>{{u.reason}}</td>
                    <td>{{u.checkOut}}</td>
                    <td>{{u.reasonOut}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>