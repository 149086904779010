<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="row">
            <div class="col">
                <Label>&nbsp;</Label>
                <input name="file" id="file" (change)="upload($event)" type="file" #inputFile hidden>
                <button class="btn btn-info" (click)="inputFile.click()">.Xls Upload-{{search.epiType}}</button> &nbsp;
            </div>
            <div class="col">
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-end">
                        <li class="page-item"><input type="text" class="page-link" [(ngModel)]="search.saleReturnNo">
                        </li>
                        <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a></li>
                        <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                        <li class="page-item"><span class="page-link">Page:{{pagenumber+1}}</span></li>
                        <li class="page-item">
                            <div class="input-group mb-3">
                                <input type="number" class="page-link" [(ngModel)]="pagenumber1"
                                    style="max-width: 65px;" min="1">
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-info" (click)="onGoto()">Goto</button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Sale Return No</th>
                            <th scope="col">Date</th>
                            <th scope="col">Outlet</th>
                            <th scope="col">Sk Unit</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Reason</th>
                            <th scope="col">Sale Return State</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let u of customers">
                        <tr>
                            <td>{{u.saleReturnNo}}</td>
                            <td>{{u.date}}</td>
                            <td>{{u.outlet}}</td>
                            <td>{{u.skunit}}</td>
                            <td>{{u.quantity}}</td>
                            <td>{{u.unitPrice}}</td>
                            <td>{{u.amount}}</td>
                            <td>{{u.reason}}</td>
                            <td>{{u.saleReturnState}}</td>
                            <td>{{u.action}}</td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td scope="col"></td>
                            <td scope="col"></td>
                            <td scope="col"></td>
                            <td scope="col"></td>
                            <td scope="col"></td>
                            <td scope="col">Total</td>
                            <td scope="col">{{getTotal()}}</td>
                            <td scope="col"></td>
                            <td scope="col"></td>
                            <td scope="col"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="form-row">
            <div class="col-xs-12">
                <div class="row">
                    <div class="col">
                        <label>Customer</label>
                        <input type="text" [(ngModel)]="srcCustomerName" class="form-control" />
                    </div>
                    <div class="col">
                        <label>Bill Number</label>
                        <input type="text" [(ngModel)]="srcBillNumber" class="form-control" />
                    </div>
                    <div class="col">
                        <label>Bill/Balance Value</label>
                        <input type="number" [(ngModel)]="srcBillValue" class="form-control" />
                    </div>
                    <div class="col-">
                        <label for="companyName">Company</label>
                        <select class="form-control" [(ngModel)]="srcCompany">
                            <option value="GTEpigamia" selected>GTEpigamia</option>
                            <option value="MTEpigamia" selected>MTEpigamia</option>
                        </select>
                    </div>
                    <div class="col">
                        <label> </label><br>
                        <div>
                            <a class="btn btn-sm btn-info" (click)="onCollBillSearch1()">Search</a>
                        </div>
                    </div>
                </div>
            </div>
        </div> <br>
        <div class="row">
            <div class="col">
                <table>
                    <tbody>
                        <tr *ngFor="let u of getSummarry()">
                            <td [ngStyle]="{'background':getTheColor(u)}">{{u}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label> </label><br>
                <div>
                    <button (click)="onClickAddCol()" class="btn btn-sm btn-info">
                       Save
                    </button>
                </div>
            </div>
            <div class="col-md-2">
                <label> </label><br>
                <div>
                    <button (click)="onNotUsed()" class="btn btn-sm btn-info">
                       Mark Not Used
                    </button>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Claim Disc</th>
                            <th scope="col">Remaining</th>
                            <th scope="col">Balance</th>
                            <th scope="col">Bill Value</th>
                            <th scope="col">Collected Value</th>
                            <th scope="col">Bill#</th>
                            <th scope="col">Customer</th>
                            <th scope="col">Company</th>
                            <th scope="col">Bill Date</th>
                            <th scope="col">Bill Assigned</th>
                        </tr>
                    <tbody>
                        <tr *ngFor="let u of bills">
                            <td class="fixw">
                                <input type="number" min="0" [(ngModel)]="u.displayDiscount"
                                    class="form-control form-control-sm">
                            </td>
                            <td [class.alert]="u.billValue1+u.discount+u.damage+u.shortage-u.balance>0">
                                {{u.billValue1+u.discount+u.displayDiscount+u.damage+u.shortage-u.balance}}</td>
                            <td>{{u.balance }}</td>
                            <td>{{u.billValue }}</td>
                            <td>{{u.colVal }}</td>
                            <td>{{u.billNumber}}</td>
                            <td class="elip">{{u.customerName}}</td>
                            <td>{{u.companyName}}</td>
                            <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
                            <td>{{u.personName }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>