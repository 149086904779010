import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TimeTrackResourceModel, AttendanceModel, companyModel, BankCollectionModel, SaleManTrack, SaleManTrackModel, BSaleManTrackModel, BillProductModel, SaveBillPlanModel, BillProductInvModel, accountComOperModel, accountCompanyModel, grnEmailModel, custmarviewModel, empModel, EmpAttendanceModel, LoadSheetModel, CollectionMtModel, prnReult, collectionDetailModel, invoiceTrackModel, LedgerBillSumModel } from '../models';
import { CustomerModel, BillModel, CollectonPersonModel, CollectionModel, CollectionPendingReview, PendingBillsByPerson, VehicleModel, ResourceVModel, epiprnModel } from '../models';
import { billPlanModel, CustomerMarginModel, BankCollection1Model, PendingFollowUpModel, damageReport, rayarSettingModel, collectionFollowupModel } from '../models';
import { noteTrackModel, InventLookupModel, SalesLookupModel, iv, isales, paymentModel, DocumentTrackModel, vehicalVm, grnModel, grnFollowUpModel } from '../models';
import { PersonCollectionReport, CollectionPersonVolumneByMonth, ExpenseModel, OrderTrackModel, BankStmtModel, RoutePlanBillModel, PendingBills } from '../models';
import { DamageTrackModel, ProductModel, accountModel, accountLedgerModel, InvoiceModel, comLedger, InventoryModel, AsgnTrackModel, DocumentModel, ReturnTrackModel } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RayarApiService {


  constructor(private http: HttpClient) { }

  getepiprn(b: epiprnModel) {
    return this.http.post<prnReult>(`${environment.apiUrl}/Rayar/EpiPrns`, b);
  }

  getepiprnView(b: epiprnModel) {
    return this.http.post<epiprnModel[]>(`${environment.apiUrl}/Rayar/EpiPrnsView`, b);
  }

  getAccountBalance() {
    return this.http.get<accountModel[]>(`${environment.apiUrl}/Rayar/AccountBalance`);
  }

  AddBankStmt(b: BankStmtModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddEditBankStatement`, b);
  }

  markBankStmtRev(b: BankStmtModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkBkstmtReviewed`, b);
  }

  postCollection(accountId: number, bankStatementId: number) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddCollection1`, { accountId, bankStatementId });
  }

  getPendRevBankStmts() {
    return this.http.get<BankStmtModel[]>(`${environment.apiUrl}/Rayar/BankStmtPendRv`);
  }

  getPendRevBankStmts1(page: number, source: string, search: string) {
    if (!search) search = 'NoSearch';
    return this.http.get<BankStmtModel[]>(`${environment.apiUrl}/Rayar/BankStmtPendRv/` + page + '/' + source + '/' + search);
  }

  getBankStmts(query: string, transPostedDate: string, valueDate: string,
    reviewe: string, remar: string, description: string, crDr: string, source: string, page: number) {
    return this.http.post<BankStmtModel[]>(`${environment.apiUrl}/Rayar/BankStatements/` + query,
      { transPostedDate, valueDate, reviewe, remar, description, crDr, source, page });
  }

  getMtBankStmts(p: BankStmtModel) {
    return this.http.post<BankStmtModel[]>(`${environment.apiUrl}/Rayar/MtBankStatements`, p);
  }

  getRoutePlanBills() {
    return this.http.get<RoutePlanBillModel[]>(`${environment.apiUrl}/Rayar/RoutePlanBills`);
  }

  getCustomerMargin(b: CustomerMarginModel) {
    return this.http.post<CustomerMarginModel[]>(`${environment.apiUrl}/Rayar/SearchCustomerMargin`, b);
  }

  saveCustomerMargin(b: CustomerMarginModel[]) {
    return this.http.post<boolean>(`${environment.apiUrl}/Rayar/UpdateMargin`, b);
  }

  getSearchCustomer(s: string, s1: string) {
    return this.http.get<CustomerModel[]>(`${environment.apiUrl}/Rayar/SearchCustomer/` + s + '/' + s1);
  }

  getSearchCustomerId(s: number) {
    return this.http.get<CustomerModel[]>(`${environment.apiUrl}/Rayar/SearchCustomerId/` + s);
  }

  moveBill(customerId: number, billId: number[]) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MoveBill/`, { customerId, billId });
  }

  getBills(s: number) {
    return this.http.get<BillModel[]>(`${environment.apiUrl}/Rayar/GetBills/` + s);
  }

  MarkReviewedCust(s: number) {
    return this.http.get<any>(`${environment.apiUrl}/Rayar/MarkReviewedCust/` + s);
  }


  getCustomers() {
    return this.http.get<CustomerModel[]>(`${environment.apiUrl}/Rayar/SearchCustomer1`);
  }

  deleteCustomer(s: number) {
    return this.http.get<any>(`${environment.apiUrl}/Rayar/DeleteCustomer/` + s);
  }

  AddEditCustomer(b: CustomerModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddCustomer`, b);
  }

  AddEditProduct(b: ProductModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddEditProduct`, b);
  }


  getSearchCollPeson(s: string) {
    if (!s)
      return this.http.get<CollectonPersonModel[]>(`${environment.apiUrl}/Rayar/SearchCollPerson`);
    else
      return this.http.get<CollectonPersonModel[]>(`${environment.apiUrl}/Rayar/SearchCollPerson/` + s);
  }

  getCollPersons() {
    return this.http.get<CollectonPersonModel[]>(`${environment.apiUrl}/Rayar/CollectionPersons`);
  }

  getCollPersons1() {
    return this.http.get<CollectonPersonModel[]>(`${environment.apiUrl}/Rayar/CollectionPersons1`);
  }

  getAsgnTrackReport(b: BillModel) {
    return this.http.post<AsgnTrackModel[]>(`${environment.apiUrl}/Rayar/AsgnTrackReport`, b);
  }

  AddEditCollectionPerson(b: CollectonPersonModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddEditCollectionPerson`, b);
  }

  getAccountCompOper() {
    return this.http.get<accountComOperModel>(`${environment.apiUrl}/Rayar/AccountCompOper`);
  }

  getAccountCompanySetting(a: number, c: string) {
    return this.http.get<accountCompanyModel[]>(`${environment.apiUrl}/Rayar/AccountCompanySetting/${a}/${c}`);
  }

  updateAccountCompanySetting(a: number, c: string, d: accountCompanyModel[]) {
    return this.http.post<accountCompanyModel[]>(`${environment.apiUrl}/Rayar/SaveAccountCompanySetting/${a}/${c}`, d);
  }

  getAccounts() {
    return this.http.get<accountModel[]>(`${environment.apiUrl}/Rayar/Accounts`);
  }

  getEmps() {
    return this.http.get<empModel[]>(`${environment.apiUrl}/Rayar/Emps`);
  }

  getRayarSettings() {
    return this.http.get<rayarSettingModel[]>(`${environment.apiUrl}/Rayar/RayarSettings`);
  }

  getRayarSettingsRefresh() {
    return this.http.get<rayarSettingModel[]>(`${environment.apiUrl}/Rayar/RayarSettingsRefresh`);
  }

  getSearchRayarSetting(s: string) {
    return this.http.get<rayarSettingModel[]>(`${environment.apiUrl}/Rayar/SearchRayarSetting/` + s);
  }

  AddEditRayarSetting(b: rayarSettingModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddEditRayarSetting`, b);
  }

  getBillPlan() {
    return this.http.get<SaveBillPlanModel>(`${environment.apiUrl}/Rayar/BillPlan`);
  }

  getBillPlanRoute(n: number) {
    return this.http.get<SaveBillPlanModel>(`${environment.apiUrl}/Rayar/BillPlanRoute/` + n);
  }

  getShortBillPlan(b: billPlanModel[]) {
    return this.http.post<billPlanModel[]>(`${environment.apiUrl}/Rayar/ShortBillPlan`, b);
  }

  getBillPlan1() {
    return this.http.get<SaveBillPlanModel>(`${environment.apiUrl}/Rayar/BillPlan1`);
  }


  PlannedDrivers() {
    return this.http.get<string[]>(`${environment.apiUrl}/Rayar/PlannedDrivers`);
  }

  SavePlannedDrivers(save: boolean, driverName: string) {
    return this.http.get<string[]>(`${environment.apiUrl}/Rayar/SavePlannedDrivers/` + save + '/' + driverName);
  }

  SetCashCarry(billId: number, cashCarry: boolean) {
    return this.http.get<any>(`${environment.apiUrl}/Rayar/SetCashCarry/` + billId + '/' + cashCarry);
  }

  SaleManEmailBillPlan(b: string[]) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/SaleManEmailBillPlan`, b);
  }

  BillPlanSetDelivered(b: billPlanModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/BillPlanSetDelivered`, b);
  }

  BillPlanSetUnDelivered(b: billPlanModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/BillPlanSetUnDelivered`, b);
  }

  SaveBillPlan1(b: SaveBillPlanModel) {
    return this.http.post<SaveBillPlanModel>(`${environment.apiUrl}/Rayar/SaveBillPlan1`, b);
  }

  SaveBillPlan(b: SaveBillPlanModel) {
    return this.http.post<SaveBillPlanModel>(`${environment.apiUrl}/Rayar/SaveBillPlan`, b);
  }

  getcom(q: string) {
    return this.http.get<comLedger[]>(`${environment.apiUrl}/Rayar/Ledgers/` + q);
  }

  getBakup(q: string) {
    return this.http.get<comLedger[]>(`${environment.apiUrl}/RayarGoogle/` + q);
  }

  getSearchCompany(s: string) {
    if (s)
      return this.http.get<companyModel[]>(`${environment.apiUrl}/Rayar/SearchCompanys/` + s);
    else
      return this.http.get<companyModel[]>(`${environment.apiUrl}/Rayar/SearchCompanys`);
  }

  getCompanys() {
    return this.http.get<companyModel[]>(`${environment.apiUrl}/Rayar/Companys`);
  }

  getCustStatus(customerId: number, status: string) {
    return this.http.get<any>(`${environment.apiUrl}/Rayar/UpdateCustStatus/${customerId}/${status}`);
  }

  getCustMar(b: custmarviewModel) {
    return this.http.post<custmarviewModel[]>(`${environment.apiUrl}/Rayar/CompanyMargins`, b);
  }

  getCustMar1(b: string) {
    return this.http.get<string[]>(`${environment.apiUrl}/Rayar/CompanyMargins1/` + b);
  }

  getCompanys1() {
    return this.http.get<companyModel[]>(`${environment.apiUrl}/Rayar/Companys1`);
  }

  getGrnfollowup() {
    return this.http.get<grnFollowUpModel[]>(`${environment.apiUrl}/Rayar/Grnfollowup`);
  }

  AddEditCompany(b: companyModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddEditCompany`, b);
  }

  getSearchVehicle(s: VehicleModel) {
    return this.http.post<vehicalVm>(`${environment.apiUrl}/Rayar/SearchVehicles`, s);
  }

  getDocumentTracks(s: DocumentTrackModel) {
    return this.http.post<DocumentTrackModel[]>(`${environment.apiUrl}/Rayar/SearchDocumentTracks`, s);
  }

  getVehicles() {
    return this.http.get<VehicleModel[]>(`${environment.apiUrl}/Rayar/Vehicles`);
  }

  AddEditVehicle(b: VehicleModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddEditVehicle`, b);
  }

  AddEditDocTrack(b: DocumentTrackModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddEditDocumentTrack`, b);
  }

  getInventLookups(s: InventLookupModel) {
    return this.http.post<iv>(`${environment.apiUrl}/Rayar/Inventrylookups`, s);
  }

  getSaleslookups(s: SalesLookupModel) {
    return this.http.post<isales>(`${environment.apiUrl}/Rayar/Saleslookups`, s);
  }

  getLoadSheet(s: LoadSheetModel) {
    return this.http.post<SalesLookupModel[]>(`${environment.apiUrl}/Rayar/LoadSheet`, s);
  }

  getSearchAccount(s: string) {
    return this.http.get<accountModel[]>(`${environment.apiUrl}/Rayar/SearchAccounts/` + s);
  }

  getSearcEmp(s: string) {
    return this.http.get<empModel[]>(`${environment.apiUrl}/Rayar/SearchEmps/` + s);
  }

  AddEditAccount(b: accountModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddEditAccount`, b);
  }

  AddEditEmp(b: empModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddEditEmp`, b);
  }

  SetNoTrack(b: empModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/SetNoTrack`, b);
  }

  AddEditResource(b: TimeTrackResourceModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddEditResource`, b);
  }

  getSearchResource(s: string) {
    if (s)
      return this.http.get<TimeTrackResourceModel[]>(`${environment.apiUrl}/Rayar/SearchResources/` + s);
    else
      return this.http.get<TimeTrackResourceModel[]>(`${environment.apiUrl}/Rayar/SearchResources`);
  }

  getResources() {
    return this.http.get<ResourceVModel>(`${environment.apiUrl}/Rayar/Resources`);
  }

  getAttendances(b: AttendanceModel) {
    return this.http.post<AttendanceModel[]>(`${environment.apiUrl}/Rayar/Attendance`, b);
  }

  getEmpAttendances(b: EmpAttendanceModel) {
    return this.http.post<EmpAttendanceModel[]>(`${environment.apiUrl}/Rayar/EmpAttendance`, b);
  }

  SaveEmpAttendRemarks(b: EmpAttendanceModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/SaveEmpAttendRemarks`, b);
  }

  SaveAttendRemarks(b: AttendanceModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/SaveAttendRemarks`, b);
  }

  SaveAttendRemarksOut(b: AttendanceModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/SaveAttendRemarksOut`, b);
  }

  MarkAbsent(b: AttendanceModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkAbsent`, b);
  }

  getSearchInventory(s: InventoryModel) {
    return this.http.post<InventoryModel[]>(`${environment.apiUrl}/Rayar/SearchInventory`, s);
  }

  AddEditInventory(b: InventoryModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddEditInventory`, b);
  }

  SaveGrnDetail(b: accountLedgerModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/SaveGrnDetail`, b);
  }

  SaveRemakrs(b: accountLedgerModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/SaveRemakrs`, b);
  }

  SavePrint(b: BillModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkPrinted`, b);
  }

  getGrn(b: grnModel) {
    return this.http.post<grnModel[]>(`${environment.apiUrl}/Rayar/MtGrn`, b);
  }

  getGrnReport(b: grnModel) {
    return this.http.post<grnModel[]>(`${environment.apiUrl}/Rayar/MtGrnReport`, b);
  }

  MtGrnEmail(b: grnEmailModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MtGrnEmail`, b);
  }

  MtPaymentEmail(b: BankStmtModel[]) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MtBankEmail`, b);
  }

  getemailBks(s: string) {
    return this.http.get<BankStmtModel[]>(`${environment.apiUrl}/Rayar/BankStmtEmail/` + s);
  }

  getAccountLedger(b: accountLedgerModel) {
    return this.http.post<accountLedgerModel[]>(`${environment.apiUrl}/Rayar/AccountLedgers`, b);
  }

  getInvoiceLedger(b: accountLedgerModel) {
    return this.http.post<accountLedgerModel[]>(`${environment.apiUrl}/Rayar/InvoiceLedger`, b);
  }

  getInvoiceLedgerBill() {
    return this.http.get<LedgerBillSumModel[]>(`${environment.apiUrl}/Rayar/LedgerBillSum`);
  }

  getCustomerLedger(b: accountLedgerModel) {
    return this.http.post<accountLedgerModel[]>(`${environment.apiUrl}/Rayar/CustomerLedgers`, b);
  }

  UpdateBillRemakrs(b: BillModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/UpdateBillRemarks`, b);
  }

  UpdateCustomer(b: CustomerModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/UpdateCustomer`, b);
  }

  UpdateCustomerBillPlan(b: CustomerModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/UpdateCustomerBillPlan`, b);
  }
  UpdateDocRemakrs(b: paymentModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/UpdateDocRemakrs`, b);
  }

  UpdateBillTag(b: BillModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/UpdateBillTag`, b);
  }

  UpdateBillDriver(b: billPlanModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/UpdateBillDriver`, b);
  }

  UpdateBill(b: BillModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/UpdateBill`, b);
  }

  UpdateBill1(b: BillModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/UpdateBill1`, b);
  }

  MUpdateBill(b: BillModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MUpdateBill`, b);
  }

  MUpdateBill1(b: BillModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MUpdateBill1`, b);
  }

  AddExpense(b: ExpenseModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddEditExpense`, b);
  }

  AddInvoiceTrack(b: invoiceTrackModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddInvoiceTrack`, b);
  }

  UpdateInvoiceTrack(b: invoiceTrackModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/UpdateInvoiceTrack`, b);
  }

  markExpenseRev(expenseId: number) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkExpReviewed`, { expenseId });
  }

  markExpInAct(expenseId: number) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkExpInActive`, { expenseId });
  }

  getnoteTraks(b: noteTrackModel) {
    return this.http.post<noteTrackModel[]>(`${environment.apiUrl}/Rayar/Notes`, b);
  }

  saveNoteTrack(documentId: noteTrackModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/SaveNoteTrack`, documentId);
  }

  deletenoteTrack(documentId: number) {
    return this.http.get<any>(`${environment.apiUrl}/Rayar/DeleteNoteTrack/` + documentId);
  }

  getDcouments(billId: number, documentType: string) {
    return this.http.get<DocumentModel[]>(`${environment.apiUrl}/BlobStorage/Documents/` + billId + "/" + documentType);
  }

  getmtcollectiondetails(billId: number) {
    return this.http.get<collectionDetailModel[]>(`${environment.apiUrl}/Rayar/mtcollectiondetails/` + billId);
  }

  getSalesMan(billId: number, companyName: string) {
    return this.http.get<string[]>(`${environment.apiUrl}/Rayar/GetSalesMan/` + billId + "/" + companyName);
  }

  getDcoumentsPage(billId: number, documentType: string, p: number, ps: number) {
    return this.http.get<DocumentModel[]>(`${environment.apiUrl}/BlobStorage/Documents/` + billId + "/" + documentType + "/" + p + "/" + ps);
  }

  getDocuments1(page: number, documentType: string) {
    return this.http.get<DocumentModel[]>(`${environment.apiUrl}/BlobStorage/Documents1/` + page + "/" + documentType);
  }

  deleteDocument(documentId: number) {
    return this.http.get<DocumentModel[]>(`${environment.apiUrl}/BlobStorage/DeleteFile/` + documentId);
  }

  getExpenses(entryDate: string, reviewedDate: string, category: string, reviewe: string) {
    return this.http.post<ExpenseModel[]>(`${environment.apiUrl}/Rayar/Expenses`, { category, entryDate, reviewedDate, reviewe });
  }

  getExpensesSum(entryDate: string, reviewedDate: string, category: string, reviewe: string) {
    return this.http.post<ExpenseModel[]>(`${environment.apiUrl}/Rayar/ExpensesSumary`, { category, entryDate, reviewedDate, reviewe });
  }

  getDamages(p: ReturnTrackModel) {
    return this.http.post<ReturnTrackModel[]>(`${environment.apiUrl}/Rayar/Damages`, p);
  }

  getProducts(p: ProductModel) {
    return this.http.post<ProductModel[]>(`${environment.apiUrl}/Rayar/Products`, p);
  }

  getProducts1(p: ProductModel) {
    return this.http.post<ProductModel[]>(`${environment.apiUrl}/Rayar/Products1`, p);
  }

  getBillProdtsInv(p: ProductModel) {
    return this.http.post<BillProductInvModel[]>(`${environment.apiUrl}/Rayar/BillProdtsInv`, p);
  }

  getBillProducts(p: BillProductModel) {
    return this.http.post<BillProductModel[]>(`${environment.apiUrl}/Rayar/BillProducts`, p);
  }

  AddDamageTrack(b: DamageTrackModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddEditDamage`, b);
  }

  markCollectionInactive(collectionId: number) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkCollectionInactive`, { collectionId });
  }

  markReceived(collectionId: number) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkReceived`, { collectionId });
  }

  markAutoReceived(collectionIds: number[]) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkAutoReceived`, { collectionIds });
  }

  markCredited(collectionIds: number[], ids: number[], markbkStRev: boolean, bounced: boolean) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkCredited`, { ids, collectionIds, markbkStRev, bounced });
  }

  markMissing(collectionId: number) {
    return this.http.get<any>(`${environment.apiUrl}/Rayar/MarkMissing/` + collectionId);
  }

  markFollowuped(collectionId: number, remarks: string) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkFollowuped`, { collectionId, remarks });
  }

  markPaid(billId: number) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkPaid`, { billId });
  }

  markSupplied(billId: number) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkSupplied`, { billId });
  }

  markDelivered(billId: number) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkDelivered`, { billId });
  }

  markNew(billId: number) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkNew`, { billId });
  }

  markCancel(billId: number) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkCancel`, { billId });
  }

  markReviewed(collectionId: number) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkReviewed`, { collectionId });
  }

  onAddCollection(b: CollectionModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddCollection`, b);
  }

  onAddCollections(collectionModel: CollectionModel, pendingBillsList: PendingBills[]) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddCollections`, { collectionModel, pendingBillsList });
  }

  onAddCollectionPRN(collectionModel: CollectionModel, pendingBillsList: PendingBills[], epiprnModelList: epiprnModel[]) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddCollectionPRN`, { collectionModel, pendingBillsList, epiprnModelList });
  }

  onMarkNotUsedPRN(epiprnModelList: epiprnModel[]) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkNotUsedPRN`, { epiprnModelList });
  }

  onMarkChecked(billId: number, verifiedRemarks: string) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkChecked`, { billId, verifiedRemarks });
  }

  onAssignCollection(billId: number, personId: number, customerId: number, companyName: string) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AssignCollection`, { billId, personId, customerId, companyName });
  }

  markCancelOrder(orderId: number) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkOrderCancel`, { orderId });
  }

  getOrders(q: string, s: string, pid: number) {
    return this.http.get<OrderTrackModel[]>(`${environment.apiUrl}/Rayar/Orders/` + q + '/' + s + '/' + pid);
  }

  AddEditOrder(b: OrderTrackModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddEditOrder`, b);
  }

  getBillSequence(companyName: string) {
    return this.http.get<string[]>(`${environment.apiUrl}/Rayar/BillSequence/` + companyName);
  }

  getDamageSequence(companyName: string) {
    return this.http.get<string[]>(`${environment.apiUrl}/Rayar/DamageSequence/` + companyName);
  }

  onCollBillSearch(s: string, q: string) {
    return this.http.get<BillModel[]>(`${environment.apiUrl}/Rayar/SearchBill/` + s + "/" + q);
  }

  onBillSearch(q: string, b: BillModel) {
    return this.http.post<BillModel[]>(`${environment.apiUrl}/Rayar/SearchBill/` + q, b);
  }

  getPayments(b: paymentModel) {
    return this.http.post<paymentModel[]>(`${environment.apiUrl}/Rayar/Payments`, b);
  }

  markRevPayments(docid: number, b: paymentModel) {
    return this.http.post<paymentModel[]>(`${environment.apiUrl}/Rayar/MarkRevPayments/${docid}`, b);
  }

  oncollectionBank(b: BankCollectionModel) {
    return this.http.post<CollectionModel[]>(`${environment.apiUrl}/Rayar/MarkCredited1`, b);
  }

  onAutoReview(s: string) {
    return this.http.get<any>(`${environment.apiUrl}/Rayar/AutoReview/${s}`);
  }

  oncollectionBank1(b: BankCollection1Model) {
    return this.http.post<CollectionModel[]>(`${environment.apiUrl}/Rayar/AddCollection2`, b);
  }

  oncollectionBank2(b: BankCollection1Model) {
    return this.http.post<CollectionModel[]>(`${environment.apiUrl}/Rayar/AddCollection3`, b);
  }

  oncollectionMTIR(b: grnModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/SaveCollectionMTIR`, b);
  }


  getCustomerSearchMT(billId: number) {
    return this.http.post<PendingBills[]>(`${environment.apiUrl}/Rayar/MTPendingBills`, { billId });
  }

  onSaleManTrack(b: SaleManTrack) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/SalesManTrack`, b);
  }

  SaveBaskinSaleTrack(b: BSaleManTrackModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/BSalesManTrack`, b);
  }

  getClaimFollowup(b: collectionFollowupModel) {
    return this.http.post<collectionFollowupModel[]>(`${environment.apiUrl}/Rayar/ClaimFollowup`, b);
  }

  SavecollectionFollowupModel(b: collectionFollowupModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/SaveCollectionFollowUp`, b);
  }

  getBSaleManTrackModel(q: String, p: number, a: String, s: string, s1: string, s2: string, s3: string, s4: string, s5: string) {
    if (!s4) s4 = "0";
    if (!s5) s5 = "0";
    return this.http.get<BSaleManTrackModel[]>(`${environment.apiUrl}/Rayar/BSalesManTrackReport/` + q + `/` + p + '/' + a + '/' + s + '/' + s1 + '/' + s2 + '/' + s3 + '/' + s4 + '/' + s5);
  }

  getPendingBillAgeBSaleManTrackModel(q: String, p: number, a: String, s: string, s1: string, s2: string, s3: string, s4: string, s5: string) {
    if (!s4) s4 = "0";
    if (!s5) s5 = "0";
    return `PendingBillAgeBSalesManTrackReport/` + q + `/` + p + '/' + a + '/' + s + '/' + s1 + '/' + s2 + '/' + s3 + '/' + s4 + '/' + s5;
  }

  oncollsView(q: string, b: BillModel) {
    return this.http.post<CollectionModel[]>(`${environment.apiUrl}/Rayar/ViewColls/` + q, b);
  }

  onInvoiceView(b: InvoiceModel) {
    return this.http.post<InvoiceModel[]>(`${environment.apiUrl}/Rayar/SearchInvoice`, b);
  }

  AddInvoice(b: InvoiceModel) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddEditInvoice`, b);
  }

  markInvoiceInactive(invoiceId: number) {
    return this.http.post<any>(`${environment.apiUrl}/Rayar/MarkInvoiceInAct`, { invoiceId });
  }

  getCollsSearch(s: string, q: string) {
    return this.http.get<CollectionModel[]>(`${environment.apiUrl}/Rayar/SearchCollsBy/` + s + '/' + q);
  }

  getCollsmt(s: string, q: string) {
    return this.http.get<CollectionMtModel[]>(`${environment.apiUrl}/Rayar/SearchCollsmtBy/` + s + '/' + q);
  }

  getCollectionPendingReview() {
    return this.http.get<CollectionPendingReview[]>(`${environment.apiUrl}/Rayar/CollectionPendingReview`);
  }

  getPersonCollectionReport(s: number) {
    return this.http.get<PersonCollectionReport[]>(`${environment.apiUrl}/Rayar/PersonCollectionReport/` + s);
  }

  getCollectionPersonVolumneByMonth(s: number) {
    return this.http.get<CollectionPersonVolumneByMonth[]>(`${environment.apiUrl}/Rayar/CollectionPersonVolumneByMonth/` + s);
  }

  getPendingBillsByPerson() {
    return this.http.get<PendingBillsByPerson[]>(`${environment.apiUrl}/Rayar/PendingBillsByPerson`);
  }

  getCustomerSearch(customerName: string, billNumber: string, billValue: number, companyName: string) {
    return this.http.post<PendingBills[]>(`${environment.apiUrl}/Rayar/BillsCustomerSearch`, { customerName, billNumber, billValue, companyName });
  }

  getCustomerSearch1(customerName: string, billNumber: string, billValue: number) {
    return this.http.post<PendingBills[]>(`${environment.apiUrl}/Rayar/BillsCustomerSearch1`, { customerName, billNumber, billValue });
  }

  getPendingFollowup() {
    return this.http.get<PendingFollowUpModel[]>(`${environment.apiUrl}/Rayar/PendingFollowUpModel`);
  }

  getPendingFollowup1(c: number) {
    return this.http.get<PendingFollowUpModel[]>(`${environment.apiUrl}/Rayar/PendingFollowUpModel/` + c);
  }

  getdamageReport() {
    return this.http.get<damageReport[]>(`${environment.apiUrl}/Rayar/DiscountDamageReport`);
  }

  getPendingBillAge() {
    return this.http.get<PendingBills[]>(`${environment.apiUrl}/Rayar/PendingBillAge`);
  }

  getBillReportNew() {
    return this.http.get<billPlanModel[]>(`${environment.apiUrl}/Rayar/BillReportNew`);
  }

  getCustomerReport(routeName: string) {
    return this.http.get<CustomerModel[]>(`${environment.apiUrl}/Rayar/CustomerReport/` + routeName);
  }

  SalesManTrackReport(b: SaleManTrack) {
    return this.http.post<SaleManTrackModel[]>(`${environment.apiUrl}/Rayar/SalesManTrackReport`, b);
  }

  getCustomerRoute() {
    return this.http.get<CustomerModel[]>(`${environment.apiUrl}/Rayar/GetCustomerRoute`);
  }

  downloadFile(q: string): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'GET',
      `${environment.apiUrl}/Rayar/` + q,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  downloadFilePost(q: string, b: any): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiUrl}/Rayar/` + q,
      b,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  uploadAddBilFile(file: Blob, q: BillModel) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('jsonString', JSON.stringify(q));
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddBill`, formData);
  }

  uploadAddCustFile(file: Blob, q: CustomerModel) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('jsonString', JSON.stringify(q));
    return this.http.post<any>(`${environment.apiUrl}/Rayar/AddCustomerSales`, formData);
  }

  public uploadFile(file: Blob, q: string): Observable<HttpEvent<void>> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiUrl}/Rayar/` + q,
      formData,
      {
        reportProgress: true
      }));
  }

  public uploadToBlob(file: Blob, q: string): Observable<HttpEvent<void>> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiUrl}/BlobStorage/` + q,
      formData,
      {
        reportProgress: true
      }));
  }

  uploadToBlob1<T>(file: Blob, q: string): Observable<HttpEvent<T[]>> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiUrl}/BlobStorage/` + q,
      formData,
      {
        reportProgress: true
      }));
  }

  downloadBlobFilePost(q: string, b: any): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.apiUrl}/BlobStorage/` + q,
      b,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
}
