  import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
  import { User, Role, billPlanModel, billsumModel, CollectonPersonModel, TimeTrackResourceModel, AttendanceModel, VehicleModel, DocumentTrackModel, empModel, BillModel, EmpAttendanceModel, noteTrackModel } from '../models';
  import { RayarApiService } from '../_services/rayar-api.service';
  import { AuthenticationService } from '../_services/authentication.service';
  import { HttpEventType } from '@angular/common/http';
  import { formatDate } from '@angular/common';
  import { locationService } from '../_services/locationService';
  import { NgxImageCompressService } from 'ngx-image-compress';
  import jsPDF from 'jspdf';

  @Component({
    selector: 'app-driverview',
    templateUrl: './driverview.component.html',
    styleUrls: ['./driverview.component.css']
  })
  export class DriverviewComponent implements OnInit {
    loading = false;
    error = '';
    accledgersAll: billPlanModel[] = [];
    billSum: billsumModel[] = [];
    billSum1: billsumModel = new billsumModel();
    currentUser: User;
    selecteddriver: string = '0';
    selectedcompany: string = '0';
    drivers: string[] = [];
    vehicles: VehicleModel[] = [];

    constructor(private authenticationService: AuthenticationService,
      private locationService: locationService,
      private imageCompress: NgxImageCompressService,
      private api: RayarApiService) {
      this.currentUser = this.authenticationService.currentUserValue;
      this.reload();
    }

    reload() {
      this.selecteddriver = '0';
      this.selectedcompany = '0';
      if (this.currentUser.role == Role.Company) {
        this.selectedcompany = this.currentUser.profileId;
      }
      this.api.PlannedDrivers().subscribe(result => {
        this.drivers = result;
        if (this.currentUser.role == Role.Driver) {
          var filteredStrings = result.filter((str) => str.toLowerCase().includes(this.currentUser.profileId.toLowerCase()));
          if (filteredStrings.length > 0)
            this.selecteddriver = this.currentUser.profileId.toLowerCase();
        }
        this.getBills(0);
        this.getEmpAttends(0);
        this.getVehicles();
        this.getResources();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
    }
    empsAll: empModel[] = [];

    getResources() {
      this.api.getResources()
        .subscribe(result => {
          this.empsAll = result.employees.sort((a, b) => a.employeeName.localeCompare(b.employeeName));
          this.onCollBillSearch();
        }, error => {
          this.error = error;
          this.loading = false; console.error(error);
        });
    }

    onClickDelivered(u: billPlanModel) {
      this.api.markDelivered(u.billId)
        .subscribe(result => {
          this.onCollBillSearch();
        }, error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
    }

    onDownloadDocument(i: number, f: string) {
      this.api.downloadBlobFilePost("Download/" + i, null).subscribe(
        data => {
          switch (data.type) {
            case HttpEventType.Response:
              const downloadedFile = new Blob([data.body], { type: data.body.type });
              const a = document.createElement('a');
              a.setAttribute('style', 'display:none;');
              document.body.appendChild(a);
              a.download = f;
              a.href = URL.createObjectURL(downloadedFile);
              a.target = '_blank';
              a.click();
              document.body.removeChild(a);
              break;
          }
        },
        () => {
        }
      );
    }

    getVehicles() {
      var search: VehicleModel = new VehicleModel();
      search.page = 0;
      search.category = "Vehicle";
      this.api.getSearchVehicle(search).subscribe(result => {
        this.vehicles = result.vehicles;
        this.docTracks = result.vehicleDocs;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
    }

    ngOnInit(): void {
    }

    displayText(u: EmpAttendanceModel) {
      if (!u.checkIn || u.checkIn == "4") return "A"
      if (u.checkIn == "1") return "F";
      if (u.checkIn == "2") return "H";
      if (u.checkIn == "3") return "PL";
    }

    searchlModel = new AttendanceModel();
    searchActive: string = "";
    selectedDate1 = Date.now();
    selectedDate = Date.now();
    bills: AttendanceModel[] = [];
    docTracks: DocumentTrackModel[] = [];
    billsAll: AttendanceModel[] = [];



    upload(event) {
      this.error = "";
      const reader = new FileReader();
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (file.type.startsWith("image")) {
            var imageSrc = reader.result as string;
            this.imageCompress.compressFile(imageSrc, 50, 100).then((compressedImage) => {
              var doc = new jsPDF();
              doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
              this.onUploadVISIT(doc.output('blob'));
            });
          }
        };
      }
    }
    @ViewChild('inputFile') inputFile: ElementRef;

    onUploadVISIT(file: Blob) {
      var vsturl = `UploadPetrol/${this.selAcled.attendanceId}`;
      this.api.uploadToBlob(file, vsturl).subscribe(
        data => {
          if (data) {
            switch (data.type) {
              case HttpEventType.UploadProgress:
                break;
              case HttpEventType.Response:
                this.inputFile.nativeElement.value = '';
                this.getBills(this.searchlModel.page);
                break;
            }
          }
        },
        error => {
          this.inputFile.nativeElement.value = '';
        }
      );
    }
    selAcled: AttendanceModel = new AttendanceModel();

    selectledger(u: AttendanceModel) {
      this.selAcled = u;
    }

    downloadPetrol(u: AttendanceModel) {
      this.api.downloadBlobFilePost("Download1/" + u.attendanceId + "/Petrol", null).subscribe(
        data => {
          switch (data.type) {
            case HttpEventType.Response:
              var fileName = u.attendanceId + ".jpg";
              if (data.body.type == "application/zip") {
                fileName = u.attendanceId + ".zip"
              } else if (data.body.type == "application/pdf") {
                fileName = u.attendanceId + ".pdf"
              }
              const a = document.createElement('a');
              a.setAttribute('style', 'display:none;');
              document.body.appendChild(a);
              a.download = fileName;
              a.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
              a.target = '_blank';
              a.click();
              document.body.removeChild(a);
              break;
          }
        },
        () => {
        }
      );
    }

    isSelectResStarte() {
      var d3 = this.selecteddriver;
      var isSelectResStarted = false;
      if (this.currentUser.role === Role.Driver) {
        this.bills.forEach(function (hero) {
          if ((hero.resourceName.toLowerCase() == d3)) {
            if (hero.checkIn) {
              isSelectResStarted = true;
            }
          }
        });
      } else {
        return true;
      }
      return isSelectResStarted;
    }

    getData1() {
      var d3 = this.selecteddriver;
      return this.bills.filter(function (hero) {
        return d3 == "overall" || (hero.resourceName.toLowerCase() == d3);
      });
    }

    onBlurEvent(b: billPlanModel) {
      var d = new BillModel();
      d.billId = b.billId; d.billRemarks = b.billRemarks;
      this.api.UpdateBillRemakrs(d).subscribe(result => {
      }, error => { this.error = error; this.loading = false; console.error(error) });
    }

    saveNoteTrack1(b: billPlanModel) {
      if (b.billRemarks) {
        var n = new noteTrackModel();
        n.id = b.customerId;
        n.noteType = "CustNote";
        n.internal = true;
        n.customerId = b.customerId;
        n.billId = b.billId;
        n.notes = b.billRemarks;
        n.removeFollowUp = false;
        this.api.saveNoteTrack(n).subscribe(result => {
        }, error => {
          this.loading = false; console.error(error)
        });
      }
    }

    showRecieved(u: billPlanModel) {
      var d3 = this.selecteddriver;
      if ((u.deliveryStatus == 'Delivered' ||u.deliveryStatus == 'SDelivered' )&& d3.toLowerCase() == "Godown supply".toLowerCase()) return true;
      var dd = this.bills.filter(function (hero) {
        return (hero.resourceName.toLowerCase() == d3);
      });
      return dd[0].reasonOut && (u.deliveryStatus == 'Delivered'||u.deliveryStatus == 'SDelivered') && dd[0].checkInTime && dd[0].checkOutTime && !u.supplied;
    }

    showDelivered(u: billPlanModel) {
      var d3 = this.selecteddriver;
      var dd = this.bills.filter(function (hero) {
        return (hero.resourceName.toLowerCase() == d3);
      });
      if (this.currentUser.role == Role.Admin) {
        return dd[0].checkInTime && !dd[0].reasonOut;
      } else {
        return dd[0].checkInTime && !dd[0].checkOutTime;
      }
    }

    showDone(u: AttendanceModel) {
      if (!u.reasonOut) return false;
      var isAnyDelNotMarkSuppliedExists = false;
      var d3 = this.selecteddriver;
      var d4 = this.selectedcompany;
      this.accledgersAll.filter(function (hero) {
        return (hero.driverName.toLowerCase() == d3) && (d4 == '0' || hero.companyName == d4)
      }).forEach(c => {
        if ((c.deliveryStatus == 'Delivered'||c.deliveryStatus == 'SDelivered') && !c.supplied) {
          isAnyDelNotMarkSuppliedExists = true;
        } else {

        }
      })

      if (isAnyDelNotMarkSuppliedExists) return false;
      else return true;
    }

    onCheckboxChange(u: AttendanceModel) {
      this.api.SavePlannedDrivers(false, u.resourceName).subscribe(() => {
        this.loading = false;
        this.reload();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    }

    dateDifference(date2: Date) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      var date1 = new Date();
      const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
      const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    getDataDocDownload() {
      var lst = [];
      var d3 = this.selecteddriver;
      this.bills.forEach(x1 => {
        if ((x1.resourceName.toLowerCase() == d3)) {
          if (x1.vehicleName) {
            this.docTracks.forEach(x => {
              if (x.vehicleName.toLowerCase() == x1.vehicleName.toLowerCase()) {
                if (x.documentId && x.documentId > 0)
                  lst.push(x);
              }
            });
          }
        }
      });
      return lst.sort((a, b) => a.documentName.localeCompare(b.documentName));;
    }


    getData12() {
      var lst = [];
      var lst1 = [];
      var d3 = this.selecteddriver;
      this.bills.forEach(x1 => {
        if ((x1.resourceName.toLowerCase() == d3)) {
          if (x1.vehicleName) {

            this.billsAll.forEach(x => {
              if (x.vehicleName && x.vehicleName.toLowerCase() == x1.vehicleName.toLowerCase() && x.checkOut) {
                var odo = parseFloat(x.checkOut.toString());
                if (isNaN(odo)) odo = 0;
                lst1.push(odo);
              }
            });
            var currentOdoMeter = 0;
            if (lst1.length > 0) currentOdoMeter = Math.max.apply(Math, lst1);
            this.docTracks.forEach(x => {
              if (x.vehicleName.toLowerCase() == x1.vehicleName.toLowerCase()) {
                var exp = this.dateDifference(new Date(x.validTillDate));
                if (x.documentName == "Service") {
                  var lastServicedOdo = 0;
                  if (x.notes) {
                    lastServicedOdo = parseFloat(x.notes.toString());
                    if (isNaN(lastServicedOdo)) lastServicedOdo = 0;
                  }
                  if ((currentOdoMeter - lastServicedOdo > x.serviceMileLimit))
                    lst.push("Service: " + exp + " days ago Serviced: " + new Date(x.validTillDate).toDateString() + ";NextServiceMile:  " + x.serviceMileLimit + ";Last Serviced Odo:  " + x.notes + ";Current Odo: " + currentOdoMeter + "; Miles run:" + (currentOdoMeter - lastServicedOdo));
                } else {
                  if (exp <= 10)
                    lst.push(x.documentName + " Expires in " + exp + " days; Valid Till: " + new Date(x.validTillDate).toDateString());
                }
              }
            });
          }
        }
      });
      return lst;
    }

    getvehicleData() {
      var lst = [];
      var d3 = this.selecteddriver;
      this.bills.forEach(x1 => {
        if ((x1.resourceName.toLowerCase() == d3)) {
          if (x1.vehicleName) {
            this.billsAll.forEach(x => {
              if (x.vehicleName && x.vehicleName.toLowerCase() == x1.vehicleName.toLowerCase()) {
                lst.push(x);
              }
            });
          }
        }
      });

      for (let i = 0; i < lst.length - 1; i++) {
        var codo = 0;
        if (lst[i].checkOut)
          codo = parseFloat(lst[i].checkOut.toString());
        if (isNaN(codo)) codo = 0;

        var podo = 0;
        if (lst[i + 1].checkOut)
          podo = parseFloat(lst[i + 1].checkOut.toString());
        if (isNaN(podo)) podo = 0;
        var f = 0;
        if (lst[i].fuel)
          f = parseFloat(lst[i].fuel.toString());
        if (isNaN(f)) f = 0;

        lst[i].miles = codo - podo;
        if (f == 0)
          lst[i].mileage = 0;
        else
          lst[i].mileage = ((codo - podo) / f).toFixed(2);
      }
      return lst;
    }

    getmiles() {
      var lst = [];
      var d3 = this.selecteddriver;
      this.bills.forEach(x1 => {
        if ((x1.resourceName.toLowerCase() == d3)) {
          if (x1.vehicleName) {
            this.billsAll.forEach(x => {
              if (x.vehicleName && x.vehicleName.toLowerCase() == x1.vehicleName.toLowerCase()) {
                lst.push(x);
              }
            });
          }
        }
      });

      for (let i = 0; i < lst.length - 1; i++) {
        var codo = 0;
        if (lst[i].checkOut)
          codo = parseFloat(lst[i].checkOut.toString());
        if (isNaN(codo)) codo = 0;

        var podo = 0;
        if (lst[i + 1].checkOut)
          podo = parseFloat(lst[i + 1].checkOut.toString());
        if (isNaN(podo)) podo = 0;
        var f = 0;
        if (lst[i].fuel)
          f = parseFloat(lst[i].fuel.toString());
        if (isNaN(f)) f = 0;

        lst[i].miles = codo - podo;
        if (f == 0)
          lst[i].mileage = 0;
        else
          lst[i].mileage = ((codo - podo) / f).toFixed(2);
      }
      if (lst.length > 0)
        return lst[0].miles;
      else return 0;
    }

    getdriverattend() {
      var lst = [];
      var d3 = this.selecteddriver;
      this.bills.forEach(x1 => {
        if ((x1.resourceName.toLowerCase() == d3)) {
          if (x1.driverName) {
            this.empAttends.forEach(x => {
              if (x.employeeName.toLowerCase() == x1.driverName.toLowerCase()) {
                lst.push(x);
              }
            });
          }
        }
      });
      return lst.reverse();
    }

    gethelperAtted() {
      var lst = [];
      var d3 = this.selecteddriver;
      this.bills.forEach(x1 => {
        if ((x1.resourceName.toLowerCase() == d3)) {
          if (x1.helperName) {
            this.empAttends.forEach(x => {
              if (x.employeeName.toLowerCase() == x1.helperName.toLowerCase()) {
                lst.push(x);
              }
            });
          }
        }
      });
      return lst.reverse();
    }

    searchlModelEA = new EmpAttendanceModel();
    empAttends: EmpAttendanceModel[] = [];

    getEmpAttends(p: number) {
      this.error = "";
      var d = new Date(formatDate(new Date(new Date().setDate(1)), 'yyyy-MM-dd', 'en'));
      this.searchlModelEA.fromDate = d;
      this.searchlModelEA.toDate = new Date(this.selectedDate1);
      this.searchlModelEA.page = p;
      this.searchlModelEA.employeeId = -1;
      this.searchlModelEA.uniqId = null;
      this.api.getEmpAttendances(this.searchlModelEA).subscribe(result => {
        this.empAttends = result;
      }, error => {
        this.error = error; this.loading = false; console.error(error);
      });
    }

    getBills(p: number) {
      if (this.searchActive == "")
        this.searchlModel.isReviewed = null;
      else if (this.searchActive == "true")
        this.searchlModel.isReviewed = true;
      else if (this.searchActive == "false")
        this.searchlModel.isReviewed = false;
      var d = new Date();
      d.setDate(d.getDate() - 7);
      this.searchlModel.fromDate = new Date(d);
      this.searchlModel.toDate = new Date(this.selectedDate1);
      this.searchlModel.page = p;
      this.searchlModel.uniqId = null;
      this.searchlModel.timeTrackResourceId = Number(this.searchlModel.timeTrackResourceId);
      this.api.getAttendances(this.searchlModel).subscribe(result => {
        var today = new Date().getDate();
        this.billsAll = result;
        this.bills = result.filter(function (hero) {
          return (new Date(hero.dateValue).getDate() == today);
        });
      }, error => {
        this.error = error; this.loading = false; console.error(error);
      });
    }

    editGrn(u: AttendanceModel) {
      this.bills.forEach(x => {
        x.isEdit = u.uniqId == x.uniqId;
      });
    }

    saveGrn(u: AttendanceModel) {
      if (u.driverName && u.helperName && u.vehicleName && (u.driverName != u.helperName)) {
        u.checkIn = "1";
        if (u.checkOut != null)
          u.checkOut = u.checkOut.toString();
        if (u.reason != null)
          u.reason = u.reason.toString();
        u.entryDate = u.dateValue;
        var d3 = u.driverName.toLowerCase();
        var dal = this.empsAll.filter(function (hero) {
          return hero.employeeName.toLowerCase() == d3;
        });
        u.driverPhone = dal[0].phone.toString();
        var h3 = u.helperName.toLowerCase();
        var dal1 = this.empsAll.filter(function (hero) {
          return hero.employeeName.toLowerCase() == h3;
        });
        u.helperPhone = dal1[0].phone.toString();
        this.api.SaveAttendRemarks(u).subscribe(() => {
          this.loading = false;
          this.getBills(this.searchlModel.page);
        }, error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
      }
    }

    saveOut(u: AttendanceModel) {
      if (u.checkOut != null && u.fuel != null && u.reason != null) {
        var isAnyDelNotExists = false;
        var d3 = this.selecteddriver;
        var d4 = this.selectedcompany;
        this.accledgersAll.filter(function (hero) {
          return (hero.driverName.toLowerCase() == d3) && (d4 == '0' || hero.companyName == d4)
        }).forEach(c => {
          if (c.billType == "NB" && !c.deliveryStatus) {
            isAnyDelNotExists = true;
          }
        })
        if (isAnyDelNotExists) {
          confirm("Mark All Bill Delivered/NotDelivered")
          return;
        }
        if ((u.fuel > 0 || Number(u.reason) > 0) && u.doc <= 0) {
          confirm("Petrol/Gas Photo Missing.")
          return;
        }
        var dal = this.billSum.filter(function (hero) {
          return hero.driverName.toLowerCase() == d3;
        });

        u.billCount = dal[0].billCount;
        u.billCount1 = dal[0].billCount1;
        u.billValue = dal[0].billValue;
        u.billValue1 = dal[0].billValue1;
        u.customerCount = dal[0].customerCount;
        u.customerCount1 = dal[0].customerCount1;
        
        if (confirm("Are you sure to Complete Trip")) {
          this.api.SaveAttendRemarksOut(u).subscribe(() => {
            this.loading = false;
            this.getBills(this.searchlModel.page);
          }, error => {
            this.error = error;
            this.loading = false; console.error(error)
          });
        }
      }
    }

    saveOutadmin(u: AttendanceModel) {
      if (u.checkOut != null && u.fuel != null && u.reason != null) {
        var isAnyDelNotExists = false;
        var d3 = this.selecteddriver;
        var d4 = this.selectedcompany;
        this.accledgersAll.filter(function (hero) {
          return (hero.driverName.toLowerCase() == d3) && (d4 == '0' || hero.companyName == d4)
        }).forEach(c => {
          if (c.billType == "NB" && !c.deliveryStatus) {
            isAnyDelNotExists = true;
          }
        })
        if (isAnyDelNotExists) {
          confirm("Mark All Bill Delivered/NotDelivered")
          return;
        }
        var dal = this.billSum.filter(function (hero) {
          return hero.driverName.toLowerCase() == d3;
        });
        u.billCount = dal[0].billCount;
        u.billCount1 = dal[0].billCount1;
        u.billValue = dal[0].billValue;
        u.billValue1 = dal[0].billValue1;
        u.customerCount = dal[0].customerCount;
        u.customerCount1 = dal[0].customerCount1;
        if (confirm("Driver,Helper,Vehicle,Fuel,OdoMeter,Delivery are Correct?")) {
          u.reasonOut = this.currentUser.userName + "AdminConfirmed "+ dal[0].shortDelBillCount +' Short Del';
          this.api.SaveAttendRemarksOut(u).subscribe(() => {
            this.loading = false;
            this.getBills(this.searchlModel.page);
          }, error => {
            this.error = error;
            this.loading = false; console.error(error)
          });
        }
      }
    }

    cancelGrn(u: AttendanceModel) {
      this.bills.forEach(x => {
        x.isEdit = false;
      });
    }

    downloadBills(u: number) {
      this.api.downloadBlobFilePost("Download1/" + u + "/Order", null).subscribe(
        data => {
          switch (data.type) {
            case HttpEventType.Response:
              var fileName = u + ".jpg";
              if (data.body.type == "application/zip") {
                fileName = u + ".zip"
              } else if (data.body.type == "application/pdf") {
                fileName = u + ".pdf"
              }
              const a = document.createElement('a');
              a.setAttribute('style', 'display:none;');
              document.body.appendChild(a);
              a.download = fileName;
              a.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
              a.target = '_blank';
              a.click();
              document.body.removeChild(a);
              break;
          }
        },
        () => {
        }
      );

    }

    markDelivered(u: billPlanModel, s : boolean) {
      if ((this.currentUser.role == Role.Driver || this.currentUser.role == Role.Admin)) {
        this.locationService.getPosition().then(pos => {
          u.pLatitude = pos.lat;
          u.pLongitude = pos.lng;
          u.shortDelivery = s;
          var d = this.getvehicleData();
          u.rhelperName = d[0].helperName;
          u.rdriverName = d[0].driverName;
          this.api.BillPlanSetDelivered(u).subscribe(() => {
            this.loading = false;
            this.onCollBillSearch();
          }, error => {
            this.error = error;
            this.loading = false; console.error(error)
          });
        });
      }
    }

    markUnDelivered(u: billPlanModel) {
      this.locationService.getPosition().then(pos => {
        u.pLatitude = pos.lat;
        u.pLongitude = pos.lng;
        var d = this.getvehicleData();
        u.rhelperName = d[0].helperName;
        u.rdriverName = d[0].driverName;
        this.api.BillPlanSetUnDelivered(u).subscribe(() => {
          this.loading = false;
          this.onCollBillSearch();
        }, error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
      });
    }

    showDetail(u: billsumModel) {
      this.selecteddriver = u.driverName.toLowerCase();
    }

    lat: number = 13.0386729;
    lng: number = 77.5495909;
    directions: any = [];
    waypoints: any = [];
    isMapView: boolean = false;
    markers: any = [];

    renderOptions = { suppressMarkers: true };
    markerOptions = {
      origin: {
        icon: '',
        label: {
          color: 'white', text: '1'
        }
      },
      destination: {
        icon: '',
        label: {
          color: 'white', text: ''
        }
      },
      waypoints: []
    };

    lastmapdriver: string = '';
    showMap(u: billsumModel) {
      this.selecteddriver = u.driverName.toLowerCase();
      if (this.lastmapdriver == u.driverName) {
        this.isMapView = !this.isMapView;
      } else {
        this.isMapView = true;
        this.lastmapdriver = u.driverName;
      }
      if (!this.isMapView) return;
      this.markerOptions.waypoints = [];
      this.waypoints = [];
      var d3 = u.driverName;
      this.directions = [];
      var dynamic = [];
      var dal = this.accledgersAll.filter(function (hero) {
        return (d3 == "0" || hero.driverName == d3);
      });
      if (dal.length > 2) {
        dynamic = [{ origin: "", destination: "" }];
        var custAdd = [];
        dal.sort((a, b) => a.driverName.localeCompare(b.driverName) || a.serial - b.serial)
          .forEach((currentValue, index) => {
            console.log(custAdd.length + 1 + ':::' + currentValue.serial + ':::' + currentValue.customerName + ':::' + currentValue.address);
            this.waypoints.push({
              location: currentValue.address,
              stopover: false,
            });
            var k = currentValue.customerName + '\n' +
              currentValue.address + '\n' + 'Serial::' +
              currentValue.serial + ':: ' +
              currentValue.companyName + '::$ ' +
              currentValue.billValue + '::# ' +
              currentValue.billNumber + '\n';
            if (currentValue.pLatitude)
              this.markers.push({
                lat: currentValue.pLatitude,
                lng: currentValue.pLongitude,
                label: currentValue.serial,
                label1: k
              });
            this.markerOptions.waypoints.push({
              title: k,
              icon: {
                url: 'https://maps.google.com/mapfiles/ms/icons/green.png',
                labelOrigin: new google.maps.Point(15, 10)
              },
              label: { color: 'white', text: (currentValue.serial).toString() }
            });
            custAdd.push(currentValue.address);
          });
        dynamic[0].origin = custAdd[0];
        this.markerOptions.origin.icon = this.markerOptions.waypoints[0].icon.url;
        var la = this.markerOptions.waypoints[this.markerOptions.waypoints.length - 1];
        this.markerOptions.destination.icon = la.icon.url;
        this.markerOptions.destination.label.text = la.label.text;
        dynamic[0].destination = custAdd[custAdd.length - 1];
        this.directions = dynamic;
      } else {

      }
    }

    CollPersons: CollectonPersonModel[] = [];

    onCollBillSearch() {
      this.api.getCollPersons1().subscribe(r => {
        this.CollPersons = r;
        var sales = [];
        r.forEach((currentValue) => {
          sales.push(currentValue.name);
        });
        var isSales = this.currentUser.role === Role.Sales;
        var isCompany = this.currentUser.role === Role.Company;
        var c = this.selectedcompany;
        this.api.getBillPlanRoute(0).subscribe(result => {
          this.accledgersAll = result.billPlanModels.filter(function (hero) {
            return hero.driverName != '-1' && (!isSales || (sales.includes(hero.salesPersonName))) && (!isCompany || hero.companyName == c);
          });
          this.accledgersAll.forEach((currentValue, index) => {
            currentValue.driverName1 = currentValue.driverName;
            currentValue.showDetail = false;
          });
          this.refresh();
        }, error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
      });

    }

    getRouteSumm() {
      var d3 = this.selecteddriver;
      var retarr = [];
      var bs = this.billSum.filter(function (f) {
        return f.driverName.toLowerCase() == d3.toLowerCase();
      });
      
      if (bs.length > 0)
        retarr.push("Delivered Bills : " + bs[0].billCount1 + "(" + bs[0].shortDelBillCount + ") ; Not Delivered Bills : " + (bs[0].billCount - bs[0].billCount1)
          + " ; Total Bills : " + bs[0].billCount + " ; Performance : " + ((bs[0].billCount1 / bs[0].billCount) * 100).toFixed(2)
          + " %");
      return retarr;
    }

    refresh() {
      this.billSum = [];
      this.accledgersAll.map(item => item.driverName)
        .filter((value, index, self) => self.indexOf(value) === index)
        .forEach(drivnername => {
          var dal = this.accledgersAll.filter(function (hero) {
            return hero.driverName == drivnername;
          });

          var dal1 = this.accledgersAll.filter(function (hero) {
            return hero.driverName == drivnername && (hero.deliveryStatus == 'Delivered'||hero.deliveryStatus == 'SDelivered')
          });

          let count1 = 0;
          let total1 = 0;
          let count = 0;
          let total = 0;
          let sdbctotal = 0;
          for (let data of dal) {
            total += data.billValue;
            count += 1;
            if (data.deliveryStatus == 'Delivered'|| data.deliveryStatus == 'SDelivered') {
              total1 += data.billValue;
              count1 += 1;
            }
            if(data.deliveryStatus == 'SDelivered'){
              sdbctotal +=1;
            }
          }
          var billsum = new billsumModel();
          billsum.driverName1 = drivnername;
          billsum.driverName = drivnername;
          billsum.billCount = count;
          billsum.billValue = total;
          billsum.billCount1 = count1;
          billsum.billValue1 = total1;
          billsum.shortDelBillCount = sdbctotal;
          billsum.customerCount = dal.map(item => item.customerId).filter((value, index, self) => self.indexOf(value) === index).length;
          billsum.customerCount1 = dal1.map(item => item.customerId).filter((value, index, self) => self.indexOf(value) === index).length;
          if (this.drivers.findIndex(x => x === drivnername) >= 0)
            this.billSum.push(billsum);
        });
      this.billSum.sort((a, b) => a.driverName.localeCompare(b.driverName));
      var drs = this.drivers;
      var dal11 = this.accledgersAll.filter(function (hero) { return hero.driverName != '-1' && drs.findIndex((x: string) => x === hero.driverName) >= 0 });
      var dal111 = this.accledgersAll.filter(function (hero) {
        return hero.driverName != '-1' && (hero.deliveryStatus == 'Delivered'||hero.deliveryStatus == 'SDelivered') && drs.findIndex((x: string) => x === hero.driverName) >= 0
      });
      this.billSum1 = new billsumModel();
      let count1 = 0;
      let total1 = 0;
      let count = 0;
      let total = 0;
      for (let data of dal11) {
        total += data.billValue;
        count += 1;
        if (data.deliveryStatus == 'Delivered'||data.deliveryStatus == 'SDelivered') {
          total1 += data.billValue;
          count1 += 1;  
        }
      }
      this.billSum1.driverName = "Overall";
      this.billSum1.billCount = count;
      this.billSum1.billValue = total;
      this.billSum1.billCount1 = count1;
      this.billSum1.billValue1 = total1;
      this.billSum1.customerCount = dal11.map(item => item.customerId).filter((value, index, self) => self.indexOf(value) === index).length;
      this.billSum1.customerCount1 = dal111.map(item => item.customerId).filter((value, index, self) => self.indexOf(value) === index).length;
    }

    goToLink(u: billPlanModel) {
      if (u.mapLink)
        window.open(u.mapLink, "_blank");
    }

    getData() {
      var d3 = this.selecteddriver;
      var d4 = this.selectedcompany;
      return this.accledgersAll.filter(function (hero) {
        return (hero.driverName.toLowerCase() == d3) && (d4 == '0' || hero.companyName == d4)
      }).sort((a, b) => a.serial - b.serial);
    }

  }
