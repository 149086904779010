<div class="row">
    <div class="col">
        <div *ngIf="!isAddCustomerClicked">
            <div class="form-row">
                <div class="col-md-2">
                    <label>Name</label>
                    <input class="form-control" type="text" [(ngModel)]="searchCustomerName" />
                </div>
                <div class="col-md-2">
                    <label for="role">Track</label>
                    <select class="form-control" [(ngModel)]="searchTrack">
                        <option value="All" selected>All</option>
                        <option value="Yes" selected>Yes</option>
                        <option value="No" selected>No</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <label></label>
                    <div>
                        <button class="btn btn-outline-primary" (click)="getCollPesons()">Search</button>
                        <button class="btn btn-outline-primary" (click)="onCustSearchReset()">Reset</button>
                    </div>
                </div>
                <div class="col-md-2">
                    <label></label>
                    <div>
                        <button class="btn btn-outline-primary" (click)="oAddCustomer()">Add</button>
                    </div>
                </div>
            </div>
            <br>
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Track</th>
                        <th scope="col" *DataCustomAclUsers="'Admin:mahendran:santhanam'">Basic Salary</th>
                        <th scope="col" *DataCustomAclUsers="'Admin:mahendran:santhanam'">Incentive</th>
                    </tr>
                </thead>
                <tbody *ngFor="let u of getcustomers()">
                    <tr>
                        <td>
                            <button class="btn btn-outline-primary" (click)="onEditCustomer(u)">Edit</button>
                            <button class="btn btn-outline-primary"
                                (click)="SetNoTrack(u)">{{!u.track?'Track':'NoTrack'}}</button>
                        </td>
                        <td>{{u.employeeName}}</td>
                        <td>{{u.phone}}</td>
                        <td>{{u.track}}</td>
                        <td *DataCustomAclUsers="'Admin:mahendran:santhanam'">{{u.salary}}</td>
                        <td *DataCustomAclUsers="'Admin:mahendran:santhanam'">{{u.incentive}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="isAddCustomerClicked">
            <form [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
                <div class="form-row">
                    <div class="col-md-2">
                        <label for="employeeName">Name</label>
                        <input type="text" formControlName="employeeName" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.employeeName.errors }" />
                        <div *ngIf="submitted && f.employeeName.errors" class="invalid-feedback">
                            <div *ngIf="f.employeeName.errors.required">Name is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="phone">Phone</label>
                        <input type="text" formControlName="phone" class="form-control" maxlength="2000"
                            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                            <div *ngIf="f.phone.errors.required">Phone is required</div>
                        </div>
                    </div>
                    <div class="col-md-2" *DataCustomAclUsers="'Admin:mahendran:santhanam'">
                        <label for="salary">Salary</label>
                        <input type="number" formControlName="salary" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.salary.errors }" />
                        <div *ngIf="submitted && f.salary.errors" class="invalid-feedback">
                            <div *ngIf="f.salary.errors.required">salary is required</div>
                        </div>
                    </div>
                    <div class="col-md-2" *DataCustomAclUsers="'Admin:mahendran:santhanam'">
                        <label for="incentive">Incentive</label>
                        <input type="number" formControlName="incentive" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.incentive.errors }" />
                        <div *ngIf="submitted && f.incentive.errors" class="invalid-feedback">
                            <div *ngIf="f.incentive.errors.required">Incentive is required</div>
                        </div>
                    </div>
                </div>
                <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Save
                </button>
                <button class="btn btn-outline-primary" (click)="onCloseAddCust()">close</button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>
    </div>
</div>