<div class="card">
    <h4 class="card-header">Inventorys</h4>
    <div class="card-body">
        <div *ngIf="!isAddCustomerClicked">
            <div class="form-row">
                <div class="col-md-2">
                    <label>From</label>
                    <input type="date" [ngModel]="searchBillModel.fromDate | date:'yyyy-MM-dd'"
                        (change)="selectChangeHandler($event)" class="form-control" />
                </div>
                <div class="col-md-2">
                    <label>To</label>
                    <input type="date" [ngModel]="searchBillModel.toDate | date:'yyyy-MM-dd'"
                        (change)="selectChangeHandler1($event)" class="form-control" />
                </div>
                <div class="col-md-2">
                    <label>Inventory Value</label>
                    <input class="form-control" type="number" [(ngModel)]="searchBillModel.inventoryValue" />
                </div>
                <div class="col-md-2">
                    <label></label>
                    <div>
                        <button class="btn btn-outline-primary" (click)="onNameCustomerSearch()">Search</button>
                        <button class="btn btn-outline-primary" (click)="onCustSearchReset()">Reset</button>
                        <button class="btn btn-outline-primary" (click)="oAddCustomer()">Add Inventory</button>
                    </div>
                </div>
            </div>
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Inventory Id</th>
                        <th scope="col">Inventory Date</th>
                        <th scope="col">Inventory Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let u of customers">
                        <th scope="row">
                            <button class="btn btn-outline-primary" (click)="onEditCustomer(u)">Edit</button>
                        </th>
                        <td>{{u.inventoryId}}</td>
                        <td>{{u.inventoryDate| date: 'dd/MM/yyyy'}}</td>
                        <td>{{u.inventoryValue}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="isAddCustomerClicked">
            <form [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
                <div class="form-row">
                    <div class="col-md-2">
                        <label for="inventoryDate">Inventory Date</label>
                        <input type="date" formControlName="inventoryDate" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.inventoryDate.errors }" />
                        <div *ngIf="submitted && f.inventoryDate.errors" class="invalid-feedback">
                            <div *ngIf="f.inventoryDate.errors.required">Inventory Date is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="inventoryValue">Inventory Value</label>
                        <input type="number" formControlName="inventoryValue" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.inventoryValue.errors }" />
                        <div *ngIf="submitted && f.inventoryValue.errors" class="invalid-feedback">
                            <div *ngIf="f.inventoryValue.errors.required">Inventory Value is required</div>
                        </div>
                    </div>
                </div>
                <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Save
                </button>
                <button class="btn btn-outline-primary" (click)="onCloseAddCust()">close</button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>
    </div>
</div>