<div class="row">
    <div class="col">
        <h4>Time : {{ time | date: 'medium' }}</h4>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-light">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">In Time</th>
                    <th scope="col">Hrs</th>
                    <th scope="col">Remarks</th>
                    <th scope="col">#</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of bills">
                    <td>{{u.employeeName}}</td>
                    <td>{{u.checkInTime | date: 'shortTime'}} </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.checkIn }}</div>
                        <input type="number" *ngIf="u.isEdit" [(ngModel)]="editgrnData.checkIn"
                            class="form-control form-control-sm" min="0" />
                    </td>
                    <td>
                        <div *ngIf="!u.isEdit">{{u.reason }}</div>
                        <input type="text" *ngIf="u.isEdit" [(ngModel)]="editgrnData.reason"
                            class="form-control form-control-sm" />
                    </td>
                    <td>
                        <button class="btn btn-outline-primary" *ngIf="!u.isEdit" (click)="editGrn(u)">In</button>
                        <button class="btn btn-outline-primary" *ngIf="u.isEdit" (click)="saveGrn(u)">Save</button>
                        <button class="btn btn-outline-primary" *ngIf="u.isEdit" (click)="cancelGrn(u)">Cancel</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>