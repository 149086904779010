import { formatDate } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { noteTrackModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-notesview',
  templateUrl: './notesview.component.html',
  styleUrls: ['./notesview.component.css']
})
export class NotesviewComponent implements OnInit {
  suppliedDocs: noteTrackModel[] = [];
  loading = false;
  selectedDate;
  selectedDate2

  constructor(private api: RayarApiService) {
    this.selectedDate = new Date();
    this.selectedDate2 = new Date();
    this.getNotes();
  }

  ngOnInit(): void {
  }

  selectChangeHandlerFrom(event: any) {
    this.selectedDate = event.target.value;
  }

  selectChangeHandlerTo(event: any) {
    this.selectedDate2 = event.target.value;
  }

  Next() {
    var d = new Date(this.selectedDate)
    d.setDate(d.getDate() + 1);
    this.selectedDate = d;
    this.getNotes();
  }

  getNotes() {
    var d = new noteTrackModel();
    d.noteType = "All";
    d.page = 0;
    d.enteredDate = new Date(this.selectedDate);
    d.customerFolDate = new Date(this.selectedDate2);
    d.pageSize = 5000;
    this.api.getnoteTraks(d).subscribe(result => this.suppliedDocs = result, error => {
      this.loading = false; console.error(error)
    });
  }

  download() {
    var d = new noteTrackModel();
    d.noteType = "All";
    d.page = 0;
    d.enteredDate = new Date(this.selectedDate);
    d.customerFolDate = new Date(this.selectedDate2);
    d.pageSize = 5000;
    this.api.downloadFilePost("NotesReport", d).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = 'Notes' + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }



  selectedSearch: string = '';
  selectednoteType: string = 'ALL';

  getData() {
    var d4 = this.selectedSearch;
    var d3 = this.selectednoteType;
    return this.suppliedDocs.filter(function (hero) {
      return (
        (d3 == 'ALL' || hero.noteType == d3) &&
        (d4 == '' || (hero.billNumber != null && hero.billNumber.toLowerCase().includes(d4.toLowerCase()))
          || (hero.notes != null && hero.notes.toLowerCase().includes(d4.toLowerCase()))
          || (hero.customerName != null && hero.customerName.toLowerCase().includes(d4.toLowerCase())))
      );
    });
  }

}
