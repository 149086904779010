import { AuthenticationService } from '../_services/authentication.service';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({ selector: '[DataCustomAclOnly]' })
export class AclOnlyDirective {

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthenticationService) { }

    @Input()
    set DataCustomAclOnly(demandAcl: string) {
        if (environment.applyAcl && demandAcl && this.authService) {
            if (demandAcl.split(":").findIndex(x => x === this.authService.currentUserValue.role.toString()) >= 0) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}


@Directive({ selector: '[DataCustomAclUsers]' })
export class AclUsersDirective {

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthenticationService) { }

    @Input()
    set DataCustomAclUsers(demandAcl: string) {
        if (environment.applyAcl && demandAcl && this.authService) {
            if (demandAcl.split(":").findIndex(x => x === this.authService.currentUserValue.userName.toString()) >= 0) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}