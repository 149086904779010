<div class="row">
  <div class="col">
    <div class="form-row">
      <div class="col-md-2">
        <label>Brand</label>
        <input class="form-control" type="text" [(ngModel)]="searchCustomerName" />
      </div>
      <div class="col-md-2">
        <label></label>
        <div>
          <button class="btn btn-sm btn-info" (click)="onNameCustomerSearch()">Search</button>&nbsp;
          <button class="btn btn-sm btn-info" (click)="onCustSearchReset()">Reset</button>
        </div>
      </div>
      <div class="col-md-2">
        <label></label>
        <div>
          <button class="btn btn-sm btn-info" (click)="oAddCustomer()">Add Company</button>
        </div>
      </div>
    </div>
    <br>
    <table class="table table-striped table-sm table-responsive table-condensed">
      <thead class="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Company</th>

          <th scope="col">Brand</th>
          <th scope="col">Email</th>
          <th scope="col">Serial Group</th>
          <th scope="col">Bill# Serial Start</th>
          <th scope="col">IR# Serial Start</th>
          <th scope="col" *DataCustomAcl="'Admin'">UserName</th>
          <th scope="col">AliasInv</th>
          <th scope="col">AliasSales</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isAddCustomerClicked">
          <td colspan="10">
            <form [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
              <div class="form-row">
                <div class="col-md-2">
                  <label for="vehicleId">Company</label>
                  <select formControlName="vehicleId" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.vehicleId.errors }">
                    <option value="" selected disabled>Choose</option>
                    <option *ngFor="let p of vehicles" [value]="p.vehicleId">{{p.vehicleName}}</option>
                  </select>
                  <div *ngIf="submitted && f.vehicleId.errors" class="invalid-feedback">
                    <div *ngIf="f.vehicleId.errors.required">Company is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="companyName">Brand Name</label>
                  <input type="text" formControlName="companyName" class="form-control" maxlength="50"
                    [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }" />
                  <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
                    <div *ngIf="f.companyName.errors.required">Brand Name is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="serialGroup">Serial Group</label>
                  <input type="text" formControlName="serialGroup" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.serialGroup.errors }" />
                  <div *ngIf="submitted && f.serialGroup.errors" class="invalid-feedback">
                    <div *ngIf="f.serialGroup.errors.required">Serial Group is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="serialStart">Serial Start</label>
                  <input type="number" formControlName="serialStart" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.serialStart.errors }" />
                  <div *ngIf="submitted && f.serialStart.errors" class="invalid-feedback">
                    <div *ngIf="f.serialStart.errors.required">Serial Start is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="damageSerialStart">IR Serial Start</label>
                  <input type="number" formControlName="damageSerialStart" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.damageSerialStart.errors }" />
                  <div *ngIf="submitted && f.damageSerialStart.errors" class="invalid-feedback">
                    <div *ngIf="f.damageSerialStart.errors.required">IR Serial Start is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="email">Email</label>
                  <input type="text" formControlName="email" class="form-control" maxlength="2000"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="rCompanyName">Alais1</label>
                  <input type="text" formControlName="rCompanyName" class="form-control" maxlength="2000"
                    [ngClass]="{ 'is-invalid': submitted && f.rCompanyName.errors }" />
                  <div *ngIf="submitted && f.rCompanyName.errors" class="invalid-feedback">
                    <div *ngIf="f.rCompanyName.errors.required">Alais1 is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="sCompanyName">Alais2</label>
                  <input type="text" formControlName="sCompanyName" class="form-control" maxlength="2000"
                    [ngClass]="{ 'is-invalid': submitted && f.sCompanyName.errors }" />
                  <div *ngIf="submitted && f.sCompanyName.errors" class="invalid-feedback">
                    <div *ngIf="f.sCompanyName.errors.required">Alais2 is required</div>
                  </div>
                </div>
              </div>

              <button type="submit" [disabled]="loading" class="btn btn-sm btn-info">
                Save
              </button>&nbsp;
              <button class="btn btn-sm btn-info" (click)="onCloseAddCust()">close</button>
            </form>
          </td>
        </tr>
      </tbody>
      <tbody *ngFor="let u of customers">
        <tr>
          <td>
            <button class="btn btn-sm btn-info" (click)="onEditCustomer(u)">Edit</button>
          </td>
          <td>{{u.vehicleName}}</td>
          <td>{{u.companyName}}</td>
          <td>{{u.email}}</td>
          <td>{{u.serialGroup}}</td>
          <td>{{u.serialStart}}</td>
          <td>{{u.damageSerialStart}}</td>
          <td *DataCustomAcl="'Admin'">
            <button *ngIf="u.userName=='zzzzz'" class="btn btn-sm btn-info" (click)="onCreateSalesLogin(u)">Create
              Login</button>
            <div *ngIf="u.userName !='zzzzz'"> {{u.userName}}</div>
          </td>
          <td>{{u.rCompanyName}}</td>
          <td>{{u.sCompanyName}}</td>
        </tr>
        <tr *ngIf="u.showDetail">
          <td colspan="10">
            <form [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
              <div class="form-row">
                <div class="col-md-2">
                  <label for="vehicleId">Company</label>
                  <select formControlName="vehicleId" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.vehicleId.errors }">
                    <option value="" selected disabled>Choose</option>
                    <option *ngFor="let p of vehicles" [value]="p.vehicleId">{{p.vehicleName}}</option>
                  </select>
                  <div *ngIf="submitted && f.vehicleId.errors" class="invalid-feedback">
                    <div *ngIf="f.vehicleId.errors.required">Company is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="companyName">Company Name</label>
                  <input type="text" formControlName="companyName" class="form-control" maxlength="50"
                    [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }" />
                  <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
                    <div *ngIf="f.companyName.errors.required">Company Name is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="serialGroup">Serial Group</label>
                  <input type="text" formControlName="serialGroup" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.serialGroup.errors }" />
                  <div *ngIf="submitted && f.serialGroup.errors" class="invalid-feedback">
                    <div *ngIf="f.serialGroup.errors.required">Serial Group is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="serialStart">Serial Start</label>
                  <input type="number" formControlName="serialStart" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.serialStart.errors }" />
                  <div *ngIf="submitted && f.serialStart.errors" class="invalid-feedback">
                    <div *ngIf="f.serialStart.errors.required">Serial Start is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="damageSerialStart">IR Serial Start</label>
                  <input type="number" formControlName="damageSerialStart" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.damageSerialStart.errors }" />
                  <div *ngIf="submitted && f.damageSerialStart.errors" class="invalid-feedback">
                    <div *ngIf="f.damageSerialStart.errors.required">IR Serial Start is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="email">Email</label>
                  <input type="text" formControlName="email" class="form-control" maxlength="2000"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="rCompanyName">Alais1</label>
                  <input type="text" formControlName="rCompanyName" class="form-control" maxlength="2000"
                    [ngClass]="{ 'is-invalid': submitted && f.rCompanyName.errors }" />
                  <div *ngIf="submitted && f.rCompanyName.errors" class="invalid-feedback">
                    <div *ngIf="f.rCompanyName.errors.required">Alais1 is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="sCompanyName">Alais2</label>
                  <input type="text" formControlName="sCompanyName" class="form-control" maxlength="2000"
                    [ngClass]="{ 'is-invalid': submitted && f.sCompanyName.errors }" />
                  <div *ngIf="submitted && f.sCompanyName.errors" class="invalid-feedback">
                    <div *ngIf="f.sCompanyName.errors.required">Alais2 is required</div>
                  </div>
                </div>

              </div>
              <button type="submit" [disabled]="loading" class="btn btn-sm btn-info">
                Save
              </button>
            </form>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>