import { formatDate } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmpAttendanceModel, empModel, ExpenseModel, User } from '../models';
import { AuthenticationService } from '../_services/authentication.service';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-emp-attendance',
  templateUrl: './emp-attendance.component.html',
  styleUrls: ['./emp-attendance.component.css']
})
export class EmpAttendanceComponent implements OnInit {

  searchlModel = new EmpAttendanceModel();
  selectedDate = new Date().getUTCFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2);;
  error = '';
  bills: EmpAttendanceModel[] = [];
  loading = false;
  emps: empModel[] = [];
  expensesAll: ExpenseModel[] = [];
  expensesSumAll: ExpenseModel[] = [];

  currentUser: User;
  searchTrack = 'Yes';
  billAddEditForm: FormGroup;
  get f() { return this.billAddEditForm.controls; }

  selectChangeHandler(event: any) {
    this.selectedDate = event.target.value;
  }

  onpage(next: boolean) {
    if (next) {
      this.getBills(this.searchlModel.page + 1);
    }
    else {
      if (this.searchlModel.page >= 1)
        this.getBills(this.searchlModel.page - 1);
    }
  }

  constructor(private authenticationService: AuthenticationService, private formBuilder: FormBuilder, private api: RayarApiService) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.getExpensesAll();
  }

  ngOnInit(): void {
    this.billAddEditForm = this.formBuilder.group({
      category: ['', Validators.required],
      amount: ['', Validators.required],
      reason: ['', Validators.required],
      entryDate: ['', Validators.required]
    });
    this.searchlModel.page = 0;
    this.searchlModel.employeeId = -1
    this.getCollPesons();
  }

  gethelperAtted(s: string) {
    var lst = [];
    this.bills.forEach(x => {
      if (x.employeeName.toLowerCase() == s.toLowerCase()) {
        lst.push(x);
      }
    });
    return lst;
  }

  displayText(u: EmpAttendanceModel) {
    var dt = new Date(u.dateValue).getDate();
    if (!u.checkIn || u.checkIn == "4") return dt + "A"
    if (u.checkIn == "1") return dt + "F";
    if (u.checkIn == "2") return dt + "H";
    if (u.checkIn == "3") return dt + "PL";
  }

  gethelperAttedTotal(s: string) {
    var e = this.emps.filter(function (x) { return x.employeeName == s });
    var F = 0, H = 0, P = 0, A = 0, days = 0;
    this.bills.forEach(x => {
      if (x.employeeName.toLowerCase() == s.toLowerCase()) {
        days = days + 1;
        if (!x.checkIn || x.checkIn == "4") A = A + 1;
        if (x.checkIn == "1") F = F + 1;
        if (x.checkIn == "2") H = H + 1;
        if (x.checkIn == "3") P = P + 1;
      }
    });
    var wd = F + P + (H / 2);
    return [F, H, P, A, wd, e[0].salary, e[0].incentive, ((wd / 26) * (e[0].salary + e[0].incentive)).toFixed(2)];
  }

  editgrnData: EmpAttendanceModel;
  selEmp: string;
  billModel = new ExpenseModel();
  submitted = false;

  OnAttendance(s: string, u: EmpAttendanceModel) {
    this.selEmp = s;
    this.editgrnData = u;
    this.editgrnData.entryDate = u.dateValue;

    this.billModel = new ExpenseModel();
    this.billModel.expenseId = 0;
    this.billModel.paidTo = s;
    this.billAddEditForm.setValue({
      entryDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
      category: '',
      reason: '',
      amount: ''
    });

  }

  onBillSubmit() {
    this.submitted = true;
    if (this.billAddEditForm.invalid) return;
    this.loading = true;
    this.billModel.amount = this.f.amount.value;
    this.billModel.entryDate = this.f.entryDate.value;
    this.billModel.category = this.f.category.value;
    this.billModel.reason = this.f.reason.value;

    this.api.AddExpense(this.billModel).subscribe(result => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.getExpensesAll();
      var p = this.billModel.paidTo;
      this.billModel = new ExpenseModel();
      this.billModel.expenseId = 0;
      this.billModel.paidTo = p;
      this.billAddEditForm.setValue({
        entryDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
        category: '',
        reason: '',
        amount: ''
      });
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  getexpenses(s: string) {
    return this.expensesAll.filter(function (d) {
      return d.paidTo.toLowerCase() == s.toLowerCase();
    });
  }

  getexpensesSum(s: string) {
    return this.expensesSumAll.filter(function (d) {
      return d.paidTo.toLowerCase() == s.toLowerCase();
    });
  }

  saveAttendance(u: number) {
    this.editgrnData.checkIn = u.toString();
    this.editgrnData.reason = new Date().toDateString() + ":" + this.currentUser.userName;
    this.api.SaveEmpAttendRemarks(this.editgrnData)
      .subscribe(() => {
        this.loading = false;
        this.getBills(this.searchlModel.page);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }


  employees: string[] = [];
  getBills(p: number) {
    var s = this.searchTrack;
    this.error = "";
    var d = this.selectedDate.split('-');
    var y = Number(d[0]);
    var m = Number(d[1]) - 1;
    var fdate = new Date(y, m, 1);
    var tdate = new Date(y, m + 1, 0);
    var timeZoneDifference = (fdate.getTimezoneOffset() / 60) * -1; //convert to positive value.
    fdate.setTime(fdate.getTime() + (timeZoneDifference * 60) * 60 * 1000);
    tdate.setTime(tdate.getTime() + (timeZoneDifference * 60) * 60 * 1000);
    this.searchlModel.fromDate = fdate;
    this.searchlModel.toDate = tdate;
    this.searchlModel.page = p;
    this.searchlModel.employeeId = Number(this.searchlModel.employeeId);
    this.searchlModel.uniqId = null;
    this.api.getEmpAttendances(this.searchlModel).subscribe(result => {
      this.bills = result
        .filter(function (f) { return (s == "Yes" && f.track) || (s == "No" && !f.track); })
        .sort((a, b) => new Date(a.dateValue).getTime() - new Date(b.dateValue).getTime());
      this.employees = this.bills.map(item => item.employeeName)
        .filter((value, index, self) => self.indexOf(value) === index);
    }, error => {
      this.error = error; this.loading = false; console.error(error);
    });
  }

  getExpensesAll() {
    var d = this.selectedDate.split('-');
    var ld = new Date(Number(d[0]), Number(d[1]), 0);
    var fd = new Date(Number(d[0]), Number(d[1]), 1);
    var sixfd = formatDate(fd.setMonth(fd.getMonth() - 3), 'yyyy-MM-dd', 'en')
    this.api.getExpenses(sixfd, this.selectedDate + "-" + ld.getDate(), "SalaryAdvance;Salary", "2")
      .subscribe(result => {
        this.expensesAll = result;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });

    this.api.getExpensesSum(sixfd, this.selectedDate + "-" + ld.getDate(), "SalaryAdvance;Salary", "2")
      .subscribe(result => {
        this.expensesSumAll = result;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  getCollPesons() {
    this.api.getEmps().subscribe(result => {
      this.emps = result;
      this.onSearch();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  onSearch() {
    this.getBills(0);
  }

  onReset() {
    this.selectedDate = new Date().getUTCFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2);;
    this.searchlModel.employeeId = -1;
    this.error = "";
    this.searchTrack = "Yes";
    this.bills = [];
    this.employees = [];
  }

  downloadReport() {
    this.searchlModel.page = 0;
    var d = this.selectedDate.split('-');
    var y = Number(d[0]);
    var m = Number(d[1]) - 1;
    var fdate = new Date(y, m, 1);
    var tdate = new Date(y, m + 1, 0);
    this.searchlModel.fromDate = fdate;
    this.searchlModel.toDate = tdate;
    this.searchlModel.employeeId = Number(this.searchlModel.employeeId);
    this.searchlModel.uniqId = "d";
    this.api.downloadFilePost("EmpAttendDownload", this.searchlModel).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = "Attendance" + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }

  public download(q: string, b: any) {
    this.api.downloadFilePost(q, b).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }

}
