import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BillModel, BillProductModel, companyModel, InvoiceModel, ProductModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { NgxImageCompressService } from 'ngx-image-compress';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  loading = false;
  colls: InvoiceModel[] = [];
  error = '';
  searchBillModel: InvoiceModel = new InvoiceModel();
  isAddNewBillClicked: boolean = false;
  submitted = false;
  billModel = new InvoiceModel();
  billAddEditForm: FormGroup;
  get f() { return this.billAddEditForm.controls; }
  companys: companyModel[];

  constructor(private imageCompress: NgxImageCompressService, private formBuilder: FormBuilder, private api: RayarApiService) {
    this.companys = [];
    this.getcompany();
  }

  margin: number = 0;

  ngOnInit() {
    this.billAddEditForm = this.formBuilder.group({
      invoiceNumber: ['', Validators.required],
      invoiceValue: ['', Validators.required],
      invoiceDate: ['', Validators.required],
      companyName: ['', Validators.required],
      gstPercentage: ['', Validators.required],
      gstValue: ['', Validators.required],
      preGstValue: ['', Validators.required],
      mode: ['', Validators.required],
      margin: ['', Validators.required],
    });
    this.billAddEditForm.get('margin').valueChanges.subscribe(value => {
      this.margin = value;
    });
  }


  getcompany() {
    this.api.getCompanys().subscribe(result => { this.companys = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  downloadInvoiceReport() {
    this.searchBillModel.page = 0;
    this.download("InvoiceReport", this.searchBillModel)
  }

  public download(q: string, b: any) {
    this.api.downloadFilePost(q, b).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }


  onCollBillSearch() {
    this.searchBillModel.page = 0;
    this.api.onInvoiceView(this.searchBillModel).subscribe(result => {
      this.colls = result;
      this.colls.forEach(function (hero) {
        if (!hero.margin) hero.margin = 0;
      });
    }, error => {
      this.error = error;
      this.loading = false;
      console.error(error);
    });
  }

  onCollBillSearchReset() {
    this.searchBillModel = new InvoiceModel();
    this.colls = [];
  }


  selectChangeHandler(event: any) {
    this.searchBillModel.fromDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.searchBillModel.toDate = event.target.value;
  }

  onBillCancel() {
    this.isAddNewBillClicked = false;
  }

  editBill(u: InvoiceModel) {
    this.billProducts = [];
    this.getBillProducts(u.invoiceId);
    this.error = '';
    this.isAddNewBillClicked = true;
    this.billModel = u;
    this.getProducts(u.companyName);
    this.billAddEditForm.setValue({
      invoiceDate: formatDate(u.invoiceDate, 'yyyy-MM-dd', 'en'),
      invoiceNumber: u.invoiceNumber,
      invoiceValue: u.invoiceValue,
      companyName: u.companyName,
      mode: u.mode,
      margin: u.margin,
      preGstValue: u.preGstValue,
      gstValue: u.gstValue,
      gstPercentage: u.gstPercentage
    });
  }


  markInActive(u: InvoiceModel) {
    this.api.markInvoiceInactive(u.invoiceId)
      .subscribe(result => { this.onCollBillSearch(); }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  selectedComp: string = "";

  onAddNewBill() {
    if (!this.selectedComp) return;
    this.billProducts = [];
    this.getProducts(this.selectedComp);
    this.error = '';
    this.isAddNewBillClicked = true;
    this.billModel = new InvoiceModel();
    this.billModel.invoiceId = 0;
    this.billModel.margin = 0;
    this.billModel.companyName = this.selectedComp;
    this.billAddEditForm.setValue({
      invoiceDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
      invoiceNumber: '',
      invoiceValue: '',
      companyName: this.selectedComp,
      preGstValue: '',
      gstValue: '',
      gstPercentage: '',
      mode: '',
      margin: 0,
    });
  }

  selAccledger: InvoiceModel;
  selectlinvoideTrak(u: InvoiceModel) {
    this.selAccledger = u;
  }

  uploadLedger(event) {
    this.error = "";
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          var imageSrc = reader.result as string;
          this.imageCompress.compressFile(imageSrc, 50, 100).then((compressedImage) => {
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            this.onUploadLedger(doc.output('blob'));
          });
        }
        else {
          if (file.type.startsWith("application/pdf")) {
            this.onUploadLedger(file);
          } else {
            this.error = "Invalid File format images/PDF only"
          }
        }
      };
    }
  }

  @ViewChild('inputFile') inputFile: ElementRef;
  onUploadLedger(file: Blob) {
    this.api.uploadToBlob(file, `UploadLedger/${this.selAccledger.invoiceId}/INVICE`).subscribe(
      data => {
        if (data) {
          switch (data.type) {
            case HttpEventType.UploadProgress:
              break;
            case HttpEventType.Response:
              this.inputFile.nativeElement.value = '';
              break;
          }
        }
      },
      error => {
        this.inputFile.nativeElement.value = '';
      }
    );
  }

  downloadBills(u: InvoiceModel) {
    this.api.downloadBlobFilePost("Download1/" + u.invoiceId + "/INVICE", null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            var fileName = u.invoiceId + ".jpg";
            if (data.body.type == "application/zip") {
              fileName = u.invoiceId + ".zip"
            } else if (data.body.type == "application/pdf") {
              fileName = u.invoiceId + ".pdf"
            }
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }

  onBillSubmit() {
    this.submitted = true;
    if (this.billAddEditForm.invalid) return;
    this.loading = true;
    this.billModel.invoiceNumber = this.f.invoiceNumber.value;
    this.billModel.invoiceDate = this.f.invoiceDate.value;
    this.billModel.invoiceValue = this.f.invoiceValue.value;
    this.billModel.companyName = this.f.companyName.value;
    this.billModel.mode = this.f.mode.value;
    this.billModel.preGstValue = this.f.preGstValue.value;
    this.billModel.gstPercentage = this.f.gstPercentage.value;
    this.billModel.gstValue = this.f.gstValue.value;
    this.billModel.margin = this.f.margin.value;
    this.billModel.billProducts = this.billProducts.filter(function (hero) {
      return hero.quantity > 0;
    });
    this.api.AddInvoice(this.billModel).subscribe(result => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.isAddNewBillClicked = false;
      this.onCollBillSearch();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }


  search: ProductModel = new ProductModel();
  searchBp: BillProductModel = new BillProductModel();
  billProducts: BillProductModel[] = [];
  products: ProductModel[] = [];
  srcPrds: string = '';

  color(q: ProductModel) {
    var d = false;
    this.billProducts.forEach(function (hero) {
      if (hero.productId == q.productId) {
        d = true;
      }
    });
    return d;
  }

  onAdd(p: ProductModel) {
    var isexists = false;
    var m = this.margin;
    this.billProducts.forEach(function (hero) {
      if (hero.productId == p.productId) {
        hero.quantity = hero.quantity + 1;
        hero.disPrice = parseFloat((hero.quantity * (hero.price * (100 - m) / 100)).toFixed(2));
        isexists = true;
      }
    });
    if (!isexists) {
      var bp = new BillProductModel();
      bp.quantity = 1;
      bp.invoiceId = this.billModel.invoiceId;
      bp.billProductId = 0;
      bp.price = p.mrp;
      bp.crate = p.crate;
      bp.srate = p.srate;
      bp.disPrice = parseFloat((bp.quantity * (bp.price * (100 - m) / 100)).toFixed(2));
      bp.productId = p.productId;
      bp.productName = p.productName
      this.billProducts.push(bp);
    }
  }

  onAdd1(q: BillProductModel) {
    var m = this.margin;
    this.billProducts.forEach(function (hero) {
      if (hero.productId == q.productId) {
        hero.quantity = hero.quantity + 1;
        hero.disPrice = parseFloat((hero.quantity * (q.price * (100 - m) / 100)).toFixed(2));
      }
    });
  }

  onSub(q: BillProductModel) {
    var m = this.margin;
    this.billProducts.forEach(function (hero, index, object) {
      if (hero.productId == q.productId) {
        hero.quantity = hero.quantity - 1;
        hero.disPrice = parseFloat((hero.quantity * (q.price * (100 - m) / 100)).toFixed(2));
        if (hero.quantity <= 0) {
          object.splice(index, 1);
        }
      }
    });
  }

  onSub1(q: BillProductModel) {
    this.billProducts.forEach(function (hero, index, object) {
      if (hero.productId == q.productId) {
        object.splice(index, 1);
      }
    });
  }

  getTotal() {
    let total = 0;
    this.billProducts.forEach((item) => {
      total += item.disPrice;
    });
    return total.toFixed(2);
  }

  getBillProductsFilter() {
    var m = this.margin;
    var p = this.billProducts.filter(function (hero) {
      hero.disPrice = parseFloat((hero.quantity * (hero.price * (100 - m) / 100)).toFixed(2));
      return true;
    });
    return p;
  }

  getProductsFilter() {
    var st = this.srcPrds;
    return this.products.filter(function (hero) {
      return st == "" || hero.productName.toLowerCase().includes(st.toLowerCase());
    });
  }

  getProducts(s: string) {
    this.search.category = s;
    this.search.page = 0;
    this.search.pageSize = 1000;
    this.api.getProducts(this.search).subscribe(result => { this.products = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  getBillProducts(s: number) {
    this.searchBp.billId = s;
    this.searchBp.type = "I";
    this.api.getBillProducts(this.searchBp).subscribe(result => { this.billProducts = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

}
