import { Component, OnInit } from '@angular/core';
import { User, empModel, ExpenseModel, Role, VehicleModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { AuthenticationService } from '../_services/authentication.service';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.css']
})
export class ExpenseComponent implements OnInit {

  loading = false;
  isAddNewBillClicked: boolean = false;
  bills: ExpenseModel[] = [];
  billModel = new ExpenseModel();
  error = '';
  billAddEditForm: FormGroup;
  get f() { return this.billAddEditForm.controls; }
  submitted = false;
  isEditBill = false;
  currentUser: User;
  sReviewed: string = "2";
  colAmount: number = 0;
  colAmount1: number = 0;
  customers: empModel[] = [];

  constructor(private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder, private api: RayarApiService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getCollPesons();
  }

  ngOnInit() {
    this.billAddEditForm = this.formBuilder.group({
      category: ['', Validators.required],
      amount: ['', Validators.required],
      paidTo: ['', Validators.required],
      reason: ['', Validators.required],
      entryDate: ['', Validators.required]
    });
  }

  getCollPesons() {
    this.api.getEmps()
      .subscribe(result => { this.customers = result; this.getVehicles(); }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  getVehicles() {
    var search: VehicleModel = new VehicleModel();
    search.page = 0;
    search.category = "Vehicle";
    this.api.getSearchVehicle(search).subscribe(result => {
      result.vehicles.forEach(x => {
        var d = new empModel();
        d.employeeName = x.vehicleName;
        this.customers.push(d);
      });
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  selectChangeHandler11(event: any) {
    this.sReviewed = event.target.value;
  }

  get isAdmin() {
    return this.currentUser && this.currentUser.role === Role.Admin;
  }

  getBills() {
    this.api.getExpenses(formatDate(this.selectedDate, 'yyyy-MM-dd', 'en'),
      formatDate(this.selectedDate1, 'yyyy-MM-dd', 'en'), this.selectedCategory, this.sReviewed)
      .subscribe(result => {
        this.bills = result;
        this.colAmount = 0;
        this.colAmount1 = 0;
        this.bills.forEach(x => {
          if (x.isReviewed) {
            this.colAmount = this.colAmount + x.amount;
          } else {
            this.colAmount1 = this.colAmount1 + x.amount;
          }
        });
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  onAddNewBill() {
    this.error = '';
    this.isAddNewBillClicked = true;
    this.billModel = new ExpenseModel();
    this.billModel.expenseId = 0;
    this.billAddEditForm.setValue({
      entryDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
      category: '',
      paidTo: '',
      reason: '',
      amount: ''
    });
  }


  selectedDate1 = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
  selectedDate = new Date(formatDate(new Date(new Date().setMonth(new Date().getMonth() - 2)), 'yyyy-MM-dd', 'en'));
  selectedCategory = "";

  selectChangeHandler(event: any) {
    this.selectedDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.selectedDate1 = event.target.value;
  }

  onSearch() {
    this.getBills();
  }

  onReset() {
    this.selectedDate1 = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.selectedDate = new Date(formatDate(new Date(new Date().setMonth(new Date().getMonth() - 2)), 'yyyy-MM-dd', 'en'));
    this.selectedCategory = "";
    this.sReviewed = "2";
    this.bills = [];
  }

  downloadExpenseReport() {
    var entryDate: string = formatDate(this.selectedDate, 'yyyy-MM-dd', 'en');
    var reviewedDate: string = formatDate(this.selectedDate1, 'yyyy-MM-dd', 'en');
    var category = this.selectedCategory;
    var reviewe = this.sReviewed;
    this.download("ExpenseReport", { entryDate, reviewedDate, category, reviewe })
  }


  public download(q: string, b: any) {
    this.api.downloadFilePost(q, b).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }


  editBill(u: ExpenseModel) {
    this.error = '';
    this.isEditBill = true;
    this.isAddNewBillClicked = true;
    this.billModel = u;
    this.billAddEditForm.setValue({
      entryDate: formatDate(u.entryDate, 'yyyy-MM-dd', 'en'),
      amount: u.amount,
      category: u.category,
      paidTo: u.paidTo,
      reason: u.reason,
    });
  }

  onBillCancel() {
    this.isAddNewBillClicked = false;
    this.getBills();
  }

  onBillSubmit() {
    this.submitted = true;
    if (this.billAddEditForm.invalid) return;
    this.loading = true;
    this.billModel.amount = this.f.amount.value;
    this.billModel.entryDate = this.f.entryDate.value;
    this.billModel.paidTo = this.f.paidTo.value;
    this.billModel.category = this.f.category.value;
    this.billModel.reason = this.f.reason.value;

    this.api.AddExpense(this.billModel).subscribe(result => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.isAddNewBillClicked = false;
      this.getBills();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  markReviewedExp(u: ExpenseModel) {
    this.api.markExpenseRev(u.expenseId).subscribe(result => { this.getBills(); }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  markExpInAct(u: ExpenseModel) {
    this.api.markExpInAct(u.expenseId).subscribe(result => { this.getBills(); }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

}
