import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { CollectonPersonModel, BillModel, CollectionModel, User, Role } from '../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { AuthenticationService } from '../_services/authentication.service';
import { conditionalValidator } from '../_helpers/conditionalValidator';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.css']
})
export class CollectionComponent implements OnInit {
  loading = false;
  error = '';
  persons1: CollectonPersonModel[] = [];
  submitted = false;
  collsByUser: CollectionModel[] = [];
  selectedPersonId: number;
  currentUser: User;

  constructor(private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder, private api: RayarApiService) {
    this.getCollPersons();
    this.selectedPersonId = 0;
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.isUrAmSr)
      this.getColls(this.selectedPersonId);
    else
      this.getColls(this.getProfileId);
  }

  selectChangeHandler(event: any) {
    var personId = parseInt(event.target.value)
    this.selectedPersonId = personId;
    this.getColls(this.selectedPersonId);
  }

  get isUrAmSr() {
    return this.currentUser && (this.currentUser.role === Role.Employee ||
      this.currentUser.role === Role.Admin || this.currentUser.role === Role.Sales);
  }

  get getProfileId() {
    return parseInt(this.currentUser.profileId);
  }

  get isSalePerson() {
    return this.currentUser && this.currentUser.role === Role.Sales;
  }

  ngOnInit(): void {
  }

  searchBillModel: BillModel = new BillModel();

  getColls(p: number) {
    this.searchBillModel.personId = p;
    this.searchBillModel.page = 0;
    this.api.oncollsView('collsForReview', this.searchBillModel)
      .subscribe(result => { this.collsByUser = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  getCollPersons() {
    this.api.getCollPersons().subscribe(result => {
      this.persons1 = result;
      var pid = this.getProfileId;
      if (!this.isUrAmSr) {
        var personName = result.filter(function (hero) {
          return hero.personId == pid;
        });
        this.persons1 = personName;
      }
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  get isAdmin() {
    return this.currentUser && this.currentUser.role === Role.Admin;
  }
  deleteCol(u: CollectionModel) {
    this.api.markCollectionInactive(u.collectionId)
      .subscribe(result => {
        this.getColls(this.selectedPersonId);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }
}
