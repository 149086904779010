<div class="form-row">
  <div class="col">
    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
    <div *ngIf="!isAddCustomerClicked">
      <div class="form-row">
        <div class="col-md-2">
          <label>Chit Name</label>
          <input class="form-control" type="text" [(ngModel)]="searchCustomerName" />
        </div>
        <div class="col-md-2">
          <label></label>
          <div>
            <button class="btn btn-outline-primary" (click)="onNameCustomerSearch(0)">Search</button>
            <button class="btn btn-outline-primary" (click)="onCustSearchReset()">Reset</button>
          </div>
        </div>
        <div class="col-md-2">
          <label></label>
          <div>
            <button class="btn btn-outline-primary" (click)="oAddCustomer()">Add Chit</button>
          </div>
        </div>
      </div>
      <br>
      <div class="form-row">
        <div class="col-md-2">
          <label>Show Complete</label>
          <input type="checkbox" [(ngModel)]="isChecked">
        </div>
      </div>
      <table class="table table-striped table-sm table-responsive table-condensed">
        <thead class="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">#</th>
            <th scope="col" (click)="sorting('accountName')">Chit Name</th>
            <th scope="col" (click)="sorting('amount')">Chit Amount</th>
            <th scope="col">Fee</th>
            <th scope="col">Start Date</th>
            <th scope="col">Status</th>
            <th scope="col" (click)="sorting('auctionDate')">Last Auction</th>
            <th scope="col">Chit Month</th>
            <th scope="col">Total Profit</th>
            <th scope="col">Profit-Fee</th>
            <th scope="col">Paid</th>
            <th scope="col">Percentage</th>
            <th scope="col">Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of getData()">
            <th scope="row">
              <button *ngIf="u.chitStatus!=2" class="btn btn-sm btn-success" (click)="onEditCustomer(u)">
                Edit
              </button>&nbsp;
              <button class="btn btn-sm btn-info" (click)="ShowDetail(u)">Detail</button>
            </th>
            <th scope="row">
              <button *ngIf="u.chitStatus!=2" class="btn btn-sm btn-warning" (click)="onMarkClose(u)">
                Complete
              </button>&nbsp;
              <button class="btn btn-sm btn-danger" (click)="onMarkcDelete(u)">Delete</button>
            </th>
            <td>{{u.accountName}}</td>
            <td>{{u.amount|currency:'INR': 'symbol' : '1.0-0'}}</td>
            <td>{{u.fee|currency:'INR': 'symbol' : '1.0-0'}}</td>
            <td>{{u.startDate| date:'MMM dd yy'}}</td>
            <td>{{u.chitStatus==2?'Complete':'Open'}}</td>
            <td>{{u.auctionDate| date:'MMM dd yy'}}</td>
            <td>{{u.chitMonth}}</td>
            <td>{{u.profit|currency:'INR': 'symbol' : '1.0-0'}}</td>
            <td>{{u.profit-u.fee|currency:'INR': 'symbol' : '1.0-0'}}</td>
            <td>{{u.chitMonth*(u.amount/u.months)- u.profit|currency:'INR': 'symbol' : '1.0-0'}}</td>
            <td>{{(((u.profit-u.fee)*100)/(u.chitMonth*(u.amount/u.months)- (u.profit)))| number : '1.2-2'}}</td>
            <td>{{u.remarks}}</td>
          </tr>
          <tr>
            <th scope="row">
            </th>
            <th scope="row">
            </th>
            <td>Total</td>
            <td>{{customerst.amount|currency:'INR': 'symbol' : '1.0-0'}}</td>
            <td>{{customerst.fee|currency:'INR': 'symbol' : '1.0-0'}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{customerst.profit|currency:'INR': 'symbol' : '1.0-0'}}</td>
            <td>{{customerst.profit-customerst.fee|currency:'INR': 'symbol' : '1.0-0'}}</td>
            <td>{{customerst.months|currency:'INR': 'symbol' : '1.0-0'}}</td>
            <td>{{ (((customerst.profit-customerst.fee)*100)/customerst.months)/12 | number : '1.2-2'}}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="isAddCustomerClicked">
      <div *ngIf="addEditCustModel.showDetail">
        <button class="btn btn-outline-primary" (click)="onCloseAddCust()">Back</button>
        <button class="btn btn-outline-primary" *ngIf="addEditCustModel.chitStatus!=2" (click)="oAddAuction()">Add
          Auction</button>
        <div *ngIf="isAddAuctionClicked">
          <form [formGroup]="customerForm1" (ngSubmit)="onAddCustSubmit1()">
            <div class="form-row">
              <div class="col-md-2">
                <label for="auctionDate">Auction Date</label>
                <input type="date" formControlName="auctionDate" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f1.auctionDate.errors }" />
                <div *ngIf="submitted && f1.auctionDate.errors" class="invalid-feedback">
                  <div *ngIf="f1.auctionDate.errors.required">Auction Date is required</div>
                </div>
              </div>
              <div class="col-md-2">
                <label for="auction">Auction Amount </label>
                <input type="number" formControlName="auction" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f1.auction.errors }" />
                <div *ngIf="submitted && f1.auction.errors" class="invalid-feedback">
                  <div *ngIf="f1.auction.errors.required">Auction Amount is required</div>
                </div>
              </div>
              <div class="col-md-2">
                <label for="remarks">Notes</label>
                <input type="text" formControlName="remarks" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f1.remarks.errors }" />
                <div *ngIf="submitted && f1.remarks.errors" class="invalid-feedback">
                  <div *ngIf="f1.remarks.errors.required">Notes is required</div>
                </div>
              </div>
            </div>
            <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Save
            </button>
            <button class="btn btn-outline-primary" (click)="onCloseAddCust1()">close</button>
          </form>
        </div>
        <br>
        <table class="table table-striped table-sm table-responsive table-condensed">
          <thead class="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">SlNo</th>
              <th scope="col">Auction Date</th>
              <th scope="col">Auction Amount</th>
              <th scope="col">Profit</th>
              <th scope="col">To Pay</th>
              <th scope="col">Paid</th>
              <th scope="col">Paid Date</th>
              <th scope="col">Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let u of  chitDetails">
              <th scope="row">
                <button *ngIf="!u.paid" class="btn btn-outline-primary" (click)="onEditChitDetail(u)">Edit</button>
                <button *ngIf="!u.paid" class="btn btn-outline-primary" (click)="onMarkPaid(u)">Mark Paid</button>
              </th>
              <td>{{u.slNo}}</td>
              <td>{{u.auctionDate| date:'dd/MM/yy'}}</td>
              <td>{{u.auction|currency:'INR': 'symbol' : '1.0-0'}}</td>
              <td>{{((u.auction- addEditCustModel.fee)/addEditCustModel.months) |currency:'INR': 'symbol' : '1.0-0'}}
              </td>
              <td>{{((addEditCustModel.amount/20)- ((u.auction- addEditCustModel.fee)/addEditCustModel.months))
                |currency:'INR': 'symbol' : '1.0-0'}}</td>
              <td>{{u.paid?'Paid':''}}</td>
              <td>{{u.paidDate| date:'dd/MM/yy'}}</td>
              <td>{{u.remarks}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <form *ngIf="!addEditCustModel.showDetail" [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
        <div class="form-row">
          <div class="col-md-2">
            <label for="accountName">Chit Name</label>
            <input type="text" formControlName="accountName" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.accountName.errors }" />
            <div *ngIf="submitted && f.accountName.errors" class="invalid-feedback">
              <div *ngIf="f.accountName.errors.required">Chit Name is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="amount">Chit Amount </label>
            <input type="number" formControlName="amount" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.amount.errors }" />
            <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
              <div *ngIf="f.amount.errors.required">Chit Amount is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="fee">Fee</label>
            <input type="number" formControlName="fee" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.fee.errors }" />
            <div *ngIf="submitted && f.fee.errors" class="invalid-feedback">
              <div *ngIf="f.fee.errors.required">Fee is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="months">NoOfMonths</label>
            <input type="number" formControlName="months" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.months.errors }" />
            <div *ngIf="submitted && f.months.errors" class="invalid-feedback">
              <div *ngIf="f.months.errors.required">Months is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="startDate">Start Date</label>
            <input type="date" formControlName="startDate" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }" />
            <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
              <div *ngIf="f.startDate.errors.required">Start Date is required</div>
            </div>
          </div>
          <div class="col-md-2">
            <label for="remarks">Notes</label>
            <input type="text" formControlName="remarks" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }" />
            <div *ngIf="submitted && f.remarks.errors" class="invalid-feedback">
              <div *ngIf="f.remarks.errors.required">Notes is required</div>
            </div>
          </div>
        </div>
        <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Save
        </button>
        <button class="btn btn-outline-primary" (click)="onCloseAddCust()">close</button>
      </form>
    </div>
  </div>
</div>