import { formatDate } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { CollectonPersonModel, company1Model, companyModel, CustomerModel, grnFollowUpModel, noteTrackModel, PendingBills, Role, User, userModel } from '../models';
import { AuthenticationService } from '../_services/authentication.service';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

    loading = false;
    currentUser: User;
    pendBills: PendingBills[] = [];
    pendBillsAll: PendingBills[] = [];
    pendBills1: PendingBills[] = [];
    srcCustomer: string = "";
    companys: companyModel[] = [];
    companys2: company1Model[] = [];
    companys21: company1Model[] = [];

    grnfollowups: grnFollowUpModel[] = [];
    total1: grnFollowUpModel = new grnFollowUpModel();
    selectedcompany: string = '-1';
    selectedSales: string = '-1';
    selectedEmployee: string = '-1';

    CollPersons: CollectonPersonModel[] = [];
    total: company1Model = new company1Model();
    total11: company1Model = new company1Model();
    srcCustomerFUp: string = "0";
    srcCustomerStatus: string = "0";

    constructor(private api1: AuthenticationService, private api: RayarApiService) {
        this.currentUser = this.api1.currentUserValue;
        this.total1.accountName = "Total";
        this.total1.allgrnBillCount = 0;
        this.total1.noGRNBillCount = 0;
        this.total1.halfGRNBillCount = 0;
        this.GetAppUsers();
        //this.getGrnfollowup();
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key == "Enter") {
            this.search();
        }
    }

    suppliedDocs: noteTrackModel[] = [];
    addNotes: noteTrackModel = new noteTrackModel();

    downloadBills(u: PendingBills) {
        this.api.downloadBlobFilePost("Download1/" + u.billId + "/ALL", null).subscribe(
            data => {
                switch (data.type) {
                    case HttpEventType.Response:
                        var fileName = u.billId + ".jpg";
                        if (data.body.type == "application/zip") {
                            fileName = u.billId + ".zip"
                        } else if (data.body.type == "application/pdf") {
                            fileName = u.billId + ".pdf"
                        }
                        const a = document.createElement('a');
                        a.setAttribute('style', 'display:none;');
                        document.body.appendChild(a);
                        a.download = fileName;
                        a.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
                        a.target = '_blank';
                        a.click();
                        document.body.removeChild(a);
                        break;
                }
            },
            () => {
            }
        );

    }


    getNotes(i: number) {
        var d = new noteTrackModel();
        d.customerId = i;
        d.noteType = "ALL";
        d.page = 0;
        d.pageSize = 100;
        this.api.getnoteTraks(d).subscribe(result => this.suppliedDocs = result, error => {
            this.loading = false; console.error(error)
        });
    }

    saveNoteTrack1(c: number) {
        if (this.addNotes.notes) {
            this.addNotes.removeFollowUp = false;
            this.api.saveNoteTrack(this.addNotes).subscribe(result => {
                this.addNotes.notes = "";
                this.addNotes.customerFolDate = null;
                this.addNotes.internal = true;
                this.getNotes(c);
                var n = new noteTrackModel();
                n.id = c;
                n.noteType = "CustNote";
                n.internal = true;
                n.customerId = c;
                this.addNotes = n;
            }, error => {
                this.loading = false; console.error(error)
            });
        }
    }

    saveNoteTrack2(c: number) {
        if (this.addNotes.customerFolDate) {
            this.addNotes.notes = '';
            this.addNotes.removeFollowUp = false;
            var ff = this.addNotes.customerFolDate;
            this.api.saveNoteTrack(this.addNotes).subscribe(result => {
                this.addNotes.notes = "";
                this.addNotes.customerFolDate = null;
                this.addNotes.internal = true;
                this.pendBills.forEach(c1 => {
                    if (c1.customerId == c) {
                        c1.customerFolDate = ff;
                    }
                })
                this.getNotes(c);
                var n = new noteTrackModel();
                n.id = c;
                n.noteType = "CustNote";
                n.internal = true;
                n.customerId = c;
                this.addNotes = n;
            }, error => {
                this.loading = false; console.error(error)
            });
        }
    }

    saveNoteTrack3(c: number) {
        this.addNotes.notes = '';
        this.addNotes.removeFollowUp = true;
        this.api.saveNoteTrack(this.addNotes).subscribe(result => {
            this.addNotes.notes = "";
            this.addNotes.customerFolDate = null;
            this.addNotes.internal = true;
            this.pendBills.forEach(c1 => {
                if (c1.customerId == c) {
                    c1.customerFolDate = null;
                }
            })
            this.getNotes(c);
            var n = new noteTrackModel();
            n.id = c;
            n.noteType = "CustNote";
            n.internal = true;
            n.customerId = c;
            this.addNotes = n;
        }, error => {
            this.loading = false; console.error(error)
        });
    }

    onBlurEvent(b: company1Model) {
        var d = new CustomerModel();
        d.customerId = b.companyId;
        d.contactName = b.contactName;
        d.contactPhone = b.contactPhone;
        d.gst = b.gst;
        this.api.UpdateCustomer(d).subscribe(result => {
            this.pendBills.forEach(c => {
                if (c.customerId == b.companyId) {
                    c.contactName = b.contactName;
                    c.contactPhone = b.contactPhone;
                    c.gst = b.gst;
                }
            })
        }, error => { this.loading = false; console.error(error) });
    }

    saveNoteTrackBill(c: PendingBills) {
        if (c.billNote) {
            var n = new noteTrackModel();
            n.id = c.customerId;
            n.noteType = "CustNote";
            n.internal = true;
            n.customerId = c.customerId;
            n.billId = c.billId;
            n.notes = c.billNote;
            this.addNotes.removeFollowUp = false;
            this.api.saveNoteTrack(n).subscribe(result => {
                c.billNote = "";
                this.getNotes(n.customerId);
            }, error => {
                this.loading = false; console.error(error)
            });
        }
    }

    custcompanys: string[] = []
    selectedcompany1: string = "-1";

    getpendBills() {
        var s = this.selectedcompany1;
        return this.pendBills1.filter(function (f) {
            return s == "-1" || f.companyName == s;
        });
    }

    markStatus(u: company1Model, s: string) {
        var msg = "Are you sure to mark Close this Customer?";
        if (s == 'A')
            msg = "Are you sure to mark Active this Customer?";
        if (confirm(msg))
            this.api.getCustStatus(u.companyId, s).subscribe(result => {
                this.companys21.forEach(c => {
                    if (c.companyId == u.companyId) {
                        c.custStatus = s;
                    }
                })
            });
    }

    showDetail(u: company1Model) {
        this.selectedcompany1 = this.selectedcompany;
        this.companys21.forEach(x => {
            if (u.companyName == x.companyName) {
                x.showDetail = !x.showDetail;
                if (x.showDetail) {
                    this.pendBills1 = this.pendBillsAll.filter(function (h) {
                        return h.customerName == u.companyName;
                    });
                    this.custcompanys = this.pendBills1.map(item => item.companyName)
                        .filter((value, index, self) => self.indexOf(value) === index);
                    this.getNotes(x.companyId);
                    var n = new noteTrackModel();
                    n.id = x.companyId;
                    n.noteType = "CustNote";
                    n.internal = true;
                    n.customerId = x.companyId
                    this.addNotes = n;
                    this.pendBills1.sort((a, b) => a.billDays - b.billDays);
                }
            }
            else
                x.showDetail = false;
        });
    }

    ngOnInit() {
    }

    appusers: userModel[] = [];
    searchAppName: string = "Rayar";

    GetAppUsers() {
        this.api1.getSearchUsers(null, this.searchAppName)
            .subscribe(result => {
                this.appusers = result.filter(function (hero) {
                    return hero.role == 'Admin' || hero.role == 'Employee';
                }).sort((a, b) => a.role.localeCompare(b.role) || a.userName.localeCompare(b.userName));
                this.geCompany();
            }, error => {
                console.error(error);
                this.loading = false;
            });
    }

    geCompany() {
        this.api.getCompanys().subscribe(result => {
            if (this.currentUser.role == Role.Company) {
                var c = this.currentUser.profileId;
                this.companys = result.filter(function (hero) {
                    return hero.companyName == c;
                });
            } else {
                this.companys = result;
            }
        }, error => {
            this.loading = false; console.error(error);
        });
        this.api.getCollPersons1().subscribe(result => {
            this.CollPersons = result.sort((a, b) => a.name.localeCompare(b.name));
        }, error => {
            this.loading = false; console.error(error)
        });
    }

    search() {
        this.api.getPendingBillAge().subscribe(result => {
            this.pendBills = result;
            this.pendBillsAll = result;
            this.summarize1();
        }, error => {
            this.loading = false; console.error(error);
        });
    }

    getGrnfollowup() {
        this.api.getGrnfollowup().subscribe(result => {
            this.grnfollowups = result.filter(function (hero) { return hero.noGRNBillCount + hero.allgrnBillCount + hero.halfGRNBillCount > 0 });
            this.grnfollowups.sort((a, b) => a.accountName.localeCompare(b.accountName));
            for (let data of this.grnfollowups) {
                this.total1.allgrnBillCount = this.total1.allgrnBillCount + data.allgrnBillCount;
                this.total1.noGRNBillCount = this.total1.noGRNBillCount + data.noGRNBillCount;
                this.total1.halfGRNBillCount = this.total1.halfGRNBillCount + data.halfGRNBillCount;
            }
        }, error => {
            this.loading = false; console.error(error);
        });
    }

    sort2(u: string) {
        if (u == "0") {
            this.companys2 = this.companys2.filter(function (hero) { return hero.billAmount > 0 }).sort((a, b) => b.billAmount - a.billAmount);
        }
        if (u == "1") {
            this.companys2 = this.companys2.filter(function (hero) { return hero.billAmount > 0 }).sort((a, b) => b.billAmount1 - a.billAmount1);
        }
        if (u == "2") {
            this.companys2 = this.companys2.filter(function (hero) { return hero.billAmount > 0 }).sort((a, b) => b.billAmount2 - a.billAmount2);
        }
        if (u == "3") {
            this.companys2 = this.companys2.filter(function (hero) { return hero.billAmount > 0 }).sort((a, b) => b.billAmount3 - a.billAmount3);
        }
        if (u == "4") {
            this.companys2 = this.companys2.filter(function (hero) { return hero.billAmount1 + hero.billAmount2 + hero.billAmount3 > 0 }).sort((a, b) => b.billAmount1 + b.billAmount2 + b.billAmount3 - a.billAmount1 + a.billAmount2 + a.billAmount3);
        }
    }

    sort21(u: string) {
        if (u == "0") {
            this.companys21 = this.companys21.filter(function (hero) { return hero.billAmount > 0 }).sort((a, b) => b.billAmount - a.billAmount);
        }
        if (u == "1") {
            this.companys21 = this.companys21.filter(function (hero) { return hero.billAmount > 0 }).sort((a, b) => b.billAmount1 - a.billAmount1);
        }
        if (u == "2") {
            this.companys21 = this.companys21.filter(function (hero) { return hero.billAmount > 0 }).sort((a, b) => b.billAmount2 - a.billAmount2);
        }
        if (u == "3") {
            this.companys21 = this.companys21.filter(function (hero) { return hero.billAmount > 0 })
                .sort((a, b) => b.billAmount3 - a.billAmount3);
        }
        if (u == "4") {
            this.companys21 = this.companys21.filter(function (hero) { return hero.billAmount1 + hero.billAmount2 + hero.billAmount3 > 0 }).sort((a, b) => b.billAmount1 + b.billAmount2 + b.billAmount3 - a.billAmount1 + a.billAmount2 + a.billAmount3);
        }
        if (u == "5") {
            this.companys21 = this.companys21.filter(function (hero) {
                return hero.customerFolDate
            }).sort((a, b) => a.customerFolDate.getTime() - b.customerFolDate.getTime());
        }
    }

    getFDate(id: number) {
        var d = this.pendBills.filter(function (f) {
            return f.customerId == id;
        })
        return d[0].customerFolDate;
    }

    FollowUp: boolean = false;
    selectedDate = Date.now();
    getEmployeeName(appuserId: number) {
        if (appuserId <= 0) return "NoEmp";
        var personName = this.appusers.filter(function (hero) {
            return hero.id == appuserId;
        });
        return personName[0].userName;
    }

    summarize1() {
        this.companys21 = [];
        var sc = this.srcCustomer;
        var ss = this.selectedSales;
        var se = this.selectedEmployee;
        var s1 = this.srcCustomerFUp;
        var s11 = this.srcCustomerStatus;
        var s2 = this.selectedDate;
        var f = this.FollowUp;
        var cs = this.selectedcompany;
        var salesMans = [];
        if (se != "-1") {
            this.CollPersons.filter(function (d) {
                return d.appUserId == parseInt(se);
            }).forEach((d) => {
                salesMans.push(d.name);
            });
        }else{
            salesMans = [];
        }

        this.pendBills.filter(function (c) {
            return (ss == "-1" || ss == c.personName)
                && (se == "-1" || salesMans.includes(c.personName))
                && (cs == '-1' || c.companyName == cs)
                && (!f || (c.customerFolDate && formatDate(c.customerFolDate, 'yyyy-MM-dd', 'en_US') == formatDate(s2, 'yyyy-MM-dd', 'en_US')))
                && (s1 == "0" || (s1 == "1" && c.customerFolDate) || (s1 == "2" && !c.customerFolDate))
                && (s11 == "0" || c.custStatus == "C")
                && (!sc || (sc && c.customerName.toLowerCase().includes(sc.toLowerCase())));
        }).map(item => item.customerId)
            .filter((value, index, self) => self.indexOf(value) === index)
            .forEach(x => {
                var c = new company1Model();
                c.companyId = x;
                c.billAmount1 = 0;
                c.billAmount2 = 0;
                c.billAmount3 = 0;
                c.billAmount4 = 0;
                c.billAmount = 0;
                c.billcount = 0;
                c.billcount1 = 0;
                c.billcount2 = 0;
                c.billcount3 = 0;
                c.billcount4 = 0;
                this.companys21.push(c);
            });
        var c = new company1Model();
        c.companyId = 0;
        c.companyName = "AllCustomers";
        c.billAmount1 = 0;
        c.billAmount2 = 0;
        c.billAmount3 = 0;
        c.billAmount4 = 0;
        c.billAmount = 0;
        c.billcount = 0;
        c.billcount1 = 0;
        c.billcount2 = 0;
        c.billcount3 = 0;
        c.billcount4 = 0;
        if (this.currentUser.role !== Role.Sales) {
            this.companys21.push(c);
        }
        this.companys21.forEach(x => {
            this.pendBills.filter(function (c) {
                return c.customerId == x.companyId
                    && (ss == "-1" || ss == c.personName)
                    && (cs == '-1' || c.companyName == cs)
                    && (!f || (c.customerFolDate && formatDate(c.customerFolDate, 'yyyy-MM-dd', 'en_US') == formatDate(s2, 'yyyy-MM-dd', 'en_US')))
                    && (s1 == "0" || (s1 == "1" && c.customerFolDate) || (s1 == "2" && !c.customerFolDate))
                    && (s11 == "0" || c.custStatus == "C")
                    && (!sc || (sc && c.customerName.toLowerCase().includes(sc.toLowerCase())));
            }).forEach(hero => {
                x.companyName = hero.customerName;
                x.contactName = hero.contactName;
                x.contactPhone = hero.contactPhone;
                x.custStatus = hero.custStatus;
                x.gst = hero.gst;
                x.billcount = x.billcount + 1;
                x.billAmount = x.billAmount + hero.balance;
                x.customerFolDate = x.customerFolDate;
                if (hero.billDays >= 21 && hero.billDays < 42) {
                    x.billAmount1 = x.billAmount1 + hero.balance;
                    x.billcount1 = x.billcount1 + 1;
                } else if (hero.billDays >= 42 && hero.billDays < 63) {
                    x.billAmount2 = x.billAmount2 + hero.balance;
                    x.billcount2 = x.billcount2 + 1;
                } else if (hero.billDays >= 63) {
                    x.billAmount3 = x.billAmount3 + hero.balance;
                    x.billcount3 = x.billcount3 + 1;
                }
            });
        });
        let t1 = 0;
        let t2 = 0;
        let t3 = 0;
        let t4 = 0;
        let t5 = 0;
        let t6 = 0;
        let t7 = 0;
        let t8 = 0;
        for (let data of this.companys21) {
            t1 += data.billAmount1;
            t2 += data.billcount1;

            t3 += data.billAmount2;
            t4 += data.billcount2;

            t5 += data.billAmount3;
            t6 += data.billcount3;

            t7 += data.billAmount;
            t8 += data.billcount;
        }

        var c = new company1Model();
        c.companyId = 0;
        c.companyName = "Total";
        c.billAmount1 = t1;
        c.billAmount2 = t3;
        c.billAmount3 = t5;
        c.billAmount4 = t1 + t3 + t5;
        c.billAmount = t7;

        c.billcount1 = t2;
        c.billcount2 = t4;
        c.billcount3 = t6;
        c.billcount4 = t2 + t4 + t6;
        c.billcount = t8;
        this.total11 = c;
        this.companys21 = this.companys21.filter(function (hero) { return hero.billAmount > 0 }).sort((a, b) => a.companyName.localeCompare(b.companyName));
    }

    download() {
        this.api.downloadFile("PendingBillAgeReport/" + this.selectedcompany + '/' + this.selectedSales).subscribe(
            data => {
                switch (data.type) {
                    case HttpEventType.Response:
                        const downloadedFile = new Blob([data.body], { type: data.body.type });
                        const a = document.createElement('a');
                        a.setAttribute('style', 'display:none;');
                        document.body.appendChild(a);
                        a.download = "Report" + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
                        a.href = URL.createObjectURL(downloadedFile);
                        a.target = '_blank';
                        a.click();
                        document.body.removeChild(a);
                        break;
                }
            },
            () => {
            }
        );
    }

}
