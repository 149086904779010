import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './_helpers/auth.guard';
import { LoginComponent } from './login/login.component';
import { Role, Role1 } from './models';
import { CollectionReviewComponent } from './collection-review/collection-review.component';
import { CollectionComponent } from './collection/collection.component';
import { BillsComponent } from './bills/bills.component';
import { AssignCollectionComponent } from './assign-collection/assign-collection.component';
import { EmployeesComponent } from './employees/employees.component';
import { CustomersComponent } from './customers/customers.component';
import { CollectionReportComponent } from './collection-report/collection-report.component';
import { CustomerReportComponent } from './customer-report/customer-report.component';
import { CancelbillComponent } from './cancelbill/cancelbill.component';
import { PaidBillsComponent } from './paid-bills/paid-bills.component';
import { AppuserComponent } from './appuser/appuser.component';
import { ProfileComponent } from './profile/profile.component';
import { ChequeReviewComponent } from './cheque-review/cheque-review.component';
import { ExpenseComponent } from './expense/expense.component';
import { BouncedChequesComponent } from './bounced-cheques/bounced-cheques.component';
import { CollectionReceiveComponent } from './collection-receive/collection-receive.component';
import { ViewBillsComponent } from './view-bills/view-bills.component';
import { BankStmtComponent } from './bank-stmt/bank-stmt.component';
import { AddCollectionComponent } from './add-collection/add-collection.component';
import { DamageTrackComponent } from './damage-track/damage-track.component';
import { ProdutsComponent } from './produts/produts.component';
import { ViewcollectionComponent } from './viewcollection/viewcollection.component';
import { AccountsComponent } from './accounts/accounts.component';
import { AccountLedgerComponent } from './account-ledger/account-ledger.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ComledgerComponent } from './comledger/comledger.component';
import { InventoryComponent } from './inventory/inventory.component';
import { DocreviewComponent } from './docreview/docreview.component';
import { ExtmgnxeroxComponent } from './extmgnxerox/extmgnxerox.component';
import { CheckinComponent } from './checkin/checkin.component';
import { AttendsrcComponent } from './attendsrc/attendsrc.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { CustomerledgerComponent } from './customerledger/customerledger.component';
import { CompanyComponent } from './company/company.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ClosingComponent } from './closing/closing.component';
import { BankstmtreviewComponent } from './bankstmtreview/bankstmtreview.component';
import { SalemantrackComponent } from './salemantrack/salemantrack.component';
import { SalemantrackbaskinComponent } from './salemantrackbaskin/salemantrackbaskin.component';
import { BillgrnComponent } from './billgrn/billgrn.component';
import { BillplanComponent } from './billplan/billplan.component';
import { CustomermarginComponent } from './customermargin/customermargin.component';
import { Billplan1Component } from './billplan1/billplan1.component';
import { OrderIntakeComponent } from './order-intake/order-intake.component';
import { DriverviewComponent } from './driverview/driverview.component';
import { FullviewComponent } from './fullview/fullview.component';
import { ChitsComponent } from './pm/chits/chits.component';
import { ActivityComponent } from './pm/activity/activity.component';
import { InventComponent } from './invent/invent.component';
import { SaleslookupComponent } from './saleslookup/saleslookup.component';
import { BillComponent } from './bill/bill.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PaymentsComponent } from './payments/payments.component';
import { AccountcompanyComponent } from './accountcompany/accountcompany.component';
import { BankstmtemailComponent } from './bankstmtemail/bankstmtemail.component';
import { CustmarginviewComponent } from './custmarginview/custmarginview.component';
import { SalemantrackrouteComponent } from './salemantrackroute/salemantrackroute.component';
import { EmpComponent } from './emp/emp.component';
import { EmpAttendanceComponent } from './emp-attendance/emp-attendance.component';
import { CustorderComponent } from './custorder/custorder.component';
import { EpiprnComponent } from './epiprn/epiprn.component';
import { EpiprnviewComponent } from './epiprnview/epiprnview.component';
import { NotesviewComponent } from './notesview/notesview.component';
import { MtstmtviewComponent } from './mtstmtview/mtstmtview.component';
import { AccountComponent } from './pm/account/account.component';
import { InvledgerComponent } from './invledger/invledger.component';
import { HrchitComponent } from './pm/hrchit/hrchit.component';
import { RanaComponent } from './pm/rana/rana.component';
import { Account1Component } from './pm/account1/account1.component';


const routes: Routes = [
  {
    path: '', component: OrderIntakeComponent, canActivate: [AuthGuard]
  },
  {
    path: 'datr', component: AccountComponent, canActivate: [AuthGuard], data: { roles: [Role.MN, Role.PAdmin] }
  },
  {
    path: 'datr1', component: Account1Component, canActivate: [AuthGuard], data: { roles: [Role.TI, Role.PAdmin] }
  },
  {
    path: 'hrchits', component: HrchitComponent, canActivate: [AuthGuard], data: { roles: [Role.TI, Role.PAdmin] }
  },
  {
    path: 'rana', component: RanaComponent, canActivate: [AuthGuard], data: { roles: [Role.TI, Role.PAdmin] }
  },
  {
    path: 'pmc', component: ChitsComponent, canActivate: [AuthGuard], data: { roles: [Role.MN, Role.PAdmin] }
  },
  {
    path: 'activ', component: ActivityComponent, canActivate: [AuthGuard], data: { roles: [Role.MN, Role.TI, Role.PAdmin] }
  },

  {
    path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: { roles: [Role.ReadOnly, Role.Admin, Role.Employee, Role.Driver, Role.Sales, Role.Company, Role.Customer] }
  },
  {
    path: 'invledger', component: InvledgerComponent , canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee, Role.Sales, Role.Company] }
  },
  {
    path: 'notesView', component: NotesviewComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }
  },
  {
    path: 'mtpayre', component: MtstmtviewComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'prnepi', component: EpiprnComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'prnepi1', component: EpiprnviewComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'custord', component: CustorderComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Customer] }
  },
  {
    path: 'custs2', component: CustmarginviewComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }
  },
  {
    path: 'mtes', component: AccountcompanyComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }
  },
  {
    path: 'mtbks', component: BankstmtemailComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }
  },
  {
    path: 'scols', component: PaymentsComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Sales] }
  },
  {
    path: 'dash', component: DashboardComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee, Role.Sales, Role.Company] }
  },
  {
    path: 'aldg1', component: BillgrnComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee, Role.Sales, Role.Company] }
  },
  {
    path: 'drve', component: DriverviewComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Driver, Role.Sales, Role.Company] }
  },
  {
    path: 'bpl', component: BillplanComponent, canActivate: [AuthGuard], data: { roles: [Role.ReadOnly, Role.Admin, Role.Employee, Role.Sales, Role.Company] }
  },
  {
    path: 'smr', component: SalemantrackrouteComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Sales, Role.Company] }
  },
  {
    path: 'smrc', component: SalemantrackbaskinComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Sales, Role.Company] }
  },
  {
    path: 'fv', component: FullviewComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'bpl1', component: Billplan1Component, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'stc', component: SalemantrackComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Company] }
  },
  {
    path: 'i100', component: InventComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Company, Role.Sales, Role.Employee] }
  },
  {
    path: 'i200', component: SaleslookupComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Company, Role.Sales, Role.Employee] }
  },
  {
    path: 'aci', component: CheckinComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee, Role.Driver] }
  },
  {
    path: 'aco', component: CheckoutComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee, Role.Driver] }
  },
  {
    path: 'acr', component: AttendsrcComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'staff', component: EmpComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'vch', component: VehicleComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee, Role.Driver] }
  },
  {
    path: 'acrv', component: AttendanceComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'ear', component: EmpAttendanceComponent, canActivate: [AuthGuard], data: { roles: [Role.ReadOnly, Role.Admin, Role.Employee, Role.Driver, Role.Sales] }
  },
  {
    path: 'exp', component: ExpenseComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'bills', component: BillsComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'bills1', component: BillComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee, Role.Customer] }
  },
  {
    path: 'dmgtk', component: DamageTrackComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'appset', component: DocreviewComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }
  },
  {
    path: 'agnColl', component: AssignCollectionComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'bnkSt', component: BankStmtComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'bnkSt1', component: BankstmtreviewComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'chkRev', component: ChequeReviewComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'bncChk', component: BouncedChequesComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'collRev', component: CollectionReviewComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'collRec', component: CollectionReceiveComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }
  },
  {
    path: 'closRev', component: ClosingComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }
  },
  {
    path: 'custs', component: CustomersComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'custsq1', component: CustomermarginComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'purInv', component: InvoiceComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'emps', component: EmployeesComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'acts', component: AccountsComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'c1mps', component: CompanyComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'comLed', component: ComledgerComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }
  },
  {
    path: 'invent', component: InventoryComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }
  },
  {
    path: 'prds', component: ProdutsComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'canbill', component: CancelbillComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }
  },
  {
    path: 'padbill', component: PaidBillsComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }
  },
  {
    path: 'user', component: AppuserComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }
  },


  {
    path: 'cldg', component: CustomerledgerComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'custRpt', component: CustomerReportComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'vwCols', component: ViewcollectionComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'collRpt', component: CollectionReportComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'bakprnt', component: ExtmgnxeroxComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'vwBill', component: ViewBillsComponent, canActivate: [AuthGuard], data: { roles: [Role.ReadOnly, Role.Admin, Role.Employee] }
  },
  {
    path: 'colls', component: CollectionComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'acolls', component: AddCollectionComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },
  {
    path: 'aldg', component: AccountLedgerComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Employee] }
  },

  {
    path: 'pr', component: ProfileComponent, canActivate: [AuthGuard]
  },
  {
    path: 'login', component: LoginComponent
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
