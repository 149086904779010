<div class="row">
    <div class="col">
        <div class="form-row">
            <div class="col-lg-1 col-md-2">
                <label for="companyName">Company</label>
                <select class="form-control" [(ngModel)]="searchBillModel.companyName">
                    <option value="" selected>All</option>
                    <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
                </select>
            </div>
            <div class="col-lg-1 col-md-2">
                <label>Bill Number</label>
                <input type="text" [(ngModel)]="searchBillModel.billNumber" class="form-control" />
            </div>
            <div class="col-lg-1 col-md-2">
                <label>Customer/Account</label>
                <input type="text" [(ngModel)]="searchBillModel.customerName" class="form-control" />
            </div>
            <div class="col-lg-1 col-md-2">
                <label>Bill Assigned</label>
                <input type="text" [(ngModel)]="searchBillModel.personName" class="form-control" />
            </div>
            <div class="col-lg-1 col-md-2">
                <label>From</label>
                <input type="date" [ngModel]="searchBillModel.fromDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler($event)" class="form-control" />
            </div>
            <div class="col-lg-1 col-md-2">
                <label>To</label>
                <input type="date" [ngModel]="searchBillModel.toDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler1($event)" class="form-control" />
            </div>
            <div class="col-lg-1 col-md-2">
                <label for="mode">Mode</label>
                <select class="form-control" [(ngModel)]="searchBillModel.billStatus"
                    (change)="selectChangeHandler3($event)">
                    <option selected value="">All</option>
                    <option value="Cash">Cash</option>
                    <option value="CashDiscount">CashDiscount</option>
                    <option value="DisplayDis">DisplayDiscount</option>
                    <option value="Cheque">Cheque</option>
                    <option value="Damage">Damage</option>
                    <option value="MobileTransfer">MobileTransfer</option>
                    <option value="NEFT">NEFT</option>
                    <option value="Shortage">Shortage</option>
                </select>
            </div>
            <div class="col-lg-1 col-md-2">
                <label for="collectedBy">Collected By</label>
                <select class="form-control" [(ngModel)]="searchBillModel.personId"
                    (change)="selectChangeHandler2($event)">
                    <option value="0" selected *ngIf="isUrAmSr">All</option>
                    <option *ngFor="let p of persons1" [value]="p.personId">{{p.name}}</option>
                </select>
            </div>
            <div class="col-lg-1 col-md-2">
                <label> </label>
                <div>
                    <button class="btn btn-outline-primary" (click)="onCollBillSearch()">Search</button>
                </div>
            </div>
            <div class="col-lg-1 col-md-2">
                <label> </label>
                <div>
                    <button class="btn btn-outline-primary" (click)="onCollBillSearchReset()">Reset</button>
                </div>
            </div>
            <div class="col-lg-1 col-md-2">
                <label> </label>
                <div>
                    <button class="btn btn-outline-primary" (click)="downloadExpenseReport()">Download</button>
                </div>
            </div>
        </div>
        <br>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Created</th>
                    <th scope="col">Company</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Bill Date</th>
                    <th scope="col">Bill#</th>
                    <th scope="col">Bill Value</th>
                    <th scope="col">Bill Status</th>
                    <th scope="col">AssginedTo</th>
                    <th scope="col">Mode</th>
                    <th scope="col">Collected</th>
                    <th scope="col">Date</th>
                    <th scope="col">remarks</th>
                    <th scope="col">CollectedBy</th>
                    <th scope="col">CheckDetails</th>
                    <th scope="col">Bounced Credited Date</th>
                    <th scope="col">Bounced CheckDetail</th>
                    <th scope="col">Bounced</th>
                    <th scope="col">Bounced FollowedUp</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of colls">
                    <td>{{u.createdBy}}</td>
                    <td>{{u.companyName}}</td>
                    <td>{{u.customerName}}</td>
                    <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.billNumber}}</td>
                    <td>{{u.billValue}}</td>
                    <td>{{u.billStatus}}</td>
                    <td>{{u.personName}}</td>
                    <td>{{u.mode}}</td>
                    <td>{{u.collectionValue}}</td>
                    <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.remarks}}</td>
                    <td>{{u.collectedBy}}</td>
                    <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}:: {{u.chequeNumber}}</td>
                    <td>{{u.creditedDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.bouncedCheckDetail}}</td>
                    <td>{{u.bounced}}</td>
                    <td>{{u.bouncedFollowedUp}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>