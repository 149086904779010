<div class="row">
  <div class="col">
    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
    <div class="form-row">
      <div class="col-md-2">
        <label>Search</label>
        <input class="form-control" type="text" [(ngModel)]="searchCustomerName" />
      </div>
      <div class="col-lg-2 col-md-2">
        <label>Reviewed</label>
        <select class="form-control" [(ngModel)]="reviewed">
          <option value="both" selected>both</option>
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      </div>
      <div class="col-md-2">
        <label></label>
        <div>
          <button class="btn btn-outline-primary" (click)="onNameCustomerSearch()">Search</button>
          <button class="btn btn-outline-primary" (click)="onCustSearchReset()">Reset</button>
        </div>
      </div>
      <div class="col-md-2">
        <label></label>
        <div>
          <button class="btn btn-outline-primary" (click)="oAddCustomer()">Add Customer</button>
        </div>
      </div>
      <div class="col-md-2">
        <label></label>
        <div>
          <button class="btn btn-outline-primary" (click)="download()">Download</button>
        </div>
      </div>
    </div>
    <br>
    <table class="table table-striped table-sm table-responsive table-condensed">
      <thead class="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col" *DataCustomAcl="'Admin:Employee'">Edit</th>
          <th scope="col" *DataCustomAcl="'Admin'">Delete</th>
          <th scope="col" *DataCustomAcl="'Admin'"></th>
          <th scope="col" *DataCustomAcl="'Admin'">Login</th>
          <th scope="col">Phone</th>
          <th scope="col">SalesAdd</th>
          <th scope="col">Account</th>
          <th scope="col">Name</th>
          <th scope="col">Information1</th>
          <th scope="col">Information2</th>
          <th scope="col">Information3</th>
          <th scope="col">Zone</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isAddCustomerClicked">
          <td colspan="50">
            <form [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
              <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Save
              </button>
              <button class="btn btn-outline-primary" (click)="onCloseAddCust()">close</button>
              <div class="form-row">
                <div class="col-md-2">
                  <label for="accountId">Account</label>
                  <select class="form-control" formControlName="accountId"
                    [ngClass]="{ 'is-invalid': submitted && f.accountId.errors }">
                    <option value="0" selected>General</option>
                    <option *ngFor="let p of persons" [value]="p.accountId">{{p.accountName}}</option>
                  </select>
                  <div *ngIf="submitted && f.accountId.errors" class="invalid-feedback">
                    <div *ngIf="f.accountId.errors.required">Account Name is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="baskinBill">Baskin Bill Track</label>
                  <select class="form-control" formControlName="baskinBill"
                    [ngClass]="{ 'is-invalid': submitted && f.baskinBill.errors }">
                    <option value="0" selected>No</option>
                    <option value="1">Yes</option>
                  </select>
                  <div *ngIf="submitted && f.baskinBill.errors" class="invalid-feedback">
                    <div *ngIf="f.baskinBill.errors.required">Baskin Bill Track is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="zone">Zone</label>
                  <select class="form-control" formControlName="zone"
                    [ngClass]="{ 'is-invalid': submitted && f.zone.errors }">
                    <option value="Zone1">Zone1</option>
                    <option value="Zone2">Zone2</option>
                    <option value="Zone3">Zone3</option>
                    <option value="Zone4">Zone4</option>
                    <option value="Zone5">Zone5</option>
                    <option value="Zone6">Zone6</option>
                    <option value="Zone7">Zone7</option>
                    <option value="Zone8">Zone8</option>
                    <option value="Zone9">Zone9</option>
                    <option value="Zone10">Zone10</option>
                    <option value="Zone11">Zone11</option>
                    <option value="Zone12">Zone12</option>
                    <option value="Zone13">Zone13</option>
                    <option value="Zone14">Zone14</option>
                  </select>
                  <div *ngIf="submitted && f.zone.errors" class="invalid-feedback">
                    <div *ngIf="f.zone.errors.required">Zone is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="customerName">Customer Name</label>
                  <input type="text" formControlName="customerName" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.customerName.errors }" />
                  <div *ngIf="submitted && f.customerName.errors" class="invalid-feedback">
                    <div *ngIf="f.customerName.errors.required">Customer Name is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="address">Address</label>
                  <input type="text" formControlName="address" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
                  <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                    <div *ngIf="f.address.errors.required">Address is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="mapLink">Map Link</label>
                  <input type="text" formControlName="mapLink" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.mapLink.errors }" />
                  <div *ngIf="submitted && f.mapLink.errors" class="invalid-feedback">
                    <div *ngIf="f.mapLink.errors.required">MapLink is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="information1">Information1</label>
                  <input type="text" formControlName="information1" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.information1.errors }" />
                  <div *ngIf="submitted && f.information1.errors" class="invalid-feedback">
                    <div *ngIf="f.information1.errors.required">Information1 is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="information2">Information2</label>
                  <input type="text" formControlName="information2" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.information2.errors }" />
                  <div *ngIf="submitted && f.information2.errors" class="invalid-feedback">
                    <div *ngIf="f.information2.errors.required">Information2 is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="information3">Information3</label>
                  <input type="text" formControlName="information3" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.information3.errors }" />
                  <div *ngIf="submitted && f.information3.errors" class="invalid-feedback">
                    <div *ngIf="f.information3.errors.required">Information3 is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="gst">GST</label>
                  <input type="text" formControlName="gst" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.gst.errors }" />
                  <div *ngIf="submitted && f.gst.errors" class="invalid-feedback">
                    <div *ngIf="f.gst.errors.required">GST is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="contactName">Contact Name</label>
                  <input type="text" formControlName="contactName" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.contactName.errors }" />
                  <div *ngIf="submitted && f.contactName.errors" class="invalid-feedback">
                    <div *ngIf="f.contactName.errors.required">ContactName is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="contactPhone">Contact Phone</label>
                  <input type="text" formControlName="contactPhone" class="form-control" maxlength="10"
                    [ngClass]="{ 'is-invalid': submitted && f.contactPhone.errors }" />
                  <div *ngIf="submitted && f.contactPhone.errors" class="invalid-feedback">
                    <div *ngIf="f.contactPhone.errors.required">Contact Phone is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="latitude">Latitude</label>
                  <input type="text" formControlName="latitude" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.latitude.errors }" />
                  <div *ngIf="submitted && f.latitude.errors" class="invalid-feedback">
                    <div *ngIf="f.latitude.errors.required">Latitude is required</div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="longitude">Longitude</label>
                  <input type="text" formControlName="longitude" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.longitude.errors }" />
                  <div *ngIf="submitted && f.longitude.errors" class="invalid-feedback">
                    <div *ngIf="f.longitude.errors.required">Longitude is required</div>
                  </div>
                </div>
              </div>
            </form>
            <table class="table table-striped table-sm table-responsive table-condensed">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Company</th>
                  <th scope="col">Margin</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let u of addEditCustModel.margins">
                  <td class="elip">{{u.companyName}}</td>
                  <td>
                    <input type="text" [(ngModel)]="u.margin" appTwoDigitDecimaNumber class="form-control" />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
      <tbody *ngFor="let u of customers">
        <tr>
          <td>
            <i class="fa fa-angle-down" (click)="showDetail(u)"></i>
          </td>
          <td *DataCustomAcl="'Admin:Employee'">
            <button class="btn btn-outline-primary" (click)="onEditCustomer(u)">Edit</button>
          </td>
          <td *DataCustomAcl="'Admin'">
            <button class="btn btn-outline-primary" (click)="onDelteCust(u)">Delete</button>
          </td>
          <td *DataCustomAcl="'Admin'">
            <button class="btn btn-outline-primary" *ngIf="u.hasReviewed==false"
              (click)="MarkReviewedCust(u)">Reviewed</button>
          </td>
          <td *DataCustomAcl="'Admin'">
            <button *ngIf="u.contactPhone && u.userName=='zzzzz'" class="btn btn-sm btn-info"
              (click)="onCreateSalesLogin(u)">Create</button>
            <div *ngIf="u.userName !='zzzzz'"> {{u.userName}}</div>
          </td>
          <td>{{u.contactPhone}}</td>
          <td>
            <div *ngIf="u.salesAdd">
              <button class="btn btn-sm btn-info" (click)="downloadBills(u.customerId)">
                <i class="fa fa-download"></i>
              </button>
            </div>
            <div *ngIf="!u.salesAdd">
              No
            </div>
          </td>
          <td>{{u.accountName}}</td>
          <td><i class="fa fa-map-marker" *ngIf="u.mapLink" (click)="goToLink(u)">-</i>
            {{u.customerName}}
          </td>
          <td>{{u.information1}}</td>
          <td>{{u.information2}}</td>
          <td>{{u.information3}}</td>
          <td>{{u.zone}}</td>
        </tr>
        <tr *ngIf="u.showDetail">
          <td colspan="50">
            <ul class="nav nav-pills">
              <li>
                <a class="active" id="home-tab" data-toggle="tab" href="#home">Detail</a>
              </li>
              <li>
                <a id="messages-tab" data-toggle="tab" href="#messages">Bills</a>
              </li>
            </ul>
            <div class="tab-content clearfix">
              <div class="tab-pane active" id="home">
                <div class="row">
                  <div class="col-md-6">
                    <table class="table table-striped table-sm table-responsive table-condensed">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Company</th>
                          <th scope="col">Margin</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let u of u.margins">
                          <td>{{u.companyName}}</td>
                          <td>{{u.margin}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-6">
                    <table class="table table-striped table-sm table-condensed">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Field</th>
                          <th scope="col">Data</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Baskin Bill Track</td>
                          <td>{{u.baskinBill}}</td>
                        </tr>
                        <tr>
                          <td>GST</td>
                          <td>{{u.gst}}</td>
                        </tr>
                        <tr>
                          <td>Contact Name</td>
                          <td>{{u.contactName}}</td>
                        </tr>
                        <tr>
                          <td>Latitude</td>
                          <td>{{u.latitude}}</td>
                        </tr>
                        <tr>
                          <td>Longitude</td>
                          <td>{{u.longitude}}</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>{{u.address}}</td>
                        </tr>
                        <tr>
                          <td>Map Link</td>
                          <td>{{u.mapLink}}</td>
                        </tr>
                        <tr>
                          <td>Baskin</td>
                          <td>{{u.baskinBill}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="messages">
                <div class="row">
                  <div class="col-md-6">
                    <table class="table table-striped table-sm table-responsive table-condensed">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">BillId</th>
                          <th scope="col">Bill</th>
                          <th scope="col">Date</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let u of u.bills">
                          <td> <input type="checkbox" [value]="u.billId"
                              (change)="selectChangeHandlerBillSelect($event)" />
                          </td>
                          <td>{{u.billId}}</td>
                          <td>{{u.billNumber}}</td>
                          <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
                          <td>{{u.billValue}}</td>
                          <td>{{u.billStatus}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-6">
                    Move Bill to :
                    <div class="row">
                      <div class="col-md-6">
                        <input type="text" class="form-control" (keyup)="onKey1($event)" [(ngModel)]="selectedtext1" />
                      </div>
                      <div class="col-md-6">
                        <button class="btn btn-outline-primary" *ngIf="selectedCust>0 && billIds.length>0"
                          (click)="Updatebill()">Update</button>
                      </div>
                    </div>
                    <table class="table table-striped table-sm table-responsive table-condensed">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let u of custAll1">
                          <td> <input type="radio" name="CustRadio" [value]="u.customerId"
                              (change)="selectChangeHandlerCustSelect($event)" /></td>
                          <td>{{u.customerId}}</td>
                          <td>{{u.customerName}}</td>
                          <td>{{u.address}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>