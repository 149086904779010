import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { CollectonPersonModel, BSaleManTrackModel, companyModel, PendingFollowUpModel, accountModel } from '../models';
import { PendingBills, BillModel, CollectionModel, Role, User, DocumentModel, grnModel, accountLedgerModel, CustomerModel, CustomerMarginModel } from '../models';
import { HttpEventType } from '@angular/common/http';
import { AuthenticationService } from '../_services/authentication.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { jsPDF } from 'jspdf';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { locationService } from '../_services/locationService';

@Component({
  selector: 'app-salemantrackbaskin',
  templateUrl: './salemantrackbaskin.component.html',
  styleUrls: ['./salemantrackbaskin.component.css']
})
export class SalemantrackbaskinComponent implements OnInit {

  custReport: BSaleManTrackModel[] = [];
  custReportAll: BSaleManTrackModel[] = [];
  persons1: CollectonPersonModel[] = [];
  loading = false;
  error = '';
  selectedtext: string = '';
  selectedtext1: string = '';
  selectedperson: string = '0';
  selectedcompany: string = 'All';
  selectedAccount: string = '-1';

  selectedCompany1: string;
  selectedconfig: boolean = true;
  selectedvisit: string = "2";
  selectedorder: string = "2";
  day = "0";
  pendingFollowups: PendingFollowUpModel[] = [];
  pendingBills: PendingBills[] = [];
  searchBillModel1: BillModel = new BillModel();
  colls: CollectionModel[] = [];
  companys: companyModel[] = [];
  currentUser: User;
  billscolls: CollectionModel[] = [];
  persons: accountModel[] = [];
  companysAll: companyModel[] = [];

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      customerName: ['', Validators.required],
      address: ['',],
      mapLink: ['',],
      information3: ['',],
      gst: [''],
      contactName: ['',],
      contactPhone: ['',],
      latitude: [''],
      longitude: ['']
    });
  }

  isdanger(s: string) {
    return isNaN(Number(s));
  }

  getAccounts() {
    this.api.getAccounts().subscribe(result => {
      this.persons = result;
      this.persons.sort((a, b) => a.accountName.localeCompare(b.accountName));
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  showDetail1(u: PendingBills) {
    this.pendingBills.forEach(x => {
      if (u.billId == x.billId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.suppliedDocs2 = [];
          this.api.getCollsSearch(u.billId.toString(), 'GetColls').subscribe(result => { this.billscolls = result; },
            error => console.error(error));
          this.api.getDcouments(u.billId, "Payment").subscribe(result => {
            result.sort((a, b) => b.documentId - a.documentId);
            this.suppliedDocs2 = result.slice(0, 9)
          }, error => {
            this.error = error;
            this.loading = false; console.error(error)
          });
        }
      }
      else
        x.showDetail = false;
    });
  }


  @ViewChild('inputFile2') inputFile2: ElementRef;
  suppliedDocs2: DocumentModel[] = [];

  postOrder1(u: PendingBills) {
    if (u.orderText) {
      var doc = new jsPDF();
      var splitTitle = doc.splitTextToSize(u.orderText, 180);
      var y = 7;
      for (var i = 0; i < splitTitle.length; i++) {
        if (y > 300) {
          y = 10;
          doc.addPage();
        }
        doc.text(splitTitle[i], 10, y);
        y = y + 7;
      }
      this.onUploadPayment(doc.output('blob'), u);
      u.orderText = null;
    }
  }

  upload2(event, u: PendingBills) {
    this.error = "";
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      this.pendingBills.forEach(x => {
        if (u.billId == x.billId) {
          x.showDetail = true;
          if (this.billscolls.length == 0)
            this.api.getCollsSearch(u.billId.toString(), 'GetColls').subscribe(result => { this.billscolls = result; },
              error => console.error(error));
          const file = event.target.files[0];
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (file.type.startsWith("image")) {
              var d = reader.result as string;
              this.imageCompress.compressFile(d, 50, 100).then((compressedImage) => {
                var doc = new jsPDF();
                doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
                this.onUploadPayment(doc.output('blob'), u);
              });
            }
            else {
              if (file.type.startsWith("application/pdf")) {
                this.onUploadPayment(file, u);
              } else {
                this.error = "Invalid File format images/PDF only"
              }
            }
          };
        }
        else
          x.showDetail = false;
      });
    }
  }

  onUploadPayment(file: Blob, u: PendingBills) {
    this.api.uploadToBlob1<DocumentModel>(file, `UploadPayment/${u.billId}/${u.billNumber}`).subscribe(
      data => {
        if (data) {
          switch (data.type) {
            case HttpEventType.UploadProgress:
              break;
            case HttpEventType.Response:
              this.inputFile2.nativeElement.value = '';
              this.suppliedDocs2 = data.body;
              break;
          }
        }
      },
      error => {
        this.inputFile2.nativeElement.value = '';
      }
    );
  }

  markChecked(u: PendingBills) {
    this.api.onMarkChecked(u.billId, u.verifiedRemarks)
      .subscribe(result => { },
        error => { this.error = error; this.loading = false; console.error(error) });
  }


  shownewCust: boolean = false;

  addEditCustModel: CustomerModel = new CustomerModel();
  customerForm: FormGroup;
  get f() { return this.customerForm.controls; };

  getcurr() {
    var lt = this.currentLat;
    var lg = this.currentLong;
    var ml = this.currloclink
    this.customerForm.patchValue({
      mapLink: ml,
      latitude: lt,
      longitude: lg
    });
  }

  selectedFile: any;
  fileName: string;

  uploadPO(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileName = file.name;
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          var imageSrc = reader.result as string;
          this.imageCompress.compressFile(imageSrc, 50, 100).then((compressedImage) => {
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            this.selectedFile = doc.output('blob');
          });
        }
        else {
          if (file.type.startsWith("application/pdf")) {
            this.selectedFile = file;
          } else {
            this.error = "Invalid File format images/PDF only"
          }
        }
      };
    }
  }



  newCust() {
    this.shownewCust = !this.shownewCust;
    this.selectedFile = null;
    this.fileName = "";
    this.inputFilePO.nativeElement.value = '';
    this.customerForm.setValue({
      customerName: '',
      address: '',
      mapLink: '',
      information3: '',
      gst: '',
      contactName: '',
      contactPhone: '',
      latitude: '',
      longitude: ''
    });
    this.addEditCustModel = new CustomerModel();
    this.addEditCustModel.hasReviewed = false;
    this.addEditCustModel.customerId = 0;
    this.addEditCustModel.accountId = 0;
    this.addEditCustModel.margins = [];
    this.addEditCustModel.bills = [];
    this.companysAll.forEach(x => {
      var d = new CustomerMarginModel();
      d.customerId = 0;
      d.companyName = x.companyName;
      this.addEditCustModel.margins.push(d);
    });
  }
  submitted = false;


  onAddCustSubmit() {
    this.submitted = true;
    if (this.customerForm.invalid) return;
    if (!this.fileName) return;
    this.loading = true;
    this.addEditCustModel.accountId = null;
    this.addEditCustModel.customerName = this.f.customerName.value;
    this.addEditCustModel.address = this.f.address.value;
    this.addEditCustModel.mapLink = this.f.mapLink.value;
    this.addEditCustModel.information1 = '';
    this.addEditCustModel.information2 = '';
    this.addEditCustModel.information3 = this.f.information3.value;
    this.addEditCustModel.gst = this.f.gst.value;
    this.addEditCustModel.contactName = this.f.contactName.value;
    this.addEditCustModel.zone = "Zone1";
    this.addEditCustModel.contactPhone = this.f.contactPhone.value;
    this.addEditCustModel.latitude = parseFloat(this.f.latitude.value);
    this.addEditCustModel.longitude = parseFloat(this.f.longitude.value);
    this.addEditCustModel.baskinBill = false;
    this.api.uploadAddCustFile(this.selectedFile, this.addEditCustModel)
      .subscribe(result => {
        this.loading = false;
        this.submitted = false;
        this.selectedFile = null;
        this.fileName = "";
        this.inputFilePO.nativeElement.value = '';
        this.selectedtext = this.addEditCustModel.customerName;
        this.getBSaleManTrackModel(0);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  @ViewChild('inputFilePO') inputFilePO: ElementRef;

  currentLat: any;
  currentLong: any;
  currloclink: string;

  constructor(private formBuilder: FormBuilder,
    private locationService: locationService,
    private imageCompress: NgxImageCompressService,
    private authenticationService: AuthenticationService,
    private api: RayarApiService) {

    this.locationService.getPosition().then(pos => {
      this.currentLat = pos.lat;
      this.currentLong = pos.lng;
      this.currloclink = "https://www.google.com/maps?q=" + pos.lat + "," + pos.lng;
    });
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser.role == Role.Company) {
      this.day = "0";
    }
    this.getCompanys();
    this.getAccounts();
    this.getCompanysAll();
  }


  downloadBills(u: PendingBills) {
    this.api.downloadBlobFilePost("Download1/" + u.billId + "/Bill", null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            var fileName = u.billId + ".jpg";
            if (data.body.type == "application/zip") {
              fileName = u.billId + ".zip"
            } else if (data.body.type == "application/pdf") {
              fileName = u.billId + ".pdf"
            }
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = URL.createObjectURL(new Blob([data.body], { type: data.body.type }));
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }

  showDetail(u: BSaleManTrackModel) {
    this.fileSourceMT = null;
    this.fileTypeMT = null;
    this.selAcled = u;
    this.custReport.forEach(x => {
      if (u.baskinSaleManTrackId == x.baskinSaleManTrackId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.selTab = "1";
          this.getDocuments();
          this.pendingBills = [];
          this.colls = [];
          this.pendingFollowups = [];
          this.onCollBillSearchReset();
          if (u.accountId) {
            this.searchBillModel.accountId = u.accountId;
            this.searchBillModel.billStatus = 'NotPaid';
            this.onCollBillSearch();
          } else {
            this.selectedCompany1 = u.companyName;
            this.getPendingBills(u.customerName);
          }
        }
      }
      else
        x.showDetail = false;
    });
  }

  onCollBillSearchReset() {
    this.searchBillModel = new grnModel();
    this.searchBillModel.billStatus = "All";
    this.searchBillModel.accountId = 0;
    this.accledgers = [];
    this.error = '';
    this.selAcled1 = new grnModel();
  }

  selectChangeHandlercheck1(event: any) {
    this.selectedorder = event.target.value;
    this.filter();
  }

  selectChangeHandlercheck2(event: any) {
    this.selectedvisit = event.target.value;
    this.filter();
  }

  getPendingBills(cName: string) {
    this.api.getCustomerSearch1(cName, '', 0).subscribe(result => {
      if (this.currentUser.role == Role.Company) {
        var c = this.currentUser.profileId;
        this.pendingBills = result.filter(function (hero) {
          return hero.companyName == c;
        });
      } else {
        this.pendingBills = result;
      }
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });

  }

  selTab: string = '1';

  onTabSelect(s: string, cid: number) {
    if (this.selTab != s) {
      if (s == "1") {

      }
      if (s == "2") {
        if (this.colls.length == 0 && this.pendingFollowups.length == 0) {
          this.api.getPendingFollowup1(cid).subscribe(result => { this.pendingFollowups = result; }, error => {
            this.error = error;
            this.loading = false; console.error(error);
          });
          this.getPendingCheques(cid);
        }
      }
    }
    this.selTab = s;
  }

  getPendingCheques(c: number) {
    this.searchBillModel1.page = 0;
    this.searchBillModel1.personId = 0;
    this.searchBillModel1.customerId = c;
    this.api.oncollsView('chequeReviewCid', this.searchBillModel1).subscribe(result => {
      if (this.currentUser.role == Role.Company) {
        var c = this.currentUser.profileId;
        this.colls = result.filter(function (hero) {
          return hero.companyName == c;
        });
      } else {
        this.colls = result;
      }
    }, error => {
      this.error = error; this.loading = false; console.error(error)
    });
  }

  filter() {
    var d1 = this.selectedtext;
    var d2 = this.selectedtext1;
    var d3 = parseInt(this.selectedperson);
    var d4 = this.selectedconfig;
    var d5 = this.day.toString();
    var d6 = this.selectedvisit;
    var d7 = this.selectedorder;

    var currentTime = new Date();
    var today = new Date(currentTime.getTime() + (330 + currentTime.getTimezoneOffset()) * 60000).toDateString();
    this.custReport = this.custReportAll.filter(function (hero) {
      return (d1 == '' || hero.customerName.toLowerCase().includes(d1.toLowerCase())) &&
        (d2 == '' || (hero.route != null && hero.route.toLowerCase().includes(d2.toLowerCase()))) &&
        (d3 == 0 || (d3 == -1 && hero.personId == null) || d3 == hero.personId) &&
        ((d4 == false && hero.baskinSaleManTrackId != null) || (d4 == true && hero.baskinSaleManTrackId == null)) &&
        (d5 == '0' || hero.visitDay.toString() == d5) &&
        (d6 == "2" || (d6 == "0" && hero.visitedDate != null && today === new Date(hero.visitedDate).toDateString()) || (d6 == "1" && (hero.visitedDate == null || new Date(hero.visitedDate).toDateString() !== today))) &&
        (d7 == "2" || (d7 == "0" && hero.orderedDate != null && today === new Date(hero.orderedDate).toDateString()) || (d7 == "1" && (hero.orderedDate == null || new Date(hero.orderedDate).toDateString() !== today)));
    });
  }

  isOrdered(hero: BSaleManTrackModel) {
    var currentTime = new Date();
    var today = new Date(currentTime.getTime() + (330 + currentTime.getTimezoneOffset()) * 60000).toDateString();
    return hero.orderedDate != null && today === new Date(hero.orderedDate).toDateString();
  }

  getCollPersons() {
    this.api.getCollPersons1().subscribe(result => {
      this.persons1 = result.sort((a, b) => a.name.localeCompare(b.name));
      this.getBSaleManTrackModel(this.page);
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  page: number = 0;
  onpage(next: boolean) {
    if (next) {
      this.getBSaleManTrackModel(this.page + 1);
    }
    else {
      if (this.page >= 1)
        this.getBSaleManTrackModel(this.page - 1);
    }
  }

  getBSaleManTrackModel(p: number) {
    this.page = p;
    this.shownewCust = false;
    this.api.getBSaleManTrackModel("baskinsaleManTrackConfig", p, this.selectedAccount, this.selectedcompany, this.selectedperson, this.day.toString(), this.selectedconfig.toString(), this.selectedtext, this.selectedtext1).subscribe(result => {
      result.forEach(x => { x.isEdit = false; });
      this.custReportAll = result.sort((a, b) => a.customerName.localeCompare(b.customerName) || a.companyName.localeCompare(b.companyName));
      this.filter();
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  getCompanys() {
    this.api.getCompanys1().subscribe(result => {
      if (this.currentUser.role == Role.Company) {
        var c = this.currentUser.profileId;
        this.companys = result.filter(function (hero) {
          return hero.companyName == c;
        });
        this.selectedcompany = c;
      } else {
        this.companys = result;
        if (this.currentUser.role != Role.Admin)
          this.selectedcompany = result[0].companyName
        this.getCollPersons();
      }
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  getCompanysAll() {
    this.api.getCompanys().subscribe(result => {
      this.companysAll = result;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  editGrn(u: BSaleManTrackModel) {
    this.custReport.forEach(x => {
      x.isEdit = u.uniqId == x.uniqId;
      if (u.uniqId == x.uniqId) {
        this.editgrnData = new BSaleManTrackModel();
        this.editgrnData.baskinSaleManTrackId = u.baskinSaleManTrackId;
        this.editgrnData.customerId = u.customerId;
        this.editgrnData.remarks = u.remarks;
        this.editgrnData.route = u.route;
        this.editgrnData.margin = u.margin;
        this.editgrnData.category = u.category;
        this.editgrnData.visitDay = u.visitDay;
        this.editgrnData.personId = u.personId;
        this.editgrnData.companyName = u.companyName;
      }
    });
  }

  editgrnData: BSaleManTrackModel;

  saveGrn(u: BSaleManTrackModel) {
    this.editgrnData.visitDay = parseInt(this.editgrnData.visitDay.toString());
    this.editgrnData.personId = parseInt(this.editgrnData.personId.toString());
    this.api.SaveBaskinSaleTrack(this.editgrnData)
      .subscribe(() => {
        this.loading = false;
        this.getBSaleManTrackModel(this.page);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  cancelGrn(u: BSaleManTrackModel) {
    this.custReport.forEach(x => {
      x.isEdit = false;
    });
  }

  calculateDiff(date: string) {
    return Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000 / 60 / 60 / 24);
  }

  @ViewChild('inputFile') inputFile: ElementRef;
  suppliedDocs: DocumentModel[] = [];
  selAcled: BSaleManTrackModel = new BSaleManTrackModel();

  selectledger(u: BSaleManTrackModel) {
    this.selAcled = u;
  }

  postOrder(u: BSaleManTrackModel) {
    if (u.orderText) {
      this.selAcled = u;
      var doc = new jsPDF();
      var splitTitle = doc.splitTextToSize(u.orderText, 180);
      var y = 7;
      for (var i = 0; i < splitTitle.length; i++) {
        if (y > 300) {
          y = 10;
          doc.addPage();
        }
        doc.text(splitTitle[i], 10, y);
        y = y + 7;
      }
      this.onUploadVISIT(doc.output('blob'));
      u.orderText = null;
    }
  }

  upload(event) {
    this.error = "";
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      this.custReportAll.forEach(x => {
        if (this.selAcled.baskinSaleManTrackId == x.baskinSaleManTrackId)
          x.showDetail = true;
        else
          x.showDetail = false;
      });
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          var imageSrc = reader.result as string;
          this.imageCompress.compressFile(imageSrc, 50, 100).then((compressedImage) => {
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            this.onUploadVISIT(doc.output('blob'));
          });
        }
        else {
          if (file.type.startsWith("application/pdf")) {
            this.onUploadVISIT(file);
          } else {
            this.error = "Invalid File format images/PDF only"
          }
        }
      };
    }
  }

  onUploadVISIT(file: Blob) {
    if (!this.selAcled.cashCarry) this.selAcled.cashCarry = false;
    if (!this.selAcled.configMargin) this.selAcled.configMargin = "-";
    var vsturl = `UploadOrder/${this.selAcled.baskinSaleManTrackId}/${this.selAcled.companyId}/${this.selAcled.companyName}/${this.selAcled.customerId}/${this.selAcled.cashCarry}/${this.selAcled.configMargin}`;
    this.api.uploadToBlob(file, vsturl).subscribe(
      data => {
        if (data) {
          switch (data.type) {
            case HttpEventType.UploadProgress:
              break;
            case HttpEventType.Response:
              this.inputFile.nativeElement.value = '';
              this.getBSaleManTrackModel(this.page);
              break;
          }
        }
      },
      error => {
        this.inputFile.nativeElement.value = '';
      }
    );
  }

  getDocuments() {
    this.api.getDcouments(parseInt(this.selAcled.customerId.toString() + this.selAcled.companyId), "VISIT")
      .subscribe(result => {
        result.sort((a, b) => b.documentId - a.documentId);
        this.suppliedDocs = result.slice(0, 9)
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onDownloadDocument(i: number, f: string) {
    this.api.downloadBlobFilePost("Download/" + i, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = f;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }

  onDownloadDocument1(i: number, f: string) {
    this.api.downloadBlobFilePost("Download/" + i, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            window.open(URL.createObjectURL(new Blob([data.body], { type: data.body.type })), 'mywindow1');
            break;
        }
      },
      () => {
      }
    );
  }

  saveGrn1(editgrnData: grnModel) {
    var d = new accountLedgerModel();
    d.id = editgrnData.billId;
    d.grnNumber = editgrnData.grnNumber;
    d.remarks = editgrnData.remarks;
    d.grnStatus = editgrnData.grnStatus;
    d.crDr = "Bill";
    this.api.SaveGrnDetail(d).subscribe(() => {
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });

  }

  searchBillModel: grnModel = new grnModel();;
  accledgers: grnModel[] = [];
  selAcled1: grnModel = new grnModel();
  @ViewChild('inputFile1') inputFile1: ElementRef;
  imageSrcMT: string;
  fileTypeMT: string;
  fileSourceMT: any = null;


  uploadMT(event) {
    this.error = "";
    this.fileTypeMT = null;
    this.fileSourceMT = null;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      this.accledgers.forEach(x => {
        if (this.selAcled1.billId == x.billId)
          x.showDetail = true;
        else
          x.showDetail = false;
      });
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          this.imageSrcMT = reader.result as string;
          this.imageCompress.compressFile(this.imageSrcMT, 50, 100).then((compressedImage) => {
            this.fileTypeMT = "Image";
            this.getDocumentsMT();
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            this.fileSourceMT = doc.output('blob');
          });
        }
        else {
          this.imageSrcMT = null;
          if (file.type.startsWith("application/pdf")) {
            this.api.uploadToBlob(file, "UploadBill/" + this.selAcled1.billId + "/" + this.selAcled1.billNumber + "/GRN").subscribe(
              data => {
                if (data) {
                  switch (data.type) {
                    case HttpEventType.UploadProgress:
                      break;
                    case HttpEventType.Response:
                      this.inputFile1.nativeElement.value = '';
                      this.fileTypeMT = null;
                      this.fileSourceMT = null;
                      this.imageSrcMT = null;
                      this.getDocumentsMT();
                      break;
                  }
                }
              },
              error => {
                this.inputFile.nativeElement.value = '';
              }
            );
          } else {
            this.error = "Invalid File format images/PDF only"
          }
        }
      };
    }
  }
  suppliedDocsMT: DocumentModel[] = [];

  getDocumentsMT() {
    this.api.getDcouments(this.selAcled1.billId, "ALL")
      .subscribe(result => this.suppliedDocsMT = result, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onUploadCancelMT() {
    this.fileTypeMT = null;
    this.fileSourceMT = null;
    this.imageSrcMT = null;
    this.inputFile1.nativeElement.value = '';
  }

  onUploadMT() {
    this.api.uploadToBlob(this.fileSourceMT, "UploadBill/" + this.selAcled1.billId + "/" + this.selAcled1.billNumber + "/GRN").subscribe(
      data => {
        if (data) {
          switch (data.type) {
            case HttpEventType.UploadProgress:
              break;
            case HttpEventType.Response:
              this.inputFile1.nativeElement.value = '';
              this.fileTypeMT = null;
              this.fileSourceMT = null;
              this.imageSrcMT = null;
              this.getDocumentsMT();
              break;
          }
        }
      },
      error => {
        this.inputFile.nativeElement.value = '';
      }
    );
  }

  onCollBillSearch() {
    this.selAcled1 = new grnModel();
    this.searchBillModel.page = 0;
    if (this.searchBillModel.accountId && this.searchBillModel.accountId > 0)
      this.searchBillModel.accountId = parseInt(this.searchBillModel.accountId.toString());
    this.api.getGrn(this.searchBillModel).subscribe(result => {
      this.accledgers = result;
      this.error = '';
      this.accledgers.forEach(x => {
        x.isEdit = false;
      });
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  selectledgerMT(u: grnModel) {
    this.selAcled1 = u;
  }

  showDetailMT(u: grnModel) {
    this.accledgers.forEach(x => {
      if (u.billId == x.billId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.selAcled1 = u;
          this.getDocumentsMT();
        }
      }
      else
        x.showDetail = false;
    });
  }
}
