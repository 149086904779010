import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { BillsComponent } from './bills/bills.component';
import { CollectionComponent } from './collection/collection.component';
import { CollectionReviewComponent } from './collection-review/collection-review.component';
import { AssignCollectionComponent } from './assign-collection/assign-collection.component';
import { CustomersComponent } from './customers/customers.component';
import { EmployeesComponent } from './employees/employees.component';
import { CustomerReportComponent } from './customer-report/customer-report.component';
import { CollectionReportComponent } from './collection-report/collection-report.component';
import { CancelbillComponent } from './cancelbill/cancelbill.component';
import { PaidBillsComponent } from './paid-bills/paid-bills.component';
import { AppuserComponent } from './appuser/appuser.component';
import { ProfileComponent } from './profile/profile.component';
import { LoaderService } from "./_services/LoaderService";
import { LoaderInterceptor } from './_helpers/LoaderInterceptor';
import { ChequeReviewComponent } from './cheque-review/cheque-review.component';
import { ExpenseComponent } from './expense/expense.component';
import { BouncedChequesComponent } from './bounced-cheques/bounced-cheques.component';
import { CollectionReceiveComponent } from './collection-receive/collection-receive.component';
import { ViewBillsComponent } from './view-bills/view-bills.component';
import { BankStmtComponent } from './bank-stmt/bank-stmt.component';
import { AddCollectionComponent } from './add-collection/add-collection.component';
import { DamageTrackComponent } from './damage-track/damage-track.component';
import { onAppInit } from './_helpers/onAppInit';
import { AuthenticationService } from './_services/authentication.service';
import { ProdutsComponent } from './produts/produts.component';
import { ViewcollectionComponent } from './viewcollection/viewcollection.component';
import { AccountsComponent } from './accounts/accounts.component';
import { AccountLedgerComponent } from './account-ledger/account-ledger.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ComledgerComponent } from './comledger/comledger.component';
import { InventoryComponent } from './inventory/inventory.component';
import { DocreviewComponent } from './docreview/docreview.component';
import { ExtmgnxeroxComponent } from './extmgnxerox/extmgnxerox.component';
import { CheckinComponent } from './checkin/checkin.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { AttendsrcComponent } from './attendsrc/attendsrc.component';
import { CustomerledgerComponent } from './customerledger/customerledger.component';
import { CompanyComponent } from './company/company.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ClosingComponent } from './closing/closing.component';
import { BankstmtreviewComponent } from './bankstmtreview/bankstmtreview.component';
import { SalemantrackComponent } from './salemantrack/salemantrack.component';
import { SalemantrackbaskinComponent } from './salemantrackbaskin/salemantrackbaskin.component';
import { MyMonitoringService } from './_services/MyMonitoringService';
import { ErrorHandlerService } from './_services/ErrorHandlerService';
import { BillgrnComponent } from './billgrn/billgrn.component';
import { BillplanComponent } from './billplan/billplan.component';
import { CustomermarginComponent } from './customermargin/customermargin.component';
import { Billplan1Component } from './billplan1/billplan1.component';
import { SharedModule } from './_shared/shared.module';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { OrderIntakeComponent } from './order-intake/order-intake.component';
import { DriverviewComponent } from './driverview/driverview.component';
import { AclDirective, TwoDigitDecimaNumberDirective } from './_helpers/AclDirective';
import { AclOnlyDirective, AclUsersDirective } from "./_helpers/AclOnlyDirective";
import { locationService } from './_services/locationService';
import { FullviewComponent } from './fullview/fullview.component';
import { ChitsComponent } from './pm/chits/chits.component';
import { ActivityComponent } from './pm/activity/activity.component';
import { ActivityTrackComponent } from './pm/activity-track/activity-track.component';
import { InventComponent } from './invent/invent.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { SaleslookupComponent } from './saleslookup/saleslookup.component';
import { BillComponent } from './bill/bill.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PaymentsComponent } from './payments/payments.component';
import { AccountcompanyComponent } from './accountcompany/accountcompany.component';
import { BankstmtemailComponent } from './bankstmtemail/bankstmtemail.component';
import { CustmarginviewComponent } from './custmarginview/custmarginview.component';
import { SalemantrackrouteComponent } from './salemantrackroute/salemantrackroute.component';
import { EmpComponent } from './emp/emp.component';
import { EmpAttendanceComponent } from './emp-attendance/emp-attendance.component';
import { CustorderComponent } from './custorder/custorder.component';
import { EpiprnComponent } from './epiprn/epiprn.component';
import { EpiprnviewComponent } from './epiprnview/epiprnview.component';
import { NotesviewComponent } from './notesview/notesview.component';
import { MtstmtviewComponent } from './mtstmtview/mtstmtview.component';
import { AccountComponent } from './pm/account/account.component';
import { InvledgerComponent } from './invledger/invledger.component';
import { HrchitComponent } from './pm/hrchit/hrchit.component';
import { RanaComponent } from './pm/rana/rana.component';
import { Account1Component } from './pm/account1/account1.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    BillsComponent,
    CollectionComponent,
    CollectionReviewComponent,
    AssignCollectionComponent,
    CustomersComponent,
    EmployeesComponent,
    CustomerReportComponent,
    CollectionReportComponent,
    CancelbillComponent,
    PaidBillsComponent,
    AppuserComponent,
    ProfileComponent,
    ChequeReviewComponent,
    ExpenseComponent,
    BouncedChequesComponent,
    CollectionReceiveComponent,
    ViewBillsComponent,
    BankStmtComponent,
    AddCollectionComponent,
    DamageTrackComponent,
    ProdutsComponent,
    ViewcollectionComponent,
    AccountsComponent,
    AccountLedgerComponent,
    InvoiceComponent,
    ComledgerComponent,
    DocreviewComponent,
    ExtmgnxeroxComponent,
    CheckinComponent,
    AttendanceComponent,
    AttendsrcComponent,
    CustomerledgerComponent,
    CompanyComponent,
    InventoryComponent,
    CheckoutComponent,
    VehicleComponent,
    ClosingComponent,
    BankstmtreviewComponent,
    SalemantrackComponent,
    SalemantrackbaskinComponent,
    BillgrnComponent,
    BillplanComponent,
    CustomermarginComponent,
    Billplan1Component,
    OrderIntakeComponent,
    DriverviewComponent,
    AclDirective,
    TwoDigitDecimaNumberDirective,
    AclOnlyDirective,
    AclUsersDirective,
    FullviewComponent,
    ChitsComponent,
    ActivityComponent,
    ActivityTrackComponent,
    InventComponent,
    SaleslookupComponent,
    BillComponent,
    DashboardComponent,
    PaymentsComponent,
    AccountcompanyComponent,
    BankstmtemailComponent,
    CustmarginviewComponent,
    SalemantrackrouteComponent,
    EmpComponent,
    EmpAttendanceComponent,
    CustorderComponent,
    EpiprnComponent,
    EpiprnviewComponent,
    NotesviewComponent,
    MtstmtviewComponent,
    AccountComponent,
    InvledgerComponent,
    HrchitComponent,
    RanaComponent,
    Account1Component,
  ],
  imports: [
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCb4FL7rv3jrcH1TKwqSexD6ntMcAkxYy4',
    }),
    AgmDirectionModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
  ],
  providers: [
    MyMonitoringService,
    locationService,
    LoaderService,
    NgxImageCompressService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: onAppInit, multi: true, deps: [AuthenticationService] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
