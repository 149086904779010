<div class="row" *DataCustomAcl="'Admin:Employee:Driver'">
  <div class="col">
    <table class="table table-striped table-sm table-responsive table-condensed">
      <tbody *ngFor="let u of alerts">
        <tr>
          <td colspan="9" class="bg-danger" style="color: white;">
            {{u}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row">
  <div class="col mf" [copy-clipboard]="address">
    <button class="btn btn-sm btn-info" (click)="goToLink()"><i class="fa fa-map-marker"></i></button>&nbsp;<b>Rayar
      Agencies</b><br>NO.1/10, 8th Main Muthyala
    Nagar,<br>Opp Nandish Park Apartment,<br>Bengaluru-560054.
  </div>
  <div class="col mf" [copy-clipboard]="maplink"><b>Rayar Agencies Map link</b><br>Click to copy<br>{{maplink}}
  </div>
  <div class="col mf" *ngIf="currloclink" [copy-clipboard]="currloclink"><b>My Location Map Link</b><br>Click to
    copy<br>{{currloclink}}
  </div>
</div>
<div class="row" *ngFor="let vd of vehicles1">
  <div class="col">
    <table class="table table-striped table-sm table-responsive table-condensed">
      <thead class="thead-light">
        <tr>
          <th scope="col">Vehicle</th>
          <th scope="col">Date</th>
          <th scope="col">FuelIn</th>
          <th scope="col">Fuel<br>Amount</th>
          <th scope="col">Odometer</th>
          <th scope="col">Miles</th>
          <th scope="col">Mileage</th>
          <th scope="col">Route</th>
          <th scope="col">Driver</th>
          <th scope="col">Helper</th>
          <th scope="col">Bill Value<br>Deliverd/Planned/%</th>
          <th scope="col">Bill Count<br>Deliverd/Planned/%</th>
          <th scope="col">Customer Count<br>Deliverd/Planned/%</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let u of getvehicleData(vd.vehicleName)">
          <td>{{u.vehicleName}}</td>
          <td>{{u.dateValue | date: 'dd/MM/yyyy'}}</td>
          <td>{{u.fuel}} </td>
          <td>{{u.reason}} </td>
          <td>{{u.checkOut}} </td>
          <td>{{u.miles}} </td>
          <td>{{u.mileage}} </td>
          <td>{{u.resourceName}}</td>
          <td>{{u.driverName}}</td>
          <td>{{u.helperName}}</td>
          <td>{{u.billValue1}} / {{u.billValue}} / {{((u.billValue1/u.billValue)*100).toFixed(2)}}%</td>
          <td>{{u.billCount1}} / {{u.billCount}} / {{((u.billCount1/u.billCount)*100).toFixed(2)}}%</td>
          <td>{{u.customerCount1}} / {{u.customerCount}} / {{((u.customerCount1/u.customerCount)*100).toFixed(2)}}%
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>