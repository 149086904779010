<div class="card">
    <h4 class="card-header">AppSetting</h4>
    <div class="card-body">
        <div *ngIf="!isAddCustomerClicked">
            <div class="form-row">
                <div class="col-md-2">
                    <label>Name</label>
                    <input class="form-control" type="text" [(ngModel)]="searchCustomerName" />
                </div>
                <div class="col-md-2">
                    <label></label>
                    <div>
                        <button class="btn btn-outline-primary" (click)="onNameCustomerSearch()">Search</button>
                        <button class="btn btn-outline-primary" (click)="onCustSearchReset()">Reset</button>
                    </div>
                </div>
                <div class="col-md-2">
                    <label></label>
                    <div>
                        <button class="btn btn-outline-primary" (click)="oAddCustomer()">Add</button>
                        <button class="btn btn-outline-primary" (click)="doRefresh()">Refresh</button>
                    </div>
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control" [(ngModel)]="m">
                    <input type="text" class="form-control" [(ngModel)]="day">
                    <button class="btn btn-sm btn-info" (click)="downloadBills()">Search</button>&nbsp;
                </div>
            </div>
            <br>
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let u of customers">
                        <th scope="row">
                            <button class="btn btn-outline-primary" (click)="onEditCustomer(u)">Edit</button>
                        </th>
                        <td>{{u.settingName}}</td>
                        <td>{{u.settingValue}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="isAddCustomerClicked">
            <form [formGroup]="customerForm" (ngSubmit)="onAddCustSubmit()">
                <div class="form-row">
                    <div class="col-md-2">
                        <label for="settingName">Name</label>
                        <input type="text" formControlName="settingName" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.settingName.errors }" />
                        <div *ngIf="submitted && f.settingName.errors" class="invalid-feedback">
                            <div *ngIf="f.settingName.errors.required">Name is required</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label for="settingValue">Value</label>
                        <input type="text" formControlName="settingValue" class="form-control" maxlength="2000"
                            [ngClass]="{ 'is-invalid': submitted && f.settingValue.errors }" />
                        <div *ngIf="submitted && f.settingValue.errors" class="invalid-feedback">
                            <div *ngIf="f.settingValue.errors.required">Value is required</div>
                        </div>
                    </div>
                </div>
                <button type="submit" [disabled]="loading" class="btn btn-outline-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Save
                </button>
                <button class="btn btn-outline-primary" (click)="onCloseAddCust()">close</button>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>
    </div>
</div>