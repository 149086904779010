import { formatDate } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { accountLedgerModel, CustomerModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-customerledger',
  templateUrl: './customerledger.component.html',
  styleUrls: ['./customerledger.component.css']
})
export class CustomerledgerComponent implements OnInit {

  constructor(private api: RayarApiService) {
    this.searchBillModel.toDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.searchBillModel.fromDate = new Date(formatDate(new Date(new Date().setMonth(new Date().getMonth() - 2)), 'yyyy-MM-dd', 'en'));
  }

  ngOnInit(): void {
  }
  searchCustomerName: string = "";
  searchBillModel: accountLedgerModel = new accountLedgerModel();;
  loading = false;
  error = '';
  accledgers: accountLedgerModel[] = [];
  customers: CustomerModel[] = [];

  onNameCustomerSearch() {
    this.error = "";
    if (this.searchCustomerName) {
      this.accledgers = [];
      this.api.getSearchCustomer(this.searchCustomerName,'both')
        .subscribe(result => {
          var personName = result.filter(function (hero) {
            return hero.accountName == "General";
          });
          this.customers = personName;
        }, error => {
          this.error = error;
          this.loading = false; console.error(error);
        });
    }
  }

  onEditCustomer(u: CustomerModel) {
    this.error = "";
    this.searchBillModel.page = 0;
    this.searchBillModel.accountId = u.customerId;
    this.api.getCustomerLedger(this.searchBillModel).subscribe(result => {
      this.accledgers = result;
      this.error = '';
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  selectChangeHandler(event: any) {
    this.searchBillModel.fromDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.searchBillModel.toDate = event.target.value;
  }

  onCollBillSearchReset() {
    this.searchBillModel = new accountLedgerModel();
    this.searchBillModel.toDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.searchBillModel.fromDate = new Date(formatDate(new Date(new Date().setMonth(new Date().getMonth() - 2)), 'yyyy-MM-dd', 'en'));
    this.accledgers = [];
    this.searchCustomerName = "";
    this.customers = [];
    this.error = '';
  }

  downloadAccLedReport(u: CustomerModel) {
    this.error = '';
    this.searchBillModel.page = 0;
    this.searchBillModel.accountId = u.customerId;
    this.download("CustomerLedgersReport", this.searchBillModel)
  }

  public download(q: string, b: any) {
    this.api.downloadFilePost(q, b).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }
}
