import { Component, OnInit } from '@angular/core';
import { accountModel, companyModel, custmarviewModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';

@Component({
  selector: 'app-custmarginview',
  templateUrl: './custmarginview.component.html',
  styleUrls: ['./custmarginview.component.css']
})
export class CustmarginviewComponent implements OnInit {
  accounts: accountModel[] = [];
  companys: companyModel[];
  custmars: custmarviewModel[];
  margins: string[];
  error = '';
  loading = false;
  search: custmarviewModel = new custmarviewModel();

  constructor(private api: RayarApiService) {
    this.getAccounts();
    this.getcompany();
    this.search.margin = "";
  }

  ngOnInit(): void {
  }

  getAccounts() {
    this.api.getAccounts()
      .subscribe(result => { this.accounts = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  getcompany() {
    this.api.getCompanys().subscribe(result => {
      this.companys = result;
      this.search.companyName = result[0].companyName
      this.getmargins();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  selectChangeHandler3(event: any) {
    this.getmargins();
  }

  getmargins() {
    this.api.getCustMar1(this.search.companyName).subscribe(result => {
      this.margins = result;
      this.search.margin = "";
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  onSearch() {
    this.api.getCustMar(this.search).subscribe(result => {
      this.custmars = result;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }
}
