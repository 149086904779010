import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { SalesLookupModel, BillModel, billPlanModel, billsumModel, CollectionModel, companyModel, empModel, PendingBills, PendingFollowUpModel, Role, SaveBillPlanModel, TimeTrackResourceModel, User, VehicleModel, LoadSheetModel, LoadSheetData, noteTrackModel, CustomerModel } from '../models';
import { HttpEventType } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-billplan',
  templateUrl: './billplan.component.html',
  styleUrls: ['./billplan.component.css']
})
export class BillplanComponent implements OnInit {
  loading = false;
  error = '';
  accledgersAll: billPlanModel[] = [];
  customersAll: TimeTrackResourceModel[] = [];
  empsAll: empModel[] = [];
  vehiclesAll: VehicleModel[] = [];
  customers: TimeTrackResourceModel[] = [];
  customers1: TimeTrackResourceModel[] = [];
  driverGroup: string[] = [];
  billSum: billsumModel[] = [];
  tags: string[];
  companys: companyModel[];
  selectedcompany: string = '-1';
  selectedzone: string = '-1';

  selectedstock: string = '-1';
  selectedPending: string = '-1';
  selectedPending1: string = '-1';
  selecteddriverGroup: string = 'ALL';
  selecteddriver: string = '0';
  currentUser: User;
  selectedcustomerName: string = '';
  billIds: string = '';
  pendingFollowups: PendingFollowUpModel[] = [];
  pendingFollowupsAll: PendingFollowUpModel[] = [];
  pendingBills: PendingBills[] = [];
  colls: CollectionModel[] = [];
  searchBillModel1: BillModel = new BillModel();
  selectedCompany: string;
  drivers: string[] = [];

  goToLink() {
    if (this.selMap)
      window.open(this.selMap, "_blank");
  }

  showDetail(u: billPlanModel) {
    this.accledgersAll.forEach(x => {
      if (u.billId == x.billId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.selectedCompany = u.companyName;
          this.getPendingBills(u.customerName, u.customerId)
          this.getNotes(u.customerId);
          var n = new noteTrackModel();
          n.id = u.customerId;
          n.noteType = "CustNote";
          n.internal = true;
          n.customerId = x.customerId;
          n.billId = x.billId;
          this.addNotes = n;
        }
      }
      else
        x.showDetail = false;
    });
  }

  selMap: string;
  selInfor: string;
  selInfor1: string;
  seladdress: string;
  selCustName: string;
  selcustId: number;
  selhasReviw: boolean;

  MarkReviewedCust() {
    var cid = this.selcustId;
    this.api.MarkReviewedCust(this.selcustId).subscribe(result => {
      this.accledgersAll.forEach(d1 => {
        if (d1.customerId == cid) {
          d1.hasReviewed = true;
        }
      });
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  updateCust() {
    var d = new CustomerModel();
    d.customerId = this.selcustId;
    d.information3 = this.selInfor;
    d.information1 = this.selInfor1;
    d.address = this.seladdress;
    d.mapLink = this.selMap;
    this.api.UpdateCustomerBillPlan(d).subscribe(result => {
      this.accledgersAll.forEach(d1 => {
        if (d1.customerId == d.customerId) {
          d1.information1 = d.information1;
          d1.address = d.address;
          d1.information3 = d.information3;
          d1.mapLink = d.mapLink;
        }
      });
    }, error => { this.loading = false; console.error(error) });
  }

  onClickCustomername(u: billPlanModel) {
    this.selMap = u.mapLink;
    this.selInfor = u.information3;
    this.selInfor1 = u.information1;
    this.seladdress = u.address;
    this.selCustName = u.customerName;
    this.selcustId = u.customerId;
    this.selhasReviw = u.hasReviewed == null || u.hasReviewed == true;
  }

  onChangeSet(resoureName: string) {
    const index = this.drivers.findIndex(x => x === resoureName);
    if (index == -1) {
      this.drivers.push(resoureName);
      this.api.SavePlannedDrivers(true, resoureName).subscribe(() => {
        this.loading = false;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    } else {
      this.drivers.splice(index, 1);
      this.api.SavePlannedDrivers(false, resoureName).subscribe(() => {
        this.loading = false;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    }
  }

  onCheckboxChange1(e) {
    this.api.SetCashCarry(e.target.value, e.target.checked).subscribe(() => {
      this.loading = false;
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  getPlannedDrivers() {
    this.api.PlannedDrivers().subscribe(result => { this.drivers = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  isChecked(s: string) {
    const index = this.drivers.findIndex(x => x === s);
    return index >= 0;
  }

  getPendingCheques(c: number) {
    this.searchBillModel1.page = 0;
    this.searchBillModel1.personId = 0;
    this.searchBillModel1.customerId = c;
    this.api.oncollsView('chequeReviewCid', this.searchBillModel1).subscribe(result => {

      if (this.currentUser.role == Role.Company) {
        var c = this.currentUser.profileId;
        this.colls = result.filter(function (hero) {
          return hero.companyName == c;
        });
      } else {
        this.colls = result;
      }
    }, error => {
      this.error = error; this.loading = false; console.error(error)
    });
  }

  getPendingFollowup() {
    this.api.getPendingFollowup().subscribe(result => { this.pendingFollowupsAll = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  getPendingBills(cName: string, customerId: number) {
    this.pendingFollowups = this.pendingFollowupsAll.filter(function (hero) {
      return hero.customerId == customerId;
    });
    this.api.getCustomerSearch1(cName, '', 0)
      .subscribe(result => {
        if (this.currentUser.role == Role.Company) {
          var c = this.currentUser.profileId;
          this.pendingBills = result.filter(function (hero) {
            return hero.companyName == c;
          });
        } else {
          this.pendingBills = result;
        }
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
    this.getPendingCheques(customerId);
  }


  suppliedDocs: noteTrackModel[] = [];
  addNotes: noteTrackModel = new noteTrackModel();

  getNotes(i: number) {
    var d = new noteTrackModel();
    d.customerId = i;
    d.noteType = "ALL";
    d.page = 0;
    d.pageSize = 200;
    this.api.getnoteTraks(d).subscribe(result => this.suppliedDocs = result, error => {
      this.loading = false; console.error(error)
    });
  }

  saveNoteTrack1(u: billPlanModel) {
    if (this.addNotes.notes) {
      this.addNotes.removeFollowUp = false;
      this.api.saveNoteTrack(this.addNotes).subscribe(result => {
        this.getNotes(this.addNotes.id);
        this.addNotes.notes = "";
        this.addNotes.customerFolDate = null;
        this.addNotes.internal = true;
      }, error => {
        this.loading = false; console.error(error)
      });
    }
  }

  saveNoteTrack2(u: billPlanModel) {
    if (this.addNotes.customerFolDate) {
      var ff = this.addNotes.customerFolDate;
      this.addNotes.removeFollowUp = false;
      this.addNotes.notes = '';
      this.api.saveNoteTrack(this.addNotes).subscribe(result => {
        u.customerFolDate = ff;
        this.getNotes(this.addNotes.id);
        this.addNotes.notes = "";
        this.addNotes.customerFolDate = null;
        this.addNotes.internal = true;
      }, error => {
        this.loading = false; console.error(error)
      });
    }
  }

  saveNoteTrack3(u: billPlanModel) {
    this.addNotes.notes = '';
    this.addNotes.removeFollowUp = true;
    this.api.saveNoteTrack(this.addNotes).subscribe(result => {
      u.customerFolDate = null;
      this.getNotes(this.addNotes.id);
      this.addNotes.notes = "";
      this.addNotes.customerFolDate = null;
      this.addNotes.internal = true;
    }, error => {
      this.loading = false; console.error(error)
    });
  }

  constructor(private authenticationService: AuthenticationService, private api: RayarApiService) {
    this.getPendingFollowup();
    this.getResources();
    this.getcompany();
    this.getPlannedDrivers();
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
  }

  getcompany() {
    this.api.getCompanys().subscribe(result => {
      if (this.currentUser.role == Role.Company) {
        var c = this.currentUser.profileId;
        this.companys = result.filter(function (hero) {
          return hero.companyName == c;
        });
      } else {
        this.companys = result;
      }
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  getResources() {
    this.api.getResources()
      .subscribe(result => {
        this.vehiclesAll = result.vehicles.filter(function (hero) {
          return hero.category == "Vehicle";
        });
        this.empsAll = result.employees.sort((a, b) => a.employeeName.localeCompare(b.employeeName));;
        this.customersAll = result.resources.sort((a, b) => a.contactPhone.localeCompare(b.contactPhone) || a.resourceName.localeCompare(b.resourceName));;
        this.tags = result.tags;
        this.driverGroup = result.resources.map(item => item.contactPhone)
          .filter((value, index, self) => self.indexOf(value) === index);
        var sg = this.selecteddriverGroup;
        this.customers = result.resources.filter(function (hero) {
          return sg == "ALL" || hero.contactPhone == sg;
        }).sort((a, b) => a.resourceName.localeCompare(b.resourceName));
        this.customers1 = result.resources.filter(function (hero) {
          return sg == "ALL" || hero.contactPhone != sg;
        }).sort((a, b) => a.resourceName.localeCompare(b.resourceName));
        this.onCollBillSearch();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  dbVersion: string = "";
  onCollBillSearch() {
    this.api.getBillPlan().subscribe(result => {
      this.accledgersAll = result.billPlanModels;
      this.custGrp = this.findOcc(this.accledgersAll, "customerId");
      this.dbVersion = result.dbVersion;
      this.accledgersAll.forEach((currentValue, index) => {
        currentValue.customerIdcount = this.getCustomerIdCount(currentValue.customerId)
        currentValue.driverName1 = currentValue.driverName;
        currentValue.showDetail = false;
      });
      this.accledgersOriginal = [];
      this.accledgersAll.map(item => item.driverName)
        .filter((value, index, self) => self.indexOf(value) === index)
        .forEach(drivnername => {
          var dal = this.accledgersAll.filter(function (hero) {
            return hero.driverName == drivnername;
          }).sort((a, b) => a.customerName.localeCompare(b.customerName));
          if (drivnername == "-1") {
            dal.forEach((currentValue, index) => {
              currentValue.serial = 0;
            });
          }
          var ds = [];
          dal.forEach((currentValue, index) => {
            ds.push(currentValue.serial);
          });
          var max = ds.reduce((a, b) => Math.max(a, b));
          dal.forEach((currentValue, index) => {
            if (currentValue.serial == 0) {
              max = max + 1;
              currentValue.serial = max;
            }
          });
        });
      this.refresh();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  custGrp: any;
  getCustomerIdCount(c) {
    var r = this.custGrp.filter(function (hero) {
      return hero.customerId == c;
    });
    return r[0].occurrence;
  }

  findOcc(arr, key) {
    let arr2 = [];

    arr.forEach((x) => {

      // Checking if there is any object in arr2 
      // which contains the key value 
      if (arr2.some((val) => { return val[key] == x[key] })) {

        // If yes! then increase the occurrence by 1 
        arr2.forEach((k) => {
          if (k[key] === x[key]) {
            k["occurrence"]++
          }
        })

      } else {
        // If not! Then create a new object initialize  
        // it with the present iteration key's value and  
        // set the occurrence to 1 
        let a = {}
        a[key] = x[key]
        a["occurrence"] = 1
        arr2.push(a);
      }
    })

    return arr2
  }


  onBlurEvent(b: billPlanModel) {
    var d = new BillModel();
    d.billId = b.billId; d.billRemarks = b.billRemarks;
    this.api.UpdateBillRemakrs(d).subscribe(result => {
    }, error => { this.error = error; this.loading = false; console.error(error) });
  }

  selectChangeHandlertag(b: billPlanModel) {
    var d = new BillModel();
    d.billId = b.billId; d.tag = b.tag;
    this.api.UpdateBillTag(d).subscribe(result => {
    }, error => { this.error = error; this.loading = false; console.error(error) });
  }

  onKey1(event: any) {
    this.selectedcustomerName = event.target.value;
    this.refresh();
  }

  selectChangeHandlerDriver(event: any) {
    this.selecteddriver = event.target.value
    this.refresh();
  }

  selectChangeHandlerDriver1(event: any) {
    var sm = event.target.value;
    this.selecteddriverGroup = sm;
    this.customers = this.customersAll.filter(function (hero) {
      return sm == "ALL" || hero.contactPhone == sm;
    }).sort((a, b) => a.resourceName.localeCompare(b.resourceName));
    this.customers1 = this.customersAll.filter(function (hero) {
      return sm == "ALL" || hero.contactPhone != sm;
    }).sort((a, b) => a.resourceName.localeCompare(b.resourceName));
    this.selecteddriver = "0"
    this.refresh();
  }

  selectChangeHandlerCompany(event: any) {
    this.selectedcompany = event.target.value
    this.refresh();
  }

  selectChangeHandlerZone(event: any) {
    this.selectedzone = event.target.value
    this.refresh();
  }

  selectChangeHandlerStock(event: any) {
    this.selectedstock = event.target.value
    this.refresh();
  }

  selectChangeHandlerPending(event: any) {
    this.selectedPending = event.target.value
    this.refresh();
  }
  selectChangeHandlerPending1(event: any) {
    this.selectedPending1 = event.target.value
    this.refresh();
  }
  inverse(d: billsumModel) {
    this.accledgersAll.filter(function (hero) {
      return hero.driverName == d.driverName;
    }).sort((a, b) => b.serial - a.serial).forEach((x, index) => {
      x.serial = index + 1;
    })
  }

  trySort(d: billsumModel) {
    var ds = this.accledgersAll.filter(function (hero) {
      return hero.driverName == d.driverName;
    });
    this.api.getShortBillPlan(ds).subscribe(result => {
      result.forEach((currentValue, index) => {
        this.accledgersAll.filter(function (hero) {
          return hero.billId.toString() == currentValue.billId.toString();
        }).forEach((x) => {
          x.serial = currentValue.serial;
        })
      });
      this.refresh();
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  shortRouteMove() {
    if (!this.billIds || this.billIds === "") return;
    var billids = this.billIds.split(',');
    if (billids.length == 0) return;
    billids.forEach((currentValue, index) => {
      this.accledgersAll.filter(function (hero) {
        return hero.billId.toString() == currentValue;
      }).forEach((x) => {
        x.driverName = 'ShortRoute';
        x.driverName1 = 'ShortRoute';
        x.serial = index + 1;
      })
    });
    this.refresh();
  }

  selectChangeHandler3(event: any, d: billPlanModel) {
    var ds = [];
    var newDriver = event.target.value;
    this.accledgersAll.forEach((currentValue, index) => {
      if (currentValue.driverName == newDriver)
        ds.push(currentValue.serial);
    });
    var max = 0;
    if (ds.length > 0) {
      max = ds.reduce((a, b) => Math.max(a, b));
    }
    this.accledgersAll.forEach((currentValue, index) => {
      if (currentValue.billId == d.billId) {
        currentValue.driverName = newDriver;
        currentValue.driverName1 = newDriver;
        currentValue.serial = max + 1;
      }
    });
    this.refresh();
  }

  selectChangeHandler31(event: any, d: billsumModel) {
    var ds = [];
    this.accledgersAll.forEach((currentValue, index) => {
      if (currentValue.driverName == d.driverName1)
        ds.push(currentValue.serial);
    });
    var max = 0;
    if (ds.length > 0) {
      max = ds.reduce((a, b) => Math.max(a, b));
    }
    this.accledgersAll.sort((a, b) => a.driverName.localeCompare(b.driverName) || a.serial - b.serial).forEach(x => {
      if (x.driverName == d.driverName) {
        max = max + 1;
        x.serial = max;
        x.driverName = d.driverName1;
        x.driverName1 = d.driverName1;
      }
    });
    this.refresh();
    this.billSum.forEach((currentValue, index) => {
      if (currentValue.driverName == d.driverName1) {
        currentValue.resource1 = d.resource1;
        currentValue.resource2 = d.resource2;
        currentValue.vehicleName = d.vehicleName;
        currentValue.remarks = d.remarks;
      }
    });
  }


  selectChangeHandler311(event: any) {
    var newDriver = event.target.value;
    if (newDriver == "-1") return;
    var ds = [];
    this.accledgersAll.forEach((currentValue, index) => {
      if (currentValue.driverName == newDriver)
        ds.push(currentValue.serial);
    });
    var max = 0;
    if (ds.length > 0) {
      max = ds.reduce((a, b) => Math.max(a, b));
    }
    var d6 = this.selectedzone;
    var d5 = this.selectedPending;
    var d51 = this.selectedPending1;
    var d4 = this.selectedcustomerName;
    var d1 = this.selectedstock;
    var d2 = this.selectedcompany;
    var d3 = this.selecteddriver;
    this.accledgersAll.sort((a, b) => a.driverName.localeCompare(b.driverName) || a.serial - b.serial).forEach(hero => {

      if ((d1 == '-1' || (d1 == '1' && hero.inStock) || (d1 == '0' && !hero.inStock)) &&
        (d5 == '-1' || (d5 == '0' && hero.pending == 0) || (d5 == '1' && hero.pending == 1) || (d5 == '2' && hero.pending > 1)) &&
        (d51 == '-1' || (d51 == '1' && hero.customerIdcount > 1) || (d51 == '2' && hero.customerIdcount > 2) || (d51 == '3' && hero.customerIdcount > 3)) &&
        (d2 == '-1' || hero.companyName == d2) &&
        (d6 == '-1' || hero.zone == d6) &&
        (d4 == '' || (hero.billNumber != null && hero.billNumber.toLowerCase().includes(d4.toLowerCase()))
          || (hero.customerName != null && hero.customerName.toLowerCase().includes(d4.toLowerCase()))) &&
        (d3 == '0' || hero.driverName == d3)) {
        max = max + 1;
        hero.serial = max;
        hero.driverName = newDriver;
        hero.driverName1 = newDriver;
      }
    });


    this.refresh();
  }

  showlist() {
    this.isMapView = false;
  }

  loadSheetResult: SalesLookupModel[] = [];

  showLoadSheet() {
    this.isLoadSheetView = !this.isLoadSheetView;
    if (this.isLoadSheetView) {
      this.loadSheetResult = [];
      var rq = new LoadSheetModel();
      rq.loadSheetDatas = [];
      this.accledgersAll.forEach((currentValue, index) => {
        if (currentValue.groupName == "Group2") {
          if (currentValue.driverName != '-1'
            && currentValue.driverName != 'Cancel bill'
            && currentValue.driverName != 'Hold bill') {
            if (currentValue.selected) {

            } else {
              var n = new LoadSheetData();
              n.billNumber = currentValue.billNumber;
              n.companyName = currentValue.companyName;
              rq.loadSheetDatas.push(n);
            }
          }
        }
      });
      this.api.getLoadSheet(rq).subscribe(result => {
        this.loadSheetResult = result;
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
    }
  }

  lat: Number = 13.0386729;
  lng: Number = 77.5495909;
  isLoadSheetView: boolean = false;

  isMapView: boolean = false;
  directions: any = [];
  waypoints: any = [];
  showmap() {
    this.markerOptions.waypoints = [];
    this.isMapView = true;
    this.waypoints = [];
    var d3 = this.selecteddriver;
    this.directions = [];
    var dynamic = [];
    var dal = this.accledgersAll.filter(function (hero) {
      return (d3 == "0" || hero.driverName == d3);
    });
    if (dal.length > 1) {
      dynamic = [{ origin: "", destination: "" }];
      var custAdd = [];
      dal.sort((a, b) => a.driverName.localeCompare(b.driverName) || a.serial - b.serial).forEach((currentValue, index) => {
        if (custAdd.length <= 24) {
          if (custAdd.includes(currentValue.address)) {
            console.log('Dup:::' + currentValue.serial + ':::' + currentValue.customerName + ':::' + currentValue.address);
          } else {
            console.log(custAdd.length + 1 + ':::' + currentValue.serial + ':::' + currentValue.customerName + ':::' + currentValue.address);
            if (currentValue.address == '.' || currentValue.address == '..' || currentValue.address == '.....') {
              this.waypoints.push({
                location: this.lat + ',' + this.lng,
                stopover: false,
              });
            } else {
              this.waypoints.push({
                location: currentValue.address,
                stopover: false,
              });
            }
            var dal1 = dal.filter(function (hero) {
              return hero.address == currentValue.address;
            });
            var ishighval = false;
            var label1 = currentValue.customerName + '\n' + currentValue.address + '\n';

            dal1.forEach((d) => {
              if (d.billValue > 50000) {
                ishighval = true;
              }
              label1 = label1 + 'Serial::' + d.serial + ':: ' + d.companyName + '::$ ' + d.billValue + '::# ' + d.billNumber + '\n';
            });
            var colord = 'green';
            if (ishighval) {
              colord = 'yellow';
            }
            if (dal1.length > 1) {
              colord = 'orange';
            }
            if (ishighval && dal1.length > 1) {
              colord = 'blue';
            }
            this.markerOptions.waypoints.push({
              title: label1,
              icon: {
                url: 'https://maps.google.com/mapfiles/ms/icons/' + colord + '.png',
                labelOrigin: new google.maps.Point(15, 10)
              },
              label: { color: 'white', text: (currentValue.serial).toString() }
            });
            custAdd.push(currentValue.address);
          }
        }
      });
      dynamic[0].origin = custAdd[0];
      this.markerOptions.origin.icon = this.markerOptions.waypoints[0].icon.url;
      if (custAdd.length >= 25) {
        this.markerOptions.destination.label.text = "25";
        this.markerOptions.destination.icon = this.markerOptions.waypoints[25].icon.url;
        dynamic[0].destination = custAdd[24];
      } else {
        var la = this.markerOptions.waypoints[this.markerOptions.waypoints.length - 1];
        this.markerOptions.destination.icon = la.icon.url;
        this.markerOptions.destination.label.text = la.label.text;
        dynamic[0].destination = custAdd[custAdd.length - 1];
      }

      this.directions = dynamic;
    }
  }

  increment: number = 0;
  billValueFilter: number = 0;
  information3Filter: string = '';
  showmapTest() {
    this.markerOptions.waypoints = [];
    this.isMapView = true;
    this.waypoints = [];
    var d3 = this.selecteddriver;
    this.directions = [];
    var dynamic = [];
    var inc = this.increment;
    var dal = this.accledgersAll.filter(function (hero) {
      return (d3 == "0" || hero.driverName == d3);
    });
    if (dal.length > 1) {
      dynamic = [{ origin: "", destination: "" }];
      var custAdd = [];
      dal.sort((a, b) => a.driverName.localeCompare(b.driverName) || a.serial - b.serial)
        .forEach((currentValue, index) => {
          if (index <= inc)
            if (custAdd.length <= 24) {
              if (custAdd.includes(currentValue.address)) {
                console.log('Dup:::' + currentValue.serial + ':::' + currentValue.customerName + ':::' + currentValue.address);
              } else {
                console.log(custAdd.length + 1 + ':::' + currentValue.serial + ':::' + currentValue.customerName + ':::' + currentValue.address);
                if (currentValue.address == '.' || currentValue.address == '..' || currentValue.address == '.....') {
                  this.waypoints.push({
                    location: this.lat + ',' + this.lng,
                    stopover: false,
                  });
                } else {
                  this.waypoints.push({
                    location: currentValue.address,
                    stopover: false,
                  });
                }
                var dal1 = dal.filter(function (hero) {
                  return hero.address == currentValue.address;
                });
                var ishighval = false;
                var label1 = currentValue.customerName + '\n' + currentValue.address + '\n';

                dal1.forEach((d) => {
                  if (d.billValue > 50000) {
                    ishighval = true;
                  }
                  label1 = label1 + 'Serial::' + d.serial + ':: ' + d.companyName + '::$ ' + d.billValue + '::# ' + d.billNumber + '\n';
                });
                var colord = 'green';
                if (ishighval) {
                  colord = 'yellow';
                }
                if (dal1.length > 1) {
                  colord = 'orange';
                }
                if (ishighval && dal1.length > 1) {
                  colord = 'blue';
                }
                this.markerOptions.waypoints.push({
                  title: label1,
                  icon: {
                    url: 'https://maps.google.com/mapfiles/ms/icons/' + colord + '.png',
                    labelOrigin: new google.maps.Point(15, 10)
                  },
                  label: { color: 'white', text: (currentValue.serial).toString() }
                });
                custAdd.push(currentValue.address);
              }
            }
        });
      dynamic[0].origin = custAdd[0];
      this.markerOptions.origin.icon = this.markerOptions.waypoints[0].icon.url;
      if (custAdd.length >= 25) {
        this.markerOptions.destination.label.text = "25";
        this.markerOptions.destination.icon = this.markerOptions.waypoints[25].icon.url;
        dynamic[0].destination = custAdd[24];
      } else {
        var la = this.markerOptions.waypoints[this.markerOptions.waypoints.length - 1];
        this.markerOptions.destination.icon = la.icon.url;
        this.markerOptions.destination.label.text = la.label.text;
        dynamic[0].destination = custAdd[custAdd.length - 1];
      }

      this.directions = dynamic;
    }
  }

  renderOptions = { suppressMarkers: true };
  markerOptions = {
    origin: {
      icon: '',
      label: {
        color: 'rwhiteed', text: '1'
      }
    },
    destination: {
      icon: '',
      label: {
        color: 'white', text: ''
      }
    },
    waypoints: []
  };
  getData() {
    var d6 = this.selectedzone;
    var d5 = this.selectedPending;
    var d51 = this.selectedPending1;
    var d4 = this.selectedcustomerName;
    var d1 = this.selectedstock;
    var d2 = this.selectedcompany;
    var d3 = this.selecteddriver;
    var d7 = this.selecteddriverGroup;
    var d8 = this.billValueFilter;
    var d81 = this.information3Filter;

    var driverslist = this.customersAll
      .filter(function (hero) {
        return d7 == "ALL" || hero.contactPhone == d7;
      }).map(item => item.resourceName)
      .filter((value, index, self) => self.indexOf(value) === index);
    if (d51 == "-1")
      return this.accledgersAll.filter(function (hero) {
        return (d1 == '-1' || (d1 == '1' && hero.inStock) || (d1 == '0' && !hero.inStock)) &&
          (d5 == '-1' || (d5 == '0' && hero.pending == 0) || (d5 == '1' && hero.pending == 1) || (d5 == '2' && hero.pending > 1)) &&
          (d51 == '-1' || (d51 == '1' && hero.customerIdcount > 1) || (d51 == '2' && hero.customerIdcount > 2) || (d51 == '3' && hero.customerIdcount > 3)) &&
          (d2 == '-1' || hero.companyName == d2) &&
          (d6 == '-1' || hero.zone == d6) &&
          (d8 == 0 || hero.billValue >= d8) &&
          (d81 == '' || (hero.information3 != null && hero.information3.toLowerCase().includes(d81.toLowerCase()))) &&
          (d4 == '' || (hero.billNumber != null && hero.billNumber.toLowerCase().includes(d4.toLowerCase()))
            || (hero.customerName != null && hero.customerName.toLowerCase().includes(d4.toLowerCase()))) &&
          ((d3 == '0' && (hero.driverName == "-1" || driverslist.includes(hero.driverName))) || hero.driverName == d3)
      }).sort((a, b) => a.driverName.localeCompare(b.driverName) || a.serial - b.serial);
    else
      return this.accledgersAll.filter(function (hero) {
        return (d1 == '-1' || (d1 == '1' && hero.inStock) || (d1 == '0' && !hero.inStock)) &&
          (d5 == '-1' || (d5 == '0' && hero.pending == 0) || (d5 == '1' && hero.pending == 1) || (d5 == '2' && hero.pending > 1)) &&
          (d51 == '-1' || (d51 == '1' && hero.customerIdcount > 1) || (d51 == '2' && hero.customerIdcount > 2) || (d51 == '3' && hero.customerIdcount > 3)) &&
          (d2 == '-1' || hero.companyName == d2) &&
          (d6 == '-1' || hero.zone == d6) &&
          (d8 == 0 || hero.billValue >= d8) &&
          (d81 == '' || (hero.information3 != null && hero.information3.toLowerCase().includes(d81.toLowerCase()))) &&
          (d4 == '' || (hero.billNumber != null && hero.billNumber.toLowerCase().includes(d4.toLowerCase()))
            || (hero.customerName != null && hero.customerName.toLowerCase().includes(d4.toLowerCase()))) &&
          ((d3 == '0' && (hero.driverName == "-1" || driverslist.includes(hero.driverName))) || hero.driverName == d3)
      }).sort((a, b) => a.customerName.localeCompare(b.customerName));


  }

  isHoldBillCust(u: number) {
    var retVal = false;
    var d = this.accledgersAll.filter(function (f) {
      return f.customerId == u;
    }).forEach(dd => {
      if (dd.driverName == "Hold bill") {
        retVal = true;
      }
    });
    return retVal;
  }

  refresh() {
    this.accledgersAll.map(item => item.driverName)
      .filter((value, index, self) => self.indexOf(value) === index)
      .forEach(drivnername => {
        this.accledgersAll.filter(function (hero) {
          return hero.driverName == drivnername;
        }).sort((a, b) => a.serial - b.serial).forEach((currentValue, index) => {
          currentValue.serial = index + 1;
        });
      });
    this.billSum = [];
    this.accledgersAll.map(item => item.driverName)
      .filter((value, index, self) => self.indexOf(value) === index)
      .forEach(drivnername => {
        var dal = this.accledgersAll.filter(function (hero) {
          return hero.driverName == drivnername;
        });
        let count = 0;
        let total = 0;
        for (let data of dal) {
          total += data.billValue;
          count += 1;
        }
        var r1 = "-1"; var r2 = "-1"; var r3 = "-1"; var r4 = ""; var g = "None";
        var r5 = 0;
        var tr = this.customersAll.filter(function (hero) {
          return hero.resourceName == drivnername;
        });
        if (tr.length > 0) {
          if (tr[0].resource1)
            r1 = tr[0].resource1;
          if (tr[0].resource2)
            r2 = tr[0].resource2;
          if (tr[0].vehicleName)
            r3 = tr[0].vehicleName;
          if (tr[0].remarks)
            r4 = tr[0].remarks;
          if (tr[0].serial)
            r5 = tr[0].serial;
          else
            r5 = 0;
          g = tr[0].contactPhone;
        }
        var billsum = new billsumModel();
        billsum.groupName = g;
        billsum.driverName1 = drivnername;
        billsum.driverName = drivnername;
        billsum.billCount = count;
        billsum.billValue = total;
        billsum.resource1 = r1;
        billsum.resource2 = r2;
        billsum.vehicleName = r3;
        billsum.remarks = r4;
        billsum.serial = r5;
        billsum.customerCount = dal.map(item => item.customerId).filter((value, index, self) => self.indexOf(value) === index).length;
        this.billSum.push(billsum);
      });

    this.billSum.map(item => item.groupName)
      .filter((value, index, self) => self.indexOf(value) === index)
      .forEach(groupName => {
        var dal = this.billSum.filter(function (hero) {
          return hero.groupName == groupName;
        });
        var ds = [];
        dal.forEach((currentValue, index) => {
          ds.push(currentValue.serial);
        });
        var max = ds.reduce((a, b) => Math.max(a, b));
        dal.forEach((currentValue, index) => {
          if (currentValue.serial == 0) {
            max = max + 1;
            currentValue.serial = max;
          }
        });
        dal.sort((a, b) => a.serial - b.serial).forEach((currentValue, index) => {
          currentValue.serial = index + 1;

        });
      });

    this.billSum.sort((a, b) => a.groupName.localeCompare(b.groupName) || a.serial - b.serial);
    this.accledgersAll.forEach(d => {
      if (d.driverName != "-1") {
        var tr = this.customersAll.filter(function (hero) {
          return hero.resourceName == d.driverName;
        });
        d.groupName = tr[0].contactPhone;
      }
    });
    if (this.accledgersOriginal.length == 0)
      this.accledgersAll.forEach((currentValue, index) => {
        var n = new billPlanModel();
        n.billPlanId = currentValue.billPlanId;
        n.serial = currentValue.serial;
        n.driverName = currentValue.driverName;
        this.accledgersOriginal.push(n);
      });
  }

  onUpDown(up: boolean, u: billPlanModel) {
    if (up) {
      if (u.serial == 1) {
        var max = this.accledgersAll.filter(function (hero) {
          return hero.driverName == u.driverName;
        }).length;
        this.accledgersAll.filter(function (hero) {
          return hero.driverName == u.driverName;
        }).forEach((currentValue, index) => {
          currentValue.serial = currentValue.serial - 1;
        });
        u.serial = max;
      } else {
        this.accledgersAll.filter(function (hero) {
          return hero.driverName == u.driverName;
        }).forEach((currentValue, index) => {
          if (currentValue.serial == u.serial - 1) {
            currentValue.serial = currentValue.serial + 1;
          }
        });
        u.serial = u.serial - 1;
      }
    }
    else {
      var max = this.accledgersAll.filter(function (hero) {
        return hero.driverName == u.driverName;
      }).length;
      if (u.serial == max) {
        this.accledgersAll.filter(function (hero) {
          return hero.driverName == u.driverName;
        }).forEach((currentValue, index) => {
          currentValue.serial = currentValue.serial + 1;
        });
        u.serial = 1;
      } else {
        this.accledgersAll.filter(function (hero) {
          return hero.driverName == u.driverName;
        }).forEach((currentValue, index) => {
          if (currentValue.serial == u.serial + 1) {
            currentValue.serial = currentValue.serial - 1;
          }
        });
        u.serial = u.serial + 1;
      }
    }
    this.accledgersAll.sort((a, b) => a.driverName.localeCompare(b.driverName) || a.serial - b.serial);
  }

  onUpDownG(up: boolean, u: billsumModel) {
    if (up) {
      if (u.serial == 1) {
        var max = this.billSum.filter(function (hero) {
          return hero.groupName == u.groupName;
        }).length;
        this.billSum.filter(function (hero) {
          return hero.groupName == u.groupName;
        }).forEach((currentValue, index) => {
          currentValue.serial = currentValue.serial - 1;
        });
        u.serial = max;
      } else {
        this.billSum.filter(function (hero) {
          return hero.groupName == u.groupName;
        }).forEach((currentValue, index) => {
          if (currentValue.serial == u.serial - 1) {
            currentValue.serial = currentValue.serial + 1;
          }
        });
        u.serial = u.serial - 1;
      }
    }
    else {
      var max = this.billSum.filter(function (hero) {
        return hero.groupName == u.groupName;
      }).length;
      if (u.serial == max) {
        this.billSum.filter(function (hero) {
          return hero.groupName == u.groupName;
        }).forEach((currentValue, index) => {
          currentValue.serial = currentValue.serial + 1;
        });
        u.serial = 1;
      } else {
        this.billSum.filter(function (hero) {
          return hero.groupName == u.groupName;
        }).forEach((currentValue, index) => {
          if (currentValue.serial == u.serial + 1) {
            currentValue.serial = currentValue.serial - 1;
          }
        });
        u.serial = u.serial + 1;
      }
    }
    this.billSum.sort((a, b) => a.groupName.localeCompare(b.groupName) || a.serial - b.serial);
  }

  coloralertDate(u: billPlanModel) {
    if (u.prevBillDate && u.billDate)
      return Math.floor((new Date(u.billDate).getTime() - new Date(u.prevBillDate).getTime()) / 1000 / 60 / 60 / 24) <= 4;
    return false;
  }

  coloralerPOtDate(u: billPlanModel) {
    if (u.poExpDate)
      return Math.floor((new Date(u.poExpDate).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24) < 0;
    return false;
  }

  getDays(u: billPlanModel) {
    if (u.prevBillDate && u.billDate)
      return Math.floor((new Date(u.billDate).getTime() - new Date(u.prevBillDate).getTime()) / 1000 / 60 / 60 / 24);
    return 0;
  }

  coloralertM(u: billPlanModel) {
    if (u.margin && u.configMargin)
      return u.margin.toLocaleLowerCase() != u.configMargin.toLocaleLowerCase();
    return false;
  }

  color(u: billPlanModel) {
    return this.accledgersAll.map(item => item.driverName)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.localeCompare(b))
      .indexOf(u.driverName) % 2 == 0;
  }

  colorV(u: billsumModel) {
    if (u.driverName == "-1") return false;
    if (u.vehicleName == "-1") return false;
    return this.billSum.filter(function (hero) {
      return hero.vehicleName == u.vehicleName;
    }).length > 1;
  }

  colorD(u: billsumModel) {
    if (u.driverName == "-1") return false;
    if (u.resource1 == "-1") return false;
    var r1 = this.billSum.filter(function (hero) {
      return hero.resource1 == u.resource1;
    }).length;
    var r2 = this.billSum.filter(function (hero) {
      return hero.resource2 == u.resource1;
    }).length;
    return r1 > 1 || r2 > 1 || (r1 > 0 && r2 > 0);
  }

  colorH(u: billsumModel) {
    if (u.driverName == "-1") return false;
    if (u.resource2 == "-1") return false;
    var r1 = this.billSum.filter(function (hero) {
      return hero.resource1 == u.resource2;
    }).length;
    var r2 = this.billSum.filter(function (hero) {
      return hero.resource2 == u.resource2;
    }).length;
    return r1 > 1 || r2 > 1 || (r1 > 0 && r2 > 0);
  }
  accledgersOriginal: billPlanModel[] = [];
  saveData: billPlanModel[] = [];

  saveGrn() {
    var d = new SaveBillPlanModel();
    this.accledgersOriginal.sort((a, b) => a.billPlanId - b.billPlanId)
      .forEach(x => {
        var d = this.accledgersAll.filter(function (h) {
          return h.billPlanId == x.billPlanId;
        });
        if (d.length == 1) {
          if (d[0].serial != x.serial || d[0].driverName != x.driverName) {
            var ot = new billPlanModel();
            ot.billPlanId = x.billPlanId;
            ot.serial = d[0].serial;
            ot.driverName = d[0].driverName;
            this.saveData.push(ot);
            console.log(x.billPlanId.toString()
              + ": M/O Serial:" + d[0].serial
              + "/" + x.serial
              + ": driver:" + d[0].driverName
              + "/" + x.driverName
            );
          } else {
            console.log(x.billPlanId.toString() + " Same");
          }
        } else {
          console.log(x.billPlanId.toString() + " not found");
        }
      });
    d.billPlanModels = this.saveData;
    d.billsumModels = this.billSum;
    d.dbVersion = this.dbVersion;
    this.api.SaveBillPlan(d).subscribe(result => {
      if (result.dbVersion == "Success") {
        this.getResources();
      } else {
        if (confirm("Not Saved-Version Conflict,Plesae Refresh Page")) {
        }
        this.dbVersion = "Not Saved, Please refresh;UI:" + this.dbVersion + "DBVersion:" + result.dbVersion;
      }
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  download() {
    var q = 'BillPlanDownload';
    this.api.downloadFilePost(q, null).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }

  calculateDiff(date: string) {
    return Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000 / 60 / 60 / 24);
  }
}
