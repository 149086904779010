import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { CollectonPersonModel, companyModel, Role, userModel } from '../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css']
})
export class EmployeesComponent implements OnInit {
  companys: companyModel[] = [];
  customers: CollectonPersonModel[] = [];
  appusers: userModel[] = [];
  loading = false;
  isAddCustomerClicked: boolean = false;
  error = '';
  submitted = false;
  customerForm: FormGroup;
  get f() { return this.customerForm.controls; }
  searchCustomerName: string = "";
  addEditCustModel: CollectonPersonModel = new CollectonPersonModel();

  constructor(private formBuilder: FormBuilder, private api: RayarApiService, private api1: AuthenticationService) {
    this.getcompany();
    this.GetAppUsers();;
    this.onNameCustomerSearch();
  }

  getcompany() {
    this.api.getCompanys().subscribe(result => { this.companys = result; }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      emailId1: [''],
      emailId2: [''],
      appUserId: [''],
      contactPhone: ['', Validators.required],
      managerId: ['', Validators.required],
      name: ['', Validators.required]
    });
  }

  onCheckboxChange1(event: any) {
    var cid = event.target.value;
    this.companys.forEach(x => {
      if (x.companyName == cid) {
        if (event.target.checked) {
          x.showDetail = true;
        } else {
          x.showDetail = false;
        }
      }
    });
  }

  onEditCustomer(u: CollectonPersonModel) {
    this.companys.forEach(x => {
      x.showDetail = false;
    });
    this.customers.forEach(x => {
      if (u.personId == x.personId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          if (u.companys) {
            var cs = u.companys.split(',');
            this.companys.forEach(x => {
              x.showDetail = false;
              if (cs.includes(x.companyName)) {
                x.showDetail = true;
              }
            });
          }
          this.isAddCustomerClicked = false;
          this.customerForm.setValue({
            appUserId: u.appUserId,
            emailId1: u.emailId1,
            emailId2: u.emailId2,
            contactPhone: u.contactPhone,
            managerId: u.managerId,
            name: u.name,
          });
          this.addEditCustModel = u
        }
      }
      else
        x.showDetail = false;
    });
  }

  onCreateSalesLogin(cp: CollectonPersonModel) {
    if (cp.userName == "zzzzz") {
      this.error = '';
      this.submitted = true;
      this.loading = true;
      var u = new userModel();
      u.userName = cp.name;
      u.firstName = cp.name;
      u.lastName = cp.name;
      u.role = Role.Sales;
      u.profileId = cp.personId.toString();
      u.appName = 'Rayar';
      this.api1.AddEditUser(u)
        .subscribe(result => {
          this.submitted = false;
          this.loading = false;
          this.isAddCustomerClicked = false;
          this.onNameCustomerSearch();
        }, error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
    }
  }

  onAddCustSubmit() {
    this.submitted = true;
    if (this.customerForm.invalid) return;
    this.loading = true;
    this.addEditCustModel.appUserId = parseInt(this.f.appUserId.value);
    this.addEditCustModel.name = this.f.name.value;
    this.addEditCustModel.emailId2 = this.f.emailId2.value;
    this.addEditCustModel.emailId1 = this.f.emailId1.value;
    this.addEditCustModel.contactPhone = this.f.contactPhone.value;
    this.addEditCustModel.managerId = parseInt(this.f.managerId.value);
    var cs = "";
    this.companys.forEach(x => {
      if (x.showDetail) {
        cs = cs + x.companyName + ","
      }
    });
    this.addEditCustModel.companys = cs;

    this.api.AddEditCollectionPerson(this.addEditCustModel)
      .subscribe(() => {
        this.submitted = false;
        this.loading = false;
        this.onCustSearchReset();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onCloseAddCust() {
    this.isAddCustomerClicked = false;
  }

  getManagerName(managerId: number) {
    if (managerId <= 0) return "Sales";
    var personName = this.customers.filter(function (hero) {
      return hero.personId == managerId;
    });
    return personName[0].name;
  }

  getEmployeeName(appuserId: number) {
    if (appuserId <= 0) return "NoEmp";
    var personName = this.appusers.filter(function (hero) {
      return hero.id == appuserId;
    });
    return personName[0].userName;
  }
  searchAppName: string = "Rayar";

  GetAppUsers() {
    this.api1.getSearchUsers(null, this.searchAppName)
      .subscribe(result => {
        this.appusers = result.filter(function (hero) {
          return hero.role == 'Admin' || hero.role == 'Employee';
        }).sort((a, b) => a.role.localeCompare(b.role) || a.userName.localeCompare(b.userName));
      }, error => {
        console.error(error);
        this.error = error;
        this.loading = false;
      });
  }

  oAddCustomer() {
    this.isAddCustomerClicked = true;
    this.companys.forEach(x => { x.showDetail = false; });
    this.customers.forEach(x => { x.showDetail = false; });
    this.customerForm.setValue({
      appUserId: 0,
      emailId1: '',
      emailId2: '',
      contactPhone: '',
      managerId: 0,
      name: ''
    });
    this.addEditCustModel = new CollectonPersonModel();
    this.addEditCustModel.personId = 0;
  }

  onNameCustomerSearch() {
    this.isAddCustomerClicked = false;
    this.api.getSearchCollPeson(this.searchCustomerName)
      .subscribe(result => {
        result.forEach(x => { x.showDetail = false; if (!x.userName) x.userName = "zzzzz"; });
        this.customers = result.sort((a, b) => a.userName.localeCompare(b.userName) || a.name.localeCompare(b.name));
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  onCustSearchReset() {
    this.isAddCustomerClicked = false;
    this.searchCustomerName = "";
    this.onNameCustomerSearch();
  }

}

