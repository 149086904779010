import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { User, Role, BankStmtModel, CollectionModel, accountModel, BillModel, BankCollectionModel, PendingBills, BankCollection1Model, BillDetail, accountLedgerModel, DocumentModel } from '../models';
import { RayarApiService } from '../_services/rayar-api.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { AuthenticationService } from '../_services/authentication.service';
import { HttpEventType } from '@angular/common/http';
import { jsPDF } from 'jspdf';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-bankstmtreview',
  templateUrl: './bankstmtreview.component.html',
  styleUrls: ['./bankstmtreview.component.css']
})
export class BankstmtreviewComponent implements OnInit {
  @ViewChild('inputFile') inputFile: ElementRef;
  @ViewChild('inputFile2') inputFile2: ElementRef;

  loading = false;
  bankstmts: BankStmtModel[] = [];
  billModel = new BankStmtModel();
  error = '';
  billAddEditForm: FormGroup;
  get f() { return this.billAddEditForm.controls; }
  submitted = false;
  currentUser: User;
  persons: accountModel[] = [];
  accountId: number = 0;
  searchBillModel: BillModel = new BillModel();
  colls1: CollectionModel[] = [];
  colls: CollectionModel[] = [];
  pagenumber = 0;
  cl: BankCollectionModel = new BankCollectionModel();
  cl1: BankCollection1Model = new BankCollection1Model();
  bouncedRadio: string = "No";
  colAmount: number = 0;
  srcCustomerName: string = "";
  srcBillNumber: string = "";
  pendingBills: PendingBills[] = [];
  mtPndingBills: PendingBills[] = [];
  mtPndingBillsPost: PendingBills[] = [];

  description: string = '';
  collectionIds: number[] = [];
  viewbills: BillModel[] = [];
  viewbillsAll: BillModel[] = [];
  searchBillModel1: BillModel = new BillModel();
  showCancel: boolean = false;
  vwbillcolls: CollectionModel[] = [];

  constructor(private imageCompress: NgxImageCompressService, private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder, private api: RayarApiService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getAccounts();
    this.getBills(0);
  }

  ngOnInit() {
    this.billAddEditForm = this.formBuilder.group({
      remarks: ['', Validators.required],
    });
  }
  
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Enter") {
      this.getBills(this.pagenumber);
    }
  }

  selectChangeHandler31(event: any) {
    this.getBills(0);
  }

  onpage(next: boolean) {
    if (next) {
      this.getBills(this.pagenumber + 1);
    }
    else {
      if (this.pagenumber >= 1)
        this.getBills(this.pagenumber - 1);
    }
  }
  pagenumber1 = 1;
  onGoto() {
    if (this.pagenumber1 > 0) {
      this.getBills(this.pagenumber1 - 1);
    }
  }

  getBills(p: number) {
    this.mtPndingBillsPost = [];
    this.accountId = 0;
    this.searchBillModel.companyName = "";
    this.bouncedRadio = "No";
    this.collectionIds = [];
    this.colls1 = [];
    this.colAmount = 0;
    this.pagenumber = p;
    this.error = '';
    this.mtPndingBills = [];
    this.pendingBills = [];
    this.api.getPendRevBankStmts1(p, this.selSource, this.selSearch)
      .subscribe(result => {
        if (result.length > 0) {
          this.billModel = result[0];
          this.billAddEditForm.setValue({
            remarks: this.billModel.remarks,
          });
          this.api.getCollsSearch(this.billModel.id.toString(), 'GetCollsByBkId')
            .subscribe(result => { this.colls = result; }, error => console.error(error));
        }
        else {
          this.billModel = new BankStmtModel();
          this.billModel.id == 0;
        }
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  selectChangeHandler1(event: any) {
    var cid = parseInt(event.target.value);
    if (event.target.checked) {
      this.collectionIds.push(cid);
    }
    else {
      for (var i = 0; i < this.collectionIds.length; i++) {
        if (this.collectionIds[i] === cid) {
          this.collectionIds.splice(i, 1);
        }
      }
    }
  }

  selectChangeHandlercancel(event: any) {
    if (this.showCancel) {
      this.viewbills = this.viewbillsAll;
    }
    else {
      this.viewbills = this.viewbillsAll.filter(function (hero) {
        return hero.billStatus != 'CANCEL';
      })
    }
  }


  autoReview() {
    this.error = "";
    this.api.onAutoReview(this.selSource).subscribe(result => {
      this.error = "AutoReviewed statements Sucessfully";
    }, error => {
      this.error = error; this.loading = false; console.error(error)
    });
  }

  onCollectionLink() {
    this.accountId = 0;
    if (this.collectionIds.length <= 0) return;
    this.cl = new BankCollectionModel();
    if (this.bouncedRadio == 'Yes') {
      this.cl.bounced = true;
    } else {
      this.cl.bounced = false;
    }
    this.cl.collectionId = this.collectionIds;
    this.cl.id = this.billModel.id;
    this.api.oncollectionBank(this.cl).subscribe(result => {
      this.getBills(this.pagenumber);
      this.bouncedRadio = "No";
      this.collectionIds = [];
      this.colls1 = [];
      this.colAmount = 0;
      this.pendingBills = [];
      this.searchBillModel.companyName = "";
    }, error => {
      this.error = error; this.loading = false; console.error(error)
    });
  }

  getbankStmts() {
    this.error = '';
    this.api.getBankStmts("bankStmts", formatDate(new Date(new Date().setMonth(new Date().getMonth() - 12)), 'yyyy-MM-dd', 'en'),
      formatDate(new Date(new Date().setMonth(new Date().getMonth() + 1)), 'yyyy-MM-dd', 'en'), "2", "2", this.description, "", "", 0)
      .subscribe(result => { this.bankstmts = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  getViewBills() {
    this.vwbillcolls = [];
    this.searchBillModel1.page = 0;
    this.api.onBillSearch('ViewBillsAll', this.searchBillModel1).subscribe(result => {
      this.viewbillsAll = result;
      if (this.showCancel) {
        this.viewbills = this.viewbillsAll;
      }
      else {
        this.viewbills = this.viewbillsAll.filter(function (hero) {
          return hero.billStatus != 'CANCEL';
        })
      }
    }, error => {
      this.error = error;
      this.loading = false;
      console.error(error);
    });
  }

  viewColls(u: BillModel) {
    this.api.getCollsSearch(u.billId.toString(), 'GetColls').subscribe(result => { this.vwbillcolls = result; },
      error => console.error(error));
  }

  getPendingBills() {
    this.pendingBills = [];
    this.api.getCustomerSearch(this.srcCustomerName, this.srcBillNumber, 0, '')
      .subscribe(result => {
        this.pendingBills = result;
        this.pendingBills.forEach(x => {
          x.billValue1 = 0;
          x.discount = 0;
          x.shortage = 0;
          x.displayDiscount = 0;
          x.claimDocCount = 0;
        });
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  srcMtBills: string = '';

  getData() {
    var d4 = this.srcMtBills;
    return this.mtPndingBillsPost.filter(function (hero) {
      return (d4 == '' || (hero.billNumber != null && hero.billNumber.toLowerCase().includes(d4.toLowerCase()))
        || (hero.customerName != null && hero.customerName.toLowerCase().includes(d4.toLowerCase())))
    });
  }

  selectmtbill(u: PendingBills) {
    var f = this.mtPndingBills.filter(function (d) {
      return d.billId == u.billId;
    });
    if (f.length == 0)
      this.mtPndingBills.push(u);
  }

  removemtbill(u: PendingBills) {
    this.mtPndingBills.forEach(function (hero, index, object) {
      if (hero.billId == u.billId) {
        object.splice(index, 1);
      }
    });
  }

  getPendingBillsMT() {
    this.mtPndingBills = [];
    this.mtPndingBillsPost = [];
    this.api.getCustomerSearchMT(this.accountId).subscribe(result => {
      this.mtPndingBillsPost = result;
      this.mtPndingBillsPost.forEach(x => {
        x.billValue1 = 0;
        x.discount = 0;
        x.shortage = 0;
        x.displayDiscount = 0;
        x.claimDocCount = 0;
      });
    }, error => {
      this.error = error;
      this.loading = false; console.error(error);
    });
  }

  onBillCollectionLink1() {
    var t: number = 0;
    var cd: number = 0;
    var s: number = 0;
    var dc: number = 0;
    var isdocMissing = false;
    this.mtPndingBills.forEach(x => {
      if (x.billValue1 > 0) {
        t = t + x.billValue1;
      }
      if (x.discount > 0) {
        cd = cd + x.discount;
      }
      if (x.shortage > 0) {
        s = s + x.shortage;
      }
      if (x.displayDiscount > 0) {
        dc = dc + x.displayDiscount;
        if (x.claimDocCount <= 0) {
          isdocMissing = true;
        }
      }
    });
    t = Math.round(t * 100) / 100;
    cd = Math.round(cd * 100) / 100;
    s = Math.round(s * 100) / 100;
    dc = Math.round(dc * 100) / 100;

    if (this.billModel.transactionAmount != Math.round((t - cd - s - dc) * 100) / 100) return;
    if (isdocMissing) return;
    this.cl1 = new BankCollection1Model();
    this.cl1.id = this.billModel.id;
    this.cl1.billDetail = [];
    this.cl1.accountId = this.accountId;
    this.mtPndingBills.forEach(x => {
      if (x.billValue1 > 0) {
        var d = new BillDetail();
        d.billId = x.billId;
        d.collectionValue = x.billValue1;
        d.discountValue = x.discount;
        d.shortageValue = x.shortage;
        d.displayDiscountValue = x.displayDiscount;
        this.cl1.billDetail.push(d);
      } else {
        if (x.discount > 0) {
          var d = new BillDetail();
          d.billId = x.billId;
          d.collectionValue = x.billValue1;
          d.discountValue = x.discount;
          d.shortageValue = x.shortage;
          d.displayDiscountValue = x.displayDiscount;
          this.cl1.billDetail.push(d);
        } else {
          if (x.shortage > 0) {
            var d = new BillDetail();
            d.billId = x.billId;
            d.collectionValue = x.billValue1;
            d.discountValue = x.discount;
            d.shortageValue = x.shortage;
            d.displayDiscountValue = x.displayDiscount;
            this.cl1.billDetail.push(d);
          } else {
            if (x.displayDiscount > 0) {
              var d = new BillDetail();
              d.billId = x.billId;
              d.collectionValue = x.billValue1;
              d.discountValue = x.discount;
              d.shortageValue = x.shortage;
              d.displayDiscountValue = x.displayDiscount;
              this.cl1.billDetail.push(d);
            }
          }
        }
      }
    });
    this.api.oncollectionBank2(this.cl1).subscribe(result => {
      this.getBills(this.pagenumber);
      this.bouncedRadio = "No";
      this.collectionIds = [];
      this.pendingBills = [];
      this.mtPndingBills = [];
      this.colls1 = [];
      this.colAmount = 0;
      this.searchBillModel.companyName = "";
    }, error => {
      this.error = error; this.loading = false; console.error(error)
    });
  }


  onBillCollectionLink() {
    this.accountId = 0;
    var d: number[] = [];
    var t: number = 0;
    var isdocMissing = false;
    this.pendingBills.forEach(x => {
      if (x.billValue1 > 0) {
        d.push(x.customerId);
        t = t + x.billValue1;
      }
      if (x.displayDiscount > 0) {
        if (x.claimDocCount <= 0) {
          isdocMissing = true;
        }
      }
    });
    t = Math.round(t * 100) / 100;
    if (this.billModel.transactionAmount != t) return;
    if (d.map(item => item).filter((value, index, self) => self.indexOf(value) === index).length > 1) return;
    if (isdocMissing) return;
    this.cl1 = new BankCollection1Model();
    this.cl1.id = this.billModel.id;
    this.cl1.billDetail = [];
    this.pendingBills.forEach(x => {
      if (x.billValue1 > 0) {
        var d = new BillDetail();
        d.billId = x.billId;
        d.collectionValue = x.billValue1;
        d.discountValue = x.discount;
        d.displayDiscountValue = x.displayDiscount;
        this.cl1.billDetail.push(d);
      }
    });
    this.api.oncollectionBank1(this.cl1).subscribe(result => {
      this.getBills(this.pagenumber);
      this.bouncedRadio = "No";
      this.collectionIds = [];
      this.pendingBills = [];
      this.colls1 = [];
      this.colAmount = 0;
      this.searchBillModel.companyName = "";
    }, error => {
      this.error = error; this.loading = false; console.error(error)
    });
  }

  coloralertS(u: PendingBills) {
    if (u.billValue1 > 0 && u.billValue - u.billValue1 != 0) {
      return true;
    }
    return false;
  }

  color(u: PendingBills) {
    var d: number[] = [];
    this.pendingBills.forEach(x => {
      if (x.billValue1 > 0) {
        d.push(x.customerId);
      }
    });
    var selCu = d.map(item => item).filter((value, index, self) => self.indexOf(value) === index);
    return selCu.length > 1 && selCu.includes(u.customerId) && u.billValue1 > 0;
  }

  gettotal() {
    var t = 0;
    this.pendingBills.forEach(x => {
      if (x.billValue1 && x.billValue1 > 0) {
        t = t + Number(x.billValue1);
      }
    });
    return Math.round(t * 100) / 100;
  }

  getdiscount() {
    var t = 0;
    this.pendingBills.forEach(x => {
      if (x.discount && x.discount > 0) {
        t = t + Number(x.discount);
      }
    });
    return Math.round(t * 100) / 100;
  }

  getcdiscount() {
    var t = 0;
    this.pendingBills.forEach(x => {
      if (x.displayDiscount && x.displayDiscount > 0) {
        t = t + Number(x.displayDiscount);
      }
    });
    return Math.round(t * 100) / 100;
  }

  gettotal1() {
    var t = 0;
    this.mtPndingBills.forEach(x => {
      if (x.billValue1 && x.billValue1 > 0) {
        t = t + Number(x.billValue1);
      }
    });
    return Math.round(t * 100) / 100;
  }

  getdiscount1() {
    var t = 0;
    this.mtPndingBills.forEach(x => {
      if (x.discount && x.discount > 0) {
        t = t + Number(x.discount);
      }
    });
    return Math.round(t * 100) / 100;
  }

  getdiscount12() {
    var t = 0;
    this.mtPndingBills.forEach(x => {
      if (x.shortage && x.shortage > 0) {
        t = t + Number(x.shortage);
      }
    });
    return Math.round(t * 100) / 100;
  }

  getdiscount121() {
    var t = 0;
    this.mtPndingBills.forEach(x => {
      if (x.displayDiscount && x.displayDiscount > 0) {
        t = t + Number(x.displayDiscount);
      }
    });
    return Math.round(t * 100) / 100;
  }

  getdiscount123() {
    var t = 0;
    var t1 = 0;
    var t2 = 0;
    var t3 = 0;

    this.mtPndingBills.forEach(x => {
      if (x.billValue1 && x.billValue1 > 0) {
        t = t + Number(x.billValue1);
      }
      if (x.discount && x.discount > 0) {
        t1 = t1 + Number(x.discount);
      }
      if (x.shortage && x.shortage > 0) {
        t2 = t2 + Number(x.shortage);
      }
      if (x.displayDiscount && x.displayDiscount > 0) {
        t3 = t3 + Number(x.displayDiscount);
      }
    });
    return Math.round((t - t1 - t2 - t3) * 100) / 100;
  }

  onSearchPerson() {
    this.accountId = 0;
    this.searchBillModel.page = 0;
    if (this.searchBillModel.companyName)
      this.searchBillModel.companyName = this.searchBillModel.companyName.toString();
    this.api.oncollsView('chequeReview', this.searchBillModel).subscribe(result => {
      this.colls1 = result;
      this.colAmount = 0;
      this.collectionIds = [];
      this.colls1.forEach(x => {
        this.colAmount = this.colAmount + x.collectionValue;
      });
    }, error => {
      this.error = error; this.loading = false; console.error(error)
    });
  }

  selectChangeHandler3(event: any) {
    this.accountId = parseInt(event.target.value);
    this.getPendingBillsMT();
  }

  getAccounts() {
    this.api.getAccounts().subscribe(result => {
      this.persons = result;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  postCollection() {
    this.error = '';
    if (this.accountId <= 0) return;
    this.submitted = true;
    this.loading = true;
    this.api.postCollection(this.accountId, this.billModel.id).subscribe(() => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.getBills(this.pagenumber);
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  public upload(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      var s = ""; ''
      if (this.selFormat == "Format1") {
        s = 'UploadBkStmtO/' + this.selSource;
      }
      if (this.selFormat == "Format2") {
        s = 'UploadBkStmt/' + this.selSource;
      }
      this.api.uploadFile(file, s).subscribe(
        data => {
          if (data) {
            switch (data.type) {
              case HttpEventType.UploadProgress:
                break;
              case HttpEventType.Response:
                this.inputFile.nativeElement.value = '';
                this.error = "Uploaded Sucessfully";
                this.getBills(this.pagenumber);
                break;
            }
          }
        },
        error => {
          this.inputFile.nativeElement.value = '';
        }
      );
    }
  }

  get isAdmin() {
    return this.currentUser && this.currentUser.role === Role.Admin;
  }

  onBillSubmit() {
    this.error = '';
    this.submitted = true;
    if (this.billAddEditForm.invalid) return;
    this.loading = true;
    this.billModel.remarks = this.f.remarks.value;
    this.api.AddBankStmt(this.billModel).subscribe(() => {
      this.error = '';
      this.submitted = false;
      this.loading = false;
      this.getBills(this.pagenumber);
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  markBankStmtRev(u: BankStmtModel) {
    this.error = '';
    if (!u.remarks) {
      this.error = "Remarks is Required";
      return;
    }
    this.api.markBankStmtRev(u).subscribe(() => {
      this.getBills(this.pagenumber);
    }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  markBankStmtRevWithRemarks() {
    this.error = '';
    this.submitted = true;
    if (this.billAddEditForm.invalid) return;
    this.loading = true;
    if (confirm("Are you sure to Mark Reviewed")) {
      this.billModel.remarks = this.f.remarks.value;
      this.api.markBankStmtRev(this.billModel).subscribe(() => {
        this.error = '';
        this.submitted = false;
        this.loading = false;
        this.getBills(this.pagenumber);
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
    }
  }

  onBlurEvent(b: PendingBills) {
    var d = new accountLedgerModel();
    d.id = b.billId; d.remarks = b.remarks;
    this.api.SaveRemakrs(d).subscribe(result => {
    }, error => { this.error = error; this.loading = false; console.error(error) });
  }

  upload1(event, u: PendingBills) {
    this.error = "";
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          var imageSrc = reader.result as string;
          this.imageCompress.compressFile(imageSrc, 50, 100).then((compressedImage) => {
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            this.api.uploadToBlob(doc.output('blob'), "UploadBill/" + u.billId + "/" + u.billNumber + "/Claim").subscribe(
              data => {
                if (data) {
                  switch (data.type) {
                    case HttpEventType.UploadProgress:
                      break;
                    case HttpEventType.Response:
                      this.inputFile2.nativeElement.value = '';
                      this.api.getDcouments(u.billId, "Claim")
                        .subscribe(result => {
                          u.claimDocCount = result.length;
                        }, error => {
                          this.error = error;
                          this.loading = false; console.error(error)
                        });
                      break;
                  }
                }
              },
              error => {
                this.inputFile2.nativeElement.value = '';
              }
            );
          });
        }
        else {
          if (file.type.startsWith("application/pdf")) {
            this.api.uploadToBlob(file, "UploadBill/" + u.billId + "/" + u.billNumber + "/Claim").subscribe(
              data => {
                if (data) {
                  switch (data.type) {
                    case HttpEventType.UploadProgress:
                      break;
                    case HttpEventType.Response:
                      this.inputFile2.nativeElement.value = '';
                      this.api.getDcouments(u.billId, "Claim")
                        .subscribe(result => {
                          u.claimDocCount = result.length;
                        }, error => {
                          this.error = error;
                          this.loading = false; console.error(error)
                        });
                      break;
                  }
                }
              },
              error => {
                this.inputFile2.nativeElement.value = '';
              }
            );
          } else {
            this.error = "Invalid File format images/PDF only"
            return;
          }
        }
      };
    }
  }

  selFormat: string = "Format1";
  selSource: string = "Source1";
  selSearch: string = "";

  @ViewChild('inputFile21') inputFile21: ElementRef;

  upload12(event, u: PendingBills) {
    this.error = "";
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          var imageSrc = reader.result as string;
          this.imageCompress.compressFile(imageSrc, 50, 100).then((compressedImage) => {
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            this.api.uploadToBlob(doc.output('blob'), "UploadBill/" + u.billId + "/" + u.billNumber + "/Claim").subscribe(
              data => {
                if (data) {
                  switch (data.type) {
                    case HttpEventType.UploadProgress:
                      break;
                    case HttpEventType.Response:
                      this.inputFile21.nativeElement.value = '';
                      this.api.getDcouments(u.billId, "Claim")
                        .subscribe(result => {
                          u.claimDocCount = result.length;
                        }, error => {
                          this.error = error;
                          this.loading = false; console.error(error)
                        });
                      break;
                  }
                }
              },
              error => {
                this.inputFile21.nativeElement.value = '';
              }
            );
          });
        }
        else {
          if (file.type.startsWith("application/pdf")) {
            this.api.uploadToBlob(file, "UploadBill/" + u.billId + "/" + u.billNumber + "/Claim").subscribe(
              data => {
                if (data) {
                  switch (data.type) {
                    case HttpEventType.UploadProgress:
                      break;
                    case HttpEventType.Response:
                      this.inputFile21.nativeElement.value = '';
                      this.api.getDcouments(u.billId, "Claim")
                        .subscribe(result => {
                          u.claimDocCount = result.length;
                        }, error => {
                          this.error = error;
                          this.loading = false; console.error(error)
                        });
                      break;
                  }
                }
              },
              error => {
                this.inputFile21.nativeElement.value = '';
              }
            );
          } else {
            this.error = "Invalid File format images/PDF only"
            return;
          }
        }
      };
    }
  }

}
