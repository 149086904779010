<div class="row">
    <div class="col">
        <div class="row">
            <div class="col-md-2">
                <label for="From">From</label>
                <input type="date" [ngModel]="selectedDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandlerFrom($event)" class="form-control" />
            </div>
            <div class="col-md-2">
                <label for="From">To</label>
                <input type="date" [ngModel]="selectedDate2 | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandlerTo($event)" class="form-control" />
            </div>
            <div class="col-md-2"> <label>&nbsp;</label><br>
                <a class="btn btn-outline-primary" (click)="Next()">Search</a>
            </div>
            <div class="col-md-2"> <label>&nbsp;</label><br>
                <a class="btn btn-outline-primary" (click)="download()">Download</a>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-3 col-lg-2">
                <label>Customer/Bill</label>
                <input type="text" class="form-control  form-control-sm" [(ngModel)]="selectedSearch" />
            </div>
            <div class="col-md-3 col-lg-2">
                <label>NoteType</label><Br>
                <input type="radio" name="revRadio1" value="ALL" [(ngModel)]="selectednoteType" />
                All
                <input type="radio" name="revRadio1" value="CustNote" [(ngModel)]="selectednoteType" />
                CustNote
                <input type="radio" name="revRadio1" value="BncChFlp" [(ngModel)]="selectednoteType" />
                BncChFlp
                <input type="radio" name="revRadio1" value="CustUpdate" [(ngModel)]="selectednoteType" />
                CustUpdate
                <input type="radio" name="revRadio1" value="DelyUpdate" [(ngModel)]="selectednoteType" />
                DelyUpdate
                <input type="radio" name="revRadio1" value="BillCancel" [(ngModel)]="selectednoteType" />
                BillCancel
            </div>
        </div><br>
        <div class="row">
            <div class="col-md-12">
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">User</th>
                            <th scope="col">Date</th>
                            <th scope="col">CustomerName</th>
                            <th scope="col">Bill#</th>
                            <th scope="col">BillValue</th>
                            <th scope="col">NoteType</th>
                            <th scope="col">Person</th>
                            <th scope="col">Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let u of getData()">
                            <td>{{u.userName}}</td>
                            <td>{{u.enteredDate| date: 'dd/MM/yy hh:mm a'}}</td>
                            <td>{{u.customerName}}</td>
                            <td>{{u.billNumber}}</td>
                            <td>{{u.billValue}}</td>
                            <td>{{u.noteType}}</td>
                            <td>{{u.personName}}</td>
                            <td>{{u.notes}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>