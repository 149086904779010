<div class="card">
    <div class="card-body">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-md-2">
                <label>From</label>
                <input type="date" [ngModel]="searchBillModel.fromDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler($event)" class="form-control" />
            </div>
            <div class="col-md-2">
                <label>To</label>
                <input type="date" [ngModel]="searchBillModel.toDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler1($event)" class="form-control" />
            </div>
            <div class="col-md-2">
                <label>Customer Name</label>
                <input class="form-control" type="text" [(ngModel)]="searchCustomerName" />
              </div>
            <div class="col-md-2">
                <label> </label>
                <div>
                    <button class="btn btn-outline-primary" (click)="onNameCustomerSearch()">Search</button>
                    <button class="btn btn-outline-primary" (click)="onCollBillSearchReset()">Reset</button>
                </div>
            </div>
        </div>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Account Name</th>
                <th scope="col">Name</th>
                <th scope="col">Address</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let u of customers">
                <th scope="row">
                  <button class="btn btn-outline-primary" (click)="onEditCustomer(u)">View</button>
                  <button class="btn btn-outline-primary" (click)="downloadAccLedReport(u)">Download</button>
                </th>
                <td>{{u.accountName}}</td>
                <td>{{u.customerName}}</td>
                <td>{{u.address}}</td>
              </tr>
            </tbody>
          </table>
        <table class="table table-striped ">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">SlNo</th>
                    <th scope="col">Transaction Date</th>
                    <th scope="col">Description</th>
                    <th scope="col">CrDr</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Running Balance</th>
                </tr>
            <tbody>
                <tr *ngFor="let u of accledgers">
                    <td>{{u.rowNum}}</td>
                    <td>{{u.transactionDate | date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.descriptions }}</td>
                    <td>{{u.crDr }}</td>
                    <td>{{u.amount }}</td>
                    <td>{{u.runningBalance }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>