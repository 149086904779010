<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-md-12 col-lg-12">
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Route</th>
                            <th scope="col">Bill<br>Amount</th>
                            <th scope="col">Bill<br>Count</th>
                            <th scope="col">Customer<br>Count</th>
                            <th *DataCustomAclOnly="'Employee:Admin'" scope="col">Change <br>Route To</th>
                            <th *DataCustomAclOnly="'Employee:Admin'" scope="col">Inverse <br>Serial</th>
                        </tr>
                    <tbody>
                        <tr [class.bg-success]="u.groupName=='Group1'" [class.bg-warning]="u.groupName=='Group2'"
                            *ngFor="let u of billSum">
                            <td>{{u.driverName=='-1'?"None":u.driverName}}</td>
                            <td>{{u.billValue |currency:'INR': 'symbol' : '1.0-0'}}</td>
                            <td>{{u.billCount }}</td>
                            <td>{{u.customerCount }}</td>
                            <td class="elip" *DataCustomAclOnly="'Employee:Admin'">
                                <select class="form-control form-control-sm" [(ngModel)]="u.driverName1"
                                    (change)="selectChangeHandler31($event,u)">
                                    <option value="-1">None</option>
                                    <option *ngFor="let p of customersAll" [value]="p.resourceName">{{p.resourceName}}
                                    </option>
                                </select>
                            </td>
                            <td *DataCustomAclOnly="'Employee:Admin'">
                                <button class="btn btn-sm btn-info" (click)="inverse(u)">Inverse</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-12 col-lg-12">
                <div class="row">
                    <div class="col-md-3 col-lg-2">
                        <label>Drivers</label>
                        <select class="form-control form-control-sm" [(ngModel)]="selecteddriver"
                            (change)="selectChangeHandlerDriver($event)">
                            <option value="0" selected>All</option>
                            <option value="-1">None</option>
                            <option *ngFor="let p of customers" [value]="p.resourceName">{{p.resourceName}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-lg-2">
                        <label>Company</label>
                        <select class="form-control form-control-sm" [(ngModel)]="selectedcompany"
                            (change)="selectChangeHandlerCompany($event)">
                            <option value="-1" selected>All</option>
                            <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-lg-2">
                        <label for="zone">Zone</label>
                        <select class="form-control form-control-sm" [(ngModel)]="selectedzone"
                            (change)="selectChangeHandlerZone($event)">
                            <option value="-1" selected>All</option>
                            <option value="Zone1">Zone1</option>
                            <option value="Zone2">Zone2</option>
                            <option value="Zone3">Zone3</option>
                            <option value="Zone4">Zone4</option>
                            <option value="Zone5">Zone5</option>
                            <option value="Zone6">Zone6</option>
                            <option value="Zone7">Zone7</option>
                            <option value="Zone8">Zone8</option>
                            <option value="Zone9">Zone9</option>
                            <option value="Zone10">Zone10</option>
                            <option value="Zone11">Zone11</option>
                            <option value="Zone12">Zone12</option>
                            <option value="Zone13">Zone13</option>
                            <option value="Zone14">Zone14</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-lg-2">
                        <label>Customer/Bill</label>
                        <input type="text" class="form-control  form-control-sm" (keyup)="onKey1($event)"
                            [(ngModel)]="selectedcustomerName" />
                    </div>
                    <div class="col-md-3 col-lg-2">
                        <label>&nbsp;</label><Br>
                        <div>
                            <button *DataCustomAclOnly="'Admin:Employee'" class="btn btn-sm btn-info"
                                (click)="saveGrn()">Save</button>&nbsp;
                            <button class="btn btn-sm btn-info" (click)="download()">Download</button>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-2" *DataCustomAclOnly="'Employee:Admin'">
                        <label>&nbsp;</label><Br>
                        <button class="btn btn-sm btn-info" (click)="showlist()">List</button>&nbsp;
                        <button class="btn btn-sm btn-info" (click)="showmap()">map</button>
                    </div>
                    <div class="col-md-3 col-lg-4" *DataCustomAclOnly="'Employee:Admin'">
                        <label>BillIds-CommaSeparated</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control form-control-sm" [(ngModel)]="billIds">
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-info" (click)="shortRouteMove()">ShortRoute</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-2" *DataCustomAclOnly="'Employee:Admin'">
                        <label>Change To</label>
                        <select class="form-control form-control-sm" (change)="selectChangeHandler311($event)">
                            <option value="-1" selected>Select</option>
                            <option *ngFor="let p of customersAll" [value]="p.resourceName">{{p.resourceName}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 col-lg-2" *DataCustomAcl="'Employee:Admin'">
                        <label>&nbsp;</label><Br>
                        <input type="number" class="form-control form-control-sm" [(ngModel)]="increment">
                        <button class="btn btn-sm btn-info" (click)="showmapTest()">mapTest</button>
                    </div>
                    <div class="col-md-3 col-lg-2">
                        <label>BillValue ></label><Br>
                        <input type="number" class="form-control form-control-sm" [(ngModel)]="billValueFilter">
                    </div>
                </div>
            </div>
        </div>
        <Br>
        <div class="row" *DataCustomAclOnly="'Employee:Admin'">
            <div class="col-md-12 col-lg-12">
                <span *ngFor="let u of customersAll">
                    <span *ngIf="u.contactPhone=='Group1' &&  (
                        u.resourceName =='1PendingRoute' 
                        || u.resourceName =='2PendingRoute'
                        || u.resourceName =='3PendingRoute'
                        || u.resourceName =='4PendingRoute'
                        || u.resourceName =='5PendingRoute'
                        || u.resourceName =='6PendingRoute')
                        ">
                        <button class="btn btn-sm" (click)="onChangeSet(u.resourceName)" [ngClass]="{ 
                        'btn-success': isChecked(u.resourceName) ==true,
                        'btn-warning':  isChecked(u.resourceName) ==false}">
                            {{u.resourceName}}</button>&nbsp;
                    </span>
                </span>
            </div>
        </div>
        <br>{{dbVersion}}<br>
        <div class="row">
            <div class="col">
                <agm-map *ngIf="isMapView" [latitude]="lat" [longitude]="lng">
                    <agm-direction [waypoints]="waypoints" *ngFor="let dir of directions" [origin]="dir.origin"
                        [renderOptions]="renderOptions" [markerOptions]="markerOptions" [destination]="dir.destination">
                    </agm-direction>
                </agm-map>
            </div>
        </div>
        <div class="row">
            <div class="col order-last">
                <table class="table table-striped table-sm table-responsive table-condensed">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Age</th>
                            <th scope="col">Bill#</th>
                            <th scope="col">BillDate</th>
                            <th scope="col">Amount</th>
                            <th scope="col">ColVal</th>
                            <th scope="col">Balance</th>
                            <th scope="col">Pend</th>
                            <th scope="col">Company</th>
                            <th scope="col">Customer</th>
                            <th scope="col">infor3</th>
                            <th scope="col">Route</th>
                            <th scope="col">Remarks</th>
                            <th scope="col" *DataCustomAclOnly="'Employee:Admin'"><i class="fa fa-arrow-up"></i></th>
                            <th scope="col">#</th>
                            <th scope="col" *DataCustomAclOnly="'Employee:Admin'"><i class="fa fa-arrow-down"></i></th>
                            <th scope="col">infor1</th>
                            <th scope="col">PO Number</th>
                            <th scope="col">Zone</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let u of getData()">
                        <tr [class.active]="color(u)">
                            <td>{{u.billDays}}</td>
                            <td [class.bg-danger]="!u.inStock">{{u.billNumber}}</td>
                            <td [class.bg-warning]="u.custOrder==1" [class.bg-danger]="u.saleOrder||u.custOrder==2">
                                {{u.billDate |
                                date:'dd/MM/yy'}}</td>

                            <td [class.bg-warning]="u.billValue > 50000">{{u.billValue}}</td>
                            <td>{{u.colVal}}</td>
                            <td>{{u.balance}}</td>
                            <td>
                                <button class="btn btn-sm btn-success" (click)="showDetail(u)">{{u.pending}}</button>
                            </td>
                            <td [copy-clipboard]="u.address">{{u.companyName}}</td>
                            <td class="elip" [class.bg-warning]="u.hasReviewed==false" [class.bg-danger]="u.mapLink==''"
                                [copy-clipboard]="u.customerName">
                                <div (click)="onClickCustomername(u)"> {{u.customerName}}</div>
                            </td>
                            <td class="elip">{{u.information3}}</td>
                            <td class="elip" [class.bg-danger]="isHoldBillCust(u.customerId)">
                                <span
                                    *DataCustomAclOnly="'Sales:Company:ReadOnly'">{{u.driverName=='-1'?"None":u.driverName}}</span>
                                <select *DataCustomAclOnly="'Employee:Admin'" class="form-control form-control-sm"
                                    (change)="selectChangeHandler3($event,u)" [(ngModel)]="u.driverName1">
                                    <option value="-1">None</option>
                                    <option *ngFor="let p of customers" [value]="p.resourceName">{{p.resourceName}}
                                    </option>
                                </select>
                            </td>
                            <td class="elip">
                                <span *DataCustomAclOnly="'Sales:Company:ReadOnly'">{{u.billRemarks}}</span>
                                <input title="{{u.billRemarks}}" *DataCustomAclOnly="'Employee:Admin'"
                                    (blur)="onBlurEvent(u)" type="text" [(ngModel)]="u.billRemarks"
                                    class="form-control form-control-sm" />
                            </td>
                            <td *DataCustomAclOnly="'Employee:Admin'">
                                <i class="fa fa-arrow-up" (click)="onUpDown(true,u)"></i>
                            </td>
                            <td [class.bg-success]="u.groupName=='Group1'" [class.bg-warning]="u.groupName=='Group2'">
                                {{u.serial}}
                            </td>
                            <td *DataCustomAclOnly="'Employee:Admin'">
                                <i class="fa fa-arrow-down" (click)="onUpDown(false,u)"></i>
                            </td>
                            <td class="elip">{{u.information1}}</td>
                            <td>{{u.poNumber}}</td>
                            <td [copy-clipboard]="u.address">{{u.zone}}</td>
                        </tr>
                        <tr *ngIf="u.showDetail">
                            <td colspan="24">
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <div>
                                            CustomerPhone:{{u.customerPhone}}<br>
                                            Saleman:{{u.salesPersonName}}<br>
                                            SalesmanPhone:{{u.salesContactPhone}}<br>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <table class="table table-striped table-sm table-responsive table-condensed">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">User</th>
                                                    <th scope="col">Notes</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *DataCustomAclOnly="'Employee:Admin'">
                                                    <td colspan="2">
                                                        <textarea rows="2" class="form-control form-control-sm"
                                                            [(ngModel)]="addNotes.notes"></textarea>
                                                    </td>
                                                    <td><input type="date" class="form-control form-control-sm"
                                                            [(ngModel)]="addNotes.customerFolDate"></td>
                                                    <td>
                                                        <button class="btn btn-sm btn-info"
                                                            (click)="saveNoteTrack(false)">Add Note</button>
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-sm btn-info"
                                                            (click)="saveNoteTrack(true)">Remove
                                                            FollowUp</button>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let u of suppliedDocs">
                                                    <td>{{u.userName}}</td>
                                                    <td>{{u.notes}}</td>
                                                    <td>{{u.enteredDate| date: 'dd/MM/yy hh:mm a'}}</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="form-row" *ngIf="u.showDetail">
                                    <div class="col-md-2">
                                        Bounced Checks
                                        <table class="table table-striped table-sm table-responsive table-condensed">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">Bill Number</th>
                                                    <th scope="col">Check Details</th>
                                                </tr>
                                            <tbody>
                                                <tr *ngFor="let u of pendingFollowups">
                                                    <td>{{u.billNumber}}</td>
                                                    <td>{{u.bouncedCheckDetail }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-4">
                                        Pending Bills
                                        <table class="table table-striped table-sm table-responsive table-condensed">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">Age</th>
                                                    <th scope="col">Company</th>
                                                    <th scope="col">Bill#</th>
                                                    <th scope="col">Bill Date</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Collected</th>
                                                    <th scope="col">Balance</th>
                                                    <th scope="col">Assigned</th>
                                                </tr>
                                            <tbody>
                                                <tr *ngFor="let u of pendingBills"
                                                    [class.bg-warning]="u.companyName==this.selectedCompany">
                                                    <td>{{ calculateDiff(u.billDate) }}</td>
                                                    <td>{{u.companyName}}</td>
                                                    <td>{{u.billNumber}}</td>
                                                    <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
                                                    <td>{{u.billValue }}</td>
                                                    <td>{{u.colVal }}</td>
                                                    <td>{{u.balance }}</td>
                                                    <td>{{u.personName }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-6">
                                        Pending Cheque Clerance
                                        <table class="table table-striped table-sm table-responsive table-condensed">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th scope="col">Cheque#</th>
                                                    <th scope="col">Cheque Date</th>
                                                    <th scope="col">Collected</th>
                                                    <th scope="col">Remarks</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Mode</th>
                                                    <th scope="col">CollectedBy</th>
                                                    <th scope="col">Bill Date</th>
                                                    <th scope="col">Bill#</th>
                                                    <th scope="col">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let u of colls">
                                                    <td>{{u.chequeNumber}}</td>
                                                    <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
                                                    <td>{{u.collectionValue}}</td>
                                                    <td>{{u.remarks}}</td>
                                                    <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                                                    <td>{{u.mode}}</td>
                                                    <td>{{u.collectedBy}}</td>
                                                    <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                                                    <td>{{u.billNumber}}</td>
                                                    <td>{{u.billValue}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="w-100"></div>
        </div>
    </div>
</div>