<div class="row">
    <div class="col">
        <div class="form-row">
            <div class="col-md-2">
                <label for="companyName">Company</label>
                <select class="form-control form-control-sm" [(ngModel)]="searchIL.companyName">
                    <option value="All" selected>All</option>
                    <option *ngFor="let p of companys" [value]="p.sCompanyName">{{p.sCompanyName}}</option>
                </select>
            </div>
            <div class="col-md-2">
                <label>Bill/Customer</label>
                <input type="text" [(ngModel)]="searchIL.productName" class="form-control form-control-sm" />
            </div>
            <div class="col-md-2">
                <label>BillDate From</label>
                <input type="date" [ngModel]="searchIL.fromDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler($event)" class="form-control form-control-sm" />
            </div>
            <div class="col-md-2">
                <label>BillDate To</label>
                <input type="date" [ngModel]="searchIL.toDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler1($event)" class="form-control form-control-sm" />
            </div>
            <div class="col-md-2">
                <label></label><br>
                <div>
                    <button class="btn btn-sm btn-info" (click)="onNameCustomerSearch(0)">Search</button>

                </div>
            </div>
            <div class="col-md-2">
                <label></label><br>
                <div>
                    <button class="btn btn-sm btn-info" (click)="download()">Download</button> &nbsp;
                </div>
            </div>
            <div class="col-md-2" *DataCustomAclOnly="'Admin:Employee'">
                <label></label><br>
                <div class="input-group mb-3">
                    <select class="form-control" [(ngModel)]="selSource">
                        <option value="Source1" selected>Source1-Rayar</option>
                        <option value="Source2">Source2-WhiteHorse</option>
                        <option value="Source3">Source3-Nestling</option>
                        <option value="Source4">Source4-Sunrise</option>
                        <option value="Source5">Source5</option>
                    </select>
                    <button class="btn btn-sm btn-info" (click)="inputFile.click()">
                        Upload
                    </button>
                    <input name="file" id="file" (change)="upload($event)" type="file" #inputFile hidden>
                </div>
            </div>
        </div>
        <br>

        <div class="form-row">
            <div class="col-md-2">
                <label>Total Sales: {{total}}</label>
            </div>
            <table class="table table-striped table-sm table-responsive table-condensed">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Company</th>
                        <th scope="col">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let u of customers1">
                        <td>{{u.companyName}}</td>
                        <td>{{u.prodValue1}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <nav aria-label="...">
            <ul class="pagination">
                <li class="page-item"><a class="page-link" (click)="onpage(false)">Previous</a></li>
                <li class="page-item"><a class="page-link" (click)="onpage(true)">Next</a></li>
                <li class="page-item">
                    <span class="page-link">Page: {{searchIL.page+1}}</span>
                </li>
            </ul>
        </nav>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Company</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Bill</th>
                    <th scope="col">Date</th>
                    <th scope="col">Product</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Value1</th>
                    <th scope="col">Value2</th>
                    <th scope="col">Value3</th>
                    <th scope="col">Value4</th>
                    <th scope="col">Value5</th>
                    <th scope="col">Value6</th>
                    <th scope="col">Total</th>
                    <th scope="col">BillValue</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of customers">
                    <td>{{u.companyName}}</td>
                    <td>{{u.customerName}}</td>
                    <td>{{u.billNumber}}</td>
                    <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.productName}}</td>
                    <td>{{u.quantity}}</td>
                    <td>{{u.prodValue1}}</td>
                    <td>{{u.prodValue2}}</td>
                    <td>{{u.prodValue3}}</td>
                    <td>{{u.prodValue4}}</td>
                    <td>{{u.prodValue5}}</td>
                    <td>{{u.prodValue6}}</td>
                    <td>{{u.prodValue3+u.prodValue6}}</td>
                    <td>{{u.billValue}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>