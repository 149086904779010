<div class="row">
    <div class="col">
        <div class="form-row">
            <div class="col-lg-2 col-md-1">
                <label>Bill Number</label>
                <input class="form-control" type="text" [(ngModel)]="searchBillModel.billNumber" />
            </div>
            <div class="col-lg-2 col-md-1">
                <label>Customer</label>
                <input class="form-control" type="text" [(ngModel)]="searchBillModel.customerName" />
            </div>
            <div class="col-lg-2 col-md-1">
                <label>Account</label>
                <input class="form-control" type="text" [(ngModel)]="searchBillModel.accountName" />
            </div>
            <div class="col-lg-2 col-md-1">
                <label>Bill Assigned To</label>
                <select class="form-control" [(ngModel)]="searchBillModel.personName">
                    <option value="" selected>All</option>
                    <option value="NotAssigned" selected>NotAssigned</option>
                    <option *ngFor="let p of persons" [value]="p.name">{{p.name}}</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-1">
                <label>Supplied From</label>
                <input type="date" [ngModel]="searchBillModel.fromDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler($event)" class="form-control" />
            </div>
            <div class="col-lg-2 col-md-1">
                <label>Supplied To</label>
                <input type="date" [ngModel]="searchBillModel.toDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler1($event)" class="form-control" />
            </div>
            <div class="col-lg-2 col-md-1">
                <label>Last Checked Date</label>
                <input type="date" [ngModel]="searchBillModel.lastVerifiedDate | date:'yyyy-MM-dd'"
                    (change)="selectChangeHandler11($event)" class="form-control" />
            </div>
            <div class="col-lg-2 col-md-1">
                <label>Last Checked Date</label><Br>
                <input type="radio" name="revRadio" value="0" [(ngModel)]="searchBillModel.lastCheckedInOut" />
                Equal
                <input type="radio" name="revRadio" value="1" [(ngModel)]="searchBillModel.lastCheckedInOut" />
                Not Equal
            </div>
            <div class="col-lg-2 col-md-1">
                <label>Trade</label><Br>
                <input type="radio" name="revRadio1" value="0" [(ngModel)]="searchBillModel.trade" />
                General
                <input type="radio" name="revRadio1" value="1" [(ngModel)]="searchBillModel.trade" />
                Modern
            </div>
            <div class="col-md-1 col-lg-2">
                <label>Company</label>
                <select class="form-control form-control-sm" [(ngModel)]="searchBillModel.companyName">
                    <option value="" selected>All</option>
                    <option *ngFor="let p of companys" [value]="p.companyName">{{p.companyName}}</option>
                </select>
            </div>
        </div>
        <div class="form-row">
            <div class="col-lg-2 col-md-2">
                <label></label>
                <div>
                    <button class="btn btn-sm btn-info" (click)="onSearchPerson()">Search</button>
                </div>
            </div>
            <div class="col-lg-2 col-md-2">
                <label></label>
                <div>
                    <button class="btn btn-sm btn-info" (click)="onReset()">Reset</button>
                </div>
            </div>
            <div class="col-lg-2 col-md-2">
                <label></label>
                <div>
                    <button class="btn btn-sm btn-info" (click)="showHide()">Show/Hide</button>
                </div>
            </div>
            <div class="col-lg-2 col-md-2">
                <label></label>
                <div>
                    <button class="btn btn-sm btn-info" (click)="downloadReport()">Download</button>
                </div>
            </div>
        </div>
        <br>
        <table *ngIf="showhideTable" class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Bill Count</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of asgnTrackReport">
                    <td>{{u.name}}</td>
                    <td>{{u.billCount}}</td>
                </tr>
            </tbody>
        </table>
        <br>
        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">(Re)Assign To</th>
                    <th scope="col"></th>
                    <th scope="col">New Checked<br>Remarks</th>
                    <th scope="col"></th>
                    <th scope="col">Last Checked<br>Date</th>
                    <th scope="col">Last Check<br>Remarks</th>
                    <th scope="col">Previous Bill<br>Assigned To</th>
                    <th scope="col">Balance</th>
                    <th scope="col">Account</th>
                    <th scope="col">Bill Number</th>
                    <th scope="col">Bill Date</th>
                    <th scope="col">Bill Value</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Company Name</th>
                    <th scope="col">Supplied Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of bills">
                    <td class="atw">
                        <select class="form-control" [(ngModel)]="u.personId">
                            <option value="0" selected>NotAssigned</option>
                            <option *ngFor="let p of persons" [value]="p.personId">{{p.name}}</option>
                        </select>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-info" (click)="assignCollection(u,u.personId)"><i
                                class="fa fa-check-square"></i></button>
                    </td>
                    <td class="atw">
                        <div class="row">
                            <div class="col-sm">
                                <input type="text" [(ngModel)]="u.printedString" class="form-control" />
                            </div>
                        </div>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-success" (click)="markChecked(u)"><i
                                class="fa fa-check-square"></i></button>
                    </td>
                    <td>{{u.lastVerifiedDate| date: 'dd/MM/yyyy'}} </td>
                    <td>{{u.verifiedRemarks}}</td>
                    <td>{{u.previousAssigned}}</td>
                    <td>{{u.balance}}</td>
                    <td>{{u.accountName}}</td>
                    <td>{{u.billNumber}}</td>
                    <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.billValue}}</td>
                    <td class="atw">{{u.customerName}}</td>
                    <td>{{u.companyName}}</td>
                    <td>{{u.suppliedDate| date: 'dd/MM/yyyy'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>