import { Component } from '@angular/core';
import { User } from './models';
import { Router } from '@angular/router';
import { AuthenticationService } from './_services/authentication.service';
import { LoaderService } from "./_services/LoaderService";
import { MyMonitoringService } from './_services/MyMonitoringService';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  currentUser: User;
  loading: boolean;

  navbarCollapsed = true;

  toggleNavbarCollapsing() {
    this.navbarCollapsed = !this.navbarCollapsed;
  }

  constructor(
    private myMonitoringService: MyMonitoringService,
    private loaderService: LoaderService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  logout() {
    this.authenticationService.logout().subscribe(
      data => {
        this.router.navigate(['/login']);
      },
      error => {
        this.loading = false;
      });
  }
}
