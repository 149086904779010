import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { PendingBillsByPerson, CollectonPersonModel, CustomerModel } from '../models';
import { HttpEventType } from '@angular/common/http';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-customer-report',
  templateUrl: './customer-report.component.html',
  styleUrls: ['./customer-report.component.css']
})
export class CustomerReportComponent implements OnInit {

  pendingBillsByPerson: PendingBillsByPerson[] = [];
  custReport: CustomerModel[] = [];
  routes: CustomerModel[] = [];

  loading = false;
  error = '';
  persons1: CollectonPersonModel[] = [];
  selectedPerson = 'ALL';
  selectedRoute = 'ALL';

  ngOnInit(): void {
  }

  constructor(private api: RayarApiService) {
    this.getCollPersons();
    this.getCustomerRoute();
    this.selectedPerson = "ALL";
  }

  getBillsByPerson() {
    this.api.getPendingBillsByPerson()
      .subscribe(result => { this.pendingBillsByPerson = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  getCustomerRoute() {
    this.api.getCustomerRoute()
      .subscribe(result => { this.routes = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  getCustomerReport() {
    this.api.getCustomerReport(this.selectedRoute)
      .subscribe(result => { this.custReport = result; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }


  getCollPersons() {
    this.api.getCollPersons().subscribe(result => {
      this.persons1 = result;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  selectChangeHandler1(event: any) {
    var s = event.target.value;
    this.selectedRoute = s;
  }

  selectChangeHandler(event: any) {
    var s = event.target.value;
    if (s == "ALL" || s == "NotAssigned") {
      this.selectedPerson = s;
    } else {
      var pId = parseInt(s);
      this.selectedPerson = this.persons1.filter(function (hero) {
        return hero.personId == pId;
      })[0].name;
    }
  }

  downloadPendBillAgeReport() {
    if (this.selectedPerson) {
      this.download("PendingBillsByCust/" + this.selectedPerson)
    }
  }

  public download(q: string) {
    this.api.downloadFile(q).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      () => {
      }
    );
  }

}
