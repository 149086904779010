<div class="row">
    <div class="col">
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        <div class="form-row">
            <div class="col-md-1">
                <label>Bill Number</label>
                <input class="form-control" type="text" [(ngModel)]="searchBillModel.billNumber" />
            </div>
            <div class="col-md-1">
                <label>Customer/Account</label>
                <input class="form-control" type="text" [(ngModel)]="searchBillModel.customerName" />
            </div>
            <div class="col-md-1">
                <label>Cheque Number</label>
                <input class="form-control" type="number" [(ngModel)]="searchBillModel.companyName" />
            </div>
            <div class="col-md-1">
                <label>Bill Value</label>
                <input class="form-control" type="number" [(ngModel)]="searchBillModel.billValue" />
            </div>
            <div class="col-md-1">
                <label>Bill AssignedTo</label>
                <input class="form-control" type="text" [(ngModel)]="searchBillModel.personName" />
            </div>
            <div class="col-md-1">
                <label for="collectedBy">Collected By</label>
                <select class="form-control" [(ngModel)]="searchBillModel.personId">
                    <option value="0" selected>All</option>
                    <option *ngFor="let p of persons1" [value]="p.personId">{{p.name}}</option>
                </select>
            </div>
            <div class="col-md-1">
                <label></label><br>
                <div>
                    <button class="btn btn-sm btn-info" (click)="onSearchPerson()">Search</button>&nbsp;
                    <button class="btn btn-sm btn-info" (click)="onReset()">Reset</button>
                </div>
            </div>
        </div>
        <br>

        <table class="table table-striped table-sm table-responsive table-condensed">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Cheque#</th>
                    <th scope="col">ChequeDate</th>
                    <th scope="col">Collected</th>
                    <th scope="col">BillValue</th>
                    <th scope="col">BillDate</th>
                    <th scope="col">Bill#</th>
                    <th scope="col">Remarks</th>
                    <th scope="col">CollectionDate</th>
                    <th scope="col">Mode</th>
                    <th scope="col">CollectedBy</th>
                    <th scope="col">Assign Person</th>
                    <th scope="col">Customer </th>
                </tr>
            </thead>
            <tbody *ngFor="let u of colls">
                <tr>
                    <td>
                        <button class="btn btn-sm btn-info" (click)="markCredited(u)">Link</button>&nbsp;
                        <button class="btn btn-sm btn-info" (click)="markMissing(u)">Mark Return</button>
                    </td>
                    <td>{{u.chequeNumber}}</td>
                    <td>{{u.chequeDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.collectionValue}}</td>
                    <td>{{u.billValue}}</td>
                    <td>{{u.billDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.billNumber}}</td>
                    <td>{{u.remarks}}</td>
                    <td>{{u.entryDate| date: 'dd/MM/yyyy'}}</td>
                    <td>{{u.mode}}</td>
                    <td>{{u.collectedBy}}</td>
                    <td>{{u.personName}}</td>
                    <td>{{u.customerName}}</td>

                </tr>
                <tr *ngIf="u.showDetail">
                    <td colspan="16">
                        <div class="form-row">
                            <div class="col">
                                <table class="table table-striped table-sm table-responsive table-condensed">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">Bill Number</th>
                                            <th scope="col">Bill Date</th>
                                            <th scope="col">Bill Value</th>
                                            <th scope="col">Collected By</th>
                                            <th scope="col">Collected Date</th>
                                            <th scope="col">Customer Name</th>
                                            <th scope="col">Cheque Date</th>
                                            <th scope="col">Collection Value</th>
                                            <th scope="col">ChequeNumber</th>
                                            <th scope="col">Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let u of selectedBill">
                                            <td>{{u.billNumber}}</td>
                                            <td>{{u.billDate | date: 'dd/MM/yyyy'}}</td>
                                            <td>{{u.billValue}}</td>
                                            <td>{{u.collectedBy}}</td>
                                            <td>{{u.entryDate | date: 'dd/MM/yyyy'}}</td>
                                            <td>{{u.customerName}}</td>
                                            <td>{{u.chequeDate | date: 'dd/MM/yyyy'}}</td>
                                            <td>{{u.collectionValue}}</td>
                                            <td>{{u.chequeNumber}}</td>
                                            <td>{{u.remarks}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="w-100"></div>
                            <div class="col">
                                <div class="form-row">
                                    <div class="col-md-1">
                                        <label>From</label>
                                        <input type="date" [ngModel]="selectedFromDate | date:'yyyy-MM-dd'"
                                            (change)="selectChangeHandlerFrom($event)"
                                            class="form-control form-control-sm" />
                                    </div>
                                    <div class="col-md-1">
                                        <label>To</label>
                                        <input type="date" [ngModel]="selectedToDate | date:'yyyy-MM-dd'"
                                            (change)="selectChangeHandlerTo($event)"
                                            class="form-control form-control-sm" />
                                    </div>
                                    <div class="col-md-1">
                                        <label>Description</label>
                                        <input type="text" [(ngModel)]="description"
                                            class="form-control form-control-sm" />
                                    </div>
                                    <div class="col-md-2">
                                        <label>Remarks</label>
                                        <div>
                                            <input type="radio" name="revRadio1" value="1" [(ngModel)]="sRemar"
                                                (change)="selectChangeHandler2($event)" /> Remarks
                                            <input type="radio" name="revRadio1" value="0" [(ngModel)]="sRemar"
                                                (change)="selectChangeHandler2($event)" /> No Remarks
                                            <input type="radio" name="revRadio1" value="2" [(ngModel)]="sRemar"
                                                (change)="selectChangeHandler2($event)" /> Both
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <label>&nbsp;</label>
                                        <button class="btn btn-sm btn-info" (click)="onSearch()">Search</button>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-2">
                                        <label for="bounced">Bounced</label>
                                        <div>
                                            <input type="radio" name="bRadio1" value="Yes" [(ngModel)]="bouncedRadio" />
                                            Yes
                                            <input type="radio" name="bRadio2" value="No" [(ngModel)]="bouncedRadio" />
                                            No
                                            <button class="btn btn-sm btn-info"
                                                (click)="onSelectBkStmtSave()">Link</button> &nbsp;
                                            <button class="btn btn-sm btn-info" *DataCustomAcl="'Admin'"
                                                (click)="onSelectBkStmtSaveRev()">Link &
                                                Review</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100"></div>
                            <div class="col">
                                <table class="table table-striped table-sm table-responsive table-condensed">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Remarks</th>
                                            <th scope="col">Has Collections</th>
                                            <th scope="col">Transaction Amount</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Value Date</th>
                                            <th scope="col">CrDr</th>
                                            <th scope="col">Trans Posted Date</th>
                                            <th scope="col">ChequeNo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let u of bills">
                                            <th scope="row">
                                                <input type="checkbox" [value]="u.id"
                                                    (change)="onbankstmtsSelect($event)" />
                                            </th>
                                            <td class="elip">{{u.remarks}}</td>
                                            <td>{{u.hasCollections}}</td>
                                            <td>{{u.transactionAmount}}</td>
                                            <td>{{u.description}}</td>
                                            <td>{{u.valueDate | date: 'dd/MM/yyyy'}}</td>
                                            <td>{{u.crDr}}</td>
                                            <td>{{u.transPostedDate | date: 'dd/MM/yyyy'}}</td>
                                            <td>{{u.chequeNo}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
