import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { accountModel, accountLedgerModel, Role, User } from '../models';
import { formatDate } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { AuthenticationService } from '../_services/authentication.service';
import { jsPDF } from 'jspdf';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-account-ledger',
  templateUrl: './account-ledger.component.html',
  styleUrls: ['./account-ledger.component.css']
})
export class AccountLedgerComponent implements OnInit {
  persons: accountModel[] = [];
  loading = false;
  error = '';
  accledgers: accountLedgerModel[] = [];
  searchBillModel: accountLedgerModel = new accountLedgerModel();;

  constructor(private imageCompress: NgxImageCompressService, private authenticationService: AuthenticationService, private api: RayarApiService) {
    this.currentUser = this.authenticationService.currentUserValue;

    this.getAccounts();
    this.getAccBalance();
    this.searchBillModel.accountId = 0;
    this.searchBillModel.toDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.searchBillModel.fromDate = new Date(formatDate(new Date(new Date().setMonth(new Date().getMonth() - 2)), 'yyyy-MM-dd', 'en'));
  }

  ngOnInit(): void {
  }


  @ViewChild('inputFile') inputFile: ElementRef;
  imageSrc: string;
  fileType: string;
  fileSource: any = null;

  selAcled: accountLedgerModel = new accountLedgerModel();
  selectledger(u: accountLedgerModel) {
    this.selAcled = u;
  }

  public upload(event) {
    this.error = "";
    this.fileType = null;
    this.fileSource = null;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.startsWith("image")) {
          this.imageSrc = reader.result as string;
          this.imageCompress.compressFile(this.imageSrc, 50, 100).then((compressedImage) => {
            this.fileType = "Image";
            var doc = new jsPDF();
            doc.addImage(compressedImage, "JPEG", 15, 40, 180, 180);
            this.fileSource = doc.output('blob');
          });
        }
        else {
          this.imageSrc = null;
          if (file.type.startsWith("application/pdf")) {
            this.api.uploadToBlob(file, "UploadBill/" + this.selAcled.id + "/" + this.selAcled.id + "/GRN").subscribe(
              data => {
                if (data) {
                  switch (data.type) {
                    case HttpEventType.UploadProgress:
                      break;
                    case HttpEventType.Response:
                      this.inputFile.nativeElement.value = '';
                      this.fileType = null;
                      this.fileSource = null;
                      this.imageSrc = null;
                      break;
                  }
                }
              },
              error => {
                this.inputFile.nativeElement.value = '';
              }
            );
          } else {
            this.error = "Invalid File format images/PDF only"
          }
        }
      };
    }
  }

  onUploadCancel() {
    this.fileType = null;
    this.fileSource = null;
    this.imageSrc = null;
  }

  onUpload() {
    this.api.uploadToBlob(this.fileSource, "UploadBill/" + this.selAcled.id + "/" + this.selAcled.id + "/GRN").subscribe(
      data => {
        if (data) {
          switch (data.type) {
            case HttpEventType.UploadProgress:
              break;
            case HttpEventType.Response:
              this.inputFile.nativeElement.value = '';
              this.fileType = null;
              this.fileSource = null;
              this.imageSrc = null;
              break;
          }
        }
      },
      error => {
        this.inputFile.nativeElement.value = '';
      }
    );
  }

  sortD: boolean = true;

  sortB() {
    this.sortD = !this.sortD;
    if (this.sortD)
      this.accBalance.sort((a, b) => a.balance - b.balance);
    else
      this.accBalance.sort((a, b) => b.balance - a.balance);
  }

  sortA() {
    this.sortD = !this.sortD;
    if (this.sortD)
      this.accBalance.sort((a, b) => a.accountName.localeCompare(b.accountName))
    else
      this.accBalance.sort((a, b) => b.accountName.localeCompare(a.accountName))
  }

  accBalance: accountModel[];

  getAccBalance() {
    this.api.getAccountBalance()
      .subscribe(result => {
        this.accBalance = result; this.accBalance.sort((a, b) => a.accountName.localeCompare(b.accountName));
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  selectChangeHandler3(event: any) {
    var accountId = parseInt(event.target.value);
    this.searchBillModel.accountId = accountId;
  }


  selectChangeHandler(event: any) {
    this.searchBillModel.fromDate = event.target.value;
  }

  selectChangeHandler1(event: any) {
    this.searchBillModel.toDate = event.target.value;
  }


  onCollBillSearchReset() {
    this.searchBillModel = new accountLedgerModel();
    this.searchBillModel.toDate = new Date(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.searchBillModel.fromDate = new Date(formatDate(new Date(new Date().setMonth(new Date().getMonth() - 2)), 'yyyy-MM-dd', 'en'));
    this.searchBillModel.accountId = 0;
    this.accledgers = [];
    this.error = '';
  }

  onCollBillSearch() {
    this.searchBillModel.page = 0;
    if (this.searchBillModel.accountId)
      this.searchBillModel.accountId = parseInt(this.searchBillModel.accountId.toString());
    if (!this.searchBillModel.accountId || this.searchBillModel.accountId <= 0) {
      this.error = 'Please Select Account';
      return;
    }
    this.api.getAccountLedger(this.searchBillModel).subscribe(result => {
      this.accledgers = result;
      this.error = '';
      this.accledgers.forEach(x => {
        x.isEdit = false;
      });
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }
  currentUser: User;

  markPaid(u: accountLedgerModel) {
    this.error = '';
    this.api.markPaid(u.id).subscribe(() => { this.onCollBillSearch(); }, error => {
      this.error = error;
      this.loading = false; console.error(error)
    });
  }

  editGrn(u: accountLedgerModel) {
    this.accledgers.forEach(x => {
      x.isEdit = u.uniqId == x.uniqId;
      if (u.uniqId == x.uniqId) {
        this.editgrnData = new accountLedgerModel();
        this.editgrnData.grnNumber = u.grnNumber;
        this.editgrnData.remarks = u.remarks;
        this.editgrnData.crDr = u.crDr;
        this.editgrnData.id = u.id;
      }
    });
  }

  editgrnData: accountLedgerModel;

  saveGrn(u: accountLedgerModel) {
    this.api.SaveGrnDetail(this.editgrnData)
      .subscribe(() => {
        this.loading = false;
        this.onCollBillSearch();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });

  }

  cancelGrn(u: accountLedgerModel) {
    this.accledgers.forEach(x => {
      x.isEdit = false;
    });
  }


  getAccounts() {
    this.api.getAccounts().subscribe(result => {
      this.persons = result;
      this.persons.sort((a, b) => a.accountName.localeCompare(b.accountName));
      this.searchBillModel.accountId = this.persons[0].accountId;
    },
      error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }


  downloadAccLedReport() {
    this.error = '';
    this.searchBillModel.page = 0;
    if (this.searchBillModel.accountId)
      this.searchBillModel.accountId = parseInt(this.searchBillModel.accountId.toString());
    if (!this.searchBillModel.accountId || this.searchBillModel.accountId <= 0) {
      this.error = 'Please Select Account';
      return;
    }
    this.download("AccountLedgersReport", this.searchBillModel)
  }

  public download(q: string, b: any) {
    this.api.downloadFilePost(q, b).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = q + formatDate(new Date(), 'ddMMyyyy', 'en').toString() + ".csv";
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
      }
    );
  }

}
