import { Component, OnInit } from '@angular/core';
import { RayarApiService } from '../_services/rayar-api.service';
import { companyModel, Role, userModel, VehicleModel } from '../models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

  customers: companyModel[] = [];
  loading = false;
  isAddCustomerClicked: boolean = false;
  error = '';
  submitted = false;
  customerForm: FormGroup;
  get f() { return this.customerForm.controls; }
  searchCustomerName: string = "";
  addEditCustModel: companyModel = new companyModel();

  constructor(private formBuilder: FormBuilder, private api: RayarApiService, private api1: AuthenticationService) {
    this.getvehicles();
    this.onNameCustomerSearch();
  }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      serialStart: [0, Validators.required],
      damageSerialStart: [0, Validators.required],
      vehicleId: [0, Validators.required],
      email: [''],
      rCompanyName: [''],
      sCompanyName: [''],
      serialGroup: ['']
    });
  }

  search: VehicleModel = new VehicleModel();
  vehicles: VehicleModel[] = [];

  getvehicles() {
    this.search.page = 0;
    this.search.category = 'Company';
    this.api.getSearchVehicle(this.search)
      .subscribe(result => { this.vehicles = result.vehicles; }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  onEditCustomer(u: companyModel) {
    this.customers.forEach(x => {
      if (u.companyId == x.companyId) {
        x.showDetail = !x.showDetail;
        if (x.showDetail) {
          this.isAddCustomerClicked = false;
          this.customerForm.setValue({
            companyName: u.companyName,
            sCompanyName: u.sCompanyName,
            rCompanyName: u.rCompanyName,
            serialStart: u.serialStart,
            damageSerialStart: u.damageSerialStart,
            vehicleId: u.vehicleId,
            email: u.email,
            serialGroup: u.serialGroup
          });
          this.addEditCustModel = u
        }
      }
      else
        x.showDetail = false;
    });
  }

  onAddCustSubmit() {
    this.submitted = true;
    if (this.customerForm.invalid) return;
    this.loading = true;
    this.addEditCustModel.companyName = this.f.companyName.value;
    this.addEditCustModel.serialStart = this.f.serialStart.value;
    this.addEditCustModel.damageSerialStart = this.f.damageSerialStart.value;
    this.addEditCustModel.vehicleId = parseInt(this.f.vehicleId.value);
    this.addEditCustModel.serialGroup = this.f.serialGroup.value;
    this.addEditCustModel.email = this.f.email.value;
    this.addEditCustModel.sCompanyName = this.f.sCompanyName.value;
    this.addEditCustModel.rCompanyName = this.f.rCompanyName.value;

    this.api.AddEditCompany(this.addEditCustModel)
      .subscribe(() => {
        this.submitted = false;
        this.loading = false;
        this.onCustSearchReset();
      }, error => {
        this.error = error;
        this.loading = false; console.error(error)
      });
  }

  onCloseAddCust() {
    this.isAddCustomerClicked = false;
  }

  oAddCustomer() {
    this.isAddCustomerClicked = true;
    this.customers.forEach(x => { x.showDetail = false; });

    this.customerForm.setValue({
      rCompanyName: '',
      sCompanyName: '',
      companyName: '',
      damageSerialStart: 0,
      vehicleId: '',
      serialStart: 0,
      serialGroup: '',
      email: ''
    });
    this.addEditCustModel = new companyModel();
    this.addEditCustModel.companyId = 0;
    this.addEditCustModel.serialStart = 0;
    this.addEditCustModel.damageSerialStart = 0;
    this.addEditCustModel.vehicleId = null;;
    this.addEditCustModel.serialGroup = '';
    this.addEditCustModel.email = '';
    this.addEditCustModel.rCompanyName = '';
    this.addEditCustModel.sCompanyName = '';
  }

  onNameCustomerSearch() {
    this.isAddCustomerClicked = false;
    this.api.getSearchCompany(this.searchCustomerName)
      .subscribe(result => {
        this.customers = result;
        this.customers.forEach(x => { x.showDetail = false; if (!x.serialGroup) x.serialGroup = ""; if (!x.userName) x.userName = "zzzzz"; });
        this.customers = result.sort((a, b) => a.serialGroup.localeCompare(b.serialGroup) || a.companyName.localeCompare(b.companyName));
      }, error => {
        this.error = error;
        this.loading = false; console.error(error);
      });
  }

  onCustSearchReset() {
    this.isAddCustomerClicked = false;
    this.searchCustomerName = "";
    this.onNameCustomerSearch();
  }

  onCreateSalesLogin(cp: companyModel) {
    if (cp.userName == "zzzzz") {
      this.error = '';
      this.submitted = true;
      this.loading = true;
      var u = new userModel();
      u.userName = cp.companyName;
      u.firstName = cp.companyName;
      u.lastName = cp.companyName;
      u.role = Role.Company;
      u.profileId = cp.companyName.toString();
      u.appName = 'Rayar';
      this.api1.AddEditUser(u)
        .subscribe(result => {
          this.submitted = false;
          this.loading = false;
          this.isAddCustomerClicked = false;
          this.onNameCustomerSearch();
        }, error => {
          this.error = error;
          this.loading = false; console.error(error)
        });
    }
  }
}
